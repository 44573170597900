import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { MdVerified } from "react-icons/md";
import Domains from "../../Domains/Domains";
import { ToastContainer, toast } from "react-toastify";
import { addOrUpdateDocFromPath } from "../../globals/services";
import { AuthUserContext } from "../../Firebase";
import DeleteComponent from "../DeleteComponent";
import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import SendInviteModal from "./account_management/SendInviteModal";
import AccountUsers from "./account_management/AccountUsers";

const AccManagement = (props) => {
  const [pubProfile, setPubProfile] = useState(null);
  const [isEditManager, setIsEditManager] = useState(false);
  const [invite, setInvite] = useState(false);

  const [first, setFirst] = useState("");

  useEffect(() => {
    let componentMounted = true;
    const getPubData = async () => {
      if (componentMounted) {
        const pubProfile = await props.firebase.getVendorPublicData(
          props.vendor.id
        );
        setPubProfile(pubProfile);
      } else {
        setPubProfile(null);
      }
    };
    getPubData();
    return () => {
      componentMounted = false;
    };
  }, [props.firebase, props.vendor.id]);

  const onToggle = async (e, fieldName) => {
    let updates = { ...pubProfile };
    if (fieldName === "feature") {
      updates["featured"] = e.checked;
    }

    await props.firebase.updateVendorPubData(props.vendor.id, updates);
    props.updateVendor();
  };
  /**
   * Triggers when super User change the manager on the drop down
   * => update the vendor document by adding the manager or changing the manager ref
   * @param {Object} e Event of the select tag
   */
  const filtData = async (e) => {
    if (parseInt(e.target.value) >= 0) {
      let updates = {};
      updates["manager"] = props.managersList[parseInt(e.target.value)].userRef;
      await props.firebase.updateVendor(props.vendor.id, updates);
      props.updateVendor();
      setIsEditManager(false);
    }

    e.persist();
  };

  // deletes account users
  const deleteUser = async (path) => {
    let userData = {
      status: false,
    };
    await addOrUpdateDocFromPath(path, userData);
  };

  return (
    <div className="stat">
      <div className="stat_header">
        <div className="title">
          <h1>Account Management</h1>

          <FontAwesomeIcon className="icon" icon={faTasks} />
        </div>
      </div>
      <div className="stat_content"></div>
      {props.isSuper ? (
        <div className="stat_content_data stat_content_rev row">
          <div className="stat_content_data-title">
            <h4>Management</h4>
          </div>
          <div className="stat_content_data-content">
            <div className="column">
              <div className="row top" id="fs">
                <label className="vendor-info">
                  Feature this vendor account
                </label>
                <SwitchComponent
                  id="isFeatured"
                  name="account_feature"
                  checked={pubProfile?.featured ?? false}
                  change={(e) => onToggle(e, "feature")}
                ></SwitchComponent>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  Featured Vendors have a public discoverable page on
                  Sprytelabs.com
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="stat_content_data stat_content_rev row">
        <div className="stat_content_data-title">
          <h4>Account Manager</h4>
        </div>
        <div className="stat_content_data-content">
          <div className="column">
            <div className="column top" id="fs">
              <div className="btnSearch"></div>
              {props.isSuper && !isEditManager ? (
                <div>
                  <Edit
                    style={{
                      opacity: ".55",
                      cursor: "pointer",
                      marginBottom: "6.5px",
                    }}
                    onClick={(e) => {
                      setIsEditManager(true);
                    }}
                  />
                </div>
              ) : props.isSuper && isEditManager ? (
                <div>
                  <select
                    name="managers"
                    id="managers"
                    className="account_managers"
                    // value={props.managerDetails?.name ?? "no Name"}
                    onChange={(e) => filtData(e)}
                  >
                    <option value="no">Select a Manager</option>
                    {props.managersList.map((manager, i) => {
                      return (
                        <option value={i} key={i}>
                          {manager.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <></>
              )}
              {props.managerDetails?.first ? (
                <>
                  <p>
                    {props?.managerDetails?.first +
                      " " +
                      props?.managerDetails?.last ?? "No Name"}
                  </p>
                  <p>{props.managerDetails?.email ?? "No Email"}</p>
                  <p>{props.managerDetails?.phone ?? "No Phone Number"}</p>
                </>
              ) : (
                <p>No Manager</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="stat_content_data stat_content_rev row">
        <div className="stat_content_data-title">
          <h4>Account Users</h4>
        </div>
        <AccountUsers
          deleteUser={deleteUser}
          vendor={props.vendor}
          setPublicChatData={props.setPublicChatData}
          setChatBtnClickCount={props.setChatBtnClickCount}
        />
        <div>
          {/* react toastify component */}
          <ToastContainer />

          <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className="invite_button"
            style={{ height: 35, marginTop: 16 }}
            onClick={() => setInvite(true)}
          >
            Invite
          </Button>
        </div>
      </div>
      <div className="stat_content_data stat_content_rev row">
        <div className="stat_content_data-title">
          <h4>Self Registration</h4>
        </div>
        <div className="stat_content_data-content">
          <div className="column">
            <div className="column top" id="fs">
              <div className="row" style={{ alignItems: "baseline" }}>
                <h4 id="th">Resource self-registration:</h4>
                <a
                  href={props.firebase.getSpryteLinks(
                    `register/${props.vendor?.orgRef?.id}/${props.vendor?.id}`
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.firebase.getSpryteLinks(
                    `register/${props.vendor?.orgRef?.id}/${props.vendor?.id}`
                  )}
                </a>
              </div>
              <div className="row" style={{ alignItems: "baseline" }}>
                <h4 id="th">Internship self-registration:</h4>
                <a
                  href={props.firebase.getSpryteLinks(
                    `internship/${props.vendor?.orgRef?.id}/${props.vendor?.id}`
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.firebase.getSpryteLinks(
                    `internship/${props.vendor?.orgRef?.id}/${props.vendor?.id}`
                  )}
                </a>
              </div>
            </div>
            <div className="bottom light" style={{ marginTop: "2.5px" }}>
              <p>
                Both resources and interns can self-register and administer
                their profiles. You will have to review {"&"} authorize before
                their profile can be active on your account.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Domains
        vendorData={props.vendor}
        firebase={props.firebase}
        updateVendor={props.updateVendor}
      />

      {/* send invite component */}

      <SendInviteModal
        open={invite}
        onClose={() => setInvite(false)}
        toast={toast}
      />
    </div>
  );
};

export default AccManagement;
