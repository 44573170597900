// import { render } from 'react-dom';
import * as React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { extend } from "@syncfusion/ej2-base";

class FormTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = extend({}, {}, props, true);
    this.status = [
      { statusValue: true, statusText: "Active" },
      { statusValue: false, statusText: "Inactive" },
    ];
    this.degLevel = [
      { level: "High School" },
      { level: "Prep School" },
      { level: "BootCamp" },
      { level: "B.S." },
      { level: "B.A." },
      { level: "M.A." },
      { level: "M.S." },
      { level: "M.B.A" },
      { level: "Ph.D." },
    ];

    this.start = "Year";
    this.depth = "Year";
    this.format = "MMMM y";
    this.dateValue = new Date();
  }
  onChange(args) {
    args.persist();
    let key = args.target.name;
    let value = args.target.value;
    this.setState({ [key]: value });
  }
  render() {
    let data = this.state;
    return (
      <div className="dft" style={{ width: "865px" }}>
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input
              ref={(input) => (this.school = input)}
              id="school"
              name="school"
              type="text"
              value={data.school}
              onChange={this.onChange.bind(this)}
            />
            <span className="e-float-line"></span>
            <label className="e-float-text e-label-top">School</label>
          </div>
        </div>

        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input
              ref={(input) => (this.country = input)}
              value={data.country}
              id="country"
              name="country"
              type="text"
              onChange={this.onChange.bind(this)}
            />
            <span className="e-float-line"></span>
            <label className="e-float-text e-label-top">Country</label>
          </div>
        </div>

        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input
              ref={(input) => (this.degName = input)}
              value={data.degName}
              id="degName"
              name="degName"
              type="text"
              onChange={this.onChange.bind(this)}
            />
            <span className="e-float-line"></span>
            <label className="e-float-text e-label-top">Degree Name</label>
          </div>
        </div>

        <div className="form-group col-md-6">
          <DropDownListComponent
            id="degLevel"
            value={data.degLevel}
            dataSource={this.degLevel}
            fields={{ value: "level", text: "level" }}
            placeholder="Status"
            popupHeight="300px"
            floatLabelType="Always"
            required={false}
          ></DropDownListComponent>
        </div>

        <div className="form-group col-md-6">
          <DatePickerComponent
            id="gradDate"
            name="gradDate"
            value={data.gradDate}
            start={this.start}
            depth={this.depth}
            format={this.format}
            floatLabelType="Always"
            placeholder="Graduation date"
          ></DatePickerComponent>
        </div>

        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <textarea
              ref={(input) => (this.description = input)}
              value={data.description}
              id="description"
              name="description"
              onChange={this.onChange.bind(this)}
              // style={{ width: "90%" }}
            ></textarea>
            <span className="e-float-line"></span>
            <label className="e-float-text e-label-top">Description</label>
          </div>
        </div>
      </div>
    );
  }
}

export default FormTemplate;
