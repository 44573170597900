import styled from "styled-components";
export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RateTop = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1px;
  h3 {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
`;

export const RateContent = styled.div`
  display: flex;
`;

export const RatePrices = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;

  .ratePrice {
    display: flex;
    // flex: 1;
    align-items: center;
    justify-content: center;
    gap: 5px;
    // padding: 0 10px;
    p {
      font-size: 14px;
      color: black;
    }
    span {
      font-size: 12px;
      color: #808080;
    }
  }
  .ratePricehr {
    p {
      font-weight: 600;
    }
    border-right: 1px solid #808080;
    padding-right: 5px;
  }
`;
export const PricePerc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  p {
    color: white;
    font-weight: 600;
  }
`;
