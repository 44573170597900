// This file contains functions related to searching via the search component.
// Contents:
// - Free Form Search
//   - parseFreeFormText
//   - parseTerm

// Imports.

// FREE FORM SEARCH

/**
 * This parses free-form strings, searching for matches amongst skills.
 * It may be useful for parsing residual free form text in the search component.
 *
 * @param {String} queryString - The free-form string to parse.
 * @returns {Promise<object>} Return all data relevant to the query in the form of an object with the following structure:
 * {industry: {"name": "id"}, "subcollections": {"name": "id"}, "technologies": {"name": "id"}}
 */
const parseFreeFormText = async (queryString, firebase) => {
  // Pair the subcollectionName to a matching orderBy.
  const SKILL_ARRAY = [
    { subcollectionName: "industry", orderByKey: "name" },
    { subcollectionName: "subcollections", orderByKey: "name" },
    { subcollectionName: "technologies", orderByKey: "rank" },
  ];

  // Create an object to return.
  const resultsObject = Object.fromEntries(
    SKILL_ARRAY.map((skill) => [skill.subcollectionName, {}])
  );

  // If queryString is undefined, or empty, return the resultsObject.
  if (!queryString) return resultsObject;

  // At first, split the string into words.
  const terms = queryString.split(" ");

  // Map over each term.
  await Promise.all(
    terms.map(async (term) => {
      // Map over each potential skill collection, i.e. categories, sectors, and stacks.
      await Promise.all(
        SKILL_ARRAY.map(async ({ subcollectionName: subName, orderByKey }) => {
          // Parse the term.
          const parsedTerms = parseTerm(term);

          // Return if the term is invalid.
          if (parsedTerms.length === 0) return;

          // Search for both the exact name, and then the nameIndex.
          let results = await firebase.getDocuments(
            subName,
            true,
            "name",
            "in",
            parsedTerms,
            false,
            orderByKey,
            false,
            true,
            1,
            true
          );
          results = results.length
            ? results
            : await firebase.getDocuments(
                subName,
                true,
                "nameIndex",
                "array-contains-any",
                parsedTerms,
                true,
                orderByKey,
                false,
                true,
                1,
                true
              );

          // Store each document in the resultsObject.
          results.forEach(
            (doc) => (resultsObject[subName][doc.data().name] = doc.data().id)
          );
        })
      );
    })
  );

  // Return the resultsObject.
  return resultsObject;
};

/**
 * This function parses a string, and returns an array of valid search terms.
 * Validity is checked in line with a regex.
 *
 * @param {String} term - The string to parse for terms.
 * @returns {Array<String>} Returns an array of valid search terms.
 */
const parseTerm = (term) => {
  // Lowercase the term.
  term = term.toLocaleLowerCase();

  // Match all letters, numbers, the plus sign, and the full stop.
  const regex = /([ 0-9a-z]+[+.0-9a-z ]*)/g;

  // Find all matches.
  let matches = term.match(regex) ?? [];

  // Remove pure punction matches and pure number matches.
  const purePunctuation = /[a-z]+/;

  // Remove certain words.
  const indexLibrorumProhibitorum = new Set([
    "a",
    "and",
    "i",
    "the",
    "devs",
    "developer",
    "developers",
    "experience",
    "resource",
    "resources",
    "rate",
    "rates",
    "with",
  ]);
  matches = matches.filter(
    (word) =>
      !indexLibrorumProhibitorum.has(word) && word.match(purePunctuation)
  );

  // If there is a first index in matches, also add it, capitalized.
  if (matches.length > 0)
    matches.unshift(
      `${matches[0][0].toLocaleUpperCase()}${matches[0].slice(1)}`
    );

  // Return valid search terms. The "in" operator takes at most an array of length 10.
  return matches.slice(0, 10);
};

// Export all relevant functions.
// module.exports = {
//   parseFreeFormText: parseFreeFormText,
// };
export default parseFreeFormText;
