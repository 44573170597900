import React from "react";

const EndorsIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.47265 0.525721C9.29962 0.814985 8.73655 2.39215 8.22104 4.03053L7.28378 7.00962L6.13017 7.03929C2.03441 7.1449 0.0679007 7.36734 0.00159148 7.73278C-0.042838 7.97671 0.843741 8.8153 2.56689 10.1595C4.01498 11.2892 5.28401 12.2928 5.38693 12.3893C5.48985 12.486 5.10718 14.1094 4.5363 15.9969C3.70017 18.7622 3.55148 19.4954 3.77095 19.772C4.13844 20.2354 4.28356 20.1578 7.33982 17.8603L10 15.8604L12.6602 17.8603C15.7046 20.1487 15.8625 20.2342 16.219 19.7844C16.4255 19.5244 16.2625 18.715 15.4583 16.0044C14.8957 14.1073 14.5173 12.4785 14.6178 12.3844C14.718 12.2904 15.985 11.2892 17.4331 10.1595C19.1563 8.8153 20.0428 7.97671 19.9984 7.73278C19.9321 7.36734 17.9656 7.1449 13.8698 7.03929L12.7162 7.00962L11.779 4.03053C10.8274 1.00588 10.3836 0 10 0C9.88279 0 9.64546 0.236458 9.47265 0.525721Z"
        fill={fill}
      />
    </svg>
  );
};

export default EndorsIcon;
