import React from "react";

const RateIcon = ({ w = "26", h = "16", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8V16H13H26V8V0H13H0V8ZM3.48548 2.71879C3.20864 3.216 2.77639 3.62264 2.52525 3.62264C2.13466 3.62264 2.06818 4.23366 2.06818 7.81434C2.06818 11.9173 2.08325 12.0163 2.78436 12.4854C3.1782 12.7493 3.58623 13.2405 3.69082 13.5768C3.87607 14.1739 4.10032 14.1887 13 14.1887C21.8997 14.1887 22.1239 14.1739 22.3092 13.5768C22.4138 13.2405 22.8218 12.7493 23.2156 12.4854C23.9167 12.0163 23.9318 11.9173 23.9318 7.81434C23.9318 4.23366 23.8653 3.62264 23.4748 3.62264C23.2236 3.62264 22.7914 3.216 22.5145 2.71879L22.0114 1.81525H13H3.98864L3.48548 2.71879ZM11.5328 4.13917C8.87516 5.54083 8.61575 10.0009 11.0952 11.661C12.0478 12.2986 13.9523 12.2986 14.9048 11.661C16.536 10.5688 17.1316 7.75819 16.1439 5.81404C15.1982 3.95291 13.2367 3.24045 11.5328 4.13917ZM11.6873 6.40694C10.6828 7.872 11.497 10.2642 13 10.2642C14.503 10.2642 15.3173 7.872 14.3127 6.40694C13.6964 5.50762 12.3036 5.50762 11.6873 6.40694ZM5.08182 7.00377C4.62593 7.46959 4.62593 8.53042 5.08182 8.99623C5.91057 9.84302 7.38636 9.20513 7.38636 8C7.38636 7.19094 6.84864 6.64151 6.05682 6.64151C5.71557 6.64151 5.27682 6.80453 5.08182 7.00377ZM18.9682 7.00377C18.5123 7.46959 18.5123 8.53042 18.9682 8.99623C19.4241 9.46204 20.4623 9.46204 20.9182 8.99623C21.7469 8.14943 21.1226 6.64151 19.9432 6.64151C19.6019 6.64151 19.1632 6.80453 18.9682 7.00377Z"
        fill={fill}
      />
    </svg>
  );
};

export default RateIcon;
