import { Person } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: ${({ width, size }) => width || size || "100%"};
  height: ${({ height, size }) => height || size || "100%"};
  position: relative;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  background: ${({ bg }) => bg || "transparent"};
  border: ${({ border }) => border || "none"};
  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ objFit }) => objFit || "cover"};
    object-position: ${({ objPosition }) => objPosition || "center"};
    vertical-align: ${({ vAlign }) => vAlign || "middle"};
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: #ccc;
  }
`;

const SpResponsiveImg = ({
  src,
  alt,
  size,
  width,
  height,
  bg,
  borderRadius,
  border,
  boxShadow,
  objFit,
  objPosition,
  vAlign = "unset",
  wrappercls = "",
  imgCls = "",
  sx,
}) => {
  return (
    <ImageWrapper
      bg={bg}
      size={size}
      width={width}
      borderRadius={borderRadius}
      border={border}
      boxShadow={boxShadow}
      height={height}
      objFit={objFit}
      objPosition={objPosition}
      vAlign={vAlign}
      className={wrappercls}
      style={sx}
    >
      {src ? (
        <img src={src} alt={alt} className={`${wrappercls}_img ${imgCls}`} />
      ) : (
        <Person />
      )}
    </ImageWrapper>
  );
};

export default SpResponsiveImg;
