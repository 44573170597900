import React from "react";
import { SampleBase } from "../../sample-base";

export default class InputFiles extends SampleBase {
  render() {
    return (
      <div>
        {this.props.displayMessage}
        <br />
        <div></div>
      </div>
    );
  }
}
