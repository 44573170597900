import React, { useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
// import currencyTemplate from '../../'
import currencyTemplate from "../../../grid_templates/currencyTemplate";
import bulTemplate from "../../../grid_templates/bulTemplates";

function DataPointList({
  datapoints = [],
  dispatch,
  setSelectedData,
  initialState,
  setProtoCounter,
}) {
  // const [datapoints, setDataPoints] = useState([]);
  const editSettings = {
    allowDeleting: true,
    showDeleteConfirmDialog: true,
  };

  const onRowSelected = (args) => {
    setSelectedData(args.data);
    const {
      proto,
      hintText,
      stats,
      min,
      max,
      count,
      wFactor,
      unit,
      nameIndex,
      ...data
    } = args?.data;

    if (Array.isArray(proto)) {
      data["hintText"] = "";
      data["proto"] = "";
      data["hintArray"] = hintText;
      data["protoArray"] = proto;
      setProtoCounter(proto.length);
    } else {
      data["hintText"] = hintText;
      data["proto"] = proto;
      data["hintArray"] = [];
      data["protoArray"] = [];
    }

    dispatch({ type: "EDIT", data: data });
  };

  const onRowDeselected = (args) => {
    setSelectedData(null);
    dispatch({ type: "EDIT", data: initialState });
  };

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const { ref } = args?.data?.[0];
      if (!ref) return;
      ref.delete();
      dispatch({ type: "EDIT", data: initialState });
    }
  };
  return (
    <div className="datapoints_list">
      <GridComponent
        dataSource={datapoints}
        editSettings={editSettings}
        toolbar={["Delete"]}
        actionComplete={actionComplete}
        rowSelected={onRowSelected}
        rowDeselected={onRowDeselected}
        clipMode="EllipsisWithTooltip"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="Key"
            width="80"
          ></ColumnDirective>
          <ColumnDirective
            field="label"
            headerText="Label"
            width="160"
            wrapperText={false}
            allowTextWrap={false}
          ></ColumnDirective>
          <ColumnDirective
            field="desc"
            headerText="Description"
            width="150"
          ></ColumnDirective>

          <ColumnDirective
            field="hintText"
            headerText="Hint Text"
            width="100"
            template={bulTemplate}
            // editType="datepickeredit"
            // format="MMMM y"
            // defaultValue={new Date()}
          ></ColumnDirective>
          <ColumnDirective
            field="count"
            headerText="Count"
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="max"
            headerText="Max"
            format={`"C2"`}
            width="100"
            template={currencyTemplate}
          ></ColumnDirective>
          <ColumnDirective
            field="min"
            headerText="Min"
            template={currencyTemplate}
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="proto"
            headerText="Prototype"
            width="130"
            template={bulTemplate}
          ></ColumnDirective>
          <ColumnDirective
            field="precision"
            headerText="Precision"
            width="80"
          ></ColumnDirective>
          <ColumnDirective
            field="prefix"
            headerText="Prefix"
            width="80"
            align="center"
          ></ColumnDirective>
          <ColumnDirective
            field="type"
            headerText="Type"
            width="80"
            align="center"
          ></ColumnDirective>
          <ColumnDirective
            field="wFactor"
            headerText="Weight Factor"
            width="130"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Toolbar, Edit]} />
      </GridComponent>
    </div>
  );
}

export default DataPointList;
