import React from "react";
import ProjectCard from "./ProjectCard";

export default function DomainsList(props) {
  return (
    <div className="_spTechskills__container-body">
      {props.projects.map((project, index) => (
        <ProjectCard
          key={index}
          project={project}
          firebase={props.firebase}
          devId={props.devId}
          index={index}
          projExp={props.projExp}
          showHiddenCard={props.showHiddenCard}
          useExist={props.useExist}
          setSelectedData={props.setSelectedData}
        />
      ))}
    </div>
  );
}
