import React, { useEffect, useState } from "react";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { GetNowTimeStamp } from "../../../../globals/services";

export default function RateBox(props) {
  const [rates, setRates] = useState({});
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [desc, setDesc] = useState("");
  const [isEmptyRates, setIsEmptyRates] = useState(false);
  const onRatesChanges = (e) => {
    let rateObj = { ...rates };
    rateObj[e.target.id] = e.value;
    setRates(rateObj);
  };

  useEffect(() => {
    let ratesObj = {};
    for (const rate of props.maxRate) {
      ratesObj[`L${rate.level + 1}`] = props.isIntern ? 0 : 0.01;
    }
    setRates(ratesObj);
    if (props.onEdit) {
      const { description, dailyHours, from, to, id, ...selRate } =
        props.selectedRates;
      const mergedObj = { ...ratesObj, ...selRate };
      setRates(mergedObj);

      setDesc(description);
      setDateRange([from, to]);
    }
  }, [props.isIntern, props.maxRate, props.onEdit, props.selectedRates]);

  useEffect(() => {
    setIsEmptyRates(false);
    let greater = Object.values(rates).filter((el) => el > 25);
    let findZero = Object.values(rates).filter((el) => el === 0);
    let isRateless = Object.values(rates).filter((el) => el < 10);
    setError("");
    if (props.isIntern && greater.length > 0) {
      setError(
        "Are You Sure you want save this intern with a rate greater than $25?"
      );
    }
    if (!props.isIntern && isRateless.length > 0) {
      if (findZero.length === props.maxRate.length) {
        setIsEmptyRates(true);
        setError("");
        return;
      }
      setIsEmptyRates(false);
      setError(
        "Are You Sure you want save this resource with a rate less than $10?"
      );
    }
  }, [props.isIntern, rates, props.maxRate]);

  const getSelectedRange = (args) => {
    const { startDate, endDate } = args;
    setDateRange([startDate, endDate]);
  };

  const verifyDev = async () => {
    if (!props?.devData?.userRef) return;
    if (props?.devData?.userRef && props?.devData?.isVerified) return;
    // update Resource data
    let updates = {
      isVerified: true,
      lastUpdated: GetNowTimeStamp(),
    };
    let reportingUpdate = {
      geoTag: props.devData.locationData.geoTag,
    };
    await props.firebase.updateDev(props.devId, updates);
    await props.firebase.db
      .collection("devsReporting")
      .doc(props.devData.devReportingId.id)
      .set(reportingUpdate, { merge: true });
  };

  const onSave = async () => {
    setIsDisabled(true);
    if (!startDate || !endDate) {
      setError("Start and End Date required");
      setIsDisabled(false);
      return;
    }
    if (Object.values(rates).length !== props.maxRate.length) {
      setError("All rates box are required");
      setIsDisabled(false);
      return;
    }

    let data = {
      hourlyRates: rates,
      from: startDate,
      to: endDate,
      dailyHours: props.dailyHours,
      description: desc,
    };
    if (props.onEdit) {
      await props.firebase.db
        .collection("devs")
        .doc(props.devId)
        .collection("rateRanges")
        .doc(props.selectedRates.id)
        .set(data, { merge: true });
    } else {
      await props.firebase.db
        .collection("devs")
        .doc(props.devId)
        .collection("rateRanges")
        .add(data);
      await verifyDev();
    }
    props.onClose();
    setIsDisabled(false);
  };

  return (
    <div className="request">
      <div
        className="request_form column ctr"
        id="data-request_form"
        style={{
          marginTop: "15px",
          padding: "25px 65px",
          top: "50%",
          minWidth: "650px",
          minHeight: "400px",
          justifyContent: "space-between",
        }}
      >
        <div className="request_form-content  column">
          {props.maxRate.map((rate, i) => {
            return (
              <div key={i}>
                <label id="label">L{rate.level + 1}</label>
                <NumericTextBoxComponent
                  id={`L${rate.level + 1}`}
                  min={props.isIntern ? 0 : 0.01}
                  value={rates[`L${rate.level + 1}`] ?? 0}
                  format="C2"
                  onChange={(e) => onRatesChanges(e)}
                  step={0.01}
                />
              </div>
            );
          })}

          <div>
            <label id="label">Start - End</label>
            <DateRangePickerComponent
              startDate={startDate}
              endDate={endDate}
              change={getSelectedRange}
              min={props.lastFrom}
            ></DateRangePickerComponent>
          </div>
          <div>
            <label id="label">Description</label>
            <textarea
              style={{ minHeight: "100px" }}
              id="expboxinput"
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>

          {error && (
            <div style={{ padding: "20px" }}>
              <p style={{ color: "#EB3570" }}>{error}</p>
            </div>
          )}
        </div>

        <div className="request_form-action row">
          {!isEmptyRates && (
            <button
              id="add"
              onClick={() => {
                onSave();
              }}
              disabled={isDisabled}
            >
              {props.onEdit ? "Edit" : "Save"}
            </button>
          )}
          <button
            id="cancel"
            style={{ padding: "5px 10px", fontWeight: "600" }}
            onClick={() => props.onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
