import styled from "styled-components";
export const RateWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);

  flex-wrap: wrap;
  border-bottom: ${({ showborderbtm }) => showborderbtm && "1px solid #DCDCDC"};
  border-top: ${({ showborderTop }) => showborderTop && "1px solid #DCDCDC"};
  padding: ${({ pd }) => pd};
  div:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }
`;
export const SingleRate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1vw;
  p,
  span {
    font-size: 14px;
    font-weight: 400;
  }
  span {
    color: #808080;
  }
`;
export const HourlyRate = styled(SingleRate)`
  p {
    font-size: 16px;
    font-weight: 700;
  }
`;
