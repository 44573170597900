import React from "react";
import { SampleBase } from "../sample-base";
import SingleResume from "./SingleResume";

export default class ImportResume extends SampleBase {
  render() {
    return (
      <SingleResume
        firebase={this.props.firebase}
        vendorId={this.props.vendorId}
        dev={this.props.dev}
        devId={this.props.devId}
        multiple={this.props.multiple}
        stackExp={this.props.stackExp}
        domainExp={this.props.domainExp}
        projExp={this.props.projExp}
        minContractTeamSize={this.props.minContractTeamSize}
      />
    );
  }
}
