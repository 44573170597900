import React from "react";

const EmailIcon = ({ w = "21", h = "18", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.37208 0.379497C0.955142 0.5713 0.479075 1.04115 0.313954 1.42396C-0.0283135 2.21737 -0.65522 1.71233 6.57725 6.46985L10.5236 9.06567L14.47 6.46985C21.8032 1.64619 21.1061 2.22054 20.6809 1.35227C20.4732 0.928186 19.9613 0.468123 19.514 0.303304C18.2975 -0.144325 2.36556 -0.0776565 1.37208 0.379497ZM0 10.0305C0 13.2443 0.1115 16.1587 0.249059 16.5404C0.737154 17.8963 1.46754 18 10.5236 18C19.5987 18 20.3093 17.8981 20.8029 16.5275C20.9543 16.1076 21.0294 13.4912 20.9893 10.0522L20.9219 4.28144L15.9563 7.56934C13.2252 9.37758 10.7804 10.857 10.5231 10.857C10.266 10.857 7.93405 9.45298 5.34098 7.73681C2.74791 6.02063 0.485589 4.52562 0.313203 4.41424C0.0663989 4.25472 0 5.4439 0 10.0305Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmailIcon;
