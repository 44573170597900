import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { AuthUserContext, GlobalVendorContex } from "../../../Firebase";
import { validateEmail } from "../../../globals/helpers";
import {
  addOrUpdateDocFromPath,
  colRef,
  createDocFromCollection,
  docRef,
  fetchSignMethods,
  fire,
  getInvitedUserId,
  getSpryteLinks,
} from "../../../globals/services";
import { SpDialog } from "../../../globals/SpDialog/dialogWrapper/SpDialog";

const SendInviteModal = ({ open, onClose, toast }) => {
  const [newUser, setNewUser] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
    inviteMessage: "",
  });
  const [errors, setErrors] = useState("");

  const otherContext = useContext(GlobalVendorContex);
  const userData = useContext(AuthUserContext);

  const handleChange = (e) => {
    setNewUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors("");
    // e.persist();
  };

  const randomPass = useMemo(() => {
    return Math.random().toString(36).slice(-8);
  }, []);

  // html template
  const htmlTemplate = (showPassword) => {
    const html = `<div class="email-div" style="padding:45px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; box-shadow: 0 1px 2px rgba(0,0,0,0.025), 0 2px 4px rgba(0,0,0,0.025), 0 4px 8px rgba(0,0,0,0.025), 0 8px 16px rgba(0,0,0,0.025), 0 16px 32px rgba(0,0,0,0.025), 0 32px 64px rgba(0,0,0,0.025); border-radius: 10px; color: #838c8f; max-width: 500px; background-color: #ffffff; position: relative; font-weight: 400">
      <h2 style="color:#3ca5f5; font-weight: 700; letter-spacing: -1px;">spryte.</h2>
      <hr style="border: none; background-color: rgba(0, 0, 0, 0.075); height: 2px;">
      <p>Hi <span style="font-weight: 500;">${newUser.first}</span>,</p>
      <p>You've been invited by <span style="font-weight: 500;">${
        userData.user.first
      } ${
      userData.user.last
    }</span> to join the Spryte Partner platform as part of the <span style="font-weight: 500;">${
      otherContext.vendor.companyName
    }</span> Organisation. Your user role is <span style="font-weight: 500;">${"USER"}</span>. ${
      newUser?.inviteMessage
        ? `Please see ${userData.user.first} ${userData.user.last}'s message below:</p>
      <div class="message-box" style="background-color: whitesmoke; padding: 15px; border-radius: 10px;"><p style="font-style: italic;"></p>${newUser.inviteMessage}</div>`
        : ``
    }
      <p>Please click on the following link to setup your account.</p>
      <p>Your username is: <span style="font-weight: 500; font-style: italic;">${
        newUser.email
      }</span></p>
      ${
        showPassword
          ? `<p style=" margin-top: -12.5px;">Your temporary password is: <span style="font-weight: 500; font-style: italic;">${randomPass}</span></p>`
          : ``
      }
      <a href="${getSpryteLinks()}" target="_blank"><button style="border: none; outline: none; color: white; background-color: #3ca5f5; padding: 5px 0; width: 100%; font-size: 16.5px; font-weight: 600; letter-spacing: .25px; border-radius: 5px;">Account Setup</button></a>
      <p>Have any questions? Feel free to <a href="#" style="text-decoration: none; color: inherit;"><span style="color: #3ca5f5;">contact us</span></a>.</p>
      <p>Sincerely,</p>
      <p style="margin-top: -13.5px">The Spryte team</p>
      <hr style="border: none; background-color: rgba(0, 0, 0, 0.075); height: 2px;">
      <p style="font-size: 12px; color: #838c8f98;">Spryte Labs, 295 Madison Ave floor 35, New York, NY 10017</p>
    </div>`;
    return html;
  };

  const roleRef = docRef(
    "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/USER"
  );

  const sendInviteEmail = async (msg, email, showPassword = true) => {
    // invokes html template function
    const html = htmlTemplate(showPassword);

    // mail data object
    const mailData = {
      message: {
        html: html,
        subject: "You've been invited to Spryte Partner Platform",
      },
      inviteMessage: msg, //newUser.inviteMessage,
      to: [email], //[newUser.email],
    };

    // creates a new mail document
    await createDocFromCollection("mail", mailData);
    // notifies user on successful invitation
    toast.success("Invitation sent successfully!");
  };

  const signUpNewUser = async () => {
    let vendorData = {
      permission: [],
      role: roleRef,
      status: true,
      vendorRef: otherContext.vendor.vendorRef,
    };

    const data = {
      first: newUser.first,
      last: newUser.last,
      email: newUser.email,
      phone: newUser.phone,
      orgRef: otherContext.vendor.orgRef,
      addedOn: fire.Timestamp.now(),
    };
    // checks if email exists
    let userEmail = await fetchSignMethods(newUser.email);
    if (userEmail.length !== 0) {
      // query user email
      const existingEmail = await colRef("users")
        .where("email", "==", newUser.email)
        .get();
      // check if user doc exists
      if (existingEmail.docs.length !== 0) {
        // if user doc exists, check if account collection exists
        const existingAccountCol = colRef(
          `users/${existingEmail.docs?.[0]?.id}/accounts`
        );
        if (existingAccountCol) {
          // if account collection exists, check if spryte partner doc exists
          const sprytePartnerDoc = await docRef(
            `users/${existingEmail.docs?.[0]?.id}/accounts/spryte-partner`
          ).get();
          if (sprytePartnerDoc.exists) {
            toast.error(
              "An account is already associated with this email address"
            );
          } else {
            addOrUpdateDocFromPath(
              `users/${existingEmail.docs?.[0]?.id}/accounts/spryte-partner`,
              vendorData
            );
            sendInviteEmail(newUser.inviteMessage, newUser.email, false);
          }
        } else {
          addOrUpdateDocFromPath(
            `users/${existingEmail.docs?.[0]?.id}/accounts/spryte-partner`,
            vendorData
          );
          sendInviteEmail(newUser.inviteMessage, newUser.email, false);
        }
      }
      // else {
      //   addOrUpdateDocFromPath(`users/${existingEmail.docs?.[0]?.id}`, data);
      //   addOrUpdateDocFromPath(
      //     `users/${existingEmail.docs?.[0]?.id}/accounts/spryte-partner`,
      //     vendorData
      //   );
      // }
    } else {
      // signs up new user since it doesn't exist
      // let newUser = await signUpWithEmailPassword(email, randomPass);
      let userData = await getInvitedUserId(newUser.email, randomPass);
      // creates or updates user doc
      await addOrUpdateDocFromPath(`users/${userData?.data}`, data);
      // creates or updates account col and spryte-partner doc
      await addOrUpdateDocFromPath(
        `users/${userData?.data}/accounts/spryte-partner`,
        vendorData
      );
      sendInviteEmail(newUser.inviteMessage, newUser.email, true);
      return newUser;
    }
  };

  const verifyData = () => {
    //   verify email validity
    if (!validateEmail(newUser.email)) {
      setErrors("Email is invalid");
      return false;
    }

    if (newUser.email === "") {
      setErrors("Email is required");
      return false;
    }
    if (newUser.first === "") {
      setErrors("First name is required");
      return false;
    }
    if (newUser.last === "") {
      setErrors("Last name is required");
      return false;
    }
    return true;
  };

  const onInvite = () => {
    if (verifyData()) {
      onClose();
      signUpNewUser();

      setNewUser({
        first: "",
        last: "",
        email: "",
        phone: "",
        inviteMessage: "",
      });
    }
  };
  const onCloseModal = useCallback(() => {
    setNewUser({
      first: "",
      last: "",
      email: "",
      phone: "",
      inviteMessage: "",
    });

    setErrors("");
    onClose();
  }, [onClose]);

  return (
    <SpDialog open={open} locked cls="_spInviteUsers" onClose={onCloseModal}>
      <div className="_spInviteUsers--content">
        <div>
          <h3>Invite a Colleague</h3>
          <p>
            Enter the email address of the person you would like to invite to
            join your team.
          </p>
        </div>
        <div className="sp_col _spInviteUsers--content_form">
          <div>
            <label htmlFor="first">Their first name</label>
            <input
              type="text"
              name="first"
              id="first"
              value={newUser?.first ?? ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="last">Their last name</label>
            <input
              type="text"
              name="last"
              id="last"
              onChange={handleChange}
              value={newUser?.last ?? ""}
            />
          </div>
          <div>
            <label htmlFor="email">Their email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={newUser?.email ?? ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="phone">Their phone</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={newUser?.phone ?? ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="inviteMessage">Invite message</label>
            <textarea
              name="inviteMessage"
              id="inviteMessage"
              cols="30"
              rows="10"
              value={newUser?.inviteMessage ?? ""}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="sp_row _spInviteUsers--content_actions ">
          <button
            className="sp_btn _invitesend"
            disabled={!newUser.email || !newUser.first || !newUser.last}
            onClick={onInvite}
          >
            Send Invite
          </button>
          <button className="sp_btn _inviteclose" onClick={onCloseModal}>
            Cancel
          </button>
        </div>
        <AnimatePresence>
          {errors && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="sp_row _spInviteUsers--content_errors"
            >
              <p>{errors}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </SpDialog>
  );
};

export default SendInviteModal;
