import React, { useContext, useEffect } from "react";
import DeleteComponent from "../../DeleteComponent";
import { MdVerified } from "react-icons/md";
import { AuthUserContext } from "../../../Firebase";
import { colRef } from "../../../globals/services";

const AccountUsers = ({
  deleteUser,
  vendor,
  setPublicChatData,
  setChatBtnClickCount
}) => {
  const userData = useContext(AuthUserContext);
  const [usersList, setUsersList] = React.useState([]);

  useEffect(() => {
    return colRef("accounts", true)
      .where("vendorRef", "==", vendor.vendorRef)
      .onSnapshot((snap) => {
        let usersList = [];

        for (const accountDoc of snap.docs) {
          if (accountDoc.data().status) {
            accountDoc.ref.parent.parent.get().then((userDoc) => {
              let userData = userDoc?.data() ?? {};
              userData["id"] = userDoc?.id;
              usersList.push(userData);
              setUsersList(usersList);
            });
          }
        }
      });
  }, [vendor.vendorRef?.id]);

  // updates publicChatData state var for Oratio package
  const handleChatButtonClick = (user) => {
    setPublicChatData({
      publicChatLink: user?.publicChatLink,
      publicChatEnabled: user?.publicChatEnabled,
      parsedUserId: user?.id
    });

    // increment chatBtnClickCount on every click just so the package could popup on every click
    setChatBtnClickCount((prevCount) => prevCount + 1);
  };

  return (
    <div className="stat_content_data-content">
      <div className="column">
        <div id="fs">
          <table className="table table-responsive-sm account-users">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Live Chat</th>
                {(userData?.userPartnerAccountDoc?.role?.id === "ADMIN" ||
                  userData?.userPartnerAccountDoc?.role?.id === "SUPER-ADMIN" ||
                  userData?.userPartnerAccountDoc?.role?.id ===
                    "SALES-ADMIN") && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="account-users">
              {usersList?.length > 0 &&
                usersList?.map((user, index) => {
                  return (
                    <tr className="users" key={index}>
                      <td>
                        <p
                          style={
                            vendor?.contactRef?.id === user?.id
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row-reverse",
                                  justifyContent: "flex-end",
                                  gap: "5px",
                                }
                              : {}
                          }
                        >
                          {user?.first + " " + user?.last}{" "}
                          {vendor?.contactRef?.id === user?.id ? (
                            <MdVerified
                              size={1.5}
                              className="icon"
                              style={{
                                fill: "#27ae60",
                                opacity: "1",
                                marginLeft: 0,
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </p>
                      </td>
                      <td>
                        <p>{user?.email}</p>
                      </td>
                      <td>
                        <p>{user?.phone}</p>
                      </td>
                      {(user?.publicChatEnabled && user?.id !== userData?.userId) ? (
                        <td>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handleChatButtonClick(user) }
                          >
                            Chat With Me
                          </p>
                        </td>
                        ) : (
                          <td></td>
                        ) 
                      }
                      <td className="user-del-icon">
                        {(userData.userPartnerAccountDoc?.role?.id ===
                          "ADMIN" ||
                          userData.userPartnerAccountDoc?.role?.id ===
                            "SUPER-ADMIN" ||
                          userData.userPartnerAccountDoc?.role?.id ===
                            "SALES-ADMIN") &&
                          vendor?.contactRef?.id !== user?.id && (
                            <DeleteComponent
                              delete={() =>
                                deleteUser(
                                  `users/${user.id}/accounts/spryte-partner`
                                )
                              }
                              id={`users/${user.id}/accounts/spryte-partner`}
                              fileName={user?.first + " " + user?.last}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="bottom light">
          <p> </p>
        </div>
      </div>
    </div>
  );
};

export default AccountUsers;
