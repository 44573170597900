import React from "react";
import { renderDMYLong, renderTimeAgo } from "../../../../globals/helpers";
import MatchStats from "./MatchStats";

const MatchInfo = ({ matchesMetaData }) => {
  return (
    <div className="_sp-matches--matchInfo">
      <div className="_sp-matches--matchInfo-top">
        <h3 className="sp_bold">Match Jobs</h3>
        <p>
          <span className="sp_grey">Matched On </span>{" "}
          <span className="sp_bold addedDate">
            {renderDMYLong(matchesMetaData?.createdOn?.toDate())}
          </span>{" "}
          <span className="sp_light">
            ({renderTimeAgo(matchesMetaData?.createdOn?.toDate())})
          </span>
        </p>
      </div>
      <div className="_sp-matches--matchInfo-infos">
        {Object.keys(matchesMetaData?.dist)?.map((key) => (
          <MatchStats
            title={key?.toUpperCase()}
            y={matchesMetaData?.dist?.[key]?.[0]}
            n={matchesMetaData?.dist?.[key]?.[1]}
            mark={matchesMetaData?.dist?.[key]?.[2]}
          />
        ))}
      </div>
    </div>
  );
};

export default MatchInfo;
