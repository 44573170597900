import React, { useContext } from "react";
import {
  GanttComponent,
  DayMarkers,
  Inject,
  Edit,
  Selection,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-gantt";
import { SampleBase } from "./sample-base";
import { FirebaseContext } from "../Firebase";
import Holidays from "./Holidays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { GlobalVendorContex } from "../Firebase";
import { CircularProgress } from "@mui/material";

class ResourceAllocation extends SampleBase {
  static contextType = GlobalVendorContex;
  constructor() {
    super(...arguments);
    this.state = {
      devs: [],
      avail: [],
      vendorId: "",
      holidays: [],
      companyHolidays: [],
      availLoading: false,
      holiLoading: false,
    };
    this.resourceFields = {
      id: "id",
      name: "first",
    };
    this.taskFields = {
      id: "TaskID",
      name: "Description",
      startDate: "StartDate",
      endDate: "EndDate",
      duration: "Duration",
      // progress: 'Progress',
      resourceInfo: "resources",
      child: "subtasks",
    };

    this.timelineSettings = {
      topTier: {
        unit: "Year",
        format: "MMM dd, y",
      },
      bottomTier: {
        unit: "Week",
      },
    };
    this.gridLines = "Both";
    this.editSettings = {
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      allowTaskbarEditing: false,
    };
    this.toolbar = [
      "ExpandAll",
      "CollapseAll",
      "ZoomIn",
      "ZoomOut",
      "ZoomToFit",
    ];
    this.splitterSettings = {
      columnIndex: 2,
    };
    this.projectStartDate = new Date("03/28/2019");
    this.labelSettings = {
      rightLabel: "Description",
    };

    this.childTaskbarTemplate = this.taskbarTemplate.bind(this);
  }

  //get gantt grid data in the props
  // Change taskbar background color depending on the status value
  taskbarTemplate(props) {
    if (props.Status === "ONGOING") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#2d98da",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "CANCELED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#ff6b6b",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "COMPLETED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#1dd1a1",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "PLANNED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#fdcb6e",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "DRAFT") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#636e72",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#82ccdd",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    }
  }

  getAvail = async () => {
    // const vend = this.vendor;
    this.setState({ availLoading: true, holiLoading: true });

    const vendor = await this.vendor;
    const devs = await this.props.firebase.getDevsForVendor(vendor.id);
    const holidays = await this.props.firebase.getHolidays(vendor.id);

    const avail = [];

    const companyHolidays = [];
    holidays.forEach((holiday) => {
      companyHolidays.push({
        from: holiday.from,
        to: holiday.to,
        label: holiday.description,
      });
    });
    this.setState({ holidays: holidays, holiLoading: false });

    try {
      for (const dev of devs.devs) {
        const tasks = await this.props.firebase.getProjects(dev.id);
        avail.push(...tasks);
      }
    } catch (error) {
      console.log("availabilyty error: ", error);
    }
    this.setState({
      devs: devs,
      avail: avail,
      vendorId: vendor.id,
      availLoading: false,
      companyHolidays: companyHolidays,
    });
  };

  componentDidMount() {
    this.getAvail();
    // this.props.firebase.reportPageVisits(
    //   "Availability Page",
    //   this.props.isSuper,
    //   {
    //     "Vendor Id": this.vendor.id,
    //     "Vendor Name": this.vendor.companyName,
    //   }
    // );
  }

  /**
   * Update states when vendor is update
   */
  componentDidUpdate() {
    if (this.state.vendorId !== this.vendor.id) {
      if (this.vendor.id && this.state.vendorId) {
        this.getAvail();
      }
    }
  }

  actionBegin = (event) => {
    if (event.newTaskData && event.newTaskData.taskName) {
      event.newTaskData.resources.forEach((resource) => {
        this.props.firebase.createTask(resource, event.newTaskData);
      });
    }
  };

  updateHolidaysInAvailGrid = async () => {
    const holidays = await this.props.firebase.getHolidays(this.state.vendorId);
    const companyHolidays = [];
    holidays.forEach((holiday) => {
      companyHolidays.push({
        from: holiday.from,
        to: holiday.to,
        label: holiday.description,
      });
    });
    this.setState({
      holidays: holidays,
      companyHolidays: companyHolidays,
      holiLoading: false,
    });
  };

  render() {
    // to be able to access the venodr data context on every render and on every level
    this.vendor = this.context?.vendor;
    this.updateHolidaysInAvailGrid = this.updateHolidaysInAvailGrid.bind(this);

    return (
      <div className="control-pane avail">
        <div className="resource-header">
          <h3>Team Availability</h3>
          <p>
            Manage holidays and general availability for your entire team here
          </p>
        </div>
        {this.state.holiLoading ? (
          <CircularProgress color="primary" size="large" />
        ) : (
          <div className="grid-div">
            <div className="resources_header">
              <div className="resources_header_left">
                <div className="title">
                  <h3>Holidays</h3>
                  <FontAwesomeIcon icon={faPlane} className="icon" />
                </div>
                <p>Manage the Holidays of your entire team here.</p>
              </div>
            </div>
            <div className="data-grid avail">
              <Holidays
                vendorId={this.state.vendorId}
                firebase={this.props.firebase}
                holidays={this.state.holidays}
                updateHolidaysInAvailGrid={this.updateHolidaysInAvailGrid}
              />
            </div>
          </div>
        )}

        <div className="control-section">
          <div className="availbody">
            {this.state.availLoading ? (
              <CircularProgress color="primary" size="large" />
            ) : (
              <div className="grid-div">
                <div className="resources_header">
                  <div className="resources_header_left">
                    <div className="title">
                      <h3>Availability</h3>
                      <FontAwesomeIcon
                        icon={faCalendarCheck}
                        className="icon"
                      />
                    </div>
                    <p>Manage the availability of your entire team here.</p>
                    <div className="pallette">
                      <div className="colorBox planned"></div>
                      <p>DRAFT</p>
                      <div className="colorBox draft"></div>
                      <p>PLANNED</p>
                      <div className="colorBox booked"></div>
                      <p>BOOKED</p>
                      <div className="colorBox ongoing"></div>
                      <p>ONGOING</p>
                      <div className="colorBox compl"></div>
                      <p>COMPLETED</p>
                      <div className="colorBox cancelled"></div>
                      <p>CANCELLED</p>
                    </div>
                  </div>
                </div>
                <div className="data-grid avail">
                  <GanttComponent
                    id="ResourceView"
                    dataSource={this.state.avail}
                    treeColumnIndex={1}
                    viewType="ResourceView"
                    allowSelection={true}
                    allowResizing={true}
                    highlightWeekends={true}
                    toolbar={this.toolbar}
                    editSettings={this.editSettings}
                    resourceFields={this.resourceFields}
                    taskFields={this.taskFields}
                    labelSettings={this.labelSettings}
                    splitterSettings={this.splitterSettings}
                    height={this.state.avail.length > 10 ? "auto" : "550px"}
                    gridLines={this.gridLines}
                    timelineSettings={this.timelineSettings}
                    resources={this.state.devs.devs}
                    actionBegin={(e) => this.actionBegin(e)}
                    holidays={this.state.companyHolidays}
                    taskbarTemplate={this.childTaskbarTemplate}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field="id"
                        visible={false}
                      ></ColumnDirective>
                      <ColumnDirective
                        field="Description"
                        headerText="Description"
                        width="250"
                      ></ColumnDirective>
                      {/* <ColumnDirective field='Progress'></ColumnDirective> */}
                      <ColumnDirective field="StartDate"></ColumnDirective>
                      <ColumnDirective field="EndDate"></ColumnDirective>
                      <ColumnDirective field="Duration"></ColumnDirective>
                      <ColumnDirective
                        field="Status"
                        headerText="Sprint Status"
                      ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Selection, DayMarkers, Toolbar, Edit]} />
                  </GanttComponent>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default function FirebaseResources() {
  const firebase = useContext(FirebaseContext);
  return <ResourceAllocation firebase={firebase} />;
}
