import React from "react";
import useSortableData from "../../SortingData.js/Sortable";
// import ClientCol from "./Requirement_info/ClientCol";
import { MdStar } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faCheckCircle,
  faBan,
  faHourglassHalf,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import SingleRow from "./AllRequirement/SingleRow";
import ReqTable from "../../globals/ReqTable/ReqTable";
import RequirementHead from "./RequirementHead";
import { ReqTableBody } from "../../globals/ReqTable/style";
import SingleReqBox from "./AllRequirement/SingleReqBox";
import { SingleBed } from "@mui/icons-material";

const AllRequirements = (props) => {
  // const { items, props.requestSort } = useSortableData(props.currentList);

  const buildLocations = (locations) => {
    let el = [];
    for (let i = 0; i < locations?.length ?? 0; i++) {
      if (i < locations.length - 1) {
        el.push(`${locations[i]}, `);
      } else {
        el.push(`${locations[i]} `);
      }
    }
    return (
      <div className="row">
        <span
          style={{
            fontWeight: 600,
            fontStyle: !el.length > 0 ? "italic" : "inherit",
            opacity: !el.length > 0 ? ".35" : "inherit",
          }}
        >
          {el.length > 0 ? el.slice(0, 10) : "No Location"}
        </span>
      </div>
    );
  };
  const buildScores = (scoreAvg) => {
    let el = [];
    for (let i = 0; i < 4; i++) {
      el.push(
        <MdStar key={i} size={22} fill={i < scoreAvg ? "#4361E9" : "#CACCCD"} />
      );
    }
    return (
      <div className="row req-client-score" style={{ alignItems: "center" }}>
        <span>{el}</span>
      </div>
    );
  };
  const buildTags = (tags) => {
    let el = [];
    if (!tags?.length) {
      el.push(<span className="_spreq-locationEmpty">No Tags</span>);
    }
    for (let i = 0; i < tags?.length ?? 0; i++) {
      if (typeof tags[i] === "string") {
        el.push(
          <span key={i} className="_spreq-tags_tag">
            {tags[i]}
          </span>
        );
      } else {
        el.push(
          <span
            key={i}
            className="_spreq-tags_tag"
            style={{
              "--reqtagbg": tags[i]?.color ?? "#4361E9",
              "--reqtagcol": "white",
            }}
          >
            {tags[i].label}
          </span>
        );
      }
    }
    return <div className="_spreq-tags">{el}</div>;
  };
  const renderStatus = (statusId) => {
    let tag = statusId?.toUpperCase();
    let icon;
    switch (statusId?.toLowerCase()) {
      case "upcoming":
        icon = faHourglassHalf;
        break;
      case "paused":
        icon = faPause;
        break;

      case "completed":
        icon = faCheckCircle;
        break;
      case "in-progress":
        icon = faSpinner;
        break;
      default:
        icon = faBan;
    }

    return (
      <td className="sp-selectable" id="tdTT" style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={icon} className="icon" />
        <div className="tdTT-content">
          <p>{tag}</p>
        </div>
      </td>
    );
  };

  return (
    <div className="requirements_all">
      <ReqTable>
        <RequirementHead requestSort={props.requestSort} />
        {!props.isLoading && props?.allItems?.length < 1 ? (
          <p
            style={{ color: "#EB3570", fontSize: "1.4rem", fontWeight: "600" }}
          >
            There are currently no open requirements
          </p>
        ) : (
          <ReqTableBody>
            {props.items?.map((item, index) => (
              <SingleReqBox
                key={index}
                renderReqs={props.renderReqs}
                isSuperPartner={props.isSuperPartner}
                buildLocations={buildLocations}
                lastTerm={props.lastTerm}
                list={item}
                index={index}
                renderStatus={renderStatus}
                vendor={props.vendor}
                buildScores={buildScores}
                buildTags={buildTags}
              />
            ))}
          </ReqTableBody>
        )}
      </ReqTable>
    </div>
  );
};

export default AllRequirements;
