import styled from "styled-components";

export const ExcerptWrapper = styled.div`
  display: flex;
  //   line-height: 28px;
  flex-wrap: wrap;
`;

export const FullContent = styled.p`
  color: ${({ tcolor }) => tcolor && tcolor} !important;
  font-weight: ${({ fw }) => fw && fw};
  size: ${({ tsize }) => tsize && tsize};
`;

export const ContentExcerpt = styled(FullContent)`
  display: -webkit-box;

  -webkit-line-clamp: ${({ lineClamp }) => lineClamp && lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ShowMore = styled.button`
  background-color: transparent;
  color: #ff223d;
  font-weight: 600;
  padding: 0;
  border: none;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SideBtn = styled.button`
  background-color: transparent;
  color: #4361e9;
  font-weight: 600;
  padding: 0;
  border: none;
`;
