import React, { useEffect, useState, useContext } from "react";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Page,
  Edit,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import AddTemplate from "./pages/Branding/AddTemplate";
import { AuthUserContext, GlobalVendorContex } from "./Firebase";
import CaseStudySection from "./pages/Branding/CaseStudySection";
import ClientsLogoSection from "./pages/Branding/ClientsLogoSection";
import { Animated } from "react-animated-css";
import { caseStudyRatingType } from "./globals/services";
import SpRatingComponent from "./globals/SpRating/SpRating";
import { useCategories, useDomains, useStacks } from "./hooks/useTechs";

//////////////////////////////////////////////////
///////
///////
const Branding = (props) => {
  // User data
  const allData = useContext(AuthUserContext);

  // Vendor Data
  const vendorData = useContext(GlobalVendorContex);

  const { stacks: allStacks } = useStacks(100, 50);
  const { domains: allDomains } = useDomains(100, 12);

  const { categories: allCategories } = useCategories(200, 12);

  // wether a vendor is super Admin or not
  const isSuper = allData.isSuperPartner;

  // settings for the grid
  const editSettings = {
    allowDeleting: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
  };

  // check if the add button is clicked
  const [isAdd, setIsAdd] = useState(false);

  // holds the list stacks for a specific case Study
  const [stackOption, setStackOption] = useState([]);

  // holds the list domains for a specific case Study
  const [domainsOption, setDomainOption] = useState([]);

  // holds the list projects for a specific case Study
  const [projOption, setProjOption] = useState([]);

  // // holds the list of external case study for the current vendor
  const [external, setExternal] = useState([]);

  //  holds the list of internal case study for the current vendor
  const [internals, setInternals] = useState([]);

  // hold a list of the selected case study data
  const [selectedData, setSelectedData] = useState(null);

  // checks if the edit button is clicked
  const [isEdit, setIsEdit] = useState(false);

  // checks if it should show the status
  const [showStatus, setShowStatus] = useState(true);

  // return the selected status data
  const [selectedStatus, setSelectedStatus] = useState({});

  const [showMain, setShowMain] = useState(true);

  const [visible, setVisible] = useState(true);

  const [allowEdit, setAllowEdit] = useState(true);

  /**
   * Takes a list of references and convert it to an object containing value and label
   * @param {Array} refsList list of references
   * @returns  array of objects
   */
  const getRefDocs = (refsList) => {
    const docs = refsList.map(async (ref) => {
      let prom = await ref.get();
      let values = {
        value: prom.data()?.name ?? prom.data()?.title ?? "",
        label: prom.data()?.name ?? prom.data()?.title ?? "",
        ref: prom.ref,
      };
      return values;
    });
    return Promise.all(docs);
  };

  /**
   *
   * @param {Array} refsList
   * @returns array of objects
   */
  const getIdLabel = (refsList) => {
    const docs = refsList.map(async (ref) => {
      let prom = await ref.get();
      let values;
      if (prom.data()) {
        values = {
          value: prom.id,
          label: prom.data().fileName,
        };
        return values;
      }
    });
    return Promise.all(docs);
  };

  /**
   * checks if their is images it will delete the images from storage first and delete
   * the data from firestore
   * @param {object} args
   */
  const actionComplet = async (args) => {
    if (args.requestType === "delete") {
      const data = args.data[0];
      let storage = props.firebase.getStorage();

      let avatarRef;

      let mainRef;
      // Check if we have an avatar image
      if (data.mainPhoto.name) {
        mainRef = await storage.ref(
          `vendors/${vendorData?.vendor.id}/caseStudiesImages/${data.mainPhoto.name}`
        );
      }

      // Check if we have an main Photo
      if (data.avatarPhoto.name) {
        avatarRef = await storage.ref(
          `vendors/${vendorData?.vendor.id}/caseStudiesImages/${data.avatarPhoto.name}`
        );
      }
      // Check if the media slider is not empty
      if (data.mediaSlider.length) {
        // eslint-disable-next-line array-callback-return
        const deletedMedia = data.mediaSlider.map((media) => {
          const fileRef = storage.ref(
            `vendors/${vendorData?.vendor.id}/caseStudiesImages/${media.name}`
          );
          if (fileRef) {
            fileRef.delete();
            return fileRef;
          }
        });
        await Promise.all(deletedMedia);

        if (avatarRef) {
          avatarRef.delete();
        }
        if (mainRef) {
          mainRef.delete();
        }

        await props.firebase.deleteCaseStudy(vendorData?.vendor.id, data.Id);
      } else {
        if (avatarRef) {
          avatarRef.delete();
        }
        if (mainRef) {
          mainRef.delete();
        }

        await props.firebase.deleteCaseStudy(vendorData?.vendor.id, data.Id);
      }
    }
  };

  /**
   * when a row is selected it will get the selected row data
   * and check if the data.status is not ready to submit if it's not a super admin it will set
   * the show status input to false
   * @param {object} args
   */
  const onRowSelect = async (args) => {
    let data = args.data;
    let newData = {
      ...data,
    };
    newData.domains = await getRefDocs(newData.domains);
    newData.stacks = await getRefDocs(newData.stacks);
    newData.projects = await getRefDocs(newData.projects);
    newData.externalRef = await getIdLabel(newData.externalRef);

    setSelectedStatus({
      value: newData.status,
      label: newData.status,
    });
    setSelectedData(newData);

    if (!isSuper && newData.status === "READY-TO-SUBMIT") {
      setShowStatus(false);
    } else if (
      !isSuper &&
      (newData.status === "FEATURED" || newData.status === "REVIEWED")
    ) {
      setAllowEdit(false);
      setShowStatus(false);
    } else {
      setAllowEdit(true);
      setShowStatus(true);
    }
  };

  /**
   * When a row is deselected it will set back the selected data to null
   * and set back the show status to true
   */
  const onRowDeselect = () => {
    setSelectedData(null);
    setShowStatus(true);
  };

  /**
   * Stream the case study for any changes
   */
  useEffect(() => {
    const ref = props.firebase.db
      .collection("vendors")
      .doc(vendorData?.vendor.id)
      .collection("caseStudies")
      .onSnapshot((snapshot) => {
        let internalCases = [];
        if (snapshot.size) {
          snapshot.forEach(async (internal) => {
            let data = internal.data();

            if (data.internal) {
              data.Id = internal.id;
              data.ref = internal.ref;
              internalCases.push(data);
            }
          });
          setInternals(internalCases);
          setSelectedData(null);
        } else {
          setInternals([]);
        }
      });
    // props.firebase.reportPageVisits("Branding Page", allData.isSuperPartner, {
    //   "Vendor Id": vendorData.vendor.id,
    //   "Vendor Name": vendorData.vendor.companyName,
    // });
    return () => {
      ref();
    };
  }, [props.firebase, vendorData?.vendor.id]);

  /**
   * Gets all static data once we are on the branding page
   */
  useEffect(() => {
    const getData = async () => {
      let stackOption = [];
      allStacks.map((stack) => {
        return stackOption.push({
          value: stack.title,
          label: stack.title,
          ref: stack.ref,
        });
      });
      let domainOption = [];
      allDomains.map((domain) => {
        return domainOption.push({
          ...domain,
          value: domain.name,
          label: domain.name,
          ref: domain.ref,
        });
      });

      let projectTypes = [];
      allCategories.map((category) => {
        return projectTypes.push({
          ...category,
          value: category.name,
          label: category.name,
          ref: category.ref,
        });
      });
      setStackOption(stackOption);
      setDomainOption(domainOption);
      setProjOption(projectTypes);
    };
    getData();
  }, [allStacks, allDomains, allCategories]);

  /**
   * Changing the visibility wether the add template is vible or not
   *
   */
  useEffect(() => {
    if (!showMain) setTimeout(() => setVisible(false), 650);
    else setTimeout(() => setVisible(true), 650);
  }, [showMain]);

  return (
    <div className="brand" style={{ paddingBottom: "50px" }}>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutRight"
        isVisible={!showMain}
        style={!visible ? null : { display: "none" }}
      >
        <AddTemplate
          isAdd={isAdd}
          setIsAdd={setIsAdd}
          stackOption={stackOption}
          stacksObject={vendorData?.stackData?.stackObject}
          domainsOption={domainsOption}
          domainObject={vendorData?.domainData?.domainsObject}
          projOption={projOption}
          projectObject={vendorData?.projectsData?.projectsObject}
          external={external}
          firebase={props.firebase}
          vendorId={vendorData?.vendor?.id}
          selectedData={selectedData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isSuper={isSuper}
          showStatus={showStatus}
          setShowMain={setShowMain}
          selectedStatus={selectedStatus}
          setSelectedData={setSelectedData}
        />
      </Animated>
      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutLeft"
        isVisible={showMain}
        style={visible ? null : { display: "none" }}
      >
        <div className="resource-header">
          <div className="header-intro">
            <h3>Featured Account Case Studies</h3>
            <p className="lighter">
              These approved structured case studies are displayed on
              sprytelabs.com and our client systems, and link pricing and search
              results directly to your teams. This improves your conversion
              rates.
            </p>
          </div>
        </div>
        <div className="brand_body control-section">
          <div className="brand_body-btn" style={{ alignItems: "center" }}>
            <button
              className="spbtn spbtn-blackbkg"
              onClick={() => {
                setSelectedData(null);
                setIsAdd(true);
                setShowMain(false);
              }}
              style={{ margin: "10px 0" }}
            >
              Add a Case Study
            </button>
            {selectedData && allowEdit ? (
              <button
                className="spbtn spbtn-blackbkg"
                style={{ height: "30px" }}
                onClick={() => {
                  setIsEdit(true);
                  setShowMain(false);
                }}
              >
                Edit
              </button>
            ) : selectedData && !allowEdit ? (
              <p
                style={{
                  color: "red",
                  fontWeight: "500",
                  opacity: ".65",
                  fontStyle: "italic",
                  paddingTop: "10px",
                }}
              >
                Unable to edit please contact stephane@sprytelab.com
              </p>
            ) : (
              <></>
            )}
            {selectedData && isSuper && (
              <SpRatingComponent
                dataInput={{
                  ref: selectedData?.ref,
                  rating: selectedData?.rating ?? 0,
                  isAdmin: isSuper,
                  typeRef: caseStudyRatingType(),
                }}
              />
            )}
          </div>
          {internals.length ? (
            <div className="brand_body-grid">
              <GridComponent
                dataSource={internals}
                allowSelection={true}
                toolbar={["Delete"]}
                allowSorting={true}
                allowPaging={true}
                editSettings={editSettings}
                pageSettings={{ pageSize: 10 }}
                height="295px"
                allowPdfExport={true}
                rowSelected={onRowSelect}
                actionComplete={actionComplet}
                rowDeselected={onRowDeselect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="120"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="title"
                    headerText="Title"
                    textAlign="Center"
                    width="120"
                  />
                  <ColumnDirective
                    field="clientName"
                    headerText="Customer Name"
                    editType="numericedit"
                    format="n0"
                    width="180"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="status"
                    headerText="Status"
                    editType="numericedit"
                    format="n0"
                    width="180"
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject services={[Selection, Page, Toolbar, Edit]} />
              </GridComponent>
            </div>
          ) : (
            <div className="brand_body-grid" style={{ display: "none" }}>
              <GridComponent
                dataSource={[]}
                allowSelection={true}
                toolbar={["Delete"]}
                allowSorting={true}
                allowPaging={true}
                editSettings={editSettings}
                pageSettings={{ pageSize: 10 }}
                height="295px"
                allowPdfExport={true}
                rowSelected={onRowSelect}
                actionComplete={actionComplet}
                rowDeselected={onRowDeselect}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText="ID"
                    width="120"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="title"
                    headerText="Title"
                    textAlign="Center"
                    width="120"
                  />
                  <ColumnDirective
                    field="clientName"
                    headerText="Customer Name"
                    editType="numericedit"
                    format="n0"
                    width="180"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="status"
                    headerText="Status"
                    editType="numericedit"
                    format="n0"
                    width="180"
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject services={[Selection, Page, Toolbar, Edit]} />
              </GridComponent>
            </div>
          )}
          <CaseStudySection
            vendorId={vendorData?.vendor.id}
            firebase={props.firebase}
            setExternal={setExternal}
          />
          <ClientsLogoSection
            vendorId={vendorData?.vendor.id}
            firebase={props.firebase}
          />
        </div>
      </Animated>
    </div>
  );
};

export default Branding;
