import SpryteTooltip from "../../SpryteTooltip.js";

const RateCards = (props) => {
  /**
   * When a vcendor click on the edit button the function will set
   * the selected data state to the actual selected rate card
   * @param {object} price Object containg the selected rate card data to be edited
   */
  const onEdit = async (price) => {
    let teamData = []; // array containing objects of the location with the list of resources
    for (let i = 0; i < price.locations.length; i++) {
      teamData[i] = {
        country: price.locations[i].country,
        data: [], //array of resources
      };

      for (let pricingMatrix of price.pricingMatrix[i]) {
        let position = await pricingMatrix.position.get();
        let data = {
          position: position.data().label,
          positionRef: pricingMatrix.position,
          volume: pricingMatrix.volume,
          price: pricingMatrix.price,
        };
        teamData[i].data.push(data);
      }
    }
    props.setTeams(teamData);
    props.setSelectedData(price);
    props.setIsPriceEdit(true);
  };
  /**
   * Triggers when a vendor click on a check box of a given rate card
   * @param {event} e the check input event
   * @param {object} priceData the selected rate to be applied
   * @param {number} i index of the selected rate
   */
  const onChecked = (e, priceData, i) => {
    if (props.isApply) {
      let rates = [...props.selectedRates];
      let checks = [...props.checkedList];

      if (e.target.checked) {
        rates.push(priceData);
        props.setSelectedRates(rates);
        checks[i] = true;
      } else {
        let removed = rates.filter((el) => el.id !== priceData.id);
        checks[i] = false;
        props.setSelectedRates(removed);
      }
      props.setCheckedList(checks);
    }
    e.persist();
  };

  return (
    <div className="markets_content-yourPrice">
      <div className="markets_content-yourPrice__head row">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Rate Cards</h3>
          <SpryteTooltip desc="Rate cards define your pricing for a certain group of developers, defined by region and/or positions. You can have multiple rate cards." />
          {/* <p className="small">View & edit your pricing definitions</p> */}
        </div>
        <div>
          {!props.isApply ? (
            <button
              className="spbtn spbtn-blackbkg"
              onClick={() => {
                props.setIsAddRate(true);
              }}
            >
              Add A Rate Card
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="markets_content-yourPrice__prices prices_table">
        <div className="row prices_table-header">
          {props.isApply ? (
            <div className="checkbox priceCard_header">
              <></>
            </div>
          ) : (
            <></>
          )}
          <div className="cardName priceCard_header">
            <h4>Rate Card Name</h4>
          </div>
          <div className="locations priceCard_header">
            <h4>Locations</h4>
          </div>
          <div className="resources priceCard_header">
            <h4>resources</h4>
          </div>
          <div className="status priceCard_header">
            <h4>Status</h4>
          </div>
          <div className="rates priceCard_header">
            <h4>Rates</h4>
          </div>
          {!props.isApply ? (
            <div className="rates priceCard_header">
              <h4>Actions</h4>
            </div>
          ) : (
            <></>
          )}
          <div className="edit priceCard_header"></div>
        </div>

        {props.rateCards.map((price, i) => {
          // let checked = isChecked(price, i);
          return (
            <div key={i} className="row" id="border">
              {props.isApply ? (
                <div className="checkbox priceCard_header">
                  <input
                    type="checkbox"
                    checked={props.checkedList[i]}
                    onChange={(e) => {
                      onChecked(e, price, i);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="cardName cardName_el">{price.name}</div>
              <div className="locations locations_el">
                <p>{price.locations.length}</p>
              </div>
              <div className="resources resources_el">
                <p>{price?.stats?.variants ?? 10 + i}</p>
              </div>
              <div
                className="status status_el"
                style={{
                  backgroundColor:
                    price.status === "Active" ? "#20975b2c" : "#e70e021c",
                }}
              >
                <p
                  style={{
                    color: price.status === "Active" ? "#20975C" : "#E70E02",
                  }}
                >
                  {price.status}
                </p>
              </div>
              <div className="rates rates_el">
                <p>
                  {price.stats
                    ? `$${price?.stats?.minPrice} - $${price?.stats?.maxPrice}/hr`
                    : "$0 - $0/hr"}
                </p>
              </div>
              <div className="edit edit_el">
                <div className="edit edit_el">
                  {!props.isApply ? (
                    <button
                      onClick={() => {
                        onEdit(price);
                      }}
                      style={{
                        marginRight: "5px",
                        borderRadius: "3.5px",
                        color: "grey",
                        backgroundColor: "rgba(128, 128, 128, 0.15)",
                        border: "none",
                        padding: "0 7.5px",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Edit{" "}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p className="length">
        <span>{props?.rateCards?.length}</span> results
      </p>
    </div>
  );
};

export default RateCards;
