import styled from "styled-components";

export const ClientsWrapper = styled.div`
  background-color: ${({ bg }) => bg};
  padding: ${({ pd }) => pd};
  h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
export const ClientsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ space }) => space};
  align-items: center;
`;
export const ClientLogoWrapper = styled.div`
  height: ${({ height }) => height};
  // max-width: 120px;
  // width: 50%;
  min-width: ${({ height }) => height};
  overflow: hidden;
  position: relative;

  & > span {
    position: unset !important;
    & > img {
      width: 40% !important;
      position: relative !important;
      height: ${({ height }) => height} !important;
      min-width: 100% !important;
      max-width: 100% !important;
      // width: unset;
      min-height: ${({ height }) => height} !important;
    }
  }
`;
