import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
// import { BrowserRouter } from "react-router-dom";
import {
  GlobalVendorProvider,
  withAuthUser,
  AuthUserContext,
} from "./components/Firebase";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import NavBar from "./components/NavBar.js";
import Router from "./components/Router";
import Header from "./components/pages/Header";
import { BrowserRouter } from "react-router-dom";
import { analytics } from "./components/Firebase/firebase";
import SpryteConnections from "@sprytelabs/oratio";
import "@sprytelabs/oratio/src/styles/_style.scss";
import Hotjar from "@hotjar/browser";
import TagManager from "react-gtm-module";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1E96F5",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

function App() {
  const [isDev, setIsDev] = useState(false);
  const [isDevSelected, setIsDSelected] = useState(false);
  const [showDevData, setShowDevData] = useState(false);
  const [devPage, setDevPage] = useState("dev");
  const [isAllDevs, setIsAllDev] = useState(false);
  const [isPartnerDevs, setIsPartnerDevs] = useState(false);
  const [isVerifiedResource, setIsVerifiedResource] = useState(false);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [isOutSideBtnClicked, setIsOutSideBtnClicked] = useState(false);
  const [chatBtnClickCount, setChatBtnClickCount] = useState(0);
  const [techStackData, setTechStackData] = useState(null);
  const [publicChatData, setPublicChatData] = useState({
    publicChatLink: "",
    publicChatEnabled: false,
    parsedUserId: "",
  });
  const auth = useContext(AuthUserContext);

  // firebase app
  let app = firebase.app();
  // firebase auth
  const authentication = app.auth();
  // firestore db
  const db = app.firestore();

  const tagManagerArgs = {
    gtmId: "GTM-5PM6NVJ",
  };
  // HOTJAR
  const siteId = 3868985;
  const hotjarVersion = 6;

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    console.log("Tagmanager ok");
    analytics.page();
    console.log("Analytics ok");
    Hotjar.init(siteId, hotjarVersion);
    console.log("Hotjar ok");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalVendorProvider>
          {!isPartnerDevs ? (
            <div className="sideBar">
              <NavBar
                color="primary"
                setIsDev={setIsDev}
                showDevData={showDevData}
                setShowDevData={setShowDevData}
                isDevSelected={isDevSelected}
                setIsDSelected={setIsDSelected}
                setDevPage={setDevPage}
                isPartnerDevs={isPartnerDevs}
                isVerifiedResource={isVerifiedResource}
                isAllDevs={isAllDevs}
                setIsAllDev={setIsAllDev}
                toggleNavbar={toggleNavbar}
                setToggleNavbar={setToggleNavbar}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="content">
            {auth.auth ? (
              <div className="topBar">
                <Header />
              </div>
            ) : (
              <></>
            )}
            <div className={toggleNavbar ? "main-collapse main" : "main"}>
              <Router
                className="container"
                showDevData={showDevData}
                setShowDevData={setShowDevData}
                isDev={isDev}
                isDevSelected={isDevSelected}
                setIsDSelected={setIsDSelected}
                devPage={devPage}
                setDevPage={setDevPage}
                setIsPartnerDevs={setIsPartnerDevs}
                isPartnerDevs={isPartnerDevs}
                setIsVerifiedResource={setIsVerifiedResource}
                isVerifiedResource={isVerifiedResource}
                isAllDevs={isAllDevs}
                setIsAllDev={setIsAllDev}
                setPublicChatData={setPublicChatData}
                setChatBtnClickCount={setChatBtnClickCount}
                setIsOutSideBtnClicked={setIsOutSideBtnClicked}
                setTechStackData={setTechStackData}
                setIsDev={setIsDev}
              />
            </div>
            {auth.auth?.uid && (
              <div className="oratio_partner_platform">
                <SpryteConnections
                  firebase={firebase}
                  isLoggedIn={auth.auth?.uid ? true : false}
                  auth={authentication}
                  db={db}
                  publicChatData={publicChatData}
                  chatBtnClickCount={chatBtnClickCount}
                  isOutSideBtnClicked={isOutSideBtnClicked}
                  techStackData={techStackData}
                />
              </div>
            )}
          </div>
        </GlobalVendorProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withAuthUser(App);
