import React, { useState } from "react";
import { motion } from "framer-motion";
import { Search, ExpandMore } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import PropTypes from "prop-types";

const TechSearch = ({
  onSearch,
  searchResults = [],
  placeholder = "Python",
  title = "Add your skills",
  resultsPerPage = 10, // Number of results per page
  onItemSelect,
  data = [],
  props,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(false);

  const handleSearch = (e) => {
    try {
      const value = e.target.value;
      setSearchValue(value);
      onSearch?.(value);
      setCurrentPage(1); // Reset to the first page on a new search
      setShowResults(true);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleExpand = () => {
    setExpanded(!expanded);
    // setShowResults(!showResults);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (!Array.isArray(searchResults)) {
    console.error("searchResults should be an array");
    return null;
  }

  const totalPages = Math.ceil(
    searchResults.length / (expanded ? 27 : resultsPerPage)
  );

  const showSeeMore = searchResults.length > resultsPerPage;

  // Calculate the results to display based on the current page
  const startIndex = (currentPage - 1) * (expanded ? 27 : resultsPerPage);
  const paginatedResults = searchResults.slice(
    startIndex,
    startIndex + (expanded ? 27 : resultsPerPage)
  );

  return (
    <div
      style={{
        position: "relative",
        height: "87px",
      }}
      {...props}
    >
      <motion.div
        animate={{
          width: expanded ? "60vw" : "auto",
          boxShadow: showResults ? "0 10px 20px rgba(0, 0, 0, 0.2)" : "none",
        }}
        className={`techSearchBox ${expanded ? "expanded" : ""}`}
      >
        <div className="techSearchBox__visible">
          <div className="techSearchBox__top">
            <h3>{title}</h3>
            <motion.div
              animate={{ rotate: showResults ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ExpandMore
                className="expandIcon"
                onClick={() => {
                  setShowResults(!showResults);
                  setExpanded(false);
                }}
              />
            </motion.div>
          </div>
          <div className="techSearchBox__search">
            <input
              type="text"
              value={searchValue}
              onChange={handleSearch}
              placeholder={placeholder}
            />
            <Search className="searchIcon" />
          </div>
        </div>
        <motion.div
          className="techSearchBox__resultsBox"
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: showResults ? 1 : 0,
            height: showResults ? "auto" : 0,
            display: showResults ? "flex" : "none",
          }}
          transition={{ duration: 0.5 }}
        >
          <div>
            {paginatedResults.length > 0 ? (
              <div className="techSearchBox__results">
                {paginatedResults.map((result, index) => {
                  const exist =
                    data?.filter(
                      (el) =>
                        (el?.techRef || el?.projectRef || el?.domainRef)?.id ===
                        result?.ref?.id
                    )?.length > 0;

                  return (
                    <div
                      key={index}
                      className="techSearchBox__result"
                      onClick={() => (!exist ? onItemSelect?.(result) : null)}
                      style={{
                        cursor: exist ? "not-allowed" : "pointer",
                        backgroundColor: exist
                          ? "rgba(55, 0, 179, 0.1)"
                          : "white",
                      }}
                    >
                      <img src={result.logoStorage} alt={result.name} />
                      <span>{result.name}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="techSearchBox__noResults">
                <p>No results found</p>
              </div>
            )}
          </div>
          <div className="techSearchBox__footer">
            {expanded && paginatedResults.length > 0 && (
              <div className="techSearchBox__pagination">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
            {showSeeMore && (
              <button className="seeMoreBtn" onClick={handleExpand}>
                {expanded ? "See Less" : "See More"}
              </button>
            )}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

TechSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  resultsPerPage: PropTypes.number,
};

export default TechSearch;
