import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Page,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { SampleBase } from "../sample-base";
import { ToastContainer, toast } from "react-toastify";

export default class Holidays extends SampleBase {
  constructor() {
    super(...arguments);
    this.toolbarOptions = ["Add", "Edit", "Delete"];
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: "Dialog",
    };
    this.editparams = { params: { popupHeight: "300px" } };
    this.validationRule = { required: true };
    this.orderidRules = { required: true, number: true };
    this.pageSettings = { pageCount: 5 };
  }

  async actionComplete(args) {
    const request = args.requestType;
    // adjust dialog width and title
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.width = 500;
      // change the header of the dialog
      dialog.header =
        args.requestType === "beginEdit"
          ? `Edit ${args.rowData.tech} Details`
          : "Add Stack Details";
    }
    if (args.action === "add") {
      const data = args.data;
      if (data.to > data.from) {
        await this.props.firebase.createHoliday(this.props.vendorId, data);
        await this.props.updateHolidaysInAvailGrid();
      } else {
        await this.props.updateHolidaysInAvailGrid();
        toast.error("Invalid Date. Please Try Again.");
      }
    }
    if (args.action === "edit") {
      const data = args.data;
      const { Id, ...updates } = data;
      await this.props.firebase.updateHoliday(this.props.vendorId, Id, updates);
      await this.props.updateHolidaysInAvailGrid();
    }
    if (request === "delete") {
      const data = args.data[0];
      const { Id } = data;
      await this.props.firebase.deleteHoliday(this.props.vendorId, Id);
      await this.props.updateHolidaysInAvailGrid();
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <div className="control-section">
          <GridComponent
            dataSource={this.props.holidays}
            pageSettings={this.pageSettings}
            toolbar={this.toolbarOptions}
            allowPaging={true}
            editSettings={this.editSettings}
            // beforeBatchSave={this.props.batch}
            actionComplete={this.actionComplete.bind(this)}
          >
            <ColumnsDirective>
              {/* <ColumnDirective field='id' headerText='ID' width='130' textAlign='Center' validationRules={this.orderidRules} isPrimaryKey={true} visible={false}></ColumnDirective> */}
              <ColumnDirective
                field="from"
                textAlign="Center"
                headerText="From"
                width="130"
                editType="datepickeredit"
                format="yMd"
                validationRules={this.validationRule}
              ></ColumnDirective>
              <ColumnDirective
                field="to"
                headerText="To"
                width="130"
                textAlign="Center"
                editType="datepickeredit"
                format="yMd"
                validationRules={this.validationRule}
              ></ColumnDirective>
              <ColumnDirective
                field="description"
                headerText="Description"
                width="130"
                validationRules={this.validationRule}
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit]} />
          </GridComponent>
        </div>
      </div>
    );
  }
}
