import React from "react";

const DeleteIcon = ({ w = "12", h = "10", fill = "#FF223D" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.92086 0C5.99198 0 6.86501 0.848121 6.90504 1.90945L6.90648 1.98561H9.41007C9.64847 1.98561 9.84173 2.17887 9.84173 2.41727C9.84173 2.64076 9.67187 2.82459 9.45421 2.84669L9.41007 2.84892H9.03332L8.44518 10.6428C8.3874 11.4083 7.74942 12 6.98172 12H2.86001C2.09231 12 1.45432 11.4083 1.39655 10.6428L0.808058 2.84892H0.431655C0.208158 2.84892 0.0243336 2.67906 0.00222855 2.4614L0 2.41727C0 2.19377 0.169856 2.00995 0.387521 1.98784L0.431655 1.98561H2.93525C2.93525 0.888989 3.82424 0 4.92086 0ZM8.16763 2.84892H1.67409L2.25741 10.5779C2.2812 10.8931 2.5439 11.1367 2.86001 11.1367H6.98172C7.29783 11.1367 7.56053 10.8931 7.58432 10.5779L8.16763 2.84892ZM6.04317 4.66187C6.26666 4.66187 6.45049 4.83173 6.47259 5.04939L6.47482 5.09353V8.89209C6.47482 9.13048 6.28156 9.32374 6.04317 9.32374C5.81967 9.32374 5.63584 9.15388 5.61374 8.93622L5.61151 8.89209V5.09353C5.61151 4.85513 5.80477 4.66187 6.04317 4.66187ZM3.79856 4.66187C4.02206 4.66187 4.20588 4.83173 4.22799 5.04939L4.23022 5.09353V8.89209C4.23022 9.13048 4.03696 9.32374 3.79856 9.32374C3.57506 9.32374 3.39124 9.15388 3.36914 8.93622L3.36691 8.89209V5.09353C3.36691 4.85513 3.56016 4.66187 3.79856 4.66187ZM4.92086 0.863309C4.32241 0.863309 3.83335 1.33173 3.80034 1.92193L3.79856 1.98561H6.04317C6.04317 1.36578 5.54069 0.863309 4.92086 0.863309Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
