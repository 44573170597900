import React from "react";

/**
 * Contact template  is the template for the contact section in the subscribed vendor page
 * @param {} props arecoming from HeaderCert.js
 */
const ContactTemplate = (props) => {
  return (
    <div className={props.className}>
      <div className="stat_content_data-title">
        <h4>{props.title}</h4>
      </div>
      <div className="stat_content_data-content">
        <div className="column">
          <div className="row top">
            <label className="vendor-info">First Name: </label>
            <p>{props.firstName}</p>
          </div>
          <div className="bottom">
            {/* <p> Spryte may sporadically email you reports of your account activity, upcoming project opportunities from clients, or questions related to sales and your developers</p> */}
          </div>
        </div>

        <div className="column">
          <div className="row top">
            <label className="vendor-info">Last Name:</label>
            <p>{props.lastName}</p>
          </div>
          <div className="bottom">
            {/* <p> Spryte may sporadically email you reports of your account activity, upcoming project opportunities from clients, or questions related to sales and your developers</p> */}
          </div>
        </div>
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Email: </label>
            <p>{props.email}</p>
          </div>
          <div className="bottom">
            {/* <p> Spryte may sporadically email you reports of your account activity, upcoming project opportunities from clients, or questions related to sales and your developers</p> */}
          </div>
        </div>
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Phone Number: </label>
            <p>{props.phoneNumber}</p>
          </div>
          <div className="bottom">
            {/* <p> Spryte may sporadically email you reports of your account activity, upcoming project opportunities from clients, or questions related to sales and your developers</p> */}
          </div>
        </div>
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Job Title: </label>
            <p>{props.jobTitle}</p>
          </div>
          <div className="bottom">
            {/* <p> Spryte may sporadically email you reports of your account activity, upcoming project opportunities from clients, or questions related to sales and your developers</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTemplate;
