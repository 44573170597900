import React from "react";

import ProgressBar from "react-customizable-progressbar";
export default function devProgessTemplate(props) {
  const progress = Number(
    ((props.profileProgress?.totalProgress || 0) * 100)?.toFixed(2)
  );
  return (
    <div id="devProgress" className="dbar">
      <div className="the_ind">
        <p>{progress ?? 0}%</p>
      </div>
      <ProgressBar
        radius={15}
        progress={progress ?? 0}
        strokeWidth={2}
        fillColor="#F7FAFC"
        strokeColor={
          progress >= 70
            ? "#57CA90"
            : progress < 70 && progress >= 50
            ? "#F45E40"
            : "#B93329"
        }
        trackStrokeWidth={1}
        pointerRadius={3}
        pointerStrokeWidth={2}
        pointerStrokeColor={
          progress >= 70
            ? "#57CA90"
            : progress < 70 && progress >= 50
            ? "#F45E40"
            : "#B93329"
        }
      >
        {/* <div className="indicator">
          <p>{progress ?? 0}%</p>
        </div> */}
      </ProgressBar>
    </div>
  );
}
