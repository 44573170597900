import React, { useMemo } from "react";
import styled from "styled-components";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";
import DevA from "./Dev-a.png";
import DevB from "./Dev-b.png";

import DevC from "./Dev-c.png";

// import Dev from /public

const BoxWrapper = styled.div`
  display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: ${(props) => props.bg};
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  gap: 10px;
  width: 100%;
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
`;

const ImgDiv = styled.div`
  display: flex;
  width: ${(props) => props.width};
  position: relative;
  align-items: center;
  gap: 5px;
  .proposeDevImg {
    width: 50px;
    height: 50px;
    position: absolute;
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      left: 25px;
    }
    &:nth-child(3) {
      left: 50px;
    }
  }
`;

const images = [DevA, DevB, DevC];

const ActiveDevsAndPartners = ({
  bg = "rgba(217, 217, 217, 0.75)",
  text,
  cls = "",
  activeResources,
  partners,
}) => {
  // const activeResources = 9;
  const renderImages = () => {
    let img = [];
    for (let i = 0; i < activeResources; i++) {
      if (i < 3) {
        img.push(
          <SpResponsiveImg
            src={images[i]}
            alt={`image_${i}`}
            key={i}
            wrappercls="proposeDevImg"
          />
        );
      }
    }
    return img;
  };

  const renderWidth = useMemo(() => {
    if (activeResources === 1) {
      return "50px";
    }
    if (activeResources === 2) {
      return "75px";
    }
    if (activeResources > 2) {
      return "100px";
    }
  }, [activeResources]);

  return (
    <BoxWrapper className={`${cls} _spNotOpenPos`} bg={bg}>
      {activeResources > 0 && (
        <ImgDiv width={renderWidth}>{renderImages()}</ImgDiv>
      )}

      <TextDiv>
        <p>
          {activeResources > 1
            ? `${activeResources} Devs`
            : `${activeResources} Dev`}{" "}
          proposed
        </p>
        <p>{partners} Partners Active</p>
        <p>Rates: $45-$75</p>
      </TextDiv>
    </BoxWrapper>
  );
};

export default ActiveDevsAndPartners;
