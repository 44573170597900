import React from "react";

const ProfileIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0584851 0.217099C0.00434556 0.357717 -0.0158558 3.59998 0.0134362 7.42184L0.0665656 14.3709H7.23803H14.4095V7.21918V0.067416L7.28329 0.0144326C1.67257 -0.0272692 0.135856 0.0158428 0.0584851 0.217099ZM2.38972 6.6148C2.38972 10.711 2.40244 10.8454 2.79374 10.8454C3.01596 10.8454 3.19777 10.7217 3.19777 10.5704C3.19777 10.0654 4.01815 9.24705 4.92599 8.84676C5.89626 8.41866 6.01787 8.08605 5.52092 7.21918C5.2274 6.70707 5.1068 4.31778 5.34335 3.70273C5.50496 3.28289 6.46715 2.7871 7.12006 2.7871C7.88387 2.7871 8.98807 3.38462 9.23897 3.9336C9.50604 4.51803 9.3222 6.47781 8.92707 7.25947C8.48425 8.13561 8.59213 8.37031 9.7127 8.96924C10.4163 9.34516 10.8753 9.75493 11.0763 10.1862C11.2466 10.5517 11.5342 10.8386 11.7328 10.841C12.0585 10.845 12.0864 10.5114 12.0864 6.6148V2.38418H7.23803H2.38972V6.6148ZM15.3204 4.14694C15.3361 4.89475 15.3424 7.52378 15.3339 9.98922C15.3196 14.1918 15.2933 14.4968 14.9145 14.8746C14.5293 15.2588 14.2271 15.2785 8.39961 15.2972L2.28871 15.317L8.45011 16.3952C15.8103 17.6831 15.8535 17.6863 16.0044 16.9395C16.5086 14.4457 18.1603 4.09637 18.1098 3.74785C18.0571 3.38341 17.8328 3.26173 16.8337 3.05524C16.167 2.91744 15.5472 2.8008 15.4565 2.79596C15.3658 2.79113 15.3044 3.39913 15.3204 4.14694ZM17.6744 11.6011C17.1371 14.7865 16.5668 17.5969 16.4072 17.8461C16.2258 18.1297 15.9165 18.2994 15.5812 18.2994C15.2862 18.2994 12.3661 17.841 9.09211 17.281C5.81808 16.7207 3.06525 16.3364 2.97495 16.4264C2.88445 16.5167 2.87495 16.6921 2.95374 16.8166C3.15414 17.1335 16.7161 20.2102 16.9357 19.9886C17.0328 19.8905 17.8161 16.7435 18.6764 12.9952C20.3562 5.6752 20.3467 5.81904 19.1568 5.81219C18.6522 5.80937 18.6508 5.81481 17.6744 11.6011Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProfileIcon;
