import styled from "styled-components";
import { spPrimaryColor, spSuccess } from "../../stylesConstant";

export const DevCardWrapper = styled.div`
  border-radius: ${({ borderrad }) => (borderrad ? borderrad : 0)};
  background-color: ${({ wrapbg }) => (wrapbg ? wrapbg : "white")};
  max-width: ${({ wrapwidth }) => (wrapwidth ? wrapwidth : "fit-content")};
  padding: ${({ wrappad }) => (wrappad ? wrappad : "24px")};
  box-shadow: ${({ wrapsha }) =>
    wrapsha ? wrapsha : "18px 15px 69px rgba(0, 0, 0, 0.05)"};
  gap: 15px;
  // height: 100%;

  margin-top: 10px;

  h3 {
    font-size: 14px;
    font-weight: 600;
  }
  max-height: 590px;
  position: relative;
  @media (max-width: 769px) {
    max-height: 600px;
  }
  @media (max-width: 596px) {
    height: 100%;
    max-height: fit-content;
  }
`;
export const DevUID = styled.span`
  text-align: center;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 400;
`;

export const CounterWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${spSuccess};
  height: 3px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    min-width: 50px;
    // height: 30px;
    background-color: ${spSuccess};
    color: ${spPrimaryColor};
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    text-align: center;
  }
`;
