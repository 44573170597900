import React from "react";

const EditIcon = ({ size = "12", h = "10", fill = "#4361E9" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05728 4.39796L1.86309 9.59441C1.7123 9.74519 1.7123 9.9905 1.86309 10.1413C1.93736 10.2156 2.03638 10.2538 2.1354 10.2538C2.23442 10.2538 2.33345 10.2156 2.40771 10.1413L7.60191 4.94709C7.75269 4.79631 7.75269 4.551 7.60191 4.40022C7.45337 4.24718 7.20807 4.24718 7.05728 4.39796Z"
        fill={fill}
      />
      <path
        d="M9.70282 5.28524C9.80184 5.28524 9.90086 5.24698 9.97513 5.17272L10.5423 4.60559C11.1972 3.95069 11.1972 2.88619 10.5423 2.23354L9.76808 1.45936C9.45076 1.14204 9.02991 0.96875 8.58206 0.96875C8.13421 0.96875 7.71336 1.14429 7.39604 1.45936L6.82891 2.02649C6.67812 2.17728 6.67812 2.42258 6.82891 2.57337L9.43051 5.17497C9.50477 5.24698 9.6038 5.28524 9.70282 5.28524ZM7.94291 2.00174C8.11395 1.8307 8.34126 1.73618 8.58431 1.73618C8.82512 1.73618 9.05467 1.8307 9.22571 2.00174L9.99989 2.77592C10.3532 3.12925 10.3532 3.70313 9.99989 4.05646L9.70282 4.35353L7.64585 2.29656L7.94291 2.00174Z"
        fill={fill}
      />
      <path
        d="M3.66493 11.479L9.14494 5.99897C9.29572 5.84819 9.29572 5.60288 9.14494 5.4521C8.99415 5.30131 8.74885 5.30131 8.59806 5.4521L3.21258 10.8421L0.836031 11.1617L1.1556 8.78511L6.30029 3.64043C6.45107 3.48965 6.45107 3.24434 6.30029 3.09356C6.1495 2.94277 5.9042 2.94277 5.75341 3.09356L0.518708 8.33276C0.457944 8.39353 0.419685 8.47004 0.408433 8.55331L0.0033398 11.56C-0.0124138 11.6793 0.0280955 11.7986 0.113615 11.8841C0.185632 11.9561 0.284654 11.9966 0.385928 11.9966C0.403932 11.9966 0.419685 11.9944 0.43769 11.9921L3.44438 11.587C3.52765 11.578 3.60642 11.5397 3.66493 11.479Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
