import { colRef, currentTime, spryteOrgRef } from "../globals/services";

export const VENDORCOLLECTIONS = {
  holidays: "holidays",
  caseStudies: "caseStudies",
  contracts: "contracts",
  documents: "documents",
  generic: "generic",
  partnerPublic: "partnerPublic",
  priceCards: "priceCards",
};

export const FINANCIAL = {
  avgYearlyIncome: 0,
  avgRateSprint: 0,
  avgRateHr: 0,
  netMarginP: 0,
  grossMarginP: 0,
  publicCompany: false,
};

export const VERIFICATION = {
  businessEntityName: "Unknown",
  source: "None",
  status: true,
  jurisdiction: "Unknown",
  companyId: "Unknown",
  dateOfFormation: currentTime(),
  lastUpdated: currentTime(),
};

export const LEGAL = {
  bankruptcy: false,
  taxLienFilings: 0,
  judgementFilings: 0,
  collectionsCount: 0,
  lastUpdated: currentTime(),
};

export const REVIEWS = {
  verifiedClientReviews: 0,
  overallReviewRating: 0,
  source: "Unknown",
  lastUpdated: currentTime(),
};

export const LOGO = {
  url: "",
  name: "",
};

export const PROFILE = {
  bio: "",
};
export const SECURITY = {
  L0: {
    approved: true,
    approvedBy: "System Registration",
    approvedOn: currentTime(),
    level: 0,
    recertBy: null,
    submitted: true,
  },
  L1: {
    approved: false,
    approvedBy: null,
    approvedOn: null,
    level: 1,
    recertBy: null,
    submitted: false,
  },
  L2: {
    approved: false,
    approvedBy: null,
    approvedOn: null,
    level: 2,
    recertBy: null,
    submitted: false,
  },
  L3: {
    approved: false,
    approvedBy: null,
    approvedOn: null,
    level: 3,
    recertBy: null,
    submitted: false,
  },
  L4: {
    approved: false,
    approvedBy: null,
    approvedOn: null,
    level: 4,
    recertBy: null,
    submitted: false,
  },
  L5: {
    approved: false,
    approvedBy: null,
    approvedOn: null,
    level: 5,
    recertBy: null,
    submitted: false,
  },
};

export const PROFILEPROGRESS = {
  financialProgress: 0,
  devsProgress: 0,
};

export const CASESTUDYCOUNT = {
  DRAFT: 0,
  FEATURED: 0,
  "READY-TO-SUBMIT": 0,
  REVIEWED: 0,
  UPLOADED: 0,
};

export const VENDORDOCDATA = {
  addedOn: currentTime(),
  companyName: "",
  address: "",
  techFocus: "",
  totalDevs: 0,
  website: "",
  domains: [],
  timeToStart: 0,
  contactRef: null,
  manager: null,
  financial: FINANCIAL,
  verification: VERIFICATION,
  legal: LEGAL,
  reviews: REVIEWS,
  logo: LOGO,
  profile: PROFILE,
  orgRef: spryteOrgRef(),
  vendorProfileProgress: PROFILEPROGRESS,
  security: SECURITY,
  admin: [{ status: false }],
};

export const renderVendorData = (vendor) => {
  let vendorData = { ...VENDORDOCDATA, ...vendor.data() };

  vendorData.vendorRef = vendor.ref;
  // Convert date variables to readable format, handle for null value
  if (vendorData?.verification?.dateOfFormation) {
    let date = vendorData.verification.dateOfFormation.toDate();
    vendorData.verification.dateOfFormation =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  } else {
    vendorData["verification"]["dateOfFormation"] = " ";
  }
  if (vendorData?.verification?.lastUpdated) {
    let date = vendorData.verification.lastUpdated.toDate();
    vendorData.verification.lastUpdated =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  } else {
    vendorData.verification.lastUpdated = " ";
  }
  if (vendorData.legal.lastUpdated) {
    let date = vendorData.legal.lastUpdated.toDate();
    vendorData.legal.lastUpdated =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  } else {
    vendorData.legal.lastUpdated = " ";
  }
  if (vendorData.reviews.lastUpdated) {
    let date = vendorData.reviews.lastUpdated.toDate();
    vendorData.reviews.lastUpdated =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  } else {
    vendorData.reviews.lastUpdated = " ";
  }
  // Convert booleans to values syncfusion can read
  if (vendorData.financial.publicCompany) {
    vendorData.financial.publicCompany = "YES";
  } else {
    vendorData.financial.publicCompany = "NO";
  }
  if (vendorData.verification.status) {
    vendorData.verification.status = "Active";
  } else {
    vendorData.verification.status = "Inactive";
  }
  if (vendorData.legal.bankruptcy) {
    vendorData.legal.bankruptcy = "YES";
  } else {
    vendorData.legal.bankruptcy = "NO";
  }

  return { ...vendorData, id: vendor.id, vendorRef: vendor.ref };
};

export const createVendor = async (vendorData) => {
  return await colRef("vendors").add(vendorData);
};

export const getVendor = async (vendorId) => {
  return await colRef("vendors").doc(vendorId).get();
};

export const updateVendor = async (vendorId, vendorData) => {
  return await colRef("vendors").doc(vendorId).update(vendorData);
};

export const getVendorByContact = async (userRef) => {
  return await colRef("vendors").where("contactRef", "==", userRef).get();
};

export const getVendorByManager = async (userRef) => {
  return await colRef("vendors").where("manager", "==", userRef).get();
};
