import React, { useEffect, useState, useMemo } from "react";
import StacksList from "./StacksList";
import Search from "../Search";
import { Pagination } from "@mui/material";
import {
  getDocs,
  getDocumentsByArrayMembershipInCollection,
} from "../../../../globals/services";

export default function TechsCarousel(props) {
  const [filteredStacks, setFilteredStacks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(40);
  const [numberOfStacks, setNumberOfStacks] = useState(0);

  const currentTableData = useMemo(() => {
    // props.stacks.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    let theStacks = filteredStacks?.length < 1 ? props.stacks : filteredStacks;

    setNumberOfStacks(theStacks.length);
    const firstPageIndex = (currentPage - 1) * pageLimit;
    const lastPageIndex = firstPageIndex + pageLimit;

    return theStacks.slice(firstPageIndex, lastPageIndex);
  }, [props.stacks, currentPage, filteredStacks, pageLimit]);

  useEffect(() => {
    // props.stacks.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    props.numOfStackToShow
      ? setPageLimit(props.numOfStackToShow)
      : setPageLimit(40);
  }, [props.numOfStackToShow, props.stacks]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    props?.onStacksPaginate && props.onStacksPaginate(event, page);
  };

  const handleSearch = async (e) => {
    // setSearchValue(e.target.value);
    // setSearchValue(e.target.value);
    let dataDocs;
    let stacksCatsResult;
    if (props?.type === "stacks" || !props?.type) {
      dataDocs = await getDocumentsByArrayMembershipInCollection(
        "technologies",
        e.target.value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "rank",
        true,
        true,
        false,
        50
      );

      stacksCatsResult = await getDocs("techStacks150r");
    }
    if (props?.type === "domains") {
      dataDocs = await getDocumentsByArrayMembershipInCollection(
        "industry",
        e.target.value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "name",
        true,
        true,
        false,
        20
      );
      stacksCatsResult = await getDocs("sectors");
    }
    if (props?.type === "categories") {
      dataDocs = await getDocumentsByArrayMembershipInCollection(
        "subcollections",
        e.target.value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "name",
        true,
        true,
        false,
        20
      );
      stacksCatsResult = await getDocs("categories");
    }

    const newStacksCategories = stacksCatsResult?.docs?.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
    }));
    const data = dataDocs?.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
      catId: doc?.ref?.parent?.parent?.id,
      catData: newStacksCategories.find(
        (cat) => cat?.id === doc?.ref?.parent?.parent?.id
      ),
    }));

    setFilteredStacks(data);
  };

  const onAdd = async (tech) => {
    let data = {
      experience: 1,
      interest: "Interested",
      projects: 1,
      skill: 1,
      addedOn: new Date(),
    };
    if (props?.type === "stacks") {
      data.techRef = tech.ref;
      data.tech = tech.title;
      await props.firebase.createStack(props.devId, data);
    }

    if (props?.type === "domains") {
      data.domainRef = tech.ref;
      data.domain = tech.name;
      await props.firebase.createPrimaryDomain(props.devId, data);
    }
    if (props?.type === "categories") {
      data.projectRef = tech.ref;
      data.projType = tech.name;
      await props.firebase.createProjExp(props.devId, data);
    }
    // Updating lastUpdated for the dev
    await props.firebase.updateDev(props.devId, {
      lastUpdated: new Date(),
    });
  };

  return (
    <div className="Stacks_wrap _spTechSkills_wrap">
      <h4>{props.title}</h4>
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={props?.placeholder || "Ex: Libraries or Python or Tools"}
        allData={props.stacks}
        // setFilteredDatas={setFilteredStacks}
        currentPage={currentPage}
        pageLimit={pageLimit}
        isSub={true}
        onChange={handleSearch}
      />

      <div className="_spTechskillsSection stacks_area">
        <StacksList
          currentStacks={currentTableData}
          firebase={props.firebase}
          devId={props.devId}
          showHiddenCard={props.showHiddenCard}
          stackExp={props.stackExp}
          useExist={props.useExist}
          setSelectedStacks={props.setSelectedStacks}
          onAdd={onAdd}
          // title={props.title}
        />
        <div className="stacks_pagination">
          <Pagination
            count={Math.ceil(props?.stacks.length / pageLimit)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
