import React, { useState, useReducer, useEffect } from "react";
import PageHeader from "../../globals/PageHeader";
import DataPointList from "./components/DataPointList";
import AddDialog from "./components/AddDialog";
import FormInput from "../../globals/FormInput";
import { MdOutlineAddCircle } from "react-icons/md";

const initialState = {
  label: "",
  desc: "",
  id: "",
  hintText: "",
  hintArray: [],
  protoArray: [],
  precision: "",
  prefix: "",
  type: "UNIT",
  proto: "",
};

const reducer = (state, action) => {
  let datapoint = { ...state };
  switch (action.type) {
    case "ADD":
      datapoint[action.key] = action.value;
      return datapoint;
    case "EDIT":
      datapoint = { ...action.data };
      return datapoint;
    default:
      return datapoint;
  }
};
const DataPoint = ({ firebase, user }) => {
  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [datapoint, dispatch] = useReducer(reducer, initialState);
  const [protoCounter, setProtoCounter] = useState(1);
  const [error, setError] = useState("");
  const [fieldsError, setFiledsError] = useState("");

  // const collectionRef = ;

  useEffect(() => {
    let sub = firebase.db
      .collection("/organisations/T6BAcTjwbXleCFpmWTmu/dataPoints")
      .onSnapshot((snap) => {
        let datapoints = snap?.docs.map((query) => {
          let datapoint = {
            ...query.data(),
            ref: query?.ref,
            ...query.data()?.unit,
            ...query.data()?.stats,
            id: query.id,
          };
          return datapoint;
        });
        setData(datapoints);
      });
    return sub;
  }, []);

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  useEffect(() => {
    const checkLabel = async () => {
      setError("");
      if (!datapoint.label) return;
      let newId = camelize(datapoint.label);

      dispatch({ type: "ADD", key: "id", value: newId });
      let docRef = await firebase.db
        .doc(`/organisations/T6BAcTjwbXleCFpmWTmu/dataPoints/${newId}`)
        .get();

      if (docRef.exists && docRef.id !== datapoint.id) {
        setError("exists already. Key must be unique");
      }
    };
    checkLabel();
  }, [datapoint.id, datapoint.label]);

  const isRequiredFill = () => {
    setFiledsError("");
    if (!datapoint.label || !datapoint.desc) {
      setFiledsError("Label and description are all required");
      return false;
    }

    if (
      datapoint.type !== "radio" &&
      datapoint.type !== "checklist" &&
      (!datapoint.hintText || !datapoint.proto)
    ) {
      setFiledsError("Hint Text and Proto are required");
      return false;
    }

    if (datapoint.type === "radio" || datapoint.type === "checklist") {
      let proto = datapoint.protoArray.map((el) => {
        if (!el || el === "") return 0.0;
        return el;
      });
      let hints = datapoint.hintArray.map((el) => {
        if (!el) return "";
        return el;
      });
      let error =
        proto.length > 0 && hints.length > 0 && proto.length === hints.length
          ? ""
          : "Please verify Prototypes and Hint texts";
      setFiledsError(error);
      return (
        proto.length > 0 && hints.length > 0 && proto.length === hints.length
      );
    }

    if (
      (datapoint.type === "string" ||
        datapoint.type === "radio" ||
        datapoint.type === "checklist") &&
      !datapoint.precision
    ) {
      setFiledsError("");
      return true;
    }

    if (
      (datapoint.type !== "string" ||
        datapoint.type !== "radio" ||
        datapoint.type !== "checklist") &&
      !datapoint.precision
    ) {
      setFiledsError("Precision is required");
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    setLoading(true);
    if (!isRequiredFill() || error) {
      setLoading(false);
      return;
    }
    const {
      precision,
      prefix,
      type,
      ref,
      id,
      hintText,
      hintArray,
      proto,
      protoArray,
      ...datap
    } = datapoint;
    let dataToSave = {
      unit: {
        prefix: prefix,
        precision: precision,
        type: type,
      },
      proto:
        type === "radio" || type === "checklist"
          ? protoArray.map((el) => {
              if (!el || el === "") return 0;
              return el;
            })
          : proto,
      hintText:
        type === "radio" || type === "checklist"
          ? hintArray.filter((el) => {
              if (!el) return "";
              return el;
            })
          : hintText,
      ...datap,
    };

    if (isEdit) {
      // Edit
      ref.update(dataToSave);

      onClose();
      return;
    }

    firebase.updateDocFromPath(
      `/organisations/T6BAcTjwbXleCFpmWTmu/dataPoints/${id}`,
      dataToSave
    );
    onClose();
  };

  const onClose = () => {
    setError("");
    setFiledsError("");
    setProtoCounter(1);
    setLoading(false);
    setIsAdd(false);
    setIsEdit(false);
    dispatch({ type: "EDIT", data: initialState });
  };

  const onAddProto = () => {
    let prots = protoCounter;
    setProtoCounter(prots + 1);
  };

  const renderProto = (data, dispatch) => {
    const protos = [];
    let protoData = [];
    let hintData = [];
    if (Array.isArray(data?.hintArray)) {
      protoData = [...data?.protoArray];
      hintData = [...data?.hintArray];
    }
    for (let i = 0; i < protoCounter; i++) {
      if (protos.length < 1) {
        protos.push(
          <div className="col_2_proto" key={i}>
            <FormInput
              label="Options"
              desc="Add options below, along with their corresponding values"
              value={data?.hintArray?.[i]}
              onChange={(e) => {
                hintData[i] = e.target.value;
                dispatch({
                  type: "ADD",
                  key: "hintArray",
                  value: hintData,
                });
              }}
            />
            <FormInput
              label="Value"
              desc="Which Value is applied for computing indices"
              type="number"
              value={data?.protoArray?.[i]}
              onChange={(e) => {
                protoData[i] = parseFloat(e.target.value);
                dispatch({
                  type: "ADD",
                  key: "protoArray",
                  value: protoData,
                });
              }}
            />
          </div>
        );
      } else {
        protos.push(
          <div className="col_2_proto" key={i} style={{ marginTop: "1rem" }}>
            <input
              type="text"
              value={data?.hintArray?.[i]}
              onChange={(e) => {
                hintData[i] = e.target.value;
                dispatch({
                  type: "ADD",
                  key: "hintArray",
                  value: hintData,
                });
              }}
              id="expboxinput"
            />
            <input
              type="number"
              value={data?.protoArray?.[i]}
              step={0.01}
              onChange={(e) => {
                protoData[i] = parseFloat(e.target.value);
                dispatch({
                  type: "ADD",
                  key: "protoArray",
                  value: protoData,
                });
              }}
              id="expboxinput"
            />
          </div>
        );
      }
    }
    return (
      <div>
        {protos}
        {data?.hintArray?.[protoCounter - 1] && (
          <div>
            <MdOutlineAddCircle
              style={{ opacity: ".85", marginTop: "10px", cursor: "pointer" }}
              size={20}
              onClick={() => onAddProto()}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="datapoints">
      {(isAdd || isEdit) && (
        <AddDialog
          onClose={onClose}
          data={datapoint}
          dispatch={dispatch}
          loading={loading}
          onSubmit={onSubmit}
          renderProto={renderProto}
          error={error}
          fieldsError={fieldsError}
        />
      )}
      <PageHeader header="Data Points" desc="Manage Spryte data points" />

      <div className="brand_body-btn" style={{ alignItems: "center" }}>
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => {
            dispatch({ type: "EDIT", data: initialState });
            setIsAdd(true);
          }}
          style={{ margin: "10px 0" }}
        >
          Add
        </button>

        {selectedData ? (
          <button
            className="spbtn spbtn-blackbkg"
            onClick={() => {
              setIsEdit(true);
            }}
            style={{ margin: "10px 0" }}
          >
            Edit
          </button>
        ) : (
          <></>
        )}
      </div>

      <DataPointList
        // data={data}
        datapoints={data}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
        dispatch={dispatch}
        initialState={initialState}
        setProtoCounter={setProtoCounter}
      />
    </div>
  );
};

export default DataPoint;
