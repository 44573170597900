import { HelpRounded } from "@mui/icons-material";
import React from "react";

const SpryteTooltip = (props) => {
  return (
    <div className="ttp">
      <HelpRounded className="ttp-icon" />
      <div className="ttp-content">
        <p>{props?.desc}</p>
      </div>
    </div>
  );
};

export default SpryteTooltip;
