import React, { useEffect, useState } from "react";
import CustomResponsiveBar from "../barChart";
// import CustomResponsiveBar from "../barChart";
// import { convertDateToString } from "../../functions/queryFamilyFunctions";

function Metrics({
  logDataEntries,
  userTypes,
  types,
  startDate,
  endDate,
  setMaxValueFunction,
  maxValue,
}) {
  // This will be an object containing the log data which can be passed to the CustomResponsiveBar component.
  // It shall also also contain the count for each type.
  const [logData, setLogData] = useState(null);

  /**
   * This function pads numbers to make them a string of { length } length.
   *
   * @param {Number} digits - The number to pad.
   * @param {Number} length - The final length of the string.
   * @returns {String} A string padded with 0s.
   */
  const padDigits = (digits, length = 4) => {
    return ("0".repeat(length) + digits.toFixed()).slice(-length);
  };

  const convertDateToString = (date) => {
    return (
      padDigits(date.getFullYear()) +
      padDigits(date.getMonth(), 2) +
      padDigits(date.getDate(), 2)
    );
  };

  // This pulls all relevant vendors with developers who know selectedSkillDocRef.
  useEffect(() => {
    if (logDataEntries === null || logDataEntries === undefined) return;

    // Filter to extract only the requested the data.
    const data = logDataEntries.filter((logDocumentSnapshot) =>
      userTypes.includes(logDocumentSnapshot?.userType)
    );

    // Start at the first log's date.
    let curr = startDate;
    curr.setHours(0, 0, 0, 0);

    // Determine the final date.
    let finalDate = new Date(Math.min(endDate, new Date()));
    finalDate.setHours(0, 0, 0, 0);

    // Create a pointer for data documents.
    let pointer = 0;

    // Store log data in the array.
    const logDataArray = [];

    // Create an object to store data on each type's count.
    const typeCountObject = {};

    // Store a max value for events.
    let currMaxValue = 0;

    // Store data on a per-day basis.
    while (curr <= finalDate) {
      // The daily entry to push to the log array.
      const dailyEntry = {
        date: `${logDataArray.length + 1}`,
        EVENTS: 0,
        EVENTSColor: "hsl(167, 70%, 50%)",
      };

      // The start date can be after or equal to curr.
      // If the log happened on curr, increment the relevant key.
      while (
        pointer < data.length &&
        convertDateToString(data[pointer].addedOn.toDate()) ===
          convertDateToString(curr)
      ) {
        // Increment the correct key, if it exists.
        userTypes.includes(data[pointer]?.userType) &&
          types.includes(data[pointer]?.type) &&
          dailyEntry["EVENTS"]++;

        // Increment the type count for the correct type.
        if (data[pointer]?.type) {
          typeCountObject[data[pointer]?.type] =
            typeCountObject[data[pointer]?.type] || 0;
          typeCountObject[data[pointer]?.type]++;
        }

        // Increment the pointer.
        pointer++;
      }

      // Update the maxValue, if reuired.
      currMaxValue = Math.max(currMaxValue, dailyEntry["EVENTS"]);

      // If the start date is after curr, push the daily entry and increment curr.
      logDataArray.push(dailyEntry);
      curr.setDate(curr.getDate() + 1);
    }

    // Set the log data.
    setLogData({ logDataArray, typeCountObject });

    // Set the maxValue.
    if (currMaxValue > maxValue) setMaxValueFunction(currMaxValue);
  }, [logDataEntries]);

  return (
    <div
      style={{
        paddingBottom: "25px",
        height: 245,
        width: 355,
        backgroundColor: "white",
        marginRight: "17.5px",
        borderRadius: "3.5px",
        border: "2.15px solid rgba(71, 31, 31, 0.075)",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "16.5px",
          marginBottom: "-48.5px",
          marginLeft: "22.5px",
        }}
      >
        {logData && (
          <h4 style={{ marginRight: "7.5px" }}>
            <span style={{ fontSize: "55.5px" }}>
              {logData.typeCountObject["VIEW"] ?? "0"}
            </span>{" "}
            <span style={{ opacity: ".55", fontWeight: "500" }}>VIEWS</span>
          </h4>
        )}
        {logData && (
          <h4>
            <span style={{ fontSize: "55.5px" }}>
              {logData.typeCountObject["CLICK"] ?? "0"}
            </span>{" "}
            <span style={{ opacity: ".55", fontWeight: "500" }}>CLICKS</span>
          </h4>
        )}
      </div>
      {logData && (
        <CustomResponsiveBar
          indexByKey={"date"}
          otherKeys={["EVENTS"]}
          data={logData.logDataArray}
          maxValue={maxValue}
        />
      )}
    </div>
  );
}

export default Metrics;
