import { MdLocationOn } from "react-icons/md";
import SpStartSVG from "../../SpStartSVG";
// import SpResponsiveImg from "../../../SpResponsiveImg";
// import SpStartSVG from "../../../SpStartSVG";
// import devEmptyProfile from "../../../../public/devEmptyProfile.png";
import {
  DevDetails,
  DevImageStatus,
  DevLoc,
  DevLocation,
  DevProfileInfo,
  DevProfileTop,
  DevRate,
  DevStatus,
  DevName,
} from "./style";

export const renderDevRates = (
  startWidth = "20px",
  startHeight = "20px",
  rating = 1,
  color = "#00C1AA",
  showSpan = false
) => {
  let ratingList = [];
  for (let i = 0; i < 4; i++) {
    if (i < rating) {
      ratingList.push(
        <SpStartSVG
          width={startWidth}
          height={startHeight}
          startColor={color}
          key={`start-${i}`}
        />
      );
    } else {
      ratingList.push(
        <SpStartSVG
          width={startWidth}
          height={startHeight}
          startColor={"#868DA9"}
          key={`start--${i}`}
        />
      );
    }
  }
  return (
    <DevRate className="devRate">
      {ratingList} {showSpan && <span>{rating}</span>}
    </DevRate>
  );
};

export const DevProfile = ({
  startWidth = "20px",
  startHeight = "20px",
  profileUrl,
  profileAlt = null,
  devData,
  borderStyle = "rounded",
  sx,
  bg = "transparent",
  padding = "0px",
  bradius = "0px",
  cls,
  center = false,
  imgWidth = "75px",
  imgHeight = "75px",
  showExp = false,
  showStatus = true,
  statusColor = "#13D00F",
  statusTop = "-5px",
  statusRight = "-5px",
  onSelect,
}) => {
  return (
    <DevProfileTop
      bg={bg}
      padding={padding}
      className={`sp_row ${cls}`}
      style={sx}
      center={center}
      bradius={bradius}
    >
      <DevProfileInfo className={`sp_row ${cls + "_profInfo"}`}>
        <DevImageStatus>
          {showStatus && (
            <DevStatus
              statuscolor={devData?.status && statusColor}
              statusright={statusRight}
              statustop={statusTop}
            />
          )}
          {/* {profileUrl ? (
            <SpResponsiveImg
              src={profileUrl}
              alt={profileAlt}
              width={imgWidth}
              height={imgHeight}
              sx={{
                borderRadius:
                  borderStyle === "rounded"
                    ? "10px"
                    : borderStyle === "circle"
                    ? "50%"
                    : "0px",
                overflow: "hidden",
              }}
            />
          ) : (
            <SpResponsiveImg
              src={devEmptyProfile}
              alt={"empty profile"}
              width={imgWidth}
              height={imgHeight}
              sx={{
                borderRadius:
                  borderStyle === "rounded"
                    ? "10px"
                    : borderStyle === "circle"
                    ? "50%"
                    : "0px",
                overflow: "hidden",
              }}
            />
          )} */}
        </DevImageStatus>
        <DevDetails className="sp_col">
          <DevName
            onClick={() => {
              if (onSelect) {
                onSelect();
                return;
              }
              window.open(`../resource/${devData?.devDocID}`, "_blank");
            }}
          >
            {devData?.first} {devData?.last}
          </DevName>
          <DevLocation className="sp_row">
            <MdLocationOn />
            <DevLoc>{devData?.locTag}</DevLoc>
          </DevLocation>
          {renderDevRates(startWidth, startHeight, devData?.rating)}
          {showExp && (
            <DevLocation className="sp_row">
              <DevLoc>16 yrs experience</DevLoc>
            </DevLocation>
          )}
        </DevDetails>
      </DevProfileInfo>
    </DevProfileTop>
  );
};
