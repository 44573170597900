import { useCallback, useEffect, useState } from "react";
import { colRef, db, docRef } from "../globals/services";
import { dateString } from "../globals/helpers";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const useDev = (devId) => {
  const [devStates, setDevStates] = useState({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (!devId) return;
    // console.log("useDev...");
    const devDocRef = db.collection("devs").doc(devId);
    const unsubscribe = devDocRef.onSnapshot(
      async (snapshot) => {
        if (!snapshot.exists) {
          // setError("Dev not found");
          // setDevLoading(false);
          setDevStates({ data: null, loading: false, error: "Dev not found" });
          return;
        }

        let data = { id: snapshot.id, ...snapshot.data(), ref: snapshot.ref };
        // Compute profile progress and requirements
        const profileProgress = data.profileProgress || {};
        data.progress = computeProfileProgress(profileProgress);
        data.requirements = computeProfileRequirements(profileProgress);
        // memoize the profile progress

        // Set isVerified default
        if (
          !data.hasOwnProperty("isVerified") &&
          !data?.userRef &&
          !data?.draft
        )
          data.isVerified = true;

        // Fetch hourly rate
        data.devRate = await fetchHourlyRate(snapshot.id);

        // Fetch position label if positionRef is available
        if (data.positionRef) {
          data.position = await fetchPositionLabel(data.positionRef);
        }

        // setDev(data);
        // setDevLoading(false);
        setDevStates({ data, loading: false, error: null });
      },
      (error) => {
        // setError(error);
        // setDevLoading(false);
        setDevStates({ data: null, loading: false, error });
      }
    );

    return () => unsubscribe();
  }, [devId]);

  return devStates;
};

const computeProfileProgress = (profileProgress) => {
  return (
    Math.floor(
      ((profileProgress.stacksProgress || 0) * 0.125 +
        (profileProgress.categoriesProgress || 0) * 0.125 +
        (profileProgress.sectorsProgress || 0) * 0.125 +
        (profileProgress.ratesProgress || 0) * 0.5 +
        0.125) *
        100
    ) ?? 0
  );
};

const computeProfileRequirements = (profileProgress) => {
  let req = [];
  if (profileProgress.stacksProgress !== 1) {
    const additionalStacksNeeded = (1 - profileProgress.stacksProgress) * 8;
    req.push(`Add ${additionalStacksNeeded} tech stack(s)`);
  }
  if (profileProgress.sectorsProgress !== 1) {
    const additionalSectorsNeeded = (1 - profileProgress.sectorsProgress) * 1;
    req.push(`Add ${additionalSectorsNeeded} domain experience`);
  }
  if (profileProgress.categoriesProgress !== 1) {
    const additionalCategoriesNeeded =
      (1 - profileProgress.categoriesProgress) * 4;
    req.push(`Add ${additionalCategoriesNeeded} project experience(s)`);
  }
  return req;
};

const fetchHourlyRate = async (devId) => {
  let hourlyRate = 0;
  const ratesRef = db.collection(`devs/${devId}/rates`);
  const fullDate = dateString(); // Implement dateString to return current date in required format
  let snapshot = await ratesRef.doc(fullDate).get();

  if (snapshot.exists) {
    hourlyRate = extractRate(snapshot.data());
  } else {
    const querySnapshot = await ratesRef.orderBy("date", "desc").limit(1).get();
    if (!querySnapshot.empty) {
      hourlyRate = extractRate(querySnapshot.docs[0].data());
    }
  }
  return hourlyRate;
};

const extractRate = (rateData) => {
  for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
    if (rateData.hourlyRates && rateData.hourlyRates[secLev]) {
      return rateData.hourlyRates[secLev];
    }
  }
  return 0;
};

const fetchPositionLabel = async (positionRef) => {
  const snapshot = await positionRef.get();
  return snapshot.exists ? snapshot.data().label : null;
};

export const useDevPublic = (devId) => {
  // const devRef = docRef(`devs/${devId}/devPublic/profile`);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) {
      setError("Dev ID is undefined or invalid");
      setLoading(false);
      return;
    }
    // console.log("useDevPublic...");

    const publicRef = docRef(`devs/${devId}/devPublic/profile`);
    const unsubscribe = publicRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setData({ id: doc.id, ...doc.data() });
        } else {
          setError("Public dev profile not found");
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [devId]);

  return { data, loading, error };
};

export const useDevAllData = (devId) => {
  // should return dev public and private data
  const devRef = docRef(`devs/${devId}`);
  const { dev, devLoading, error } = useDev(devRef);
  const {
    dev: devPublic,
    devLoading: devPublicLoading,
    error: devPublicError,
  } = useDevPublic(devId);
  return {
    dev,
    devLoading,
    error,
    devPublic,
    devPublicLoading,
    devPublicError,
  };
};

export const useDevByRef = (ref) => {
  const devId = ref.id;
  return useDev(devId);
};

export const useDevPublicByRef = (ref) => {
  const devId = ref.id;
  return useDevPublic(devId);
};

export const useDevAllDataByRef = (ref) => {
  const devId = ref.id;
  return useDevAllData(devId);
};

export const useDevStacks = (devId) => {
  const [stacks, setStacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) {
      setLoading(false);
      return;
    }

    // console.log("useDevStacks...");
    const unsubscribe = colRef(`devs/${devId}/stacks`).onSnapshot(
      async (snapshot) => {
        let stacksData = [];
        const stackPromises = snapshot.docs.map(async (doc) => {
          const stack = { id: doc.id, ...doc.data(), ref: doc.ref };
          // Assuming stack.techRef is a DocumentReference
          if (stack.techRef) {
            try {
              const techDataSnapshot = await stack.techRef.get();
              if (techDataSnapshot.exists) {
                stack.techData = techDataSnapshot.data();
              } else {
                console.error("Tech data not found");
                stack.techData = null;
              }
            } catch (techError) {
              console.error("Error fetching tech data", techError);
              // Optionally set an error state specific to tech data fetch failures
            }
          }
          return stack;
        });

        try {
          stacksData = await Promise.all(stackPromises);
          setStacks(stacksData);
        } catch (error) {
          setError(error);
          console.error("Error processing stacks data", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [devId]);

  return { stacks, loading, error };
};

export const useDevDomains = (devId) => {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) {
      setLoading(false);
      return;
    }

    // console.log("useDevDomains...");
    const unsubscribe = colRef(`devs/${devId}/sectors`).onSnapshot(
      async (snapshot) => {
        let domainsData = [];
        const domainPromises = snapshot.docs.map(async (doc) => {
          const domain = { id: doc.id, ...doc.data(), ref: doc.ref };
          // Assuming domain.domainRef is a DocumentReference
          if (domain.domainRef) {
            try {
              const domainDataSnapshot = await domain.domainRef.get();
              if (domainDataSnapshot.exists) {
                domain.domainData = domainDataSnapshot.data();
              } else {
                console.error("Domain data not found");
                domain.domainData = null;
              }
            } catch (domainError) {
              console.error("Error fetching domain data", domainError);
              // Optionally set an error state specific to domain data fetch failures
              domain.domainData = null; // Or handle this error differently
            }
          }
          return domain;
        });

        try {
          domainsData = await Promise.all(domainPromises);
          setDomains(domainsData);
        } catch (error) {
          setError(error);
          console.error("Error processing domains data", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [devId]);

  return { domains, loading, error };
};

export const useDevRateRanges = (devId, maxSecLev) => {
  const [devRates, setDevRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    if (!devId) return;
    if (!maxSecLev) return;
    // console.log("useDevRateRanges...");
    const displayRatesUpTo = `L${String.fromCharCode(
      maxSecLev.charCodeAt(1) + 1
    )}`;

    const unsubscribe = colRef(`devs/${devId}/rateRanges`)
      .orderBy("to", "desc")
      .onSnapshot(
        (snapshot) => {
          const rates = [];
          let minDate = null;

          snapshot.forEach((doc) => {
            const { from, to, hourlyRates, ...data } = doc.data();
            Object.entries(hourlyRates).forEach(([key, value]) => {
              if (key <= displayRatesUpTo) {
                data[key] = value;
              }
            });

            if (!minDate || minDate < to?.toDate()) {
              minDate = new Date(to?.toDate()?.getTime());
              minDate.setDate(to?.toDate()?.getDate() + 1);
            }

            setMinDate(minDate);

            rates.push({
              from: from?.toDate(),
              to: to?.toDate(),
              id: doc.id,
              ...data,
            });
          });

          setDevRates(rates);
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );

    return () => unsubscribe();
  }, [devId, maxSecLev]);

  return { devRates, loading, error, minDate };
};

export const useDevCats = (devId) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) {
      setLoading(false);
      return;
    }

    // console.log("useDevCats...");
    const unsubscribe = colRef(`devs/${devId}/categories`).onSnapshot(
      async (snapshot) => {
        let projectDataPromises = snapshot.docs.map(async (doc) => {
          let projectData = { id: doc.id, ...doc.data(), ref: doc.ref };
          // Assuming projectData.projectRef is a DocumentReference
          if (projectData.projectRef || projectData.categoryRef) {
            try {
              const projectDataSnapshot = await (
                projectData?.projectRef ?? projectData?.categoryRef
              ).get();
              if (projectDataSnapshot.exists) {
                // Adding projectData to the object
                projectData.projectData = projectDataSnapshot.data();
              } else {
                console.error("Project data not found");
                projectData.projectData = null;
              }
            } catch (projectError) {
              console.error("Error fetching project data", projectError);
              projectData.projectData = null; // Optionally handle this error differently
            }
          }

          return projectData;
        });

        try {
          const resolvedProjectData = await Promise.all(projectDataPromises);
          setProjects(resolvedProjectData);
        } catch (error) {
          setError(error);
          console.error("Error processing projects data", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [devId]);

  return { projects, loading, error };
};

export const useDevAvails = (devId) => {
  const [avails, setAvails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) return;
    // console.log("useDevAvails...");
    const unsubscribe = colRef(`devs/${devId}/availability`).onSnapshot(
      (snapshot) => {
        let projs = [];
        snapshot?.docs?.forEach((proj) => {
          const { start, end, ...data } = proj.data();
          try {
            let status = "";
            if (data.status) {
              try {
                status = data.status.id;
              } catch (err) {
                console.log(err);
              }
            } else {
              status = "DRAFT";
            }
            // If the project has a holiday status then ignore and skip it
            // do not add it
            if (status !== "HOLIDAY") {
              const startDate = start?.toDate();
              const endDate = end?.toDate();
              let task = {
                TaskID: data.taskId,
                Id: proj.id,
                Description: data.description,
                Status: status,
                StartDate: startDate,
                EndDate: endDate,
                Duration: data.duration || 1,
                Progress: data.progress,
                info: data.info,
                resources: [{ id: devId }],
              };
              projs.push(task);
            }
          } catch (error) {
            return;
          }
        });
        setAvails(projs);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [devId]);

  return { avails, loading, error };
};

export const useDevVacations = (devId) => {
  const [vacations, setVacations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) return;
    // console.log("useDevVacations...");
    const unsubscribe = colRef(`devs/${devId}/vacation`).onSnapshot(
      (snapshot) => {
        let vacations = snapshot.docs?.map((vacationDoc) => {
          const { from, to, ...data } = vacationDoc.data();
          return {
            description: data.description,
            from: from?.toDate(),
            to: to?.toDate(),
            Id: vacationDoc.id,
          };
        });
        setVacations(vacations);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [devId]);

  return { vacations, loading, error };
};

export const useDevEducation = (devId) => {
  const [education, setEducation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) return;
    // console.log("useDevEducation...");
    const unsubscribe = colRef(`devs/${devId}/education`).onSnapshot(
      (snapshot) => {
        let data = [];
        snapshot.forEach((doc) => {
          const { addedOn, gradDate, ...rest } = doc.data();

          data.push({
            id: doc.id,
            ...rest,
            ref: doc.ref,
            addedOn: addedOn?.toDate(),
            gradDate: gradDate?.toDate(),
          });
        });
        setEducation(data);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [devId]);

  return { education, loading, error };
};

export const useDevExperience = (devId) => {
  const [experience, setExperience] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) return;
    // console.log("useDevExperience...");
    const unsubscribe = colRef(`devs/${devId}/workExp`).onSnapshot(
      (snapshot) => {
        let data = [];
        snapshot.forEach((doc) => {
          const { start, end, addedOn, ...rest } = doc.data();
          // console.log("data from useDevExperience", {
          //   id: doc.id,
          //   ...rest,
          //   ref: doc.ref,
          //   start: start.toDate(),
          //   end: end.toDate(),
          //   addedOn: addedOn.toDate(),
          // });
          data.push({
            id: doc.id,
            ...rest,
            ref: doc.ref,
            start: start ? start?.toDate() : null,
            end: end ? end?.toDate() : null,
            addedOn: addedOn ? addedOn?.toDate() : null,
          });
        });
        // console.log("data from useDevExperience", data);
        setExperience(data);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [devId]);

  return { experience, loading, error };
};

export const useDevProjects = (devId) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!devId) return;
    // console.log("useDevProjects...");
    const unsubscribe = colRef(`devs/${devId}/projectExp`).onSnapshot(
      (snapshot) => {
        let data = [];
        snapshot.forEach((doc) => {
          const { start, end, addedOn, ...rest } = doc.data();
          data.push({
            id: doc.id,
            ...rest,
            ref: doc.ref,
            start: start?.toDate() || null,
            end: end?.toDate() || null,
            addedOn: addedOn?.toDate() || null,
          });
        });
        setProjects(data);
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [devId]);

  return { projects, loading, error };
};

export const useDevAssessements = (profileAssessments = [], devID) => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [setRefetch, setRefetchState] = useState(false);

  // console.log("useDevAssessements...", { profileAssessments }, { assessments });

  // Memoize fetchVideoURL to avoid re-creation on every render
  const fetchVideoURL = useCallback(
    async (videoPath) => {
      const storage = getStorage();
      const videoRef = ref(
        storage,
        `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
      );
      try {
        return await getDownloadURL(videoRef);
      } catch (error) {
        console.error("Failed to fetch video URL", error);
        return null;
      }
    },
    [devID]
  );

  // Wrapped in useCallback to avoid re-creation unless dependencies change
  const fetchAssessments = useCallback(async () => {
    if (!profileAssessments.length > 0) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      // console.log("fetchAssessments...", profileAssessments);
      const assessmentPromises = profileAssessments.map(
        async ({ source, units }) => {
          const sourceDocPromise = source.get();
          // console.log("units...", units);
          const unitsDataPromises = units.map((unit) => fetchUnitData(unit));

          const [sourceDoc, unitsData] = await Promise.all([
            sourceDocPromise,
            Promise.all(unitsDataPromises),
          ]);

          const sourceData = {
            ...sourceDoc.data(),
            id: sourceDoc.id,
            ref: sourceDoc.ref,
          };

          return { sourceData, unitsData };
        }
      );

      const assessments = await Promise.all(assessmentPromises);
      setAssessments(assessments);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch assessments", error);
      setError(error);
      setLoading(false);
    }
  }, [profileAssessments, fetchVideoURL]);

  const fetchUnitData = async (unit) => {
    const unitSnapshot = await unit.get();
    const { questions, ...unitData } = unitSnapshot.data();
    if (!questions) {
      console.log("No questions available to fetch yet.");
      setRefetchState(true);
      return { id: unitSnapshot.id, ...unitData, questionsData: [] };
    }
    const questionsData = await Promise.all(
      questions.map((question) =>
        question ? fetchQuestionData(question) : Promise.resolve(null)
      )
    ).then((results) => results.filter((q) => q)); // Filter out null results if any question was undefined

    return { id: unitSnapshot.id, ...unitData, questionsData };
  };

  const fetchQuestionData = async (question) => {
    try {
      const questionSnapshot = await question.get();
      const questionData = questionSnapshot.data();
      const videoURL = questionData.videoPath
        ? await fetchVideoURL(questionData.videoPath)
        : null;

      const submissionsSnapshot = await questionSnapshot.ref
        .collection("submissions")
        .get();
      const submissionsData = submissionsSnapshot.docs.map((submission) => ({
        id: submission.id,
        ...submission.data(),
        ref: submission.ref,
      }));

      return {
        id: questionSnapshot.id,
        ...questionData,
        videoURL,
        submissionsData,
      };
    } catch (error) {
      console.error("Error fetching question data", error);
      return null; // Return null if there was an issue, handle this gracefully upstream
    }
  };

  useEffect(() => {
    if (profileAssessments.length) {
      fetchAssessments();
    } else {
      setLoading(false);
    }
  }, [fetchAssessments, profileAssessments]);

  useEffect(() => {
    if (setRefetch) {
      console.log("Refetching assessments...");
      const timer = setTimeout(() => {
        fetchAssessments();
        setRefetchState(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [setRefetch, fetchAssessments]);

  return { assessments, loading, error };
};

export const useDevFull = (devId, maxSecLev) => {
  const { stacks, stacksLoading, stacksError } = useDevStacks(devId);
  const { domains, domainsLoading, domainsError } = useDevDomains(devId);
  const { projects, projectsLoading, projectsError } = useDevCats(devId);
  const { avails, availsLoading, availsError } = useDevAvails(devId);
  const { vacations, vacationsLoading, vacationsError } =
    useDevVacations(devId);
  const {
    data: devPublic,
    loading: devPublicLoading,
    error: devPublicError,
  } = useDevPublic(devId);
  const { education, educationLoading, educationError } =
    useDevEducation(devId);
  const { experience, experienceLoading, experienceError } =
    useDevExperience(devId);
  const {
    projects: projectExp,
    projectsLoading: projectExpLoading,
    projectsError: projectExpError,
  } = useDevProjects(devId);

  // const { devRates, devRatesLoading, devRatesError, minDate } =
  //   useDevRateRanges(devId, maxSecLev);

  return {
    // dev,
    // devLoading,
    // error,
    stacks,
    stacksLoading,
    stacksError,
    domains,
    domainsLoading,
    domainsError,
    projects,
    projectsLoading,
    projectsError,
    avails,
    availsLoading,
    availsError,
    vacations,
    vacationsLoading,
    vacationsError,
    education,
    educationLoading,
    educationError,
    experience,
    experienceLoading,
    experienceError,
    projectExp,
    projectExpLoading,
    projectExpError,
    // devRates,
    // devRatesLoading,
    // devRatesError,
    devPublic,
    devPublicLoading,
    devPublicError,
    // minDate,
  };
};
