import { ClientLogoWrapper, ClientsList, ClientsWrapper } from "./style";

export const DevsClients = ({
  clients = [],
  title = "Clients",
  imgBorderColor = "#E1E1E1",
  imgHeight = "32px",
  imgBorderRadius = "5px",
  space = "5px",
  sx,
  imgListSx,
  cls,
  bg,
  pd,
  showBorderBtm = true,
  showNumber,
}) => {
  return (
    <>
      {clients?.length > 0 && (
        <ClientsWrapper
          style={sx}
          className={cls}
          bg={bg}
          pd={pd}
          showborderbtm={showBorderBtm}
        >
          <h3>{title}</h3>
          <ClientsList
            space={space}
            style={imgListSx}
            className={`${cls}_clients`}
          >
            {clients?.map((url, i) => {
              if (i < showNumber) {
                return (
                  <ClientLogoWrapper
                    key={`clients_${i}`}
                    bordercolor={imgBorderColor}
                    height={imgHeight}
                    imgborder={imgBorderRadius}
                    className={`${cls}_clients_logo`}
                  >
                    <img
                      src={url}
                      className={`${cls}_clients_logo_img`}
                      alt="client logo"
                    />
                  </ClientLogoWrapper>
                );
              }
            })}
            {clients?.length - showNumber > 0 && (
              <p>+{clients?.length - showNumber} more</p>
            )}
          </ClientsList>
        </ClientsWrapper>
      )}
    </>
  );
};
