export default class projectsList {
  constructor() {
    this.projects = [];
  }

  /**
   * Return the size of the projects list
   */
  size() {
    return this.projects.length;
  }

  /**
   * Checks if there is already a project with the same id
   * if it exists already if will skip
   * add new project to the project list
   * @param {class} project
   */
  addProject(project) {
    if (
      this.projects.length >= 1 &&
      this.projects.some((proj) => proj.TaskId === project.TaskId)
    ) {
      return;
    }
    this.projects.push(project);
  }

  /**
   * Loops through the sprints list and add new sprint to the list
   * @param {object} sprint
   */
  addSprintToProject(sprint) {
    let projectList = this.projects;
    for (const project of projectList) {
      project.addSprint(sprint);
    }
  }

  /**
   * return the list of projects
   */
  getProjects() {
    return this.projects;
  }
}
