import React, { Component } from "react";

export default class EditSkill extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      experienceInput: this.props.skill.experience,
    };
  }

  handChange(e) {
    let skill = { ...this.props.skill };
    // Handle for decimals
    if (e.target.type === "number" || e.target.id === "skill") {
      skill[e.target.id] = Math.floor(e.target.value);
    } else {
      skill[e.target.id] = e.target.value;
    }

    this.props.editSkill(
      this.props.resumeName,
      this.props.skillType,
      this.props.skillName,
      skill
    );
  }

  handChangeExperienceUnit(unit) {
    this.props.setExperienceUnit(
      this.props.resumeName,
      this.props.skillType,
      this.props.skillName,
      unit
    );
  }

  render() {
    return (
      <tr>
        <td>{this.props.skillName}</td>
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              width: "100%",
            }}
          >
            <div>
              <input
                type="range"
                min="1"
                max="4"
                value={this.props.skill.skill}
                step="1"
                id="skill"
                list="levels"
                onChange={(e) => this.handChange(e)}
              />
              <datalist id="levels">
                <option value="1" label="1">
                  1
                </option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </datalist>
            </div>
            <div style={{ marginTop: "-.5rem" }}>
              <output>{this.props.skill.skill}</output>
            </div>
          </div>
        </td>

        <td className="stack_entry_data">
          <div className="values">
            <input
              type="number"
              min="0"
              id="experience"
              value={this.props.skill.experience}
              onChange={(e) => this.handChange(e)}
            />
            <select
              name="time"
              id="time"
              onChange={(e) => {
                e.target.value === "years"
                  ? this.handChangeExperienceUnit("y")
                  : this.handChangeExperienceUnit("m");
              }}
            >
              <option value="years">Years</option>
              <option value="month">Months</option>
            </select>
          </div>
        </td>

        <td className="stack_entry_data">
          <input
            type="number"
            min="1"
            id="projects"
            value={this.props.skill.projects}
            onChange={(e) => this.handChange(e)}
          />
        </td>

        {/* <td className="stack_entry_data">
          <select name='interests' id='interest' value={this.props.skill.interests} onChange={e => this.handChange(e)}>
            <option value="Interested">Interested</option>
            <option value="Not Interested">Not Interested</option>
            <option value="Slightly Interested">Slightly Interested</option>
          </select>
        </td> */}
        <td>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="e-control e-btn"
              style={
                !this.props.skill.ignore
                  ? { background: "#1E96F5", color: "white" }
                  : { background: "#adadad", color: "white" }
              }
              onClick={() => {
                this.props.confirmSkill(
                  false,
                  this.props.resumeName,
                  this.props.skillType,
                  this.props.skillName
                );
              }}
            >
              Import
            </button>
            <button
              className="e-control e-btn"
              style={
                this.props.skill.ignore
                  ? { background: "#EB3570", color: "white" }
                  : { background: "#adadad", color: "white" }
              }
              onClick={() => {
                this.props.confirmSkill(
                  true,
                  this.props.resumeName,
                  this.props.skillType,
                  this.props.skillName
                );
              }}
            >
              Exclude
            </button>
          </div>
        </td>
      </tr>
    );
  }
}
