import React from "react";
import { MdExpandMore } from "react-icons/md";
import SpToggle from "../../../../globals/SpToggle/SpToggle";
import AddIcon from "../../../../svgs/location/AddIcon";
import CheckBox from "../../../../svgs/location/CheckBox";
import CollapseIcon from "../../../../svgs/location/CollapseIcon";
import UnCollapseIcon from "../../../../svgs/location/UnCollapseIcon";

const StepInfo = ({
  stepTitle,
  canAddTuc,
  dueDay,
  setShowCustomReq,
  setShowListOptions,
  showListOptions,
  setOptionParms,
  optionParms,
  selectedUserRole,
}) => {
  const [expend, setExpend] = React.useState(false);
  // const onOptionToggle = (e) => {
  //   console.log(e);
  // };
  return (
    <div
      className="_spReqTeam--OptionThird__info"
      style={{
        borderBottom: showListOptions && "1px solid #c4c4c4",
      }}
    >
      <div className="_spReqTeam--OptionThird__info--left">
        <div className="reqStepCount">
          <p>2</p>
        </div>

        <h3 className="reqStepTitle">{stepTitle}</h3>
        <div className="stepThreeDue">
          <CheckBox />
          <p>
            {dueDay > 0 ? "Due" : "Started"} {Math.abs(dueDay)} days{" "}
            {dueDay > 0 ? "From now" : "ago"}
          </p>
        </div>
        {selectedUserRole !== "partner" && (
          <div
            className="stepThreeAddMore sp_hover"
            onClick={() => {
              canAddTuc && setShowCustomReq(true);
            }}
          >
            <AddIcon />
            <p>Add custom requirement</p>
          </div>
        )}
      </div>
      <div className="_spReqTeam--OptionThird__info--right">
        {selectedUserRole !== "partner" && (
          <div className="_spReqTeam--OptionThird__info--right-options">
            <div
              className="sp_hover"
              onClick={() => setExpend((prev) => !prev)}
            >
              <h4>Options</h4>
              <MdExpandMore size={30} fill={"#4361e8"} />
            </div>
            {expend && (
              <div className="_spReqTeam--OptionThird__info--right-options--list">
                <div>
                  <p>Recommendations inline</p>
                  <SpToggle
                    checked={optionParms?.isInlineRecom}
                    onChange={(e) => {
                      // console.log(e.target.checked);
                      setOptionParms({
                        ...optionParms,
                        isInlineRecom: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div>
                  <p>Options inline</p>
                  <SpToggle
                    onChange={(e) => {
                      setOptionParms({
                        ...optionParms,
                        isInlineOption: e.target.checked,
                      });
                    }}
                    checked={optionParms?.isInlineOption}
                  />
                </div>
                <div>
                  <p>Hide Dismissed</p>
                  <SpToggle
                    checked={optionParms?.hideDismissedDev}
                    onChange={(e) => {
                      setOptionParms({
                        ...optionParms,
                        hideDismissedDev: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {!showListOptions ? (
          <CollapseIcon
            cls={"sp_hover"}
            onClick={() => setShowListOptions(true)}
          />
        ) : (
          <UnCollapseIcon
            cls={"sp_hover"}
            onClick={() => setShowListOptions(false)}
          />
        )}
      </div>
    </div>
  );
};

export default StepInfo;
