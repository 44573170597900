import React from "react";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { SampleBase } from "../../sample-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default class financial extends SampleBase {
  constructor() {
    super(...arguments);
    this.publicCompanyDropDown = ["YES", "NO"];
    this.dropDownModel = {
      dataSource: this.publicCompanyDropDown,
    };
  }
  updateVendorData(data) {
    const updates = {};
    updates.financial = this.props.financial;

    if (data.name === "publicCompany" && data.value === "YES") {
      updates.financial[data.name] = true;
    } else if (data.name === "publicCompany" && data.value === "NO") {
      updates.financial[data.name] = false;
    } else {
      updates.financial[data.name] = parseFloat(data.value);
    }
    try {
      this.props.firebase.updateVendor(this.props.vendor.id, updates);
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <div className="stat">
        <div className="stat_header">
          <div className="title">
            <h1>Finance</h1>
          </div>
          <div className="desc-icon">
          <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
          <div className="description">
            Adjust your financial information here. This data is never shared or
            revealed on the platform. It is used to calibrate and inform your
            own reports, which can provide personalized information about your
            standing and competitors
          </div>
        </div>

        </div>

        <div className="stat_content">
          <div className="stat_content_data stat_content_avg row">
            <div className="stat_content_data-title">
              <h4>Averages</h4>
            </div>

            <div className="stat_content_data-content">
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="avgYearlyIncome">
                    Average Yearly Income
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="avgYearlyIncome"
                    mode="Inline"
                    type="Numeric"
                    value={
                      this.props.financial.avgYearlyIncome
                        ? this.props.financial.avgYearlyIncome
                        : ""
                    }
                    model={this.props.dollarModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    We use this information to provide you with targeted offers
                    and reports. This is never shared.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="avgRateSprint">
                    Average Sprint Rate
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="avgRateSprint"
                    mode="Inline"
                    type="Numeric"
                    value={this.props.financial.avgRateSprint}
                    model={this.props.dollarModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    This information is calculated from the developers you have
                    entered on the platform
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="avgRateHr">
                    Average Hourly Rate
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="avgRateHr"
                    mode="Inline"
                    type="Numeric"
                    value={this.props.financial.avgRateHr}
                    model={this.props.dollarModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    This information is calculated from the developers you have
                    entered on the platform
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="stat_content_mg stat_content_data row">
            <div className="stat_content_data-title">
              <h4>Margins</h4>
            </div>

            <div className="stat_content_data-content">
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="netMarginP">
                    Net Margin
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="netMarginP"
                    mode="Inline"
                    type="Numeric"
                    value={this.props.financial.netMarginP}
                    model={this.props.percentModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    We use this information to provide you with targeted reports
                    on how you perform against your competitors. This is never
                    shared.{" "}
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="grossMarginP">
                    Gross Margin
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="grossMarginP"
                    mode="Inline"
                    type="Numeric"
                    value={this.props.financial.grossMarginP}
                    model={this.props.percentModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    We use this information to provide you with targeted reports
                    on how you perform against your competitors. This is never
                    shared.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="stat_content_other stat_content_data row">
            <div className="stat_content_data-title">
              <h4>Others</h4>
            </div>

            <div className="stat_content_data-content">
              <div className="column">
                <div className="row top">
                  <label className="vendor-info" htmlFor="publicCompany">
                    Public Company
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) => this.updateVendorData(e.data)}
                    ref={(text) => {
                      this.textObj = text;
                    }}
                    id="textboxEle"
                    name="publicCompany"
                    mode="Inline"
                    type="DropDownList"
                    value={this.props.financial.publicCompany}
                    model={this.dropDownModel}
                    popupSettings={this.popupSettings}
                  ></InPlaceEditorComponent>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    If you are a public company, let us know and we can pull
                    automated information which can improve your rankings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
