import React, { useState, useEffect, useContext, useMemo } from "react";
import AllRequirements from "./pages/Requirements/AllRequirements";
import { GlobalVendorContex, AuthUserContext } from "./Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { MdVerified } from "react-icons/md";
import "../css/danRqStyle.css";
// import { analytics } from "../components/Firebase/firebase";
import { useDynamicPaginator } from "./hooks/useDynamicPaginator";
import useSortableData from "./SortingData.js/Sortable";
import RenderagreedUser from "./pages/Requirements/RenderagreedUser";
import TermsDialog from "./pages/Requirements/TermsDialog";
import PageTop from "./globals/PageTop";
import RequirementFilters from "./pages/Requirements/RequirementFilters";
import ReqAgreement from "./pages/Requirements/ReqAgreement";

const Requirements = (props) => {
  const [currentList, setCurrentList] = useState([]);
  const [filtOption, setFiltOption] = useState(0);
  const vendorData = useContext(GlobalVendorContex);
  const userData = useContext(AuthUserContext);
  const [viewTerm, setViewTerm] = useState(false);
  const [lastTerm, setLasTerm] = useState({});
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    type: "",
  });

  const reqLists = useMemo(() => {
    let currentL = vendorData?.reqsList?.map((req) => {
      // const { partners, ...reqObj } = req;
      const reqObj = { ...req };
      let compets =
        reqObj?.partners?.filter((el) => {
          if (vendorData.searchedVendor.id)
            return el.id !== vendorData.searchedVendor.id;
          return el.id;
        }) ?? [];
      reqObj.competitors = compets?.length ?? 0;
      return reqObj;
    });
    return currentL?.filter((list)=>list?.show);
  }, [vendorData?.reqsList, vendorData?.searchedVendor?.id]);

  const { items, requestSort } = useSortableData(currentList, {
    key: "priority",
    direction: "descending",
  });

  const { currentData, viewingIndex, renderButtons } = useDynamicPaginator(
    items,
    10
  );

  useEffect(() => {
    if (!vendorData.searchedVendor.id) return;
    let lastTerm;
    const getDataOnce = async () => {
      let spryteCollections = await props.firebase.db
        .collection("organisations")
        .where("name", "==", "Spryte Labs")
        .get();
      lastTerm = spryteCollections?.docs[0]?.ref
        .collection("terms")
        .where("platform", "==", "PARTNER")
        .orderBy("version", "desc")
        .onSnapshot((snap) => {
          let lastTermDoc = snap.docs[0]?.data();
          lastTermDoc["ref"] = snap.docs[0].ref;
          lastTermDoc["id"] = snap.docs[0].id;

          setLasTerm(lastTermDoc);
        });
    };
    getDataOnce();
    return () => {
      try {
        lastTerm();
      } catch (error) {}
    };
  }, [props.firebase.db, vendorData.searchedVendor.id]);

  // =======================================Listen to the Filtering option to set array of current list===============================================
  useEffect(() => {
    const filterList = () => {
      switch (filtOption) {
        case 0:
          setCurrentList(reqLists ?? []);
          break;

        case 1:
          let confirmed =
            reqLists?.filter((item) => item.currentStep > 0) ?? [];
          setCurrentList(confirmed);
          break;

        case 2:
          const getOpenToMe = () => {
            let vendorList = {};
            reqLists?.forEach((list) => {
              list?.partners?.forEach((partner) => {
                if (vendorData.searchedVendor.id === partner.id || list?.open) {
                  vendorList[list.id] = list;
                }
              });
            });
            setCurrentList(Object.values(vendorList));
          };
          getOpenToMe();
          break;

        default:
          break;
      }
    };
    filterList();
  }, [filtOption, reqLists, vendorData.searchedVendor.id, viewingIndex]);

  const onStatusFilter = (value) => {
    setFilters({ ...filters, status: value });
    if (value === "all") {
      setCurrentList(reqLists ?? []);
    }
    if (value === "open") {
      let vendorList = {};
      reqLists?.forEach((list) => {
        list?.partners?.forEach((partner) => {
          if (vendorData.searchedVendor.id === partner.id || list?.open) {
            vendorList[list.id] = list;
          }
        });
      });
      setCurrentList(Object.values(vendorList));
    }
    if (value === "confirmed") {
      let confirmed = reqLists?.filter((item) => item.currentStep > 0) ?? [];
      setCurrentList(confirmed);
    }
  };

  const onTypeFilter = (value) => {
    setFilters({ ...filters, type: value });
    if (value === "all") {
      setCurrentList(reqLists ?? []);
    }
    if (value === "project") {
      let project =
        reqLists?.filter((item) => !item?.requirements?.staffing) ?? [];
      setCurrentList(project);
    }
    if (value === "staffing") {
      let staffing =
        reqLists?.filter((item) => item?.requirements?.staffing) ?? [];
      setCurrentList(staffing);
    }
  };
  /**
   * Takes an array of requirement names and use it to render a dom
   * @param {array} reqs array containing requirement names
   * @returns a tags do display requirements
   */
  const renderReqs = (reqs) => {
    let el = [];
    for (let i = 0; i < reqs.length; i++) {
      if (i < reqs.length - 1) {
        el.push(`${reqs[i]}, `);
      } else {
        el.push(`${reqs[i]} `);
      }
    }
    return (
      <div className="row">
        <span
          style={{
            fontWeight: 600,
            fontStyle: !el.length > 0 ? "italic" : "inherit",
            opacity: !el.length > 0 ? ".35" : "inherit",
          }}
        >
          {el.length > 0 ? el.slice(0, 10) : "No Requirement"}
        </span>
      </div>
    );
  };

  /**
   * setting the filter option to the value of the selected option
   * @param {event} e Event of the select tag
   */
  const filtData = (e) => {
    // TODO: Track When Filter Type Changed
    // analytics.track("Filter Type Changed on Open Requirements Page", {
    //   filter_type_selected: e.target.value,
    // });
    setFiltOption(parseInt(e.target.value));
    e.persist();
  };

  const onAgree = async () => {
    let updates = {
      terms: {
        agreedBy: userData.userRef,
        agreedOn: new Date(),
        ref: lastTerm.ref,
      },
    };

    await props.firebase.updateVendor(vendorData.searchedVendor.id, updates);
    setViewTerm(false);
    vendorData.setIsVendorUpdated(true);
  };

  return (
    <div className="requirements">
      <div>
        {viewTerm ? (
          <TermsDialog
            lastTerm={lastTerm}
            onAgree={onAgree}
            onCancel={() => setViewTerm(false)}
            vendorData={vendorData}
          />
        ) : (
          <></>
        )}

        <div className="requirements__header">
          <PageTop
            title="Open Requirements"
            subtitle="Clients are considering  your teams and may need more information"
            cls="requirements__header__title"
          />
          <div className="requirements__header__filters">
            <div className="requirements__header__filters__status">
              <select
                value={filters.status}
                onChange={(e) => onStatusFilter(e.target.value)}
              >
                <option value="all">All Req.</option>
                <option value="confirmed">Confirmed</option>
                <option value="open">Open To Me</option>
              </select>
            </div>

            <div className="requirements__header__filters__types">
              <select
                value={filters.type}
                onChange={(e) => onTypeFilter(e.target.value)}
              >
                <option value="all">All Type</option>
                <option value="project">Managed Project</option>
                <option value="staffing">Staffing</option>
              </select>
            </div>
          </div>
        </div>

        <ReqAgreement
          vendorData={vendorData}
          lastTerm={lastTerm}
          setViewTerm={setViewTerm}
        />
        {/* <RequirementFilters
          filters={filters}
          setFilters={setFilters}
          onStatusFilter={onStatusFilter}
          onTypeFilter={onTypeFilter}
        /> */}

        <div className="control-pane requirements_body">
          <AllRequirements
            allItems={vendorData?.reqsList}
            renderReqs={renderReqs}
            lists={currentData ?? []}
            currentList={currentList}
            setFiltOption={setFiltOption}
            filtOption={filtOption}
            isLoading={vendorData.isListLoading}
            vendor={vendorData.searchedVendor}
            lastTerm={lastTerm}
            isSuperPartner={userData.isSuperPartner}
            requestSort={requestSort}
            items={currentData}
          />

          {vendorData?.reqsList?.length > 0 && renderButtons()}
        </div>
      </div>
    </div>
  );
};

export default Requirements;
