import React from "react";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";

const Security = (props) => {
  return (
    <div className="stat_content_data stat_content_rev row">
      <div className="stat_content_data-title">
        <h4>Security</h4>
      </div>
      <div className="stat_content_data-content">
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Auto Rate Mode</label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) =>
                props.updateVendorData("securitySettings", e.data)
              }
              id="textboxEle"
              name="securityLevelRateMode"
              mode="Inline"
              type="DropDownList"
              value={
                props.settings?.securitySettings?.securityLevelRateMode
                  ? "YES"
                  : "NO"
              }
              model={props.dropDownModel}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              Auto rate mode calculates the rates for all your developers for
              each security/process certification level automatically.
            </p>
          </div>
        </div>
        <div className="column">
          <div className="row">
            <label className="vendor-info">Rate Overhead</label>
          </div>
          <div className="row top">
            {props.securityOverHeads.map((entry, i) => {
              const [secLev, overhead] = entry;
              return (
                <div key={i}>
                  <label
                    className="vendor-info"
                    style={{ marginRight: "12px" }}
                  >
                    {secLev}
                  </label>
                  <InPlaceEditorComponent
                    className="inPlaceEditor"
                    actionBegin={(e) =>
                      props.updateVendorData("securitySettings", e.data, true)
                    }
                    id="textboxEle"
                    name={secLev}
                    mode="Inline"
                    type="Numeric"
                    value={overhead ? overhead : 0}
                    model={props.percentModel}
                  ></InPlaceEditorComponent>
                </div>
              );
            })}
          </div>
          <div className="bottom light">
            <p>
              Clients recognize that asking for better processes and tighter
              security has a cost. You can adjust the overhead costs of each
              security/process level automatically here. When "Auto Rate Mode"
              is set to YES, developers are engaged at the rate specified by the
              client.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
