import React from "react";
import LocIcon from "../../../svgs/location/LocIcon";
import { renderDevRates } from "../../../globals/SpDevCard/devProfile/DevProfile";
import {
  DevDetails,
  DevPricing,
  DevProfileBorder,
  DevProfileInfo,
  DevProfileWrapper,
} from "../../SingleRequirement/reqTeam/listDev/style";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";
// import { RANDOMDEVIMG } from "../../../utils/ListUtils";
import { useCurrentDevRate } from "../../../hooks/useCurrentDevRate";

const SingleMatchDev = ({ devData, ...props }) => {
  const { devRate, isExpired } = useCurrentDevRate(devData?.id);
  return (
    <DevProfileInfo className="requirement--matchCard" {...props}>
      <DevProfileWrapper
        color={"green"}
        size={"40px"}
        className="requirement--matchCard_left"
      >
        <DevProfileBorder
          color={"green"}
          size={"40px"}
          className="requirement--matchCard_lef--img"
        >
          <SpResponsiveImg
            src={devData?.logoStorage}
            alt={"dev profile"}
            size={"40px"}
            borderRadius="50%"
          />
        </DevProfileBorder>
        <div className="devInfoMatch">
          <span
            style={{
              color: "#3FDD98",
              fontWeight: "bold",
            }}
          >
            {devData?.reqMatchScore}%
          </span>
        </div>
      </DevProfileWrapper>

      <DevDetails className="requirement--matchCard_devInfo">
        <h4 className="sp_hover" onClick={() => {}}>
          {devData?.first + " " + devData?.last}
        </h4>

        <div className="listDevLoc">
          {devData?.locationData && <LocIcon />}
          <span>
            {(devData?.locationData?.sublocality_level_1
              ? devData?.locationData?.sublocality_level_1 + ", "
              : "") + (devData?.locationData?.locality ?? "")}
          </span>
        </div>
        {renderDevRates("16px", "16px", devData?.rating, "#3FDD98", true)}
      </DevDetails>
      {/* {devData?.partnerHourlyRate ? ( */}
      <DevPricing className="requirement--matchCard_devPrice">
        <span
          className="amountText"
          style={{ color: isExpired ? "red" : null }}
        >
          ${devRate}
        </span>
        <span>Per Hr</span>
      </DevPricing>
      {/* ) : (
        devData?.rates?.length > 0 && (
          <DevPricing
            className={selectedUserRole === "admin requirement--matchCard_devPrice" ? "sp_hover requirement--matchCard_devPrice" : ""}
            onClick={() => {
              if (selectedUserRole === "admin") onEditOption();
            }}
          >
            <span className="amountText">
              ${Math.min.apply(Math, devData?.rates).toFixed(2) ?? "--"}
            </span>
            <span>Per Hr</span>
          </DevPricing>
        )
      )} */}
    </DevProfileInfo>
  );
};

export default SingleMatchDev;
