import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import React from "react";
import { GetNowTimeStamp } from "../globals/services";

export default function statusSwitchTemplate(args) {
  return (
    <div>
      <SwitchComponent
        key={`status ${args?.id}`}
        id={`status`}
        checked={args?.status}
        change={async (e) => {
          await args.devRef.update({
            status: e.checked,
            lastUpdated: GetNowTimeStamp(),
          });
        }}
      />
    </div>
  );
}
