import React, { useEffect, useState, useContext } from "react";
import progessTemplate from "./grid_templates/progressTemplate";
import { findPhoneNumbersInText, parsePhoneNumber } from "libphonenumber-js";
import SubsPage from "./pages/Certification/SubsPage";
import UnSubs from "./pages/Certification/UnSubs";
import HeaderCert from "./pages/Certification/Subs/HeaderCert";
import { GlobalVendorContex, AuthUserContext } from "./Firebase";

// REMINDER: the Firebase class from ./Firebase/firebase.js is passed to
// this file with props using the context api

const Certification = (props) => {
  const vendorData = useContext(GlobalVendorContex);

  // User data
  const allData = useContext(AuthUserContext);

  //country list to convert the country to an existing country
  const { getCode } = require("country-list");

  // Datasource for syncfusion grid
  const [certs, setCerts] = useState([]);

  //subscribed clients
  const [subs, setSubs] = useState([]);

  //All existing Domains
  const [domains, setDomains] = useState([]);

  //Spryte contact Email
  const [email, setEmail] = useState("");

  const [isSubs, setIsSubs] = useState(false);

  //risk score progress color
  const [riskColor, setRiskColor] = useState("");

  //trust score progress color
  const [trustColor, setTrustColor] = useState("");

  //all scores state
  const [scores, setScores] = useState({
    riskScore: 0.0,
    trustScore: 0.0,
  });

  //Your Certification Point of Contact
  const [interCont, setInterCont] = useState({
    email: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
  });

  //Your Spryte Certification Manager
  const [vendorCont, setVendorCont] = useState({
    email: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
  });

  // Parameters for Syncfusion Grid
  const editSettings = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
    mode: "Dialog",
  };

  //contact error
  const [contactErr, setContactErr] = useState("");

  //
  //
  // ///////////////////////////////////// Set Score Color /////////////////////////////////////////////////////////////////////////
  //
  //
  //setScoreColor is a function to change the color of
  //the scores progressbars
  //if the score  is  greater or equal to the 70 the color should be green() #57CA90;
  //if the score is between 70 and 50 we will set the colore to orange #F45E40;
  //if the score is less or equal to the 50 then we will set the color to red #B93329
  //!args*=> score and whichScore
  //TODO
  //*Set Function
  const setScoreColor = (score, whichScore) => {
    let colorToUse;
    //*Use if Statement to set color red color
    //#=>when score is greater than 70
    if (score >= 70) {
      //Good
      colorToUse = whichScore === "trustScore" ? "#57CA90" : "#B93329";
    } else if (score < 70 && score >= 50) {
      //#=>when score is between 50 and 70
      //Careful
      colorToUse = "#F45E40";
    } else if (score < 50) {
      //#=>when score is less than 50
      //Bad
      colorToUse = whichScore === "trustScore" ? "#B93329" : "#57CA90";
    }
    return colorToUse;
  };

  //
  //
  // ///////////////////////////////////// Format Phone Number /////////////////////////////////////////////////////////////////////////
  //
  //
  /**
   * formatNumber is  a function that takes the vendor  country and the contact phone
  * number and format it on the way it work internationally
  * if the country does not existit it will leave the number as  it is. 
  * The funtion uses the the country list package to get the country code base on the country in the locationdata
  * in the vendor collection
  * then uses the libphonenumber-js to find the phone number format data  base  on the contact number and the  country code
  * Then parse the phone number and return the formatNumber

  *@param {*} vendorCountry => is the country from location data or United State for the internal contact
  * @param {*} contactNumber => number from contacts in the tptData    
  */

  const formatNumber = (vendorCountry, contactNumber) => {
    let countryCode = getCode(vendorCountry);
    let numberData = findPhoneNumbersInText(contactNumber, countryCode);

    let finalNumber;
    if (numberData.length >= 1) {
      let phoneNumber = numberData[0].number.number;
      let parsedNumber = parsePhoneNumber(phoneNumber);
      finalNumber = parsedNumber.format("NATIONAL");
    } else {
      finalNumber = contactNumber;
    }
    return finalNumber;
  };

  //
  //
  // ///////////////////////////////////// UseEffect get Certification Informations /////////////////////////////////////////////////////////////////////////
  //
  //
  useEffect(() => {
    const getData = async () => {
      setIsSubs(vendorData?.vendor?.thirdPartyTrust?.accountFound ?? false);
      let domainsList = Object.keys(vendorData.domainData.domainsObject);
      let subsClient = await props.firebase.getSubsClients();
      let email = await props.firebase.getEmail();
      // let email = await props.firebase.getEmail();
      //check if the account is found in thirdPartyTrust website
      if (vendorData?.vendor?.thirdPartyTrust?.accountFound) {
        let tptData = vendorData?.vendor.thirdPartyTrust.tptData;

        let interContact = tptData?.internalContacts[0];
        let vendorContacts = tptData?.contacts[0];

        let vendorCountry = vendorData?.vendor.locationData.country;
        if (interContact) {
          let internalNumber = formatNumber(
            vendorCountry,
            interContact.phone_number
          );
          setInterCont({
            email: interContact.email,
            firstName: interContact.first_name,
            lastName: interContact.last_name,
            jobTitle: interContact.job_title,
            phoneNumber: internalNumber,
          });
        } else if (vendorContacts) {
          let externalNumber = formatNumber(
            "United States of America",
            vendorContacts.phone_number
          );

          setVendorCont({
            email: vendorContacts.email,
            firstName: vendorContacts.first_name,
            lastName: vendorContacts.last_name,
            jobTitle: vendorContacts.job_title,
            phoneNumber: externalNumber,
          });
        } else if (!interContact || !vendorContacts) {
          setContactErr(
            `Please contact your account manager at ${email} to help with your certification contact informations`
          );
        }

        let trustColor = setScoreColor(
          vendorData?.vendor.certification.trustScore,
          "trustScore"
        );
        let riskColor = setScoreColor(
          vendorData?.vendor.certification.riskScore,
          "riskScore"
        );

        setRiskColor(riskColor);
        setTrustColor(trustColor);

        let allScores = vendorData?.vendor.certification;
        let riskScore =
          (100 + allScores.impactScore - allScores.trustScore) / 2;
        setScores({
          riskScore: riskScore,
          trustScore: allScores.trustScore,
        });

        let temp = await props.firebase.getCertifications(vendorData?.vendor);
        setCerts(temp);
      }

      setDomains(domainsList);
      setSubs(subsClient);
      setEmail(email);
    };
    getData();
    // props.firebase.reportPageVisits(
    //   "Certification Page",
    //   allData.isSuperPartner,
    //   {
    //     "Vendor Id": vendorData.vendor.id,
    //     "Vendor Name": vendorData.vendor.companyName,
    //   }
    // );
  }, [vendorData?.vendor]);

  //
  //
  // ///////////////////////////////////// On query Cell info to change status bar /////////////////////////////////////////////////////////////////////////
  //
  //
  // this function get all of the grid data
  // displayScore is the va
  const onQueryCellInfo = (args) => {
    if (args.column.field === "status") {
      // the colored bar width use the score percentage
      // and the text will use the score plus % sign
      args.cell.querySelector(".bar").style.width = args.data.score + "%";
      args.cell.querySelector(".barlabel").textContent = args.data.score + "%";
      if (args.data.status === false && args.data.score === 100) {
        args.cell.querySelector(".bar").classList.add("progressdisableb");
      }
      if (args.data.status === false && args.data.score < 100) {
        args.cell.querySelector(".bar").classList.add("progressdisable");
      }
    }
  };

  //
  //
  // ///////////////////////////////////// Certification header information /////////////////////////////////////////////////////////////////////////
  //
  //
  const headeCert = (
    <HeaderCert
      scores={scores}
      trustColor={trustColor}
      riskColor={riskColor}
      interCont={interCont}
      vendorCont={vendorCont}
      contactErr={contactErr}
    />
  );

  return (
    <div className="certification">
      {isSubs ? (
        <SubsPage
          headerCert={headeCert}
          certs={certs}
          editSettings={editSettings}
          onQueryCellInfo={onQueryCellInfo}
          progessTemplate={progessTemplate}
        />
      ) : (
        <UnSubs subs={subs} email={email} domainsList={domains} />
      )}
    </div>
  );
};

export default Certification;
