import React, { useState, useContext } from "react";
import {
  Selection,
  Inject,
  Filter,
  Page,
  Edit,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { ResourcesContext } from "../../Firebase/resources/ResourcesContext";

import {
  ExcelExport,
  PdfExport,
  Group,
  Toolbar,
  Search,
} from "@syncfusion/ej2-react-grids";
import { GlobalVendorContex } from "../../Firebase";
import { useNavigate } from "react-router-dom";
function DraftResources(props) {
  const router = useNavigate();
  const { setViewableDevs, setIsViewDevsDetail, setViewingDevData } =
    useContext(ResourcesContext);
  const { setDevName, setIsVerifiedResource, viewDraftDev, viewingSection } =
    useContext(GlobalVendorContex);

  const [selectedDraft, setSelectedDraft] = useState(null);
  const draftToolbar = [
    "Delete",
    {
      text: "View Dev",
      tooltipText: "View Dev",
      prefixIcon: "e-view",
      id: "view_dev",
    },
  ];

  const selectionSettings = {
    mode: "Row",
    cellSelectionMode: "Flow",
    type: "Single",
  };

  const onDraftRowSelect = async (args) => {
    if (args?.data?.id) {
      setSelectedDraft(args.data);
      setViewingDevData(args.data);
      viewDraftDev.current = args.data;
      viewingSection.current = "Draft Resources";
      setIsVerifiedResource(true);
    }
  };

  const onRowDeselect = (args) => {
    setSelectedDraft(null);
    setViewingDevData(null);
    // viewDraftDev.current = null;
  };

  const onToolbarClicked = (args) => {
    switch (args.item.text) {
      case "View Dev":
        viewingSection.current = "Draft Resources";
        setViewableDevs(props.draftDevs);

        let name =
          viewDraftDev.current?.first + " " + viewDraftDev.current?.last;

        setDevName(name);
        router(`/resources/${selectedDraft?.id}`);

        break;

      // case "Delete":
      //   // console.log(props.gridRef);
      //   // selectedDraft?.devRef?.delete();
      //   // props?.gridRef?.current?.refresh();

      //   // props?.gridRef?.current?.clearSelection();

      //   // setSelectedDraft(null);

      //   break;

      default:
        break;
    }
  };

  const onActionComplete = (args) => {
    // console.log(args);
  };
  const onActionBegin = async (args) => {
    // console.log(args);
    if (args?.requestType === "delete") {
      if (args?.data?.length > 0) {
        await Promise.all(
          args?.data.map(async (dev) => {
            await dev?.devRef?.delete();
          })
        );

        props.gridRef.current.refresh(); // Refresh the grid to reflect changes
      }
    }
  };

  return (
    <GridComponent
      id="overviewgrid"
      className="data-grid"
      dataSource={[]}
      ref={props.gridRef}
      toolbar={selectedDraft ? draftToolbar : []}
      allowSorting={false}
      width="100%"
      loading={false}
      // queryCellInfo={props.queryCellInfo}
      allowPaging={true}
      // allowSelection={props.allowSelection}
      // allowExcelExport={props.allowExcelExport}
      editSettings={{
        allowEditing: false,
        allowAdding: false,
        allowDeleting: true,
        showDeleteConfirmDialog: true,
      }}
      // allowPdfExport={props.allowPdfExport}
      // allowGrouping={props.allowGrouping}
      toolbarClick={onToolbarClicked}
      rowSelected={onDraftRowSelect}
      rowDeselected={onRowDeselect}
      pageSettings={{ pageSize: 10 }}
      dataStateChange={props.draftDataStateChange}
      selectionSettings={selectionSettings}
      actionComplete={onActionComplete}
      actionBegin={onActionBegin}
    >
      <ColumnsDirective>
        <ColumnDirective type="checkbox" width="40"></ColumnDirective>
        <ColumnDirective
          field="devID"
          headerText="Internal ID"
          width="120"
          textAlign="Center"
          validationRules={props.validationRules}
          defaultValue={"0"}
        ></ColumnDirective>
        <ColumnDirective
          field="id"
          required={false}
          headerText="Developer ID"
          width="220"
          textAlign="Center"
        ></ColumnDirective>
        <ColumnDirective
          field="first"
          headerText="First Name"
          width="120"
          textAlign="Center"
          validationRules={props.validationRules}
        ></ColumnDirective>
        <ColumnDirective
          field="last"
          headerText="Last Name"
          width="120"
          textAlign="Center"
          validationRules={props.validationRules}
        ></ColumnDirective>
        <ColumnDirective
          field="position"
          headerText="Position"
          width="130"
          editType="dropdownedit"
          textAlign="Center"
          edit={props.positions}
          validationRules={
            props.isDup ? props.norequired : props.validationRules
          }
        />
        <ColumnDirective
          field="resume"
          required={false}
          headerText="Resume"
          width="150"
          textAlign="Center"
          template={props.resumeDwload}
        ></ColumnDirective>
        <ColumnDirective
          field="locTag"
          headerText="Location"
          width="120"
          textAlign="Center"
          validationRules={
            props.isDup ? props.norequired : props.validationRules
          }
        ></ColumnDirective>
      </ColumnsDirective>

      <Inject
        services={[
          Search,
          Selection,
          Page,
          Toolbar,
          Edit,
          Filter,
          ExcelExport,
          PdfExport,
          Group,
        ]}
      />
    </GridComponent>
  );
}

export default DraftResources;
