import styled from "styled-components";
import { spPrimaryColor } from "../../stylesConstant";

export const StacksWrapper = styled.div`
  background-color: ${({ bg }) => bg && bg};
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 118px;
  box-sizing: border-box;
  flex-grow: 3;
  overflow: hidden;
  @media (max-width: 596px) {
    max-height: 218px;
    min-height: 135px;
  }
`;
export const StackTitle = styled.div`
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
  align-items: center;
  h3 {
    font-size: 12px;
    min-width: fit-content;
  }
  span {
    font-size: 12px;
  }
`;
export const StacksList = styled.div`
  display: flex;
  flex-wrap: wrap;
  // gap: 5px;
  row-gap: 10px;
  column-gap: 5px;
  overflow: hidden;
  box-sizing: border-box;
  ._criteriaExpertise {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    min-width: 50px;
  }
`;
export const SingleStack = styled.div`
  width: fit-content;
  display: flex;
  gap: 2px;
  background-color: ${({ showstackbg }) => showstackbg && "#F4F5F9"};
  // height: 29px;
  align-items: center;

  .stackLeft {
    // flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    gap: 5px;
    max-width: 88px;
    overflow: hidden;
  }
  // img {
  //   width: 20px;
  //   height: 20px;
  //   object-fit: contain;
  // }
  p {
    font-size: 14px;
    color: ${({ showstackbg }) => (!showstackbg ? "#868DA9" : "#868DA9")};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 60px;
  }
  .stackRight {
    background-color: ${spPrimaryColor};
    color: white;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 12px;
    }
  }
`;
