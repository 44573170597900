import styled from "styled-components";

export const ToogleEl = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ToggleCover = styled.div`
  position: relative;
  width: 54px;
  height: 26px;
  overflow: hidden;
  perspective: 60px;
  overflow: visible;
  background-color: transparent;
  .checkbox:checked + ._spToggleknobs:before,
  .checkbox:checked + ._spToggleknobs span {
    left: 31px;
  }

  // .checkbox:checked + ._spToggleknobs:before {
  // transform: rotateZ(180deg);
  //   background-color: #f44336;
  // }
  .checkbox:checked + ._spToggleknobs span:before {
    left: 20px;
  }

  .checkbox:checked ~ ._spToggleLayer {
    background-color: ${({ inactivecolor }) => inactivecolor};
    // transform: rotateY(-180deg);
  }

  ._spToggleknobs,
  ._spToggleknobs:before,
  ._spToggleLayer {
    transition: 0.3s ease all;
  }
`;
export const ToggleLayer = styled(ToogleEl)`
  border-radius: 100px;
  width: 100%;
  background-color: ${({ activecolor }) => activecolor};
  transition: 0.3s ease all;
  z-index: 1;
  &::before {
    transform: rotateY(0);
    transform-origin: center;
  }
`;

export const InputEl = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
`;
export const Knobs = styled(ToogleEl)`
  z-index: 2;
  &::before,
  & span {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 0px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }
  &::before {
    background-color: ${({ knobcolor }) => knobcolor || "#fff"};
    transform: rotateY(0);
    transform-origin: center;
  }
`;
