import { useRef, useEffect, useState, useCallback, useMemo } from "react";

const useFileUploader = ({
  onFileSelect,
  accept = "",
  multiple = false,
  ...rest
}) => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const openFileDialog = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = useCallback(
    (event) => {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
        setSelectedFiles(files);
        onFileSelect(files);
      }
    },
    [onFileSelect]
  );

  const fileInputElement = useMemo(
    () => (
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept={accept}
        multiple={multiple}
        {...rest}
      />
    ),
    [handleFileChange, accept, multiple, rest]
  );

  return {
    openFileDialog,
    fileInputElement,
  };
};

export default useFileUploader;
