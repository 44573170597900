import { Cancel, Info } from "@mui/icons-material";
import React, { useState } from "react";
import { addOrUpdateDocFromPath, docRef } from "../../globals/services";

const MigrateDevs = (props) => {
  const [vendorData, setVendorData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedVendor, setSelectedVendor] = useState({});
  const [error, setError] = useState("");

  // destructures props
  const { selectedDevData, setMigrateDevs } = props;

  // fetches vendor data and sets them to the state vendorData var
  const fetchAndSetData = async (e) => {
    e.persist();
    setSearchText(e.target.value);
    const vendorFetchData =
      await props.firebase.getDocumentsByArrayMembershipInCollection(
        "vendors",
        e.target.value.toLowerCase(),
        ["nameIndex"],
        "companyName",
        false,
        true,
        false,
        5
      );
    setVendorData(vendorFetchData);
  };

  // selects a vendor when a vendor is clicked
  const selectVendor = (companyName, companyLogo, vendorID) => {
    setSearchText("");
    setSelectedVendor({ companyName, companyLogo, vendorID });
  };

  // deselects a vendor when the X button is clicked
  const deselectVendor = () => {
    setSearchText("");
    setSelectedVendor({});
  };

  // migrates a resource to a new vendor when the Migrate Dev button is clicked
  const migrateDev = async () => {
    let newVendorRef = docRef(`vendors/${selectedVendor.vendorID}`);
    let vendorRef = { vendorRef: newVendorRef };

    const devId = selectedDevData.id;
    // Updates the dev document and change the vendorRef field with the selected vendor reference
    await addOrUpdateDocFromPath(`devs/${devId}`, vendorRef);

    // closes the Migrate Dev modal when the Migrate Dev button is clicked
    setMigrateDevs(false);
  };

  return (
    <div className="marketSearch request_form" id="marketSearchComponent">
      <div className="info-ctr">
        <Info className="market-info" />
        <p>
          Search for vendor(s) using the input field, select a vendor from the
          dropdown, and click on "Migrate Dev" button to migrate a Resource.
        </p>
      </div>
      <div className="marketSearch_close">
        <Cancel
          className="marketSearch_close-icon"
          onClick={() => setMigrateDevs(false)}
        />
      </div>
      <div className="selectedStackDiv">
        {selectedVendor?.companyName && (
          <div
            className="selectedStackItem"
            key={selectedVendor?.selectedVendorID}
          >
            <div onClick={deselectVendor} className="close">
              <h3>X</h3>
            </div>
            <p>
              <img
                src={selectedVendor?.companyLogo}
                alt={selectedVendor?.companyName}
              />{" "}
              {selectedVendor?.companyName}
            </p>
          </div>
        )}
      </div>
      <input
        name="companyName"
        value={searchText}
        onChange={(e) => fetchAndSetData(e)}
      />
      <div
        className="stackDiv"
        style={{ marginBottom: vendorData.length !== 0 ? "12.5px" : "0" }}
      >
        {vendorData.length !== 0 ? (
          <h3 className="hrTitle">VENDORS</h3>
        ) : (
          <div></div>
        )}
        {vendorData.length !== 0 ? <hr></hr> : <div></div>}
        {vendorData?.map((vendor) => {
          return (
            <div
              className="stackItem"
              key={vendor?.id}
              onClick={() => {
                selectVendor(
                  vendor.data()?.companyName,
                  vendor.data()?.logo?.url,
                  vendor?.id
                );
              }}
            >
              <img src={vendor.data()?.logo?.url} alt="Vendor" />
              <h3>{vendor.data()?.companyName}</h3>
            </div>
          );
        })}
      </div>
      <div
        className="btnSearch"
        onClick={() => {
          {
            Object.keys(selectedVendor).length === 0
              ? setError("Please, select a vendor")
              : migrateDev();
          }
        }}
      >
        <h3>Migrate Dev</h3>
      </div>
      {error !== "" ? (
        <div>
          <p style={{ color: "#EB3570", fontWeight: 600 }}>{error}</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MigrateDevs;
