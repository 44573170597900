import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const PageTopBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 3rem;
  }
  p {
    font-size: 1.6rem;
    color: #484848;
  }
`;

const PageTop = ({ title, subtitle, cls = "", children, ...restProps }) => {
  return (
    <PageTopBox className={`_spPageTop ${cls}`}>
      <h3>{title}</h3>
      {children ? children : <p>{subtitle}</p>}
    </PageTopBox>
  );
};

export default PageTop;
