import { HighlightOff } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import { db } from "../../../globals/services";
import Select from "react-select";
import SwitchSp from "../../../globals/MuiExtends/SwitchSp";

const NewStack = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [itemSelected, setItemSelected] = useState(false);
  const dropdownRef = useRef(null);
  const [filteredAssessments, setFilteredAssessments] = useState([]);

  useEffect(() => {
    // Function to fetch assessment details from Firestore
    const fetchAssessmentDetails = async () => {
      const assessmentDetails = await Promise.all(
        props?.techData?.assessments?.map(async (assessment) => {
          const docSnapshot = await db.doc(assessment.ref.path).get(); // Accessing document using path
          if (docSnapshot.exists) {
            return { ...assessment, name: docSnapshot.data().name };
          }
          return null;
        })
      );
      // Filter out null values (in case document doesn't exist)
      const filteredDetails = assessmentDetails.filter(
        (detail) => detail !== null
      );
      setFilteredAssessments(filteredDetails);
    };

    fetchAssessmentDetails();
  }, [props.techData]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      // Hide dropdown when search term is empty
      props.setShowDropdown(false);
      props.setSearchData([]);
      return;
    }

    props.searchAssessments(searchTerm);
  }, [searchTerm]);

  // handle search input change
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleAssessment = (ref) => {
    const assessments = [...(props?.techData?.assessments ?? [])];
    const assessmentIndex = assessments.findIndex(
      (item) => item?.ref?.id === ref.id
    );

    if (assessmentIndex !== -1) {
      // If assessment is found, remove it
      assessments.splice(assessmentIndex, 1);
    } else {
      // If assessment is not found, add it
      const rank = assessments.length + 1;
      assessments.push({ rank, ref });
    }
    props.setTechData({
      ...props.techData,
      assessments: assessments,
    });
    const selectedAssessment = props.searchData.find(
      (item) => item.ref.id === ref.id
    );
    if (selectedAssessment) {
      props.onToggleAssessment(selectedAssessment.data.name);
    }
  };

  const selectAssessment = (assessment) => {
    setSearchTerm(assessment?.data?.name); // Set search term to the selected assessment's name
    props.setShowDropdown(false); // Hide dropdown after selecting an option

    // Automatically toggle the selected assessment
    toggleAssessment(assessment.ref);
  };

  const selectSearchItem = (item) => {
    setSearchTerm(item?.data?.name); // Set search term to the selected item's name
    props.setSearchData([]);
    props.setShowDropdown(false); // Hide dropdown after selecting an option
    setItemSelected(true);

    // Automatically toggle the selected assessment
    toggleAssessment(item.id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click happened outside of the dropdown, close it
        props.setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, props]);

  return (
    <div>
      <div className="sp-form_item">
        <label>Name</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              name: e.target.value,
            })
          }
          value={props.techData.name}
        />
      </div>
      <div className="sp-form_item">
        <label>Search keyword</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              searchKeywords: e.target.value,
            })
          }
          value={props.techData.searchKeywords}
        />
      </div>
      <div className="row uploader spbtn">
        <div>
          <input
            type="file"
            id="stackLogo"
            onChange={(e) => props.selectLogo(e)}
            accept=".png, .jpg, .jpeg"
            hidden
          />
          <button
            id="stacklogoBtn"
            className="btn spbtn-sub"
            onClick={(e) => props.handleOpen(e, "stackLogo")}
          >
            Upload Stack Logo
          </button>
        </div>
        <div className="uploader-preview">
          <img
            className="inputs-row_main-preview__img"
            src={props.logo.url ?? props.techData.logoStorage}
            alt={props.logo?.file?.name}
          />
          {props.logo.url ? (
            <HighlightOff
              onClick={() => {
                props.setLogo({});
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="sp-form_item">
        <label>Rank</label>
        <input
          type="number"
          className="sp-form_item--inp sp-form_item--inp_btm"
          min={0}
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              rank: parseInt(e.target.value),
            })
          }
          value={props.techData.rank}
        />
      </div>
      {props.openDialog ? (
        <div className="sp-form_item">
          <label>Category</label>
          <Select
            options={props.techsCategories}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            // defaultValue={props.techData.category}
            onChange={(e) =>
              props.setTechData({
                ...props.techData,
                techCategory: e,
              })
            }
          />
        </div>
      ) : (
        <></>
      )}
      {/* {props.isEdit ? ( */}
      <div className="sp-form_item">
        <label for="status">Show</label>
        {/* <Select
            options={props.show}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            defaultValue={{
              value: props.techData.show,
              label: `${props.techData.show}`,
            }}
            onChange={(e) =>
              props.setTechData({
                ...props.techData,
                show: e,
              })
            }
          /> */}
        <select
          name="status"
          id="status"
          onChange={(e) => {
            let status = e.target.value === "true" ? true : false;
            props.setTechData({
              ...props.techData,
              show: status,
            });
            e.persist();
          }}
        >
          <option value={true} selected={props.techData.show ? true : false}>
            True
          </option>
          <option value={false} selected={!props.techData.show ? true : false}>
            False
          </option>
        </select>
      </div>
      {/* ) : (
        <>:</>
      )} */}

      <div className="sp-form_item">
        <label>Sub Category</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              subcategory: e.target.value,
            })
          }
          value={props.techData.subcategory}
        />
      </div>

      <div className="sp-form_item">
        <label>Description</label>
        <textarea
          className="sp-form_item--txtarea sp-form_item--txtarea_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              description: e.target.value,
            })
          }
          value={props.techData.description}
        ></textarea>
      </div>
      <div className="sp-form_boxer">
        <div className="sp-form_item">
          <label>Search and select assessment</label>
          <input
            type="text"
            className="sp-form_item--inp sp-form_item--inp_btm"
            placeholder="Search..."
            onChange={handleChange}
            // value={searchTerm}
            // onFocus={() => props.setShowDropdown(true)}
          />
        </div>
        {filteredAssessments.length > 0 && (
          <div className="sp-form_dropdown-s">
            {filteredAssessments.map((assessment, index) => (
              <div className="sp-form_dropdown-switch" key={index}>
                <p onClick={() => selectAssessment(assessment)}>
                  {assessment?.name}
                </p>
                <SwitchSp
                  onChange={() => toggleAssessment(assessment.ref)}
                  checked={
                    props?.techData?.assessments?.findIndex(
                      (a) => a?.ref?.id === assessment.ref.id
                    ) !== -1
                  }
                  defaultChecked={true}
                />
              </div>
            ))}
          </div>
        )}
        {searchTerm !== "" && !itemSelected && (
          <div ref={dropdownRef} className="sp-form_dropdown">
            {props.searchData.length > 0 ? (
              props.searchData.map((item) => {
                return (
                  <div className="sp-form_dropdown-switch">
                    <p key={item.id} onClick={() => selectSearchItem(item)}>
                      {item?.data?.name}
                    </p>
                    <SwitchSp
                      onChange={() => toggleAssessment(item.ref)}
                      checked={
                        props?.techData?.assessments?.findIndex(
                          (a) => a?.ref?.id === item.id
                        ) !== -1
                      }
                    />
                  </div>
                );
              })
            ) : (
              <p>Not found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default NewStack;
