import * as React from "react";
import { SampleBase } from "../../sample-base";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

export default class BioEdit extends SampleBase {
  constructor() {
    super(...arguments);
    this.state = {
      bioInput: "",
      bioState: "",
      hideDialog: false,
    };
    this.buttons = [
      {
        buttonModel: {
          content: "Submit",
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: async () => {
          this.setState({ bioState: this.state.bioInput });
          this.updateVendorBio();
        },
      },
      {
        buttonModel: {
          content: "Cancel",
          cssClass: "e-flat",
        },
        click: () => {
          this.setState({ hideDialog: false, bioState: this.state.bioInput });
        },
      },
    ];
    this.dialogClose = () => {
      this.setState({ hideDialog: false });
    };
  } // End Constructor

  // function used with syncfusions dialogcomponent
  onCreate() {
    this.textareaObj.addAttributes({ rows: 1 });
    this.textareaObj.respectiveElement.style.height = "auto";
    this.textareaObj.respectiveElement.style.height =
      this.textareaObj.respectiveElement.scrollHeight + "px";
  }
  // function used with syncfusions dialogcomponent
  onInput() {
    this.textareaObj.respectiveElement.style.height = "auto";
    this.textareaObj.respectiveElement.style.height =
      this.textareaObj.respectiveElement.scrollHeight + "px";
  }
  // Handle Click when user clicks on the bulk upload button
  handleClick() {
    this.setState({ hideDialog: true });
  }

  async updateVendorBio() {
    this.setState({ bioState: this.state.bioInput });

    const updates = { profile: { bio: "" } };

    updates.profile.bio = this.state.bioInput;

    try {
      this.props.firebase.updateVendor(this.props.vendor.id, updates);
    } catch (err) {
      console.log(err);
    }
    // this.props.updateVendor();
    this.setState({ hideDialog: false });
  }

  render() {
    return (
      <div id="bioedit">
        <button
          className="e-control e-btn bulkUpload-btn"
          id="targetButton1"
          style={{ background: "#1E96F5", color: "white" }}
          onClick={(this.handleClick = this.handleClick.bind(this))}
        >
          Edit
        </button>
        <DialogComponent
          width="960px"
          visible={this.state.hideDialog}
          close={this.dialogClose}
          showCloseIcon={true}
          buttons={this.buttons}
          header="Edit Your Company Bio"
        >
          <div className="multiline" style={{ width: "100%", margin: "auto" }}>
            <TextBoxComponent
              multiline={true}
              onChange={(e) => this.setState({ bioInput: e.target.value })}
              input={(this.onInput = this.onInput.bind(this))}
              value={this?.props?.profile?.bio ?? ""}
              created={(this.onCreate = this.onCreate.bind(this))}
              floatLabelType="Auto"
              ref={(scope) => {
                this.textareaObj = scope;
              }}
            />
          </div>
        </DialogComponent>
      </div>
    );
  }
}
