import React, { useState, useEffect } from "react";
import HiddenCard from "./HiddenCard";
import { useStacks } from "../../../../hooks/useTechs";

// stack={stack}
//           firebase={props.firebase}
//           devId={props.devId}
//           stacksObject={props.stacksObject}
//           index={index}
//           stackExp={props.stackExp}
//           showHiddenCard={props.showHiddenCard}
//           useExist={props.useExist}
//           setSelectedStacks={props.setSelectedStacks}
//           title={props.title}

export default function StackCard(props) {
  // const {}
  const [showCard, setShowCard] = useState(false);
  const [className, setClassName] = useState("hidden_card-left");
  const { refreshData } = useStacks();

  const [level, setLevel] = useState("1");
  // const [exp, setExp] = useState("1");
  const [expYears, setExpYears] = useState("1");
  const [expMonth, setExpMonth] = useState("1");
  const [proj, setProj] = useState("1");
  const [interest, setInterest] = useState("Interested");
  const [isYears, setIsYears] = useState(true);
  //id for each stack card
  const stackId = `Stack_cards_${props.stack.rank}_${props.stack.title}`;

  const onAdd = async (e) => {
    if (props.showHiddenCard) {
      if (props.onAdd) {
        props.onAdd(props.stack);
        return;
      }
      let data = {
        experience: 1,
        interest: "Interested",
        projects: 1,
        skill: 1,
        addedOn: new Date(),
        tech: props.stack.title,
      };
      data.techRef = props.stack.ref;
      // console.log("data", data);
      // console.log("props.devId", props.devId);
      // console.log("props.stack", props.stack);
      await props.firebase.createStack(props.devId, data);

      // Updating lastUpdated for the dev
      await props.firebase.updateDev(props.devId, {
        lastUpdated: new Date(),
      });
    } else {
      props.setSelectedStacks(props.stack);
    }
    refreshData();
    // await props.refreshDevStackExp();
  };

  const cardClick = () => {
    if (showCard === true) {
      setShowCard(false);
    } else {
      setShowCard(true);
    }
  };

  if (showCard) {
    // close the modal when we click  outside the data-request_form box
    window.addEventListener("mouseup", (e) => {
      let subBox = document.getElementById(stackId);
      let dataEdit = document.getElementById("collect_edit"); // id in collection management from StacksCollection.js
      let dropDown = document.getElementById("stack_boxEle"); // id of the show dropdown in collection management from StacksCollection.js
      try {
        if (
          (!subBox.contains(e.target) && !dataEdit.contains(e.target)) ||
          !dropDown.contains(e.target)
        ) {
          setShowCard(false);
        }
        // if()
      } catch (e) {
        return;
      }
    });
  }

  useEffect(() => {
    if (showCard) {
      if (props.setSelectedStacks) props.setSelectedStacks(props.stack);
    } else {
      if (props.setSelectedStacks) props.setSelectedStacks({});
    }
  }, [showCard]);

  useEffect(() => {
    let card = document.getElementById(stackId);
    if (card) {
      let rect = card.getBoundingClientRect();

      if (rect.left < 700) {
        setClassName("hidden_card-right");
      } else {
        setClassName("hidden_card-left");
      }
    }
  }, []);

  const isStackTitleInStackExp = props?.stackExp?.some(
    (stack) =>
      (stack?.techRef?.path ||
        stack?.projectRef?.path ||
        stack?.domainRef?.path) === props.stack?.ref?.path
  );
  // console.log("props?.stackExp", props?.stackExp);
  // console.log("props?.stack", props?.stack);
  // console.log("props.stack.tech", props.stack);
  // console.log("props.stacksObject", props.stacksObject);

  return (
    <>
      {isStackTitleInStackExp ? (
        <div className="_spTechskills-chip _spTechskills-chip-available">
          <div className="_spTechskills-chip__text">
            <img
              src={props?.stack?.logoStorage}
              className="techskill_logo"
              alt={props?.stack?.title || props?.stack?.name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://via.placeholder.com/150x150.png?text=No+Image";
              }}
            />
            <div className="techskill_name">
              <p>{props?.stack?.title || props?.stack?.name}</p>
            </div>
          </div>
        </div>
      ) : (
        <button className="_spTechskills-chip" onClick={onAdd}>
          <div className="_spTechskills-chip__text" id={stackId}>
            <img
              src={props.stack.logoStorage}
              alt={props?.stack?.title || props?.stack?.name}
              className=" techskill_logo"
            />
            <div className="techskill_name">
              <p>{props?.stack?.title || props?.stack?.name}</p>
            </div>
          </div>
          {props.showHiddenCard && (
            <div className="addInfo">
              <span>Add</span>
            </div>
          )}

          {/* <button className="addButton">Add</button> */}

          {props.showHiddenCard ? (
            <HiddenCard
              className={className}
              showCard={showCard}
              setShowCard={setShowCard}
              title={props.stack.title}
              category={props.stack.category}
              description={props.stack.description}
              rank={props.stack.rank}
              sub_cat={props.stack.subcategory}
              firebase={props.firebase}
              devId={props.devId}
              stacksObject={props.stacksObject}
            />
          ) : (
            <></>
          )}
        </button>
      )}
    </>
  );
}
