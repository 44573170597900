import { useEffect, useState } from "react";
import { dateString } from "../globals/helpers";
import { colRef, docRef } from "../globals/services";

export const useCurrentDevRate = (devId, showRate = true) => {
  const [rate, setRate] = useState(0);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!showRate) return;
    if (!devId) return;
    let fullDate = dateString();
    let currentRate = 0;
    let dataSna = docRef(`devs/${devId}/rates/${fullDate}`).onSnapshot(
      (rate) => {
        let data = rate.data();
        // let profileProgress = 0.0;
        if (data) {
          // devInfo.hourlyRate = data.hourlyRates ? data.hourlyRates.L1 : 0
          for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
            if (data.hourlyRates[secLev]) {
              currentRate = data.hourlyRates ? data.hourlyRates[secLev] : 0;
            }
          }
          setRate(currentRate);
        } else {
          if (currentRate === 0) {
            let latestRateSna = colRef(`devs/${devId}/rates`)
              .orderBy("date", "desc")
              .limit(1)
              .onSnapshot((tempRate) => {
                let rateDoc = tempRate.docs[0];
                if (rateDoc?.data()) {
                  // devInfo.hourlyRate = data.hourlyRates ? data.hourlyRates.L1 : 0
                  for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
                    if (rateDoc?.data().hourlyRates[secLev]) {
                      currentRate = rateDoc?.data().hourlyRates
                        ? rateDoc?.data().hourlyRates[secLev]
                        : 0;
                    }
                  }
                }
                setIsExpired(true);
                setRate(currentRate);
              });
            return latestRateSna;
          }
        }
      }
    );
    return dataSna;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devId, showRate]);
  return { devRate: rate, isExpired: isExpired };
};
