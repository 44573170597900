import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Alert,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { FirebaseContext } from "./Firebase";

// Import Logo
import logo from "./images/Logo.png";

export default function Register() {
  const firebase = useContext(FirebaseContext);
  // Initialize and define default values
  const [inputPassword, setInputPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [emptyAlert, setEmptyAlert] = useState("");
  const [newVendor, setNewVendor] = useState({
    contactFirst: "",
    website: "",
    contactLast: "",
    contactEmail: "",
    contactPhone: "",
    techFocus: "",
    totalDevs: 0.0,
    timeToStart: 0.0,
  });

  // add user to firebase database
  const addUserAsync = async (e) => {
    if (inputPassword === confirmPassword) {
      try {
        const auth = await firebase.doCreateUserWithEmailAndPassword(
          newVendor.contactEmail,
          inputPassword
        );
        // add a new user to users collection
        await firebase.createUser(
          {
            first: newVendor.contactFirst.toString(),
            last: newVendor.contactLast.toString(),
            email: newVendor.contactEmail.toString(),
            phone: newVendor.contactPhone.toString(),
            status: true,
          },
          auth.user.uid
        );
        // Default values for vendor maps
        const financial = {
          avgYearlyIncome: 0,
          avgRateSprint: 0,
          avgRateHr: 0,
          netMarginP: 0,
          grossMarginP: 0,
          publicCompany: false,
        };
        const verification = {
          businessEntityName: "Unknown",
          source: "None",
          status: true,
          jurisdiction: "Unknown",
          companyId: "Unknown",
          dateOfFormation: new Date(),
          lastUpdated: new Date(),
        };
        const legal = {
          bankruptcy: false,
          taxLienFilings: 0,
          judgementFilings: 0,
          collectionsCount: 0,
          lastUpdated: new Date(),
        };
        const reviews = {
          verifiedClientReviews: 0,
          overallReviewRating: 0,
          source: "Unknown",
          lastUpdated: new Date(),
        };
        const logo = {
          url: "",
          name: "",
        };
        // add vendor to vendors collection and reference associated users
        // from users collection in contactRef
        const responseVendor = await firebase.createVendor({
          companyName: newVendor.companyName.toString(),
          address: newVendor.address.toString(),
          techFocus: newVendor.techFocus.toString(),
          totalDevs: parseFloat(newVendor.totalDevs),
          website: newVendor.website.toString(),
          timeToStart: parseFloat(newVendor.timeToStart),
          contactRef: firebase.db.doc(`users/${auth.user.uid}`),
          financial: financial,
          verification: verification,
          legal: legal,
          reviews: reviews,
          logo: logo,
          profile: { bio: "Add your compa ny bio!" },
          vendorProfileProgress: {
            financialProgress: 0,
            devsProgress: 0,
          },
        });
        // Once vendor is created, link the created user to the created vendor
        // the user who creates the vendor is defaulted to be the point of
        // contact between Spryte and the vendor
        const vendorRef = {
          vendorRef: firebase.db.doc(`vendors/${responseVendor.id}`),
          status: true,
          permissions: [],
        };
        await firebase.linkAccount(auth.user.uid, vendorRef);
        await firebase.doSignOut();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  // If all vendor fields are correctly filled out
  // proceed with adding a new user and vendor
  const checkVendor = () => {
    // Throw an error if any of the fields are empty
    if (
      newVendor.contactFirst === "" ||
      newVendor.contactLast === "" ||
      newVendor.contactPhone === "" ||
      newVendor.contactEmail === "" ||
      newVendor.techFocus === "" ||
      newVendor.timeToStart === ""
    ) {
      setEmptyAlert("Please Fill All Fields");
      // Throw an error if Employed Directors or Project Lead Time fields are 0
    } else if (newVendor.totalDevs <= 0 || newVendor.timeToStart <= 0) {
      setEmptyAlert(
        "Please check Employed Developer or Project Lead Time Field(must be greater than 0)"
      );
    } else {
      addUserAsync();
    }
  };

  // setNewVendor field so that a new vendor can be created
  const handleChange = (prop) => (event) => {
    setNewVendor({ ...newVendor, [prop]: event.target.value });
  };

  // const handleCheck = (prop) => (event) => {
  //   setNewVendor({ ...newVendor, [prop]: event.target.checked });
  // };

  // If show password is toggled, show password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // Prevent passwords that the browser remembers from showing
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //==================================== Added this alerte section to check and display the alert from the database=================================
  let alerte = "";
  let EmptyAlert = "";
  if (error !== "") {
    alerte = <Alert severity="error">{error}</Alert>;
  }
  if (emptyAlert !== "") {
    EmptyAlert = <Alert severity="error">{emptyAlert}</Alert>;
  }

  return (
    <div className="Register" style={{ textAlign: "center" }}>
      <div className="vendor-signup">
        <div>
          <img src={logo} height="40" width="40" alt="" />
          <Typography component="h1" variant="h5">
            Vendor Sign Up
          </Typography>
        </div>
        {alerte}
        {EmptyAlert}
        <div>
          {/* Set Values for newVendor */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="compnyName"
            label="Company Name"
            name="companyName"
            autoComplete="companyName"
            autoFocus
            type={"text"}
            value={newVendor.companyName}
            onChange={handleChange("companyName")}
            alt=""
          />
          &nbsp; &nbsp;
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="website"
            label="Website"
            name="website"
            autoComplete="website"
            autoFocus
            type={"text"}
            value={newVendor.website}
            onChange={handleChange("website")}
          />
          &nbsp; &nbsp;
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="address"
            label="Address"
            name="address"
            autoComplete="address"
            autoFocus
            type={"text"}
            value={newVendor.address}
            onChange={handleChange("address")}
          />
        </div>
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="techFocus"
            label="Tech Focus"
            name="techFocus"
            autoComplete="techFocus"
            autoFocus
            type={"text"}
            value={newVendor.techFocus}
            onChange={handleChange("techFocus")}
          />
          &nbsp; &nbsp;
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="totalDevs"
            label="Employed Developers"
            name="totalDevs"
            autoComplete="totalDevs"
            autoFocus
            type={"number"}
            inputProps={{
              min: "0",
            }}
            value={newVendor.totalDevs}
            onChange={handleChange("totalDevs")}
          />
          &nbsp; &nbsp;
          <TextField
            variant="outlined"
            margin="normal"
            required
            halfWidth
            id="timeToStart"
            label="Project Lead Time"
            name="timeToStart"
            autoComplete="timeToStart"
            autoFocus
            type={"number"}
            inputProps={{
              min: "0",
            }}
            value={newVendor.timeToStart}
            onChange={handleChange("timeToStart")}
          />
        </div>
      </div>
      <div className="primary-contact">
        <div>
          <img src={logo} height="40" width="40" alt="" />
          <Typography component="h2" variant="h5">
            Primary Contact
          </Typography>
        </div>
        <div className="form">
          <FormControl className="form-left" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Contact First Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="contactFirst"
              autoComplete="contactFirst"
              label="Contact First Name"
              type={"text"}
              value={newVendor.contactFirst}
              onChange={handleChange("contactFirst")}
              alt=""
            />
          </FormControl>
          <FormControl className="form-right" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Contact Last Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              label="Contact Last Name"
              margin="normal"
              type={"text"}
              value={newVendor.contactLast}
              onChange={handleChange("contactLast")}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="form-left" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Contact Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              label="Contact Email"
              margin="normal"
              type={"text"}
              value={newVendor.contactEmail}
              onChange={handleChange("contactEmail")}
            />
          </FormControl>
          <FormControl className="form-right" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Contact Phone
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              label="Contact Phone"
              margin="normal"
              type={"phone"}
              value={newVendor.contactPhone}
              onChange={handleChange("contactPhone")}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="form-left" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className="form-right" variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <br />
      </div>
      <div>
        <Button
          type="submit"
          required
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => checkVendor()}
        >
          Sign Up
        </Button>
      </div>
    </div>
  );
}
