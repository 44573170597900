import React, { useEffect } from "react";
import Login from "../Login.js";
import Register from "../Register.js";
import PassResetForm from "../passResetForm.js";
import PartnerDevSignUp from "../PartnerDevSignUp";
import FirebaseContext from "../Firebase/context.js";
import { Route, Routes, useNavigate } from "react-router-dom";
import Requirements from "../Requirements.js";

const NonLoggedUserRouter = ({
  setIsPartnerDevs,
  isPartnerDevs,
  newUser,
  setNewUser,
  isDev,
  authUser,
}) => {
  return (
    <Routes>
      <Route path="/pw-forget" element={<PassResetForm />} />
      <Route path="/signup" element={<Register />} />
      <Route
        path="/register/:orgId/:id"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <PartnerDevSignUp
                firebase={firebase}
                isIntern={false}
                setIsPartnerDevs={setIsPartnerDevs}
                isPartnerDevs={isPartnerDevs}
                authUser={authUser}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/internShip/:orgId/:id"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <PartnerDevSignUp
                firebase={firebase}
                isIntern={true}
                setIsPartnerDevs={setIsPartnerDevs}
                isPartnerDevs={isPartnerDevs}
                authUser={authUser}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/requirements"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => <Requirements firebase={firebase} vendor={null} />}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Login
                newUser={newUser}
                setNewUser={setNewUser}
                isDev={isDev}
                firebase={firebase}
                auth={authUser}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />
    </Routes>
  );
};

export default NonLoggedUserRouter;
