// Import all classes within the Firebase folder so that files outside of this folder
// can easily import the classes without having to specify the file they are in
import FirebaseContext, { withFirebase } from "./context";
import Firebase from "./firebase";
import withAuthUser, { AuthUserContext } from "./session";
import GlobalVendorProvider, {
  GlobalVendorContex,
} from "./GlobalVendorContext";
import BookedProvider, { BookedContext } from "./BookedContext";

export default Firebase;
export { GlobalVendorProvider, GlobalVendorContex };
export { FirebaseContext, withFirebase };
export { withAuthUser, AuthUserContext };
export { BookedProvider, BookedContext };
