import React from "react";
import { StepperWrapper } from "./style";
import {
  StepperElementWrapper,
  StepperLeft,
  StepperRight,
} from "../StepperElement/style";

import AddCircleIcon from "@mui/icons-material/AddCircle";

const SpStepper = ({ children, cls = "", onAdd, isEmpty, ...props }) => {
  return (
    <StepperWrapper
      className={`${cls} _spStepperWrapper`}
      {...props}
      isEnableEdit={props.isEnableEdit}
    >
      {children}
      {props.isEnableEdit ? (
        <StepperElementWrapper
          {...props}
          className={`${cls} _spStepperElement`}
        >
          <StepperLeft
            className={`${cls}__left _spStepperElement__left`}
          ></StepperLeft>
          <StepperRight className={`${cls}__right _spStepperElement__right`}>
            <button className="add-button" onClick={onAdd}>
              <AddCircleIcon />
            </button>
          </StepperRight>
        </StepperElementWrapper>
      ) : !isEmpty ? (
        <StepperElementWrapper
          {...props}
          className={`${cls} _spStepperElement`}
        >
          <StepperLeft
            className={`${cls}__left _spStepperElement__left`}
          ></StepperLeft>
          <StepperRight className={`${cls}__right _spStepperElement__right`}>
            {/* <button className="add-button" onClick={onAdd}>
              <AddCircleIcon />
            </button> */}
          </StepperRight>
        </StepperElementWrapper>
      ) : null}
    </StepperWrapper>
  );
};

export default SpStepper;
