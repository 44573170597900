import { Cancel, Info } from "@mui/icons-material";
import React, { useState } from "react";
import { colRef } from "../globals/services";

function Domains({ vendorData, firebase, updateVendor }) {
  let oldDomains = vendorData?.domains ?? [];
  const [error, setError] = useState("");
  const [DomainClick, setDomainClick] = useState(false);
  const [allDomains, setallDomains] = useState([...new Set([...oldDomains])]);
  const [Domains, setDomains] = useState();
  const addDomains = async (event) => {
    setError("");
    if (event.key === "Enter") {
      let newDomains = Domains?.split(" ");
      newDomains?.forEach(async (element) => {
        let a = await colRef("vendors")
          .where("domains", "array-contains", element)
          .get();
        let doc = a?.docs?.length;
        if (doc === 0) {
          setallDomains([...new Set([...newDomains, ...allDomains])]);
        } else {
          setError(element + " already exist");
        }
      });
    }
  };

  const addDomainsButton = () => {
    let newDomains = Domains?.split(" ");
    newDomains?.forEach(async (element) => {
      let a = await colRef("vendors")
        .where("domains", "array-contains", element)
        .get();
      let doc = a?.docs?.length;
      if (doc === 0) {
        setallDomains([...new Set([...newDomains, ...allDomains])]);
      } else {
        setError(element + " already exist");
      }
    });
  };

  const confirmChanges = async () => {
    let data = {
      domains: allDomains,
    };

    await firebase.updateVendor(vendorData?.id, data);

    updateVendor();

    setDomainClick(false);
  };

  const removeItem = (item) => {
    setallDomains(allDomains?.filter((i) => i !== item));
  };

  return (
    <div className="stat_content_data stat_content_rev row">
      <div className="stat_content_data-title">
        <h4 style={{ cursor: "pointer" }}>Domains </h4>
      </div>
      <div className="stat_content_data-content" style={{ display: "flex" }}>
        <div className="row">
          <div className="row top" id="fs" style={{ display: "flex" }}>
            {allDomains ? (
              allDomains.map((item, i) => {
                return <p>{item}</p>;
              })
            ) : (
              <p>No Domains</p>
            )}
            <br />
          </div>
        </div>

        <>
          {DomainClick && (
            <div
              className="marketSearch request_form"
              id="marketSearchComponent"
            >
              <div className="info-ctr">
                <Info className="market-info" />
                <p>Add Domains</p>
              </div>
              <div className="marketSearch_close">
                <Cancel
                  className="marketSearch_close-icon"
                  onClick={() => setDomainClick(false)}
                />
              </div>
              <div className="selectedStackDiv"></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  name="stackName"
                  onChange={(e) => {
                    setDomains(e.target.value);
                  }}
                  onKeyDown={addDomains}
                />
                <button
                  className="e-control e-btn "
                  // id="targetButton1"
                  style={{
                    background: "white",
                    color: "black",
                    marginLeft: 10,
                    height: 30,
                  }}
                  onClick={() => {
                    addDomainsButton();
                  }}
                >
                  Add +
                </button>
              </div>
              <div class="selectedStackDiv">
                {allDomains?.map((item, i) => {
                  return (
                    <div class="selectedStackItem" key={`${item}_${i}`}>
                      <div
                        class="close"
                        onClick={() => {
                          removeItem(item);
                        }}
                      >
                        <h3>X</h3>
                      </div>
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
              <p>{error}</p>
              <div className="btnSearch" onClick={confirmChanges}>
                <h3>Confirm Changes</h3>
              </div>
            </div>
          )}

          <button
            className="e-control e-btn "
            // id="targetButton1"
            style={{
              background: "white",
              color: "black",
              marginLeft: "auto",
              height: 30,
            }}
            onClick={() => setDomainClick(true)}
          >
            Edit Domains
          </button>
        </>
      </div>
    </div>
  );
}

export default Domains;
