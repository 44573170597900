import React, { useEffect } from "react";

export default function Search(props) {
  // useEffect(() => {
  //   // check in all stack for any stack that the title have the typed value
  //   const titleResult = props.allData.filter((data) => {
  //     let lowerValue = props?.searchValue?.toLowerCase();
  //     return data?.title
  //       ? data?.title?.toLowerCase()?.includes(lowerValue)
  //       : data?.name?.toLowerCase()?.includes(lowerValue); //converts the stacks title to lower case then compare with the search box value
  //   });

  //   const categoriesResult = props.allData.filter((data) => {
  //     let lowerValue = props?.searchValue.toLowerCase();
  //     return data?.category
  //       ? data?.category?.toLowerCase().includes(lowerValue)
  //       : []; //converts the stacks title to lower case then compare with the search box value
  //   });
  //   const subCategories = props?.allData.filter((data) => {
  //     let lowerValue = props?.searchValue.toLowerCase();
  //     return data?.subcategory
  //       ? data?.subcategory?.toLowerCase().includes(lowerValue)
  //       : [];

  //     //converts the stacks title to lower case then compare with the search box value
  //   });

  //   let result = props.isSub //if the data has subcategories then we will concatenate all title or name then categories and subcategories
  //     ? [...new Set(titleResult.concat(categoriesResult, subCategories))]
  //     : [...new Set(titleResult.concat(categoriesResult))];
  //   props?.setFilteredDatas(result);
  // }, [props?.searchValue]);

  return (
    <div className="search">
      <input
        type="text"
        placeholder={props?.placeholder}
        className="search_input"
        value={props.mapsearchValue}
        onChange={(e) => {
          if (props?.onChange) {
            props.onChange(e);
            return;
          }
          props.setSearchValue(e.target.value);
        }}
      />
    </div>
  );
}
