import React from "react";
import { SampleBase } from "../../../../sample-base";
import { DatePicker } from "@syncfusion/ej2-calendars";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { Toolbar, Inject, Page, Edit } from "@syncfusion/ej2-react-grids";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import RateBox from "./RateBox";

export default class DevRate extends SampleBase {
  constructor() {
    super(...arguments);
    this.toolbarOptions = ["Delete"];
    this.editSettings = {
      allowEditing: false,
      allowAdding: true,
      allowDeleting: true,
      mode: "Dialog",
      template: this.dialogTemplate,
    };
    this.dateFromPickParams = {
      create: () => {
        this.dateElem = document.createElement("input");
        return this.dateElem;
      },
      destroy: () => {
        this.dateObj.destroy();
      },
      read: () => {
        return this.dateObj.value;
      },
      write: (args) => {
        this.dateObj = new DatePicker({
          floatLabelType: "Never",
          value: new Date(),
          placeholder: "From",
        });
        this.dateObj.appendTo(this.dateElem);
      },
    };

    this.dateToPickParams = {
      create: () => {
        this.dateElem = document.createElement("input");
        return this.dateElem;
      },
      destroy: () => {
        this.dateObj.destroy();
      },
      read: () => {
        return this.dateObj.value;
      },
      write: (args) => {
        this.dateObj = new DatePicker({
          floatLabelType: "Always",
          value: new Date(),
          placeholder: "To",
        });
        this.dateObj.appendTo(this.dateElem);
      },
    };

    this.currencyParams = {
      params: {
        format: "C2",
        min: 0,
      },
    };

    this.dateParams = {
      params: {
        min: this.props.minDate,
      },
    };
    this.minDate = new Date();

    // Set Validation and Order id rules for syncfusion components
    this.validationRule = { required: true };
    this.norequired = { required: false };

    this.numericParams = {
      params: {
        format: "N0",
        decimals: 0,
        min: 4,
      },
    };
    this.state = {
      openDialog: false,
      isUpdate: false,
      onAdd: false,
      onEdit: false,
      selectedRate: {},
    };
    this.orderidRules = { required: true, number: true };
    this.pageSettings = { pageCount: 5 };
    this.displayRatesUpTo = !this.props.maxSecLev
      ? "L1"
      : "L" + String.fromCharCode(this.props.maxSecLev.charCodeAt(1) + 1);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.setState({
      isRequest: false,
    });
  }

  checkForDateOverLap(dateRange1, dateRange2) {
    if (dateRange2.from < dateRange1.to) {
      return true;
    } else {
      return false;
    }
  }

  onClose() {
    this.setState({
      openDialog: false,
      isUpdate: false,
      onAdd: false,
      onEdit: false,
    });
  }

  onCloseConfirmation() {
    this.setState({ openDialog: false, isUpdate: false });
  }

  onRowSelected(args) {
    if (args?.data) {
      this.setState({ selectedRate: args.data });
    }
  }
  onRowDeslected(args) {
    this.setState({ selectedRate: {} });
  }
  async actionComplete(args) {
    // If it's a delete request
    const request = args.requestType;
    if (request === "delete") {
      if (args.data.length && args.data[0].id) {
        const data = args.data[0];
        const { id } = data;
        await this.props.firebase.deleteDevRate(this.props.devId, id);

        // Updating lastUpdated for the dev
        await this.props.firebase.updateDev(this.props.devId, {});
        // regardless of the action or request type, retrieve the new list of
        // dev rates

        // this.gridInstance.refresh();
      }
    }
  }

  render() {
    // console.log(this.props.devData);s
    // this.actionComplete = this.actionComplete.bind(this);

    // Only display rates up to the security level 1 higher than the vendor's
    let devRatesForSecLev = [
      <ColumnDirective
        key="devRate-L1"
        field="L1"
        textAlign="Center"
        format="c2"
        editType="numericedit"
        edit={this.currencyParams}
        width="130"
        headerText="L1 Rate"
        validationRules={this.validationRule}
      ></ColumnDirective>,
    ];
    for (let secLev of ["L2", "L3", "L4", "L5"]) {
      if (secLev <= this.displayRatesUpTo) {
        devRatesForSecLev.push(
          <ColumnDirective
            key={`devRate-${secLev}`}
            field={secLev}
            textAlign="Center"
            format="c2"
            editType="numericedit"
            edit={this.currencyParams}
            headerText={`${secLev} Rate`}
            width="130"
            validationRules={this.validationRule}
            defaultValue={0}
          ></ColumnDirective>
        );
      }
    }

    return (
      <div
        className={
          this.props.devPage === "devRates" ? "control-section" : "hide_page"
        }
        id="DevRates"
      >
        {this.state.onAdd || this.state.onEdit ? (
          <RateBox
            onClose={this.onClose}
            minDate={this.minDate}
            displayRates={this.displayRatesUpTo}
            maxRate={this.props.maxRates}
            isIntern={this.props?.devData?.intern ?? false}
            firebase={this.props.firebase}
            devId={this.props.devData.id}
            devData={this.props?.devData}
            dailyHours={this.props.hoursInWorkDay}
            onEdit={this.state.onEdit}
            selectedRates={this.state.selectedRate}
            setIsVerifiedResource={this.props.setIsVerifiedResource}
            lastFrom={
              this.props.devRates.length
                ? this.props.devRates[0].to
                : new Date()
            }
          />
        ) : (
          <></>
        )}
        {!this.props.isVerifiedResource ? (
          <div className="clientDescCtr">
            <div className="warningCtr">
              <div className="warning">
                <h4>!</h4>
              </div>
              <div>
                <p id="bold">
                  Please Verify this Resource By Adding a Rate.
                  <a
                    target="_blank"
                    href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo"
                    rel="noreferrer"
                  >
                    {" "}
                    Contact us
                  </a>{" "}
                  If you don't recognize this resource
                </p>
                <p>
                  Once You add a Rating for this Resource, The resource will be
                  verified and He/She will be able to Login to his account and
                  modifyor add Stacks, Projects, Domains, etc. .
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <ToastContainer />
        <div className="resources_header">
          <div className="resources_header_left">
            <div className="title">
              <h3>Developer Rates</h3>
              <FontAwesomeIcon icon={faMoneyBill} className="icon" />
            </div>
            <p>Set the rates for your developer.</p>
          </div>
        </div>
        <div>
          <div className="row" style={{ gap: "10px" }}>
            <button
              className="spbtn spbtn-blackbkg"
              onClick={() => {
                this.setState({ onAdd: true });
              }}
              style={{ margin: "10px 0" }}
            >
              Add Rate
            </button>
            {this.state.selectedRate?.to && (
              <button
                className="spbtn spbtn-blackbkg"
                onClick={() => {
                  this.setState({ onAdd: false, onEdit: true });
                }}
                style={{ margin: "10px 0" }}
              >
                Edit Rate
              </button>
            )}
          </div>
          <GridComponent
            dataSource={this.props.devRates}
            ref={(grid) => (this.gridInstance = grid)}
            pageSettings={this.pageSettings}
            height="295px"
            toolbar={this.props.devRates.length > 0 ? this.toolbarOptions : []}
            allowPaging={true}
            editSettings={this.editSettings}
            actionComplete={this.actionComplete.bind(this)}
            rowSelected={this.onRowSelected.bind(this)}
            rowDeselected={this.onRowDeslected.bind(this)}
          >
            <ColumnsDirective>
              {devRatesForSecLev}
              <ColumnDirective
                field="dailyHours"
                editType="numericedit"
                edit={this.numericParams}
                textAlign="Center"
                headerText="Daily Hours"
                width="130"
                validationRules={this.validationRule}
                // min={4}
                defaultValue={this.props.hoursInWorkDay}
              ></ColumnDirective>
              <ColumnDirective
                field="from"
                editType="datepickeredit"
                edit={this.dateParams}
                // edit={this.dateFromPickParams}
                format="yMd"
                textAlign="Center"
                headerText="From"
                width="130"
                validationRules={this.validationRule}
              ></ColumnDirective>
              <ColumnDirective
                field="to"
                editType="datepickeredit"
                edit={this.dateParams}
                // edit={this.dateToPickParams}
                format="yMd"
                textAlign="Center"
                width="130"
                headerText="To"
                validationRules={this.validationRule}
              ></ColumnDirective>
              <ColumnDirective
                field="description"
                headerText="Description"
                width="130"
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit]} />
          </GridComponent>
        </div>
      </div>
    );
  }
}
