import React, { useEffect, useState } from "react";

const RenderagreedUser = ({ agreeBy }) => {
  const [agreedBy, setAgreedBy] = useState(null);
  useEffect(() => {
    if (!agreeBy) return;
    return agreeBy.onSnapshot((snap) => {
      let agreedBy = {};
      const data = snap.data();
      setAgreedBy(data);
      agreedBy["first"] = data?.first ?? "No First ";
      agreedBy["last"] = data?.last ?? " No Last";

      let userName = " " + agreedBy.first + " " + agreedBy.last;
      setAgreedBy(userName);
    });
  }, [agreeBy]);

  return (
    <span
      style={{
        marginLeft: "5px",
      }}
    >
      {agreedBy}
    </span>
  );
};

export default RenderagreedUser;
