import React from "react";

export default function SpLoadingBtn({
  btnText,
  loadingText = "please wait...",
  btnClicked,
  loading,
  sx,
  disabled,
}) {
  return (
    <div className="spLoadingBtn">
      <button
        // className="button"
        onClick={() => btnClicked()}
        disabled={loading || disabled}
        className="spLoadingBtn-btn"
        style={sx}
      >
        {loading && (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
        )}
        {loading && <span>{loadingText}</span>}
        {!loading && <span>{btnText}</span>}
      </button>
    </div>
  );
}
