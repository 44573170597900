import React, { useState, useEffect } from "react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ActiveInactiveIcon = ({
  value,
  readOnly = false,
  defaultActive = false,
  activeColor = "#25C7AA",
  inactiveColor = "#D0D0D0",
  size = "default",
  onToggle,
  className,
  ...props
}) => {
  const [isActive, setIsActive] = useState(value ?? defaultActive);

  useEffect(() => {
    if (value !== undefined) {
      setIsActive(value);
    }
  }, [value]);

  const handleClick = () => {
    if (readOnly) {
      return;
    }

    const newActiveStatus = !isActive;
    setIsActive(newActiveStatus);

    if (onToggle) {
      onToggle(newActiveStatus);
    }
  };

  return (
    <div onClick={handleClick} className={className} {...props}>
      {isActive ? (
        <CheckCircleIcon style={{ color: activeColor, fontSize: size }} />
      ) : (
        <DoNotDisturbIcon style={{ color: inactiveColor, fontSize: size }} />
      )}
    </div>
  );
};

export default ActiveInactiveIcon;
