import { FileCopy } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

const ClipBoard = (props) => {
  // the status state
  const [status, setStatus] = useState(false);

  // the embedded code
  const [code, setCode] = useState("");

  // create the id of each badge
  const theId = "badge" + props.id;

  /**
   * Use the badge id and select the current badge
   * embedded code for the vendor to embed it in their website
   * After that it will set the status to true
   * then wait 1 second and set it back to false
   */
  const copyCodeToClipboard = () => {
    // the selection
    let range = document.createRange();
    let selection = window.getSelection();
    let selectedId = "#" + theId;
    range.selectNodeContents(document.querySelector(selectedId));
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();

    //
    setStatus(true);

    // set back the status to false
    setTimeout(() => {
      setStatus(false);
    }, 1000);
  };

  useEffect(() => {
    let url = `https://www.sprytelabs.com/?utm_source=${props.companyName}&utm_medium=referral&utm_campaign=badge&utm_content=${props.name}`;
    const el = `
    <a href=${url} target="_blank" rel="noopener">
        <img src=${props.url} alt="Spryte Badge"/>
    </a>`;

    setCode(el);
  }, []);

  return (
    <div className="clipboard">
      <div className="clipboard_copy">
        <FileCopy onClick={() => copyCodeToClipboard()} />
        {status ? <div style={{ color: "#1E96F5" }}>copied!</div> : null}
      </div>
      <div className="clipboard_code">
        <pre>
          <code id={theId}>{code}</code>
        </pre>
      </div>
    </div>
  );
};

export default ClipBoard;
