import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
// import FirebaseContext from "./context";
// import { AuthUserContext } from "./session";

export const ResourcesContext = createContext(null);

const ResourcesProvider = ({ children }) => {
  const [viewableDevs, setViewableDevs] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [resourcesNumber, setResourcesNumber] = useState(0);
  const [isViewDevsDetail, setIsViewDevsDetail] = useState(false);
  const [viewingDevData, setViewingDevData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [dataToDuplicate, setDataToDuplicate] = useState({
    stacks: [],
    domains: [],
    projects: [],
  });
  const [isDuplicate, setIsDuplicate] = useState(true);

  return (
    <ResourcesContext.Provider
      value={{
        viewableDevs,
        setViewableDevs,
        isDraft,
        setIsDraft,
        resourcesNumber,
        setResourcesNumber,
        isViewDevsDetail,
        setIsViewDevsDetail,
        viewingDevData,
        setViewingDevData,
        isUploading,
        setIsUploading,
        isDuplicate,
        dataToDuplicate,
        setDataToDuplicate,
        setIsDuplicate,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export default ResourcesProvider;
