import React, { useEffect, useReducer } from "react";
import { DateRangeInput } from "@datepicker-react/styled";
import styled, { ThemeProvider } from "styled-components";

// Full list of themeable properties
// https://github.com/t0gre/react-datepicker/blob/master/docs/THEME_PROPS.md

const SpDatePickerWrapper = styled.div`
  button {
    border-radius: 0 !important;
  }
`;
const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "focusChange":
      return { ...state, focusedInput: action.payload };
    case "dateChange":
      return action.payload;
    default:
      throw new Error();
  }
}
const DateRanger = ({ startDate, endDate, onDatesChange }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (startDate && endDate) {
      dispatch({
        type: "dateChange",
        payload: {
          startDate,
          endDate,
          focusedInput: null,
        },
      });
    }
  }, []);

  return (
    <SpDatePickerWrapper className="_spDatePickerSection">
      <ThemeProvider
        theme={{
          breakpoints: ["32em", "48em", "64em"],
          reactDatepicker: {
            daySize: [36, 36],
            fontFamily: "Manrope, sans-serif",
            colors: {
              primaryColor: "#4B35D2",
              accessibility: "#4B35D2",
              selectedDay: "#EEEDFD",
              selectedDayHover: "#faf9fd",
              normalDayHover: "#faf9fd",
            },

            dayLabelFontSize: 12,
            dayLabelColor: "#8F8B9B",

            selectDateLabelFontSize: 10,
            selectDateLabelColor: "#D0CED5",
            selectDateDateFontSize: 14,
            selectDateDateColor: "#34323A",
            selectDateBorderColor: "#4B35D2",

            navButtonBorder: "none",

            monthLabelFontSize: 16,
            monthLabelFontWeight: 600,

            dayBackground: "#ffffff",
            dayBorderColor: "transparent",
            dayColor: "#34323A",
            dayFontSize: 15,
            dayBorderRadius: 0,
            dayMargin: 5,
            dayBoxShadow: "none",
            daySelectedBorderColor: "transparent",
            daySelectedColor: "#34323A",
            daySelectedHoverColor: "#000000",
            dayHoverColor: "#000000",
            dayHoverBackground: "#EEEDFD",
            //   dayHoverRangeColor: "#000000", // attention
            dayHoverRangeBackground: "#EEEDFD",
            dayAccessibilityBorderColor: "none",

            inputLabelDisplay: "flex",
            inputLabelBorder: "none",
            inputLabelMargin: 0,
            inputPadding: 0,
            inputBorder: "none",
            inputFontSize: 14,
            inputColor: "#34323A",
            inputActiveBoxShadow: "none",
            inputBackground: "none",

            datepickerZIndex: 50,
            datepickerBoxShadow: "0px 5px 15px #28128733",
            datepickerBorderRadius: 10,
            datepickerPadding: 25,
            datepickerPosition: "absolute",
            datepickerMonthsGridGap: 10,

            dateRangeBorder: "none",
            dateRangeArrowIconColor: "#34323A",
            dateRangeStartDateInputPadding: 0,
            dateRangeEndDateInputPadding: 0,
            dateRangeDatepickerWrapperLeft: 0,
            dateRangeDatepickerWrapperTop: 50,
          },
        }}
      >
        <DateRangeInput
          onDatesChange={(data) => {
            dispatch({ type: "dateChange", payload: data });
            onDatesChange(data);
          }}
          onFocusChange={(focusedInput) =>
            dispatch({ type: "focusChange", payload: focusedInput })
          }
          startDate={state.startDate} // Date or null
          endDate={state.endDate} // Date or null
          focusedInput={state.focusedInput} // START_DATE, END_DATE or null
        />
      </ThemeProvider>
    </SpDatePickerWrapper>
  );
};

export default DateRanger;
