import React, { useCallback, useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EndorsmentBadge from "../svgs/endorsementBadge";
import SpUploader from "../globals/SpUploader";
import useFileUploader from "../hooks/useFileUploader";
import UploadIcon from "../svgs/UploadIcon";
import { db, spryteCloudUrl, uploadFileToStorage } from "../globals/services";
import { CircularProgress, Zoom } from "@mui/material";
import { CustomTooltip } from "../globals/CustomTooltip";
import OptionInfo from "./OptionInfo";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import EndorsmentBadge2 from "../svgs/EndorsementBadge2";
import { generateTimestamp } from "../globals/helpers";
// import SPRYTLOGO from "/images/sprytelab-logo.png";

const LeftSection = ({
  devData,
  devPublic,
  vendorPublic,
  vendor,
  devId,
  firebase,
  profile,
  setProfile,
  handleDevDetailUpdate,
  isDev,
  renderBadge,
  isResumeUploadeding,
  setIsResumeUploadeding,
}) => {
  // console.log("devData", devData);

  const [, setProgress] = useState(0);
  const [, setError] = useState("");
  const [, setLoading] = useState(false);

  const handleUpdate = (im) => {
    if (im) {
      const storage = firebase.getStorage();
      const uploadTask = storage
        .ref(`devs/${devId}/profile/${im.name}`)
        .put(im);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setError(error);
        },
        () => {
          storage
            .ref(`devs/${devId}/profile`)
            .child(im.name)
            .getDownloadURL()
            .then(async (url) => {
              // setUrl(url);
              setProgress(0);
              const profile = {
                fileUrl: url,
                fileName: im.name,
              };
              setProfile({
                url: url,
                name: im.name,
              });
              setLoading(true);
              await firebase.createProfile(devId, profile);
              setLoading(false);
            });
        }
      );
    } else {
      setError("Error please choose an image to upload");
    }
  };

  const handChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      // console.log(fileType);
      // check if the file is an image
      if (fileType.includes("image/")) {
        setError("");
        try {
          if (profile?.id) firebase.deleteProfile(devId, profile?.id);
        } catch (error) {
          console.log(error);
        }
      } else {
        setError("Please select an image to upload");
      }

      handleUpdate(file);
    }
  };

  useEffect(() => {
    const sub = db
      .collection(`devs/${devId}/profiles`)
      .orderBy("uploadedDate", "desc")
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length > 0) {
          // console.log(snapshot.docs[0].data());
          const data = snapshot.docs[0].data();
          setProfile({
            url: data.fileUrl,
            name: data.fileName,
            id: snapshot?.docs[0].id,
          });
        }
      });

    return () => sub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devId]);

  const handleResumeUpload = useCallback(
    async (files) => {
      // console.log("Selected files:", files);
      // Handle the selected files here
      const resume = files[0];
      if (!devData?.last || !devData?.first) return;
      const folderName = `${(devData?.first ?? "").replace(/\s+/g, "_")}_${(
        devData?.last ?? ""
      ).replace(/\s+/g, "_")}`;
      setIsResumeUploadeding(true);
      const resumeFileUrl = await uploadFileToStorage(
        `${devData?.vendorRef.path}/${folderName}`,
        resume
      );
      let bodyData = {
        resume_dict: {},
        additional_data: {},
        dev_id: devId,
        vendor_id: devData?.vendorRef?.id,
      };

      bodyData.resume_dict[resume?.name] = resumeFileUrl;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(bodyData),
        redirect: "follow",
      };

      const sendDevData = spryteCloudUrl("resume_parser");

      fetch(sendDevData, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("result", result);
          setIsResumeUploadeding(false);
          return;
        })
        .catch((error) => {
          console.log("error", error);
          setIsResumeUploadeding(false);
        });
    },
    [devData?.vendorRef?.id, devData?.vendorRef?.path, devId]
  );

  const { openFileDialog, fileInputElement } = useFileUploader({
    onFileSelect: handleResumeUpload,
    accept: ".pdf,.doc,.docx,.txt",
    multiple: false, // Allow multiple file selection
  });

  // console.log("devData", devData);

  return (
    <div className="_spDevPage--left">
      <div className="_spDevPage--left-profile">
        <div className="_spDevPage--left-profile_avatar">
          {profile?.url || devPublic?.logoStorage ? (
            <img
              src={profile?.url || devPublic?.logoStorage}
              alt={profile?.name}
            />
          ) : (
            <img src={"/images/devEmptyProfile.png"} alt="Profile" />
          )}
          {/* <img src={devPublic?.logoStorage} alt="Profile" /> */}
        </div>
        <div className="_spDevPage--left-profile_upload">
          <SpUploader
            uploadText={
              devPublic?.logoStorage
                ? "Change Profile Picture"
                : "Upload Profile Picture"
            }
            accept="image/*"
            onFileSelect={handChange}
          />
        </div>
      </div>
      <div
        className="_spDevPage--left-resume"
        style={{
          cursor: isResumeUploadeding ? "not-allowed" : "pointer",
        }}
        onClick={(e) => {
          if (!isResumeUploadeding) {
            openFileDialog(e);
          }
        }}
      >
        {fileInputElement}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h4>Resume</h4>
          {isResumeUploadeding ? (
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <CircularProgress size={12} />
              <span>processing...</span>
            </div>
          ) : !devData?.resume && !devData?.resumeData?.resumeUrl ? (
            <EndorsmentBadge fill="#888888" />
          ) : (
            <a
              href={devData?.resume ?? devData?.resumeData?.resumeUrl}
              target="blank"
              style={{
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                color: "transparent",
                backgroundColor: "transparent",
              }}
              className="link"
              onClick={(e) => e.stopPropagation()}
            >
              <EndorsmentBadge2 />
            </a>
          )}
        </div>
        <div className="_spDevPage--left-resume__text">
          <UploadIcon />
          <h3>Upload</h3>
        </div>
        <div className="_spDevPage--left-resume__info">
          <p>
            Upload your existing resume, and {`we’ll`} automatically auto-fill
            your profile
          </p>
        </div>
      </div>
      <a
        className="_spDevPage--left-branded"
        href={`https://www.sprytelabs.com/partners/${vendorPublic?.slug}/resource/${devPublic?.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="_spDevPage--left-branded_info">
          {vendorPublic?.logo?.url && (
            <div className="_spDevPage--left-branded_info__img">
              <img src={vendorPublic?.logo?.url} alt="Branded" />
            </div>
          )}
          <h4 className="_spDevPage--left-branded_info__name">
            {vendorPublic?.companyName} Branded Profile
          </h4>
        </div>
        <div className="_spDevPage--left-branded_details">
          <p>
            Your company branded profile highlights your company's know-how{" "}
          </p>
        </div>
        {/* <div className="_spDevPage--left-branded_link"></div> */}
      </a>

      <a
        className="_spDevPage--left-branded"
        href={`https://www.sprytelabs.com/profile/${devPublic?.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="_spDevPage--left-branded_info">
          <div className="_spDevPage--left-branded_info__img">
            <img src={"/images/sprytelab-logo.png"} alt="Branded" />
          </div>
          <h4 className="_spDevPage--left-branded_info__name">
            Spryte Branded Profile
          </h4>
        </div>
        <div className="_spDevPage--left-branded_details">
          <p>
            Spryte always uses the Spryte-branded profile to highlight you on
            sprytelabs.com. It’s optimized for conversion & trust.
          </p>
        </div>
        {/* <div className="_spDevPage--left-branded_link">
          <a
            href={`https://www.sprytelabs.com/profile/${devPublic?.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            More <ArrowForwardIosIcon />
          </a>
        </div> */}
      </a>

      <a
        href={`https://www.sprytelabs.com/resource/${devId}`}
        className="_spDevPage--left-unbranded"
      >
        <span>Unbranded Profile</span>
        <ArrowForwardIosIcon />
      </a>

      {!isDev && (
        <div className="_spDevPage--left-options">
          <h3>Resource Options</h3>
          <div className="_spDevPage--left-options__items">
            <div className="_spDevPage--left-options__item">
              <span>Cross-Match</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    title="Match this resource with other Partners"
                    desc={`If you have selected the "Cross Vendor" option under your Account Page, you can select here if you want this resource to be matched with other Partners or not.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="crossVendors"
                    name="crossVendors"
                    checked={devData?.crossVendor ?? true}
                    change={(e) => {
                      handleDevDetailUpdate("crossVendor", e.checked);
                    }}
                  />
                </div>
              </CustomTooltip>
            </div>

            <div className="_spDevPage--left-options__item">
              <span>Feature</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    title="FEATURE THIS RESOURCE"
                    desc={`This resource will be featured on your Sprytelabs.com profile page`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="isfeatured"
                    name="featured"
                    checked={devData?.featured ?? false}
                    change={(e) => {
                      handleDevDetailUpdate("featured", e.checked);
                    }}
                  />
                </div>
              </CustomTooltip>
            </div>

            <div className="_spDevPage--left-options__item">
              <span>Discovery</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    title="MAKE THIS RESOURCE DISCOVERABLE TO YOUR ENTIRE ORGANISATION"
                    desc={`The Spryte Platform is made to work for large and small partners alike. If you want this resource to be discoverable to other users within your own Organisation, leave this on. They can then be added on your internal projects.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="isdiscoverable"
                    name="discovery"
                    checked={devData?.discovery ?? true}
                    change={(e) => {
                      handleDevDetailUpdate("discovery", e.checked);
                    }}
                  />
                </div>
              </CustomTooltip>
            </div>

            <div className="_spDevPage--left-options__item">
              <span>Bookable</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    title="MAKE THIS RESOURCE BOOKABLE ON THE SPRYTE PLATFORM"
                    desc={`The Spryte Platform is made to work for many use cases. By default all resources you add who have a profile completion score of above 80% are bookable on the spryte platform by our clients. If you would like to use the Spryte-Partner or Spryte-Dev platforms for your own company needs, but have resources which you don’t want to offer for booking, you may toggle this option off for them.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="isBookable"
                    name="isBookable"
                    checked={devData?.status}
                    readOnly={!devData?.devRate}
                    disabled={!devData?.devRate}
                    change={(e) => {
                      if (!devData?.devRate) return;
                      handleDevDetailUpdate("status", e.checked);
                    }}
                  />
                </div>
              </CustomTooltip>
            </div>
            {vendor?.settings?.ops?.discovery && (
              <div className="_spDevPage--left-options__item">
                <span>Lead Time</span>
                <CustomTooltip
                  TransitionComponent={Zoom}
                  title={
                    <OptionInfo
                      title="Automatic Resource Lead Time"
                      desc={`If you don't want your resources to be immediatly booked (for tomorrow as an example), you can set a lead time. Setting a lead time of 15 days for example, will ensure that all your resources who have the lead time flag set will be undiscoverable and not booked until 15 days from today. This is automatically applied every day so that you don't have to manage availability precisely to the day on the platform.`}
                    />
                  }
                  placement="right-start"
                  arrow
                >
                  <div
                    className="_spDevPage--left-options__item-switch"
                    style={{ width: "fit-content" }}
                  >
                    <SwitchComponent
                      id="checkedLead"
                      name="leadTimeOn"
                      checked={devData?.leadTime?.leadTimeOn ?? false}
                      change={async (e) => {
                        await handleDevDetailUpdate("leadTime", e.checked);
                        // await handleDevDetailUpdate("leadTime.lastUpdated", );
                      }}
                    />
                  </div>
                </CustomTooltip>
              </div>
            )}

            <div className="_spDevPage--left-options__item">
              <span>Intern</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    // title="Match this resource with other Partners"
                    desc={`Set your profile as an INTERN or RESOURCE. Interns are students looking for internships,  while resources are looking for full time jobs.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="intern"
                    name="intern"
                    checked={devData?.intern ?? false}
                    change={(e) => {
                      handleDevDetailUpdate("intern", e.checked);
                    }}
                  />
                </div>
              </CustomTooltip>
            </div>

            <div className="_spDevPage--left-options__item">
              <span>Draft</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    // title="Match this resource with other Partners"
                    desc={`DRAFT resources have incomplete profiles. If you wish to delete a resource, first move them to draft, then delete them from the draft resources section.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="draft"
                    name="draft"
                    checked={!devData?.devRate ? false : devData?.draft}
                    // readOnly={!devData?.devRate}
                    change={(e) => {
                      if (!devData?.devRate) return;
                      handleDevDetailUpdate("draft", e.checked);
                    }}
                    disabled={!devData?.devRate}
                  />
                </div>
              </CustomTooltip>
            </div>
            <div className="_spDevPage--left-options__item">
              <span>Verified</span>
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  <OptionInfo
                    // title="Match this resource with other Partners"
                    desc={`Mark the profile as VERIFIED once you want to include it in your roster, this allows for matches to be computed.`}
                  />
                }
                placement="right-start"
                arrow
              >
                <div
                  className="_spDevPage--left-options__item-switch"
                  style={{ width: "fit-content" }}
                >
                  <SwitchComponent
                    id="isVerified"
                    name="isVerified"
                    checked={
                      !devData?.userRef && !devData?.draft
                        ? true
                        : devData?.isVerified
                    }
                    // readOnly={!devData?.devRate}
                    change={(e) => {
                      if (!devData?.devRate) return;
                      handleDevDetailUpdate("isVerified", e.checked);
                    }}
                    disabled={!devData?.devRate}
                  />
                </div>
              </CustomTooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftSection;
