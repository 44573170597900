import React from 'react';
import { Link } from 'react-router-dom';
import '../css/ErrorPage.css';

function ErrorPage() {
  return(
    <div className="errorPage">
      <div className="errorDiv">
        <img src="https://www.bittbox.com/wp-content/uploads/2008/06/10-37-Intriguing-404-Error-Page-Designs.jpg" alt="error"/>
        <div className="error-cta">
          <h1>404</h1>
          <h2>SORRY! Something went wrong.</h2>
          <p>This page does not exist. However, if you would like to navigate back to the Spryte Partner homepage, please click the button below.</p>
          <Link to={'/'}>HOME</Link>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage;