import React, { useState } from "react";
import Entries from "../Entries";

export default function HiddenCard(props) {
  const [level, setLevel] = useState("1");
  const [exp, setExp] = useState("1");
  const [expYears, setExpYears] = useState("0");
  const [expMonth, setExpMonth] = useState("0");
  const [proj, setProj] = useState("1");
  const [interest, setInterest] = useState("Interested");
  const [isYears, setIsYears] = useState(true);

  // Store data to the database
  const onAdd = async (e) => {
    let exp = 0;
    if (expMonth !== "0") {
      exp = Math.abs(parseInt(expMonth));
    } else {
      exp = Math.abs(parseInt(expYears) * 12);
    }
    if (
      level !== "" &&
      exp !== 0 &&
      exp !== "" &&
      proj !== "" &&
      interest !== ""
    ) {
      if (exp) {
        let data = {
          experience: exp,
          interest: interest,
          projects: Math.abs(parseInt(proj)),
          skill: Math.abs(parseInt(level)),
          domain: props.title,
        };
        data.domainRef = props?.domain?.ref;

        props.setShowCard(false);
        await props.firebase.createPrimaryDomain(props.devId, data);

        // // Updating lastUpdated for the dev
        await props.firebase.updateDev(props.devId, {});
      }
    }
  };

  if (props.showCard) {
    window.addEventListener("mouseup", (e) => {
      let box = document.getElementById("stackInfoBox");
      try {
        if (!box.contains(e.target)) {
          props.setShowCard(false);
        }
      } catch (e) {
        // console.log(e)
      }
    });
  }

  return (
    <div
      id={props.showCard ? "stackInfoBox" : ""}
      className={
        props.showCard ? `${props.className} show` : `${props.className} hide`
      }
    >
      <div className="stack_form">
        <div className="stack_top">
          <div className="description">
            <p>
              <span>Description: </span>
              {props.description}
            </p>
          </div>
        </div>

        <Entries
          level={level}
          setLevel={setLevel}
          exp={exp}
          setExp={setExp}
          proj={proj}
          setProj={setProj}
          setInterest={setInterest}
          isYears={isYears}
          setIsYears={setIsYears}
          expMonth={expMonth}
          setExpMonth={setExpMonth}
          expYears={expYears}
          setExpYears={setExpYears}
        />

        <div className="stack_bottom">
          <div>
            <p>
              <span>Title: </span>
              {props.title}
            </p>
          </div>
          <div>
            <p>
              <span>Category: </span>
              {props.category}
            </p>
          </div>
          <div className="stack_btn">
            <button className="btn" onClick={(e) => onAdd(e)}>
              Add
            </button>
            <button className="btn" onClick={(e) => props.setShowCard(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
