import React, { useEffect, useState, useMemo } from "react";
// import '../../../../../css/stacks.css';
import DomainsList from "./DomainsList";
import Search from "../Search";
import { Pagination } from "@mui/material";
import { getDocumentsByArrayMembershipInCollection } from "../../../../globals/services";

export default function DomainsCarousel(props) {
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(12);
  const [numberOfData, setNumberOfData] = useState(0);

  const currentTableData = useMemo(() => {
    const theData =
      filteredDomains?.length < 1 ? props.domains : filteredDomains;
    setNumberOfData(theData.length);
    const firstPageIndex = (currentPage - 1) * pageLimit;
    const lastPageIndex = firstPageIndex + pageLimit;
    return theData.slice(firstPageIndex, lastPageIndex);
  }, [filteredDomains, props.domains, currentPage]);

  useEffect(() => {
    props.numOfDomToShow
      ? setPageLimit(props.numOfDomToShow)
      : setPageLimit(12);
  }, [props.domains]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    props?.onDomainsPaginate && props.onDomainsPaginate(event, page);
  };

  const handleSearch = async (e) => {
    // setSearchValue(e.target.value);
    const dataDocs = await getDocumentsByArrayMembershipInCollection(
      "industry",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "name",
      true,
      true,
      false,
      20
    );

    const data = dataDocs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
    }));
    setFilteredDomains(data);
  };

  return (
    <div className="projects _spTechSkills_wrap">
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder="Ex: Agriculture, category"
        allData={props.domains}
        setFilteredDatas={setFilteredDomains}
        currentPage={currentPage}
        pageLimit={pageLimit}
        onChange={handleSearch}
      />
      <div className="_spTechskillsSection stacks_area">
        <DomainsList
          domains={currentTableData}
          firebase={props.firebase}
          devId={props.devId}
          domainExperiencesObject={props.domainExp}
          showHiddenCard={props.showHiddenCard}
          useExist={props.useExist}
          setSelectedData={props.setSelectedDomain}
          domId={props.domId}
        />
        <div className="stacks_pagination">
          <Pagination
            count={Math.ceil(props.domains?.length / pageLimit)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
