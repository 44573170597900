import styled from "styled-components";

export const ListDev = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  padding: 10px 10px;

  .showOnHover {
    display: none;
    opacity: 0;
  }
  .showAlways {
    display: flex;
    opacity: 1;
  }
  &:hover {
    .showOnHover {
      display: flex;
      opacity: 1;
    }
  }
`;

export const DevProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DevProfileBorder = styled.div`
  // clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0% 80%, 0% 20%);
  border-radius: 50%;
  width: ${({ size }) => (size ? `calc(${size} + 5px)` : "50px")};
  height: ${({ size }) => (size ? `calc(${size} + 5px)` : "50px")};
  // background-color: ${({ color }) => (color ? color : "#efefef")};
  border: 3px solid ${({ color }) => (color ? color : "#efefef")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
`;
export const DevProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -1rem;
  .devInfoIcon {
    background-color: ${({ color }) => (color ? color : "#efefef")};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -15px;
  }
`;

export const DevRater = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e9effc;
  padding: 5px;
  border-radius: 5px;
`;
export const DevDetails = styled.div`
  flex: 3;
  display: grid;
  gap: 0.3rem;
  .listDevLoc {
    display: flex;
    align-items: center;
    // gap: 0.3rem;
    span {
      color: #808080;
      font-size: 1.6rem;
    }
  }
  h4 {
    margin: 0;
    font-size: 1.8rem;
    white-space: unset;
    line-height: 1;
  }
`;

export const DevPricing = styled.div`
  background-color: ${({ color }) => (color ? color : "#E7E1FF")};
  // height: 46px;
  padding: 0.5rem 1rem;
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 5px;

  .amountText {
    font-size: 1.9rem;
    font-weight: 800;
  }

  span:not(.amountText) {
    font-size: 1.8rem;
  }
`;
export const DevInterview = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &.confirmedInterview {
    div {
      background-color: #02d161;
      color: #fff;
      align-items: center;
    }
  }

  div {
    background-color: #f5f5f5;
    padding: 0.2rem 0.2rem;
    color: #808080;
    flex: 1;
    border-radius: 5px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    span {
      color: #ff1842;
      font-weight: 600;
      font-size: 0.9em;
    }
  }
`;

export const DevTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  div {
    background-color: #c4f0fe;
    border-radius: 5px;
    color: #289ec4;
    padding: 0.2rem 1rem;
  }
`;

export const DevNotes = styled.div`
  display: grid;
  gap: 1rem;
`;

export const DevNote = styled.p`
  
  span:first-child {
position: relative;
font-weight: 600;
    &::after{
      content: "";
      position: absolute;
      top: 50%;
      right: -22px;
      width: 15px;
      height:2px;
      background-color: black;
      opacity: 0.5;
    }
  }
  span:last-child {
    margin-left: 35px;
    color: #808080;
    word-break: break-word;
  }

  q {
    
    margin-left: 1rem;
  }

  
  }
`;

export const DevOptions = styled.div`
  padding: 10px 0;
  background-color: #f4f5f9;
  transition: all 0.3s ease-in-out;
  // display: flex;
  // gap: 1rem;
  justify-content: space-between;
`;

export const SingleOption = styled.div`
  display: grid;
  align-items: center;
  // justify-content: center;
  justify-items: center;
  gap: 0.5rem;
  flex: 1;
  width: 80px;
  p {
    color: #4361e9;
    font-size: 1.4rem;
  }
  &:not(:last-child) {
    border-right: 2px solid #d0d5dc;
  }
  &:hover {
    cursor: pointer;
  }
`;
