import React from "react";
import {
  addDocument,
  GetNowTimeStamp,
  GetRefFromPath,
} from "../../../../globals/services";
import SpMatchCard, {
  DevActions,
  DevProfile,
  DevRates,
  DevsClients,
  DevStacks,
} from "../../../../globals/SpMatchCard";

const SingleMatch = ({
  member,
  allTucPositions,
  setSelectedTabIndex,
  setShowDevProfile,
}) => {
  const [isPicked, setIsPicked] = React.useState(false);
  const devData = member["devData"];
  devData["experience"] = devData?.stats?.stacks?.stats?.max?.experience;
  //should be dev hourly rate
  devData["hourlyRate"] = devData?.hourlyRate ?? 0;
  devData["sprintRate"] =
    devData?.hourlyRate > 0 ? (devData?.hourlyRate * 80).toFixed(2) : null;
  devData["monthlyBurn"] =
    devData?.hourlyRate > 0 ? (devData?.hourlyRate * 23 * 8).toFixed(2) : null;

  async function addDevToTuc(tucIndex, positionIndex) {
    if (member.ref.parent.parent.path == allTucPositions[tucIndex].tucPath) {
      if (member.ref.parent.id.split("-")[1] == positionIndex.toString()) {
        await member.ref.update({
          status: GetRefFromPath(`${member.status.parent.path}/CONSIDERED`),
        });
        return;
      }
    }
    await member.ref.update({
      status: GetRefFromPath(`${member.status.parent.path}/ABANDONED`),
    });
    await addDocument(
      `${allTucPositions[tucIndex].tucPath}/POS-${positionIndex}`,
      {
        addedOn: GetNowTimeStamp(),
        availableDates: [],
        devRef: member?.devRef,
        name: member?.name ?? "",
        notes: [],
        partnerRef: member.partnerRef,
        rates: [],
        reviews: [],
        status: GetRefFromPath(
          `${
            allTucPositions[tucIndex].tucPath?.split("/tuc")[0]
          }/resourceStatuses/CONSIDERED`
        ),
        tags: [],
      }
    );
  }

  async function onDevSelect() {
    setShowDevProfile(member);
    setSelectedTabIndex(4);
  }

  // convert time to hours ago
  const timeAgo = (time) => {
    const timeNow = new Date().getTime();
    const timeDiff = timeNow - time;
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
    // if hours is less than 24 return hours ago
    if (hoursAgo < 24) {
      return `${hoursAgo} hours ago`;
    }
    // if hours is more than 24 return days ago
    const daysAgo = Math.floor(hoursAgo / 24);
    return `${daysAgo} days ago`;
  };

  return (
    <SpMatchCard devId={devData?.id} count={(member?.matchRank ?? 0) + 1}>
      <DevProfile
        devData={devData}
        profileUrl={devData?.logoStorage}
        profileAlt={devData?.first}
        onSelect={() => onDevSelect()}
        showRates={false}
        score={devData?.matchesDocSnap?.data().mScorePlus}
      />
      <DevStacks
        experience={devData?.experience / 12}
        stacks={devData?.stacks}
        title="Matching Expertise"
        experText="experience"
        showNumber={5}
      />
      {/* <DevsClients clients={devData?.clients} showNumber={4} /> */}
      {devData?.hourlyRate && devData?.sprintRate && devData?.monthlyBurn ? (
        <DevRates
          titleRight=""
          pricePerc={null}
          hourlyRate={devData?.hourlyRate ?? " -"}
          sprint={
            devData?.sprintRate?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
          }
          month={
            devData?.monthlyBurn?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
          }
        />
      ) : null}
      {isPicked && (
        <div className="_spSingleOptionMatch--optionPicker">
          {allTucPositions.map((option, tucIndex) => {
            return (
              <div
                key={tucIndex}
                className="_spSingleOptionMatch--optionPicker-optPosLists"
              >
                <h3>{option.name}</h3>
                {option.positions.map((position, positionIndex) => {
                  return (
                    <div
                      key={positionIndex}
                      className="_spSingleOptionMatch--optionPicker-optPosLists--position"
                      onClick={async () => {
                        await addDevToTuc(tucIndex, position.posIndex);
                      }}
                    >
                      <p>{position.label}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      <DevActions
        actionsType={true}
        cls={isPicked ? "_spSingleOptionMatch--actions" : ""}
        onChat={async () => {
          if (isPicked) {
            setIsPicked(false);
            return;
          }
          await member.ref?.update({
            status: GetRefFromPath(`${member.status.parent.path}/ABANDONED`),
          });
        }}
        chatText="Dismiss"
        pickText="Pick"
        onPick={async () => {
          setIsPicked(true);
        }}
        pickDisabled={
          !devData.status ||
          !devData?.hourlyRate ||
          !devData?.sprintRate ||
          !devData?.monthlyBurn
        }
      />
    </SpMatchCard>
  );
};

export default SingleMatch;
