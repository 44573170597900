import React from "react";

const DevPosition = ({ size = "14", h = "10", fill = "#4361E9" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2386_19580)">
        <path
          d="M8.92466 13.1405C9.3736 13.1405 9.3736 13.8232 8.92466 13.8232H0.698716C0.51021 13.8232 0.357422 13.6703 0.357422 13.4818V3.20177C0.357422 3.10752 0.395619 3.02203 0.457297 2.96035L3.14102 0.276798C3.20766 0.21016 3.29496 0.176758 3.38227 0.176758H11.1026C11.2911 0.176758 11.444 0.329712 11.444 0.518217V6.49351C11.444 6.94262 10.7613 6.94262 10.7613 6.49351V0.859511H3.52365L1.04018 3.34315V13.1405H8.92466V13.1405ZM3.11721 10.1313C2.6681 10.1313 2.6681 9.44858 3.11721 9.44858H8.18817C8.63727 9.44858 8.63727 10.1313 8.18817 10.1313H3.11721ZM3.11721 5.58934C2.6681 5.58934 2.6681 4.90659 3.11721 4.90659H8.18817C8.63727 4.90659 8.63727 5.58934 8.18817 5.58934H3.11721ZM3.11721 7.10334C2.6681 7.10334 2.6681 6.42059 3.11721 6.42059H8.18817C8.63727 6.42059 8.63727 7.10334 8.18817 7.10334H3.11721ZM3.11721 8.61734C2.6681 8.61734 2.6681 7.93458 3.11721 7.93458H8.18817C8.63727 7.93458 8.63727 8.61734 8.18817 8.61734H3.11721ZM3.11721 4.07535C2.6681 4.07535 2.6681 3.39259 3.11721 3.39259H8.18817C8.63727 3.39259 8.63727 4.07535 8.18817 4.07535H3.11721ZM11.3283 8.01495C12.1524 8.01495 12.8204 8.68298 12.8204 9.50712C12.8204 9.80641 12.7323 10.085 12.5807 10.3185C13.2019 10.7307 13.5971 11.4112 13.6411 12.1573C13.6674 12.6047 12.9871 12.6446 12.961 12.1973C12.9261 11.6071 12.5871 11.0815 12.0659 10.8043C11.8483 10.9283 11.5966 10.9991 11.3283 10.9991C11.0379 10.9991 10.7669 10.9163 10.5377 10.7727C9.98476 11.0393 9.61833 11.5837 9.58212 12.1973C9.55582 12.6446 8.87572 12.6047 8.90201 12.1573C8.94814 11.3771 9.37741 10.6711 10.0443 10.2676C9.91217 10.0449 9.83627 9.78491 9.83627 9.50712C9.83627 8.68315 10.5043 8.01495 11.3283 8.01495V8.01495ZM11.3283 8.6977C10.8813 8.6977 10.519 9.06016 10.519 9.50712C10.519 9.95407 10.8813 10.3164 11.3283 10.3164C11.7752 10.3164 12.1377 9.95407 12.1377 9.50712C12.1377 9.06016 11.7754 8.6977 11.3283 8.6977V8.6977Z"
          fill="#808080"
        />
      </g>
      <defs>
        <clipPath id="clip0_2386_19580">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DevPosition;
