import React, { useState } from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const TabEl = styled.div`
  padding: 2rem 1rem;
  display: flex;
  gap: 1rem;
  background-color: ${({ selectedTab }) => selectedTab && "#e5f4ff"};
  border-top: ${({ selectedTab }) => selectedTab && "1px solid #aaddff"};
  &:hover {
    background-color: #f7fafc;
    cursor: pointer;
  }
`;

const TabTitle = styled.h3``;
const TabDesc = styled.p`
  font-weight: 400;
  font-size: 12px;
`;

function SpTabs({
  tabContent = [],
  isCol = false,
  onTabClick = null,
  defaultTab = "",
  sx = null,
  wrapperCls = "",
  tabCls = "",
}) {
  const [selectedTab, setSelectedTa] = useState();
  return (
    <TabContainer isCol={isCol} style={sx} className={wrapperCls}>
      {tabContent.map((tab, i) => {
        return (
          <TabEl
            className={tabCls}
            key={`${tab?.title}_${i}`}
            onClick={() => {
              setSelectedTa(tab?.title);
              onTabClick != null &&
                onTabClick({ title: tab?.title, tabIndex: i });
            }}
            selectedTab={
              selectedTab
                ? selectedTab === tab?.title
                : defaultTab === tab?.title
            }
          >
            <div style={{ flexBasis: "90%" }}>
              <TabTitle>{tab?.title}</TabTitle>
              {tab?.desc && <TabDesc>{tab?.desc}</TabDesc>}
            </div>
            <div
              style={{
                width: "4rem",
                height: "4rem",
                backgroundColor: "#464646",
                // textAlign: "center",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <p style={{ marginBottom: "0" }}> {tab?.length}</p>
            </div>
          </TabEl>
        );
      })}
    </TabContainer>
  );
}

export default SpTabs;
