import styled from "styled-components";

export const StepperElementWrapper = styled.div`
  display: flex;
  gap: 1rem;
  cursor: ${(props) => (props.isEnableEdit ? "pointer" : "default")};
  h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
`;

export const StepperRight = styled.div`
  flex: 3;
  display: grid;
  gap: 0.2rem;
  ._stepDiploma {
    color: black;
    font-weight: 700;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  ._stepDesc {
    color: #656667;
    font-weight: 400;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  ._editActions button {
    margin-right: 5px;
  }
  ._bulletEdit {
    display: flex;
    gap: 5px;
    margin-top: 5px;
  }
  ._bullets {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > span{
    margin-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #656667;

      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
`;

export const StepperLeft = styled.div`
  flex: 1;
  min-width: 50px;
`;
