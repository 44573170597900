import React, { useContext, useState } from "react";
import { GlobalVendorContex } from "../Firebase/GlobalVendorContext";
import { AuthUserContext } from "../Firebase/session";
import LefttTop from "./topNav/LeftTop";
import "../../css/dan.css";
import { FirebaseContext } from "../Firebase";
import { Feedback, FindInPage } from "@mui/icons-material";
import { getCollectionRefFromPath } from "../globals/services";

export default function Header() {
  const vendorData = useContext(GlobalVendorContex);
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  const [searchValue, setSearchValue] = useState("");
  const [vendors, setVendors] = useState(null);
  const [fetchActiveVendors, setFetchActiveVendors] = useState(true);

  const onInputChange = async (value) => {
    setSearchValue(value);
    if (!value) {
      setVendors(null);
      return;
    }
    try {
      let vendorsQuery = getCollectionRefFromPath("vendors").where(
        "nameIndex",
        "array-contains",
        value?.toLowerCase()
      );
      if (fetchActiveVendors) {
        vendorsQuery = vendorsQuery.where(
          "activePartner",
          "==",
          fetchActiveVendors
        );
      }
      const fetchedVendors = await vendorsQuery.get();
      setVendors(fetchedVendors?.docs ?? null);
      if (!fetchedVendors?.docs?.length) setVendors(null);
    } catch (e) {
      console.log("error: ", e);
    }
  };

  const handleOnSelect = (id) => {
    vendorData.setSearchedVendId(id);
    vendorData.isSubmitted();
    setSearchValue("");
    setVendors(null);
  };

  return (
    <div className="header">
      {authUser.isSuperPartner ? (
        <LefttTop
          onInputChange={onInputChange}
          isReset={vendorData.isReset}
          name={vendorData?.vendor?.companyName ?? ""}
          searchValue={searchValue}
          vendors={vendors}
          handleOnSelect={handleOnSelect}
          fetchActiveVendors={fetchActiveVendors}
          setFetchActiveVendors={setFetchActiveVendors}
        />
      ) : (
        <div className="header__left"></div>
      )}

      <div className="header__right">
        <div>
          <Feedback className="icon" />
          <a
            href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo"
            rel="noreferrer"
            target="_blank"
            className="link"
          >
            <span>Feedback?</span>
          </a>
        </div>
        <div>
          <FindInPage className="icon" />
          <a
            href="https://sprytelabs.github.io/Spryte-Docs/#/partnerPlatform"
            rel="noreferrer"
            target="_blank"
            className="link"
          >
            <span>Documentation</span>
          </a>
        </div>
      </div>
    </div>
  );
}
