import React from 'react';

// Create a firebase context to allow components to use the
// Firebase class defined in firebase.js
// FirebaseContext.Provider found in index.js within the src folder
const FirebaseContext = React.createContext(null);

export default FirebaseContext;

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase}/>}
  </FirebaseContext.Consumer>
);

