import { AddCircleOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
// import '../../../../css/Contract.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //https://fkhadra.github.io/react-toastify/introduction/
import DeleteComponent from "../DeleteComponent";

const CaseStudySection = (props) => {
  const [caseStudies, setCaseStudy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const vendorId = props.vendorId;

  const handChange = (e) => {
    const image = e.target.files[0];
    if (image.size <= 10000000) {
      if (image) {
        let storage = props.firebase.getStorage();
        const uploadTask = storage
          .ref(`vendors/${vendorId}/caseStudies/${image.name}`)
          .put(image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          (error) => {
            toast.error(error);
          },
          () => {
            storage
              .ref(`vendors/${vendorId}/caseStudies/`)
              .child(image.name)
              .getDownloadURL()
              .then(async (url) => {
                setProgress(0);
                const contract = {
                  fileRef: url,
                  fileName: image.name,
                  internal: false,
                };
                props.firebase.createExternalCaseStudy(vendorId, contract);
                setLoading(true);
                toast.success("Case Study Uploaded");

                if (loading) {
                  try {
                    const caseStudies =
                      await props.firebase.getExternalCaseStudies(vendorId);

                    setCaseStudy(caseStudies);
                    setLoading(false);
                  } catch (err) {
                    toast.error(err);
                  }
                }
              });
          }
        );
      } else {
        toast.error("Error please choose a file to upload");
      }
    } else {
      toast.error("Error please choose a file size less than 10MB");
    }
  };

  const deleteCaseStudy = async (fileName, docId) => {
    let storage = props.firebase.getStorage();
    const fileRef = storage.ref(`vendors/${vendorId}/caseStudies/${fileName}`);
    fileRef
      .delete()
      .then(async () => {
        await props.firebase.deleteCaseStudy(vendorId, docId);
        setLoading(true);
      })
      .catch((err) => {
        toast.error(err);
        props.firebase.deleteCaseStudy(vendorId, docId);
        setLoading(true);
      });
    const caseStudies = await props.firebase.getExternalCaseStudies(vendorId);
    setCaseStudy(caseStudies);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const caseStudies = await props.firebase.getExternalCaseStudies(
          vendorId
        );
        let externals = [];
        caseStudies.map((cases) => {
          return externals.push({
            value: cases.Id,
            label: cases.fileName,
          });
        });
        props.setExternal(externals);
        setCaseStudy(caseStudies);
        setLoading(false);
      } catch (err) {
        toast.error(err);
      }
    };

    getData();
  }, [props.firebase, loading, vendorId]);

  const handleClick = () => {
    const uploadbtn = document.querySelector("#csp_btn");
    uploadbtn.click();
  };

  let grid = caseStudies.map((caseStudy, index) => {
    return (
      <tr key={index}>
        <td>{caseStudy.fileName}</td>
        <td>{caseStudy.uploadedDate.toDateString()}</td>
        <td>
          <a className="download" href={caseStudy.fileRef} target="blank">
            Download
          </a>
        </td>
        <td>
          <DeleteComponent
            delete={() => deleteCaseStudy(caseStudy.fileName, caseStudy.Id)}
            fileName={caseStudy.fileName}
          />
        </td>
      </tr>
    );
  });
  return (
    <div className="contract_cont">
      <div className="ctr security">
        <div className="ctr_el_header resource-header">
          <div className="header-intro">
            <h3>Original Company Case Studies</h3>
            <div className="add_btn">
              <input
                type="file"
                name="myfile"
                id="csp_btn"
                onChange={handChange}
              />
              <AddCircleOutline
                className="icon"
                onClick={(e) => handleClick()}
              />
            </div>
            {progress > 0 ? <progress value={progress} max="100" /> : <></>}
          </div>

          <div className="content">
            <p className="lighter" style={{ marginTop: "-8.5px" }}>
              Upload your company's original best case studies for our review.
            </p>
          </div>
        </div>
      </div>

      <div className="contract_bottom">
        {grid.length !== 0 ? (
          <table className="stable">
            <thead className="stable_header">
              <tr>
                <th>File</th>
                <th>Uploaded Date</th>
                <th>Download</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>{grid}</tbody>
          </table>
        ) : (
          <div className="resource-header">
            <p
              style={{
                color: "red",
                fontWeight: "500",
                opacity: ".65",
                fontStyle: "italic",
                paddingTop: "25px",
              }}
            ></p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseStudySection;
