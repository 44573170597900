import { Close } from "@mui/icons-material";
import React from "react";
import SpUpload from "../../../../globals/SpUploadBtn";
import {
  deleteFileFromStorage,
  uploadFileToStorage,
} from "../../../../globals/services";
import expl from "./chrome-capture_1.jpg";

const AddTesti = (props) => {
  const [resetFile, setResetFile] = React.useState(false);
  const [file, setFile] = React.useState(null);
  if (props.isAdd) {
    // close the modal when we click  outside the data-request_form box
    window.addEventListener("mouseup", (e) => {
      let subBox = document.getElementById("data-testimonial");
      try {
        if (!subBox.contains(e.target)) {
          props.setIsAdd(false);
        }
      } catch (e) {
        // console.log(e)
        return;
      }
    });
  }

  const uploadFile = async () => {
    if (!file) return;
    const fileLink = await uploadFileToStorage(
      `vendors/${props.vendorId}/testimonials/avatars`,
      file
    );
    // console.log(fileLink);
    props.setAuthorAvatar(fileLink);
    return fileLink;
  };

  /**
   * Checks if it's an Edit or an add
   * If it's an edit it will just use the selected review data and use it as values in the input fields
   * and update the selected review when the vendor submit
   *
   * if it's an add it will just create a new review and push it to the reviews list
   *
   */
  const updatVendor = async () => {
    if (props.isEdit) {
      if (
        props.authorName &&
        props.authorPosition &&
        props.authorAvatar &&
        props.authorCompany &&
        props.authorQuote
      ) {
        let data = {
          author: props.authorName,
          authorAvatar: props.authorAvatar,
          authorCompany: props.authorCompany,
          authorPosition: props.authorPosition,
          testimonialText: props.authorQuote,
          display: props.displayed,
          featured: props.featured,
        };
        let testimonials = [...props.testimonials];
        testimonials[props.index] = data;

        let updates = {
          reviews: {
            testimonials: testimonials,
            lastUpdated: new Date(),
          },
        };

        await props.firebase.updateVendor(props.vendorId, updates);
        props.setIsEdit(false);
        props.setIsAdd(false);
      }
    } else {
      const fileLink = await uploadFile();
      if (
        props.authorName &&
        props.authorPosition &&
        fileLink &&
        props.authorCompany &&
        props.authorQuote
      ) {
        let data = {
          author: props.authorName,
          authorAvatar: fileLink,
          authorCompany: props.authorCompany,
          authorPosition: props.authorPosition,
          testimonialText: props.authorQuote,
          display: true,
          featured: true,
        };
        let testimonials = [...props.testimonials];
        testimonials.push(data);

        let updates = {
          reviews: {
            verifiedClientReviews: props.verifiedClientReviews + 1,
            testimonials: testimonials,
            lastUpdated: new Date(),
          },
        };
        await props.firebase.updateVendor(props.vendorId, updates);

        props.setIsAdd(false);
      }
    }
    props.isInitUpdated(true);
    props.isVendorUpdated(true);
  };

  const handleUpload = (e) => {
    setResetFile(false);
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      props.setAuthorAvatar(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
    setFile(file);
  };

  const handleImageDelete = () => {
    props.setAuthorAvatar("");
    setResetFile(true);
    deleteFileFromStorage(props.authorAvatar);

    // delete file from storage firebase using the url
  };

  return (
    <div className="addData_add">
      <div className="addData_add__content column" id="data-testimonial">
        <p className="addData_add__content-paragraph">
          Add a Testimonial. Make sure your client has given their permission to
          use their words and image
        </p>
        <img src={expl} alt="testimonial exemple" />
        <form className="addData_add__content-inputs column">
          <div className="column input">
            <label>Author Name</label>
            <input
              type="text"
              value={props.authorName}
              onChange={(e) => {
                props.setAuthorName(e.target.value);
                e.persist();
              }}
            />
          </div>
          <div className="column input">
            <label>Author Position</label>
            <input
              type="text"
              value={props.authorPosition}
              onChange={(e) => {
                props.setAuthorPosition(e.target.value);
                e.persist();
              }}
            />
          </div>
          <div className="column input">
            <label>Author Company</label>
            <input
              type="text"
              value={props.authorCompany}
              onChange={(e) => {
                props.setAuthorCompany(e.target.value);
                e.persist();
              }}
            />
          </div>
          <div className="column input addData_add__content-inputs--avatar">
            <label>Author Avatar Url</label>
            {/* <input
              type="text"
              value={props.authorAvatar}
              onChange={(e) => {
                // props.setAuthorAvatar(e.target.value);
                // e.persist();
              }}
            /> */}
            <div className="addData_add__content-inputs--avatar_upload">
              <SpUpload
                onChange={handleUpload}
                accept="image/*"
                resetFile={resetFile}
              />
              {props.authorAvatar && (
                <div
                  className="addData_add__content-inputs--avatar_upload__imBox"
                  onClick={handleImageDelete}
                >
                  <img src={props.authorAvatar} alt="avatar" />
                </div>
              )}
            </div>
          </div>
          <div className="column input">
            <label>Testimonial Quote</label>
            <textarea
              value={props.authorQuote}
              onChange={(e) => {
                props.setAuthorQuote(e.target.value);
                e.persist();
              }}
            ></textarea>
          </div>
        </form>

        <div className="addData_add__content-actions">
          <button
            id="addData_sub"
            className="btn"
            onClick={() => updatVendor()}
          >
            Submit
          </button>
          <button
            id="addData_cancel"
            className="btn"
            onClick={() => {
              props.setIsAdd(false);
              props.setIsEdit(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTesti;
