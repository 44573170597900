import React from "react";
import StackCard from "./StacksCard";

export default function StacksList(props) {
  return (
    <div className="_spTechskills__container-body">
      {props.currentStacks.map((stack, index) => (
        <StackCard
          key={index}
          stack={stack}
          firebase={props.firebase}
          devId={props.devId}
          index={index}
          stackExp={props.stackExp}
          showHiddenCard={props.showHiddenCard}
          useExist={props.useExist}
          setSelectedStacks={props.setSelectedStacks}
          title={props.title}
          onAdd={props.onAdd}
        />
      ))}
    </div>
  );
}
