import React, { useState, useEffect } from "react";
import styles from "../styles/requirementsSteps.module.css";

const UserLogs = ({ logsData, logAuthors }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(
      logsData?.filter(
        (item) => item?.type !== "CLICK" && item?.type !== "VIEW"
      )
    );
  }, [logsData]);

  function filterLogs(option) {
    if (option === "Actions") {
      const tempLogs = [...logsData]?.filter((item) => item.type === "ACTION");
      setList(tempLogs);
    } else if (option === "Simplified") {
      const tempLogs = [...logsData]?.filter(
        (item) => item.type !== "CLICK" && item.type !== "VIEW"
      );
      setList(tempLogs);
    } else if (option === "Emails") {
      const tempLogs = [...logsData]?.filter((item) => item.type === "EMAIL");
      setList(tempLogs);
    } else {
      setList(logsData);
    }
  }

  function formatDateTime(input) {
    var curdate = new Date(null);
    curdate.setTime(input * 1000);
    return curdate.toLocaleString().split(",")[0];
  }

  return (
    <div className=" _spReqTeam--OptionThird__details--right_logs sp_col">
      <div className=" _spReqTeam--OptionThird__details--right_logs__header sp_row">
        <h4>Activity Logs</h4>
        {logsData ? (
          <select
            onChange={(e) => filterLogs(e.target.value)}
            style={{
              background: "transparent",
              border: "2px solid rgba(128, 128, 128, 0.15)",
              fontWeight: "500",
              padding: "1.5px 10px",
            }}
          >
            <option style={{ fontWeight: "500", padding: "1.5px 0" }}>
              Simplified
            </option>
            <option style={{ fontWeight: "500", padding: "1.5px 0" }}>
              All
            </option>
            <option style={{ fontWeight: "500", padding: "1.5px 0" }}>
              Actions
            </option>
            <option style={{ fontWeight: "500", padding: "1.5px 0" }}>
              Emails
            </option>
          </select>
        ) : (
          <></>
        )}
      </div>
      {list?.length ? (
        <div className=" _spReqTeam--OptionThird__details--right_logs__list sp_col">
          {list?.map((log, index) => {
            return log.msg ? (
              <p
                className="_spReqTeam--OptionThird__details--right_logs__list-single"
                key={index}
              >
                <span
                  className="reqLogInd"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  {index + 1}.
                </span>
                <span className="reqLogTime">
                  {formatDateTime(log?.addedOn?.seconds)}{" "}
                  {new Date(log?.addedOn?.seconds)
                    .toTimeString()
                    .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1")}
                </span>
                <span className="reqLogAuthor">
                  {logAuthors[log?.author?.id]
                    ? logAuthors[log?.author?.id]?.["first"] +
                      " " +
                      `${logAuthors[log?.author?.id]?.["last"]?.[0]}.`
                    : ""}
                </span>
                <span className="reqLogMessage">{log?.msg}</span>
              </p>
            ) : null;
          })}
        </div>
      ) : (
        <p
          style={{
            opacity: ".55",
            fontStyle: "italic",
            fontSize: "14.5px",
            fontWeight: "500",
            padding: "15px 0",
          }}
        >
          No logs found.
        </p>
      )}
    </div>
  );
};

export default UserLogs;
