import { Switch } from "@mui/material";
import styled, { css } from "styled-components";

const StyledSwitch = styled(Switch)((props) => {
  return css`
    && {
      & .MuiSwitch-track {
        background-color: ${props.trackColor || "black"};
        opacity: ${props.trackOpacity || ".7"};
      }
      & .MuiSwitch-thumb {
        background-color: ${props.thumbColor || "#6449ff"};
      }

      // add custom thumb color when switch is checked and disabled
      &.Mui-checked {
        .MuiSwitch-thumb {
          background-color: ${props.checkedThumbColor || "red"};
        }
        .MuiSwitch-track {
          background-color: ${props.checkedTrackColor ||
          props.trackColor ||
          "red"};
          opacity: ${props.trackOpacity || ".7"};
        }
      }
    }
  `;
});

const SwitchSp = (props) => {
  const { color, ...rest } = props;

  return (
    <StyledSwitch defaultChecked={props.defaultChecked || false} {...rest} />
  );
};

export default SwitchSp;
