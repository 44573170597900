import React, { useCallback, useState } from "react";
import SpInlineEdit from "../globals/SpInlineEdit";
import EndorsmentBadge from "../svgs/endorsementBadge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CircularProgress, Rating } from "@mui/material";
import DevAssessement from "../pages/Resources/Profile/components/DevAssessement";
import AvailabilityTemplate from "../grid_templates/availabilityTemplate";
import EligibilityTemplate from "../grid_templates/eligibilityTemplate";
import StepsComponent from "./StepsComponent";
import SpCircularProg from "../globals/SpCircularProg";
import { getYear, limitToTwoDigits } from "../globals/helpers";
import { useScreenSize } from "../hooks/useScreenSize";
import EndorsmentBadge2 from "../svgs/EndorsementBadge2";
import useFileUploader from "../hooks/useFileUploader";
import WorkIcon from "../svgs/WorkIcon";
import EduIcon from "../svgs/EduIcon";
import ProjIcon from "../svgs/ProjIcon";
import DomainsIcon from "../svgs/DomainsIcon";
import StackIcon from "../svgs/StackIcon";
import DocumentUploader from "../DevDocuments/DocumentUploader";
import ImportResume from "../ImportResume";
import DocumentsViewer from "../DevDocuments/DocumentsViewer";
import {
  EDUCINIT,
  EDUKEYMAP,
  PROJEXINIT,
  PROJKEYMAP,
  WORKEXPINIT,
  WORKKEYMAP,
} from "../globals/constants";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import SpRatingComponent from "../globals/SpRating/SpRating";
import { GetRefFromPath, devRatingType } from "../globals/services";
import LinkedInIcon from "../svgs/LinkedInIcon";
import ProfileIcon from "../svgs/ProfileIcon";
import AssessmentIcon from "../svgs/AssessmentIcon";
import AvailabilityIcon from "../svgs/AvailabilityIcon";
import ConnectivityIcon from "../svgs/ConnectivityIcon";
import EmailIcon from "../svgs/EmailIcon";
import EndorsIcon from "../svgs/EndorsIcon";
import AboutMeIcon from "../svgs/AboutMeIcon";
import RateIcon from "../svgs/RateIcon";

const iconMap = {
  StackIcon: StackIcon,
  DomainsIcon: DomainsIcon,
  ProjIcon: ProjIcon,
  EduIcon: EduIcon,
  WorkIcon: WorkIcon,
  LinkedInIcon: LinkedInIcon,
  ProfileIcon: ProfileIcon,
  AssessmentIcon: AssessmentIcon,
  AvailabilityIcon: AvailabilityIcon,
  ConnectivityIcon: ConnectivityIcon,
  EmailIcon: EmailIcon,
  EndorsIcon: EndorsIcon,
  AboutIcon: AboutMeIcon,
  RateIcon: RateIcon,
};

const handleClick = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });

    // Add the highlight class
    element.classList.add("highlighted");

    // Remove the highlight class after the animation ends
    setTimeout(() => {
      element.classList.remove("highlighted");
    }, 1000); // Duration should match the animation duration in CSS
  }
};

const checkMissingItems = (devData, profileUrl, setDevPage) => {
  const progress = devData.profileProgress || {};
  const missingItems = [];

  const stacksProgress = progress.stacksProgress || 0;
  const categoriesProgress = progress.categoriesProgress || 0;
  const sectorsProgress = progress.sectorsProgress || 0;
  const educationProgress = progress.educationProgress || 0;
  const workExperienceProgress = progress.workExperienceProgress || 0;
  const ratingProgress = progress.ratingProgress || 0;
  const availabilityProgress = progress.availabilityProgress || 0;
  const eligibility = progress.eligibilityProgress || 0;

  const requiredStacks = 8;
  const currentStacks = Math.round(stacksProgress / (0.05 / requiredStacks));
  const missingStacks = requiredStacks - currentStacks;
  if (missingStacks > 0) {
    missingItems.push({
      icon: "StackIcon",
      text: `Add ${missingStacks} tech stacks`,
      onClick: () => {
        setDevPage("techs");
      },
    });
  }

  const requiredCategories = 3;
  const currentCategories = Math.round(
    categoriesProgress / (0.05 / requiredCategories)
  );
  const missingCategories = requiredCategories - currentCategories;
  if (missingCategories > 0) {
    missingItems.push({
      icon: "DomainsIcon",
      text: `Add ${missingCategories} project types`,
      onClick: () => {
        setDevPage("domain");
      },
    });
  }

  const requiredProjects = 3;
  const currentProjects = Math.round(
    sectorsProgress / (0.05 / requiredProjects)
  );
  const missingProjects = requiredProjects - currentProjects;
  if (missingProjects > 0) {
    missingItems.push({
      icon: "ProjIcon",
      text: `Add ${missingProjects} domains experience`,
      onClick: () => {
        setDevPage("project");
      },
    });
  }

  const requiredEducation = 1;
  const currentEducation = Math.round(educationProgress / 0.05);
  const missingEducation = requiredEducation - currentEducation;
  if (missingEducation > 0) {
    missingItems.push({
      icon: "EduIcon",
      text: `Add ${missingEducation} education experience`,
      onClick: () => {
        window.location.hash = "#education";
      },
    });
  }

  const requiredWorkExperience = 1;
  const currentWorkExperience = Math.round(workExperienceProgress / 0.05);
  const missingWorkExperience = requiredWorkExperience - currentWorkExperience;
  if (missingWorkExperience > 0) {
    missingItems.push({
      icon: "WorkIcon",
      text: `Add ${missingWorkExperience} work experience`,
      onClick: () => {
        window.location.hash = "#workExp";
      },
    });
  }
  if (eligibility === 0) {
    missingItems.push({
      icon: "ConnectivityIcon",
      text: "eligibility",
      onClick: () => {
        window.location.hash = "#eligibility";
      },
    });
  }
  if (ratingProgress === 0) {
    missingItems.push({
      icon: "RateIcon",
      text: " hourly rate",
      onClick: () => {
        window.location.hash = "#rate";
      },
    });
  }

  if (
    devData?.aboutMe === "" ||
    devData?.aboutMe === null ||
    devData?.aboutMe === undefined
  ) {
    missingItems.push({
      icon: "AboutIcon",
      text: "about me",
      onClick: () => {
        window.location.hash = "#about";
      },
    });
  }

  if (availabilityProgress === 0) {
    missingItems.push({
      icon: "AvailabilityIcon",
      text: "availability",
      onClick: () => {
        window.location.hash = "#eligibility";
      },
    });
  }

  if (!devData?.email) {
    missingItems.push({
      icon: "EmailIcon",
      text: "email",
      onClick: () => {
        window.location.hash = "#email";
      },
    });
  }

  if (
    !(
      devData?.linkedIn?.profileUrl &&
      (devData.linkedIn.profileUrl.includes("linkedin.com/") ||
        devData.linkedIn.profileUrl.includes("linkedin.com/in/") ||
        devData.linkedIn.profileUrl.includes("linkedin.com/pub/"))
    )
  ) {
    missingItems.push({
      icon: "LinkedInIcon",
      text: "LinkedIn profile Url",
      onClick: () => {
        window.location.hash = "#linkedin";
      },
    });
  }

  if (
    devData?.rating === undefined ||
    devData?.rating === null ||
    devData?.rating === 0
  ) {
    missingItems.push({
      icon: "EndorsIcon",
      text: "endorsements",
      onClick: () => {
        window.location.hash = "#endorsements";
      },
    });
  }

  if (
    !devData?.profileAssessments ||
    devData?.profileAssessments?.length === 0
  ) {
    missingItems.push({
      icon: "AssessmentIcon",
      text: "profile assessments",
      onClick: () => {
        window.location.hash = "#profileAssessments";
      },
    });
  }

  if (!profileUrl) {
    missingItems.push({
      icon: "ProfileIcon",
      text: "profile image",
      onClick: () => {
        window.location.hash = "#profile";
      },
    });
  }

  return missingItems;
};

const RightSection = ({
  devData,
  devPublic,
  vendorPublic,
  vendor,
  devId,
  assementClicked,
  assessementLoading,
  assessments,
  onAnswerProfileQuestions,
  educations,
  workExperiences,
  projectExperiences,
  handleDevDetailUpdate,
  firebase,
  stackExp,
  domainExp,
  projExp,
  allPositions,
  isVendor,
  isDev,
  isSuperPartner,
  profileUrl,
  renderBadge,
  setDevPage,
}) => {
  // console.log("devData", devData);
  // console.log("allPositions", allPositions);
  const windowWidth = useScreenSize();
  let storage = firebase.getStorage();
  const [isViewDocs, setIsViewDocs] = useState(false);
  const [docs, setDocs] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLeadOn, setIsLeadOn] = useState(false);
  const [onOpenEndorsment, setOnOpenEndorsment] = useState(false);

  const renderDate = (start, end) => {
    const startYear = start ? getYear(start) : null;
    const endYear = end ? getYear(end) : null;

    // If we don't have any start year or end year, return undefined
    if (startYear === null && endYear === null) return;

    // If the window width is greater than 980, use the full year
    if (windowWidth?.winWidth > 980) {
      if (startYear && endYear) {
        return `${startYear} - ${endYear}`;
      }
      return startYear ? `${startYear} - Present` : `${endYear}`;
    }

    // If the window width is less or equal to 980, use the last two digits of the year
    const shortEndYear = endYear?.toString().slice(-2);
    if (startYear) {
      return shortEndYear
        ? `${startYear} - ${shortEndYear}`
        : `${startYear} - Present`;
    }
    return shortEndYear;
  };

  const missingItems = checkMissingItems(devData, profileUrl, setDevPage);

  const handleClick = (id) => {
    const uploadbtn = document.querySelector(id);
    uploadbtn.click();
  };

  const handleResumeUpload = useCallback((files) => {
    // console.log("Selected files:", files);
    // Handle the selected files here
  }, []);

  const handleDocsUpload = useCallback((files) => {
    // console.log("Selected files:", files);
    // Handle the selected files here
  }, []);

  const { openFileDialog, fileInputElement } = useFileUploader({
    onFileSelect: handleResumeUpload,
    accept: ".pdf,.doc,.docx,.txt",
    multiple: false, // Allow multiple file selection
  });

  const {
    openFileDialog: openDocsDialog,
    fileInputElement: docsFileInputElement,
  } = useFileUploader({
    onFileSelect: handleDocsUpload,
    accept: ".pdf,.doc,.docx,.txt",
    multiple: true, // Allow multiple file selection
  });

  /**
   * When Users click on the view docs button, it will set the docs state to be the selected dev docs list
   * and open the view docs dialog box
   */
  const onViewDocs = () => {
    setDocs(devData?.docs);
    setIsViewDocs(true);
  };

  /**
   * takes the document to delete index and appends it to the deleted docs state
   * then removes it from the docs state
   * @param {number} i doc to delete index
   */
  const delFile = (i) => {
    setDeletedFiles([...deletedFiles, docs[i]]);
    let rest = docs.filter((item, index) => index !== i);
    setDocs(rest);
  };

  /**
   * functions to delete the removed docs from the database
   * Loops trhough the deleted files states array then removes the from the storage
   */
  const delFilesOnStorage = async () => {
    for (const file of deletedFiles) {
      const fileRef = storage.ref(`devs/${devId}/docs/${file.docName}`);
      fileRef.delete();
    }
  };

  /**
   * function triggered when user removes files and click on save
   * firstly removes the files we are removing from the database then updates the dev docs
   * with the rest of the docs
   */
  const onViewDocsSave = () => {
    setIsSaving(true);
    delFilesOnStorage().then((_) => {
      let updates = {};
      updates["docs"] = docs;
      devData?.ref?.update(updates);
      setDeletedFiles([]);
      setIsViewDocs(false);
      setIsSaving(false);
    });
  };

  const uploadData = async (imageFiles) => {
    const uploadPromises = imageFiles.map((imageFile) => {
      if (imageFile) {
        const fileName = Date.now() + imageFile.name;
        return storage
          .ref(`devs/$${devId}/projectsImages/${fileName}`)
          .put(imageFile)
          .then(async (snap) => {
            return {
              url: await snap.ref.getDownloadURL(),
              name: `${fileName}`,
            };
          });
      } else {
        return null;
      }
    });

    return Promise.all(uploadPromises);
  };

  const onEducationUpdate = (data, ref) => {
    const initials = EDUCINIT;
    // const keyMap = EDUKEYMAP;

    const updates = {};
    for (const key in initials) {
      updates[key] = data[key] || initials[key];
    }

    if (data?.dates?.start) {
      updates["gradDate"] = data?.dates?.start?.toDate() || new Date();
    }

    // console.log(updates);
    try {
      if (ref) {
        return ref.update(updates);
      }
      return devData?.ref?.collection("education").add(updates);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onExpUpdate = async (data, ref, imagesFiles, field) => {
    const initials = field === "workExp" ? WORKEXPINIT : PROJEXINIT;

    // console.log("data", data);
    const updates = {};
    for (const key in initials) {
      updates[key] = data[key] || initials[key];
    }

    if (data?.dates?.start) {
      updates["start"] = data?.dates?.start?.toDate() || new Date();
    }
    if (data?.dates?.end)
      updates["end"] = data?.dates?.end?.toDate() || new Date();

    // console.log("added Item:", updates);

    try {
      if (imagesFiles?.length > 0) {
        const images = [...updates?.images];
        // Upload the images
        const uploadedImages = await uploadData(imagesFiles);
        // Add the uploaded images URLs to updates
        updates["images"] = [...images, uploadedImages];
      }

      if (ref) {
        return ref.update(updates);
      }
      return devData?.ref?.collection(field).add(updates);
    } catch (error) {
      console.log("error", error);
    }
  };

  // console.log("devData", devData);

  return (
    <div className="_spDevPage--right">
      <div className="_spDevPage--right-devInfo">
        <div className="_spDevPage--right-devInfo_left">
          <DocumentUploader
            // refreshDevData={p}
            firebase={firebase}
            dev={devData}
          />

          <ImportResume
            className="uploader"
            isSingle={true}
            firebase={firebase}
            vendorId={vendor?.id}
            dev={devData}
            devId={devId}
            stackExp={stackExp}
            domainExp={domainExp}
            projExp={projExp}
          />

          {isViewDocs && (
            <DocumentsViewer
              isViewDocs={isViewDocs}
              setIsViewDocs={setIsViewDocs}
              dev={devData}
              docs={docs}
              delFile={delFile}
              onSave={onViewDocsSave}
              setDeletedFiles={setDeletedFiles}
              isSaving={isSaving}
            />
          )}
          <div className="_spDevPage--right-devInfo_left_section namepos">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label>Personal Information</label>
              {renderBadge(
                !devData?.first || !devData?.last || !devData?.position
              )}
            </div>
            <SpInlineEdit
              initialValue={devData?.first + " " + devData?.last}
              onSave={(value) => {
                // const value = e?.target?.value;
                // console.log("value", value);

                value = value?.trim();
                const namesSplit = value?.split(" ");
                // remove empty strings
                namesSplit?.filter((name) => name !== "");
                const last = namesSplit?.[namesSplit?.length - 1];
                const first = namesSplit?.slice(0, -1)?.join(" ");

                // console.log("first", first);
                // console.log("last", last);

                if (first && last) {
                  handleDevDetailUpdate("first", first);
                  handleDevDetailUpdate("last", last);
                }
              }}
              showEdit={true}
              showSaveCancelBtn={true}
              // handleChange={(value, initialValue) => {
              //   console.log("value", value);
              //   console.log("initialValue", initialValue);
              //   // last is the last value of the name
              // }}
              fontSize="24px"
              fontWeight="bold"
            />

            <SpInlineEdit
              initialValue={devData?.position || "No Position"}
              onSave={(value) => {
                // console.log("update position", value);

                const pos = allPositions?.find((pos) => pos?.label === value);
                handleDevDetailUpdate("position", pos?.label);
                handleDevDetailUpdate("positionRef", pos?.ref);
              }}
              showEdit={true}
              showSaveCancelBtn={false}
              options={allPositions?.map((pos) => ({
                label: pos?.label,
                value: pos?.label,
              }))}
              fontSize="14px"
              fontWeight="normal"
            />
          </div>
          <div className="_spDevPage--right-devInfo_left_section devContacts">
            {/* <div className="_spDevPage--right-devInfo_left_section_access">
              <label>Access Code</label>
              <SpInlineEdit
                initialValue={devData?.accessCode || "No Code"}
                onSave={(value) => {
                  // console.log("value", value);
                  handleDevDetailUpdate("accessCode", value);
                }}
                showEdit={true}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
              />
            </div> */}
            {/* <div className="_spDevPage--right-devInfo_left_section_internalId">
              <label>Internal Id</label>
              <SpInlineEdit
                initialValue={devData?.id || "No Id"}
                showEdit={false}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
                enableEdit={false}
              />
            </div> */}

            <div className="_spDevPage--right-devInfo_left_section_location">
              <label>Location</label>
              <SpInlineEdit
                initialValue={devData?.locTag || "No Location"}
                onSave={(value) => {
                  // console.log("value", value);
                  handleDevDetailUpdate("locTag", value);
                }}
                showEdit={true}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
              />
            </div>
            <div className="_spDevPage--right-devInfo_left_section_resume docs">
              <label>Documents</label>
              {devData?.docs?.length ? (
                <div className="row el" style={{ gap: "1px" }}>
                  <button
                    className="spbtn spbtn-docView"
                    onClick={() => onViewDocs()}
                  >
                    View Documents
                  </button>
                  <AddCircleIcon
                    onClick={() => handleClick("#doc_uploadder")}
                  />
                </div>
              ) : (
                <div className="docs__info">
                  <span>No Documents</span>
                  <button
                    onClick={() => handleClick("#doc_uploadder")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Upload</span>
                    <AddCircleIcon />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="_spDevPage--right-devInfo_left_section devDocs">
            {!isDev && (
              <div
                className="_spDevPage--right-devInfo_left_section_rate"
                id="rate"
              >
                <div className="_spDevPage--right-devInfo_left_section_rate__top">
                  <label>HOURLYRATE</label>
                  {!devData?.devRate ? (
                    <EndorsmentBadge fill="#888888" />
                  ) : (
                    <EndorsmentBadge2 />
                  )}
                </div>

                <span>${devData?.devRate ?? 0}</span>
              </div>
            )}
            {/* <div className="_spDevPage--right-devInfo_left_section_resume docs">
              <label>Resume</label>
              {devData?.resume || devData?.resumeData?.resumeUrl ? (
                <div className="row el" style={{ gap: "1px" }}>
                  <a
                    href={devData.resume ?? devData?.resumeData?.resumeUrl}
                    target="blank"
                    style={{ fontWeight: 600 }}
                    className="link"
                  >
                    View Resume
                  </a>
                  <Edit onClick={() => handleClick("#resumebtn-input")} />
                </div>
              ) : (
                <div className="docs__info">
                  <span>No Resume</span>
                  <button
                    onClick={() => handleClick("#resumebtn-input")}
                  >
                    <span>Upload</span>
                    <AddCircleIcon />
                  </button>
                </div>
              )}
            </div> */}

            <div
              className="_spDevPage--right-devInfo_left_section_email"
              id="email"
            >
              <label>Email</label>
              <SpInlineEdit
                initialValue={devData?.email || "No Email"}
                onSave={(value) => {
                  handleDevDetailUpdate("email", value);
                }}
                showEdit={true}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
              />
            </div>
            <div
              className="_spDevPage--right-devInfo_left_section_linkdin "
              id="linkedin"
            >
              <label>LinkedIn</label>
              <SpInlineEdit
                initialValue={devData?.linkedIn?.profileUrl || "No LinkedIn"}
                showEdit={true}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
                onSave={(value) => {
                  handleDevDetailUpdate("linkedIn", value);
                }}
              />
            </div>
          </div>
          <div className="_spDevPage--right-devAbout" id="about">
            <div className="_spDevPage--right-devAbout_title">
              <h4>About</h4>
              {devData?.aboutMe === "" || !devData?.aboutMe ? (
                <EndorsmentBadge fill="#888888" />
              ) : (
                <EndorsmentBadge2 />
              )}
            </div>
            <div className="_spDevPage--right-devAbout_content">
              <SpInlineEdit
                initialValue={devData?.aboutMe || "No About"}
                onSave={(value) => {
                  // console.log("value", value);
                  handleDevDetailUpdate("aboutMe", value);
                }}
                showEdit={true}
                showSaveCancelBtn={true}
                fontSize="14px"
                fontWeight="normal"
              />
            </div>
          </div>
          <div className="_spDevPage--right-assess" id="profileAssessments">
            <div
              className="_spDevPage--right-assess_title
        "
            >
              <h4>VIDEO PROFILE</h4>
              {renderBadge(!(devData?.profileAssessments?.length > 0))}
            </div>
            {devData?.profileAssessments?.length > 0 &&
            assessments?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <DevAssessement
                  data={assessments}
                  userRef={devData?.userRef}
                  showScore={true}
                  showRate={true}
                  showDifficulty={true}
                />
              </div>
            ) : (
              <div className="_spDevPage--assessement-empty">
                <h3>YOUR VIDEO PROFILE</h3>
                <div className="_spDevPage--assessement-empty__infos">
                  <div className="_spDevPage--assessement-empty__infos_imgWrapper">
                    <img src="/images/assEmpt.png" alt="assessemt_empty_img" />
                  </div>
                  <div className="_spDevPage--assessement-empty__infos_content">
                    <h5>Help employers get to know you</h5>
                    <p>
                      Everyone hates looking at resumes, but people LOVE seeing
                      you in person. Get more responses by telling us about you
                      via video.
                    </p>
                    {(assementClicked || assessementLoading) && (
                      <CircularProgress />
                    )}
                    {devData?.userRef &&
                      !assementClicked &&
                      !assessementLoading && (
                        <button
                          onClick={onAnswerProfileQuestions}
                          disabled={assementClicked}
                        >
                          Answer Profile Questions
                        </button>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="_spDevPage--right-devInfo_right">
          <div className="_spDevPage--right-completion _spDevPage--borderedCard">
            <h4>Profile Completion</h4>
            <div className="_spDevPage--right-completion_progress">
              <SpCircularProg
                progress={limitToTwoDigits(
                  devData?.profileProgress?.totalProgress * 100
                )}
                progressColor={"#6349FF"}
                textColor={"#6349FF"}
                bgColor={"rgba(99, 73, 255, 0.4)"}
              />
            </div>
            <div className="_spDevPage--right-completion_missing">
              {missingItems.map((item, index) => {
                const match = item.text.match(/\d+/);
                const number = match ? match[0] : "";
                const remainingText = match
                  ? item.text.split(" ").slice(2).join(" ")
                  : item.text;

                return (
                  <div
                    key={index}
                    className="_spDevPage--right-completion_missing__item"
                  >
                    {React.createElement(iconMap[item.icon])}
                    <p
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={item.onClick}
                    >
                      {number ? (
                        <>
                          Add <span>{number}</span> {remainingText}
                        </>
                      ) : (
                        <>Missing {item.text}</>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="_spDevPage--right-endorsment _spDevPage--borderedCard"
            id="endorsements"
          >
            <div className="_spDevPage--right-endorsment_top">
              <h4>Endorsements</h4>
              {isSuperPartner ? (
                <button
                  className="_spDevPage--right-endorsment_top_btn"
                  onClick={() => setOnOpenEndorsment(!onOpenEndorsment)}
                >
                  {renderBadge(devData?.rating === 0)}
                </button>
              ) : (
                renderBadge(devData?.rating === 0)
              )}
            </div>
            <h4 className="_spDevPage--right-endorsment_ratingText">
              {devData?.rating > 0 ? devData?.rating?.toFixed(2) : "No Rating"}
            </h4>
            <div className="_spDevPage--right-endorsment_stars">
              {isSuperPartner && (
                <SpRatingComponent
                  dataInput={{
                    ref: GetRefFromPath(`devs/${devId}`),
                    rating: devData?.rating ?? 0,
                    isAdmin: isSuperPartner,
                    typeRef: devRatingType(),
                  }}
                  showStart={false}
                  onOpen={onOpenEndorsment}
                  onClose={() => setOnOpenEndorsment(false)}
                />
              )}
              <Rating
                // name="half-rating-read"
                // defaultValue={devData?.rating ?? 0}
                precision={0.5}
                max={4}
                value={devData?.rating ?? 0}
                readOnly
                size="large"
                style={{
                  fontSize: "45px",
                  color: "#6349FF",
                }}
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "#6349FF",
                  },

                  "& .MuiRating-iconHover": {
                    color: "#6349FF",
                  },

                  "& .MuiRating-iconEmpty": {
                    color: "#E0E0E0",
                  },

                  // make size of the stars bigger 45px
                  "& .MuiRating-icon": {
                    fontSize: "45px",
                  },
                }}
                // onChange={(e, newValue) => {
                //   // console.log("newValue", newValue);
                //   handleDevDetailUpdate("rating", newValue);
                // }}
              />
            </div>
            {/* <button
              
            >
              Click here to ask for endorsement
            </button> */}
          </div>
        </div>
      </div>

      <div className="_sppdevAvail _spDevPage--right-avail" id="eligibility">
        <AvailabilityTemplate args={devData} renderBadge={renderBadge} />
        <EligibilityTemplate args={devData} renderBadge={renderBadge} />
      </div>

      <StepsComponent
        cls="_spDevProfile-devInfo__right__educations"
        title="Your Education"
        data={educations}
        renderTitle={(edu) => edu?.school}
        renderInfo={(edu) => edu?.degLevel}
        renderDesc={(edu) => edu?.description}
        renderLeft={(edu) => getYear(edu?.gradDate)}
        showBullets={false}
        labels={{
          title: "School Name",
          info: "Degree Level",
          desc: "Description",
          date1: "Graduation Date",

          date2: null,
        }}
        keyMap={EDUKEYMAP}
        type={1}
        renderDate={(edu) => ({ start: edu?.gradDate })}
        onUpdate={onEducationUpdate}
        initialData={EDUCINIT}
        renderBadge={renderBadge}
        id={"education"}
      />

      <StepsComponent
        cls="_spDevProfile-devInfo__right__projExp"
        title="Your Projects Experience"
        data={projectExperiences}
        renderTitle={(proj) => proj?.company}
        renderInfo={(proj) => proj?.location}
        renderDesc={(proj) => proj?.description}
        renderLeft={(proj) =>
          renderDate(proj?.start ?? null, proj?.end ?? null)
        }
        showBullets={true}
        labels={{
          title: "Role",
          info: "Location",
          desc: "Description",
          date1: "Start Date",
          date2: "End Date",
          company: "Company",
          location: "Location",
        }}
        keyMap={PROJKEYMAP}
        showDesc={true}
        useDateRange={true}
        renderDate={(proj) => ({ start: proj?.start, end: proj?.end })}
        onUpdate={(data, ref, imagesFiles) =>
          onExpUpdate(data, ref, imagesFiles, "projectExp")
        }
        showImageUpload={true}
        initialData={PROJEXINIT}
        renderBadge={renderBadge}
        id={"project"}
      />

      <StepsComponent
        cls="_spDevProfile-devInfo__right__workExp"
        title="Your Work Experience"
        id={"workExp"}
        data={workExperiences}
        renderTitle={(exp) => exp?.title}
        renderInfo={(exp) => exp?.company}
        // renderDesc={(exp) => renderStepperBullets(exp?.bullets ?? [])}
        renderLeft={(exp) => renderDate(exp?.start ?? null, exp?.end ?? null)}
        labels={{
          title: "POSITION TITLE",

          date1: "Start Date",
          date2: "End Date",
          company: "Company",
          location: "Location",
          desc: "Description",
          bullets: "Bullets",
        }}
        keyMap={WORKKEYMAP}
        initialData={WORKEXPINIT}
        useDateRange={true}
        showDesc={false}
        onUpdate={(data, ref) => onExpUpdate(data, ref, null, "workExp")}
        renderBadge={renderBadge}
      />
    </div>
  );
};

export default RightSection;
