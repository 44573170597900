import React, { useCallback, useContext, useState } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { AuthUserContext } from "../../Firebase";
import { isValidUrl } from "../../globals/helpers";
import { createVendor, VENDORDOCDATA } from "../../utils/VendorUtils";
import { Box } from "@mui/material";
import { SpDialog } from "../../globals/SpDialog/dialogWrapper/SpDialog";

function AddPartner({ openState, setOpenState }) {
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState({
    companyName: "",
    address: "",
    techFocus: "",
    totalDevs: 0,
    website: "",
    timeToStart: 0,
  });

  const userData = useContext(AuthUserContext);

  const resetInputs = () => {
    setVendor({
      companyName: "",
      address: "",
      techFocus: "",
      totalDevs: 0,
      website: "",
      timeToStart: 0,
    });
    setLoading(false);
  };

  const handleChange = (e) => {
    setVendor((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const checkInputs = () => {
    if (!vendor.companyName) {
      seterrorMessage("Company Name is required");
      setLoading(false);
      return false;
    }
    if (!vendor.address) {
      seterrorMessage("Company Address is required");
      setLoading(false);
      return false;
    }
    if (!vendor.techFocus) {
      seterrorMessage("Company Tech Focus is required");
      setLoading(false);
      return false;
    }
    if (!vendor.website) {
      seterrorMessage("Company Website is required");
      setLoading(false);
      return false;
    }
    if (!isValidUrl(vendor.website)) {
      seterrorMessage("Invalid Domain Name, should be a valid URL");
      setLoading(false);
      return false;
    }
    if (vendor.timeToStart <= 0) {
      seterrorMessage("Company Time to start must be greater than 0");
      setLoading(false);
      return false;
    }
    if (vendor.totalDevs <= 0) {
      seterrorMessage("Company Total Devs must be greater than 0");
      setLoading(false);
      return false;
    }
    seterrorMessage("");
    return true;
  };

  const onCreate = async () => {
    if (!checkInputs()) return;
    setLoading(true);
    const vendorData = {
      ...VENDORDOCDATA,
      ...vendor,
      manager: userData?.user?.ref,
    };
    createVendor(vendorData);
    setOpenState(false);
    resetInputs();
  };

  const onModalClose = useCallback(() => {
    setOpenState(false);
    seterrorMessage("");
    resetInputs();
  }, [setOpenState]);

  return (
    <SpDialog open={openState} onClose={onModalClose} locked>
      <div style={{ display: "block", padding: 10 }}>
        <h1 style={{ marginBottom: 10 }}>Add New Partner </h1>
        <Box component="fieldset" mb={5} borderColor="transparent">
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 50, width: "50%" }}>
              <h5>Domain name: </h5>
              <TextBoxComponent
                type="text"
                style={{ color: "black" }}
                floatLabelType="Auto"
                name="website"
                value={vendor.website}
                onChange={handleChange}
              />
            </div>
            <div style={{ width: "50%" }}>
              <h5>Company name: </h5>
              <TextBoxComponent
                type="text"
                style={{ color: "black" }}
                floatLabelType="Auto"
                name="companyName"
                value={vendor.companyName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 50, width: "50%" }}>
              <h5>Address: </h5>
              <TextBoxComponent
                type="text"
                style={{ color: "black" }}
                floatLabelType="Auto"
                name="address"
                value={vendor.address}
                onChange={handleChange}
              />
            </div>
            <div style={{ width: "50%" }}>
              <h5>Tech Focus: </h5>
              <TextBoxComponent
                type="text"
                style={{ color: "black" }}
                floatLabelType="Auto"
                name="techFocus"
                value={vendor.techFocus}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 50, width: "50%" }}>
              <h5>Total Devs: </h5>
              {/* <TextBoxComponent
                    type="number"
                    style={{ color: "black" }}
                    floatLabelType="Auto"
                    name="totalDevs"
                    value={vendor.totalDevs}
                    onChange={handleChange}
                    minValue={0}
                  /> */}
              <NumericTextBoxComponent
                style={{ color: "black", width: "100%" }}
                floatLabelType="Auto"
                name="totalDevs"
                value={vendor.totalDevs}
                onChange={handleChange}
                min={0}
                step={1}
                format="n0"
              />
            </div>
            <div style={{ width: "50%" }}>
              <h5>timeToStart: </h5>
              {/* <TextBoxComponent type="number" /> */}
              <NumericTextBoxComponent
                min={0}
                step={1}
                style={{ color: "black", width: "100%" }}
                floatLabelType="Auto"
                name="timeToStart"
                value={vendor.timeToStart}
                onChange={handleChange}
                format="n0"
              />
            </div>
          </div>
          <p style={{ color: "#FF4C4C", marginTop: "1rem" }}>{errorMessage}</p>
          <button
            className="e-control e-btn"
            style={{
              background: loading ? "#AAAAAA" : "#1E96F5",
              color: loading ? "#868686" : "white",
              marginLeft: "auto",
              width: 150,
              height: 30,
            }}
            disabled={loading}
            onClick={onCreate}
          >
            Add Partner
          </button>
        </Box>
      </div>
    </SpDialog>
  );
}

export default AddPartner;
