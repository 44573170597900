import { useEffect, useState } from "react";

export const useScreenSize = () => {
  const [windowDimenion, detectHW] = useState({
    winWidth: 1030,
    winHeight: 1120,
  });

  useEffect(() => {
    detectHW({ winWidth: window.innerWidth, winHeight: window.innerHeight });
  }, []);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return windowDimenion;
};
