import { HighlightOff, InsertDriveFile } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
const FilePreview = (props) => {
  return (
    <div className="request">
      <div className="request_form column" id="data-request_form">
        <h2>Confirm Selected files And Upload</h2>
        <div className="docUpload_docs">
          {props.files.map((file, i) => {
            return (
              <div key={i} className="docUpload_docs-doc">
                <div className="docUpload_docs-doc__icons">
                  <InsertDriveFile className="docUpload_docs-doc__icons-file" />
                  <HighlightOff
                    className="docUpload_docs-doc__icons-del"
                    onClick={() => props.delFile(i)}
                  />
                </div>
                <p className="docUpload_docs-doc__name">{file.name}</p>
              </div>
            );
          })}
        </div>
        <div className="request_form-action row">
          {props.isSaving ? (
            <CircularProgress color="primary" size="small" />
          ) : (
            <button
              className="spbtn spbtn-sub"
              onClick={() => {
                props.onSave();
              }}
            >
              Save
            </button>
          )}

          <button
            className="spbtn spbtn-canc"
            onClick={() => {
              props.setIsFileSelected(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
