import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./Firebase";
import ContractSection from "./pages/Contract/Sections/ContractSection";
import SecuritySection from "./pages/Contract/Sections/SecuritySection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { GlobalVendorContex, AuthUserContext } from "./Firebase";

// REMINDER: the Firebase class from ./Firebase/firebase.js is passed to
// this file with props using the context api

const Contract = (props) => {
  const [vendorId, setVendorId] = useState("");
  const vendorData = useContext(GlobalVendorContex);
  // User data
  const allData = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const vendor = await vendorData?.vendor;
      setVendorId(vendor.id);
      setIsLoading(false);
    };
    getData();
    // props.firebase.reportPageVisits("Contract Page", allData.isSuperPartner, {
    //   "Vendor Id": vendorData.vendor.id,
    //   "Vendor Name": vendorData.vendor.companyName,
    // });
  }, [vendorData.vendor]);

  return (
    <div>
      <div className="resource-header cont">
        <div className="header-intro">
          <h3>Your Documents</h3>
          <div className="desc-icon">
            <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
            <div className="description">
              Manage your contracts and valuable documents here
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div>
          {vendorId ? (
            <div className="contract contain">
              <ContractSection firebase={props.firebase} vendorId={vendorId} />
              <SecuritySection firebase={props.firebase} vendorId={vendorId} />

              {/* <Badges firebase={props.firebase} vendorId={vendorId} vendorName={vendorName}/> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default function FirebaseResources() {
  const firebase = useContext(FirebaseContext);
  return <Contract firebase={firebase} />;
}
