import React, { Children } from "react";
import {
  TechsTableWrapper,
  TechTableBody,
  TechTableBodyRow,
  TechTableHeader,
} from "./style";

const SpTechsTable = ({ children }) => {
  return (
    <TechsTableWrapper>
      <TechTableHeader>
        <h4>Tools</h4>
        <h4>Exp</h4>
        <h4>Projects</h4>
        <h4>Skills</h4>
        <h4>Score</h4>
      </TechTableHeader>
      <>{children}</>
    </TechsTableWrapper>
  );
};

export default SpTechsTable;
