import React from "react";

const ConnectivityIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.69364 0.408856C5.09159 0.958527 2.85 2.51224 1.30682 4.14296C0.588182 4.90217 0 5.61453 0 5.72555C0 5.83658 0.391818 6.46743 0.870455 7.12749L1.74114 8.32759L3.15568 6.994C5.33909 4.93554 7.32227 4.09066 9.97 4.09066C12.6755 4.09066 14.6691 4.92239 16.8016 6.94073L18.2623 8.3231L19.1311 7.12524C19.6091 6.46647 20 5.84653 20 5.7477C20 5.64886 19.4355 4.94549 18.7455 4.18468C17.265 2.55203 14.6898 0.818944 13.0002 0.318047C11.4477 -0.142419 8.16273 -0.0949291 6.69364 0.408856ZM6.74227 7.36494C5.63045 7.90499 4.57523 8.70527 3.29909 9.97596C3.16477 10.1098 3.47727 10.7904 4.05273 11.6182L5.03568 13.0324L6.6325 11.8756C8.06318 10.8391 8.41341 10.7188 10 10.7188C11.5866 10.7188 11.9368 10.8391 13.3675 11.8756L14.9643 13.0324L15.937 11.633C17.0336 10.0552 17.0589 10.1512 15.1136 8.50247C12.9045 6.63044 9.27136 6.1366 6.74227 7.36494ZM8.79955 13.4604C7.79864 13.8012 6.59091 14.6313 6.59091 14.9785C6.59091 15.1107 7.35795 16.2948 8.29545 17.6094L10 20L11.7045 17.6094C12.642 16.2948 13.4091 15.0988 13.4091 14.9522C13.4091 14.5527 11.6043 13.5252 10.5682 13.3346C10.0682 13.2429 9.27227 13.2993 8.79955 13.4604Z"
        fill={fill}
      />
    </svg>
  );
};

export default ConnectivityIcon;
