import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ReqTableHead } from "../../globals/ReqTable/style";
import styled from "styled-components";
import { GRIDCOLUMN } from "./constants";

const HeadItemWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }
`;

export const HeadItem = ({
  children,
  desc = true,
  sortingKey,
  label,
  ...props
}) => {
  return (
    <HeadItemWrapper
      {...props}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div className="head-item">
        <div className="head-item-label">
          <span>{label}</span>
        </div>
        {sortingKey && (
          <div className="head-item-icon">
            <>{desc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</>
          </div>
        )}
      </div>
    </HeadItemWrapper>
  );
};

const RequirementHead = ({
  sortingKey,
  setSortingKey,
  sortingDesc,
  setSortingDesc,
  headContent = [],
  requestSort,
}) => {
  return (
    <ReqTableHead gridColumn={GRIDCOLUMN}>
      <HeadItem label="Match" onClick={() => requestSort("vendorMatch")} />
      <HeadItem
        label="DeadLine"
        onClick={() => requestSort("deadLine", true)}
      />

      <HeadItem
        label="Requirements"
        onClick={() => requestSort("reqs", false, "", true)}
      />

      <HeadItem
        label="Team Location"
        onClick={() => requestSort("deadLine", true)}
      />

      <HeadItem label="Type" onClick={() => requestSort("deadLine", true)} />
      <HeadItem label="Status" onClick={() => requestSort("priority")} />

      <HeadItem label="Priority" onClick={() => requestSort("priority")} />

      <HeadItem
        label="Created On"
        onClick={() => requestSort("openOn", true)}
      />
    </ReqTableHead>
  );
};

export default RequirementHead;
