import styled from "styled-components";

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction};
  // flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 327px) {
    flex-direction: column;
    align-items: center;
    button {
      flex: 100%;
      width: 100% !important;
    }
  }
  button {
    flex: 50%;
    min-width: 98px;
  }
  button:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #4361e9;
      font-weight: 700;
      font-size: 16px;
    }
  }
`;
