import React, { useEffect, useState } from "react";
// import {
//   updateDcoumentWithPath,
//   getDocumentsByArrayMembershipInCollection,
// } from "../../firebase/store";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { AddCircleRounded, RefreshOutlined } from "@mui/icons-material";
// import SpResponsiveImg from "../SpResponsiveImg";
import SpDialog from "../../../globals/SpDialog";
import NotifyUsers from "./notifyUsers";

import Uncollapse2Icon from "../../../svgs/Uncollapse2";
import SpOption from "../../../globals/SpOptions/SpOption";
import { SpOptionItem } from "../../../globals/SpOptions/style";
import { AnimatePresence, motion } from "framer-motion";
import {
  GetRefFromPath,
  getCollectionRefFromPath,
  getDocumentsByArrayMembershipInCollection,
  getDocumentsWhere,
  updateDcoumentWithPath,
} from "../../../globals/services";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";
import SpBtn from "../../../globals/SpBtn";

export default function ManageVendors({
  tucItem,
  isAdmin,
  loggedInUserData,
  setSelectedPartner,
  emailTemplates,
  setShowMore,
  showMore = true,
  setSelectedTabIndex,
}) {
  const [partnersData, setPartnersData] = useState({});
  const [localRecommendedVendors, setLocalRecommendedVendors] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [partnersToNotify, setPartnersToNotify] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isOpenToAll, setIsOpenToAll] = useState(false);

  useEffect(() => {
    const fetchVendorsData = async () => {
      var tempPartnersData = {};
      await Promise.all(
        tucItem?.partners.map(async (partnerRef) => {
          if (!tempPartnersData[partnerRef.id]) {
            var tempPartner = await partnerRef.get();
            tempPartnersData[partnerRef?.id] = {
              ...tempPartner?.data(),
              ref: partnerRef,
            };
          }
        })
      );
      setPartnersData(tempPartnersData);
    };
    if (tucItem?.partners != []) fetchVendorsData();
  }, [tucItem?.partners]);

  useEffect(() => {
    const fetchRecommendedVendors = async () => {
      const allPartnersFound = await getDocumentsWhere(
        "matches",
        true,
        [
          ["source", "==", GetRefFromPath(tucItem?.path)],
          [
            "type",
            "==",
            GetRefFromPath(
              "organisations/T6BAcTjwbXleCFpmWTmu/matchesTypes/vendorRec"
            ),
          ],
        ],
        true,
        "createdOn",
        true,
        false,
        2500,
        true
      );
      var tempPartnersData = {};
      await Promise.all(
        allPartnersFound?.map(async (partnerMatch) => {
          if (
            !Object.keys(tempPartnersData).includes(
              partnerMatch?.data()?.ref?.id
            )
          ) {
            const partnerData = (
              await partnerMatch?.data()?.ref?.get()
            )?.data();
            tempPartnersData[partnerMatch?.data()?.ref?.id] = {
              ...partnerData,
              ref: partnerMatch?.data()?.ref,
              mScore: 0,
            };
          }
        })
      );
      await Promise.all(
        Object.values(tempPartnersData).map(async (partnerData) => {
          const filteredMatches = allPartnersFound.filter(
            (foundPartner) =>
              foundPartner?.data()?.ref?.path === partnerData?.ref?.path
          );
          let maxMScore = 0;
          filteredMatches?.map((matchData) => {
            maxMScore = Math.max(maxMScore, matchData?.data()?.mScore ?? 0);
          });
          tempPartnersData[partnerData?.ref?.id]["mScore"] =
            Math.ceil(maxMScore);

          const devsFound = await getDocumentsWhere(
            "matches",
            true,
            [
              ["vendorRef", "==", partnerData?.ref],
              ["source", "==", GetRefFromPath(tucItem?.path)],
            ],
            false,
            null,
            false,
            false,
            null,
            true
          );
          let uniqueDevs = [];
          devsFound?.map((devDoc) => {
            if (!uniqueDevs?.includes(devDoc?.data()?.ref?.path))
              uniqueDevs.push(devDoc?.data()?.ref?.path);
          });
          tempPartnersData[partnerData?.ref?.id]["devsMatched"] =
            uniqueDevs?.length;
          const VendorDevs = await getCollectionRefFromPath("devs")
            .where("vendorRef", "==", partnerData?.ref)
            .where("status", "==", true)
            .get();
          tempPartnersData[partnerData?.ref?.id]["vendorAllActiveDevs"] =
            VendorDevs?.docs?.length;
        })
      );
      setLocalRecommendedVendors({ ...tempPartnersData });
    };
    if (tucItem?.path && tucItem?.matching?.computeMatch === false)
      fetchRecommendedVendors();
  }, [tucItem?.path, tucItem?.matching?.computeMatch]);

  async function fetchAndSetAllVendors(e) {
    setLoading(true);
    if (e.target.value) {
      const data = await getDocumentsByArrayMembershipInCollection(
        "vendors",
        e.target.value.toLowerCase(),
        ["nameIndex"],
        "companyName",
        false,
        true,
        false,
        5
      );
      setSearchResults(data);
    } else {
      setSearchResults([]);
    }
    setLoading(false);
  }

  const addPartner = async (partnerRef) => {
    var tempPartners = [...(tucItem?.partners ?? [])];
    tempPartners.push(partnerRef);
    await updateDcoumentWithPath(tucItem?.path, {
      partners: tempPartners,
    });
  };
  const variant = {
    initial: {
      rotate: 180,
    },
    animate: {
      rotate: 0,
    },
  };

  const removePartner = async (partnerRef) => {
    setShowOptions(false);
    let tempPartners = [...(tucItem?.partners ?? [])];
    tempPartners = tempPartners.filter(
      (partner) => partner.id !== partnerRef.id
    );
    await updateDcoumentWithPath(tucItem?.path, {
      partners: tempPartners,
    });
  };

  const notifyPartners = (partner) => {
    setShowOptions(false);
    setPartnersToNotify([partner]);
    setShowNotify(true);
  };

  const selectPartnerForTab = (partner) => {
    setSelectedTabIndex(3);
    setSelectedPartner(partner);
  };

  return (
    // <div className="_spreqPartnerManagement">
    <div>
      {tucItem?.open && showMore && (
        <div
          className="_spreqPartnerManagement--openOverLaye"
          // onClick={() => {
          //   setShowMore(false);
          // }}
        >
          <div className="_spreqPartnerManagement--openOverLaye--content">
            <p>Open to all partners</p>
            <button
              onClick={() => {
                updateDcoumentWithPath(tucItem?.path, {
                  open: false,
                });
              }}
            >
              Close down
            </button>
          </div>
        </div>
      )}

      <SpDialog
        open={showNotify}
        locked={true}
        // onClose={() => {
        //   setShowNotify(false);
        // }}
        showCloseIcon={false}
        cls="_spreqPartnerManagement-dialog"
        useCustomBox
      >
        <NotifyUsers
          onClose={() => {
            setShowNotify(false);
          }}
          emailTemplates={emailTemplates}
          partnersToNotify={partnersToNotify}
          loggedInUserData={loggedInUserData}
        />
      </SpDialog>

      <div>
        <Divider
          sx={{
            width: "100%",
            height: "1px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        {tucItem?.name && (
          <div className="_spreqPartnerManagement__header">
            <div className="_spreqPartnerManagement__header__title">
              <h2>{tucItem?.name}</h2>
              <br></br>
            </div>
          </div>
        )}
        {/* <div className="_spreqPartnerManagement__header">
          <div className="_spreqPartnerManagement__header__title">
            <h2>Partner Access</h2>
            {showMore && (
              <p>{`${Object.values(partnersData)?.length} partners added`}</p>
            )}
          </div>
          <div className="_spreqPartnerManagement__header__actions">
            <motion.div
              variants={variant}
              initial={"initial"}
              animate={showMore ? "animate" : "initial"}
              transition={{ duration: 0.2 }}
              onClick={() => setShowMore(!showMore)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Uncollapse2Icon />
            </motion.div>
          </div>
        </div> */}
        <AnimatePresence>
          {showMore && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="_spreqPartnerManagement__body"
            >
              <Autocomplete
                id="company-search-box"
                classes={{
                  root: "_spreqPartnerManagement__body__search",
                  inputRoot: "_spreqPartnerManagement__body__search__input",
                  input: "_spreqPartnerManagement__body__search__input__input",
                }}
                options={searchResults}
                getOptionLabel={(option) =>
                  option?.data()?.companyName ?? "companyName"
                }
                loading={loading}
                renderOption={
                  isAdmin
                    ? (props, option) => (
                        <Box component="li" {...props}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>{option?.data()?.companyName ?? ""}</div>
                            {!partnersData[option?.ref?.id] && (
                              <IconButton
                                aria-label="add"
                                size="small"
                                onClick={async () => {
                                  if (
                                    !tucItem?.partners?.includes(option.ref)
                                  ) {
                                    await addPartner(option.ref);
                                  }
                                }}
                              >
                                <AddCircleRounded
                                  style={{
                                    color: "#4361E9",
                                    transform: "matrix(1, 0, 0, -1, 0, 0)",
                                  }}
                                  fontSize="inherit"
                                />
                              </IconButton>
                            )}
                          </div>
                        </Box>
                      )
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      fetchAndSetAllVendors(e);
                    }}
                    // label="Vendor Search"
                    placeholder="Search and add vendors"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <div className="_spreqPartnerManagement__body__partners">
                {Object.values(partnersData).map((partner) => {
                  return (
                    <div className="_spreqPartnerManagement__body__partners--partner sp_row">
                      <div
                        className="_spreqPartnerManagement__body__partners--partner_info sp_row"
                        onClick={() => {
                          selectPartnerForTab(partner);
                        }}
                      >
                        <SpResponsiveImg
                          src={partner.logo?.url ?? ""}
                          alt={partner.logo?.name ?? ""}
                          width={"25px"}
                          height={"25px"}
                          className="_spreqPartnerManagement__body__partners--partner_info__logo"
                          sx={{
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                        />
                        <p>{partner?.companyName ?? "partnerName"}</p>
                      </div>
                      <SpOption
                        iconSize={20}
                        cls="_spreqPartnerManagement__body__partners--partner__options"
                        right="100%"
                        iconColor="#C7C8D0"
                        open={showOptions}
                        onOptionClick={(option) => {
                          setShowOptions(true);
                        }}
                      >
                        <SpOptionItem
                          onClick={() => removePartner(partner?.ref)}
                        >
                          Remove
                        </SpOptionItem>
                        <SpOptionItem
                          onClick={() => {
                            notifyPartners(partner);
                          }}
                        >
                          Notify
                        </SpOptionItem>
                      </SpOption>
                    </div>
                  );
                })}
                <div className="_spreqPartnerManagement__body__partners--more sp_row">
                  <p
                    onClick={async () => {
                      await updateDcoumentWithPath(tucItem?.path, {
                        open: !tucItem?.open,
                      });

                      setIsOpenToAll(tucItem?.open);
                    }}
                  >
                    Open to all partners
                  </p>
                  <p
                    onClick={() => {
                      setPartnersToNotify(Object.values(partnersData));
                      setShowNotify(true);
                    }}
                  >
                    Notify All
                  </p>
                </div>
              </div>
              {localRecommendedVendors ? (
                <div className="_spreqPartnerManagement__body--recommend sp_col">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="_spreqPartnerManagement__body--recommend__header">
                      <h2>Recommended</h2>
                      <p>{`${
                        Object.values(localRecommendedVendors)?.length
                      } partners seem like a good fit`}</p>
                      <h5>
                        last triggered on:{" "}
                        {tucItem?.matching?.lastMatchedOn?.toDate()
                          ? tucItem?.matching?.lastMatchedOn
                              ?.toDate()
                              ?.toLocaleDateString("en-US")
                          : "N/A"}
                      </h5>
                    </div>
                    <RefreshOutlined
                      style={{
                        cursor: "pointer",
                        marginTop: "5px",
                        marginRight: "15px",
                      }}
                      onClick={async () => {
                        await updateDcoumentWithPath(tucItem?.path, {
                          "matching.computeMatch": true,
                        });
                      }}
                    />
                  </div>

                  {Object.values(localRecommendedVendors)?.length > 0 && (
                    <div className="_spreqPartnerManagement__body--recommend__list sp_col">
                      {Object.values(localRecommendedVendors)
                        ?.sort((a, b) => b.mScore - a.mScore)
                        .map((partner) => {
                          return (
                            <div className="_spreqPartnerManagement__body--recommend__list--partner sp_row">
                              <div>
                                <div className="_spreqPartnerManagement__body--recommend__list--partner_info sp_row">
                                  <SpResponsiveImg
                                    src={partner.logo?.url ?? ""}
                                    alt={partner.logo?.name ?? ""}
                                    width={"25px"}
                                    height={"25px"}
                                    sx={{
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                    }}
                                  />
                                  <p>{partner?.companyName ?? "partnerName"}</p>
                                </div>
                                {partner?.mScore && (
                                  <p>{"(" + partner?.mScore + "% match)"}</p>
                                )}
                                <p>{`${partner?.devsMatched ?? "-"}/${
                                  partner?.vendorAllActiveDevs ?? "-"
                                }/${partner?.totalDevsLive ?? "-"}/${
                                  partner?.totalDevs ?? "-"
                                }`}</p>
                              </div>
                              {tucItem?.partners?.filter(
                                (a) => a?.path === partner.ref?.path
                              )?.length === 0 && (
                                <SpBtn
                                  bg={"black"}
                                  onClick={async () => {
                                    var tempPartners = tucItem?.partners ?? [];
                                    tempPartners.push(partner.ref);
                                    await updateDcoumentWithPath(
                                      tucItem?.path,
                                      {
                                        partners: tempPartners,
                                      }
                                    );
                                  }}
                                >
                                  add
                                </SpBtn>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
