import React, { useState } from "react";
import styles from "./styles/requirementsSteps.module.css";
import { MdStar, MdStarBorder } from "react-icons/md";
import PosTeamComponent from "./posTeamComponent";

const RequirementTeamListCard = ({
  selectedUserRole,
  selectedPartner,
  setSelectedTuc,
  setShowDevProfile,
  setPosRecommendations,
  showActions,
  info,
  tucItem,
  staffing,
  userAccData,
  logAction,
  logAuthors,
  userType,
  paidStatus,
  devPositions,
  costFeeSum,
  priceFeeSum,
  fixedCostFeeSum,
  fixedPriceFeeSum,
  optionParms,
  setSelectedTabIndex,
  partnerAccountData,
  orgPosSpecs,
}) => {
  function buildRatings(rating) {
    const ratings = [];
    for (let i = 0; i < Math.floor(rating); i++) {
      ratings.push(<MdStar className={styles.star} />);
    }
    for (let i = 0; i < 4 - Math.floor(rating); i++) {
      ratings.push(<MdStarBorder className={styles.star} />);
    }
    return ratings;
  }

  const [showPosOptions, setShowPosOptions] = useState(false);

  return (
    <div className="sp_column" style={{ gap: "2rem" }}>
      {tucItem?.positions?.map((position, index) => {
        return (
          <PosTeamComponent
            setSelectedTuc={setSelectedTuc}
            setShowDevProfile={setShowDevProfile}
            setPosRecommendations={setPosRecommendations}
            showActions={showActions}
            index={index}
            staffing={staffing}
            tucItem={tucItem}
            buildRatings={buildRatings}
            listTags={info?.listTags}
            logAction={logAction}
            logAuthors={logAuthors}
            userType={userType}
            paidStatus={paidStatus}
            devPositions={devPositions}
            costFeeSum={costFeeSum}
            priceFeeSum={priceFeeSum}
            fixedCostFeeSum={fixedCostFeeSum}
            fixedPriceFeeSum={fixedPriceFeeSum}
            optionParms={optionParms}
            selectedUserRole={selectedUserRole}
            selectedPartner={selectedPartner}
            setSelectedTabIndex={setSelectedTabIndex}
            partnerAccountData={partnerAccountData}
            orgPosSpecs={orgPosSpecs}
          />
        );
      })}
    </div>
  );
};

export default RequirementTeamListCard;
