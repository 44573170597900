import styled from "styled-components";

export const PartnerWrapper = styled.div`
  background-color: ${({ bg }) => bg};
  padding: ${({ pd }) => pd};
  h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
export const PartnerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ space }) => space};
  align-items: center;
`;
