import { motion } from "framer-motion";
import styled from "styled-components";

export const OptionWrapper = styled.div`
  position: relative;
`;

export const OptionIconWrapper = styled.div`
  // width: 30px;
  cursor: pointer;
`;

export const OptionElWrapper = styled(motion.div)`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bg }) => bg};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: 5;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  //   justify-content: center;
  min-width: 200px;
  min-height: 100px;
`;
export const SpOptionItem = styled.p`
  padding: 10px;
  font-size: 14px;
  color: ${({ color }) => color};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-width: fit-content;
  &:hover {
    background-color: ${({ hoverBg }) => hoverBg ?? "rgba(67,97,233,0.1)"};
  }
`;
