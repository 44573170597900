const bulTemplate = (props) => {
  return (
    <div>
      {Array.isArray(props?.[props?.column?.field]) ? (
        <ul>
          {props?.[props?.column?.field].map((bul, i) => {
            return <li key={i}>{bul}</li>;
          })}
        </ul>
      ) : (
        <div>{props?.[props?.column?.field]}</div>
      )}
    </div>
  );
};
export default bulTemplate;
