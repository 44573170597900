import React from "react";

const RateStar = ({ size = "25", fill = "#FF2559", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.693 1L21.349 11.092L32.386 12.4015L24.226 19.9465L26.392 30.8485L16.693 25.42L6.994 30.85L9.16 19.948L1 12.4L12.0385 11.0905L16.693 1Z"
        fill={fill}
        stroke={stroke}
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RateStar;
