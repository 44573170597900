import React, { useState, useEffect, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import styles from "./pages/SingleRequirement/styles/requirementsSteps.module.css";
import editPopupStyles from "./pages/SingleRequirement/styles/editPopUp.module.css";
import {
  db,
  fetchClientLists,
  fireAuth,
  getDocumentsWhere,
  getDocumentFromPath,
  getDocumentsByArrayMembershipInCollection,
  transferClientRequirement,
  logUserAction,
  updateDcoumentWithPath,
  GetRefFromPath,
  shareRequirementViaMailURL,
  getCurrentUser,
  deleteClientRequirement,
} from "./globals/services";
import { GlobalVendorContex } from "./Firebase";
import { MdClose, MdEdit, MdWarning } from "react-icons/md";
import RequirementsSteps from "./pages/SingleRequirement/requirementsSteps";

export default function SingleListRequirement(props) {
  const { listId } = useParams();
  const contextVendorData = useContext(GlobalVendorContex);
  const [partnerAccountData, setPartnerAccountData] = useState({});
  const [listData, setListData] = useState();
  const [termsAndConditions, setTermsAndConditions] = useState();
  const [listName, setListName] = useState(listData?.name ?? "");
  const [tempListName, setTempListName] = useState("");
  const [editName, setEditName] = useState(false);
  const [tempDesc, setTempDesc] = useState("");
  const [editDesc, setEditDesc] = useState(false);
  const [viewTerms, setViewTerms] = useState(false);
  const [userAccountData, setUserAccountData] = useState({});
  const [userType, setUserType] = useState("");
  const [editTags, setEditTags] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tempTagName, setTempTagName] = useState("");
  const [tempTagDesc, setTempTagDesc] = useState("");
  const [createNewTag, setCreateNewTag] = useState(false);
  const [selectedTagIndex, setSelectedTagIndex] = useState();
  const [cancelPauseAlertBox, setCancelPauseAlertBox] = useState(false);
  const [cancelPauseData, setCancelPauseData] = useState({
    action: "",
    msg: "",
    id: "",
  });
  const [tempTagColor, setTempTagColor] = useState("");
  const [listDocData, setListDocData] = useState({});
  const [showEmailingInput, setShowEmailingInput] = useState(false);
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [mailIds, setMailIds] = useState();
  const [fetchedClients, setFetchedClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState([]);
  const [searchedClient, setSearchedClient] = useState("");
  const [clientSelected, setClientSelected] = useState(false);

  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartnerAdmin, setIsPartnerAdmin] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [userRolesAvailable, setUserRolesAvailable] = useState([]);

  const adminUserRolePaths = [
    "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/SUPER-ADMIN",
    "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/SALES-ADMIN",
    "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/ADMIN",
  ];

  const requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");

  useEffect(() => {
    return fireAuth.onAuthStateChanged((user) => {
      if (user?.uid) setUserLoggedIn(true);
    });
  }, []);
  useEffect(() => {
    const getInitialData = async () => {
      var _loggedInUserData = {};
      var userDetails = await getCurrentUser();
      _loggedInUserData["user"] = userDetails;
      _loggedInUserData["accounts"] = {};
      var userAcc =
        (await getDocumentFromPath(
          `users/${fireAuth?.currentUser?.uid}/accounts/spryte-dev`
        )) ?? {};
      _loggedInUserData["accounts"]["spryteDev"] = userAcc;
      setUserAccountData(userAcc);
      var tempIsAdmin = adminUserRolePaths.includes(
        userAcc?.["role"]?.path ?? ""
      );
      setIsAdmin(tempIsAdmin);
      setUserAccountData(userAcc);
      if (tempIsAdmin) _loggedInUserData["accounts"]["admin"] = {};

      var partnerAcc = await GetRefFromPath(
        `users/${fireAuth?.currentUser?.uid}/accounts/spryte-partner`
      ).get();
      if (partnerAcc?.exists)
        setPartnerAccountData({
          ...partnerAcc?.data(),
          vendorRef: GetRefFromPath(
            `vendors/${contextVendorData?.searchedVendor?.id}`
          ),
        });

      _loggedInUserData["accounts"]["sprytePartner"] = partnerAcc;

      setLoggedInUserData(_loggedInUserData);
    };
    if (fireAuth?.currentUser?.uid) getInitialData();
  }, [fireAuth?.currentUser?.uid]);

  useEffect(() => {
    logAction("Page Viewed", "VIEW");
    // var tempTermsAndConditions = await getDocumentsWhere('organisations/T6BAcTjwbXleCFpmWTmu/terms', true, [['platform', '==', 'CLIENT']], true, 'version', true, true, 1, false);
    // var tempListData = await fetchClientLists(router.query.cId, router.query.lId);
    var tempTermsAndConditions = [];
    var tempListData = [];
    const getData = async () => {
      tempTermsAndConditions = await getDocumentsWhere(
        "organisations/T6BAcTjwbXleCFpmWTmu/terms",
        true,
        [["platform", "==", "CLIENT"]],
        true,
        "version",
        true,
        true,
        1,
        false
      );
      tempListData = await fetchClientLists(listId);
      setListName(tempListData[0]?.name ?? "");
      setTempListName(tempListData[0]?.name ?? "");
      setEditDesc(tempListData[0]?.description ?? "");
      setTempDesc(tempListData[0]?.description ?? "");
      setTermsAndConditions(tempTermsAndConditions[0]?.data());
      setListData(tempListData[0]);
    };
    if (fireAuth.currentUser?.uid)
      db.collectionGroup("lists")
        .where("id", "==", listId)
        .onSnapshot((listDocSnap) => {
          if (listDocSnap.exists) setListDocData(listDocSnap?.data() ?? {});
        });
    if (fireAuth.currentUser?.uid) getData();
  }, [fireAuth.currentUser]);

  useEffect(() => {
    var userAccountDataTemp = {};
    const getUserAccountData = async () => {
      userAccountDataTemp = await getDocumentFromPath(
        `users/${fireAuth.currentUser?.uid}/accounts/spryte-dev`
      );
      setUserAccountData(userAccountDataTemp);
      var tempUserType = adminUserRolePaths.includes(
        userAccountDataTemp?.["role"]?.path ?? ""
      )
        ? "ADMIN"
        : "CLIENT";
      // ['ADMIN', 'SUPER-ADMIN', 'SALES-ADMIN'].includes(userAccountDataTemp?.["role"]?.id ?? '') ? 'ADMIN' : 'CLIENT';
      setUserType(tempUserType);
    };
    if (fireAuth.currentUser?.uid) {
      setUserLoggedIn(true);
      getUserAccountData();
    } else {
      setUserLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const data = await getDocumentsByArrayMembershipInCollection(
        "client",
        searchedClient.toLowerCase(),
        ["nameIndex"],
        "companyName",
        false,
        true,
        false,
        5
      );
      setFetchedClients(data);
    };
    if (!clientSelected) fetchClients();
  }, [searchedClient]);

  useEffect(() => {
    if (Object.keys(partnerAccountData).length)
      setIsPartnerAdmin(
        [
          "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/SUPER-ADMIN",
          "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/PARTNER-ADMIN",
          // "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/ADMIN",
        ].includes(partnerAccountData?.role?.path ?? "")
      );
  }, [partnerAccountData?.role?.path]);

  useEffect(() => {
    if (contextVendorData?.searchedVendor && partnerAccountData)
      setPartnerAccountData({
        ...partnerAccountData,
        vendorRef: GetRefFromPath(
          `vendors/${contextVendorData?.searchedVendor?.id}`
        ),
      });
  }, [contextVendorData?.searchedVendor?.id]);

  useEffect(() => {
    const FetchRolesAndSet = () => {
      // if(loggedInUserData?.['accounts']?.['sprytePartner']!={}){
      //   if(!listDocData?.partners.includes(loggedInUserData?.['accounts']?.['sprytePartner']?.ref)){
      //   }
      // }
      var tempRoles = [];
      if (isAdmin || isPartnerAdmin) tempRoles.push("admin");
      if (loggedInUserData?.["accounts"]?.["sprytePartner"] != {})
        // if (
        //   listData?.partners?.find(
        //     (ref) =>
        //       loggedInUserData?.["accounts"]?.["sprytePartner"]?.vendorRef
        //         ?.id == ref?.id
        //   ) ||
        //   listData?.open
        // )
        tempRoles.push("partner");
      if (loggedInUserData?.["accounts"]?.["spryteDev"] != {})
        if (
          loggedInUserData?.["accounts"]?.["spryteDev"]?.["clientId"]?.id ===
          listData?.clientId
        )
          tempRoles.push("client");

      if (!selectedUserRole) {
        if (isAdmin || isPartnerAdmin) {
          setSelectedUserRole("admin");
        } else if (loggedInUserData?.["accounts"]?.["sprytePartner"] != {}) {
          // if (
          //   listData?.partners?.find(
          //     (ref) =>
          //       loggedInUserData?.["accounts"]?.["sprytePartner"]?.data()?.vendorRef
          //         ?.id == ref?.id
          //   ) ||
          //   listData?.open
          // )
          setSelectedUserRole("partner");
        } else if (loggedInUserData?.["accounts"]?.["spryteDev"] != {}) {
          if (
            loggedInUserData?.["accounts"]?.["spryteDev"]?.["clientId"]?.id ===
            listData?.clientId
          )
            setSelectedUserRole("client");
        }
      }
      setUserRolesAvailable(tempRoles);
    };
    FetchRolesAndSet();
  }, [loggedInUserData, isPartnerAdmin, isAdmin, listData]);

  async function transferRequirement() {
    var newReqDocRef = transferClientRequirement(
      listData.clientId,
      selectedClientId,
      listData,
      searchedClient
    );
    // router.push("/requirements");
    // open new link in new tab
    window.open(`/requirements/`);
    // router.push({pathname:'/requirementTeam', query: {lId: newReqDocRef['newReqRef']?.parent?.parent?.id, cId: newReqDocRef['newReqRef']?.id}});
  }

  async function logAction(message, actionType) {
    // LOG: Logging user action.
    try {
      await logUserAction(
        `client/${listData.clientId}/lists/${listData.id}/log`,
        message,
        userType,
        actionType
      );
    } catch (error) {
      return null;
    }
  }
  function resetTempTagValues() {
    setTempTagName("");
    setTempTagDesc("");
    setTempTagColor("");
  }

  const onReqOptionClick = (action) => {
    switch (action) {
      case "Show":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Show",
          id: "SHOW",
          msg: "made VISIBLE",
        });
        break;
      case "Hide":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Hide",
          id: "HIDE",
          msg: "HIDDEN",
        });
        break;
      case "Resume":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Resume",
          id: "IN-PROGRESS",
          msg: "RESUME",
        });
        break;
      case "Pause":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Pause",
          id: "PAUSED",
          msg: "PAUSED",
        });
        break;
      case "Cancel":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Cancel",
          id: "CANCELLED",
          msg: "CANCELLED",
        });
        break;
      case "Delete":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Delete",
          id: "DELETE",
          msg: "DELETE",
        });
        break;
      case "Abandon":
        setCancelPauseAlertBox(true);
        setCancelPauseData({
          action: "Abandon",
          id: "ABANDONED",
          msg: "ABANDONED",
        });
        break;
      case "Transfer":
        setShowClientSelection(true);
        break;
      case "Email":
        setShowEmailingInput(true);

        break;
      case "Tag":
        setEditTags(true);
        logAction("Button Clicked", "CLICK");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {listData ? (
        <div className="_spReqTeam">
          <div className="requirement-ctr">
            <RequirementsSteps
              info={listData}
              setViewTerms={setViewTerms}
              termsAndConditions={termsAndConditions}
              userAccountData={userAccountData}
              partnerAccountData={partnerAccountData}
              logAction={logAction}
              userType={userType}
              loggedInUserData={loggedInUserData}
              isAdmin={isAdmin}
              userRolesAvailable={userRolesAvailable}
              selectedUserRole={selectedUserRole}
              setSelectedUserRole={setSelectedUserRole}
              onReqOptionClick={onReqOptionClick}
            />
          </div>
          {viewTerms ? (
            <div className="termsPopup">
              <div className="termsPopupContent">
                <MdClose
                  onClick={() => {
                    setViewTerms(false);
                    logAction("Button Clicked", "CLICK");
                  }}
                  className="close"
                  size={25}
                />
                <h4>AGREEMENT</h4>
                <h2>Terms of Service</h2>
                <div className="termsText">
                  {termsAndConditions.data.map((condition, index) => {
                    return (
                      <div>
                        <p>{`${index + 1}. ${condition.title}`}</p>
                        <p>{condition.description}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="btnCtr">
                  <button
                    onClick={() => {
                      setViewTerms(false);
                      logAction("Button Clicked", "CLICK");
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {showConfirm ? (
            <div className={styles.confirmationCtr}>
              <div className={styles.confirmationCtrContent}>
                <div style={{ height: "50px" }} className={styles.top}>
                  <h2 style={{ color: "#2d3436" }}>Create a new label.</h2>
                </div>
                <div className={styles.bottom}>
                  <h4 id="tag-label">Tag Label</h4>
                  <div className="inputCtr">
                    <input
                      value={tempTagName}
                      onChange={(e) => {
                        setTempTagName(e.target.value);
                      }}
                    ></input>
                  </div>
                  <h4 id="tag-label">Tag Description</h4>
                  <div className="inputCtr">
                    <input
                      value={tempTagDesc}
                      onChange={(e) => {
                        setTempTagDesc(e.target.value);
                      }}
                    ></input>
                  </div>
                  <h4 id="tag-label">Tag Color</h4>
                  <div className="inputCtr">
                    <input
                      value={tempTagColor}
                      onChange={(e) => {
                        setTempTagColor(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className={styles.btnCtr}>
                    <button
                      id={styles.yes}
                      onClick={async () => {
                        setShowConfirm(false);
                        logAction("Button Clicked", "CLICK");
                        var tempTagsList = [...listDocData?.listTags];
                        if (createNewTag) {
                          tempTagsList.push({
                            label: tempTagName,
                            desc: tempTagDesc,
                            color: tempTagColor,
                          });
                          logAction(
                            `New Tag ${tempTagName} is Created.`,
                            "ACTION"
                          );
                        } else {
                          logAction(
                            `${tempTagName} has been updated.`,
                            "ACTION"
                          );
                          tempTagsList[selectedTagIndex]["label"] = tempTagName;
                          tempTagsList[selectedTagIndex]["desc"] = tempTagDesc;
                          tempTagsList[selectedTagIndex]["color"] =
                            tempTagColor;
                        }
                        updateDcoumentWithPath(`${listData?.ref?.path}`, {
                          listTags: tempTagsList,
                        });
                        resetTempTagValues();
                        setEditTags(true);
                      }}
                    >
                      {createNewTag ? "CREATE" : "UPDATE"}
                    </button>
                    {!createNewTag ? (
                      <button
                        id={styles.delete}
                        onClick={async () => {
                          updateDcoumentWithPath(`${listData?.ref?.path}`, {
                            listTags: [
                              ...listDocData?.listTags?.filter(
                                (tag, i) => i != selectedTagIndex
                              ),
                            ],
                          });
                          setShowConfirm(false);
                          setEditTags(true);
                          resetTempTagValues();
                          logAction("Button Clicked", "CLICK");
                        }}
                      >
                        DELETE
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      id={styles.no}
                      onClick={() => {
                        setShowConfirm(false);
                        resetTempTagValues();
                        setEditTags(true);
                        logAction("Button Clicked", "CLICK");
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {cancelPauseAlertBox ? (
            <div className={styles.confirmationCtr}>
              <div className={styles.confirmationCtrContent}>
                <div className={styles.top}>
                  <MdWarning className={styles.warning} />
                  <h2>{cancelPauseData.action} your requirement</h2>
                </div>
                <div className={styles.bottom}>
                  <h4>
                    Are you sure you want to {cancelPauseData.action} your
                    requirement now?
                  </h4>
                  <div className={styles.btnCtr}>
                    <button
                      id={styles.yes}
                      onClick={async () => {
                        if (["SHOW", "HIDE"].includes(cancelPauseData.id)) {
                          setCancelPauseAlertBox(false);
                          logAction("Button Clicked", "CLICK");
                          updateDcoumentWithPath(`${listData?.ref?.path}`, {
                            show: !listData?.show,
                          });
                          logAction(
                            `List had been ${cancelPauseData.msg}`,
                            "ACTION"
                          );
                        } else if (cancelPauseData.id != "DELETE") {
                          setCancelPauseAlertBox(false);
                          logAction("Button Clicked", "CLICK");
                          updateDcoumentWithPath(`${listData?.ref?.path}`, {
                            status: GetRefFromPath(
                              `${listData.status.parent.path}/${cancelPauseData.id}`
                            ),
                          });
                          logAction(
                            `List had been ${cancelPauseData.msg}`,
                            "ACTION"
                          );
                        } else {
                          setCancelPauseAlertBox(false);
                          deleteClientRequirement(listData.clientId, listData);
                          window.open("/requirements");
                        }
                      }}
                    >
                      {cancelPauseData.action} Requirement
                    </button>
                    <button
                      id={styles.no}
                      onClick={() => {
                        setCancelPauseAlertBox(false);
                        logAction("Button Clicked", "CLICK");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {editTags ? (
            <div className={editPopupStyles.editPopup}>
              <div className={editPopupStyles.editPopupContent}>
                <div className={editPopupStyles.top}>
                  <button
                    onClick={() => {
                      setCreateNewTag(true);
                      setEditTags(false);
                      setShowConfirm(true);
                    }}
                  >
                    Create New Label
                  </button>
                  <MdClose
                    style={{ opacity: ".45", cursor: "pointer" }}
                    onClick={() => {
                      setEditTags(false);
                      resetTempTagValues();
                      logAction("Button Clicked", "CLICK");
                    }}
                    size={20}
                  />
                </div>
                <div
                  className={editPopupStyles.bottom}
                  style={{ padding: "10px" }}
                >
                  {listDocData ? (
                    listDocData?.listTags?.map((tag, tagIndex) => {
                      return (
                        <div className="chipDiv">
                          <div className="fixed-width">
                            <div
                              style={{
                                color: tag.color,
                                backgroundColor: `${tag.color}17`,
                                border: `1px solid ${tag.color}`,
                                padding: "1.5px 10px",
                                borderRadius: "2.5px",
                                width: "fit-content",
                                fontSize: "12px",
                                letterSpacing: "0",
                              }}
                              className="chip"
                            >
                              {tag.label}
                            </div>
                          </div>
                          <p>{tag?.desc}</p>
                          <MdEdit
                            style={{
                              marginLeft: "8.5px",
                              opacity: ".35",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              logAction("Button Clicked", "CLICK");
                              setCreateNewTag(false);
                              setEditTags(false);
                              setShowConfirm(true);
                              setSelectedTagIndex(tagIndex);
                              setTempTagName(tag?.label ?? "");
                              setTempTagDesc(tag?.desc ?? "");
                              setTempTagColor(tag?.color ?? "");
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="bottom">
                  <div className="chipDiv">
                    <div className="fixed-width">
                      <div style={{color: '#EFE85E', backgroundColor: '#efe85e21', border: '1px solid #EFE85E', padding: '1.5px 10px', borderRadius: '2.5px', width: 'fit-content', fontSize: '12px', letterSpacing: '0'}}className="chip">Potential Candidate</div>
                    </div>
                    <p>Some tag description.</p>
                  </div>
                  <div className="chipDiv">
                    <div className="fixed-width">
                      <div style={{color: '#f76646', backgroundColor: '#432a3338', border: '1px solid #E06B51', padding: '1.5px 10px', borderRadius: '2.5px', width: 'fit-content', fontSize: '12px', letterSpacing: '0'}}className="chip">Failed Interview</div>
                    </div>
                    <p>Some tag description.</p>
                  </div>
                  <div className="chipDiv">
                    <div className="fixed-width">
                      <div style={{color: '#2EABFF', backgroundColor: '#2eabff1e', border: '1px solid #2EABFF', padding: '1.5px 10px', borderRadius: '2.5px', width: 'fit-content', fontSize: '12px', letterSpacing: '0'}}className="chip">Internal Resource</div>
                    </div>
                    <p>Some tag description.</p>
                  </div>
                </div> */}
              </div>
            </div>
          ) : null}
          {showEmailingInput ? (
            <div className={editPopupStyles.editPopup}>
              <div className={`${editPopupStyles.editPopupContent}`}>
                <div className={`${editPopupStyles.mail}`}>
                  <div className={editPopupStyles.top}>
                    <MdClose
                      style={{ opacity: ".45", cursor: "pointer" }}
                      onClick={() => {
                        setMailIds("");
                        setShowEmailingInput(false);
                        logAction("Button Clicked", "CLICK");
                      }}
                      size={20}
                    />
                  </div>

                  <div className={`${editPopupStyles.bottom}`}>
                    <div className={`${editPopupStyles.mail}`}>
                      <h4>Send a copy of this requirement</h4>
                      <p>
                        Enter a recipient's email below in order for them to
                        receive a copy of this requirement. If there are any
                        questions, don't hesitate to contact us.
                      </p>
                      <div className="fixed-width">
                        <input
                          type="email"
                          placeholder="email@website.com"
                          value={mailIds}
                          onChange={(event) => setMailIds(event.target.value)}
                        />
                      </div>
                      <button
                        className={editPopupStyles.darkBtn}
                        onClick={() => {
                          setShowEmailingInput(false);
                          const payload = {
                            listPath: `client/${listData.clientId}/lists/${listData.id}`,
                            addresses: mailIds?.split(",") ?? [],
                          };

                          // The request's options.
                          const requestOptions = {
                            method: "POST",
                            body: JSON.stringify(payload),
                            headers: requestHeaders,
                          };

                          // Attempt to call getMatches.
                          try {
                            // Call the getMatches function.
                            fetch(
                              shareRequirementViaMailURL,
                              requestOptions
                            ).catch((error) => console.log(error));

                            // If an error occurred, do nothing.
                          } catch (error) {}
                        }}
                      >
                        EMAIL THIS REQUIREMENT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {showClientSelection ? (
            <div className={editPopupStyles.editPopup}>
              <div className={`${editPopupStyles.editPopupContent}`}>
                <div className={`${editPopupStyles.mail}`}>
                  <div className={editPopupStyles.top}>
                    <div>
                      <h4>Transfer this requirement</h4>
                    </div>
                    <MdClose
                      style={{ opacity: ".45", cursor: "pointer" }}
                      onClick={() => {
                        setClientSelected(false);
                        setSearchedClient("");
                        setFetchedClients([]);
                        setShowClientSelection(false);
                        logAction("Button Clicked", "CLICK");
                      }}
                      size={20}
                    />
                  </div>
                  <div className={`${editPopupStyles.bottom}`}>
                    <div className={`${editPopupStyles.mail}`}>
                      <p style={{ color: "red" }}>
                        * Once transferred you cannot undo this action. This
                        requirement will be moved to the selected client's
                        account.
                      </p>
                      <div className="fixed-width">
                        <input
                          style={{ marginBottom: "5px" }}
                          type="text"
                          placeholder="Client Company Name"
                          value={searchedClient}
                          onChange={(e) => {
                            setClientSelected(false);
                            setSearchedClient(e.target.value);
                          }}
                        />
                        {fetchedClients.length > 0 ? (
                          fetchedClients.map((client) => {
                            return (
                              <p
                                style={{ color: "black" }}
                                onClick={() => {
                                  setClientSelected(true);
                                  setSelectedClientId(client.id);
                                  client?.data()?.companyName &&
                                    setSearchedClient(
                                      client?.data()?.companyName
                                    );
                                  setFetchedClients([]);
                                }}
                              >
                                {client?.data()?.companyName ??
                                  client?.data()?.name + " - name"}
                              </p>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        {fetchedClients.length == 0 &&
                        searchedClient != "" &&
                        !clientSelected ? (
                          <p style={{ color: "red" }}>
                            Search for a valid client
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      {clientSelected != "" && (
                        <button
                          className={editPopupStyles.darkBtn}
                          onClick={() => {
                            setClientSelected(false);
                            setSearchedClient("");
                            setFetchedClients([]);
                            setShowClientSelection(false);
                            transferRequirement();
                          }}
                        >
                          TRANSFER NOW
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <p>loading data...</p>
      )}
    </div>
  );
}
