import React from "react";

const EduIcon = ({ w = "17", h = "16", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.38498 1.87814C0.88557 3.39245 0.0507996 3.81361 0.00486379 4.08835C-0.0455755 4.39008 0.428014 4.63201 4.28176 6.27311C6.6652 7.28807 8.79842 8.11506 9.02216 8.11107C9.24589 8.10709 11.3744 7.26185 13.7523 6.23294C17.3086 4.69411 18.0859 4.30039 18.1338 4.01353C18.1657 3.82174 18.1252 3.67976 18.0438 3.69816C17.9622 3.71641 15.9304 2.89018 13.5286 1.86204C11.1267 0.833903 9.05963 -0.00397207 8.93497 1.41648e-05C8.81031 0.00400039 6.76283 0.849234 4.38498 1.87814ZM0 4.1322C0 4.34301 0.0408919 4.42933 0.0907908 4.32385C0.14087 4.21852 0.14087 4.04604 0.0907908 3.94056C0.0408919 3.83523 0 3.92139 0 4.1322ZM16.6796 6.0597L16.1842 6.28844V8.56443C16.1842 10.4007 16.1319 10.8775 15.914 11.0314C15.486 11.3338 15.6002 12.1117 16.2044 13.0083C16.8172 13.9179 16.7815 13.9265 17.5424 12.6847C18.0531 11.8514 18.091 11.5153 17.7211 11.0991C17.5143 10.8665 17.4554 10.2575 17.4509 8.31008C17.4477 6.93989 17.3843 5.82144 17.3101 5.82482C17.2358 5.82819 16.9521 5.93382 16.6796 6.0597ZM3.57435 8.32939C3.57435 9.91131 3.9222 10.4369 5.39341 11.0771C7.47547 11.9834 10.4817 11.9834 12.5637 11.0771C14.0349 10.4369 14.3828 9.91131 14.3828 8.32939V7.05257L11.8394 8.12211C10.4406 8.71039 9.1533 9.19165 8.97856 9.19165C8.80383 9.19165 7.51654 8.71039 6.11775 8.12211L3.57435 7.05257V8.32939Z"
        fill={fill}
      />
    </svg>
  );
};

export default EduIcon;
