import styled from "styled-components";

export const RequirementTable = styled.div`
  width: 100%;
`;

export const ReqTableHead = styled.div`
  background-color: ${({ bgColor }) => bgColor ?? "black"}};
  color: ${({ color }) => color ?? "white"}};
  height: ${({ height }) => height ?? "auto"}};
  max-height: ${({ maxHeight }) => maxHeight ?? "auto"}};
  display: grid;
  gap: ${({ gap }) => gap ?? "5px"}};
  padding: ${({ padding }) => padding ?? "1rem 2rem"}};
  grid-template-columns: ${({ gridColumn }) => gridColumn};
`;

export const ReqTableRow = styled.div`
  display: grid;
    gap: ${({ gap }) => gap ?? "5px"}};
  grid-template-columns: ${({ gridColumn }) => gridColumn};
`;

export const ReqTableBody = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
`;
