import { useEffect, useState, useRef } from "react";

import CustomReq from "./customReq";
import ConfirmationPopup from "./confirmationPopup";
import SingleOption from "./reqTeam/stepOneComponents/SingleOption";
import { deleteRequirementTuc } from "../../globals/services";

function RequirementPriceCard({
  showActions,
  info,
  data,
  currentStep,
  index,
  clientId,
  listId,
  paidStatus,
  path,
  listData,
  userType,
  devPositions,
  selectedUserRole,
}) {
  const [edit, setEdit] = useState(false);
  const [tempName, setTempName] = useState(data?.name ?? "");
  const [tempDescription, setTempDescription] = useState(
    data?.description ?? ""
  );
  const [viewDescription, setViewDescription] = useState(false);
  const [showEditRequirements, setShowEditRequirements] = useState(false);
  const [showComfirmationPopup, setShowComfirmationPopup] = useState(false);
  const [localSkillsObject, setLocalSkillsObject] = useState([]);
  const [pricePerDev, setPricePerDev] = useState(
    data?.originalTeamCardData?.hourlyPrice ?? ""
  );
  const [priceError, setPriceError] = useState("");
  const [updatedSkillsObject, setUpdatedSkillsObject] = useState();
  const requirements = useRef();
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    if (data) {
      // setTempName(data?.name ?? "")
      // setTempDescription(data?.description ?? "");
      setLocalSkillsObject(
        Object.values(data?.originalTeamCardData?.skillsObject ?? {})
      );
    }
  }, [data]);

  useEffect(() => {
    const getPositions = async () => {
      if (!data?.positions?.length) {
        setPositions([]);
        return;
      }
      // const positionRefs =
      const posis = await Promise.all(
        data?.positions?.map((position) => position.title.get())
      );
      const allPositions = posis?.map((pos) => {
        return { ...pos.data(), ref: pos.ref };
      });
      setPositions(allPositions);
    };
    getPositions();
  }, [data?.positions]);

  useEffect(() => {
    var tempUpdatedSkillsObject = {};
    localSkillsObject.forEach((e) => {
      tempUpdatedSkillsObject[e.id] = e;
    });
    setUpdatedSkillsObject(tempUpdatedSkillsObject);
  }, [localSkillsObject]);

  const onOptionEdit = () => {
    setShowEditRequirements(true);
    setLocalSkillsObject(
      Object.values(data?.originalTeamCardData?.skillsObject ?? {})
    );
    setPricePerDev(data?.originalTeamCardData?.hourlyPrice ?? "");
  };
  const onOptionDelete = () => {
    setShowComfirmationPopup(true);
  };

  return (
    <>
      {showComfirmationPopup && (
        <ConfirmationPopup
          info={{
            listPath: `client/${info.clientId}/lists/${info.id}`,
            userType: userType,
            title: "Team option",
            action: "Delete",
            confirmButton: async () => {
              await deleteRequirementTuc({
                ...info?.tuc?.[index],
                path: data?.path ?? "",
              });
              setShowComfirmationPopup(false);
            },
            cancelButton: () => setShowComfirmationPopup(false),
          }}
        />
      )}
      <SingleOption
        userType={userType}
        optionData={data}
        onOptionEdit={onOptionEdit}
        onOptionDelete={onOptionDelete}
        positions={positions}
        selectedUserRole={selectedUserRole}
      />
      {showEditRequirements ? (
        <CustomReq
          setShowCustomReq={setShowEditRequirements}
          path={path}
          listData={listData}
          userType={userType}
          devPositions={devPositions}
          isEdit={true}
          existingData={data}
        />
      ) : null}
    </>
  );
}

export default RequirementPriceCard;
