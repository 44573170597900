import React, { useEffect, useState } from "react";

const PartnerInfo = (props) => {
  const [companyManager, setCompanyManager] = useState("N/A");
  useEffect(() => {
    const fetchManager = async () => {
      const docSnap = await props?.selectedVendor?.manager?.get();
      let manager = docSnap?.data();
      let managerName = manager
        ? (manager?.first ?? "") + " " + (manager?.last ?? "")
        : "N/A";
      setCompanyManager(managerName);
    };

    if (props?.selectedVendor !== null) fetchManager();
  }, [props?.selectedVendor]);
  return (
    <div
      className={`partnManagement_partnerInfo ${
        props.selectedVendor ? "partnerInfo-show" : "partnerInfo-hide"
      }  `}
    >
      {props.selectedVendor ? (
        <div className="partnManagement_partnerInfo-details">
          <div>
            <h4>Company Name</h4>
            <p>{props?.selectedVendor?.name}</p>
          </div>
          <div>
            <h4>Company Address</h4>
            <p>{props?.selectedVendor?.address}</p>
          </div>
          <div>
            <h4>Company Id</h4>
            <p>{props?.selectedVendor?.id}</p>
          </div>
          <div>
            <h4>Last Login</h4>
            <p>{props?.selectedVendor?.lastLogin}</p>
          </div>
          <div>
            <h4>Case Studies</h4>
            <div>
              <p>
                {" "}
                DRAFT: {props.selectedVendor?.caseStudyCount?.["DRAFT"] ?? 0}
              </p>
              <p>
                FEATURED:{" "}
                {props.selectedVendor?.caseStudyCount?.["FEATURED"] ?? 0}
              </p>
              <p>
                READY-TO-SUBMIT:
                {props.selectedVendor?.caseStudyCount?.["READY-TO-SUBMIT"] ?? 0}
              </p>
              <p>
                REVIEWED:{" "}
                {props.selectedVendor?.caseStudyCount?.["REVIEWED"] ?? 0}
              </p>
              <p>
                UPLOADED:{" "}
                {props.selectedVendor?.caseStudyCount?.["UPLOADED"] ?? 0}
              </p>
            </div>
          </div>
          <div>
            <h4>Manager</h4>
            <p>{companyManager}</p>
          </div>
          <div>
            <div>
              <h4>Markets</h4>
              <div>
                <p>
                  {" "}
                  Active: {props.selectedVendor?.marketCount?.["active"] ?? 0}
                </p>
                <p>
                  Inactive:{" "}
                  {props.selectedVendor?.marketCount?.["inactive"] ?? 0}
                </p>
              </div>
            </div>
            <div>
              <h4>Price Cards</h4>
              <div>
                <p>
                  {" "}
                  Active:{" "}
                  {props.selectedVendor?.priceCardCount?.["active"] ?? 0}
                </p>
                <p>
                  Inactive:{" "}
                  {props.selectedVendor?.priceCardCount?.["inactive"] ?? 0}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h4>Added On</h4>
            <p>{props.selectedVendor?.addedOn?.toDateString()}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PartnerInfo;
