import React from "react";
import SpLabelInfo from "../SpLabelInfo";

function FormInput({
  label = "",
  desc = "",
  isInput = true,
  value = "",
  onChange,
  type = "text",
  max = null,
  min = null,
  step = 0.01,
  stl = null,
  readOnly = false,
}) {
  return (
    <div style={stl} className="formInput sp-form_item">
      {desc ? (
        <SpLabelInfo
          title={label}
          description={desc}
          addClass={readOnly ? "readOnlyStl" : ""}
        />
      ) : (
        <label id="label">{label}</label>
      )}
      {isInput ? (
        <>
          {type === "number" ? (
            <input
              type="number"
              min={min}
              max={max}
              step={step}
              value={value}
              onChange={onChange}
              id="expboxinput"
              readOnly={readOnly}
            />
          ) : (
            <input
              type="text"
              value={value}
              onChange={onChange}
              id="expboxinput"
              readOnly={readOnly}
            />
          )}
        </>
      ) : (
        <textarea
          type="text"
          value={value}
          onChange={onChange}
          id="expboxinput"
        />
      )}
    </div>
  );
}

export default FormInput;
