import React, { use, useEffect, useMemo, useReducer, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import SpBtn from "../../../../globals/SpBtn";
import { convertDateToString } from "../../../../globals/helpers";
import DateRanger from "./DateRanger";
import SpOption from "../../../../globals/SpOptions/SpOption";
import { SpOptionItem } from "../../../../globals/SpOptions/style";
import { MdExpandMore } from "react-icons/md";
// import { analytics } from "../../../../Firebase/firebase";
import {
  currentTime,
  db,
  fireAuth,
  updateDcoumentWithPath,
} from "../../../../globals/services";

const FIrstStep = ({
  listData,
  setViewTerms,
  logAction,
  userType,
  onReqOptionClick,
  setShowListOptions,
  selectedUserRole,
  userRolesAvailable,
  expand,
  setExpand,
  setSelectedUserRole,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState({
    name: listData?.name || "",
    description: listData?.description || "",
    startDate: listData?.requirements?.start?.toDate() || Date.now(),
    endDate: listData?.requirements?.end?.toDate() || Date.now(),
    staffing: listData?.requirements?.staffing || false,
    minMonths: listData?.requirements?.minMonths || 1,
    termsStatus: listData?.requirements?.termsStatus || false,
  });
  const [error, setError] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const wasAgreed = useMemo(() => {
    return listData?.requirements?.termsStatus;
  }, [listData?.ref?.id]);

  useEffect(() => {
    setIsEditing(false);
    setEditingData({
      name: listData?.name || "",
      description: listData?.description || "",
      startDate: listData?.requirements?.start?.toDate() || Date.now(),
      endDate: listData?.requirements?.end?.toDate() || Date.now(),
      staffing: listData?.requirements?.staffing || false,
      minMonths: listData?.requirements?.minMonths || 1,
      termsStatus: listData?.requirements?.termsStatus || false,
    });
  }, [listData?.ref?.id]);

  const staffingList = [
    { label: "Staffing", value: "Staffing" },
    { label: "Managed Project", value: "Managed Project" },
  ];

  function appendChatbot() {
    window.HubSpotConversations.widget.open();
    // analytics.track("Chat Intent", {});
  }

  const onMonthChange = (e) => {
    if (error) setError("");
    setEditingData({ ...editingData, minMonths: parseInt(e.target.value) });
  };

  const onStaffingChange = (e) => {
    if (error) setError("");
    setEditingData({
      ...editingData,
      staffing: e.target.value === "Staffing" ? true : false,
    });
  };

  const onDatesChange = (data) => {
    if (error) setError("");
    setEditingData({
      ...editingData,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };

  const onTermsChange = (e) => {
    if (error) setError("");
    setEditingData({ ...editingData, termsStatus: e.target.checked });
  };

  const openNewWindow = (url) => {
    window.open(url, "_blank");
  };

  const checkError = () => {
    if (editingData.name === "") {
      setError("Please enter a name for your requirement");
      return true;
    }
    if (editingData.description === "") {
      setError("Please enter a description for your team");
      return true;
    }
    if (editingData.minMonths === 0) {
      setError("Please enter a minimum number of months");
      return true;
    }
    if (editingData.startDate === null) {
      setError("Please enter a start date");
      return true;
    }
    if (editingData.endDate === null) {
      setError("Please enter an end date");
      return true;
    }
    if (!editingData?.termsStatus) {
      setError("Please accept the terms and conditions");
      return true;
    }
    return false;
  };

  const onUpdate = async () => {
    if (checkError()) return;
    logAction("Button Clicked", "CLICK");
    const reqUpdate = {
      name: editingData.name,
      description: editingData.description,
      requirements: {
        ...(listData?.requirements ?? {}),
        start: editingData.startDate,
        end: editingData.endDate,
        staffing: editingData.staffing,
        minMonths: editingData.minMonths,
      },
    };
    if (!wasAgreed && editingData.termsStatus) {
      const userDocRef = db.collection("users").doc(fireAuth?.currentUser?.uid);
      reqUpdate["requirements"]["termsStatus"] = editingData.termsStatus;
      reqUpdate["requirements"]["agreedOn"] = currentTime();
      reqUpdate["requirements"]["agreedBy"] = userDocRef;
      reqUpdate["currentStep"] = 1;
    }

    logAction(`Updated requirement`, "CLICK");
    setIsEditing(false);
    await updateDcoumentWithPath(listData?.ref?.path, reqUpdate);
    if (!wasAgreed && editingData.termsStatus) {
      setShowListOptions(true);
    }
  };

  const onReqOptionClicked = (action) => {
    onReqOptionClick(action);
    setShowOptions(false);
  };

  return (
    <section className="_spReqTeam--firstStep">
      <div className="_spReqTeam--firstStep_firstInfos">
        <div className="_spReqTeam--firstStep_firstInfos__left">
          <div
            className="_spReqTeam--firstStep_firstInfos__left-title"
            style={{ flex: "1" }}
          >
            <div className="reqStepCount">
              <p>1</p>
            </div>
            <h3 className="reqStepTitle">
              {
                listData["steps"][0]["meta"][
                  selectedUserRole === "partner" ? "partner" : "client"
                ]["name"]
              }
            </h3>
          </div>
          <div
            className="_spReqTeam--firstStep_firstInfos__left-reqInfo"
            style={{ flex: "3" }}
          >
            {!isEditing && listData?.currentStep > 0 ? (
              <h4>{editingData?.name}</h4>
            ) : (
              <input
                type="text"
                placeholder="Weave-JUnit-Timy"
                className="_reqTitleEditor"
                value={editingData?.name}
                onChange={(e) => {
                  if (error) setError("");
                  setEditingData({ ...editingData, name: e.target.value });
                }}
              />
            )}
            <span
              style={{
                minWidth: "fit-content",
              }}
            >
              Created on {convertDateToString(listData?.createdOn?.toDate())}
            </span>
          </div>
        </div>
        <div className="_spReqTeam--firstStep_firstInfos__right">
          {userRolesAvailable.length > 1 && !isEditing && (
            <div
              className="_spReqTeam--OptionThird__info--right"
              style={{ position: "relative" }}
            >
              <div className="_spReqTeam--OptionThird__info--right-options _spOptionSelector">
                <div
                  className="sp_hover"
                  onClick={() => setExpand((prev) => !prev)}
                >
                  <h4>view as {selectedUserRole ?? ""}</h4>
                  <MdExpandMore size={30} fill={"#4361e8"} />
                </div>
                {expand && (
                  <div className="_spReqTeam--OptionThird__info--right-options--list _spOptionSelector--list">
                    {userRolesAvailable.map((role) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedUserRole(role);
                            setExpand(false);
                          }}
                          className="sp_hover"
                        >
                          <p>{role}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedUserRole !== "partner" && (
            <SpOption
              right={"100%"}
              open={showOptions}
              onOptionClick={(isOpen) => setShowOptions(isOpen)}
            >
              {userType == "ADMIN" && selectedUserRole === "admin" && (
                <SpOptionItem
                  onClick={() =>
                    onReqOptionClicked(listData?.show ? "Hide" : "Show")
                  }
                >
                  {listData?.show ? "Hide" : "Show"} Requirement
                </SpOptionItem>
              )}
              {listData?.status?.id == "PAUSED" ? (
                <SpOptionItem onClick={() => onReqOptionClicked("Resume")}>
                  Resume Requirement
                </SpOptionItem>
              ) : (
                <SpOptionItem onClick={() => onReqOptionClicked("Pause")}>
                  Pause Requirement
                </SpOptionItem>
              )}
              <SpOptionItem onClick={() => onReqOptionClicked("Delete")}>
                Delete Requirement
              </SpOptionItem>
              <SpOptionItem onClick={() => onReqOptionClicked("Cancel")}>
                Cancel Requirement
              </SpOptionItem>
              {userType === "ADMIN" && (
                <SpOptionItem onClick={() => onReqOptionClicked("Abandon")}>
                  Abandon Requirement
                </SpOptionItem>
              )}
              {userType == "ADMIN" && (
                <SpOptionItem onClick={() => onReqOptionClicked("Transfer")}>
                  Transfer Requirement
                </SpOptionItem>
              )}

              <SpOptionItem onClick={() => onReqOptionClicked("Email")}>
                Email this Requirement
              </SpOptionItem>
              <SpOptionItem onClick={() => onReqOptionClicked("Tag")}>
                Edit Your Tags
              </SpOptionItem>
            </SpOption>
          )}
        </div>
      </div>
      <div className="_spReqTeam--firstStep__descActions sp_row">
        <div className="_spReqTeam--firstStep__descActions--left sp_col">
          {!isEditing && listData?.currentStep > 0 ? (
            <p>
              {listData?.description || "This is a description of the project"}
            </p>
          ) : (
            <textarea
              placeholder="Describe your requirements"
              className="_reqDescEditor"
              rows={10}
              value={editingData.description}
              onChange={(e) => {
                if (error) setError("");
                setEditingData({ ...editingData, description: e.target.value });
              }}
            />
          )}
          {!isEditing && listData?.currentStep > 0 && (
            <div className="sp_row _spReqTeam--firstStep__descActions--left-reqTimes">
              <div className="sp_row">
                <span>Expected to start between: </span>
                <span>
                  {convertDateToString(editingData?.startDate)}-{" "}
                  {convertDateToString(editingData?.endDate)}
                </span>
              </div>
              <div className="sp_row">
                <span>Expected to last: </span>
                <span>
                  {editingData?.minMonths > 1
                    ? `${editingData?.minMonths} months`
                    : `${editingData?.minMonths} month`}{" "}
                </span>
              </div>
              <div className="sp_row">
                <span>Engagement Type: </span>
                <span>
                  {editingData?.staffing ? "Staffing" : "Managed Project"}
                </span>
              </div>
              <div className="sp_row">
                <span>Spryte Terms: </span>
                {editingData?.termsStatus ? (
                  <span className="_reqTime-confirmed">Confirmed</span>
                ) : (
                  <span className="_reqTime-pending">Not Confirmed</span>
                )}
              </div>
              {selectedUserRole == "admin" && (
                <SpBtn
                  label={"edit"}
                  sx={{ width: "94px" }}
                  onClick={() => {
                    logAction("Button Clicked", "CLICK");
                    setIsEditing(true);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {(isEditing || listData?.currentStep === 0) && (
        <div className="_spReqTeam--firstStep__editInfo">
          <h3 className="reqStepTitle">Set your engagement requirements</h3>
          <div className="_spReqTeam--firstStep__editInfo--fields sp_row">
            <div className="_spReqTeam--firstStep__editInfo--fields_datePicker">
              <h3>Earliest to Latest Project Start Date</h3>
              <DateRanger
                startDate={listData?.requirements?.start?.toDate()}
                endDate={listData?.requirements?.end?.toDate()}
                onDatesChange={onDatesChange}
              />
            </div>
            <div className="_spReqTeam--firstStep__editInfo--fields-selecters">
              {/* <h3>Engagements</h3> */}
              <div className="sp_row _spReq-selections">
                <div className="sp_col _spReq-selections_engType">
                  <h3>Engagement type</h3>
                  <select onChange={onStaffingChange}>
                    {staffingList.map((item, index) => {
                      const staffingRes = editingData?.staffing
                        ? "Staffing"
                        : "Managed Project";
                      const selected = item.value === staffingRes;

                      return (
                        <option
                          key={index}
                          value={item.value}
                          selected={selected}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="sp_col _spReq-selections_engTerm">
                  <h3>Expected engagement term</h3>
                  <select onChange={onMonthChange}>
                    <option value="1" selected={editingData?.minMonths === 1}>
                      1 month
                    </option>
                    <option value="2" selected={editingData?.minMonths === 2}>
                      2 months
                    </option>
                    <option value="3" selected={editingData?.minMonths === 3}>
                      3 months
                    </option>
                    <option value="4" selected={editingData?.minMonths === 4}>
                      4 months
                    </option>
                    <option value="5" selected={editingData?.minMonths === 5}>
                      5 months
                    </option>
                    <option value="6" selected={editingData?.minMonths === 6}>
                      6 months
                    </option>
                    <option value="7" selected={editingData?.minMonths === 7}>
                      7 months
                    </option>
                    <option value="8" selected={editingData?.minMonths === 8}>
                      8 months
                    </option>
                    <option value="9" selected={editingData?.minMonths === 9}>
                      9 months
                    </option>
                    <option value="10" selected={editingData?.minMonths === 10}>
                      10 months
                    </option>
                    <option value="11" selected={editingData?.minMonths === 11}>
                      11 months
                    </option>
                    <option value="12" selected={editingData?.minMonths === 12}>
                      12 months
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="sp_row _spReqTeam--firstStep__editInfo--actions">
            <div className="sp_row _spReqTeam--firstStep__editInfo--actions_check">
              {wasAgreed ? (
                <input
                  type={"checkbox"}
                  checked
                  readOnly={true}
                  // onChange={onTermsChange}
                />
              ) : (
                <input
                  type={"checkbox"}
                  checked={editingData?.termsStatus}
                  onChange={onTermsChange}
                />
              )}

              <label>
                I accept the Spryte{" "}
                <span
                  style={{
                    color: "#4361E9",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={() => setViewTerms(true)}
                >
                  Terms & Conditions
                </span>
              </label>
            </div>
            <div className="sp_row _spReqTeam--firstStep__editInfo--actions_btns">
              <SpBtn
                label={"Update"}
                sx={{ width: "194px" }}
                onClick={onUpdate}
              />
              {listData?.currentStep > 0 && (
                <SpBtn
                  label={"Cancel"}
                  sx={{ width: "194px" }}
                  showBg={false}
                  onClick={() => setIsEditing(false)}
                />
              )}
            </div>
            <AnimatePresence>
              {error && (
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30 }}
                  className="_spReqTeam--firstStep__editInfo--error"
                >
                  <p>{error}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </section>
  );
};

export default FIrstStep;
