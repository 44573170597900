import React from "react";

const TermsDialog = ({ lastTerm, vendorData, onCancel, onAgree }) => {
  return (
    <div className="request">
      <div className="request_form column" id="data-request_form">
        <div className="request_form-content column">
          {lastTerm?.data?.map((term, i) => {
            return (
              <div key={i} className="request_form-content__data">
                <h2>{term.title}</h2>
                <p>{term.description}</p>
              </div>
            );
          })}
        </div>
        <div className="request_form-action row">
          {vendorData?.searchedVendor?.id &&
          vendorData?.searchedVendor?.terms?.ref?.id !== lastTerm.id ? (
            <button
              id="agree"
              style={{
                padding: "5px 10px",
                fontWeight: "600",
                backgroundColor: "#1E96F5",
                border: "none",
                color: "#FFFFFF",
              }}
              onClick={() => onAgree()}
            >
              Agree
            </button>
          ) : (
            <></>
          )}
          <button
            id="cancel"
            style={{ padding: "5px 10px", fontWeight: "600" }}
            onClick={onCancel}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsDialog;
