import styled from "styled-components";

export const SingleHeadTab = styled.div`
  padding: 20px;
  min-width: 220px;
  box-sizing: initial;
  flex: 1;
  background-color: ${({ isActive }) =>
    isActive === "1" ? "#f5f5f5" : "white"};
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid #dfdfdf;
  &:last-child {
    border-right: none;
  }

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const TabTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopicCount = styled.div`
  span {
    font-size: 10px;
    color: #afafaf;
  }
`;

export const TabTopicRate = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    font-size: 12px;
    // color: #afafaf;
  }
`;

export const TabBottom = styled.div`
  gap: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 14px;
    color: ${({ isActive }) => (isActive === "1" ? "black" : "#afafaf")};
    font-weight: 600;
  }

  ._spAssHead--singleTab-bottom_scoreInfo {
    font-size: 10px;
    gap: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    padding: 0 5px;
    height: 100%;

    &--score {
      color: #13d426;
    }
  }
`;
