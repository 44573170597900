export const DEGRELEVELS = [
  { level: "High School" },
  { level: "Prep School" },
  { level: "BootCamp" },
  { level: "B.S." },
  { level: "B.A." },
  { level: "M.A." },
  { level: "M.S." },
  { level: "M.B.A" },
  { level: "Ph.D." },
];

export const DEFAULTSTEPSEDITLABELS = {
  title: "Title",
  info: "Info",
  desc: "Description",
  date1: "Start Date",
  date2: "End Date",
  company: "Company",
  location: "Location",
  bullets: "Bullets",
  schoolName: "School Name",
  country: "Country",
  degreeName: "Degree Name",
  degreeLevel: "Degree Level",
};

export const KEYMAPSTEPPER = {
  title: "title",
  desc: "desc",
  bullets: "bullets",
  company: "company",
  location: "location",
  schoolName: "school",
  country: "country",
  degreeName: "degName",
  degreeLevel: "degLevel",
  images: "images",
};

export const EDUKEYMAP = {
  schoolName: "school",
  country: "country",
  desc: "description",
  gradDate: "gradDate",
  degreeLevel: "degLevel",
  degreeName: "degName",
};

export const EDUCINIT = {
  school: "",
  country: "",
  description: "",
  gradDate: new Date(),
  degLevel: DEGRELEVELS?.[0]?.level,
  degName: "",
  addedOn: new Date(),
};

export const WORKKEYMAP = {
  company: "company",
  location: "location",
  start: "start",
  end: "end",
  title: "title",
  bullets: "bullets",
  position: "position",
};

export const WORKEXPINIT = {
  bullets: [""],
  company: "",
  end: new Date(),
  location: "",
  position: 0,
  start: new Date(),
  title: "",
  addedOn: new Date(),
};

export const PROJKEYMAP = {
  company: "company",
  location: "location",
  start: "start",
  end: "end",
  title: "role",
  bullets: "bullets",
  desc: "description",
  position: "position",
  images: "images",
};

export const PROJEXINIT = {
  company: "",
  location: "",
  start: new Date(),
  end: new Date(),
  role: "",
  bullets: [""],
  description: "",
  addedOn: new Date(),
  images: [],
};
