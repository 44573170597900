import styles from "../styles/uploadDevModal.module.css";
import { MdHelp } from "react-icons/md";
import React, { useState, useEffect, useCallback } from "react";
import { spryteCloudUrl, uploadFileToStorage } from "../../../globals/services";
import RequirementLocalLocation from "../reqTeam/requirementLocalLocation";
import SpUploader from "../../../globals/SpUploader";
import SpDialog from "../../../globals/SpDialog";
import SpBtn from "../../../globals/SpBtn";
import { generateTimestamp } from "../../../globals/helpers";

const UploadDev = ({
  showUploadDevs,
  setShowUploadDevs,
  vendorRef,
  devPositions,
  devData,
  isUploadDev = true,
}) => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [fetchedLocationsMap, setFetchedLocationsMap] = useState({});
  const [localAddress, setLocalAddress] = useState("");
  const [showPositionSelection, setShowPositionSelection] = useState(false);
  const [error, setError] = useState("");
  const [devPosition, setDevPosition] = useState();
  const [resume, setResume] = useState();
  const [uploading, setUploading] = useState(false);

  const onReset = () => {
    setFirst("");
    setLast("");
    setHourlyRate("");
    setDevPosition();
    setLocalAddress("");
    setResume();
    setError("");
  };

  useEffect(() => {
    if (!isUploadDev) return;
    if (hourlyRate !== "") {
      try {
        var parsedRate = parseInt(hourlyRate);
        if (isNaN(parsedRate) || parsedRate <= 0) {
          setError("Please enter valid rate.");
        } else if (parsedRate === "") {
          setError("Rate is required.");
        } else {
          setError("");
        }
      } catch (error) {
        setError(error);
      }
    } else {
      setError("");
    }
  }, [hourlyRate]);

  // Saves the uploaded file and realted item details which will be saved to database onSubmit
  function handleUploaderChange(e) {
    // console.log(e.target?.files?.[0]);
    const file = e.target?.files?.[0];
    setResume(file);
  }

  const updateNewResume = async () => {
    const resumeFileUrl = await uploadFileToStorage(
      `${vendorRef.path}/${resume?.name ?? "devResume"}`,
      resume
    );
    devData["devRef"]?.update({
      "resume.externalRef": resumeFileUrl,
      "resume.external": true,
    });
    setShowUploadDevs(false);
  };

  const uploadResource = async () => {
    setUploading(true);
    const folderName = `${(first ?? "").replace(/\s+/g, "_")}_${(
      last ?? ""
    ).replace(/\s+/g, "_")}`;
    let ResumeFileUrl = await uploadFileToStorage(
      `${vendorRef.path}/${folderName}`,
      resume
    );
    let bodyData = {
      vendor_id: vendorRef?.id,
      resume_dict: {},
      additional_data: {},
    };
    bodyData.additional_data[resume.name] = {
      first: first,
      last: last,
      rate: Number.parseInt(hourlyRate),
      position: devPosition?.ref?.path,
      location: localAddress,
    };
    bodyData.resume_dict[resume?.name] = ResumeFileUrl;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(bodyData),
      redirect: "follow",
    };

    const sendDevData = spryteCloudUrl("resume_parser");

    fetch(sendDevData, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        setUploading(false);
        return;
      })
      .catch((error) => console.log("error", error));
    onReset();

    setShowUploadDevs(false);
    setUploading(false);
  };

  const onModalClose = useCallback(() => {
    setShowUploadDevs(false);
    setFirst("");
    setLast("");
    setHourlyRate("");
    setDevPosition();
    setResume();
    setError("");
  }, []);

  return (
    <SpDialog
      locked={true}
      open={showUploadDevs}
      onClose={onModalClose}
      cls={styles.upDevModal}
    >
      {/* <div className={styles.customReqBg}>
      <div className={styles.upDevModal}> */}
      {/* <div className={styles.help}>
          {!isEdit && <MdHelp size={23.5} style={{ opacity: ".5" }} />}
          <div className={styles.helpContent}>
            <div className={styles.helpItem}>
              <h6>How & when to use custom requirements?</h6>
              <p>
                While you can search and pick teams directly to create
                requirements based on existing inventory, sometimes you need a
                team which is currently unavailable. If you can't find the right
                approximative team through a search to begin with, create a
                custom requirement here.
              </p>
            </div>
            <div className={styles.helpItem}>
              <h6>What happens next?</h6>
              <p>
                Once created, Spryte staff will review your requirement and
                assign it to the right mix of partner companies. You should
                expect resource profiles to stream in by the next business day.
              </p>
            </div>
            <div className={styles.helpItem}>
              <h6>Any questions?</h6>
              <p>
                Hit the chat icon on the bottom right of the page, and a Spryte
                specialist will help you out.
              </p>
            </div>
          </div>
        </div> */}
      <h3 id={styles.title}>
        {isUploadDev
          ? "Upload a Resource"
          : `Update ${devData?.first}'s Resume`}
      </h3>
      <p id={styles.alert}>
        <span>* Required fields</span>
      </p>
      {isUploadDev && (
        <>
          <div className={styles.nameDiv}>
            <div className={styles.columnDiv}>
              <p>
                Resource First Name <span>*</span>
              </p>
              <input
                key="firstNameInput"
                className={styles.input}
                placeholder="Resource's First Name"
                value={first}
                onChange={(e) => setFirst(e.target.value)}
              ></input>
            </div>
            <div className={styles.columnDiv}>
              <p>
                Last Name <span>*</span>
              </p>
              <input
                key="lastNameInput"
                className={styles.input}
                placeholder="Resource's Last Name"
                value={last}
                onChange={(e) => setLast(e.target.value)}
              ></input>
            </div>
          </div>
          <div className={styles.nameDiv}>
            <div className={styles.columnDiv}>
              <p>
                Hourly Rate <span>*</span>
              </p>
              <div>
                <input
                  key="rateInput"
                  className={styles.input}
                  placeholder="USD / Hour"
                  type={"number"}
                  value={hourlyRate}
                  onChange={(e) => setHourlyRate(e.target.value)}
                ></input>
                <p id={styles.helpText}>
                  Enter Your PUBLIC PARTNER RATE For This Resource. The Spryte
                  Platform Fee Will Be Deducted.
                </p>
              </div>
            </div>
            <div className={styles.columnDiv}>
              <p>
                Position <span>*</span>
              </p>
              <div className={styles.inputCtr}>
                {showPositionSelection && (
                  <div className={styles.positionsCtr}>
                    {devPositions ? (
                      devPositions?.map((position) => {
                        return (
                          <div
                            className={styles.position}
                            onClick={() => {
                              setDevPosition(position);
                              setShowPositionSelection(false);
                            }}
                          >
                            {position?.data()?.label ?? "name"}
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                <SpBtn
                  sx={{
                    opacity: showPositionSelection && ".35",
                    width: "80%",
                    marginTop: "10px",
                  }}
                  bg={"#2d3436"}
                  onClick={() =>
                    setShowPositionSelection(!showPositionSelection)
                  }
                  label={
                    devPosition?.data()?.label
                      ? devPosition?.data()?.label
                      : showPositionSelection
                      ? "COLLAPSE"
                      : "SELECT POSITION"
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.inputCtr}>
            <p>
              Location <span>*</span>
            </p>
            <p id={styles.helpText}>
              Now pick a precise location to showcase as this resource's
              residency
            </p>
            <RequirementLocalLocation
              onSelection={(e) => {
                if (e !== "") setLocalAddress(e?.toLowerCase());
              }}
              localAddress={localAddress}
              setLocalAddress={setLocalAddress}
              fetchedLocationsMap={fetchedLocationsMap}
              setFetchedLocationsMap={setFetchedLocationsMap}
              placeHolder="Where does this resource live & work?"
            />
          </div>
        </>
      )}

      <div className={styles.inputCtr}>
        <p>
          Resume / Curriculum Vitea Document <span>*</span>
        </p>
        <input
          className={styles.input}
          type="text"
          value={resume?.name ?? ""}
          style={{
            marginBottom: "1rem",
          }}
        />
        <SpUploader
          onFileSelect={handleUploaderChange}
          label="Upload Resume"
          btnStyle={{
            backgroundColor: "#2d3436",
            fontSize: "12px",
            fontWeight: "500",
            padding: "3.5px 15px",
            borderRadius: "1.5px",
            letterSpacing: "-0.15px",
            opacity: "0.935",
            transition: "none",
            color: "white",
            marginTop: "10px",
            textTransform: "uppercase",
          }}
        />
      </div>
      {isUploadDev ? (
        <>
          {error && <p style={{ color: "red" }}>* {error}</p>}
          <p id={styles.infoText}>
            TO SPEED UP RESOURCE SUBMISSION, PLEASE ENSURE THE RESUME/CV
            DOCUMENT YOU UPLOAD IS
            <span> NOT BRANDED WITH YOUR COMPANY INFORMATION</span>. BRANDED
            RESUMES WILL NEED A MANUAL OVERSIGHT AND WILL BE DELAYED IN THEIR
            SUBMISSION TO END-CLIENTS.
          </p>
          <SpBtn
            label="Upload Resource"
            onClick={() => uploadResource()}
            sx={{
              width: "100%",
              padding: "10px",
            }}
            bg={"#F95E2F"}
            disabled={
              first === "" ||
              last === "" ||
              !hourlyRate ||
              !devPosition ||
              !localAddress ||
              !resume ||
              error !== "" ||
              uploading
            }
          />
        </>
      ) : (
        <SpBtn
          label="Upload New Resume"
          onClick={() => updateNewResume()}
          sx={{
            width: "100%",
            padding: "10px",
          }}
          bg={"#F95E2F"}
          disabled={!resume}
        />
      )}
      {/* </div>
    </div> */}
    </SpDialog>
  );
};

export default UploadDev;
