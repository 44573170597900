import { MdLocationOn } from "react-icons/md";
import DevPosition from "../../../svgs/DevPosition";
import ResponsiveImage from "../../ResponsiveImg";
// import SpResponsiveImg from "../../../SpResponsiveImg";
import SpStartSVG from "../../SpStartSVG";
// import devEmptyProfile from "../../../../public/devEmptyProfile.png";
import {
  DevDetails,
  DevImageStatus,
  DevLoc,
  DevLocation,
  DevProfileInfo,
  DevProfileTop,
  DevRate,
  DevStatus,
  DevName,
  DevRightElement,
} from "./style";

export const renderDevRates = (
  startWidth = "20px",
  startHeight = "20px",
  rating = 1,
  color = "#00C1AA",
  count = 4
) => {
  let ratingList = [];
  for (let i = 0; i < count; i++) {
    if (i < rating) {
      ratingList.push(
        <SpStartSVG
          width={startWidth}
          height={startHeight}
          startColor={color}
          key={`start-${i}`}
        />
      );
    } else {
      ratingList.push(
        <SpStartSVG
          width={startWidth}
          height={startHeight}
          startColor={"#868DA9"}
          key={`start--${i}`}
        />
      );
    }
  }
  return <DevRate>{ratingList}</DevRate>;
};

export const DevProfile = ({
  startWidth = "20px",
  startHeight = "20px",
  profileUrl,
  profileAlt = null,
  devData,
  borderStyle = "rounded",
  sx,
  bg = "transparent",
  padding = "0px",
  bradius = "0px",
  cls,
  center = false,
  imgWidth = "75px",
  imgHeight = "75px",
  showExp = false,
  showStatus = true,
  statusColor = "#13D00F",
  statusTop = "-5px",
  statusRight = "-5px",
  score = 10,
  onSelect,
  showRightElement = true,
  renderRightElement = null,
  showRates = true,
  progressText = "Match Score",
  progressValue = 10,
}) => {
  return (
    <DevProfileTop
      bg={bg}
      padding={padding}
      className={`sp_row ${cls}`}
      style={sx}
      center={center}
      bradius={bradius}
    >
      <DevProfileInfo className={`sp_row ${cls + "_profInfo"}`}>
        <DevImageStatus className={`${cls}_imgStatus`}>
          {showStatus && (
            <DevStatus
              statuscolor={devData?.status && statusColor}
              statusright={statusRight}
              statustop={statusTop}
              className={`${cls}_imgStatus--status`}
            />
          )}

          {profileUrl ? (
            // <img
            //   src={profileUrl}
            //   alt={profileAlt}
            //   width={imgWidth}
            //   height={imgHeight}
            //   className={`${cls}_imgStatus--img`}
            // />
            <ResponsiveImage
              src={profileUrl}
              alt={profileAlt}
              width={imgWidth}
              height={imgHeight}
              className={`${cls}_imgStatus--img`}
            />
          ) : (
            <ResponsiveImage
              src={"/images/devEmptyProfile.png"}
              alt={profileAlt}
              width={imgWidth}
              height={imgHeight}
              className={`${cls}_imgStatus--img`}
            />
          )}
        </DevImageStatus>
        <DevDetails className={`${cls}_detail sp_col`}>
          <DevName
            onClick={() => {
              if (onSelect) {
                onSelect();
                return;
              }
              // window.open(`../resource/${devData?.devDocID}`, "_blank");
            }}
            className={`${cls}_detail-name`}
          >
            {devData?.first} {devData?.last}
          </DevName>
          <DevLocation className="sp_row">
            <DevPosition />
            <DevLoc>{devData?.position}</DevLoc>
          </DevLocation>
          <DevLocation className="sp_row">
            <MdLocationOn />
            <DevLoc>{devData?.locTag}</DevLoc>
          </DevLocation>
          {showRates &&
            renderDevRates(startWidth, startHeight, devData?.rating)}
          {showExp && (
            <DevLocation className="sp_row">
              <DevLoc>16 yrs experience</DevLoc>
            </DevLocation>
          )}
        </DevDetails>
      </DevProfileInfo>
      {showRightElement && (
        <>
          {renderRightElement ? (
            renderRightElement()
          ) : (
            <DevRightElement className={`${cls}_right sp_col`}>
              <span className="_spMatchText">{progressText}</span>
              <span className="_spMatchScore">{progressValue}%</span>
            </DevRightElement>
          )}
        </>
      )}
    </DevProfileTop>
  );
};
