import { AddCircleOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
// import '../../../../css/Contract.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //https://fkhadra.github.io/react-toastify/introduction/
import DeleteComponent from "../../DeleteComponent";

const SecuritySection = (props) => {
  const [securities, setSecurities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const vendorId = props.vendorId;

  const handChange = (e) => {
    const image = e.target.files[0];
    if (image.size <= 10000000) {
      if (image) {
        let storage = props.firebase.getStorage();
        const uploadTask = storage
          .ref(`vendors/${vendorId}/documents/${image.name}`)
          .put(image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          (error) => {
            toast.error(error);
          },
          () => {
            storage
              .ref(`vendors/${vendorId}/documents/`)
              .child(image.name)
              .getDownloadURL()
              .then(async (url) => {
                setProgress(0);
                const contract = {
                  fileRef: url,
                  fileName: image.name,
                };
                props.firebase.createDocument(vendorId, contract);
                setLoading(true);
                toast.success("Document Uploaded");

                if (loading) {
                  try {
                    const securities = await props.firebase.getDocuments(
                      vendorId
                    );

                    setSecurities(securities);
                    setLoading(false);
                  } catch (err) {
                    toast.error(err);
                  }
                }
              });
          }
        );
      } else {
        toast.error("Error please choose a file to upload");
      }
    } else {
      toast.error("Error please choose a file size less than 10MB");
    }
  };

  const deleteDocument = async (fileName, docId) => {
    let storage = props.firebase.getStorage();
    const fileRef = storage.ref(`vendors/${vendorId}/documents/${fileName}`);
    fileRef
      .delete()
      .then(async () => {
        await props.firebase.deleteDocument(vendorId, docId);
        setLoading(true);
      })
      .catch((err) => {
        toast.error(err);
        props.firebase.deleteContract(vendorId, docId);
        setLoading(true);
      });
    const securities = await props.firebase.getDocuments(vendorId);
    setSecurities(securities);
  };

  useEffect(() => {
    const getData = async () => {
      if (loading) {
        try {
          const securities = await props.firebase.getDocuments(vendorId);

          setSecurities(securities);
          setLoading(false);
        } catch (err) {
          toast.error(err);
        }
      }
    };

    getData();
  }, [loading, props.firebase, vendorId]);

  const handleClick = () => {
    const uploadbtn = document.querySelector("#cert_btn");
    uploadbtn.click();
  };

  let grid = securities.map((security, index) => {
    return (
      <tr key={index}>
        <td>{security.fileName}</td>
        <td>{security.uploadedDate.toDateString()}</td>
        <td>
          <a className="download" href={security.fileRef} target="blank">
            Download
          </a>
        </td>
        <td>
          <DeleteComponent
            delete={() => deleteDocument(security.fileName, security.Id)}
            fileName={security.fileName}
          />
        </td>
      </tr>
    );
  });
  return (
    <div className="contract_cont">
      <div className="ctr security">
        <div className="ctr_el_header">
          <h3>Documents</h3>
          <div className="add_btn">
            <input
              type="file"
              name="myfile"
              id="cert_btn"
              onChange={handChange}
            />
            <AddCircleOutline className="icon" onClick={(e) => handleClick()} />
          </div>
          {progress > 0 ? <progress value={progress} max="100" /> : <></>}
        </div>

        <div className="content">
          <p>
            Please upload your company's security, process, sales or management
            documents for review. This will open up more contracts opportunities
          </p>
        </div>
      </div>
      <div className="contract_bottom">
        {grid.length !== 0 ? (
          <table className="stable">
            <thead className="stable_header">
              <tr>
                <th>File</th>
                <th>Uploaded Date</th>
                <th>Download</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>{grid}</tbody>
          </table>
        ) : (
          <div className="resource-header">
            <p
              style={{
                color: "red",
                fontWeight: "500",
                opacity: ".65",
                fontStyle: "italic",
                paddingTop: "25px",
              }}
            >
              You do not have any documents uploaded.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecuritySection;
