import { Slider } from "@mui/material";
import styled, { css } from "styled-components";

const StyledSlider = styled(Slider)(
  (props) => css`
    && {
      color: ${props.trackColor || "black"};
      height: ${props.height || "8px"};
      padding: ${props.padding || "10px 0"};
      min-width: ${props.minWidth || "100px"};
      width: ${props.width || "100%"};
    }
  `
);

const SliderSp = (props) => {
  return (
    <StyledSlider
      defaultValue={props.defaultValue || 20}
      getAriaValueText={props.getAriaValueText}
      aria-labelledby="discrete-slider-custom"
      step={props.step || 1}
      valueLabelDisplay="auto"
      marks={props.marks}
      min={props.min || 0}
      max={props.max || 100}
      trackColor={props.trackColor ?? "black"}
      {...props}
    />
  );
};

export default SliderSp;
