import React from "react";

const ArrowDownOutlined = ({ w = "14", h = "15", stroke = "black" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73675 8.61335L4.73718 9.11335L4.23718 9.11377L2.28839 9.11538C1.75931 9.11582 1.26267 9.129 0.890289 9.14953C0.825445 9.15311 0.765104 9.15686 0.709591 9.16076C0.858826 9.31589 1.05668 9.51238 1.29203 9.73982C1.85155 10.2805 2.60516 10.979 3.37527 11.6746C4.1453 12.3701 4.92796 13.0592 5.5452 13.5813C5.85439 13.8428 6.11833 14.0593 6.31681 14.2128C6.41658 14.29 6.49471 14.3474 6.55103 14.3855C6.56574 14.3954 6.57761 14.4031 6.58688 14.409C6.61987 14.4146 6.6694 14.4196 6.72968 14.4196C6.79725 14.4195 6.85118 14.4131 6.88342 14.4067C6.89289 14.4014 6.91114 14.3908 6.93975 14.3722C7.00078 14.3326 7.08323 14.2737 7.1869 14.1948C7.39336 14.0377 7.66249 13.8177 7.9748 13.552C8.59822 13.0217 9.37622 12.3246 10.135 11.6213C10.894 10.9179 11.6288 10.213 12.1666 9.66732C12.3395 9.4918 12.4896 9.33526 12.6124 9.20212C12.324 9.17635 11.8948 9.15911 11.2507 9.14397L11.2506 9.14397L9.20079 9.09556L8.71299 9.08404L8.71258 8.59611L8.70927 4.68111C8.70919 4.59337 8.70914 4.5074 8.70908 4.42314C8.70836 3.35411 8.70783 2.56147 8.67565 1.963C8.64 1.30016 8.56731 0.965618 8.47177 0.786242C8.43174 0.711086 8.39138 0.67136 8.34773 0.643095C8.29866 0.61131 8.22045 0.578233 8.0847 0.552922C7.80709 0.501161 7.4251 0.501591 6.82723 0.502264C6.79154 0.502305 6.75507 0.502346 6.71781 0.502376C6.68007 0.502408 6.64314 0.502427 6.607 0.502446C6.00975 0.502758 5.62814 0.502958 5.35085 0.555166C5.21518 0.58071 5.1371 0.613927 5.08813 0.645795C5.04458 0.674137 5.00425 0.713989 4.9643 0.789423C4.86896 0.969414 4.79687 1.3048 4.76238 1.96879C4.73127 2.56781 4.73208 3.36094 4.73318 4.43038C4.73327 4.51563 4.73336 4.60265 4.73343 4.69146L4.73675 8.61335ZM6.6116 14.4235C6.61144 14.4234 6.61017 14.4227 6.60787 14.4216C6.61057 14.4231 6.61177 14.4236 6.6116 14.4235ZM11.2624 8.64411C12.9821 8.68452 13.3257 8.74089 13.3956 8.99343C13.4802 9.29882 7.64066 14.7107 7.04097 14.8827L11.2624 8.64411Z"
        stroke={stroke}
      />
    </svg>
  );
};

export default ArrowDownOutlined;
