import styled from "styled-components";

export const StacksWrapper = styled.div`
  background-color: ${({ bg }) => bg && bg};
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 118px;
  box-sizing: border-box;
  flex-grow: 3;
  overflow: hidden;
  @media (max-width: 596px) {
    max-height: 218px;
    min-height: 135px;
  }
`;
export const StackTitle = styled.div`
  display: flex;
  gap: 1px;
  h3 {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
`;
export const StacksList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden;
  box-sizing: border-box;
`;
export const SingleStack = styled.div`
  width: fit-content;
  display: flex;
  gap: 5px;
  background-color: ${({ showstackbg }) => showstackbg && "#F4F5F9"};
  height: 29px;
  align-items: center;
  padding: ${({ showstackbg, stackpadding }) =>
    showstackbg && stackpadding == "0px" ? ".3rem .5rem" : stackpadding};
  border-radius: ${({ showstackbg, stackborderradius }) =>
    showstackbg && stackborderradius == "0px" ? "6px" : stackborderradius};

  p {
    font-size: 14px;
    color: ${({ showstackbg }) => (!showstackbg ? "#868DA9" : "#868DA9")};
  }
`;
