import React from "react";
import SliderSp from "../../../../globals/MuiExtends/SliderSp";
import { Refresh } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const MatchActions = ({
  handleMatchCriteriaChange,
  sigma,
  handlePassCriteriaChange,
  jobLookBack,
  computing,
  onRecompute,
  filterOptions,
  selectedOption,
  handleFilterChange,
}) => {
  return (
    <div className="spDevMatches_actions">
      <div className="spDevMatches_actions__slides">
        <label>Match Criteria </label>
        {/* slide */}
        <SliderSp defaultValue={sigma} onChange={handleMatchCriteriaChange} />
      </div>
      <div className="spDevMatches_actions__slides">
        <label>Freshness Criteria </label>
        {/* slide */}
        <SliderSp
          defaultValue={jobLookBack}
          onChange={handlePassCriteriaChange}
        />
      </div>
      <div className="spDevMatches_actions__btn">
        <button
          style={{
            backgroundColor: computing ? "gray" : "#000",
            color: "white",
          }}
          onClick={onRecompute}
        >
          {computing ? (
            <CircularProgress
              size={20}
              sx={{
                color: "white",
                fontWeight: "bold",
              }}
            />
          ) : (
            <Refresh />
          )}
          <span>Refresh Matches</span>
        </button>
      </div>
      <div className="spDevMatches_actions__filter">
        <label>Filter By: </label>
        <select
          value={selectedOption?.value}
          onChange={handleFilterChange}
          style={{
            width: "unset",
          }}
        >
          {filterOptions?.map((option, optionIndex) => (
            <option value={option.value} key={`${option.value}_${optionIndex}`}>
              {option?.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MatchActions;
