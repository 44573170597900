import {
  PricePerc,
  RateContent,
  RatePrices,
  RateTop,
  RateWrapper,
} from "./style";

export const DevRates = ({
  showBorderBtm = true,
  showborderTop = true,
  pd = "1rem 0",
  sprint,
  hourlyRate,
  month,
  title = "Pricing",
  titleRight = "( 37% less expensive than the average of your matches)",
  pricePerc = -37,
  cls,
}) => {
  return (
    <RateWrapper className={cls}>
      <RateTop className={`${cls}_top`}>
        <h3>{title}</h3>
        <span>{titleRight}</span>
      </RateTop>
      <RateContent className={`${cls}_content`}>
        <RatePrices className={`${cls}_content__prices`}>
          <div className="ratePrice ratePricehr">
            <p>${hourlyRate}</p>
            <span>avg / hour</span>
          </div>
          <div className="ratePrice ratePricemt">
            <p>${month}</p>
            <span>per month</span>
          </div>
        </RatePrices>
        {pricePerc !== null && (
          <PricePerc>
            <p>{pricePerc}%</p>
          </PricePerc>
        )}
      </RateContent>
      {/* <SingleRate>
        <p>${sprint}</p>
        <span>per sprint</span>
      </SingleRate>
      <SingleRate>
        <p>${month}</p>
        <span>per month</span>
      </SingleRate>
      <HourlyRate>
        <p>${hourlyRate}</p>
        <span>per hour</span>
      </HourlyRate> */}
    </RateWrapper>
  );
};
