import { useEffect, useState } from "react";
import { db } from "../../globals/services";
import TechsCarousel from "../Developer/Carousels/Techs/TechsCarousel";
import AddNew from "./components/AddNew";

const StacksCollection = (props) => {
  const [selectedData, setSelectedData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultStack = props?.stacks[0] ?? {};

  const [searchData, setSearchData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedAssessmentNames, setSelectedAssessmentNames] = useState([]);

  useEffect(() => {
    const getAssessments = async () => {
      let _assessmentsNames = [];
      if (selectedData?.assessments) {
        await Promise.all(
          selectedData?.assessments?.map(async (aMap) => {
            const snapShot = await aMap?.ref?.get();
            if (
              snapShot?.data()?.name &&
              !_assessmentsNames.includes(snapShot?.data()?.name)
            )
              _assessmentsNames.push(snapShot?.data()?.name);
          })
        );
      } else if (
        Object.keys(selectedData).length === 0 &&
        defaultStack?.assessments
      ) {
        await Promise.all(
          defaultStack?.assessments?.map(async (aMap) => {
            const snapShot = await aMap?.ref?.get();
            if (
              snapShot?.data()?.name &&
              !_assessmentsNames.includes(snapShot?.data()?.name)
            )
              _assessmentsNames.push(snapShot?.data()?.name);
          })
        );
      }
      setSelectedAssessmentNames([..._assessmentsNames]);
    };
    getAssessments();
  }, [defaultStack?.assessments, selectedData?.assessments, selectedData]);

  const search = async (searchVal) => {
    const lowerCaseSearchValue = searchVal?.toLowerCase();
    const initialCapSearchValue =
      lowerCaseSearchValue?.charAt(0)?.toUpperCase() +
      lowerCaseSearchValue?.slice(1);
    const upperCaseSearchValue = searchVal?.toUpperCase();

    let convertedSearchValue = [
      initialCapSearchValue,
      lowerCaseSearchValue,
      upperCaseSearchValue,
    ];

    try {
      const searchIndex = await db
        .collection("catalog")
        .where("nameIndex", "array-contains-any", convertedSearchValue)
        .limit(10)
        .get();

      const searchData = searchIndex.docs.map((doc) => ({
        id: doc.id,
        ref: doc.ref,
        data: doc.data(),
      }));
      setSearchData(searchData);
      setShowDropdown(true);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleToggleAssessments = (assessmentName) => {
    // Check if the assessment name is already in the selectedAssessmentNames array
    if (!selectedAssessmentNames.includes(assessmentName)) {
      // If not, add it to the array
      setSelectedAssessmentNames((prevNames) => [...prevNames, assessmentName]);
    } else {
      // If it's already in the array, remove it
      setSelectedAssessmentNames((prevNames) =>
        prevNames.filter((name) => name !== assessmentName)
      );
    }
  };

  /**
   * takes the id of the input and the event
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
  };

  // console.log("selectedData", selectedData);

  return (
    <div className="colManagement_body-stacks collection">
      <AddNew
        open={openDialog || isEdit}
        onClose={() => {
          setOpenDialog(false);
          setIsEdit(false);
        }}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        techsCategories={props.techsCategories}
        firebase={props.firebase}
        handleFileUpload={handleFileUpload}
        collection="techStacks150r"
        isEdit={isEdit}
        categoryId={selectedData?.catId ?? defaultStack.catId}
        setIsEdit={setIsEdit}
        defaultData={selectedData.name ? selectedData : defaultStack}
        searchAssessments={search}
        searchData={searchData}
        setSearchData={setSearchData}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        setSelectedAssessmentNames={setSelectedAssessmentNames}
        isTech={true}
        onToggleAssessment={handleToggleAssessments}
        setSelectedStacks={setSelectedData}
        refreshData={props.refreshData}
      />

      <div className="collection_header">
        <h3>STACKS</h3>
        <p>All Sprytelabs Stacks.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          <TechsCarousel
            showHiddenCard={false}
            stacks={props.stacks}
            firebase={props.firebase}
            numOfStackToShow={48}
            setSelectedStacks={setSelectedData}
            useExist={false}
            onStacksPaginate={props.onStacksPaginate}
          />
          <div>
            <button
              className="spbtn spbtn-subno"
              onClick={() => setOpenDialog(true)}
            >
              Add new Stack
            </button>
          </div>
        </div>
        <div className="collection_data-information" id="collect_edit">
          <div className="collection_data-information__header row">
            <h3>{selectedData?.name ?? defaultStack.name}</h3>
            <div className="spbtn">
              <button
                id="stacklogoEditBtn"
                className="spbtn-sub spbtn"
                onClick={(e) => setIsEdit(true)}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="collection_data-information__detail">
            <div className="collectDetail">
              <span className="collectDetail_title">name</span>
              <p>{selectedData?.name ?? defaultStack.name}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Description</span>
              <p>{selectedData?.description ?? defaultStack.description}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">searchKeywords</span>
              <p>
                {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Rank</span>
              <p>{selectedData?.rank ?? defaultStack.rank}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Category</span>
              <span>
                {selectedData?.catData?.name ?? defaultStack?.catData?.name}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">SubCategory</span>
              <p>{selectedData?.subcategory ?? defaultStack.subcategory}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Id</span>
              <span>{selectedData?.id ?? defaultStack.id}</span>
            </div>
            <div className="collectDetail">
              <span className="collectDetail_title">Path</span>
              <span>{selectedData?.ref?.path ?? defaultStack?.ref?.path}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Show</span>
              <p>
                {selectedData?.show ?? defaultStack.show ? "true" : "false"}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">LogoStorage</span>
              <div>
                <span>
                  {selectedData?.logoStorage ?? defaultStack.logoStorage}
                </span>
              </div>
            </div>
            <div className="collectDetail">
              {selectedAssessmentNames.length > 0 && (
                <>
                  <span className="collectDetail_title">Assessments</span>
                  <div>
                    <span>{selectedAssessmentNames.join(", ")}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StacksCollection;
