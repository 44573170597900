import { useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Page,
  Edit,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import statusTemplate from "../../grid_templates/statusTemplate";
import {
  faArrowAltCircleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MyProduct = (props) => {
  // settings for the grid
  const editSettings = {
    allowDeleting: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
  };

  /**
   * Renders the combination spans
   * @returns a span containing an array of combinations
   */
  const combinationSpan = () => {
    let el = [];
    for (let i = 0; i < props.combination.length; i++) {
      if (i < props.combination.length - 1) {
        el.push(`${props.combination[i]} + `);
      } else {
        el.push(`${props.combination[i]} `);
      }
    }
    return <span style={{ fontWeight: 600 }}>{el}</span>;
  };

  /**
   * Takes an array of rate cards and groups them using their ids then set the products arrays
   *
   * @param {array} cardList array of object containing each rates details
   */
  const groupCards = (cardList) => {
    let pricesCards = []; //array to store the end list of generics
    let checks = [...props.checkedList];
    // group generics by their names and types
    let groupedCards = cardList.reduce((r, a) => {
      r[a.id] = [...(r[a.id] || []), a];

      return r;
    }, {});

    // arrays of the generic products ids to be able to delete them in the future
    //Looping through the arrays of ratescard in order to get the matching one to set their check box to true
    //or false depending on the array of rhe apploed cards
    for (let i = 0; i < props.rateCards.length; i++) {
      checks[i] = false;

      //looping through the grouped cards
      Object.values(groupedCards).forEach((group) => {
        let allLocations = []; //store the locations
        let allPricing = []; //stores the pricing Matrices

        let allIds = [];
        let variants = 0;
        group.forEach((generic) => {
          allLocations.push(generic.locations[0]);
          allPricing.push(generic.pricingMatrix);
          allIds.push(generic.Id);
          variants += generic.variantsCount;
        });

        //spread the group and remove locations and pricing matrix
        const { Id, pricingMatrix, locations, stats, variantsCount, ...data } =
          group[0]; //create the grid data to store all of the generics values with all locations and
        //pricing matrix
        let genericData = {
          // id: index,
          ids: allIds,
          locations: allLocations,
          pricingMatrix: allPricing,
          minPrice: stats.minPrice,
          maxPrice: stats.maxPrice,
          stats: stats,
          variantsCount: variants,
          ...data,
        };

        if (data.id === props.rateCards[i].id) {
          checks[i] = true;
          pricesCards.push(genericData);
        }
      });
    }

    props.setCheckedList(checks);
    props.setGridData(pricesCards);
  };

  useEffect(() => {
    let productsStream;
    const getProducts = async () => {
      productsStream = await props.firebase.db
        .collection(
          `vendors/${props.vendorId}/generic/${props.genericId}/genProducts`
        )
        .onSnapshot(async (snap) => {
          let genProducts = [];

          //when docs is empty we will set back the checks to false;
          for (const prodDoc of snap.docs) {
            let product = prodDoc.data();
            product.id = prodDoc.id;
            let relPriceCardRef = await product.priceCardRef.get();
            let relPriceCard = relPriceCardRef.data();
            relPriceCard.Id = prodDoc.id;
            const {
              availability,
              status,
              type,
              location,
              pricingMatrix,
              variantsCount,
              ...rateCard
            } = relPriceCard;

            let cardData = {
              from: availability.from.toDate(),
              to: availability.to.toDate(),
              status: status ? "Active" : "Inactive",
              type: type === 0 ? "Generic Resource" : "Generic Service",
              locations: [location],
              pricingMatrix: pricingMatrix,
              variantsCount: product.variantsCount,
              ...rateCard,
            };
            genProducts.push(cardData);
          }

          groupCards(genProducts);
        });
    };
    getProducts();
    return productsStream;
  }, [props.firebase]);

  /**
   * checks if their is images it will delete the images from storage first and delete
   * the data from firestore
   * @param {object} args
   */
  const actionComplet = async (args) => {
    if (args.requestType === "delete") {
      const { ids } = args.data[0];
      ids.forEach((id) => {
        props.firebase.db
          .collection(
            `vendors/${props.vendorId}/generic/${props.genericId}/genProducts`
          )
          .doc(id)
          .delete();
      });
    }
  };

  return (
    <div style={{ paddingTop: "20px", paddingBottom: "65px" }}>
      {props.isApply ? (
        <div className="request">
          <div className="request_form column" id="data-request_form">
            {props.renderRateCardComponent()}
            <div className="request_form-action row">
              <button
                className="spbtn spbtn-sub"
                onClick={() => props.onApplyCard()}
              >
                APPLY
              </button>
              <button
                className="spbtn spbtn-canc"
                onClick={() => props.setIsApply(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <button
          id="cancel"
          style={{ fontWeight: "600", padding: "2.5px 10px" }}
          onClick={() => {
            props.setIsPricingCreated(false);
            props.setGridData([]);
          }}
          className="row back"
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} className="arrow" />
          Go Back
        </button>
        <div
          className="bottom blue"
          style={{ margin: "25px 0", maxWidth: "950px" }}
        >
          <div>
            {" "}
            <p className="bold">
              {" "}
              Currently showing pricing for any combination of{" "}
              {combinationSpan()}
            </p>
            <p style={{ marginBottom: "0" }}>
              A Generic project team defines a group of resource you have
              available to put on project contracts. Alternatively you may also
              define actual resources on the Resources Tab. Project contracts
              are Sprint-based ongoing projects, where teams are contracted with
              an Agile mindset, i.e. clients pay for an
              <span className="bold"> hourly</span> engagement without fixed
              delivery expectations. Sprints contracted are due and paid.
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href={props.pageLink}
              target="_blank"
              style={{ color: "#007bff!important" }}
            >
              View {props.pageLink}
            </a>
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="arrow" />
          </div>
        </div>
      </div>
      <div className="row prods" style={{ gap: "2rem" }}>
        <h3>My Products</h3>
        <button
          className="spbtn spbtn-sub"
          onClick={() => {
            props.setIsApply(true);
          }}
          style={{ borderRadius: "2.5px", padding: "3.5px 15px" }}
        >
          APPLY PRICING CARD
        </button>
      </div>
      <div className="brand_body-grid">
        <GridComponent
          dataSource={props.gridData}
          allowSelection={true}
          toolbar={["Delete"]}
          allowSorting={true}
          allowPaging={true}
          editSettings={editSettings}
          pageSettings={{ pageSize: 10 }}
          height="295px"
          allowPdfExport={true}
          actionComplete={actionComplet}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              width="120"
              textAlign="Center"
            />
            <ColumnDirective
              field="name"
              headerText="Name"
              textAlign="Center"
              width="180"
            />
            <ColumnDirective
              field="status"
              headerText="Status"
              editType="numericedit"
              template={statusTemplate}
              defaultValue={""}
              width="130"
              textAlign="Center"
            />
            <ColumnDirective
              field="type"
              headerText="Type"
              width="180"
              textAlign="Center"
            />
            <ColumnDirective
              field="variantsCount"
              headerText="#Variants"
              editType="numericedit"
              format="n0"
              width="130"
              textAlign="Center"
            />
            <ColumnDirective
              field="minPrice"
              headerText="Min. Price"
              editType="numericedit"
              format="C2"
              width="130"
              textAlign="Center"
            />
            <ColumnDirective
              field="maxPrice"
              headerText="Max. Price"
              editType="numericedit"
              format="C2"
              width="130"
              textAlign="Center"
            />
            <ColumnDirective
              field="from"
              headerText="From"
              editType="datepickeredit"
              format="yMd"
              width="110"
              textAlign="Center"
            />
            <ColumnDirective
              field="to"
              headerText="To"
              editType="datepickeredit"
              format="yMd"
              width="110"
              textAlign="Center"
            />
            <ColumnDirective
              field="leadTime"
              headerText="Lead Time"
              editType="numericedit"
              format="n0"
              width="110"
              textAlign="Center"
            />
            <ColumnDirective
              field="quantity"
              headerText="Volume"
              editType="numericedit"
              format="n0"
              width="100"
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Selection, Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
};
export default MyProduct;
