import React from "react";
import { useCurrentDevRate } from "../hooks/useCurrentDevRate";

const HourlyRateTemplate = (props) => {
  const { devRate, isExpired } = useCurrentDevRate(props?.id);
  return (
    <div
      style={{
        color: isExpired ? "red" : null,
        fontWeight: isExpired ? "bold" : null,
      }}
    >
      ${devRate ?? 0}
    </div>
  );
};

export default HourlyRateTemplate;
