import React, { useEffect, useRef, useState } from "react";
import { StepperElementWrapper, StepperLeft, StepperRight } from "./style";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getYear } from "../../helpers";
import { useScreenSize } from "../../../hooks/useScreenSize";
import ArrowUpFilled from "../../../svgs/ArrowUpFilled";
import ArrowDownOutlined from "../../../svgs/ArrowDownOutlined";
import { StyledEngineProvider } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useFileUploader from "../../../hooks/useFileUploader";
import {
  DEFAULTSTEPSEDITLABELS,
  DEGRELEVELS,
  EDUKEYMAP,
  KEYMAPSTEPPER,
} from "../../constants";

const theme = createTheme({
  components: {
    // Override MUI DatePicker styles
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
      },
    },
    MuiCalendarHeader: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
        dayLabel: {
          fontSize: "12px",
        },
        iconButton: {
          fontSize: "12px",
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialog: {
          fontSize: "14px",
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
        input: {
          fontSize: "14px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: "12px",
        },
      },
    },
  },
});

const renderEducationElement = ({ editData, setEditData, keyMap }) => {
  return (
    <div className="_spStepperElement__right_edits">
      <div className="_spStepperElement__right_edits--row">
        <div className="_spStepperElement__right_edits--single">
          <label>School Name</label>
          <input
            type="text"
            value={editData?.[keyMap.schoolName]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap.schoolName]: e.target.value })
            }
          />
        </div>
        <div className="_spStepperElement__right_edits--single">
          <label>Country</label>
          <input
            type="text"
            value={editData?.[keyMap?.country]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap?.country]: e.target.value })
            }
          />
        </div>
      </div>
      <div className="_spStepperElement__right_edits--row">
        <div className="_spStepperElement__right_edits--single">
          <label>Degree Name</label>
          <input
            type="text"
            value={editData?.[keyMap?.degreeName]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap?.degreeName]: e.target.value })
            }
          />
        </div>
        <div className="_spStepperElement__right_edits--single">
          <label>Degree Level</label>
          <select
            value={editData?.[keyMap?.degreeLevel]}
            onChange={(e) =>
              setEditData({
                ...editData,
                [keyMap?.degreeLevel]: e.target.value,
              })
            }
          >
            {DEGRELEVELS.map(({ level }, index) => (
              <option key={index} value={level}>
                {level}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="_spStepperElement__right_edits--single">
        <label>Description</label>
        <textarea
          type="text"
          value={editData?.[keyMap?.desc]}
          onChange={(e) =>
            setEditData({ ...editData, [keyMap?.desc]: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export const renderExperiencesElement = ({
  editData,
  setEditData,
  labels,
  showDesc = true,
  showBullets = true,
  showImageUpload = false,
  onOpenImageUpload,
  fileInputElement,
  keyMap,
  images,
  onRemoveImage,
}) => {
  return (
    <div className="_spStepperElement__right_edits">
      <div className="_spStepperElement__right_edits--single">
        <label>{labels.title}</label>
        <input
          type="text"
          value={editData?.[keyMap?.title]}
          onChange={(e) =>
            setEditData({ ...editData, [keyMap?.title]: e.target.value })
          }
        />
      </div>
      <div className="_spStepperElement__right_edits--row">
        <div className="_spStepperElement__right_edits--single">
          <label>{labels.company}</label>
          <input
            type="text"
            value={editData?.[keyMap?.company]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap?.company]: e.target.value })
            }
          />
        </div>
        <div className="_spStepperElement__right_edits--single">
          <label>{labels.location}</label>
          <input
            type="text"
            value={editData?.[keyMap?.location]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap?.location]: e.target.value })
            }
          />
        </div>
      </div>
      {showDesc && (
        <div className="_spStepperElement__right_edits--single">
          <label>{labels.desc}</label>
          <textarea
            type="text"
            value={editData?.[keyMap?.desc]}
            onChange={(e) =>
              setEditData({ ...editData, [keyMap?.desc]: e.target.value })
            }
          />
        </div>
      )}
      {showBullets && (
        <div className="_spStepperElement__right_edits--single">
          <label>{labels.bullets}</label>
          {editData?.[`${keyMap?.bullets}`]?.map((bullet, index) => (
            <div key={index} className="_bulletEdit">
              <textarea
                type="text"
                value={bullet}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    bullets: editData?.[`${keyMap?.bullets}`].map((b, i) =>
                      i === index ? e.target.value : b
                    ),
                  })
                }
              />
              <button
                className="_bulletEdit--del"
                onClick={() =>
                  setEditData({
                    ...editData,
                    bullets: editData?.[`${keyMap?.bullets}`].filter(
                      (b, i) => i !== index
                    ),
                  })
                }
              >
                <HighlightOffIcon />
              </button>
            </div>
          ))}
          <div className="_bulletAdd">
            <button
              onClick={() => {
                if (!editData?.[`${keyMap?.bullets}`].includes("")) {
                  setEditData({
                    ...editData,
                    bullets: [...editData?.[`${keyMap?.bullets}`], ""],
                  });
                }
              }}
            >
              Add Bullet
            </button>
          </div>
        </div>
      )}
      {showImageUpload && (
        <div className="_spStepperElement__right_edits--uploadAction">
          <button onClick={onOpenImageUpload}>Upload Image</button>
          {fileInputElement}
        </div>
      )}
      {((Array.isArray(editData?.images) && editData?.images.length > 0) ||
        (Array.isArray(images) && images.length > 0)) && (
        <div className="_spStepperElement__right_edits--images">
          {Array.isArray(images) &&
            images.map((image, index) => (
              <div key={index} className="_image">
                <img key={index} src={image} alt="project" />
                <button onClick={() => onRemoveImage(index)}>
                  <HighlightOffIcon />
                </button>
              </div>
            ))}
          {Array.isArray(editData?.images) &&
            editData?.images.map((image, index) => (
              <div key={index} className="_image">
                <img key={index} src={image?.url} alt="project" />
                <button
                  onClick={() =>
                    setEditData({
                      ...editData,
                      images: editData?.images.filter((img, i) => i !== index),
                    })
                  }
                >
                  <HighlightOffIcon />
                </button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const SpStepperElement = ({
  children,
  left,
  title,
  info,
  desc,
  bullets,
  isEnableEdit,
  cls = "",
  onSave,
  onCancel,
  showBullets = true,
  showDesc = true,
  onRemove,
  enableEditing,
  type = 0,
  labels = DEFAULTSTEPSEDITLABELS,
  showImageUpload = false,

  keyMap = KEYMAPSTEPPER,
  itemData = {},
  useDateRange = false,
  dates = {
    start: null,
    end: null,
  },
  useUpDownArrows = false,
  renderEdits,
  incrementPosition,
  decrementPosition,
  onItemEdit,
  showUpArrow,
  showDownArrow,
  index,
  ...props
}) => {
  const windowWidth = useScreenSize();
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isEditing, setIsEditing] = useState(false);

  const [editData, setEditData] = useState({});
  const [images, setImages] = useState([]);
  const [imagesRaw, setImagesRaw] = useState([]);
  const [errors, setErrors] = useState({});
  // const fileUploaderRef = useRef(
  //   useFileUploader({
  //     onFileSelect: (files) => {
  //       const newImagesRaw = Array.from(files);
  //       setImagesRaw((prev) => [...prev, ...newImagesRaw]);

  //       const newImages = newImagesRaw.map((file) => URL.createObjectURL(file));
  //       setImages((prev) => [...prev, ...newImages]);
  //     },
  //     accept: "image/*",
  //   })
  // );

  const { openFileDialog, fileInputElement } = useFileUploader({
    onFileSelect: (files) => {
      const newImagesRaw = Array.from(files);
      setImagesRaw((prev) => [...prev, ...newImagesRaw]);

      const newImages = newImagesRaw.map((file) => URL.createObjectURL(file));
      setImages((prev) => [...prev, ...newImages]);
    },
    accept: "image/*",
    multiple: true,
  });

  useEffect(() => {
    if (!itemData) return;
    if (!isEditing) return;
    // Initialize editData based on itemData and keyMap
    const initialEditData = {};
    Object.values(keyMap).forEach((key) => {
      initialEditData[key] = itemData[key] || "";
    });
    setEditData(initialEditData);
  }, [itemData, keyMap, isEditing]);

  useEffect(() => {
    if (dates?.start && !useDateRange) {
      setSelectedDate(dayjs(dates?.start));
    }

    if (dates?.start && dates?.end && useDateRange) {
      setDateRange([dayjs(dates?.start), dayjs(dates?.end)]);
    }
  }, [dates, useDateRange]);

  const renderDate = () => {
    const startYear = dates?.start ? getYear(dates?.start) : null;
    const endYear = dates?.start ? getYear(dates?.end) : null;

    // If we don't have any start year or end year, return undefined
    if (startYear === null && endYear === null) return;

    // If the window width is greater than 980, use the full year
    if (windowWidth?.winWidth > 980) {
      if (startYear && endYear) {
        return `${startYear} - ${endYear}`;
      }
      return startYear ? `${startYear} - Present` : `${endYear}`;
    }

    // If the window width is less or equal to 980, use the last two digits of the year
    const shortEndYear = endYear?.toString().slice(-2);
    if (startYear) {
      return shortEndYear
        ? `${startYear} - ${shortEndYear}`
        : `${startYear} - Present`;
    }
    return shortEndYear;
  };

  useEffect(() => {
    if (enableEditing) {
      setIsEditing(true);
    }
  }, [enableEditing]);

  const handleEditClick = (e) => {
    if (isEnableEdit && !isEditing) {
      setIsEditing(true);

      if (onItemEdit) {
        onItemEdit(index);
      }
      e.stopPropagation();
    }
  };

  const handleSave = () => {
    setErrors({});
    // required fields validation: dates are required
    // education: schoolName, country, degreeName, degreeLevel
    // experience: title, company, location

    if (useDateRange && (!dateRange[0] || !dateRange[1])) {
      setErrors({
        dates: !dateRange[0] || !dateRange[1] ? "Dates are required" : null,
      });
      return;
    }

    if (
      type !== 1 &&
      (!editData?.[keyMap?.title] ||
        !editData?.[keyMap?.company] ||
        !editData?.[keyMap?.location])
    ) {
      setErrors({
        title: !editData?.[keyMap?.title]
          ? `${labels?.title ?? "Title"} is required`
          : null,
        company: !editData?.[keyMap?.company]
          ? `${labels?.company ?? "Title"}is required`
          : null,
        location: !editData?.[keyMap?.location]
          ? `${labels?.location ?? "Location"} is required`
          : null,
      });
      return;
    }
    if (
      type === 1 &&
      (!editData?.[keyMap?.schoolName] ||
        !editData?.[keyMap?.country] ||
        !editData?.[keyMap?.degreeName] ||
        !editData?.[keyMap?.degreeLevel])
    ) {
      setErrors({
        schoolName: !editData?.[keyMap?.schoolName]
          ? `${labels?.schoolName ?? "School Name"} is required`
          : null,
        country: !editData?.[keyMap?.country]
          ? `${labels?.country ?? "Country"} is required`
          : null,
        degreeName: !editData?.[keyMap?.degreeName]
          ? `${labels?.degreeName ?? "Degree Name"} is required`
          : null,
        degreeLevel: !editData?.[keyMap?.degreeLevel]
          ? `${labels?.degreeLevel ?? "Degree Level"} is required`
          : null,
      });
      return;
    }

    setIsEditing(false);
    if (onSave) {
      onSave({
        dates: {
          start: useDateRange ? dateRange[0] : selectedDate,
          end: useDateRange ? dateRange[1] : null,
        },
        editData,
        imagesFile: imagesRaw,
      });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditData(itemData);
    setImages([]);
    setImagesRaw([]);

    if (onCancel) {
      onCancel();
    }
  };

  const handleDateChange = (index, date) => {
    const newDateRange = [...dateRange];
    newDateRange[index] = date;
    setDateRange(newDateRange);

    if (
      index === 0 &&
      newDateRange[1] &&
      dayjs(date).isAfter(newDateRange[1])
    ) {
      newDateRange[1] = date;
    } else if (
      index === 1 &&
      newDateRange[0] &&
      dayjs(date).isBefore(newDateRange[0])
    ) {
      newDateRange[0] = date;
    }
    setDateRange(newDateRange);
  };

  const onRemoveImage = (index) => {
    setImages(images.filter((img, i) => i !== index));
    setImagesRaw(imagesRaw.filter((img, i) => i !== index));
  };

  const renderLeft = () => {
    if (left) {
      return left;
    }
    if (useDateRange && dates?.start && dates?.end) {
      return renderDate();
    }

    if (!useDateRange && dates?.start) {
      return getYear(dates?.start);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StepperElementWrapper
          {...props}
          className={`${cls} _spStepperElement`}
          onClick={handleEditClick}
        >
          <StepperLeft className={`${cls}__left _spStepperElement__left`}>
            <div
              className="_spStepperElement__left--dateContent"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                height: "fit-content",
              }}
            >
              {(isEnableEdit || enableEditing) && isEditing ? (
                useDateRange ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="_spStepperElement__left--dateRange">
                      <div className="_spStepperElement__left--dateRange-single">
                        <div className="_spStepperElement__left--dateRange-single_top">
                          <label>{labels.date1}</label>
                          {showUpArrow && (
                            <button onClick={incrementPosition}>
                              <ArrowUpFilled />
                            </button>
                          )}
                        </div>
                        <DatePicker
                          // label={labels.date1}
                          value={dateRange[0]}
                          onChange={(date) => handleDateChange(0, date)}
                          views={["year", "month"]}
                          renderInput={(params) => <TextField {...params} />}
                          format="YYYY/MM"
                        />
                      </div>
                      <div className="_spStepperElement__left--dateRange-single">
                        <div className="_spStepperElement__left--dateRange-single_top">
                          <label>{labels.date2}</label>
                          {showDownArrow && (
                            <button onClick={decrementPosition}>
                              <ArrowDownOutlined />
                            </button>
                          )}
                        </div>

                        <DatePicker
                          value={dateRange[1]}
                          onChange={(date) => handleDateChange(1, date)}
                          renderInput={(params) => <TextField {...params} />}
                          views={["year", "month"]}
                          format="YYYY/MM"
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="_spStepperElement__left--dateRange-single">
                      <div className="_spStepperElement__left--dateRange-single_top">
                        {/* <button >
                        <ArrowUpFilled />
                      </button> */}

                        <label>{labels.date1}</label>
                        {/* <button>
                        <ArrowDownOutlined />
                      </button> */}
                      </div>

                      <DatePicker
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                        views={["year", "month"]}
                        format="YYYY/MM"
                      />
                    </div>
                  </LocalizationProvider>
                )
              ) : (
                <>
                  {isEnableEdit && useDateRange ? (
                    <div
                      className="_spStepperElement__left--dateRange-edit"
                      style={{
                        display: "flex",
                        alignItems: "center",

                        gap: "10px",
                      }}
                    >
                      {showUpArrow && (
                        <button onClick={incrementPosition}>
                          <ArrowUpFilled />
                        </button>
                      )}

                      <h4>{renderLeft()}</h4>
                      {showDownArrow && (
                        <button onClick={decrementPosition}>
                          <ArrowDownOutlined />
                        </button>
                      )}
                    </div>
                  ) : (
                    <h4>{renderLeft()}</h4>
                  )}
                </>
              )}
            </div>
          </StepperLeft>
          <StepperRight
            className={`${cls}__right _spStepperElement__right`}
            isEnableEdit={isEnableEdit}
          >
            {isEnableEdit && (
              <button className="delete-button" onClick={onRemove}>
                <CloseIcon />
              </button>
            )}
            {children ? (
              <>{children}</>
            ) : (isEnableEdit || enableEditing) && isEditing ? (
              <>
                {renderEdits
                  ? renderEdits({
                      editData,
                      setEditData,
                      labels,
                      keyMap,
                    })
                  : type === 1
                  ? renderEducationElement({
                      editData,
                      setEditData,
                      keyMap: EDUKEYMAP,
                    })
                  : renderExperiencesElement({
                      editData,
                      setEditData,
                      labels,
                      showDesc,
                      showBullets,
                      showImageUpload,
                      fileInputElement,
                      onOpenImageUpload: openFileDialog,
                      keyMap,
                      images,
                      onRemoveImage,
                    })}
                <div className="_editError">
                  {Object.values(errors).map((error, index) => (
                    <span key={index}>{error}</span>
                  ))}
                </div>

                <div className="_editActions">
                  <button
                    className="_editActions_cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button className="_editActions_save" onClick={handleSave}>
                    Save
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4>{title}</h4>
                <p className="_stepDiploma">{info}</p>
                {showDesc && <p className="_stepDesc">{desc}</p>}
                {showBullets && bullets.length > 0 && (
                  <div className="_bullets">
                    {bullets.map((bullet, index) => (
                      <span key={index}>{bullet}</span>
                    ))}
                  </div>
                )}
                {itemData?.images?.length > 0 && (
                  <div className="_spStepperElement__right_edits--images">
                    {itemData?.images.map((image, index) => (
                      <div key={index} className="_image">
                        <img key={index} src={image?.url} alt="project" />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </StepperRight>
        </StepperElementWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default SpStepperElement;
