import React from "react";
import SingleMatch from "./MatchesTab/SingleMatch";

const MatchesTab = ({ posRecommendations, allTucPositions, setSelectedTabIndex, setShowDevProfile }) => {
  return (
    <div className=" _spReqTeam--OptionThird__details--right_matches">
      {posRecommendations?.map((member, i) => {
        return (
          <SingleMatch
            member={member}
            key={member["devData"]?.id}
            allTucPositions={allTucPositions}
            setSelectedTabIndex={setSelectedTabIndex}
            setShowDevProfile={setShowDevProfile}
          />
        );
      })}
    </div>
  );
};

export default MatchesTab;
