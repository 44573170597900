import React from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { renderDevRates } from "../../../../globals/SpDevCard/devProfile/DevProfile";
// import SpPolygonImg from "../../globalComponents/SpPolygonImg";
import LocIcon from "../../../../svgs/location/LocIcon";
import DevPickIcon from "../../../../svgs/DevPickIcon";
import DevProfileIcon from "../../../../svgs/DevProfile";
import InterviewIcon from "../../../../svgs/InterviewIcon";
import NoteIcon from "../../../../svgs/NoteIcon";
import {
  DevDetails,
  DevInterview,
  DevNote,
  DevNotes,
  DevPricing,
  DevProfileInfo,
  DevRater,
  DevTags,
  ListDev,
  DevOptions,
  SingleOption,
  DevProfileBorder,
  DevProfileWrapper,
} from "./style";
import EditIcon from "../../../../svgs/EditIcon";
import DeleteIcon from "../../../../svgs/DeleteIcon";

import SpResponsiveImg from "../../../../globals/SpResponsiveImg";
import { Tooltip } from "@mui/material";

const ListSingleDev = ({
  devData,
  onUpRank = null,
  onDownRank = null,
  logAuthors,
  onViewDev = null,
  onAddNote = null,
  onInterview = null,
  onInterviewConfirm = null,
  onPick = null,
  className = "",
  statusColorObject,
  profileSize = "50px",
  optionParms,
  selectedUserRole,
  onNoteClicked,
  onEditdev,
  onRemoveDev,
  onEditOption,
}) => {
  // convert timestamp to date
  const convertDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toDateString();
  };

  const getDevLocation = (fullString = false) => {
    let tempLoc = [devData?.devData?.locTag];
    if (devData?.devData?.locationData) {
      tempLoc = [devData?.devData?.locationData?.country];
      if (devData?.devData?.locationData?.locality)
        tempLoc.push(devData?.devData?.locationData?.locality);
      if (devData?.devData?.locationData?.sublocality_level_1)
        tempLoc.push(devData?.devData?.locationData?.sublocality_level_1);
    }
    return fullString
      ? tempLoc.join(", ")
      : tempLoc?.[0]?.substring(0, 20) +
          (tempLoc?.[0]?.length > 20 ? "..." : "");
  };

  return (
    <ListDev>
      <DevProfileInfo>
        {selectedUserRole !== "partner" && (
          <DevRater>
            <MdExpandLess className="sp_hover" size={20} onClick={onUpRank} />
            <span>{devData?.rank ?? "?"}</span>
            <MdExpandMore className="sp_hover" size={20} onClick={onDownRank} />
          </DevRater>
        )}

        <Tooltip
          title={
            selectedUserRole === "admin"
              ? devData?.partnerData?.companyName ?? ""
              : ""
          }
          placement="top"
          arrow
        >
          <DevProfileWrapper
            color={
              statusColorObject?.[devData?.status?.id?.toLowerCase()]?.fill
            }
            size={profileSize}
          >
            <DevProfileBorder
              color={
                statusColorObject?.[devData?.status?.id?.toLowerCase()]?.fill
              }
              size={profileSize}
            >
              <SpResponsiveImg
                src={devData?.devData?.logoStorage}
                alt={devData?.name}
                size={profileSize}
                borderRadius="50%"
              />
            </DevProfileBorder>
            <div className="devInfoIcon">
              {statusColorObject?.[devData?.status?.id?.toLowerCase()]?.icon}
            </div>
          </DevProfileWrapper>
        </Tooltip>

        <DevDetails>
          <h4 className="sp_hover" onClick={onViewDev}>
            {devData?.name?.substr(0, 20) +
              (devData?.name?.length > 20 ? "..." : "")}
          </h4>
          <Tooltip title={getDevLocation(true)} placement="top" arrow>
            <div className="listDevLoc">
              <LocIcon />
              <span>
                {getDevLocation()}
              </span>
            </div>
          </Tooltip>
          {renderDevRates(
            "15px",
            "15px",
            devData?.devData?.rating ?? 0,
            "#3A0C9E"
          )}
        </DevDetails>
        {devData?.partnerHourlyRate ? (
          <DevPricing>
            <span className="amountText">
              ${devData?.partnerHourlyRate?.toFixed(2) ?? "--"}
            </span>
            <span>Per Hr</span>
          </DevPricing>
        ) : (
          devData?.rates?.length > 0 && (
            <DevPricing
              className={selectedUserRole === "admin" ? "sp_hover" : ""}
              onClick={() => {
                if (selectedUserRole === "admin") onEditOption();
              }}
            >
              <span className="amountText">
                ${Math.min.apply(Math, devData?.rates).toFixed(2) ?? "--"}
              </span>
              <span>Per Hr</span>
            </DevPricing>
          )
        )}
      </DevProfileInfo>

      {devData?.interview?.length === 1 && devData?.interviewConfirmed && (
        <DevInterview className="confirmedInterview">
          <div>
            {/* <CheckBox size={20} /> */}
            <p>
              Interview Confirmed for {convertDate(devData?.interview?.[0])}
            </p>
          </div>
        </DevInterview>
      )}
      {devData?.interview?.length > 0 && !devData?.interviewConfirmed && (
        <DevInterview>
          <div>
            Interview <span>Requested</span>
          </div>
          <div
            style={{ cursor: selectedUserRole !== "client" ? "pointer" : null }}
            onClick={() => {
              selectedUserRole !== "client" && onInterviewConfirm();
            }}
          >
            <span>
              {selectedUserRole !== "client" ? "CONFIRM" : "Not confirmed"}
            </span>
          </div>
        </DevInterview>
      )}

      {devData?.tags?.length > 0 && (
        <DevTags>
          {devData?.tags?.map((tag, i) => {
            return (
              <div
                key={i}
                style={{
                  backgroundColor: tag?.color,
                  color: tag?.textColor ?? "#fff",
                }}
              >
                {tag?.label}
              </div>
            );
          })}
        </DevTags>
      )}

      {devData?.notes?.length > 0 && (
        <DevNotes>
          {devData?.notes?.map((note, i) => {
            return (
              note?.note !== "" && (
                <DevNote
                  onClick={() => onNoteClicked(note, devData, i)}
                  className="sp_hover"
                >
                  <span>
                    {logAuthors[note?.author?.id]
                      ? logAuthors[note?.author?.id]?.["first"] +
                        " " +
                        logAuthors[note?.author?.id]?.["last"][0] +
                        "."
                      : "Author"}
                  </span>
                  <span>"{note?.note}"</span>
                </DevNote>
              )
            );
          })}
        </DevNotes>
      )}

      <DevOptions
        className={`${className} _spReqDevOptions ${
          !optionParms?.isInlineOption ? "showOnHover" : "showAlways"
        }`}
      >
        {selectedUserRole === "admin" ? (
          <SingleOption
            onClick={onRemoveDev}
            className={`${className}--pick _spReqDevOptions--remove`}
          >
            <DeleteIcon w="20" h="14" />
            <p
              style={{
                color: "#FF223D",
              }}
            >
              Remove
            </p>
          </SingleOption>
        ) : (
          <SingleOption
            onClick={onViewDev}
            className={`${className}--viewProfile _spReqDevOptions--viewProfile`}
          >
            <DevProfileIcon />
            <p>View profile</p>
          </SingleOption>
        )}

        <SingleOption
          onClick={onAddNote}
          className={`${className}--addNote _spReqDevOptions--addNote`}
        >
          <NoteIcon />
          <p>Add note</p>
        </SingleOption>

        {selectedUserRole === "partner" ? (
          <SingleOption
            onClick={onEditdev}
            className={`${className}--interview _spReqDevOptions--interview`}
          >
            <EditIcon />
            <p>Edit Profile</p>
          </SingleOption>
        ) : (
          <SingleOption
            onClick={() => {
              !devData?.interviewConfirmed && onInterview();
            }}
            className={`${className}--interview _spReqDevOptions--interview`}
          >
            {devData?.interviewConfirmed ? (
              <InterviewIcon fill={"black"} />
            ) : (
              <InterviewIcon />
            )}
            <p style={{ color: devData?.interviewConfirmed ? "black" : "" }}>
              Interview
            </p>
          </SingleOption>
        )}

        {selectedUserRole === "partner" ? (
          <SingleOption
            onClick={onRemoveDev}
            className={`${className}--pick _spReqDevOptions--remove`}
          >
            <DeleteIcon w="20" h="14" />
            <p
              style={{
                color: "#FF223D",
              }}
            >
              Remove
            </p>
          </SingleOption>
        ) : (
          <SingleOption
            onClick={onPick}
            className={`${className}--pick _spReqDevOptions--pick`}
          >
            <DevPickIcon />
            <p>Pick</p>
          </SingleOption>
        )}
      </DevOptions>
    </ListDev>
  );
};

export default ListSingleDev;
