// "use client";
import styles from "./styles/requirementsSteps.module.css";
import {
  MdStar,
  MdInfo,
  MdWarning,
  MdCheckCircle,
  MdStarBorder,
} from "react-icons/md";

import { useState, useEffect, useRef, createRef } from "react";
// import { useRouter } from "next/router";
import SuccessPopup from "./successPopup";
import RequirementPriceCard from "./requirementPriceCard";
import RequirementTeamListCard from "./RequirementTeamListCard";
import CustomReq from "./customReq";
import ConfirmedDevsComponent from "./confirmedDevs";
import StepInfo from "./reqTeam/stepOneComponents/StepInfo";
import InfoTabs from "./requirement/infoTabs";
import ManageVendors from "./requirement/manageVendors";
import FIrstStep from "./reqTeam/firstStep/FIrstStep";
import {
  db,
  fetchPartnerDevsOnReqPage,
  fetchRecommendationsOnReqPage,
  fire,
  getCollectionRefFromPath,
  getDocumentFromPath,
  GetNowTimeStamp,
  GetRefFromPath,
  helpTabSiteComponentPath,
  updateDcoumentWithPath,
} from "../../globals/services";
import UploadDev from "./reqTeam/UploadDevModal";

function RequirementsSteps({
  info,
  setViewTerms,
  termsAndConditions,
  userAccountData,
  logAction,
  userType,
  partnerAccountData,
  loggedInUserData,
  isAdmin,
  userRolesAvailable,
  selectedUserRole,
  setSelectedUserRole,
  onReqOptionClick,
}) {
  // const router = useRouter();

  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setUTCSeconds(secs);
    return t;
  }

  function returnDaysFromNow(endDate) {
    var msDiff =
      new Date(endDate?.seconds).getTime() - new Date().getTime() / 1000;
    var daysTilDeadline = Math.floor(msDiff / 86400);
    return Math.abs(daysTilDeadline);
  }

  const [clientData, setClientData] = useState({});
  const [confirmed, setConfirmed] = useState(
    info?.requirements?.termsStatus ?? false
  );
  const [minHourlyPrice, setMinHourlyPrice] = useState(
    info?.requirements?.minHourlyPrice ?? 12
  );
  const [maxHourlyPrice, setMaxHourlyPrice] = useState(
    info?.requirements?.maxHourlyPrice ?? 36
  );
  const [tempMinPrice, setTempMinPrice] = useState(
    info?.requirements?.minHourlyPrice ?? 12
  );
  const [tempMaxPrice, setTempMaxPrice] = useState(
    info?.requirements?.maxHourlyPrice ?? 36
  );
  const [staffing, setStaffing] = useState(
    info?.requirements?.staffing ?? true
  );
  const [minMonths, setMinMonths] = useState(
    info?.requirements?.minMonths ?? 6
  );
  const [showAlert, setShowAlert] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [confirmationData, setConfirmationData] = useState();
  const [listData, setListData] = useState();
  const [teamRef, setTeamRef] = useState();
  const [logsData, setLogsData] = useState([]);
  const [logAuthors, setLogAuthors] = useState({});
  const [openTucToVendors, setOpenTucToVendors] = useState({});
  const [userAccData, setUserAccData] = useState({});
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [userAccountDataLoaded, setUserAccountDataLoaded] = useState(false);
  const [tucsData, setTucsData] = useState([]);
  const [nextDueDate, setNextDueDate] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [devPositions, setDevPositions] = useState([]);

  //Org fees state variables
  const [orgFees, setOrgFees] = useState([]);
  const [costFeeSum, setCostFeeSum] = useState(0);
  const [priceFeeSum, setPriceFeeSum] = useState(0);
  const [fixedCostFeeSum, setFixedCostFeeSum] = useState(0);
  const [fixedPriceFeeSum, setFixedPriceFeeSum] = useState(0);
  const [parentDom, setParentDom] = useState(null);

  // This sets the max value for the y-axis of the bar charts shown on this page.
  const [maxValue, setMaxValue] = useState(0);

  const [optionsHeight, setOptionsHeight] = useState(0);

  const option2Ref = useRef(null);

  //Stateful variables for dates
  const [startDate, setStartDate] = useState(
    info?.requirements?.startDate?.seconds * 1000 ?? Date.now()
  );
  const [endDate, setEndDate] = useState(
    info?.requirements?.endDate?.seconds * 1000 ??
      new Date(+new Date() + 2678400000)
  );

  // Bool to toggle opening of custom requirement modal.
  const [showCustomReq, setShowCustomReq] = useState(false);

  // bool to hide/show the list options
  const [showListOptions, setShowListOptions] = useState(
    info?.requirements?.termsStatus ?? false
  );

  const [posRecommendations, setPosRecommendations] = useState([]);
  // const [tucRecommendedVendors, setTucRecommendedVendors] = useState({});
  const [showDevProfile, setShowDevProfile] = useState({});
  const [selectedTuc, setSelectedTuc] = useState();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState();
  const [selectedPartnerDevs, setSelectedPartnerDevs] = useState([]);
  const [expand, setExpand] = useState(false);

  //Used for adding a dev to a particular position on matches or recommendations.
  const [allTucPositions, setAllTucPositions] = useState([]);

  const [showPartnerMore, setShowPartnerMore] = useState(false);

  //Tab index to display the selected tab in infotabs
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  //Site component data for help tab on infoTabs
  const [helpTabData, setHelpTabData] = useState([]);
  const [orgPosSpecs, setOrgPosSpecs] = useState([]);

  //Bool to check if the macTuc limit has been reached
  const [canAddTuc, setCanAddTuc] = useState(false);

  //Boolean to show/hide uploading resources modal from roster tab.
  const [showUploadDevs, setShowUploadDevs] = useState(false);
  const [showUpdateDev, setShowUpdateDev] = useState(false);
  const [devDataToUpdate, setDevDataToUpdate] = useState({});

  useEffect(() => {
    setSelectedTabIndex(0);
    if (selectedUserRole)
      GetRefFromPath(helpTabSiteComponentPath[selectedUserRole]).onSnapshot(
        (tempHelpTabDoc) => {
          setHelpTabData(
            tempHelpTabDoc
              ?.data()
              ?.data?.sections?.filter((topic) => topic?.show)
              ?.sort((a, b) => a.rank - b.rank)
          );
        }
      );
  }, [selectedUserRole]);

  useEffect(() => {
    if (partnerAccountData?.vendorRef && selectedUserRole != "client") {
      partnerAccountData?.vendorRef
        // ?.collection("partnerPublic")
        // ?.doc("profile")
        .onSnapshot((vendorDocSnap) => {
          setSelectedPartner(vendorDocSnap);
        });
    }
  }, [partnerAccountData?.vendorRef, selectedUserRole]);

  useEffect(() => {
    const fetchPartnerDevs = async () => {
      await fetchPartnerDevsOnReqPage(
        selectedPartner,
        selectedUserRole,
        partnerAccountData,
        setSelectedPartnerDevs
      );
    };
    if (selectedPartner) fetchPartnerDevs();
  }, [selectedPartner, selectedUserRole]);

  // Bool to toggle showing inline recommended devs and options
  const [optionParms, setOptionParms] = useState({
    isInlineRecom: false,
    isInlineOption: false,
    hideDismissedDev: true,
  });

  useEffect(() => {
    db.collection("/organisations/T6BAcTjwbXleCFpmWTmu/positions").onSnapshot(
      async (positionsCollectionSnap) => {
        setDevPositions(positionsCollectionSnap.docs);
      }
    );

    db.collection("/organisations/T6BAcTjwbXleCFpmWTmu/emailTemplates")
      .where("editable", "==", true)
      .onSnapshot((templates) => {
        setEmailTemplates(templates?.docs);
      });

    db.collection("/organisations/T6BAcTjwbXleCFpmWTmu/posSpecs")
      .where("show", "==", true)
      .onSnapshot((posSpecs) => {
        setOrgPosSpecs(
          posSpecs?.docs?.sort((a, b) => a?.data()?.rank - b?.data()?.rank)
        );
      });

    //Getting organisation fees for spryte margin
    const getOrgFees = async () => {
      var nowTime = GetNowTimeStamp();
      var getOrgAllFees = await db
        .collection("/organisations/T6BAcTjwbXleCFpmWTmu/fees")
        .get();
      var tempFeeData = [];
      getOrgAllFees.docs.map((feeDocMap) => {
        if (
          feeDocMap?.data()?.startOn <= nowTime &&
          nowTime <= feeDocMap?.data()?.endOn
        ) {
          tempFeeData.push(...(feeDocMap?.data()?.fees ?? []));
        }
      });
      setOrgFees(tempFeeData);
      var tempSumCostFee = 0;
      var tempSumPriceFee = 0;
      var tempSumFixedCostFee = 0;
      var tempSumFixedPriceFee = 0;
      tempFeeData.forEach((fee) => {
        if (fee["percentual"]) {
          if (fee["takenFrom"] == "cost") {
            tempSumCostFee = tempSumCostFee + fee["amount"];
          } else {
            tempSumPriceFee = tempSumPriceFee + fee["amount"];
          }
        } else {
          if (fee["takenFrom"] == "cost") {
            tempSumFixedCostFee = tempSumFixedCostFee + fee["amount"];
          } else {
            tempSumFixedPriceFee = tempSumFixedPriceFee + fee["amount"];
          }
        }
      });
      setCostFeeSum(tempSumCostFee);
      setPriceFeeSum(tempSumPriceFee);
      setFixedCostFeeSum(tempSumFixedCostFee);
      setFixedPriceFeeSum(tempSumFixedPriceFee);
    };
    getOrgFees();
  }, []);

  useEffect(() => {
    const now = new Date();
    now.setDate(now.getDate() + 14);
    setNextDueDate(
      now.toLocaleDateString("en", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    );

    const getClientData = async () => {
      var data = await getDocumentFromPath(`client/${info.clientId}`);
      setClientData(data);
    };
    const updateCurrentStepIfTermsAgreed = async () => {
      await updateDcoumentWithPath(`client/${info.clientId}/lists/${info.id}`, {
        currentStep: 1,
      });
    };
    setShowListOptions(info?.requirements?.termsStatus ?? false);
    setConfirmed(info?.requirements?.termsStatus ?? false);
    setMinHourlyPrice(info?.requirements?.minHourlyPrice ?? 12);
    setMaxHourlyPrice(info?.requirements?.maxHourlyPrice ?? 36);
    setTempMinPrice(info?.requirements?.minHourlyPrice ?? 12);
    setTempMaxPrice(info?.requirements?.maxHourlyPrice ?? 36);
    setStaffing(info?.requirements?.staffing ?? true);
    setMinMonths(info?.requirements?.minMonths ?? 6);
    setLogsData(info?.logs ?? []);
    // setStartDate((info?.requirements?.start?.seconds * 1000) ?? Date.now());
    // setEndDate((info?.requirements?.end?.seconds * 1000) ?? new Date((+new Date) + 2678400000));
    var openToggle = {};
    tucsData?.map((tucItem, tucIndex) => {
      openToggle[tucIndex] = tucItem?.open ?? false;
    });
    setOpenTucToVendors(openToggle);
    //Gets a dev's data to display on infotab's devProfile tab by default.
    const getDevForTab = async () => {
      var tempDevDocSnap = await getCollectionRefFromPath(
        `client/${info.clientId}/lists/${info.id}/tuc/${info.tuc[0].id}/POS-1`
      )
        .where(
          "status",
          "==",
          GetRefFromPath(
            `client/${info.clientId}/lists/${info.id}/resourceStatuses/PROPOSED`
          )
        )
        .limit(1)
        .get();
      if (tempDevDocSnap.docs.length == 0)
        tempDevDocSnap = await getCollectionRefFromPath(
          `client/${info.clientId}/lists/${info.id}/tuc/${info.tuc[0].id}/POS-1`
        )
          .where(
            "status",
            "==",
            GetRefFromPath(
              `client/${info.clientId}/lists/${info.id}/resourceStatuses/RECOMMENDED`
            )
          )
          .limit(1)
          .get();
      var dev = tempDevDocSnap.docs[0];
      var devData = dev?.data() ?? {};
      if (devData && dev?.exists) {
        devData["id"] = dev?.id;
        devData["ref"] = dev?.ref;
        var devRefData =
          (await getDocumentFromPath(
            `${devData?.devRef?.path}/devPublic/profile`
          )) ?? {};
        devRefData["id"] = devData?.devRef?.id;
        devData["devData"] = devRefData;
        devData["partnerData"] =
          (
            await devData?.partnerRef
              ?.collection("partnerPublic")
              ?.doc("profile")
              ?.get()
          )?.data() ?? {};
        devData["partnerData"]["ref"] = devData?.partnerRef;
        setShowDevProfile(devData);
      }
    };
    if (info) {
      // update CurrentStep to 1 if terms are already agreed and still if current step value is 0
      if (info?.requirements?.termsStatus && info?.currentStep == 0)
        updateCurrentStepIfTermsAgreed();
      getClientData();
      getDevForTab();
      db.collection(`client/${info.clientId}/Payment-Methods`).onSnapshot(
        async (pmColSnapShot) => {
          var tempPaymentMethods = [];
          Promise.all(
            pmColSnapShot.docs.map((pmDocSnapShot) => {
              var tempPmData = pmDocSnapShot?.data();
              tempPmData["ref"] = pmDocSnapShot.ref;
              tempPaymentMethods.push(tempPmData);
            })
          );
          setPaymentMethods(tempPaymentMethods);
        }
      );
      db.doc(`client/${info.clientId}/lists/${info.id}`).onSnapshot(
        async (snap) => {
          var listRefData = snap?.data() ?? {};
          listRefData["id"] = snap?.id;
          listRefData["ref"] = snap?.ref;
          setListData(listRefData);
          setTeamRef(listRefData?.confirmed?.teamRef);
          setStartDate(
            listRefData?.requirements?.start?.toDate() ?? Date.now()
          );
          setEndDate(
            listRefData?.requirements?.end?.toDate() ??
              new Date(+new Date() + 2678400000)
          );
        }
      );
      db.collection(`client/${info.clientId}/lists/${info.id}/log`)
        // .where("addedOn", ">=", GetNowTimeStamp())
        .onSnapshot(async (logSnap) => {
          var logs = [...(info.logs ?? [])];
          var tempLogAuthors = {};
          await Promise.all(
            logSnap?.docs?.map(async (logDocSnap) => {
              var logData = {};
              if (logDocSnap.exists) {
                logData = logDocSnap?.data();
                if (
                  logData?.author?.id !== "PROCESS" &&
                  !tempLogAuthors[logData?.author?.id]
                ) {
                  var dat = await logData?.author?.get();
                  tempLogAuthors[logData?.author?.id] = dat.data() ?? {};
                }
                logs.push(logData);
              }
            })
          );
          logs?.sort((a, b) => a?.addedOn - b?.addedOn);
          setLogsData(logs);
          setLogAuthors(tempLogAuthors);
        });
      db.collection(`client/${info.clientId}/lists/${info.id}/tuc`).onSnapshot(
        async (tucSnap) => {
          var tucs = [];
          // var tempRecommendedVendors = {};
          await Promise.all(
            tucSnap?.docs?.map(async (tucDocSnap, tucIndex) => {
              if (tucDocSnap.exists) {
                var tucDocSnapData = tucDocSnap?.data();
                tucDocSnapData["path"] = tucDocSnap?.ref?.path;
                tucDocSnapData["id"] = tucDocSnap?.id;
                tucs.push(tucDocSnapData);
              }
            })
          );
          tucs?.sort((a, b) => a?.addedOn - b?.addedOn);
          setTucsData(tucs);
          setSelectedTuc(tucs[0]);
          // setTucRecommendedVendors(tempRecommendedVendors);
        }
      );
      setInfoLoaded(true);
    }
  }, [info, selectedUserRole]);

  useEffect(() => {
    const getTucPositions = async () => {
      var temp = [];
      tucsData.map(async (tuc) => {
        var tempOption = {
          name: tuc.name,
          positions: [],
          tucPath: tuc["path"],
        };
        tuc.positions.map(async (position, index) => {
          tempOption["positions"].push({
            ...(await position?.title.get()).data(),
            ref: position?.title,
            posIndex: index + 1,
          });
        });
        // tempOption['positions'].sort((a,b) => a?.posIndex - b?.posIndex);
        temp.push(tempOption);
      });
      setAllTucPositions(temp);
    };
    if (tucsData.length) {
      setCanAddTuc(clientData?.listConfigs?.maxTUC > tucsData.length);
      getTucPositions();
      fetchRecommendationsOnReqPage(tucsData[0], 0, setPosRecommendations);
    }
  }, [tucsData]);

  useEffect(() => {
    setUserAccData(userAccountData);
    setUserAccountDataLoaded(true);
  }, [userAccountData]);

  useEffect(() => {
    if (teamRef)
      teamRef?.onSnapshot(async (snap) => {
        var teamRefData = snap?.data();
        var membersData = [];
        await Promise.all(
          teamRefData?.members?.map(async (dev) => {
            var devData = (await dev?.id?.get())?.data() ?? {};
            devData["docId"] = dev?.id?.id;
            info?.tuc?.map((tucMap) => {
              Object.values(tucMap?.posData ?? {})?.map((posMap) => {
                Object.values(posMap ?? {})?.map((posDevMap) => {
                  if (posDevMap?.["devRef"]?.id == devData["docId"])
                    devData["rates"] = posDevMap?.rates ?? [];
                });
              });
            });
            membersData.push(devData);
          })
        );
        teamRefData["membersData"] = membersData;
        setConfirmationData(teamRefData);
      });
  }, [teamRef]);

  function buildRatings(rating) {
    const ratings = [];
    for (let i = 0; i < rating; i++) {
      ratings.push(<MdStar className={styles.star} />);
    }
    for (let i = 0; i < 4 - rating; i++) {
      ratings.push(<MdStarBorder className={styles.star} />);
    }
    return ratings;
  }

  const mainRef = createRef();

  useEffect(() => {
    // listen to element height changes
    if (!option2Ref?.current) return;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setOptionsHeight(option2Ref?.current?.clientHeight);
      }
    });
    observer.observe(option2Ref?.current);
    return () => observer.disconnect();
  }, [option2Ref, selectedUserRole, listData?.id]);

  return (
    <>
      <UploadDev
        showUploadDevs={showUploadDevs}
        setShowUploadDevs={setShowUploadDevs}
        vendorRef={selectedPartner?.ref}
        devPositions={devPositions}
      />
      <UploadDev
        showUploadDevs={showUpdateDev}
        setShowUploadDevs={setShowUpdateDev}
        devData={devDataToUpdate}
        isUploadDev={false}
        vendorRef={selectedPartner?.ref}
        devPositions={devPositions}
      />
      {showCustomReq ? (
        <CustomReq
          setShowCustomReq={setShowCustomReq}
          path={`client/${info.clientId}/lists/${info.id}`}
          listData={listData}
          userType={userType}
          devPositions={devPositions}
        />
      ) : null}
      {showAlert ? <SuccessPopup message={alertMessage} /> : null}
      {userAccountDataLoaded && infoLoaded ? (
        <div className={styles.requirementsSteps}>
          {listData && (
            <FIrstStep
              listData={listData}
              setViewTerms={setViewTerms}
              logAction={logAction}
              onReqOptionClick={onReqOptionClick}
              userType={userType}
              userAccountData={userAccountData}
              setShowListOptions={setShowListOptions}
              loggedInUserData={loggedInUserData}
              selectedUserRole={selectedUserRole}
              userRolesAvailable={userRolesAvailable}
              expand={expand}
              setExpand={setExpand}
              setSelectedUserRole={setSelectedUserRole}

              // showListOptions={showListOptions}
            />
          )}

          <div className="_spReqTeam--OptionThird" ref={mainRef}>
            {(listData?.currentStep < 1 ||
              !listData?.requirements?.termsStatus) && (
              <div className="_spReqTeam--OptionThird_unconfirmed"></div>
            )}
            <StepInfo
              stepTitle={
                listData?.["steps"]?.[1]?.["meta"]?.[
                  selectedUserRole === "partner" ? "partner" : "client"
                ]?.["name"] ?? ""
              }
              canAddTuc={canAddTuc}
              dueDay={returnDaysFromNow(listData?.steps?.[1]?.end)}
              setShowCustomReq={setShowCustomReq}
              setShowListOptions={setShowListOptions}
              showListOptions={showListOptions}
              setOptionParms={setOptionParms}
              optionParms={optionParms}
              selectedUserRole={selectedUserRole}
            />
            {showListOptions && (
              <div
                className="_spReqTeam--OptionThird__details"
                ref={option2Ref}
              >
                <div className="_spReqTeam--OptionThird__details--left">
                  <div className="_spOptionsList" style={{ gap: "1rem" }}>
                    {/* <SingleOption /> */}
                    {tucsData?.map((item, index) => {
                      // if(selectedUserRole == 'partner' && !item?.partners?.includes(GetRefFromPath(selectedPartner?.ref?.parent?.parent?.path))) return;
                      return (
                        <div className="_spOptionsList-single">
                          <RequirementPriceCard
                            showActions={listData?.currentStep > 0}
                            info={info}
                            data={item}
                            currentStep={listData?.currentStep}
                            index={index}
                            clientId={info?.clientId}
                            listId={info?.id}
                            paidStatus={listData?.paidStatus}
                            path={`client/${info.clientId}/lists/${info.id}`}
                            listData={listData}
                            userType={userType}
                            devPositions={devPositions}
                            selectedUserRole={selectedUserRole}
                          />
                          {/* {userType === "ADMIN" && (
                            <ManageVendors
                              tucItem={item}
                              isAdmin={userType === "ADMIN"}
                              // recommendedVendors={tucRecommendedVendors[index]}
                              setSelectedPartner={setSelectedPartner}
                              emailTemplates={emailTemplates}
                              setShowMore={setShowPartnerMore}
                              showMore={showPartnerMore}
                              setSelectedTabIndex={setSelectedTabIndex}
                            />
                          )} */}
                        </div>
                      );
                    })}
                  </div>
                  {/* {userType === "ADMIN" && selectedUserRole == "admin" && (
                    <div className="_spVendorsList">
                      {tucsData?.map((item, index) => (
                        <ManageVendors
                          tucItem={item}
                          isAdmin={
                            userType === "ADMIN" && selectedUserRole === "admin"
                          }
                          loggedInUserData={loggedInUserData}
                          // recommendedVendors={tucRecommendedVendors[index]}
                          setSelectedPartner={setSelectedPartner}
                          emailTemplates={emailTemplates}
                          setShowMore={setShowPartnerMore}
                          showMore={showPartnerMore}
                          setSelectedTabIndex={setSelectedTabIndex}
                        />
                      ))}
                    </div>
                  )} */}

                  <div className="_spPositionsList">
                    {tucsData?.map((tucItem, tucIndex) => {
                      // if(selectedUserRole == 'partner' && !tucItem?.partners?.includes(GetRefFromPath(selectedPartner?.ref?.parent?.parent?.path))) return;
                      return (
                        <RequirementTeamListCard
                          setSelectedTuc={setSelectedTuc}
                          setPosRecommendations={setPosRecommendations}
                          showActions={listData?.currentStep > 0}
                          info={info}
                          tucItem={tucItem}
                          staffing={
                            listData?.["requirements"]?.["staffing"] ?? false
                          }
                          userAccData={userAccData}
                          logAction={logAction}
                          logAuthors={logAuthors}
                          userType={userType}
                          paidStatus={listData?.paidStatus}
                          devPositions={devPositions}
                          costFeeSum={costFeeSum}
                          priceFeeSum={priceFeeSum}
                          fixedCostFeeSum={fixedCostFeeSum}
                          fixedPriceFeeSum={fixedPriceFeeSum}
                          optionParms={optionParms}
                          setShowDevProfile={setShowDevProfile}
                          selectedUserRole={selectedUserRole}
                          selectedPartner={selectedPartner}
                          setSelectedTabIndex={setSelectedTabIndex}
                          partnerAccountData={partnerAccountData}
                          orgPosSpecs={orgPosSpecs}
                        />
                      );
                    })}
                  </div>
                </div>
                <InfoTabs
                  helpTabData={helpTabData}
                  posRecommendations={posRecommendations}
                  listData={listData}
                  maxValue={maxValue}
                  setMaxValue={setMaxValue}
                  endDate={endDate}
                  logsData={logsData}
                  logAuthors={logAuthors}
                  showDevProfile={showDevProfile}
                  setShowDevProfile={setShowDevProfile}
                  selectedTuc={selectedTuc}
                  selectedPartner={selectedPartner}
                  selectedPartnerDevs={selectedPartnerDevs}
                  selectedUserRole={selectedUserRole}
                  parentRef={option2Ref}
                  showMore={showPartnerMore}
                  allTucPositions={allTucPositions}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                  optionsHeight={optionsHeight}
                  tucsData={tucsData}
                  setShowUploadDevs={setShowUploadDevs}
                  showUpdateDev={showUpdateDev}
                  setShowUpdateDev={setShowUpdateDev}
                  devDataToUpdate={devDataToUpdate}
                  setDevDataToUpdate={setDevDataToUpdate}
                  setSelectedPartner={setSelectedPartner}
                  emailTemplates={emailTemplates}
                  loggedInUserData={loggedInUserData}
                />
              </div>
            )}
          </div>

          <div
            className={styles.priceCardCtr}
            style={{ marginBottom: "5px" }}
          ></div>
          <div
            className={`${styles.resourceCtr} ${styles.thin}`}
            style={{
              opacity: listData?.currentStep < 1 ? ".325" : "1",
              filter: listData?.currentStep < 1 ? "grayscale(100%)" : "none",
            }}
          ></div>
          {confirmationData?.membersData?.length &&
          selectedUserRole != "partner" ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "7.5px",
                }}
              >
                <h4 style={{ marginBottom: "0" }}>Confirm & Book</h4>
              </div>
              <div className={styles.confirmAndBookCtr}>
                <div
                  className={`${styles.resourceCtr} ${styles.thin}`}
                  style={{ overflowX: "visible", paddingRight: "0" }}
                >
                  <div
                    className={`${styles.requirementCard} ${styles.devs} ${styles.confirmed}`}
                  >
                    <div className={styles.confirmInfoCtr}>
                      <MdInfo
                        size={40}
                        style={{
                          fill: "#459df5",
                          marginRight: "3.5px",
                          marginTop: "-10px",
                          marginLeft: "8.5px",
                        }}
                      />
                      <p>
                        Below is a list of all of your confirmed resources. If
                        you are interested in continuing with this team, please
                        reserve it.
                      </p>
                    </div>
                    {tucsData?.map((tucItem, tucIndex) => {
                      return tucItem?.positions?.map((position, index) => {
                        return (
                          <ConfirmedDevsComponent
                            index={index}
                            tucItem={tucItem}
                            logAuthors={logAuthors}
                            userType={userType}
                          />
                        );
                      });
                    })}
                  </div>
                </div>
                <div
                  className={styles.confirmAndBook}
                  style={{
                    opacity: listData?.currentStep < 1 ? ".325" : "1",
                    filter:
                      listData?.currentStep < 1 ? "grayscale(100%)" : "none",
                    minHeight:
                      userType === "ADMIN" && selectedUserRole == "admin"
                        ? "415px"
                        : "371.5px",
                  }}
                >
                  <div className={styles.confirmAndBookTop}>
                    {/* <div className={styles.priceChip}>
                      <h4>50% DOWN</h4>
                  </div> */}
                    <h2>Reserve Now</h2>
                    <p>
                      Ready to get started? Reserve this team now by settling
                      the first and last sprints, followed by recurring payments
                      of 1 sprint every 2 weeks.
                    </p>
                  </div>
                  <div className={styles.confirmAndBookBottom}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={styles.checkCtr}>
                          <MdCheckCircle
                            size={19.5}
                            style={{ fill: "#459df5", marginRight: "7.5px" }}
                          />
                          <h4>
                            24/7 <span>Support</span>
                          </h4>
                        </div>
                        <div className={styles.checkCtr}>
                          <MdCheckCircle
                            size={19.5}
                            style={{ fill: "#459df5", marginRight: "7.5px" }}
                          />
                          <h4>
                            {confirmationData?.membersData?.length}{" "}
                            <span>Team members</span>
                          </h4>
                        </div>
                        <div className={styles.checkCtr}>
                          <MdCheckCircle
                            size={19.5}
                            style={{ fill: "#459df5", marginRight: "7.5px" }}
                          />
                          <h4>
                            Spryte Exclusive <span>Team</span>
                          </h4>
                        </div>
                      </div>
                      <p className={styles.note}>
                        At Spryte, clients have a no questions asked next sprint
                        cancellation clause. If you are unsatisfied at any time
                        with the quality of your Spryte team, you can simply
                        alert us to stop after the end of your next sprint.
                        Spryte partners are expected to deliver quality work,
                        every day. That's how we ensure a successful long-term
                        relationship, and successful projects.
                      </p>
                    </div>
                    <hr></hr>
                    <div className={styles.finalNextPriceCtr}>
                      <div className={styles.finalPriceCtr}>
                        <h2>
                          {listData?.confirmed?.teamPricePerHour?.length > 0
                            ? `$${(
                                Math.min(
                                  ...listData?.confirmed?.teamPricePerHour
                                ) *
                                80 *
                                2
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : "--/--"}
                        </h2>
                        <p>DUE NOW</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <MdWarning
                          style={{ opacity: ".85", fill: "#3e9416" }}
                        />
                        <p className={styles.due}>
                          Next payment due on:{" "}
                          <span style={{ fontWeight: "600" }}>
                            {nextDueDate}
                          </span>
                        </p>
                      </div>
                      {userType === "ADMIN" && selectedUserRole == "admin" ? (
                        listData?.paidStatus ? (
                          <h3> PAID </h3>
                        ) : (
                          <div>
                            {listData?.currentStep > 0 &&
                              listData?.confirmed?.teamPricePerHour.length >
                                0 && (
                                <button
                                  disabled={
                                    listData?.confirmed?.teamPricePerHour
                                      ?.length <= 0
                                  }
                                  onClick={() => {
                                    // router.push({
                                    //   pathname: "/checkout-sessions",
                                    //   query: {
                                    //     requestType: "payment",
                                    //     price: (
                                    //       Math.min(
                                    //         ...listData?.confirmed
                                    //           ?.teamPricePerHour
                                    //       ) *
                                    //       80 *
                                    //       2
                                    //     ).toFixed(2),
                                    //     subscriptionPrice: (
                                    //       Math.min(
                                    //         ...listData?.confirmed
                                    //           ?.teamPricePerHour
                                    //       ) * 80
                                    //     ).toFixed(2),
                                    //     teamId:
                                    //       listData?.confirmed?.teamRef?.id,
                                    //     teamSize:
                                    //       confirmationData?.membersData?.length,
                                    //     stripeId: clientData.stripeId,
                                    //     clientId: info.clientId,
                                    //     duration: 14,
                                    //     interval: "week",
                                    //     interval_count: 2,
                                    //     listId: listData.id,
                                    //   },
                                    // });
                                  }}
                                  type="submit"
                                >
                                  Pay Now
                                </button>
                              )}
                          </div>
                        )
                      ) : null}
                    </div>
                    {listData?.confirmed?.teamPricePerHour.length > 0 && (
                      <div className={styles.subPriceCtr}>
                        <div className={`${styles.subPrice}`}>
                          <h2>
                            $
                            {Math.min(...listData?.confirmed?.teamPricePerHour)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                          <p>/ per hour</p>
                        </div>
                        <div className={`${styles.subPrice}`}>
                          <h2>
                            $
                            {(
                              Math.min(
                                ...listData?.confirmed?.teamPricePerHour
                              ) * 80
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                          <p>/ per sprint</p>
                        </div>
                        <div className={`${styles.subPrice}`}>
                          <h2>
                            $
                            {(
                              Math.min(
                                ...listData?.confirmed?.teamPricePerHour
                              ) *
                              8 *
                              23
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                          <p>/ est. per month</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default RequirementsSteps;
