import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { ArrowBox, HeadTabWrapper, TabList } from "./style";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

/**
 * SpScrollableTab Component
 * A customizable scrollable tab component.
 *
 * Props:
 * - cls (string): Custom class name for styling.
 * - data (array): Items to be rendered within tabs.
 * - renderItem (function): Render function for each tab item.
 * - showArrows (boolean): Flag to show/hide navigation arrows. Default: true.
 * - renderArrowLeft (function): Custom render function for the left arrow.
 * - renderArrowRight (function): Custom render function for the right arrow.
 * - showLeftArrow (boolean): Flag to show/hide the left arrow. Default: true.
 * - showRightArrow (boolean): Flag to show/hide the right arrow. Default: true.
 * - onItemChange (function): Callback function when the selected item changes.
 * - tabListStyle (object): Custom styles for the tab list.
 *
 * Exposes:
 * - setSelectedIndex (function): Allows the parent component to set the selected index.
 * - getTabRef (function): Provides access to the tabs container DOM ref.
 * - selectedIndex (number): Current index of the selected tab, allowing the parent component to read it.
 *
 * Usage:
 * ```jsx
 * const App = () => {
 *   const tabRef = useRef();
 *
 *   useEffect(() => {
 *     console.log("Currently selected index:", tabRef.current.selectedIndex);
 *   }, []);
 *
 *   const handleChangeTab = () => {
 *     tabRef.current.setSelectedIndex(2); // Change selected tab index to 3
 *   };
 *
 *   return (
 *     <div>
 *       <SpScrollableTab ref={tabRef} data={[...]} renderItem={({ item }) => (<div>{item}</div>)} />
 *       <button onClick={handleChangeTab}>Change Tab</button>
 *     </div>
 *   );
 * };
 * ```
 */
const SpScrollableTab = forwardRef(
  (
    {
      cls,
      data = [],
      renderItem,
      showArrows = true,
      renderArrowLeft,
      renderArrowRight,
      showLeftArrow = true,
      showRightArrow = true,
      onItemChange,
      tabListStyle,
      ...props
    },
    ref
  ) => {
    const tabsContainerRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    // Use `useImperativeHandle` to expose specific properties and methods to parent components
    useImperativeHandle(ref, () => ({
      // Allow external manipulation of selectedIndex
      setSelectedIndex,
      // Provide direct access to the tab container's ref
      getTabRef: () => tabsContainerRef.current,
      selectedIndex, // Expose selectedIndex for read access
    }));

    // Ref to store the previous teams array
    // const prevTeamsRef = useRef();
    useEffect(() => {
      const handleResize = () => {
        const containerWidth = tabsContainerRef.current.offsetWidth;
        const maxVisibleTabs = Math.floor(containerWidth / 220); // assuming each tab is 200px wide
        // console.log(maxVisibleTabs);
        // setVisibleTabs(project?.teams?.slice(0, maxVisibleTabs));
      };

      window.addEventListener("resize", handleResize);
      handleResize(); // initial call to set visible tabs

      // Function to determine if a new team has been added
      // const hasNewTeamBeenAdded = () => {
      //   const currentTeams = project?.teams;
      //   const prevTeams = prevTeamsRef.current;
      //   return currentTeams?.length > prevTeams?.length;
      // };

      // If there's a new team, select it and scroll into view
      // if (hasNewTeamBeenAdded()) {
      //   const newTeamIndex = project.teams.length - 1;
      //   setSelectedIndex(newTeamIndex); // Select the new team
      //   prevTeamsRef.current = project.teams; // Update the ref with the new teams array
      // }

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [data]);

    // Adjusting the useEffect hook that handles scrollIntoViewIfNeeded
    useEffect(() => {
      const scrollIntoViewIfNeeded = () => {
        const container = tabsContainerRef.current;
        const elementToScrollTo = container?.children[selectedIndex];
        if (onItemChange) {
          onItemChange({
            index: selectedIndex,
            item: data[selectedIndex],
            containerRef: tabsContainerRef.current,
          });
        }
        if (elementToScrollTo) {
          // Calculate the position to scroll to for better centering
          const elementStart = elementToScrollTo.offsetLeft;
          const elementEnd = elementStart + elementToScrollTo.offsetWidth;
          const containerScrollStart = container.scrollLeft;
          const containerCenter =
            containerScrollStart + container.offsetWidth / 2;

          // Determine if element is out of view and needs scrolling
          let newScrollPosition = containerScrollStart;
          if (elementStart < containerScrollStart) {
            newScrollPosition =
              elementStart -
              container.offsetWidth / 2 +
              elementToScrollTo.offsetWidth / 2;
          } else if (
            elementEnd >
            containerScrollStart + container.offsetWidth
          ) {
            newScrollPosition =
              elementEnd -
              container.offsetWidth +
              container.offsetWidth / 2 -
              elementToScrollTo.offsetWidth / 2;
          }

          container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
        }
      };

      scrollIntoViewIfNeeded(); // Call the function to scroll to the selected index
    }, [selectedIndex]); // Triggered every time selectedIndex changes

    const handleScroll = (direction) => {
      const container = tabsContainerRef.current;
      if (direction === "right") {
        container.scrollLeft += 220;
      } else {
        container.scrollLeft -= 220;
      }
    };

    const handleNext = () => {
      setSelectedIndex((prev) => (prev + 1) % data.length);
    };

    const handlePrev = () => {
      setSelectedIndex((prev) => (prev - 1 + data.length) % data.length);
    };

    return (
      <HeadTabWrapper className={`_spScrollableTab ${cls}`} {...props}>
        {selectedIndex !== 0 &&
          showArrows &&
          showLeftArrow &&
          !renderArrowLeft && (
            <ArrowBox className={`_spScrollableTab--arrowBox ${cls}--arrowBox`}>
              <ArrowLeftIcon
                sx={{
                  fontSize: "20px",
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={() => handlePrev()}
              />
            </ArrowBox>
          )}

        {showArrows &&
          showLeftArrow &&
          renderArrowLeft &&
          renderArrowLeft({ handlePrev })}

        <TabList
          className={`_spScrollableTab--tabList ${cls}--tabList`}
          ref={tabsContainerRef}
          style={tabListStyle}
        >
          {data.map((item, index) =>
            renderItem({ item, index, setSelectedIndex, selectedIndex })
          )}
        </TabList>
        {selectedIndex < data?.length - 1 &&
          showArrows &&
          showRightArrow &&
          !renderArrowRight && (
            <ArrowBox
              className={`_spScrollableTab--arrowBox ${cls}--arrowBox`}
              onClick={() => handleNext()}
            >
              <ArrowRightIcon
                sx={{
                  fontSize: "20px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
            </ArrowBox>
          )}
        {showArrows &&
          showRightArrow &&
          renderArrowRight &&
          renderArrowRight({ handleNext })}
      </HeadTabWrapper>
    );
  }
);

export default SpScrollableTab;
