import React from "react";

const InterviewIcon = ({ size = 13, fill = "#1B16FF" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7139_15374)">
        <path
          d="M11.5199 4.74644H7.3255C7.33995 4.6697 7.34756 4.59143 7.34756 4.5117V1.93501C7.34756 1.23849 6.78092 0.671875 6.08443 0.671875H1.26285C0.56649 0.671875 0 1.23849 0 1.93501V4.5117C0 5.20804 0.56649 5.77453 1.26285 5.77453H2.00479L1.96475 6.70733C1.96115 6.7915 2.00479 6.87064 2.07792 6.91246C2.11263 6.93231 2.1512 6.94219 2.18976 6.94219C2.23239 6.94219 2.27497 6.93008 2.31212 6.90603L4.06042 5.7745H4.28193C4.23617 5.91697 4.21119 6.06865 4.21119 6.2261V9.34092C4.21119 10.1568 4.87497 10.8206 5.6909 10.8206H6.33019L7.73972 12.2602C7.78281 12.3042 7.84111 12.3278 7.90062 12.3278C7.92563 12.3278 7.95085 12.3236 7.97527 12.315C8.05779 12.286 8.11604 12.2119 8.12467 12.1248L8.25409 10.8206H11.52C12.3361 10.8206 13 10.1568 13 9.34092V6.22612C13 5.4102 12.336 4.74644 11.5199 4.74644ZM4.51902 5.32415H3.99387C3.95045 5.32415 3.90797 5.33669 3.87151 5.3603L2.43341 6.29107L2.46485 5.55901C2.46749 5.49767 2.44494 5.43792 2.40249 5.39357C2.36001 5.34921 2.30128 5.32415 2.23988 5.32415H1.26288C0.814861 5.32415 0.450354 4.95969 0.450354 4.5117V1.93501C0.450354 1.48684 0.814861 1.12225 1.26285 1.12225H6.08448C6.53265 1.12225 6.89724 1.48686 6.89724 1.93501V4.5117C6.89724 4.59236 6.88558 4.6709 6.86271 4.74644H5.69088C5.21427 4.74644 4.78987 4.97315 4.51902 5.32415ZM12.5497 9.34092C12.5497 9.9085 12.0877 10.3703 11.52 10.3703H8.0501C7.93434 10.3703 7.83743 10.458 7.826 10.5733L7.72411 11.6005L6.58574 10.4379C6.54342 10.3947 6.4854 10.3703 6.42487 10.3703H5.69093C5.12335 10.3703 4.66157 9.90853 4.66157 9.34094V6.22612C4.66157 5.65854 5.12335 5.19679 5.69093 5.19679H11.52V5.19676C12.0878 5.19676 12.5497 5.65852 12.5497 6.2261V9.34092H12.5497Z"
          fill={fill}
        />
        <path
          d="M4.65451 2.38119C4.62389 1.88829 4.22909 1.49349 3.73618 1.46289C3.46178 1.44593 3.20079 1.53978 3.00115 1.72741C2.80427 1.91243 2.69141 2.17342 2.69141 2.44345C2.69138 2.56787 2.79216 2.66867 2.91657 2.66867C3.04098 2.66867 3.14176 2.56784 3.14176 2.44348C3.14176 2.29504 3.20135 2.15733 3.30957 2.05559C3.41768 1.95397 3.55931 1.90311 3.70825 1.91236C3.97051 1.92863 4.18869 2.14681 4.20499 2.40905C4.21728 2.60715 4.12003 2.7944 3.95116 2.89779C3.63655 3.09038 3.44871 3.4232 3.44871 3.78812C3.44871 3.91248 3.54951 4.0133 3.6739 4.0133C3.79828 4.0133 3.89909 3.91248 3.89909 3.78812C3.89909 3.58121 4.00646 3.392 4.1863 3.2819C4.49772 3.09129 4.67716 2.74613 4.65451 2.38119Z"
          fill={fill}
        />
        <path
          d="M8.60507 6.10547C8.48068 6.10547 8.37988 6.2063 8.37988 6.33066V8.23655C8.37988 8.36092 8.48071 8.46174 8.60507 8.46174C8.72946 8.46174 8.83026 8.36092 8.83026 8.23655V6.33066C8.83024 6.2063 8.72946 6.10547 8.60507 6.10547Z"
          fill={fill}
        />
        <path
          d="M8.60507 9.46991C8.72944 9.46991 8.83026 9.36909 8.83026 9.24472C8.83026 9.12035 8.72944 9.01953 8.60507 9.01953C8.4807 9.01953 8.37988 9.12035 8.37988 9.24472C8.37988 9.36909 8.4807 9.46991 8.60507 9.46991Z"
          fill={fill}
        />
        <path
          d="M3.67343 4.84882C3.7978 4.84882 3.89862 4.748 3.89862 4.62363C3.89862 4.49926 3.7978 4.39844 3.67343 4.39844C3.54906 4.39844 3.44824 4.49926 3.44824 4.62363C3.44824 4.748 3.54906 4.84882 3.67343 4.84882Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7139_15374">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InterviewIcon;
