import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { colRef, docRef, GetNowTimeStamp } from "../../../../globals/services";
import { SpDialog } from "../../../../globals/SpDialog/dialogWrapper/SpDialog";
import SpLoadingBtn from "../../../../SpLoadingBtn";

const degLevel = [
  { level: "High School" },
  { level: "Prep School" },
  { level: "BootCamp" },
  { level: "B.S." },
  { level: "B.A." },
  { level: "M.A." },
  { level: "M.S." },
  { level: "M.B.A" },
  { level: "Ph.D." },
];

const start = "Year";
const depth = "Year";
const format = "MMMM y";
const dateValue = new Date();

const status = [
  { statusValue: true, statusText: "Active" },
  { statusValue: false, statusText: "Inactive" },
];

const initialState = {
  degName: "",
  degLevel: "",
  country: "",
  description: "",
  gradDate: new Date(),
  school: "",
};
const EducationModal = ({
  action = "add",
  selectedEd = null,
  open,
  onClose,
  devId,
}) => {
  const [educationData, setEducationData] = useState(initialState);

  useEffect(() => {
    if (action === "edit" && selectedEd) {
      setEducationData(selectedEd);
    }
  }, [action, selectedEd?.id]);

  const [loading, setLoading] = useState(false);

  const onChanges = (e) => {
    setEducationData({ ...educationData, [e.target.id]: e.target.value });
  };

  const isDisabled = useMemo(() => {
    if (
      !educationData.degName ||
      !educationData.degLevel ||
      !educationData.country ||
      !educationData.gradDate ||
      !educationData.school
    ) {
      return true;
    }
    return false;
  }, [educationData]);

  const onSave = async () => {
    if (!devId) return;
    setLoading(true);
    if (action === "add") {
      educationData["addedOn"] = GetNowTimeStamp();
      await colRef(`devs/${devId}/education`).add(educationData);
    }
    if (action === "edit") {
      const { id, ref, ...rest } = educationData;
      await docRef(`devs/${devId}/education/${id}`).update(rest);
    }
    // Updating lastUpdated for the dev
    await docRef(`devs/${devId}`).update({ lastUpdated: GetNowTimeStamp() });
    setLoading(false);
    setEducationData(initialState);
    onClose();
  };

  const onModalClose = useCallback(() => {
    setEducationData(initialState);
    onClose();
  }, [onClose]);

  return (
    <SpDialog open={open} onClose={onModalClose} locked>
      <div className="modal-header">
        <h5
          className="modal-title"
          id="exampleModalLabel"
          style={{
            fontSize: "1.8rem",
            fontWeight: "600",
          }}
        >
          {action === "add" ? "Add Education" : "Edit Education"}
        </h5>
      </div>
      <div className="modal-body">
        <form
          style={{
            display: "grid",
            gap: "10px",
          }}
        >
          <div className="form-group">
            <label htmlFor="school">
              School<span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="school"
              placeholder="School Name"
              value={educationData.school}
              onChange={onChanges}
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">
              Country<span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              placeholder="Country"
              value={educationData.country}
              onChange={onChanges}
            />
          </div>
          <div className="form-group">
            <label htmlFor="degName">
              Degree Name<span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="degName"
              placeholder="Degree Name"
              value={educationData.degName}
              onChange={onChanges}
            />
          </div>
          <div className="form-group">
            <label htmlFor="degLevel">
              Degree Level<span>*</span>
            </label>
            <select
              className="form-control"
              id="degLevel"
              value={educationData.degLevel}
              onChange={onChanges}
            >
              <option value="">Select Degree Level</option>
              {degLevel.map((deg, index) => (
                <option key={index} value={deg.level}>
                  {deg.level}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="gradDate">
              Graduation Date<span>*</span>
            </label>
            <DatePickerComponent
              id="gradDate"
              name="gradDate"
              value={educationData.gradDate}
              start={start}
              depth={depth}
              format={format}
              change={(e) => {
                setEducationData({
                  ...educationData,
                  gradDate: e.value,
                });
              }}
            ></DatePickerComponent>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              id="description"
              rows="3"
              value={educationData.description}
              onChange={onChanges}
            ></textarea>
          </div>
        </form>
      </div>
      <div
        className="modal-footer sp_row"
        style={{
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onModalClose}
        >
          Close
        </button>

        <SpLoadingBtn
          btnClicked={onSave}
          btnText="Save changes"
          disabled={isDisabled}
          loading={loading}
          loadingText="Saving..."
        />
      </div>
    </SpDialog>
  );
};

export default EducationModal;
