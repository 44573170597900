import React, { Fragment, useContext, useState } from "react";
import {
  AuthUserContext,
  FirebaseContext,
  GlobalVendorContex,
} from "./Firebase";

import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import {
  faCodeBranch,
  faCubes,
  faPlane,
  faTasks,
  faMoneyBill,
  faGraduationCap,
  faCalendarCheck,
  faSignOutAlt,
  faSearchDollar,
  faBriefcase,
  faProjectDiagram,
  faBrain,
  faMagnifyingGlass,
  faPerson,
  faPortrait,
} from "@fortawesome/free-solid-svg-icons";
// import { analytics } from "./Firebase/firebase";
import FacadeProfileCard from "./navbar/FacadeProfileCard";
import navLogo from "./images/nav-logo.png";
import NonLoggedNav from "./Layout/Navigations/NonLoggedNav";
import {
  AccountCircle,
  AccountTree,
  ArrowBack,
  EventAvailable,
  InsertDriveFile,
  MonetizationOn,
  VerifiedUser,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRouteMatch = useMatch("/resources/:id");

  const firebase = useContext(FirebaseContext);
  const vendorData = useContext(GlobalVendorContex);
  const [hoverAccount, setHoverAccount] = useState(false);
  const [hoverRequirements, setHoverRequirements] = useState(false);
  const [hoverMarkets, setHoverMarkets] = useState(false);
  const [hoverBranding, setHoverBranding] = useState(false);
  const [hoverResources, setHoverResources] = useState(false);
  const [hoverAvailability, setHoverAvailability] = useState(false);
  const [hoverProjects, setHoverProjects] = useState(false);
  const [hoverDocuments, setHoverDocuments] = useState(false);
  const [hoverCertification, setHoverCertification] = useState(false);
  const [hoverLogout, setHoverLogout] = useState(false);

  // destructures props
  const { toggleNavbar, setToggleNavbar } = props;

  // toggles left sidebar on click
  const toggleNav = () => {
    // 👇️ passed function to setState
    setToggleNavbar((current) => !current);
  };

  // on mouse leave, icons labels are not shown
  const handleMouseOut = () => {
    setHoverAccount(false);
    setHoverRequirements(false);
    setHoverMarkets(false);
    setHoverBranding(false);
    setHoverResources(false);
    setHoverAvailability(false);
    setHoverProjects(false);
    setHoverDocuments(false);
    setHoverCertification(false);
    setHoverLogout(false);
  };

  let devName = localStorage.getItem("devName");

  //logout vendor
  const logoutVendor = async (authUser) => {
    const vendor = authUser.vendor;
    // console.log("authUser", authUser);
    //create the update key value,
    //to create or update(if existingt)
    //lastLogOut in the vendor and user doc
    let updates = {};
    updates["lastLogOut"] = new Date();
    console.log("vendor", vendor);
    if (vendor?.id) await firebase.updateVendor(vendor.id, updates);
    const uid = authUser?.userId;

    await firebase.updateUserPartnerAccount(uid, updates);

    //logout the vendor
    firebase
      .doSignOut()
      .then((r) => {
        if (devName) {
          localStorage.removeItem("devName");
        }

        props.setIsDev(false);
        // navigate to /
        navigate("/");
        //Track Analytics
        // analytics.track("User Logout", {});
      })
      .catch((err) => {});
  };

  //Logout the dev page
  const logoutDev = async (authUser) => {
    //get the dev id from the local storage
    let docId = localStorage?.getItem("dev") || authUser?.devRef?.id;

    // console.log("docId", docId);
    // console.log("authUser", authUser);

    //create the update key value, to create or update(if existingt) lastLogOut in the dev object
    let updates = {};
    updates["lastLogOut"] = new Date();
    await firebase.updateDev(docId, updates);
    firebase.doSignOut().then((r) => {
      //remove the devId from the local storage
      localStorage.removeItem("dev");
      localStorage.removeItem("devName");
      props.setIsDev(false);
      authUser?.onUpddatState("isDevSelfReg", false);
    });
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div
          className={toggleNavbar ? "nav-collapse nav" : "nav"}
          id="sp_navbar"
        >
          <div
            className={
              toggleNavbar ? "nav-content-collapse nav-content" : "nav-content"
            }
            id="content"
          >
            <div style={{ display: "flex" }}>
              <img
                src={navLogo}
                alt="Spryte Logo"
                width="25"
                height="25"
                className={
                  toggleNavbar ? "spryte-logo toggleBtn" : "spryte-logo"
                }
                onClick={toggleNav}
              />
              {toggleNavbar ? (
                <></>
              ) : (
                <div className="nav-logo">
                  <h2>Spryte Partner</h2>
                </div>
              )}
            </div>
            <div className="content">
              {!authUser.auth ? (
                <NonLoggedNav
                  setIsDev={props.setIsDev}
                  toggleNavbar={toggleNavbar}
                />
              ) : authUser.vendorRef &&
                !isRouteMatch &&
                authUser.userPartnerAccountDoc.status ? (
                <>
                  <Link to={"/"}>
                    <AccountCircle
                      onMouseOver={() => setHoverAccount(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Account
                    </span>
                    {toggleNavbar && hoverAccount && <small>Account</small>}
                  </Link>
                  <Link to={"/requirements"}>
                    <MonetizationOn
                      onMouseOver={() => setHoverRequirements(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Open Requirements
                    </span>
                    {toggleNavbar && hoverRequirements && (
                      <small>Open Requirements</small>
                    )}
                  </Link>
                  <Link to={"/markets"}>
                    <FontAwesomeIcon
                      icon={faSearchDollar}
                      onMouseOver={() => setHoverMarkets(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Markets
                    </span>
                    {toggleNavbar && hoverMarkets && <small>Markets</small>}
                  </Link>
                  <Link to={"/branding"}>
                    <InsertDriveFile
                      onMouseOver={() => setHoverBranding(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Branding
                    </span>
                    {toggleNavbar && hoverBranding && <small>Branding</small>}
                  </Link>
                  <Link to={"/resources"}>
                    <AccountTree
                      onMouseOver={() => setHoverResources(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Resources
                    </span>
                    {toggleNavbar && hoverResources && <small>Resources</small>}
                  </Link>
                  <Link to={"/availability"}>
                    <EventAvailable
                      onMouseOver={() => setHoverAvailability(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Availability
                    </span>
                    {toggleNavbar && hoverAvailability && (
                      <small>Availability</small>
                    )}
                  </Link>
                  <Link to={"/projects"}>
                    <FontAwesomeIcon
                      icon={faTasks}
                      className="icon"
                      onMouseOver={() => setHoverProjects(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Projects
                    </span>
                    {toggleNavbar && hoverProjects && <small>Projects</small>}
                  </Link>
                  <Link to={"/contract"}>
                    <InsertDriveFile
                      onMouseOver={() => setHoverDocuments(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Documents
                    </span>
                    {toggleNavbar && hoverDocuments && <small>Documents</small>}
                  </Link>

                  <Link to={"/certification"}>
                    <VerifiedUser
                      onMouseOver={() => setHoverCertification(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Certification
                    </span>
                    {toggleNavbar && hoverCertification && (
                      <small>Certification</small>
                    )}
                  </Link>

                  <Link to={"/"} onClick={(e) => logoutVendor(authUser)}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      onMouseOver={() => setHoverLogout(true)}
                      onMouseOut={handleMouseOut}
                    />
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar logout" : "logout"
                      }
                    >
                      Logout
                    </span>
                    {toggleNavbar && hoverLogout && <small>Logout</small>}
                  </Link>
                  {authUser.isSuperPartner ? (
                    <div
                      className={
                        toggleNavbar ? "toggleNavbar" : "admin_container"
                      }
                    >
                      <h4 className="admin">Admin</h4>
                      <Link to={"/indexitems"}>
                        <span
                          className={
                            toggleNavbar ? "toggleNavbar admin" : "admin"
                          }
                        >
                          Index Items
                        </span>
                      </Link>
                      <Link to={"/datapoints"}>
                        <span
                          className={
                            toggleNavbar ? "toggleNavbar admin" : "admin"
                          }
                        >
                          Data Points
                        </span>
                      </Link>
                      <Link to={"/collection-management"}>
                        <span
                          className={
                            toggleNavbar ? "toggleNavbar admin" : "admin"
                          }
                        >
                          Collections
                        </span>
                      </Link>
                      <Link to={"/partners"}>
                        <span
                          className={
                            toggleNavbar ? "toggleNavbar admin" : "admin"
                          }
                        >
                          Partners
                        </span>
                      </Link>
                      <Link to={"/all-resources"}>
                        <span
                          className={
                            toggleNavbar ? "toggleNavbar admin" : "admin"
                          }
                        >
                          All Resources
                        </span>
                      </Link>
                    </div>
                  ) : null}
                  <FacadeProfileCard
                    authUser={authUser}
                    vendor={vendorData.vendor}
                    firebase={firebase}
                    toggleNavbar={toggleNavbar}
                  />
                </>
              ) : isRouteMatch ? (
                <>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      props.setShowDevData(false);
                      navigate(-1);
                    }}
                  >
                    <ArrowBack style={{ color: "#1E96F5" }} />{" "}
                    <span
                      className={toggleNavbar ? "toggleNavbar back" : "back"}
                    >
                      Back to all resources
                    </span>
                  </div>
                  <Link
                    to={location.pathname}
                    onClick={(e) => {
                      props.setDevPage("dev");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      maxWidth: "185px",
                      overflow: "hidden",
                      textAlign: "left",
                    }}
                  >
                    {/* profile icon */}
                    <FontAwesomeIcon
                      icon={faPortrait}
                      className="icon"
                      style={{ marginRight: "5px", marginTop: "32px" }}
                    />
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar dev_name" : "dev_name"
                      }
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 3,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {vendorData?.devName}
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("devRates")}
                  >
                    <FontAwesomeIcon icon={faMoneyBill} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Rates
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("techs")}
                  >
                    <FontAwesomeIcon icon={faCubes} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Techstacks
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("domain")}
                  >
                    <FontAwesomeIcon icon={faBrain} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Domains
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("project")}
                  >
                    <FontAwesomeIcon icon={faCodeBranch} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Project Types
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("vacation")}
                  >
                    <FontAwesomeIcon icon={faPlane} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Vacations
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("avail")}
                  >
                    <FontAwesomeIcon icon={faCalendarCheck} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Availability
                    </span>
                  </Link>

                  <Link
                    to={location.pathname}
                    onClick={() => props.setDevPage("matches")}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="icon"
                    />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Matches
                    </span>
                  </Link>
                </>
              ) : props.showDevData && authUser.vendorRef && props.isAllDevs ? (
                <>
                  <Link
                    to={"/all-resources"}
                    onClick={(e) => {
                      props.setShowDevData(false);
                      props.setIsAllDev(false);
                    }}
                  >
                    <ArrowBack style={{ color: "#1E96F5" }} />{" "}
                    <span
                      className={toggleNavbar ? "toggleNavbar back" : "back"}
                    >
                      Back to all all-resources
                    </span>
                  </Link>
                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("dev")}
                  >
                    <FontAwesomeIcon
                      icon={faPortrait}
                      className="icon"
                      style={{ marginRight: "5px", marginTop: "32px" }}
                    />
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar dev_name" : "dev_name"
                      }
                    >
                      {devName}
                    </span>
                  </Link>

                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("techs")}
                  >
                    <FontAwesomeIcon icon={faCubes} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Techstacks
                    </span>
                  </Link>

                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("domain")}
                  >
                    <FontAwesomeIcon icon={faBrain} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Domains
                    </span>
                  </Link>

                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("project")}
                  >
                    <FontAwesomeIcon icon={faCodeBranch} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Project Types
                    </span>
                  </Link>

                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("vacation")}
                  >
                    <FontAwesomeIcon icon={faPlane} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Vacations
                    </span>
                  </Link>

                  <Link
                    to={"/all-resources"}
                    onClick={() => props.setDevPage("avail")}
                  >
                    <FontAwesomeIcon icon={faCalendarCheck} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Availability
                    </span>
                  </Link>
                </>
              ) : props.showDevData &&
                authUser.vendorRef &&
                !props.isVerifiedResource &&
                !props.isAllDevs ? (
                <>
                  <Link
                    to={"/resources"}
                    onClick={(e) => {
                      props.setShowDevData(false);
                      vendorData?.setDevName("");
                    }}
                  >
                    <ArrowBack style={{ color: "#1E96F5" }} />{" "}
                    <span
                      className={toggleNavbar ? "toggleNavbar back" : "back"}
                    >
                      Back to all resources
                    </span>
                  </Link>
                  <Link to={"/resources"} onClick={() => {}}>
                    <FontAwesomeIcon
                      icon={faPortrait}
                      className="icon"
                      style={{ marginRight: "5px", marginTop: "32px" }}
                    />
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar dev_name" : "dev_name"
                      }
                    >
                      {vendorData?.devName}
                    </span>
                  </Link>
                </>
              ) : authUser.devRef ? (
                <>
                  <Link
                    to={"/"}
                    onClick={() => props.setDevPage("dev")}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      maxWidth: "185px",
                      overflow: "hidden",
                      textAlign: "left",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPortrait}
                      className="icon"
                      style={{ marginRight: "5px", marginTop: "32px" }}
                    />
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar dev_name" : "dev_name"
                      }
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 3,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {vendorData?.devName}
                    </span>
                  </Link>
                  <Link to={"/"} onClick={() => props.setDevPage("techs")}>
                    <FontAwesomeIcon icon={faCubes} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Techstacks
                    </span>
                  </Link>
                  <Link to={"/"} onClick={() => props.setDevPage("domain")}>
                    <FontAwesomeIcon icon={faBrain} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Domains
                    </span>
                  </Link>
                  <Link to={"/"} onClick={() => props.setDevPage("project")}>
                    <FontAwesomeIcon icon={faCodeBranch} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Project Types
                    </span>
                  </Link>
                  <Link to={"/"} onClick={() => props.setDevPage("vacation")}>
                    <FontAwesomeIcon icon={faPlane} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Vacations
                    </span>
                  </Link>
                  <Link to={"/"} onClick={() => props.setDevPage("avail")}>
                    <FontAwesomeIcon icon={faCalendarCheck} className="icon" />
                    <span className={toggleNavbar ? "toggleNavbar" : ""}>
                      Availability
                    </span>
                  </Link>
                  <Link
                    to={"/"}
                    onClick={(e) => {
                      logoutDev(authUser);
                    }}
                  >
                    <span
                      className={
                        toggleNavbar ? "toggleNavbar logout" : "logout"
                      }
                    >
                      Logout
                    </span>
                  </Link>
                </>
              ) : (
                <Link
                  to={"/"}
                  onClick={(e) => {
                    localStorage.removeItem("dev");
                    firebase.doSignOut();
                    props.setIsDev(false);
                  }}
                >
                  <span
                    className={toggleNavbar ? "toggleNavbar logout" : "logout"}
                  >
                    Logout
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default NavBar;
