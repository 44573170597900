import React from "react";

function SpStartSVG({ startColor = "#3A0C9E", width = "26", height = "25" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1838 1.10611C12.5823 0.543148 13.4177 0.543149 13.8162 1.10611L17.8241 6.76777C17.9509 6.947 18.1333 7.07949 18.343 7.14478L24.966 9.20693C25.6246 9.41198 25.8827 10.2064 25.4705 10.7594L21.3244 16.3207C21.1931 16.4967 21.1235 16.7111 21.1262 16.9307L21.2116 23.8668C21.2201 24.5565 20.5443 25.0475 19.891 24.8263L13.3207 22.6017C13.1127 22.5313 12.8873 22.5313 12.6793 22.6017L6.10903 24.8263C5.45572 25.0475 4.77991 24.5565 4.7884 23.8668L4.87381 16.9307C4.87651 16.7111 4.80686 16.4967 4.6756 16.3207L0.529544 10.7594C0.117285 10.2064 0.375423 9.41198 1.03398 9.20693L7.65703 7.14478C7.86671 7.07949 8.04906 6.947 8.17594 6.76777L12.1838 1.10611Z"
        fill={startColor}
      />
    </svg>
  );
}

export default SpStartSVG;
