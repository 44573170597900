import { useEffect, useState } from "react";
import { colRef } from "../globals/services";

export const useCurrentVendorHolidays = (vendorId) => {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (!vendorId) return;
    let dataSna = colRef(`vendors/${vendorId}/holidays`)
      .orderBy("to", "desc")
      .onSnapshot((snap) => {
        let vendorHolidays = snap?.docs?.map((holidayDoc) => {
          const { from, to, ...data } = holidayDoc.data();
          return {
            // id : data.id,
            description: data.description,
            from: from.toDate(),
            to: to.toDate(),
            Id: holidayDoc.id,
          };
        });
        setHolidays(vendorHolidays);
      });
    return dataSna;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);
  return { vendorHolidays: holidays };
};
