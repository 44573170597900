import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

function ItemsList({ indexes = [], dispatch, setisRowSelected, initialState }) {
  const editSettings = {
    allowDeleting: true,
    showDeleteConfirmDialog: true,
  };

  const onRowDeselected = (args) => {
    setisRowSelected(false);
    dispatch({ type: "RESET", data: initialState });
  };

  const onRowSelected = (args) => {
    setisRowSelected(true);
    const {
      max,
      min,
      count,
      stats,
      wFactor,
      dataPointsKeys,
      form,
      isLetter,
      ...data
    } = args.data;
    data["letterGrade"] = isLetter === "Yes" ? true : false;
    data["formTitle"] = form?.title ?? "";
    data["formDesc"] = form?.desc ?? "";
    data["formSub"] = form?.subTitle ?? "";
    dispatch({ type: "RESET", data: data });
  };

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const { ref } = args?.data?.[0];
      if (!ref) return;
      ref.delete();
      dispatch({ type: "RESET", data: initialState });
    }
  };

  return (
    <div>
      <GridComponent
        dataSource={indexes}
        editSettings={editSettings}
        toolbar={["Delete"]}
        actionComplete={actionComplete}
        rowSelected={onRowSelected}
        rowDeselected={onRowDeselected}
        clipMode="EllipsisWithTooltip"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="Key"
            width="80"
          ></ColumnDirective>
          <ColumnDirective
            field="label"
            headerText="Label"
            width="160"
            wrapperText={false}
            allowTextWrap={false}
          ></ColumnDirective>
          <ColumnDirective
            field="desc"
            headerText="Description"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="formDesc"
            headerText="Form Description"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="formSub"
            headerText="Form Sub-Title"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="formTitle"
            headerText="Form Title"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="isLetter"
            headerText="Letter Grade"
            width="100"
          ></ColumnDirective>

          <ColumnDirective
            field="dataPointsKeys"
            headerText="Data Points"
            width="100"
            // editType="datepickeredit"
            // format="MMMM y"
            // defaultValue={new Date()}
          ></ColumnDirective>
          <ColumnDirective
            field="count"
            headerText="Count"
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="max"
            headerText="Max"
            width="100"
            // template={currencyTemplate}
          ></ColumnDirective>
          <ColumnDirective
            field="min"
            headerText="Min"
            // template={currencyTemplate}
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="wFactor"
            headerText="Weight Factor"
            width="130"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Toolbar, Edit]} />
      </GridComponent>
    </div>
  );
}

export default ItemsList;
