import React from "react";
import styled from "styled-components";

export const Button = styled.button`
  background-color: ${({ bg, showbg }) =>
    showbg ? bg : "transparent"} !important;
  border: ${({ border, showbg }) =>
    !showbg ? `2px solid ${border}` : "none"} !important;
  color: ${({ color, showbg, bg }) =>
    showbg ? `${color ?? "white"}` : bg || color} !important;
  font-weight: 700;
  border-radius: 6px;
  padding: 10px;
  &:hover {
    filter: brightness(95%);
    color: white;
    background-color: ${({ showbg }) =>
      !showbg && "rgba(0,0,0,0.1)"} !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  }
  &:active {
    transform: scale(0.9);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 8px;
  }
  &:disabled {
    background-color: #e0e0e0 !important;
    color: #9e9e9e !important;
    border: 2px solid #9e9e9e !important;
    cursor: not-allowed;
  }
`;
function SpBtn({
  onClick,

  loading = false,
  label,
  cls,
  sx,
  bg = "#4361E9",
  border = "#4361E9",
  color,
  showBg = true,
  children,
  disabled = false,
}) {
  return (
    <Button
      className={cls}
      style={sx}
      bg={bg}
      border={border}
      color={color}
      disabled={loading || disabled}
      onClick={onClick}
      showbg={showBg}
    >
      {children ? children : label}
    </Button>
  );
}

export default SpBtn;
