import React, { useState } from "react";
import { AuthUserContext, FirebaseContext } from "./Firebase";
import { Route, Routes, Navigate } from "react-router-dom";

import CreateVendor from "./createVendor";
import SuspendedAccount from "./SuspendedAccount";
import NonLoggedUserRouter from "./Routers/NonLoggedUserRouter";
import LoggedInVendors from "./Routers/LoggedInVendors";
import MainDev from "./ResourceSections/MainDev";
import RegisterUserFromDev from "./RegisterUserFromDev";

const Router = (props) => {
  const [newUser, setNewUser] = useState(false);
  const [isDup, setIsDup] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const getRoutesBasedOnAuth = (authUser) => {
    const storageId = localStorage.getItem("dev");
    // console.log("Current authUser state:", authUser);

    if (newUser || !authUser.auth) {
      return (
        <NonLoggedUserRouter
          {...props}
          newUser={newUser}
          setNewUser={setNewUser}
          authUser={authUser}
        />
      );
    } else if (
      authUser.vendorRef &&
      authUser.userPartnerAccountDoc.status === true
    ) {
      return (
        <LoggedInVendors
          authUser={authUser}
          {...props}
          setIsDup={setIsDup}
          isDup={isDup}
          isRequest={isRequest}
          setIsRequest={setIsRequest}
          setPublicChatData={props.setPublicChatData}
          setChatBtnClickCount={props.setChatBtnClickCount}
          setIsOutSideBtnClicked={props.setIsOutSideBtnClicked}
          setTechStackData={props.setTechStackData}
        />
      );
    } else if (authUser?.auth?.isAnonymous && !authUser?.devRef) {
      return (
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/register-dev-user" replace />}
          />
          <Route
            path="/register-dev-user"
            element={
              <FirebaseContext.Consumer>
                {(firebase) => (
                  <RegisterUserFromDev
                    firebase={firebase}
                    authUser={authUser}
                    isRequest={isRequest}
                    setIsRequest={setIsRequest}
                    devId={storageId}
                    {...props}
                  />
                )}
              </FirebaseContext.Consumer>
            }
          />
        </Routes>
      );
    } else if (authUser?.devRef) {
      return (
        <Routes>
          <Route
            path="/"
            element={
              <FirebaseContext.Consumer>
                {(firebase) => (
                  <MainDev
                    firebase={firebase}
                    auth={authUser}
                    isRequest={isRequest}
                    setIsRequest={setIsRequest}
                    devId={storageId}
                    {...props}
                  />
                  // <DevData
                  //   firebase={firebase}
                  //   firebaseContext={FirebaseContext}
                  //   {...props}
                  //   isRequest={isRequest}
                  //   setIsRequest={setIsRequest}
                  //   devRef={authUser.devRef}
                  // />
                )}
              </FirebaseContext.Consumer>
            }
          />
        </Routes>
      );
    } else if (
      !authUser.status &&
      !authUser.vendorRef &&
      !authUser.devRef &&
      !authUser?.isDevSelfReg
    ) {
      return (
        <Routes>
          <Route path="/" element={<Navigate to="/createVendor" replace />} />
          <Route
            path="/createVendor"
            element={
              <CreateVendor
                user={authUser.auth}
                newUser={newUser}
                {...props}
                setNewUser={setNewUser}
              />
            }
          />
        </Routes>
      );
    } else if (!authUser.status && authUser.vendorRef) {
      return (
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/suspendedAccount" replace />}
          />
          <Route path="/suspendedAccount" element={<SuspendedAccount />} />
        </Routes>
      );
    }

    return <></>;
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => getRoutesBasedOnAuth(authUser)}
    </AuthUserContext.Consumer>
  );
};

export default Router;
