import React from "react";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const ButtonWrapper = styled.div`
  //   margin: 1em;
  padding: 1em;
  border: dashed 2px #ccc;
  border-radius: 5px;
  text-align: center;
  transition: border-color 0.3s ease;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: #4361e9; // Change border color on hover
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  ._spUploader--content-text {
    h4 {
      font-weight: 500;
    }
  }
`;

const SpUploader = ({
  children,
  onFileSelect,
  cls,
  accept,
  uploadText = "Click to select file",
  multiple = false,
  openFileUploader = true,
  onClick,
  smallText,
  ...props
}) => {
  const fileInputRef = React.createRef();

  const handleButtonClick = () => {
    if (openFileUploader) fileInputRef.current.click();
    if (!openFileUploader && onClick) onClick();
  };

  return (
    <ButtonWrapper
      onClick={handleButtonClick}
      className={`${cls} _spUploader`}
      {...props}
    >
      <HiddenInput
        type="file"
        ref={fileInputRef}
        onChange={onFileSelect}
        accept={accept || "*"}
        className={`_spUploader--input ${cls}--input`}
        multiple={multiple}
      />
      {children ? (
        children
      ) : (
        <UploaderContent className={`_spUploader--content ${cls}--content`}>
          <CloudUploadIcon
            sx={{
              fontSize: "30px",
              color: "#4361e9",
            }}
          />

          <div className={`_spUploader--content-text ${cls}--content-text`}>
            <h4>{uploadText}</h4>
            {smallText && <span>{smallText}</span>}
          </div>
        </UploaderContent>
      )}
    </ButtonWrapper>
  );
};

export default SpUploader;
