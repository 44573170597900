import React, { forwardRef } from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  width: 100%; // Ensures the container fills the parent width
  position: relative; // Establishes a positioning context for aspect ratio
  padding-top: 56.25%; // Maintains a 16:9 aspect ratio (100 / 16 * 9)
  overflow: hidden; // Prevents content from spilling out
`;

const ResponsiveVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: unset !important;
  max-width: unset !important;
`;
const SpVideoPlayer = forwardRef(
  ({ videoUrl, autoPlay = true, parentProps = {}, ...props }, ref) => {
    return (
      <VideoContainer className="_spVideoPlayer" {...parentProps}>
        <ResponsiveVideo
          ref={ref}
          controls={true}
          autoPlay={autoPlay}
          playsInline
          {...props}
          className="_spVideoPlayer-video"
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </ResponsiveVideo>
      </VideoContainer>
    );
  }
);

export default SpVideoPlayer;
