import { useEffect, useState } from "react";
import DatePicker from "react-datepicker"; //@https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import {
  faCheckCircle,
  faArrowAltCircleRight,
  faRemove
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
// import { analytics } from "../../../components/Firebase/firebase";

const NewResources = (props) => {
  // stores the startDate and end Date data of the availability calendar
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // stores the numbers of locations that is already maped
  const [teamLocInpNumber, setTeamLocInpNum] = useState(1);

  // array of locations
  const [locations, setLocations] = useState([]);
  // Stores error message when a country name is missing for a location searched by user.
  const [locationsError, setLocationsError] = useState("");

  // single location input
  const [location, setLocation] = useState("");

  // team list containing objects of teams and loactions
  const [teamData, setTeamData] = useState([]);

  //either it is crossed vendor or not
  const [isCrossVendor, setIsCrossVendor] = useState(false);

  // either it is stuffing or not
  const [isStaffing, setIsStaffing] = useState(false);

  // stores the leading time
  const [leadingTime, setLeadingTime] = useState(14);
  // Stores empty String for valid values and error message for invalid values.
  const [leadTimeError, setLeadTimeError] = useState("");

  /// stores the volume
  const [volume, setVolume] = useState(1);
  // Stores empty String for valid values and error message for invalid values.
  const [volumeError, setVolumeError] = useState("");

  // either it is Active or inactive
  const [status, setStatus] = useState(false);

  // stores the rate card name
  const [name, setName] = useState("");

  // eitheir it is agreed or not
  const [agreed, setAgreed] = useState(false);

  const [disabled, setDisabled] = useState(false);

  //
  const [agreedUser, setAgreedUser] = useState("");

  // const [agreedUser]

  //when clicking on the view term button
  const [viewTerms, setViewTerms] = useState(false);

  // the rate id
  const [id, setId] = useState("");

  // Used to set button loading state
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const id = Math.random().toString(36).slice(2);
      setId(id);
      if (props.enableEdit && props.selectedData) {
        if (props.selectedData.agreedBy) {
          let agreedUser = await props.selectedData.agreedBy.get();
          let agreeUserName =
            agreedUser.data()?.first + " " + agreedUser.data()?.last ??
            agreedUser.data()?.email;
          setAgreedUser(agreeUserName);
          setAgreed(true);
        }
        setTeamData(props.teams);
        setName(props.selectedData.name);
        setTeamLocInpNum(props.selectedData.locations.length);
        setLocations(props.selectedData.locations);
        setDateRange([props.selectedData.from, props.selectedData.to]);
        setIsCrossVendor(props.selectedData.crossVendor);
        setLeadingTime(props.selectedData.leadTime);
        setStatus(props.selectedData.status === "Active" ? true : false);
        setIsStaffing(props.selectedData.staffing);
        setVolume(props.selectedData.quantity);
        setLocation(
          props.selectedData.locations[props.selectedData.locations.lenght - 1]
        );
      } else {
        setTeamLocInpNum(1);
        setLocations([]);
        setTeamData([]);
        setLocation("");
        setIsCrossVendor(false);
        setIsStaffing(false);
        setLeadingTime(14);
        setVolume(1);
        setStatus(false);
      }
    };
    getData();
  }, [props.enableEdit, props.selectedData, props.teams]);

  /**
   * Triggers when the vendor click on the map button to map a given location
   * @param {number} i index of the given location
   */
  const onMap = async (i) => {
    setIsLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      address: location,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let locationsData = [...locations];

    const sendPotentialLocations = props.firebase.spryteCloudUrl(
      "sendPotentialLocations"
    );

    fetch(sendPotentialLocations, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        const locationData = JSON.parse(result);
        const { geoTag, ...location } = locationData[0];
        if(!location?.locationData?.country) {
          setLocationsError("*Please enter a valid country, state or city name");
          setIsLoading(false);
          return;
        }
        setLocationsError("");

        let geoCordinate = props.firebase.createGeoPoint(
          geoTag["_latitude"],
          geoTag["_longitude"]
        );
        location.locationData["geoTag"] = geoCordinate;
        locationsData[i] = location.locationData;
        teamData[i] = {
          country: locationsData[i].country,
          data: [],
        };

        props.selectedResources.forEach((resource) => {
          teamData[i].data.push({
            position: resource.position,
            price: 5,
            volume: 0,
            positionRef: resource.positionRef,
          });
        });
        setLocations(locationsData);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  /**
   * Doesnt allow users to manually input negative or out of range values for Lead Time
   */
  useEffect(() => {
    if (leadingTime != "") {
      if (leadingTime < 0 || leadingTime > 90) {
        setLeadTimeError("*Lead time should be in range 1 to 90");
      } else {
        setLeadTimeError("");
      }
    } else {
      setLeadTimeError("");
    }
  }, [leadingTime]);

  /**
   * Doesnt allow users to manually input negative or out of range values for Volume
   */
  useEffect(() => {
    if (volume != "") {
      if (volume < 0 || volume > 100) {
        setVolumeError("*Volume should be in range 0.01 to 100");
      } else {
        setVolumeError("");
      }
    } else {
      setVolumeError("");
    }
  }, [volume]);

  /**
   * Triggers when the user click on the add button
   */
  const onAddLocation = () => {
    let locationsList = [...locations];
    locations.push(location);
    setLocations(locationsList);
    setTeamLocInpNum(teamLocInpNumber + 1);
  };

  const onInputChange = (e) => {
    if (e.target.name === "location") {
      setLocation(e.target.value);
    }
    e.persist();
  };

  /**
   * Triggers when the vendor tries to edit the number of devs or pricing
   * @param {number} i the pricing matrix index
   * @param {number} j the data in the pricing matrix index
   * @param {event} e
   */
  const onPricingMatrixChange = (i, j, e) => {
    let pricingMatrix = [...teamData];
    if (e.data.name === "price") {
      pricingMatrix[i].data[j]["price"] = 5;
      let price = parseFloat(e.data.value);
      if (!isNaN(price)) pricingMatrix[i].data[j]["price"] = price;
    } else {
      pricingMatrix[i].data[j]["volume"] = 0;
      let price = parseInt(e.data.value);
      if (!isNaN(price)) pricingMatrix[i].data[j]["volume"] = price;
    }
    setTeamData(pricingMatrix);
  };
  /**
   * Renders the inputs component depending on the teamLocInpNumber state
   * @returns The arrays of component containing the inputs, Map button or Add button or Remove button
   */
  const renderInputs = () => {
    let inputs = [];
    for (let i = 0; i < teamLocInpNumber; i++) {
      if (i === teamLocInpNumber - 1) {
        inputs.push(
          <div className="row" key={i}>
            {locations[i] ? (
              <input
                type="text"
                name="location"
                id={`location_${i}`}
                value={locations[i].locality}
                readOnly
              />
            ) : (
              <input
                type="text"
                name="location"
                id={`location_${i}`}
                onChange={(e) => onInputChange(e)}
              />
            )}
            {!locations[i] ? (
              !isLoading ? (
                <button
                  className="spbtn spbtn-sub"
                  id="map-btn"
                  onClick={() => {
                    onMap(i);
                  }}
                >
                  MAP
                </button>
              ) : (
                <button
                  className="spbtn spbtn-sub"
                  id="map-btn"
                  onClick={() => {
                    onMap(i);
                  }}
                >
                  <div className="pulse-container small">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </button>
              )
            ) : (
              <FontAwesomeIcon
                icon={!props.enableEdit? faRemove : faCheckCircle}
                onClick={() => {
                  if (!props.enableEdit) {
                    teamData.splice(i, 1);
                    locations.splice(i, 1);
                    setLocations([...locations]);
                    setTeamData([...teamData]);
                  }
                }}
                className="icon"
              />
            )}
            {teamLocInpNumber > 1 && !locations[i] ? (
              <button
                className="spbtn "
                style={{ color: "grey", fontSize: "10px" }}
                onClick={() => {
                  setTeamLocInpNum(teamLocInpNumber - 1);
                }}
              >
                REMOVE
              </button>
            ) : (
              <></>
            )}
            {locations[i] && !props.enableEdit ? (
              <button
                className="spbtn add"
                onClick={() => {
                  onAddLocation();
                }}
              >
                add
              </button>
            ) : (
              <></>
            )}
          </div>
        );
      } else if (locations[i]) {
        inputs.push(
          <div className="row" key={i}>
            <input type="text" value={locations[i].locality} readOnly />
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
          </div>
        );
      } else {
        inputs.push(
          <div className="row" key={i}>
            <input type="text" />
            <button className="spbtn spbtn-sub">MAP</button>
          </div>
        );
      }
    }
    return <div className="column">{inputs}</div>;
  };

  /**
   *
   * @returns table containing the locations and positions information
   */
  const renderTable = () => {
    let tabledata = [];
    if (teamData.length > 0) {
      for (let i = 0; i < teamData.length; i++) {
        for (let j = 0; j < teamData[i].data.length; j++) {
          if (j === 0) {
            tabledata.push(
              <tr key={i + j + "p"}>
                <td
                  rowSpan={
                    props.enableEdit
                      ? teamData[i].data.length
                      : props.selectedResources.length
                  }
                >
                  {teamData[i].country}
                </td>
                <td>{teamData[i].data[j].position}</td>
                <td>
                  <InPlaceEditorComponent
                    actionBegin={(e) => onPricingMatrixChange(i, j, e)}
                    id="textboxEle"
                    name="price"
                    mode="Popup"
                    type="Numeric"
                    value={teamData[i].data[j].price ?? 5}
                    model={{
                      format: "c2",
                      min: 5,
                      step: 0.5,
                    }}
                  ></InPlaceEditorComponent>
                </td>
                <td>
                  <InPlaceEditorComponent
                    actionBegin={(e) => onPricingMatrixChange(i, j, e)}
                    // ref={(text) => {
                    //   this.textObj = text;
                    // }}
                    id="textboxEle"
                    name="numbersOfDevs"
                    mode="Popup"
                    type="Numeric"
                    value={teamData[i].data[j].volume ?? 0}
                    model={{
                      format: "n0",
                      min: 0,
                      // step: 0.5,
                    }}
                  ></InPlaceEditorComponent>
                </td>
              </tr>
            );
          } else {
            tabledata.push(
              <tr key={i + j + teamData[i].data[j].position}>
                <td>{teamData[i].data[j].position}</td>
                <td>
                  <InPlaceEditorComponent
                    actionBegin={(e) => onPricingMatrixChange(i, j, e)}
                    id="textboxEle"
                    name="price"
                    mode="Popup"
                    type="Numeric"
                    value={teamData[i].data[j].price ?? 0}
                    model={{
                      format: "c2",
                      min: 0,
                      step: 0.5,
                    }}
                  ></InPlaceEditorComponent>
                </td>
                <td>
                  <InPlaceEditorComponent
                    actionBegin={(e) => onPricingMatrixChange(i, j, e)}
                    id="textboxEle"
                    name="numbersOfDevs"
                    mode="Popup"
                    type="Numeric"
                    value={teamData[i].data[j].volume ?? 0}
                    model={{
                      format: "n0",
                      min: 0,
                      // step: 0.5,
                    }}
                  ></InPlaceEditorComponent>
                </td>
              </tr>
            );
          }
        }
      }
    }
    return <tbody>{tabledata}</tbody>;
  };

  /**
   * Triggers when creating or adding new pricing card
   */
  const onCreate = async () => {
    setDisabled(true);
    let pricingMatrix = {};
    let allPositions = [];
    let total = 0;
    let variants = 0;
    for (let i = 0; i < teamData.length; i++) {
      let teams = [];
      for (let resource of teamData[i].data) {
        let resourceData = {
          position: resource.positionRef,
          price: resource.price,
          volume: resource.volume,
        };
        variants += resource.volume;
        total += resource.price;
        teams.push(resourceData);
        allPositions.push(resourceData);
      }
      pricingMatrix[teamData[i].country] = teams;
    }

    let removeZeros = allPositions.filter((el) => el.price > 0);
    let minPrice =
      removeZeros.length > 0
        ? Math.min.apply(
            Math,
            // eslint-disable-next-line array-callback-return
            removeZeros.map((el) => {
              if (el.price > 0) return el.price;
            })
          )
        : 0;
    let maxPrice =
      removeZeros.length > 0
        ? Math.max.apply(
            Math,
            // eslint-disable-next-line array-callback-return
            removeZeros.map((el) => {
              if (el.price > 0) return el.price;
            })
          )
        : 0;

    let avg = total / allPositions.length;

    if (startDate && endDate && locations.length > 0 && agreed && name) {
      for (const location of locations) {
        const data = {
          name: name,
          id: props.enableEdit ? props.selectedData.id : id,
          status: status,
          type: 0,
          location: location,
          pricingMatrix: pricingMatrix[location.country],
          crossVendor: isCrossVendor,
          stats: {
            variants: variants,
            minPrice: minPrice,
            maxPrice: maxPrice,
            avgPrice: avg,
          },
          availability: {
            from: startDate,
            to: endDate,
          },
          staffing: isStaffing,
          leadTime: leadingTime ?? 14,
          quantity: volume ?? 0.01,
          terms: props.lastTerm.ref,
          createdOn: props.enableEdit
            ? props.selectedData.createdOn ?? new Date()
            : new Date(),
          createdBy: props.enableEdit
            ? props.selectedData.createdBy ?? props.userRef
            : props.userRef,
          lastUpdatedBy: props.userRef,
          lastUpdatedOn: new Date(),
        };
        if (agreed) {
          data["agreedBy"] = props.enableEdit
            ? props.selectedData.agreedBy ?? props.userRef
            : props.userRef;
          data["ts"] = props.enableEdit
            ? props.selectedData.ts ?? new Date()
            : new Date();
        }
        if (!props.enableEdit) {
          await props.firebase.db
            .collection(`vendors/${props.vendorId}/priceCards`)
            .add(data);
          // props.firebase.reportPageVisits(
          //   "Price Card Created",
          //   props.isSuperPartner
          // );
        } else {
          await props.firebase.db
            .collection(`vendors/${props.vendorId}/priceCards`)
            .doc(props.selectedData.ids[location.country])
            .set(data, { merge: true });
          // props.firebase.reportPageVisits(
          //   "Price Card Edited",
          //   props.isSuperPartner
          // );
        }
      }
      setDisabled(false);
      props.setEnableEdit(false);
      props.setIsAddRate(false);
    } else if (
      startDate &&
      endDate &&
      locations.length > 0 &&
      !agreed &&
      name
    ) {
      for (const location of locations) {
        const data = {
          name: name,
          id: props.enableEdit ? props.selectedData.id : id,
          status: false,
          type: 0,
          location: location,
          pricingMatrix: pricingMatrix[location.country],
          crossVendor: isCrossVendor,
          stats: {
            variants: variants,
            minPrice: minPrice,
            maxPrice: maxPrice,
            avgPrice: avg,
          },
          availability: {
            from: startDate,
            to: endDate,
          },
          staffing: isStaffing,
          leadTime: leadingTime ?? 14,
          quantity: volume ?? 0.01,
          terms: props.lastTerm.ref,
          createdOn: props.enableEdit
            ? props.selectedData.createdOn ?? new Date()
            : new Date(),
          createdBy: props.enableEdit
            ? props.selectedData.createdBy ?? props.userRef
            : props.userRef,
          lastUpdatedBy: props.userRef,
          lastUpdatedOn: new Date(),
        };
        if (!props.enableEdit) {
          await props.firebase.db
            .collection(`vendors/${props.vendorId}/priceCards`)
            .add(data);
        } else {
          await props.firebase.db
            .collection(`vendors/${props.vendorId}/priceCards`)
            .doc(props.selectedData.ids[location.country])
            .set(data);
        }
      }
      setDisabled(false);
      props.setEnableEdit(false);
      props.setIsAddRate(false);
    }
    setDisabled(false);
  };
  return (
    <div className="stats markets_newRate">
      {viewTerms ? (
        <div className="request">
          <div className="request_form column" id="data-request_form">
            <div className="request_form-content column">
              {props.lastTerm.data.map((term, i) => {
                return (
                  <div key={i} className="request_form-content__data">
                    <h2>{term.title}</h2>
                    <p>{term.description}</p>
                  </div>
                );
              })}
            </div>
            <div className="request_form-action row">
              <button
                id="cancel"
                style={{ padding: "5px 10px", fontWeight: "600" }}
                onClick={() => setViewTerms(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="stat">
        <div className="stat_content">
          <div className="header-intro">
            <h1>Rate Card Definition</h1>
          </div>
          <div className="stat_content_data stat_content_rev row">
            <div className="stat_content_data-content">
              <div className="column">
                <div className="bottom blue">
                  <div>
                    {" "}
                    <p className="bold">
                      {" "}
                      Generic project teams are uniquely defined by their
                      pricing & terms
                    </p>
                    <p>
                      A Generic project team defines a group of resource you
                      have available to put on project contracts. Alternatively
                      you may also define actual resources on the Resources Tab.
                      Project contracts are Sprint-based ongoing projects, where
                      teams are contracted with an Agile mindset, i.e. clients
                      pay for an
                      <span className="bold"> hourly</span> engagement without
                      fixed delivery expectations. Sprints contracted are due
                      and paid.
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                      href="https://sprytelabs.github.io/Spryte-Docs/#/partnerPlatform"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      className="arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-intro">{/* <h1>Unique Attributes</h1> */}</div>
          <div className="stat_content_data stat_content_rev row">
            <div className="stat_content_data-content">
              {props.enableEdit ? (
                <div className="column mb">
                  <div className="row top center">
                    <label id="label-row" className="vendor-info">
                      Unique ID
                    </label>
                    <p className="uniqueID">{props.selectedData.id}</p>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="column">
                <div className="row top">
                  <label id="label-row" className="vendor-info">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      style={{ height: "28px", width: "300px" }}
                    />
                    <div className="bottom light">
                      <p>
                        {" "}
                        This is a shorthand name for your reference- it’s not
                        client facing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="row top" style={{ alignItems: "flex-start" }}>
                  <label id="label-row" className="vendor-info">
                    Team Locations <span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    {renderInputs()}
                    <div className="bottom light">
                    {locationsError && (
                        <p style={{ color: "red" }}>{locationsError}</p>
                      )}
                      <p style={{ marginTop: "-3.5px" }}>
                        {" "}
                        Create pricing variations depending on the team
                        location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {teamData.length ? (
                <div className="column">
                  <div
                    className="row top"
                    style={{ alignItems: "flex-start" }}
                  ></div>
                  <div className="bottom table">
                    <table>
                      <thead>
                        <tr>
                          <th className="combinations">Team Location</th>
                          <th className="wide">Position</th>
                          <th className="wide">Price/Hr/Resource</th>
                          <th className="wide"># Available</th>
                        </tr>
                      </thead>
                      {renderTable()}
                    </table>
                  </div>
                </div>
              ) : null}

              <div className="column">
                <div className="row top">
                  <label id="label-row" className="vendor-info">
                    Cross Vendor
                  </label>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        id="genCross"
                        checked={isCrossVendor}
                        onChange={(e) => {
                          setIsCrossVendor(e.target.checked);
                          e.persist();
                        }}
                      />
                      <p htmlFor="genCross" className="choice">
                        {isCrossVendor ? "Yes" : "No"}
                      </p>
                    </div>
                    <div className="bottom light">
                      <p>
                        {" "}
                        Enable Cross-vendor matching if you would like to open
                        these resources up to work on teams with other vendors.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="row top">
                  <label id="label-row" className="vendor-info">
                    Enable Staffing
                  </label>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        id="enStuf"
                        checked={isStaffing}
                        onChange={(e) => {
                          setIsStaffing(e.target.checked);
                          e.persist();
                        }}
                      />
                      <p className="choice" htmlFor="enStuf">
                        {isStaffing ? "Yes" : "No"}
                      </p>
                    </div>
                    <div className="bottom light">
                      <p>
                        {" "}
                        These resources can also be booked under a full time
                        staffing contract where the end-client manages
                        resources.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="row top">
                  <label id="label-row" className="vendor-info">
                    Availability <span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                    />
                    <div className="bottom light">
                      <p>
                        {" "}
                        Define when this product is available for purchase at
                        the defined price.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="row top">
                  <label id="label-row" className="vendor-info">
                    Lead Time
                  </label>
                  <div>
                    <input
                      type="number"
                      value={leadingTime}
                      name="leadTime"
                      id="leadTime"
                      max="90"
                      min="1"
                      onChange={(e) => {
                        let result = isNaN(parseInt(e.target.value))
                          ? 14
                          : parseInt(e.target.value);
                        setLeadingTime(result);
                      }}
                      style={{ height: "28px" }}
                    />
                    <div className="bottom light">
                      {leadTimeError && (
                        <p style={{ color: "red" }}>{leadTimeError}</p>
                      )}
                      <p>
                        {" "}
                        How many days do you need to garantee service. Clients
                        can book this from you in X days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="row top volume">
                  <label id="label-row" className="vendor-info">
                    Volume
                  </label>
                  <div>
                    <input
                      type="number"
                      value={volume}
                      name="volume"
                      id="volume"
                      min="0.01"
                      max="100"
                      step="0.01"
                      onChange={(e) => {
                        let result = isNaN(parseFloat(e.target.value))
                          ? 0.01
                          : parseFloat(e.target.value);
                        setVolume(result);
                      }}
                      style={{ height: "28px" }}
                    />
                    <div className="bottom light">
                      {volumeError && (
                        <p style={{ color: "red" }}>{volumeError}</p>
                      )}
                      <p>
                        {" "}
                        How many new bookings of this type can you handle
                        concurrently per day? This number can be fractional (1/7
                        = 0.143 -&gt; 1 booking every 7 days)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="agreement">
                <input
                  type="checkbox"
                  id="agreemt"
                  checked={agreed}
                  onChange={(e) => {
                    setAgreed(e.target.checked);
                    e.persist();
                  }}
                />
                <label htmlFor="agreemt">
                  I agree with the{" "}
                  <button
                    className="spbtn spbtn-term"
                    onClick={() => setViewTerms(true)}
                  >
                    Terms and Conditions
                  </button>{" "}
                  of the Spryte Platform.
                </label>
              </div>

              {agreed ? (
                <div className="column">
                  <div className="row top" id="status">
                    <label id="label-row" className="vendor-info">
                      Status
                    </label>
                    <InPlaceEditorComponent
                      className="inPlaceEditor"
                      actionBegin={(e) => {
                        let status = e.data.value === "Active" ? true : false;
                        setStatus(status);
                      }}
                      style={{
                        backgroundColor: status ? "#20975b2c" : "#e70e021c",
                        color: !status ? "#E70E02!important" : "#20975C",
                      }}
                      id="statusBox"
                      name="status"
                      mode="Inline"
                      type="DropDownList"
                      value={status ? "Active" : "Inactive"}
                      model={{ dataSource: ["Active", "Inactive"] }}
                    ></InPlaceEditorComponent>
                  </div>

                  {props.enableEdit && props.selectedData.agreedBy ? (
                    <div className="column mb">
                      <div className="row top center">
                        <label id="label-row" className="vendor-info">
                          Agreed By
                        </label>
                        <p className="uniqueID">{agreedUser}</p>
                      </div>
                      <div className="row top center">
                        <label id="label-row" className="vendor-info">
                          Agreed On
                        </label>
                        <p className="uniqueID">
                          {props.selectedData.ts.toDate().toDateString()}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="bottom blue terms">
                    <div>
                      {" "}
                      <p className="bold">
                        {" "}
                        Define if this product is enabled for booking, or
                        momentarily disabled.
                      </p>
                      <p>
                        Only Active Products show up on public pages and can be
                        booked by clients & partners.
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <a
                        href="https://sprytelabs.github.io/Spryte-Docs/#/partnerPlatform"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn More
                      </a>
                      <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        className="arrow"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row btn">
                <button
                  id="cancel"
                  className="spbtn spbtn-canc"
                  onClick={() => {
                    props.setEnableEdit(false);
                    props.setIsAddRate(false);
                  }}
                >
                  Cancel
                </button>
                {startDate &&
                endDate &&
                name &&
                locations.length > 0 &&
                leadTimeError === "" &&
                volumeError === "" ? (
                  <button
                    className="spbtn spbtn-sub"
                    id="create"
                    disabled={disabled}
                    onClick={(e) => {
                      onCreate();
                    }}
                  >
                    {props.enableEdit ? "Save" : "Create"}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewResources;
