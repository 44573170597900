import { HighlightOff } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "../../../../css/dan.css";
import SpryteTooltip from "../../../SpryteTooltip.js";
import SpToggle from "../../../globals/SpToggle/SpToggle";
import {
  caseStudyRatingType,
  getDocumentsByArrayMembershipInCollection,
} from "../../../globals/services";
import SpRatingComponent from "../../../globals/SpRating/SpRating";

const AddComponent = (props) => {
  const promiseOptions = async (inputValue) => {
    console.log(inputValue);
    const dataDocs = await getDocumentsByArrayMembershipInCollection(
      "technologies",
      inputValue.toLowerCase(),
      ["nameIndex", "catIndex"],
      "rank",
      true,
      true,
      false,
      50
    );

    const data = dataDocs?.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      value: doc?.data()?.name,
      label: doc?.data()?.name,
    }));

    return data;
  };
  return (
    <div
      className="brand_dialog-box add"
      id="data-branding"
      style={{ paddingTop: "15px" }}
    >
      <h1>Add a new structured Case Study</h1>
      <div className="clientDescCtr">
        <div className="warningCtr">
          <div className="warning">
            <h4>!</h4>
          </div>
          <div>
            <p id="bold">Describe your client accurately.</p>
            <p>
              Making sure this is a real person which can be found online on
              linkedIn is key to establishing trust.
            </p>
            <p className="cta">
              If anything is unclear, we recommend you{" "}
              <span>
                <a
                  href="https://www.sprytelabs.com/partners/Spryte%20Labs/case-studies/Startup%20MVP%20made%20easy"
                  target="_blank"
                  rel="noreferrer"
                >
                  view an example
                </a>
              </span>{" "}
              case study.
            </p>
          </div>
        </div>
      </div>
      <form className="brand_dialog-box__inputs column">
        <section className="column section client_info">
          <div
            className="column info_input title"
            style={{ marginBottom: "25px" }}
          >
            <label>Case Study Title</label>
            <input
              type="text"
              onChange={(e) => props.setTitle(e.target.value)}
              value={props.title}
            />
          </div>
          <div className="description">
            <h3 style={{ marginBottom: "17.5px" }}>Client Information</h3>
          </div>
          <div className=" column info info_column">
            <div className=" row info_row ">
              <div className="column info_input name">
                <label>Client Company Name</label>
                <input
                  type="text"
                  onChange={(e) => props.setCompanyName(e.target.value)}
                  value={props.companyName}
                  placeholder="e.g. Facebook"
                />
              </div>
              <div className="column info_input domain">
                <label>Client Company Domain</label>
                <input
                  type="text"
                  onChange={(e) => props.setWebsite(e.target.value)}
                  value={props.website}
                  placeholder="www.facebook.com"
                />
              </div>
            </div>
            <div className=" row info_row ">
              <div className="column info_input name">
                <label>Client Name</label>
                <input
                  type="text"
                  onChange={(e) => props.setName(e.target.value)}
                  value={props.name}
                  placeholder="Mark Zuckerburg"
                />
              </div>

              <div className="column info_input position">
                <label>Client Position</label>
                <input
                  type="text"
                  onChange={(e) => props.setPosition(e.target.value)}
                  value={props.position}
                  placeholder="CEO"
                />
              </div>
            </div>
            <div className=" row info_row ">
              <div className="column  info_input quote">
                <label>Client Quote</label>
                <input
                  type="text"
                  onChange={(e) => props.setQuote(e.target.value)}
                  value={props.quote}
                  placeholder="'This project went extremely smoothly...'"
                />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "30px" }}>
              <div className="img-wrap">
                <div className="column avat" style={{ marginRight: "15px" }}>
                  <input
                    type="file"
                    id="avaPhoto-case"
                    onChange={(e) => props.selectFile(e, "avatar")}
                    accept=".png, .jpg, .jpeg"
                    hidden
                  />
                  <div className="avat-preview">
                    {props.avatarPhoto.map((item, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="inputs-row_main-preview__img"
                            key={index}
                            src={item.url}
                            alt={item?.file?.name ?? item.name}
                          />
                          <HighlightOff
                            onClick={() => {
                              props.deleteFile(index, "avatar");
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <button
                    id="ava_photo "
                    className="btn btn_photo"
                    onClick={(e) => props.handleClicks(e, "avaPhoto-case")}
                  >
                    Upload Client Avatar
                  </button>
                </div>
              </div>
              <div className="img-wrap">
                <div className="column avat">
                  <input
                    type="file"
                    id="clientLogo-case"
                    onChange={(e) => props.selectFile(e, "clientLogo")}
                    accept=".png, .jpg, .jpeg"
                    hidden
                  />
                  <div className="avat-preview">
                    {props.clientLogo.map((item, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="inputs-row_main-preview__img"
                            key={index}
                            src={item.url}
                            alt={item?.file?.name ?? item.name}
                          />
                          <HighlightOff
                            onClick={() => {
                              props.deleteFile(index, "clientLogo");
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <button
                    id="clientLogo "
                    className="btn btn_photo"
                    onClick={(e) => props.handleClicks(e, "clientLogo-case")}
                  >
                    Upload Client Logo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section project_info">
          <div className="description">
            <h3>Project Information</h3>
            <p className="lighter">
              Describe the project details, this will help clients find your
              company and pricing.
            </p>
          </div>
          <div className="column info info_column">
            <div className="row info_row">
              <div className="column info_input stacks">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Stacks</label>
                  <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
                </div>
                {props.isEdit ? (
                  <AsyncSelect
                    className="basic-multi-select"
                    classNamePrefix="select Stacks"
                    isMulti
                    name="colors"
                    defaultOptions={props.stackOption}
                    defaultValue={props?.selectedData?.stacks ?? []}
                    onChange={(e) => {
                      props.setStacks(e);
                    }}
                    loadOptions={promiseOptions}
                  />
                ) : props.isAdd ? (
                  // <>Hello</>
                  <AsyncSelect
                    options={props.stackOption}
                    className="basic-multi-select"
                    classNamePrefix="select Stack"
                    isMulti
                    name="colors"
                    loadOptions={promiseOptions}
                    defaultOptions={props.stackOption}
                    onChange={(e) => {
                      // console.log(e);
                      props.setStacks(e);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="column info_input domains">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Domains</label>
                  <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
                </div>
                {props.isEdit ? (
                  <Select
                    options={props.domainsOption}
                    className="basic-multi-select"
                    classNamePrefix="select Domain"
                    isMulti
                    name="colors"
                    defaultValue={
                      props?.selectedData ? props?.selectedData?.domains : []
                    }
                    onChange={(e) => props.setDomains(e)}
                  />
                ) : props.isAdd ? (
                  <Select
                    options={props.domainsOption}
                    className="basic-multi-select"
                    classNamePrefix="select Domain"
                    isMulti
                    name="colors"
                    onChange={(e) => props.setDomains(e)}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="column info_input projects">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Project Types</label>
                  <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
                </div>
                {props.isEdit ? (
                  <Select
                    options={props.projOption}
                    className="basic-multi-select"
                    classNamePrefix="select project Type"
                    isMulti
                    name="colors"
                    defaultValue={
                      props?.selectedData ? props?.selectedData?.projects : []
                    }
                    onChange={(e) => props.setProjTypes(e)}
                  />
                ) : props.isAdd ? (
                  <Select
                    options={props.projOption}
                    className="basic-multi-select"
                    classNamePrefix="select project Type"
                    isMulti
                    name="colors"
                    onChange={(e) => props.setProjTypes(e)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row info_row">
              <div className="column info_input descriptions">
                <label>Description</label>
                <textarea
                  onChange={(e) => props.setDescription(e.target.value)}
                  value={props.description}
                ></textarea>
              </div>
              <div className="column info_input challenges">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>The Challenges</label>
                  <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
                </div>
                <textarea
                  onChange={(e) => props.setChallenge(e.target.value)}
                  value={props.challenge}
                ></textarea>
              </div>
              <div className="column info_input solutions">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>The Solutions</label>
                  <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
                </div>
                <textarea
                  onChange={(e) => props.setSolution(e.target.value)}
                  value={props.solution}
                ></textarea>
              </div>
            </div>
            <div className="row uploaders info_row">
              <div className="description" style={{ marginTop: "20px" }}>
                <h3>Images</h3>
                <p className="lighter">
                  Below are images that will be displayed to your public case
                  study page - please select high quality files with relevant
                  information.
                </p>
              </div>
            </div>
            <div>
              <div className="info_row-slides column">
                <input
                  type="file"
                  id="caseSlide"
                  onChange={(e) => {
                    props.selectFiles(e);
                  }}
                  accept=".png, .jpg, .jpeg"
                  multiple={true}
                  hidden
                  maxLength={12}
                />
                <div
                  className="info_row-slides-preview"
                  style={{ height: props.slides.length ? "265px" : "inherit" }}
                >
                  {props.slides.map((file, index) => {
                    return (
                      <div
                        key={index}
                        className="info_row-slides-preview__imgBox"
                      >
                        <img
                          className="info_row-slides-preview__imgBox--img"
                          src={file.url}
                          alt={file?.file?.name ?? file.name}
                        />
                        <HighlightOff
                          onClick={() => {
                            props.deleteFile(index, "slide");
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <button
                  id="media_slide"
                  className="btn btn_slid"
                  onClick={(e) => props.handleClicks(e, "caseSlide")}
                >
                  Upload Images
                </button>
                <div className="img-wrap">
                  <div className="column avat" style={{ marginRight: "15px" }}>
                    <input
                      type="file"
                      id="mainPhoto-case"
                      onChange={props.selectFile}
                      accept=".png, .jpg, .jpeg"
                      hidden
                    />
                    <div className="avat-preview">
                      {props.mainPhoto.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="info_row-main-preview_imgBox"
                          >
                            <img
                              className="inputs-row_main-preview__img"
                              key={index}
                              src={item.url}
                              alt={item?.file?.name ?? item.name}
                            />
                            <HighlightOff
                              className="delete"
                              onClick={() => {
                                props.deleteFile(index, "main");
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <button
                      id="main_photo "
                      className="btn btn_photo"
                      onClick={(e) => props.handleClicks(e, "mainPhoto-case")}
                    >
                      Upload main photo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clientDescCtr blue">
          <p>
            You can reference this structured case study to one of your own
            originals. Spryte structured case studies are pushed out to
            sprytelabs.com as well as our client interfaces, and link back
            directly to your business. Once you wish to publish your case study,
            set the status to <kbd>READY-TO-SUBMIT</kbd> , and a Spryte Manager
            will review it.
          </p>
        </div>
        <section className="section ref_stat" style={{ marginBottom: "25px" }}>
          <div className="description">
            <h3 style={{ marginBottom: "20px" }}>Reference & Status</h3>
          </div>
          <div className="row info">
            <div className="column info_input stat_item externals">
              <div style={{ display: "flex", alignItems: "center" }}>
                <label>Original Uploaded Case Studies</label>
                <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
              </div>
              {props.isEdit ? (
                <Select
                  options={props.external}
                  className="basic-multi-select"
                  classNamePrefix="select Stack"
                  isMulti
                  name="colors"
                  defaultValue={
                    props?.selectedData ? props?.selectedData?.externalRef : []
                  }
                  menuPlacement="top"
                  onChange={(e) => props.setExId(props.setMultiSelect(e))}
                />
              ) : props.isAdd ? (
                <Select
                  options={props.external}
                  menuPlacement="top"
                  className="basic-multi-select"
                  classNamePrefix="select Stack"
                  name="colors"
                />
              ) : (
                <></>
              )}
            </div>
            {props.showStatus()}
            <div className="column info_input stat_item">
              <div style={{ display: "flex", alignItems: "center" }}>
                <label>Live Link</label>
                <SpryteTooltip desc="Some information regarding this point here. Explain to the users what they will get out of inputting this information." />
              </div>
              <input
                type="text"
                onChange={(e) => props.setLink(e.target.value)}
                value={props.link}
              />
            </div>
            {props?.isSuper && (
              <div className="column info_input stat_item">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Spryte Showcase</label>
                  <SpryteTooltip desc="Show this case study on spryte website homepage under featured case studies" />
                </div>
                <SpToggle
                  checked={props?.spryteShowcase ?? false}
                  onChange={() =>
                    props?.setSpryteShowcase(!props?.spryteShowcase)
                  }
                />
              </div>
            )}
            {/* {props?.isSuper && (
              <div className="column info_input stat_item">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Rate Case Study</label>
                  <SpryteTooltip desc="Rate this case study for spryte website homepage" />
                </div>
                <SpRatingComponent
                  dataInput={{
                    ref: props?.selectedData?.ref,
                    rating: props?.selectedData?.rating ?? 0,
                    isAdmin: props?.isSuper,
                    typeRef: caseStudyRatingType(),
                  }}
                />
              </div>
            )} */}
          </div>
        </section>
      </form>
      <div className="brand_dialog-box__actions">
        {props.isUploading ? (
          <CircularProgress color="primary" size="small" />
        ) : (
          <button
            style={{ backgroundColor: "rgb(66, 158, 255)!important" }}
            id="addCase_sub"
            className="btn"
            onClick={props.onSubmit}
          >
            Submit
          </button>
        )}

        <button
          id="addCase_cancel"
          className="btn"
          onClick={() => {
            props.setIsAdd(false);
            props.setIsEdit(false);
            props.setShowMain(true);
            props.setSelectedData(null);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddComponent;
