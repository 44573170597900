import React from "react";

const UnCollapseIcon = ({
  size = 42,
  fill = "white",
  stroke = "#4361E9",
  line = "#4361E9",
  onClick,
  cls,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={cls}
    >
      <circle cx="21" cy="21" r="20.5" fill={fill} stroke={stroke} />
      <line
        x1="21"
        y1="17.4142"
        x2="13.4142"
        y2="25"
        stroke={line}
        stroke-width="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11.7279"
        y2="-1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 21 16)"
        stroke={line}
        stroke-width="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UnCollapseIcon;
