import React from "react";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";

const Profile = (props) => {
  const updateVendorPub = async (data) => {
    let updates = {};
    updates[data.name] = data.value;
    if (data.name == "index") {
      updates[data.name] = data.value === "true" ? true : false;
    }

    await props.firebase.updateVendorPubData(props.vendorData.id, updates);
    props.updateVendor();
  };

  return (
    <div className="stat_content_data stat_content_rev row marketing">
      <div className="stat_content_data-title">
        <h4>Profile</h4>
      </div>
      {props.vendorPublic?.featured ? (
        <div className="stat_content_data-content">
          <div className="column">
            {/* <div className="row">
            <label className="vendor-info">LinkBack Marketing Settings</label>
          </div> */}
            <div className="row">
              <div>
                <label className="vendor-info" style={{ marginRight: "12px" }}>
                  Slug:
                </label>
                <InPlaceEditorComponent
                  className="inPlaceEditor"
                  actionBegin={(e) => updateVendorPub(e.data)}
                  id="textboxEle"
                  name="slug"
                  mode="Inline"
                  type="Text"
                  value={
                    props.vendorPublic?.slug ?? props.vendorData.companyName
                  }
                ></InPlaceEditorComponent>
              </div>
              <div>
                <label className="vendor-info" style={{ marginRight: "12px" }}>
                  Index:
                </label>
                <InPlaceEditorComponent
                  className="inPlaceEditor"
                  actionBegin={(e) => updateVendorPub(e.data)}
                  id="textboxEle"
                  name="index"
                  mode="Inline"
                  type="DropDownList"
                  model={{ dataSource: [true, false] }}
                  value={props.vendorPublic?.index ?? true}
                ></InPlaceEditorComponent>
              </div>
              <div className="row">
                <label
                  className="vendor-info"
                  style={{ marginRight: "12px", marginLeft: "20px" }}
                >
                  Public Page Link:
                </label>
                <p>
                  {" "}
                  <a
                    href={`https://www.sprytelabs.com/partners/${props.vendorPublic?.slug}`}
                    className="link"
                    target="_blank"
                  >
                    {`https://www.sprytelabs.com/partners/${props.vendorPublic?.slug}`}
                  </a>
                </p>
              </div>
            </div>
            <div className="bottom">
              <p>
                {/* We will embed your badges in the same manner on your Spryte Labs
                Public Company Profile page, which will allow your marketing
                team to track incoming users into your site from Spryte Labs.
                You can set your own UTM parameters below to match your
                marketing needs. */}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="stat_content_data-content">
          <div className="column">
            {/* <div className="row">
            <label className="vendor-info">LinkBack Marketing Settings</label>
          </div> */}
            <div className="row top">
              <div className="row">
                <label
                  className="vendor-info"
                  style={{ marginRight: "12px", color: "grey" }}
                >
                  Slug:
                </label>
                <p style={{ color: "grey" }}>
                  {props.vendorPublic?.slug ?? props.vendorData.companyName}
                </p>
              </div>
              <div className="row">
                <label
                  className="vendor-info"
                  style={{ marginRight: "12px", color: "grey" }}
                >
                  Index:
                </label>
                <p style={{ color: "grey" }}>true</p>
              </div>
            </div>
            <div className="bottom">
              <p>
                {/* We will embed your badges in the same manner on your Spryte Labs
                Public Company Profile page, which will allow your marketing
                team to track incoming users into your site from Spryte Labs.
                You can set your own UTM parameters below to match your
                marketing needs. */}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
