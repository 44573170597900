import React, {useEffect, useState} from 'react';

const Dashboard = (props) => {

    const [className, setClassName] = useState('')
    const [frameborder, setFrameborder] = useState(0)
    const [height, setHeight] = useState(0)
    const [src, setSrc] = useState('')
    const [width, setWidth] = useState(0)

    useEffect(()=>{
        const getValues = async()=>{
        let dash = await props.firebase.getDashboard()
        let values = JSON.parse(dash)
        setClassName(values.className)
        setFrameborder(values.frameborder)
        setHeight(values.height)
        setSrc(values.src)
        setWidth(values.width)
        }
        getValues()
    }, [])
    
    return(
        <div>
            <iframe title="dashboard" width={width} height={height} src={src} frameBorder={frameborder} className={className} allowFullScreen></iframe>
        </div>
    )
}

export default Dashboard