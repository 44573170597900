import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  position: relative;
`;

const ReadContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: fit-content;
  position: relative;
  padding-right: 40px;
`;

const Content = styled.div`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  white-space: pre-wrap;
  word-break: break-word;
  width: 100%;
  line-height: ${(props) => (props.fontSize <= "14px" ? "1.5" : "1.2")};
`;

const UserActions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: fit-content;
  width: 100%;
  position: relative;
`;

const TextArea = styled.textarea`
  padding: 0px 20px 8px 0px;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  resize: none;
  overflow-y: auto;
  max-height: 250px;
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Select = styled.select`
  padding: 0 20px 8px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  box-sizing: border-box;
`;

const EditBtn = styled.button`
  background: none;
  border: none;
  color: #808080;
  cursor: pointer;
  font-size: 11px;
  gap: 4px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 2px;
  right: 0;
  svg {
    font-size: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 8px;
`;

const SaveBtn = styled.button`
  background: none;
  border: none;
  color: green;
  cursor: pointer;
  padding: 0;
`;

const CancelBtn = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  padding: 0;
`;

const SpInlineEdit = forwardRef(
  (
    {
      initialValue,
      onSave,
      showEdit = true,
      showSaveCancelBtn = true,
      handleChange,
      fontSize = "14px",
      fontWeight = "normal",
      options,
      renderOptions,
      enableEdit = true,
      ...rest
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef(null);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.style.height = "auto";
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    }, [value, isEditing, enableEdit]);

    const handleEdit = () => {
      setIsEditing(true);
      inputRef?.current?.focus();
    };

    const onChange = (e) => {
      setValue(e.target.value);
      handleChange && handleChange(e, initialValue);
    };

    const handleSave = () => {
      setIsEditing(false);
      onSave(value);
    };

    const handleCancel = () => {
      setIsEditing(false);
      setValue(initialValue);
    };

    useImperativeHandle(ref, () => ({
      inputRef: inputRef?.current,
    }));

    return (
      <Container className="_spInlineEdit" {...rest}>
        {enableEdit && isEditing ? (
          <UserActions>
            {options ? (
              <Select
                className="_spInlineEdit--select"
                value={value}
                onChange={onChange}
                ref={inputRef}
                onBlur={!showSaveCancelBtn ? handleSave : undefined}
                fontSize={fontSize}
                fontWeight={fontWeight}
              >
                {renderOptions
                  ? renderOptions()
                  : options.map((option) => (
                      <option
                        key={option?.value || option}
                        value={option?.value || option}
                      >
                        {option?.label || option}
                      </option>
                    ))}
              </Select>
            ) : (
              <TextArea
                className="_spInlineEdit--input"
                value={value}
                onChange={onChange}
                ref={inputRef}
                onBlur={!showSaveCancelBtn ? handleSave : undefined}
                fontSize={fontSize}
                fontWeight={fontWeight}
                rows={1}
              />
            )}
            {showSaveCancelBtn && (
              <Buttons className="_spInlineEdit--buttons">
                <SaveBtn
                  onClick={handleSave}
                  className="_spInlineEdit--buttons-save"
                >
                  <CheckIcon />
                </SaveBtn>
                <CancelBtn
                  onClick={handleCancel}
                  className="_spInlineEdit--buttons-cancel"
                >
                  <CloseIcon />
                </CancelBtn>
              </Buttons>
            )}
          </UserActions>
        ) : (
          <ReadContent className="_spInlineEdit--readContent">
            <Content
              className="_spInlineEdit--content"
              fontSize={fontSize}
              fontWeight={fontWeight}
              style={{
                cursor: !showEdit && enableEdit ? "pointer" : "default",
              }}
              onClick={!showEdit && enableEdit ? handleEdit : undefined}
            >
              {value}
            </Content>
            {showEdit && (
              <EditBtn className="_spInlineEdit--editBtn" onClick={handleEdit}>
                <EditIcon />
                <span>Edit</span>
              </EditBtn>
            )}
          </ReadContent>
        )}
      </Container>
    );
  }
);

export default SpInlineEdit;
