import styled from "styled-components";

// colors
export const spPrimaryColor = "#000000";
export const spDarkColor = "#151515";
export const spGreyColor = "#484848";
export const spDarkGreyColor = "#47505A";
export const spLightGreenColor = "#F4F5F9";
export const spLightGreyColor = "#E0E0E0";
export const spLightColor = "#F5F5F5";
export const spWhiteColor = "#FFFFFF";
export const spDanger = "#F95E2F";
export const spSuccessLight = "#13D00F";
export const spSuccess = " #3FDD98";
export const spWarning = "#F9C02F";
export const spInfo = "#6867FF";

export const RspTab = styled.div`
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // padding-right: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const renderOpacity = (n, high) => {
  if (n === high || n > high) return 1;
  const opacity = 0.1 + ((n - 1) * (1 - 0.1)) / (high > 1 ? high - 1 : 1);
  if (opacity < 0.1) return 0.1;
  return opacity;
};
