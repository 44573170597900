import React from "react";
import DomainCard from "./DomainCard";

export default function DomainsList(props) {
  return (
    <div className="_spTechskills__container-body">
      {props.domains.map((domain, index) => (
        <DomainCard
          key={index}
          domain={domain}
          firebase={props.firebase}
          devId={props.devId}
          domainExperiencesObject={props.domainExperiencesObject}
          index={index}
          showHiddenCard={props.showHiddenCard}
          useExist={props.useExist}
          setSelectedData={props.setSelectedData}
          domId={props.domId}
        />
      ))}
    </div>
  );
}
