import { currentTime, db } from "../globals/services";

export const DEVDATA = {
  first: "",
  last: "",
  status: false,
  addedOn: currentTime(),
  position: "Jr. Developer",
  locTag: "",
  accessCode: "",
  devID: "",
  positionRef: null,
  vendorRef: null,
  orgRef: null,
  triggerSearchCompute: null,
  minContractTeamSize: 1,
  discovery: true,
  draft: true,
  isVerified: false,
  hourlyRate: 0, // this should be ignored when saving devDocument it should be saved in the rating section
};

/**
 * this function takes an array of devs documents and renders it to return a data of devs
 * @param {Array} docsList => Array of devs documents
 * @returns array of devData
 */
export const getDevs = async (docsList) => {
  // Formats the date into MM/DD/YYYY string format.
  const formatDate = (timeStamp) => {
    if (!timeStamp?.toDate()) return "";
    let date = timeStamp.toDate();
    const formattedDate =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    return formattedDate;
  };
  let devPosMap = {};
  let devsList = await Promise.all(
    docsList?.map(async (doc) => {
      const {
        status,
        userRef,
        isVerified,
        intern,
        profileProgress,
        ...devData
      } = doc?.data();
      devData["status"] = status;
      devData["addedOnString"] = formatDate(devData["addedOn"]);
      devData["lastUpdatedString"] = formatDate(devData["lastUpdated"]);

      if (
        !devPosMap?.[devData?.["positionRef"]?.path] &&
        devData?.["positionRef"]?.path
      ) {
        devPosMap[devData?.["positionRef"]?.path] = (
          await devData["positionRef"]?.get()
        )?.data()?.label;
      }
      devData["position"] =
        devPosMap?.[devData?.["positionRef"]?.path] ?? devData?.["position"];
      devData["type"] = "RESOURCE";
      devData["progress"] =
        Math.floor(
          ((profileProgress?.stacksProgress || 0) * 0.125 +
            (profileProgress?.categoriesProgress || 0) * 0.125 +
            (profileProgress?.sectorsProgress || 0) * 0.125 +
            (profileProgress?.ratesProgress || 0) * 0.5 +
            0.125) *
            100
        ) ?? 0;

      if (userRef && !isVerified) devData["type"] = "PLEASE VERIFY";
      if (userRef && isVerified && intern) devData["type"] = "INTERN";

      return { ...devData, id: doc.id, devRef: doc.ref, profileProgress };
    })
  );
  return devsList;
};

export const fetchGridDevsVendorRef = ({
  status,
  draft,
  vendorRef,
  docs,
  skip,
  limit = 10,
  onSuccess,
  onError,
}) => {
  let reference = db
    .collection("devs")
    .where("vendorRef", "==", vendorRef)
    .where("draft", "==", draft)
    .orderBy("addedOn", "desc");

  if (!draft) {
    reference = reference.where("status", "==", status);
  }

  reference =
    skip && docs?.length >= limit
      ? reference.startAfter(docs[skip - 1]).limit(limit)
      : reference.limit(limit);

  return reference.onSnapshot(
    async (snap) => {
      // console.log("snap", snap);
      let docs = snap.docs;
      let devs = await getDevs(docs);
      onSuccess(devs, docs);
    },
    (err) => {
      console.log(err);
      onError(err);
    }
  );
};
