import React, { useState } from "react";
import {
  SingleAssessBodyContent,
  SingleAssessBodyImgWrapper,
  SingleAssessBodyTab,
  VideoContainer,
} from "./style";
import SpVideoPlayer from "../../SpVideoPlayer";

const SingleContent = ({
  cls,
  item,
  isActive = "0",
  imgUrl,
  question,
  answer,
  showScore = false,
  showDifficulty = false,
  difficulty,
  difficultyText = "Difficulty",
  score,
  scoreText = "Score",
  autoPlay = true,

  ...props
}) => {
  // console.log("item", item);
  return (
    <SingleAssessBodyTab
      className={`_spAssBody--singleTab ${cls}--singleTab`}
      isActive={isActive}
      {...props}
    >
      <SingleAssessBodyImgWrapper
        className={`_spAssBody--singleTab-imgWrapper ${cls}--singleTab-imgWrapper`}
      >
        {item.videoURL ? (
          <SpVideoPlayer
            autoPlay={autoPlay && isActive === "1"}
            videoUrl={item.videoURL}
          />
        ) : (
          <div
            style={{ width: "100%", height: "auto" }}
            className="_spAssBody--singleTab-imgWrapper--noVideo"
          >
            <h3>?</h3>
            <span>No video available</span>
          </div>
        )}
        {/* {imgUrl ? (
          <div style={{ width: "170px", height: "170px", overflow: "hidden" }}>
            <img
              src={imgUrl}
              alt="img"
              className="_spAssBody--singleTab-img"
              style={{
                objectFit: "cover",
                objectPosition: "top center",
                maxWidth: "170px",
                // height: "auto",
                // maxHeight: "170px",
              }}
            />
          </div>
        ) : (
          
        )} */}
      </SingleAssessBodyImgWrapper>
      <SingleAssessBodyContent
        className={`_spAssBody--singleTab-content ${cls}--singleTab-content`}
      >
        {(showDifficulty || showScore) && (difficulty || score) && (
          <div
            className={`_spAssBody--singleTab-content--difficulty ${cls}--singleTab-content--difficulty`}
          >
            {difficulty && (
              <div>
                <h5>{difficultyText}</h5>
                <p
                  style={{
                    color: "#F92A50",
                  }}
                >
                  {difficulty}
                </p>
              </div>
            )}
            {score && (
              <div>
                <h5>{scoreText}</h5>
                <p
                  style={{
                    color: "#13D426",
                  }}
                >
                  {score} / 5
                </p>
              </div>
            )}
          </div>
        )}
        {question && (
          <div
            className={`_spAssBody--singleTab-content--question ${cls}--singleTab-content--question`}
          >
            <h5>Q</h5>
            <p>{question}</p>
          </div>
        )}
        {answer && (
          <div
            className={`_spAssBody--singleTab-content--answer ${cls}--singleTab-content--answer`}
          >
            <h5>A</h5>
            <p>{answer}</p>
          </div>
        )}
      </SingleAssessBodyContent>
    </SingleAssessBodyTab>
  );
};

export default SingleContent;
