import { useEffect, useState } from "react";
import { db } from "../globals/services";

export const useVendorByRef = (ref) => {
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // console.log("useVendorByRef", ref);
  useEffect(() => {
    if (!ref) {
      setLoading(false);
      return;
    }
    const unsubscribe = ref.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setVendor({ id: doc.id, ...doc.data(), ref: doc.ref });
        } else {
          setError("Vendor not found");
        }
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [ref]);

  return { vendor, loading, error };
};

export const useVendor = (id) => {
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError("Vendor ID is undefined or invalid");
      setLoading(false);
      return;
    }

    const vendorRef = db.doc(`vendors/${id}`);
    const unsubscribe = vendorRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setVendor({ id: doc.id, ...doc.data(), ref: doc.ref });
        } else {
          setError("Vendor not found");
        }
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  return { vendor, loading, error };
};

export const useVendorPublic = (id) => {
  const [vendorPublic, setVendorPublic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError("Vendor ID is undefined or invalid");
      setLoading(false);
      return;
    }

    const publicRef = db.doc(`vendors/${id}/partnerPublic/profile`);
    const unsubscribe = publicRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setVendorPublic({ id: doc.id, ...doc.data() });
        } else {
          setError("Public vendor profile not found");
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  return { vendorPublic, loading, error };
};

export const useVendorPublicByRef = (ref) => {
  return useVendorPublic(ref?.id);
};

export const useVendorAllData = (id) => {
  // should return vendor public and private data
  const { vendor, loading, error } = useVendor(id);
  const {
    vendorPublic,
    loading: loadingPublic,
    error: errorPublic,
  } = useVendorPublic(id);
  return { vendor, loading, error, vendorPublic, loadingPublic, errorPublic };
};
