import React, { useEffect, useState } from "react";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { validateEmail } from "../../../globals/helpers";
import { Cancel, Info } from "@mui/icons-material";

const Communication = (props) => {
  const [showAddEmails, setShowAddEmails] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [allEmails, setAllEmails] = useState(
    props.settings?.communication?.members ?? []
  );
  const [error, setError] = useState("");
  useEffect(() => {
    setAllEmails(props.settings?.communication?.members ?? []);
  }, [props.settings?.communication?.members]);

  const updateAllEmails = () => {
    const trimmedEmail = newEmail?.trim();
    if (trimmedEmail != "") {
      if (allEmails.includes(trimmedEmail)) {
        setError("Mentioned email is already being notified!!!");
      } else {
        let validatedEmail = validateEmail(trimmedEmail);
        if (validatedEmail !== null) {
          allEmails.push(trimmedEmail);
          setAllEmails(allEmails);
          setError("");
          setNewEmail("");
        } else {
          setError("Invalid email. Please verify.");
        }
      }
    } else {
      setError("");
    }
  };

  const confirmChanges = async () => {
    props.updateVendorData("communication", {
      name: "members",
      value: [...allEmails],
    });
    setShowAddEmails(false);
  };

  return (
    <div className="stat_content_data stat_content_rev row">
      <div className="stat_content_data-title">
        <h4>Communication</h4>
      </div>
      <div className="stat_content_data-content">
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Email Notifications</label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) =>
                props.updateVendorData("communication", e.data)
              }
              id="textboxEle"
              name="emailNotifications"
              mode="Inline"
              type="DropDownList"
              value={
                props.settings?.communication?.emailNotifications ? "YES" : "NO"
              }
              model={props.dropDownModel}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              Spryte may sporadically email you reports of your account
              activity, upcoming project opportunities from clients, or
              questions related to sales and your developers
            </p>
          </div>
        </div>
        {props.settings?.communication?.emailNotifications && (
          <div className="column">
            <div className="row top">
              <label className="vendor-info">
                Notifications List Membership
              </label>
              <div className="row top" id="fs" style={{ display: "flex" }}>
                {props.settings?.communication?.members ? (
                  props.settings?.communication?.members.map((item, i) => {
                    return <p>{item}</p>;
                  })
                ) : (
                  <p>No emails to notify</p>
                )}
                <br />
              </div>

              {showAddEmails && (
                <div
                  className="marketSearch request_form"
                  id="marketSearchComponent"
                >
                  <div className="info-ctr">
                    <Info className="market-info" />
                    <h5>Add Emails to be Notified</h5>
                  </div>
                  <div className="marketSearch_close">
                    <Cancel
                      className="marketSearch_close-icon"
                      onClick={() => setShowAddEmails(false)}
                    />
                  </div>
                  <div className="selectedStackDiv"></div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      name="email"
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                      }}
                    />
                    <button
                      className="e-control e-btn "
                      style={{
                        background: "white",
                        color: "black",
                        marginLeft: 10,
                        height: 30,
                      }}
                      onClick={() => {
                        updateAllEmails(newEmail);
                      }}
                    >
                      Add +
                    </button>
                  </div>
                  <div class="selectedStackDiv">
                    {allEmails?.map((item, i) => {
                      return (
                        <div class="selectedStackItem" key={`${item}_${i}`}>
                          <div
                            class="close"
                            onClick={() => {
                              setAllEmails(
                                allEmails?.filter((i) => i !== item)
                              );
                            }}
                          >
                            <h3>X</h3>
                          </div>
                          <a style={{ color: "white" }}>{item}</a>
                        </div>
                      );
                    })}
                  </div>
                  <h6 style={{ color: "red" }}>{error}</h6>
                  <div className="btnSearch" onClick={confirmChanges}>
                    <h3>Confirm Changes</h3>
                  </div>
                </div>
              )}
              <button
                className="e-control e-btn "
                style={{
                  background: "white",
                  color: "black",
                  marginLeft: "auto",
                  height: 30,
                }}
                onClick={() => setShowAddEmails(true)}
              >
                Edit Emails
              </button>
            </div>
            <div className="bottom light">
              <p>
                {" "}
                Emails you add here will be notified when resource are
                undergoing status changes, such as becoming available or
                unavailable.
              </p>
            </div>
          </div>
        )}
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Monthly NewsLetter</label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) =>
                props.updateVendorData("communication", e.data)
              }
              id="textboxEle"
              name="monthlyNewsletter"
              mode="Inline"
              type="DropDownList"
              value={
                props?.settings?.communication?.monthlyNewsletter ? "YES" : "NO"
              }
              model={props.dropDownModel}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              We may periodically send you updates on new upcoming platform
              features, clients, and partnerships
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communication;
