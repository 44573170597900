import React from "react";

function currencyTemplate(props) {
  const valueData = () => {
    let value = props?.[props?.column?.field];
    if (props?.type === "USD") {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: props?.type,
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: props?.precision, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value);
    }
    return value;
  };

  return <div className="currency_template">{valueData()}</div>;
}

export default currencyTemplate;
