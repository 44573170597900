import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    padding: "10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f9",
    "&::before": {
      backgroundColor: "#f5f5f9",
    },
    position: "absolute",
    top: "0px !important",
    left: "-13px !important",
    width: "20px !important",
    height: "20px !important",
    transform: "rotate(0deg) !important",
    clipPath: "polygon(0 0, 100% 0, 100% 100%) !important",
    // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)",
  },
}));
