import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  GetNowTimeStamp,
  GetRefFromPath,
  getCollectionRefFromPath,
} from "./globals/services";
// import { analytics } from "./Firebase/firebase";

function LoginInputs({
  setEmail,
  setPassword,
  firebase_context,
  setCredErr,
  email,
  password,
  logincheck,
  history,
  credErr,
  newUser,
  registerEmail,
  registerPass,
  setNewUser,
  firebase,
}) {
  if (newUser) {
    setEmail(registerEmail);
    setPassword(registerPass);
  }

  const [isLoading, setIsLoading] = useState(false);

  async function createAccounts(
    isVendorAccount,
    userDoc,
    vendorRef = null,
    devRef = null,
    spryteDevAccountData
  ) {
    var devsReportingDocData = {};
    var devDocData = {};
    var clientDocRef = null;
    var devsReportingDocRef = null;
    const _addedOn = GetNowTimeStamp();

    // Creating client account if it doesn't exists.
    if (!spryteDevAccountData?.clientId) {
      var clientDocData = {
        companyName: userDoc?.["companyInfo"]?.["companyName"],
        name: userDoc["first"] + " " + userDoc["last"],
        phone: userDoc["phone"],
        createdOn: _addedOn,
        orgRef: userDoc["orgRef"],
        public: { publicUrlEnabled: false },
        mainAccountHolder: GetRefFromPath(`users/${userDoc.id}`),
      };
      clientDocRef = await getCollectionRefFromPath("client").add(
        clientDocData
      );
    }

    if (isVendorAccount)
      devDocData = {
        first: userDoc["first"],
        last: userDoc["last"],
        vendorRef: vendorRef,
        addedOn: _addedOn,
        draft: true,
        status: false,
        orgRef: userDoc["orgRef"],
        userRef: GetRefFromPath(`users/${userDoc.id}`),
      };

    if (!spryteDevAccountData?.devsReportingId) {
      // Creating devsReporting account.
      devsReportingDocData = {
        avatarURL: userDoc?.["avatar"] ?? "",
        city: null,
        companyName: userDoc?.["companyInfo"]?.["companyName"],
        companyWebsite: userDoc?.["companyInfo"]?.["website"],
        firstName: userDoc["first"],
        lastName: userDoc["last"],
        phone: userDoc["phone"],
        freelancer: false,
        geoTag: firebase?.createGeoPoint(-18.1236158, 178.4279689) ?? null,
        gitHubHandle: "",
        linkedInProfile: "",
        position: "",
      };
      if (!isVendorAccount) devsReportingDocData["devId"] = devRef;
      devsReportingDocRef = await getCollectionRefFromPath("devsReporting").add(
        devsReportingDocData
      );
      if (isVendorAccount) {
        devDocData["devsReportingId"] = devsReportingDocRef;
        // Creating dev Document.
        var devsDocRef = await getCollectionRefFromPath("devs").add(devDocData);
        // Linking devsReporting and dev documents.
        await devsReportingDocRef.update({ devId: devsDocRef });
      } else {
        await devRef?.update({ devsReportingId: devsReportingDocRef });
      }
    } else {
      // Fetching devsReporting document to see if dev document is linked.
      const devsReportingDocSnap =
        await spryteDevAccountData?.devsReportingId?.get();
      devsReportingDocData = devsReportingDocSnap?.data();
      if (!devsReportingDocData?.devId) {
        if (!isVendorAccount) {
          await spryteDevAccountData?.devsReportingId?.update({
            devId: devRef,
          });
        } else {
          // Creating and linking dev document if doesn't exists.
          devDocData["devsReportingId"] = spryteDevAccountData?.devsReportingId;
          var devsDocRef = await getCollectionRefFromPath("devs").add(
            devDocData
          );
          await spryteDevAccountData?.devsReportingId?.update({
            devId: devsDocRef,
          });
        }
      }
    }

    if (Object.keys(spryteDevAccountData).length) {
      // Linking newly created accounts which ever were missing to users spryte-dev account document.
      var updatedSpryteDevAccount = {};
      if (clientDocRef) updatedSpryteDevAccount["clientId"] = clientDocRef;
      if (devsReportingDocRef)
        updatedSpryteDevAccount["devsReportingId"] = devsReportingDocRef;
      if (Object.keys(updatedSpryteDevAccount).length)
        await GetRefFromPath(`users/${userDoc.id}/accounts/spryte-dev`).update(
          updatedSpryteDevAccount
        );
    } else {
      // For brand new spryte-dev account creating user acocunt document linking newly created client and devsReporting accounts.
      var newSpryteDevAccount = {
        status: true,
        clientId: clientDocRef,
        devsReportingId: devsReportingDocRef,
        role: GetRefFromPath(
          "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/CLIENT"
        ),
      };
      await GetRefFromPath(`users/${userDoc.id}/accounts/spryte-dev`).set(
        newSpryteDevAccount
      );
    }
  }

  function submitLogin() {
    setIsLoading(true);
    setCredErr("");
    setTimeout(() => {
      logincheck(email, password);
      setNewUser(false);

      firebase_context
        .doSignInWithEmailAndPassword(email, password)
        .then(async (doc) => {
          const getPartnerAccountDoc = await firebase.db
            .collection(`users/${doc.user.uid}/accounts`)
            .doc("spryte-partner")
            .get();

          let accountData = getPartnerAccountDoc.data();
          let updates = {};
          updates["lastLogin"] = new Date();

          // Track Login Analytics & Identify User
          const tUID = firebase_context.auth.currentUser.uid;
          const temail = firebase_context.auth.currentUser.email;
          let data = {
            email: temail,
          };
          if (accountData?.vendorRef) {
            setCredErr("");
            data["vendor id"] = accountData?.vendorRef.id;

            //update the last Login in the vendor document
            await firebase.updateVendor(accountData?.vendorRef.id, updates);
            const uid = doc.user.uid;
            //Update the lastLogin in the user Document
            await firebase.updateUserPartnerAccount(uid, updates);
          }
          if (accountData?.devRef) {
            data["dev id"] = accountData?.devRef?.id;
            await firebase.updateDev(accountData?.devRef?.id, updates);
          }

          // Fetching user document data.
          let userDoc = await firebase.getCurrentUserDoc();

          // User's company ref that is linked. if not found a company will be searched based on user's email domain and linked.
          let userCompanyRef = userDoc?.["companies"]?.[0] ?? null;

          if ((userDoc?.companies?.length ?? 0) < 1) {
            // Check, Fetch and link company document with user's document
            const company = await getCollectionRefFromPath("companies")
              .where(
                "crm.data.properties.domain.value",
                "==",
                userDoc["email"].split("@")[1]
              )
              .get();
            if (company.docs.length) {
              userCompanyRef = company?.docs?.[0]?.ref ?? null;
              var companyData =
                company?.docs?.[0]?.data()?.crm?.data?.properties;
              const companyInfoMap = {
                companyName: companyData?.name?.value ?? "",
                industry: companyData?.industry?.value ?? "",
                numEmployees: companyData?.numberofemployees?.value ?? "0",
                website: companyData?.domain?.value ?? "",
              };
              userDoc["companies"] = [userCompanyRef];
              userDoc["companyInfo"] = companyInfoMap;
              await GetRefFromPath(`users/${userDoc.id}`).update({
                companies: [userCompanyRef],
                companyInfo: companyInfoMap,
              });
            }
          } else if (
            (!userDoc?.["companyInfo"]?.["companyName"] ||
              userDoc?.["companyInfo"]?.["companyName"] === "") &&
            userDoc?.["companies"]?.[0]
          ) {
            // Updating user's document company Info with the company linked if missing.
            const companyDocSnap = await userDoc?.["companies"]?.[0]?.get();
            const companyData =
              companyDocSnap?.data()?.crm?.data?.properties ?? {};
            const companyInfoMap = {
              companyName: companyData?.name?.value ?? "",
              industry: companyData?.industry?.value ?? "",
              numEmployees: companyData?.numberofemployees?.value ?? "0",
              website: companyData?.domain?.value ?? "",
            };
            userDoc["companyInfo"] = companyInfoMap;
            await GetRefFromPath(`users/${userDoc.id}`).update({
              companyInfo: companyInfoMap,
            });
          }
          // Check and link contact document
          if (!userDoc?.contactRef) {
            let _existingContact;
            const existingContact = await getCollectionRefFromPath("/contacts")
              .where("email", "==", userDoc["email"])
              .limit(1)
              .get();
            if (existingContact.docs.length > 0) {
              _existingContact = existingContact.docs[0];
            }
            if (!_existingContact) {
              const existingContact = await getCollectionRefFromPath(
                "/contacts"
              )
                .where("crm.data.properties.email", "==", userDoc["email"])
                .limit(1)
                .get();
              if (existingContact.docs.length > 0) {
                _existingContact = existingContact.docs[0];
              }
            }
            let contactDoc = _existingContact?.ref;
            if (!contactDoc) {
              contactDoc = await getCollectionRefFromPath("contacts").add({
                email: userDoc["email"],
                firstname: userDoc["first"],
                lastname: userDoc["last"],
                phone: userDoc["phone"],
                position: "",
                spryteCompanyRef: userCompanyRef,
              });
            }
            userDoc["contactRef"] = contactDoc;
            await GetRefFromPath(`users/${userDoc.id}`).update({
              contactRef: contactDoc,
            });
          }

          // Creating required accounts for the logged in user for normalised login/registration
          // if (accountData?.vendorRef) {
          const getSpryteDevAccountDoc = await firebase.db
            .collection(`users/${userDoc.id}/accounts`)
            .doc("spryte-dev")
            .get();
          if (getSpryteDevAccountDoc.exists) {
            const spryteDevAccountData = getSpryteDevAccountDoc?.data() ?? {};
            if (
              !spryteDevAccountData?.clientId ||
              !spryteDevAccountData?.devsReportingId
            ) {
              await createAccounts(
                accountData?.vendorRef ? true : false,
                userDoc,
                accountData?.vendorRef,
                accountData?.devRef,
                spryteDevAccountData
              );
            }
          } else {
            await createAccounts(
              accountData?.vendorRef ? true : false,
              userDoc,
              accountData?.vendorRef,
              accountData?.devRef,
              {}
            );
          }
          // }

          // await analytics.identify(tUID, data);
          // //Track Analytics
          // await analytics.track("Login", {});

          // Move to home - successful sign-in
          history("/");
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.message ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            setCredErr(err.message);
          } else if (
            err.message ===
            "The password is invalid or the user does not have a password."
          ) {
            setCredErr(err.message);
          } else {
            setCredErr("Something went wrong. Please try again. Or contact us");
          }
        });
    }, 1100);
  }

  return (
    <div className="login-div">
      <h1>Log in to your account</h1>
      <div className="input-div">
        <p>Email</p>
        <input onChange={(e) => setEmail(e.target.value)} value={email}></input>
        <div
          className="forgot"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <p>Password</p>
        </div>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          id="pw-input"
        ></input>
        <Link to="/pw-forget" variant="body2" className="pw-forget">
          <span
            style={{
              color: "rgb(66, 158, 255)",
              fontSize: "1.25rem",
              fontWeight: "600",
            }}
          >
            Forgot your password?
          </span>
        </Link>
        <div className="signed-in">
          <input type="checkbox" checked readOnly />
          <p>Stay signed in</p>
        </div>
        <div className="button-div">
          {isLoading ? (
            <div className="loading-ctr">
              <div className="pulse-container">
                <div className="pulse-bubble pulse-bubble-1"></div>
                <div className="pulse-bubble pulse-bubble-2"></div>
                <div className="pulse-bubble pulse-bubble-3"></div>
              </div>
            </div>
          ) : (
            <a
              className="login-btn"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setIsLoading(true);
                submitLogin();
              }}
            >
              Authenticate
            </a>
          )}
        </div>
      </div>
      <br></br>
      <p className="error">{credErr}</p>
    </div>
  );
}

export default LoginInputs;
