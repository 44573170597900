import React, { useRef } from "react";
import { InputEl, Knobs, ToggleCover, ToggleLayer } from "./style";

const SpToggle = ({
  knobColor = "#FFFFFF",
  inactivecolor = "#06DE6A",
  activecolor = "#979797",
  onChange = null,
  checked = false,
}) => {
  const inputRef = useRef(null);

  const handleToggleClick = (e) => {
    // Prevent the default action
    e.preventDefault();

    if (inputRef.current) {
      // Toggle the checked state
      const newChecked = !inputRef.current.checked;

      // Update the input's checked state
      inputRef.current.checked = newChecked;

      // Call the onChange prop if it exists
      if (onChange) {
        // Create a synthetic event
        const syntheticEvent = {
          target: inputRef.current,
          type: "change",
          preventDefault: () => {},
        };
        onChange(syntheticEvent);
      }
    }
  };

  return (
    <ToggleCover
      className={`button r _spToggle`}
      activecolor={activecolor}
      inactivecolor={inactivecolor}
      knobcolor={knobColor}
      onClick={handleToggleClick}
    >
      <InputEl
        type="checkbox"
        className="checkbox _spToggleCheckbox"
        onChange={onChange}
        checked={checked}
        ref={inputRef}
      />
      <Knobs className="_spToggleknobs" knobcolor={knobColor}>
        <span></span>
      </Knobs>
      <ToggleLayer
        className="_spToggleLayer"
        activecolor={activecolor}
      ></ToggleLayer>
    </ToggleCover>
  );
};

export default SpToggle;
