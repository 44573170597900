import React, { useState } from "react";
import AddTesti from "./dialog/AddTesti";
import expl from "../../../images/profile2.png";
import { deleteFileFromStorage } from "../../../globals/services";

const Testimonia = (props) => {
  //trigger to open the dialog box
  const [isAdd, setIsAdd] = useState(false);

  //testimonals data
  const [authorName, setAuthorName] = useState("");
  const [authorPosition, setAuthorPosition] = useState("");
  const [authorCompany, setAuthorCompany] = useState("");
  const [authorAvatar, setAuthorAvatar] = useState("");
  const [authorQuote, setAuthorQuote] = useState("");
  const [displayed, setDisplayed] = useState(false);
  const [featured, setFeatured] = useState(false);

  //true when the edit button is clicked
  const [isEdit, setIsEdit] = useState(false);

  //setting the testimonial index
  const [index, setIndex] = useState(0);

  /**
   * Trigger when the delete button is clicked
   * use the index of the element to delete then delete the item from the array
   * @param {*} index => index of the testimonial to delete
   */
  const delReview = async (index) => {
    let reviews = [...props.reviews.testimonials];
    const res = await deleteFileFromStorage(reviews[index].authorAvatar);
    // console.log(res);
    reviews.splice(index, 1);
    let updates = {
      reviews: {
        verifiedClientReviews: props.reviews.verifiedClientReviews - 1,
        testimonials: reviews,
        lastUpdated: new Date(),
      },
    };

    await props.firebase.updateVendor(props.vendor.id, updates);

    props.isInitUpdated(true); //update initial vendor account(superUser)
    props.isVendorUpdated(true); //update selected vendor data
  };

  /**
   * Trigger when the add button is clicked to set all state to the initials
   */
  const onAdd = () => {
    setAuthorName("");
    setAuthorPosition("");
    setAuthorCompany("");
    setAuthorQuote("");
    setAuthorAvatar("");
    setIsAdd(true);
  };

  /**
   * Will trigger when the edit button is clicked to change the testimonials states
   * @param {object} testimonial => the selected reviews data
   * @param {number} index => the selected review index
   */
  const onEdit = (testimonial, index) => {
    setAuthorName(testimonial.author);
    setAuthorPosition(testimonial.authorPosition);
    setAuthorCompany(testimonial.authorCompany);
    setAuthorQuote(testimonial.testimonialText);
    setAuthorAvatar(testimonial.authorAvatar);
    setDisplayed(testimonial.display);
    setFeatured(testimonial.featured);
    setIndex(index);
    setIsEdit(true);
    setIsAdd(true);
  };

  return (
    <div className="stat_content_data stat_content_rev row testimonials">
      {isAdd ? (
        <div className="addData">
          <AddTesti
            isAdd={isAdd}
            setIsAdd={setIsAdd}
            vendorId={props.vendor.id}
            testimonials={props.reviews?.testimonials ?? []}
            firebase={props.firebase}
            verifiedClientReviews={props.reviews?.verifiedClientReviews ?? 0}
            authorName={authorName}
            authorPosition={authorPosition}
            authorQuote={authorQuote}
            authorCompany={authorCompany}
            authorAvatar={authorAvatar}
            displayed={displayed}
            featured={featured}
            setAuthorName={setAuthorName}
            setAuthorPosition={setAuthorPosition}
            setAuthorCompany={setAuthorCompany}
            setAuthorAvatar={setAuthorAvatar}
            setAuthorQuote={setAuthorQuote}
            index={index}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isVendorUpdated={props.isVendorUpdated}
            isInitUpdated={props.isInitUpdated}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="stat_content_data-title">
        <h4>Testimonials</h4>
      </div>

      <div className="stat_content_data-content testimonials_content">
        <div className="column">
          <div className="row top testimonials_content__top">
            <label className="vendor-info">
              You have {props.reviews?.testimonials?.length ?? "no"}{" "}
              testimonials
            </label>
            {/* <p>{props.reviews.source}</p> */}
            <button className="btn" onClick={() => onAdd()}>
              Add more here
            </button>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              Testimonials are one of the most important assets which help
              clients choose your brand. Testimonials on the Spryte Platform
              must be real. The use of fake testimonials will suspend your
              account.
            </p>
          </div>
        </div>
        <div className="testimonials_list column">
          {props.reviews?.testimonials?.map((testimonial, i) => {
            // console.log(testimonial);
            return (
              <div key={i} className="testimonial row">
                <div className="testimonial_author row">
                  <div className="testimonial_author__avatar">
                    <img
                      src={testimonial.authorAvatar ?? expl}
                      className="testimonial_author__avatar-img"
                      alt="Avatar"
                    />
                  </div>
                  <div className="testimonial_author__info">
                    <h3>{testimonial.author}</h3>
                    <div className="testimonial_author__info-position row">
                      <p id="company">
                        {testimonial.authorPosition} at{" "}
                        {testimonial.authorCompany}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="testimonial_description">
                  <p id="italic">
                    <q>{testimonial.testimonialText}</q>
                  </p>
                </div>
                <div className="testimonial_actions">
                  <button
                    id="testi_edit"
                    className="btn"
                    onClick={() => onEdit(testimonial, i)}
                  >
                    Edit
                  </button>
                  <button
                    id="testi_delete"
                    className="btn"
                    onClick={() => delReview(i)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonia;
