import { Divider } from "@mui/material";
import React, { useMemo, useState } from "react";
import SpBtn from "../../../globals/SpBtn";
import PartnerDev from "./RosterTab/PartnerDev";
// import PartnerDev from "./RosterTab/PartnerDev";

const RosterTab = ({
  selectedPartnerDevs,
  allTucPositions,
  tucsData,
  selectedUserRole,
  setSelectedTabIndex,
  setShowDevProfile,
  selectedPartner,
  listData,
  setShowUploadDevs,
  showUpdateDev,
  setShowUpdateDev,
  devDataToUpdate, 
  setDevDataToUpdate,
}) => {
  const [searchedDevName, setSearchedDevName] = useState("");
  const isOpenToVendor = useMemo(() => {
    if (listData?.open) return true;
    return listData?.partners?.some(
      (ref) => selectedPartner?.ref?.id == ref?.id
    );
  }, [selectedPartner?.ref?.id, listData?.partners, listData?.open]);

  return (
    <div className=" _spReqTeam--OptionThird__details--right_matches">
      <div>
        <SpBtn
          label={"Upload Resource"}
          onClick={() => setShowUploadDevs(true)}
          sx={{ width: "100%" }}
        />
        <Divider
          sx={{
            width: "70%",
            height: "1px",
            marginLeft: "15%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <input
          type={"text"}
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "5px",
            padding: "5px",
          }}
          placeholder="Search for resource by name"
          value={searchedDevName}
          onChange={(e) => {
            setSearchedDevName(e.target.value?.toLowerCase());
          }}
        />
      </div>
      {selectedPartnerDevs?.map((member, i) => {
        if (searchedDevName != "") {
          if (
            member?.first?.toLowerCase()?.match(searchedDevName) ||
            member?.last?.toLowerCase()?.match(searchedDevName)
          )
            return (
              <PartnerDev
                member={member}
                key={member?.devDocID}
                allTucPositions={allTucPositions}
                tucsData={tucsData}
                selectedUserRole={selectedUserRole}
                setSelectedTabIndex={setSelectedTabIndex}
                setShowDevProfile={setShowDevProfile}
                isOpenToVendor={isOpenToVendor}
                showUpdateDev={showUpdateDev}
                setShowUpdateDev = {setShowUpdateDev}
                devDataToUpdate={devDataToUpdate} 
                setDevDataToUpdate={setDevDataToUpdate}
              />
            );
        } else {
          return (
            <PartnerDev
              member={member}
              key={member?.devDocID}
              allTucPositions={allTucPositions}
              tucsData={tucsData}
              selectedUserRole={selectedUserRole}
              setSelectedTabIndex={setSelectedTabIndex}
              setShowDevProfile={setShowDevProfile}
              isOpenToVendor={isOpenToVendor}
              showUpdateDev={showUpdateDev}
              setShowUpdateDev = {setShowUpdateDev}
              devDataToUpdate={devDataToUpdate} 
              setDevDataToUpdate={setDevDataToUpdate}
            />
          );
        }
      })}
    </div>
  );
};

export default RosterTab;
