import styled from "styled-components";

export const DevCardWrapper = styled.div`
  border-radius: ${({ borderrad }) => (borderrad ? borderrad : "15px")};
  background-color: ${({ wrapbg }) => (wrapbg ? wrapbg : "white")};
  max-width: ${({ wrapwidth }) => (wrapwidth ? wrapwidth : "fit-content")};
  padding: ${({ wrappad }) => (wrappad ? wrappad : "24px")};
  box-shadow: ${({ wrapsha }) =>
    wrapsha ? wrapsha : "18px 15px 69px rgba(0, 0, 0, 0.05)"};
  gap: 15px;
  height: 100%;
  max-height: 590px;
  @media (max-width: 769px) {
    max-height: 600px;
  }
  @media (max-width: 596px) {
    height: 100%;
    max-height: fit-content;
  }
`;
export const DevUID = styled.span`
  text-align: center;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 400;
`;
