import React, { useMemo } from "react";
import {
  SingleHeadTab,
  TabBottom,
  TabTop,
  TabTopicRate,
  TopicCount,
} from "./style";
import { renderDevRates } from "../../SpMatchCard/devProfile/DevProfile";
import { calculateScoreFromPercentile } from "../../helpers";

function SingleTabHead({
  cls,
  item,
  topicCount = 1,
  topicTotal = 5,
  topicLabel = "Topic",
  rate = 2,
  title = "About Me",
  isActive = "0",
  showScore = false,
  scoreText = "Overall Score",
  scoreMax = 5,
  showRate = true,
  score = 2.5,
  ...props
}) {
  const renderDevRate = useMemo(() => {
    if (showRate) {
      const scoreValue = calculateScoreFromPercentile(item?.score ?? 0, 5);
      return Math.round(scoreValue * 100) / 100;
    }
    return null;
  }, [showRate, item?.score]);

  return (
    <SingleHeadTab
      className={`_spAssHead--singleTab ${cls}--singleTab`}
      isActive={isActive}
      {...props}
    >
      <TabTop className={`_spAssHead--singleTab-top ${cls}--singleTab-top`}>
        <TopicCount
          className={`_spAssHead--singleTab-topicCount ${cls}--singleTab-topicCount`}
        >
          <span>
            {topicLabel} {topicCount}/{topicTotal}
          </span>
        </TopicCount>
        {showRate && (
          <TabTopicRate
            className={`_spAssHead--singleTab-topicRate ${cls}--singleTab-topicRate`}
          >
            {/* Star rating */}
            {renderDevRates(
              "10px",
              "10px",
              renderDevRate,
              "#6449ff",
              5,

              "_spAssHead--singleTab-topicRate--rating"
            )}
            <span>{renderDevRate} / 5</span>
          </TabTopicRate>
        )}
      </TabTop>
      <TabBottom
        className={`_spAssHead--singleTab-bottom ${cls}--singleTab-bottom`}
        isActive={"1"}
      >
        <h5 className="_spAssHead--singleTab-bottom_title">{title}</h5>
        {showScore && (
          <div className="_spAssHead--singleTab-bottom_scoreInfo">
            {scoreText}{" "}
            <span className="_spAssHead--singleTab-bottom_scoreInfo--score">
              {score}
            </span>{" "}
            / {scoreMax}
          </div>
        )}
      </TabBottom>
    </SingleHeadTab>
  );
}

export default SingleTabHead;
