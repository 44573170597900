import React from "react";
import { SampleBase } from "../../sample-base";

export default class ConfirmDevInfo extends SampleBase {
  constructor() {
    super(...arguments);
    this.state = {
      x: {},
    };
    this.status = ["Active", "Inactive"];
  }

  async scrapeResumes(location) {
    const configs = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: location,
      }),
    };
    const sendPotentialLocations = this.props.firebase.spryteCloudUrl(
      "sendPotentialLocations"
    );
    try {
      const response = await fetch(sendPotentialLocations, configs);
      const jsonResponse = await response.json();
      this.setState({ x: jsonResponse[0].locationData.country });
      return jsonResponse;
    } catch (error) {
      console.log(error);
    }
  }

  handChange(e) {
    const personalInfo = e.target.id;
    let value = e.target.value;
    // latitude, longitude and sprintRate are always float
    if (personalInfo === "hourlyRate") {
      value = parseFloat(value) >= 10 ? parseFloat(value) : 10;

      // We don't want ratings to have decimal values or be greater than 4 or
      // less than 1
    } else if (personalInfo === "rating") {
      value = Math.floor(value) > 4 ? 4 : Math.floor(value);
      value = Math.abs(value);
    }
    this.props.editDevInfo(this.props.resumeName, personalInfo, value);
  }

  render() {
    this.positions = this.props?.allPositions?.map((el) => el.label) ?? [];

    return (
      <div className="dev_info">
        <div>
          <label>First Name</label>
          <input
            id="first"
            type="text"
            value={this.props.devInfo.first}
            onChange={(e) => this.handChange(e)}
          />
        </div>

        <div>
          <label>Last Name</label>
          <input
            id="last"
            type="text"
            value={this.props.devInfo.last}
            onChange={(e) => this.handChange(e)}
          />
        </div>

        <div>
          <label>Position</label>
          <select
            id="position"
            value={this.props.devInfo.position}
            onChange={(e) => this.handChange(e)}
          >
            {this.positions.map((position, index) => {
              return (
                <option key={index} value={position}>
                  {position}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label>Hourly Rate($)</label>
          <input
            id="hourlyRate"
            type="number"
            min="10"
            value={this.props.devInfo.hourlyRate}
            onChange={(e) => this.handChange(e)}
          />
        </div>

        <div>
          <label>Status</label>
          <select
            id="status"
            value={this.props.devInfo.status}
            onChange={(e) => this.handChange(e)}
          >
            {this.status.map((stat, i) => {
              return (
                <option value={stat} key={i}>
                  {stat}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label>Rating</label>
          <input
            id="rating"
            type="number"
            min="1"
            max="4"
            step="1"
            value={this.props.devInfo.rating}
            onChange={(e) => this.handChange(e)}
          />
        </div>

        <div>
          <label>Location</label>
          <input
            id="locTag"
            type="text"
            value={this.props.devInfo.locTag}
            onChange={(e) => {
              this.handChange(e);
            }}
          />
        </div>

        <div>
          <label>Internal ID</label>
          <input
            id="devID"
            type="text"
            value={this.props.devInfo.devID}
            onChange={(e) => this.handChange(e)}
          />
        </div>
      </div>
    );
  }
}
