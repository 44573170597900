import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Page,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { SampleBase } from "../../../sample-base";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";

export default class Vacation extends SampleBase {
  constructor() {
    super(...arguments);
    this.toolbarOptions = ["Add", "Edit", "Delete"];
    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: "Dialog",
      // template: this.offDaysTemplate,
      showDeleteConfirmDialog: true,
    };
    this.validationRule = { required: true };
    this.orderidRules = { required: true, number: true };
    this.pageSettings = { pageCount: 5 };
  }

  checkForDateOverLap(dateRange1, dateRange2) {
    // dateRange1 is earlier than range2
    if (dateRange1.from < dateRange2.from && dateRange1.to < dateRange2.from) {
      return true;
      // dateRange1 is later than range1
    } else if (
      dateRange1.from > dateRange2.to &&
      dateRange1.to > dateRange2.to
    ) {
      return true;
      // Any other scenario and there is overlap
    } else {
      return false;
    }
  }

  // Function that gives the resource gridcomponent props to the dialog box when adding or editing devs
  // offDaysTemplate(props) {
  //   return <OffDaysTemplate {...props} />;
  // }

  async actionComplete(args) {
    // adjust dialog width and title
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.width = 500;
      // change the header of the dialog
      dialog.header =
        args.requestType === "beginEdit"
          ? "Edit Vacation Details"
          : "Add Vacation Details";
    }

    if (args.action === "add" || args.action === "edit") {
      // Error handling for to data is before the from date

      if (args.data.from > args.data.to) {
        toast.error(
          "To date cannot be prior to the From date. Changes have not been added."
        );

        return;
      }
      // Error handling for overlapping dates with any already existing dates
      for (const vacation in this.props.vacations) {
        if (this.checkForDateOverLap(vacation, args.data)) {
          toast.error(
            "Developer cannot have two vacation in the same time period. Please remove any overlapping dates"
          );

          return;
        }
      }
      if (args.action === "add") {
        const data = args.data;
        if (args.data?.from instanceof Date && !isNaN(args.data?.from)) {
          await this.props.firebase.createVac(this.props.devId, data);

          // Updating lastUpdated Key for the dev
          await this.props.firebase.updateDev(this.props.devId, {});
        } else {
          toast.error("Invalid Date");
        }
      }
      if (args.action === "edit") {
        const data = args.data;
        if (args.data?.from instanceof Date && !isNaN(args.data?.from)) {
          const { Id, ...update } = data;
          await this.props.firebase.updateVac(this.props.devId, Id, update);

          // Updating lastUpdated Key for the dev
          await this.props.firebase.updateDev(this.props.devId, {});
        } else {
          toast.error("Invalid Date");
        }
      }
    }
    const request = args.requestType;
    if (request === "delete") {
      // If the user adds an incorrect
      if (args.data.length && args.data[0].Id) {
        const data = args.data[0];
        const { Id } = data;
        await this.props.firebase.deleteVac(this.props.devId, Id);

        // Updating lastUpdated Key for the dev
        await this.props.firebase.updateDev(this.props.devId, {});
      }
    }
  }

  render() {
    return (
      <div
        className={
          this.props.devPage === "vacation"
            ? "control-section OffDays"
            : "hide_page"
        }
        id="Vacation"
      >
        <ToastContainer />
        <div className="resources_header">
          <div className="resources_header_left">
            <div className="title">
              <h3>Your Vacation Dates</h3>
              <FontAwesomeIcon icon={faPlane} className="icon" />
            </div>
            <p>
              Inform your company and clients of your upcoming vacation dates.
              Keep this up to date!
            </p>
          </div>
        </div>
        <GridComponent
          dataSource={this.props.vacation}
          ref={(grid) => (this.gridInstance = grid)}
          dataStateChange={this.dataStateChange}
          dataSourceChanged={this.dataSourceChanged}
          pageSettings={this.pageSettings}
          height="295px"
          toolbar={this.toolbarOptions}
          allowPaging={true}
          editSettings={this.editSettings}
          actionComplete={this.actionComplete.bind(this)}
        >
          <ColumnsDirective>
            {/* <ColumnDirective field='id' headerText='ID' width='130' textAlign='Center' validationRules={this.orderidRules} isPrimaryKey={true} visible={false}></ColumnDirective> */}
            {/* <ColumnDirective
              field="dateRange"
              textAlign="Center"
              headerText="From"
              width="130"
              validationRules={this.validationRule}
            ></ColumnDirective> */}
            <ColumnDirective
              field="from"
              headerText="From"
              width="130"
              textAlign="Center"
              editType="datepickeredit"
              format="yMd"
              validationRules={this.validationRule}
            ></ColumnDirective>
            <ColumnDirective
              field="to"
              headerText="To"
              width="130"
              textAlign="Center"
              editType="datepickeredit"
              format="yMd"
              validationRules={this.validationRule}
            ></ColumnDirective>
            <ColumnDirective
              field="description"
              headerText="Description"
              width="130"
              validationRules={this.validationRule}
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    );
  }
}
