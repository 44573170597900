import { Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React from "react";
import HubspotForm from "react-hubspot-form";

function Requests(props) {
  /**
   * Trigger every time the request is clicked and open the form
   */

  if (props.isRequest) {
    // close the modal when we click  outside the data-request_form box
    window.addEventListener("mouseup", (e) => {
      let subBox = document.getElementById("data-request_form");
      try {
        if (!subBox.contains(e.target)) {
          props.setIsRequest(false);
        }
      } catch (e) {
        // console.log(e)
        return;
      }
    });
  }

  return (
    <div className="request" id="data-request">
      <div className="request_form" id="data-request_form">
        <Close
          id="request_form-close"
          onClick={(e) => props.setIsRequest(false)}
        />
        <HubspotForm
          portalId="5340732"
          formId={props.formId}
          loading={<CircularProgress color="primary" size="small" />}
        />
      </div>
    </div>
  );
}

export default Requests;
