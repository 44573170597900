import React from "react";
import "../../../css/dan.css";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

export default function LefttTop(props) {
  return (
    <div className="header__left">
      <div className="header__left-vendor">
        <p>
          <span>{props.name ?? ""}</span>
        </p>
      </div>
      <div className="header__left-search">
        <div className="header__left-search--input" style={{ flex: 1 }}>
          <input
            type="text"
            value={props.searchValue}
            onChange={(e) => props.onInputChange(e.target.value)}
          />
        </div>
        {props.vendors && (
          <div className="header__left-result">
            {props.vendors.map((vendorDoc) => {
              return (
                <div
                  key={vendorDoc.id}
                  className="header__left-result--el"
                  onClick={() => props.handleOnSelect(vendorDoc.id)}
                >
                  {vendorDoc.data().companyName}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <p>All Vendors</p>
        <SwitchComponent
          id="isActivePartner"
          checked={props?.fetchActiveVendors}
          change={(e) => props.setFetchActiveVendors(e.checked)}
        ></SwitchComponent>
        <p>Active Vendors</p>
      </div>
    </div>
  );
}
