import React from "react";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import { SampleBase } from "./sample-base.js";
import devProgessTemplate from "./grid_templates/devProgressTemp";
import devTypeTemplate from "./grid_templates/typesTemplate";
import resumeDwload from "./grid_templates/resumeDwload";
import devDocs from "./grid_templates/devDocsTemplate.js";
import { ToastContainer } from "react-toastify";
import { GlobalVendorContex } from "./Firebase";
import ResourcesGrid from "./pages/Resources/ResourcesGrid.js";
import { CircularProgress } from "@mui/material";
// import DevDetails from './pages/Resources/Profile/DevDetails'

// REMINDER: the Firebase class from ./Firebase/firebase.js is passed to
// this file with props using the context api
// bind() is an inbuilt method in React that is used to pass the data as an argument to the function of a class based component.
export default class Resource extends SampleBase {
  static contextType = GlobalVendorContex;
  constructor(props) {
    super(...arguments);
    this.key = null;
    // Initialize variable for devName so that when a dev is selected in
    // the Developer Roster, it will change the state of devName

    this.latitude = 0.0;
    this.longitude = 0.0;
    this.requirements = [];
    this.profileProgress = 0;
    this.hoursInWorkDay = 0;
    this.profileUrl = "";
    this.loading = false;

    // this.crossVendor = '';

    // toolbarOptions used with the syncfusion grid component in the
    // sections below, these are predefined by the syncfusion library
    // and you just pick and choose which ones you want
    // https://ej2.syncfusion.com/react/documentation/grid/tool-bar/

    // Default permissions and settings for the current user
    this.editSettings = {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      showDeleteConfirmDialog: false,
    };

    //this.stacks = stacks;
    this.techs = {
      params: {},
    };
    this.state = {
      // selDevID is the document ID for the selected dev in the devs
      // collection. Not to be confused with dev ID which is the vendors
      // internal resource ID for the dev
      selDevId: null,
      vendorRef: null,
      // Initalize array to store stacks in technologies subcollection in
      // the techstack150r collection
      stacks: [],
      stacksObject: [],
      stacksDropDown: [],
      stacksBox: [],
      // Initalize array to store categories in the subcollcetion of the
      // categories collection
      categories: {},
      categoriesObject: [],
      categoriesDropDown: [],
      categoriesBox: [],
      // Initalize array to store domain experiences from the industry
      // subcollection of the sector collection
      domainExperiences: {},
      domainExperiencesObject: [],
      domainDropDown: [],
      domainBox: [],
      availability: [],
      vacation: [],
      holidays: [],
      devRates: [],
      user: {},
      vendor: {},
      selDevData: {},
      isViewResources: true,

      // Initialize array to store stacks associated with a dev for when
      // a dev is selected in the Developer Roster. Stacks in this.detail
      // will display in the Technology Stacks section
      detail: [],
      projExp: [],
      // Initialize array to store domain experience associated with a dev
      // for when a dev is selected in the Developer Roster. Domain
      // experience in this.details will display in the Domain Experience
      // section
      domainExp: [],
      crossVendor: false,
      devInternalId: "1",
      loader: true,
      showViewDev: false,
      vendorCrossVendor: false,
      showDevData: false,
      minContractTeamSize: 1,
      isDevAdded: false,
      isAddOrDup: false,
      positions: [],
      devsSnaps: [],
      devsNo: 0,
      dataSourceUpdated: false,
      shouldUpdateGrid: false,
      defaultPosition: {},
      maxApprovedRates: [],
    };

    // =====================================================================End code for status and rating =================================================
    // Code to enable drop down list in the Status column of the Dev Roster
    // section. Uses syncfusion components
    // https://ej2.syncfusion.com/react/documentation/grid/how-to/cascading-drop-down-list-with-grid-editing/
    // =================================================================================================================================================================

    this.interest = [
      { interest: "Interested" },
      { interest: "Not Interested" },
      { interest: "Slightly Interested" },
    ];
    // Parameters used in the interest ColumnDirective component
    this.interestParams = {
      create: (args) => {
        this.interestElem = document.createElement("input");
        return this.interestElem;
      },
      destroy: () => {
        this.interestObj.destroy();
      },
      read: () => {
        return this.interestObj.text;
      },
      write: (args) => {
        let value = "Interested";
        if (args.rowData.interest) {
          value = args.rowData.interest;
        }
        this.interestObj = new DropDownList({
          dataSource: new DataManager(this.interest),
          fields: { value: "interest", text: "interest" },
          floatLabelType: "Always",
          placeholder: "Interest",
          value: value,
        });
        this.interestObj.appendTo(this.interestElem);
      },
    };
    // List of level options to rate a devs skill level in a stack used in the level column
    // of the Technology stacks section
    this.level = [{ level: 1 }, { level: 2 }, { level: 3 }, { level: 4 }];
    // Parameters used in the level ColumnDirective component
    this.levelParams = {
      create: () => {
        this.levelElem = document.createElement("input");
        return this.levelElem;
      },
      destroy: () => {
        this.levelObj.destroy();
      },
      read: () => {
        return this.levelObj.text;
      },
      write: (args) => {
        let value = 1;
        if (args.rowData.skill) {
          value = args.rowData.skill;
        }
        this.levelObj = new DropDownList({
          dataSource: new DataManager(this.level),
          fields: { value: "level", text: "level" },
          floatLabelType: "Always",
          placeholder: "Level",
          value: value,
        });
        this.levelObj.appendTo(this.levelElem);
      },
    };

    // Set Validation and Order id rules for syncfusion components
    this.validationRule = { required: true };
    this.norequired = { required: false };

    this.numericValidationRule = { required: true, min: 0 };
    this.numericParams = {
      params: {
        format: "N0",
        decimals: 0,
        min: 0,
      },
    };
    this.orderidRules = { required: true, number: true };
  }

  // ///////////////////////////////// Component Did Mount /////////////////////////////////////////////////////////////////////////
  // Retrieve required data from firebase database
  async componentDidMount() {
    //Track Analytics
    // this.props.firebase.reportPageVisits(
    //   "Resources Page",
    //   this.props.allData?.isSuperPartner,
    //   {
    //     "Vendor Id": this.spryteData?.vendor.id,
    //     "Vendor Name": this.spryteData?.vendor.companyName,
    //   }
    // );

    //like original useEffect call
    this.setState({ loading: true });
    this.props.setShowDevData(false);

    //when the page reload we will set the isDup variable to false
    this.props.setIsDup(false);

    // Retrieve user data and the vendor data that are associated
    // with the user who is logged in from firebase database
    const user = await this.props.firebase.getCurrentUserDoc();
    // const vendor = await this.props.firebase.getCurrentVendorDoc();
    const vendor = await this.spryteData?.vendor;
    // Calculate the max security level for the current vendor
    this.maxSecLev = "L0";
    let maxRates = [];
    Object.entries(vendor.security).forEach((entry) => {
      const [key, value] = entry;

      if (value.approved && key > this.maxSecLev) {
        this.maxSecLev = key;
      }
      if (value.approved) maxRates.push(value);
    });
    maxRates.sort((a, b) => a.level - b.level);
    // If user logs out faster than the page renders one or both of these
    // will be null, in which case break
    if (!user || !vendor) {
      return;
    }

    this.setState({ loading: false, isDevAdded: false });
    let hoursInWorkDay = vendor?.settings?.ops?.hoursInWorkDay || 8;

    // Add retrieved data to the current state
    this.setState({
      user: user,
      vendor: this.props.userData?.searchedVendor ?? vendor,
      vendorCrossVendor: vendor?.settings?.ops.crossVendor,
      hoursInWorkDay: hoursInWorkDay,
      maxApprovedRates: maxRates,
    });
  }

  //////////////////////////////////////Component Did Update/////////////////////////////////////////////////////////////////////////
  /**
   * checks if the vendor has been changed then update the devs state;
   * @param {*} prevProps
   */
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.userData.vendor.id !== prevProps.userData.vendor.id) {
      if (this.props.userData.vendor.id) {
        //Changing positions state when superUser change user

        let hoursInWorkDay =
          this.props.userData.vendor.settings.ops.hoursInWorkDay || 8;
        this.setState({
          loading: false,
          hoursInWorkDay: hoursInWorkDay,
        });
      }
    }

    if (
      this.allPositions.length &&
      !Object.values(this.state.defaultPosition).length
    ) {
      const defaultPos = this.allPositions.filter(
        (el) => el.label === "Jr. Developer"
      );

      this.setState({
        defaultPosition: defaultPos[0],
      });
    }
  }

  componentWillUnmount() {
    try {
      this.props.setShowDevData(false);
    } catch (e) {}
  }

  // ==========================================================Code to make the rating and status states work=======================================================================
  // onQueryCellInfo(args) {
  //   // Behavior of status column in dev roster section
  //   // if (args.column.field === "status") {
  //   //   // If status is active give the text a green color and background
  //   //   if (args.cell.textContent === "Active") {
  //   //     args.cell.querySelector(".statustxt").classList.add("e-activecolor");
  //   //     args.cell.querySelector(".statustemp").classList.add("e-activecolor");
  //   //   }
  //   //   // If status is inactive give the text a red color and background
  //   //   if (args.cell.textContent === "Inactive") {
  //   //     args.cell.querySelector(".statustxt").classList.add("e-inactivecolor");
  //   //     args.cell.querySelector(".statustemp").classList.add("e-inactivecolor");
  //   //   }
  //   // }
  //   // Behavior of ratings column in dev roster section
  //   // if (args.column.field === "rating") {
  //   //   if (args.column.field === "rating") {
  //   //     for (let i = 0; i < args.data.rating; i++) {
  //   //       args.cell.querySelectorAll("span")[i].classList.add("checked");
  //   //     }
  //   //   }
  //   // }
  // }

  // Bind "this" to the necessary functions
  render() {
    this.spryteData = this.context;

    this.projectData = this.spryteData.projectsData;
    this.domainData = this.spryteData.domainData;

    this.allPositions = this.context?.orgPositions;
    this.vendor = this.context?.vendor;
    return (
      <div style={{ paddingBottom: "50px" }}>
        <ToastContainer />
        {this.state.loading ? (
          <CircularProgress color="primary" size="large" />
        ) : (
          <div className="control-pane resource">
            <div
              className={
                !this.props.showDevData ? "control-section" : "slide-left"
              }
            >
              <ResourcesGrid
                isSuper={this.props.allData.isSuperPartner}
                allowSorting={true}
                // queryCellInfo={this.onQueryCellInfo.bind(this)}
                allowPaging={true}
                allowSelection={true}
                allowExcelExport={true}
                setShowDevData={this.props.setShowDevData}
                editSettings={this.editSettings}
                allowPdfExport={true}
                allowGrouping={false}
                firebase={this.props.firebase}
                vendor={this.vendor}
                domainExperiencesObject={this.domainData.domainsObject}
                categoriesObject={this.projectData.projectsObject}
                hoursInWorkDay={this.state.hoursInWorkDay}
                validationRules={this.validationRule}
                minContractTeamSize={this.state.minContractTeamSize}
                progress={devProgessTemplate}
                devTypeTemplate={devTypeTemplate}
                positions={this.state.positions}
                resumeDwload={resumeDwload}
                devDocs={devDocs}
                vendorRef={this.vendor.vendorRef}
                allPositions={this.allPositions}
                defaultPosition={this.state.defaultPosition}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
