import React from "react";

const SuspendedAccount = () => {
  return (
    <div className="spd">
      <div className="spd_box">
        <h1>
          Your account has been temporarily suspended, please contact your
          administrator for details
        </h1>
      </div>
    </div>
  );
};

export default SuspendedAccount;
