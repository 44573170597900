export default function resumeDwload(props) {
  return (
    <div>
      {props.resume ?? props?.resumeData?.resumeUrl ? (
        <a
          href={props.resume ?? props.resumeData.resumeUrl}
          target="blank"
          className="link"
        >
          View
        </a>
      ) : (
        <p style={{ color: "#F05A56", fontSize: "12px" }}>No Resume</p>
      )}
    </div>
  );
}
