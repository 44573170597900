import React, { useState, useEffect } from "react";
// This is required to parse residual text once the search button is pressed.
import parseFreeFormText from "./functions/searchFunctions";
// import { analytics } from "../../../components/Firebase/firebase";
import { Cancel, Info } from "@mui/icons-material";
// Crypto is used for hashing the skill IDs.
import CryptoJS from "crypto-js";

function SearchComponent(props) {
  const [data, setData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [selectedStacks, setSelectedStacks] = useState([]);
  const [selectedStackImages, setSelectedStackImages] = useState([]);

  const [error, setError] = useState("");
  const [docIDObject, setDocIDObject] = useState({
    industry: {},
    subcollections: {},
    technologies: {},
  });
  const [searchText, setSearchText] = useState("");

  async function fetchAndSetData(e) {
    e.persist();
    const data = await props.firebase.getDocumentsByArrayMembershipInCollection(
      "technologies",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "rank",
      true,
      true,
      false,
      5
    );
    const industryDataFetch =
      await props.firebase.getDocumentsByArrayMembershipInCollection(
        "industry",
        e.target.value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "name",
        true,
        true,
        false,
        5
      );
    const categoriesData =
      await props.firebase.getDocumentsByArrayMembershipInCollection(
        "subcollections",
        e.target.value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "name",
        true,
        true,
        false,
        5
      );
    setSearchText(e.target.value);
    setData(data);
    setIndustryData(industryDataFetch);
    setCatData(categoriesData);
  }

  function addStack(stackName, stackImage, stackCollectionKey, stackID) {
    setError("");
    let placeholderString = "";

    docIDObject[stackCollectionKey][stackName] = stackID;
    setDocIDObject(docIDObject);
    const currentStacks = selectedStacks;
    const currentStackImages = selectedStackImages;
    if (!currentStacks?.includes(stackName)) {
      currentStacks.push(stackName);
      currentStackImages.push({ image: stackImage, name: stackName });
    }

    currentStacks.map(
      (stackName) =>
        (placeholderString +=
          currentStacks.length === 1 ? stackName : `${stackName}, `)
    );

    setSearchText("");
    setSelectedStacks([...currentStacks]);
    setSelectedStackImages([...currentStackImages]);
  }

  useEffect(() => {
    props.startingStacks?.map((stack) =>
      addStack(stack.name, stack.logoStorage, stack.collectionName, stack.id)
    );
  }, [props.startingStacks]);

  function removeStack(stackName) {
    setError("");
    delete docIDObject["technologies"][stackName];
    delete docIDObject["industry"][stackName];
    delete docIDObject["subcollections"][stackName];
    setDocIDObject(docIDObject);
    const currentStacks = selectedStacks;
    const index = currentStacks.indexOf(stackName);
    currentStacks.splice(index, 1);
    setSelectedStacks([...currentStacks]);

    // To remove stack image chip from UI (TODO: re-write without the above splice?)
    const currentStackImages = selectedStackImages;
    setSelectedStackImages([
      ...currentStackImages.filter(
        (stackMap) => stackMap.name.toUpperCase() !== stackName.toUpperCase()
      ),
    ]);
  }

  /**
   * This function creates a URL to push to, based upon the search criteria.
   *
   * @returns {String} Returns the URL to push to.
   */
  // function createURL() {
  //   // Get the category, industry, and stack names.
  //   const categoryNames = {
  //     key: "category",
  //     value: [
  //       ...Object.keys(docIDObject.subcollections).map((val) =>
  //         val.replace(/ /g, "_")
  //       ),
  //     ].join(" "),
  //   };
  //   const industryNames = {
  //     key: "industry",
  //     value: [
  //       ...Object.keys(docIDObject.industry).map((val) =>
  //         val.replace(/ /g, "_")
  //       ),
  //     ].join(" "),
  //   };
  //   const stackNames = {
  //     key: "stack",
  //     value: [
  //       ...Object.keys(docIDObject.technologies).map((val) =>
  //         val.replace(/ /g, "_")
  //       ),
  //     ].join(" "),
  //   };

  //   // Create the url.
  //   let url = "/results/pricing";

  //   // For each skill, append the names
  //   for (let skillURLObject of [industryNames, categoryNames, stackNames]) {
  //     if (!skillURLObject.value) continue;
  //     url +=
  //       (url.endsWith("pricing") ? "?" : "&") +
  //       skillURLObject.key +
  //       "=" +
  //       skillURLObject.value;
  //   }

  //   //
  //   return url;
  // }

  /**
   * This function hashes objects passed in, in line with the hashes produced by
   * the getMatches cloud function.
   */
  function computeQueryFamilyHash(queryData) {
    return CryptoJS.SHA256(JSON.stringify(queryData)).toString(
      CryptoJS.enc.Hex
    );
  }

  /**
   * This function creates a URL to push to, based upon the search criteria.
   *
   * @returns {String} Returns the URL to push to.
   */
  function createQueryFamilyHashURL() {
    // Hash the data.
    const queryFamilyHash = computeQueryFamilyHash({
      industryDomain: Object.values(docIDObject.industry).sort(),
      projectTypes: Object.values(docIDObject.subcollections).sort(),
      stacks: Object.values(docIDObject.technologies).sort(),
    });

    // Return the URL.
    return queryFamilyHash;
  }

  const onSearch = async () => {
    if (
      Object.keys(docIDObject?.technologies).length ||
      Object.keys(docIDObject?.industry).length ||
      Object.keys(docIDObject?.subcollections).length
    ) {
      const parsedFreeFormText = await parseFreeFormText(
        searchText,
        props.firebase
      );
      Object.entries(parsedFreeFormText).forEach(
        ([skillKey, skillValuesObject]) =>
          Object.entries(skillValuesObject).forEach(
            ([skillName, skillID]) =>
              (docIDObject[skillKey][skillName] = skillID)
          )
      );

      setDocIDObject(docIDObject);
      let combination = [
        ...Object.keys(docIDObject?.technologies),
        ...Object.keys(docIDObject?.industry),
        ...Object.keys(docIDObject?.subcollections),
      ];
      if (combination.length < 7) {
        const query = createQueryFamilyHashURL();
        let data = {
          status: false,
          queryRef: query,
          createdOn: new Date(),
          createdBy: props.userRef,
          lastUpdatedBy: props.userRef,
          lastUpdatedOn: new Date(),
          combination: combination,
          combCalc: Math.pow(2, combination.length) - 1,
          queryData: {
            industryDomain: Object.values(docIDObject?.industry),
            locationID: {
              data: "global",
              type: "global",
            },
            projectTypes: Object.values(docIDObject?.subcollections),
            stacks: Object.values(docIDObject?.technologies),
          },
        };
        props.setQueryData(data.queryData);

        props.firebase.db
          .collection("vendors")
          .doc(props.vendorId)
          .collection("generic")
          .add(data)
          .then((appQuery) => {
            // props.firebase.reportPageVisits(
            //   "Generic Created",
            //   props.isSuperPartner
            // );
            props.setPageLink(`https://sprytelabs.com/search-results/${query}`);
            props.setCombination(data.combination);
            props.setGenericId(appQuery.id);
            props.setIsPricingCreated(true);
            props.setIsSearchClick(false);
            props.search(
              [...Object.values(docIDObject?.subcollections)],
              [...Object.values(docIDObject?.technologies)],
              [...Object.values(docIDObject?.industry)],
              ["global"],
              "global"
            );
          });
        // TODO: Track when Market is added
        // analytics.track("Vendor Added Market", {
        //   vendorId: props.vendorId,
        //   technologies: docIDObject?.technologies,
        //   industries: docIDObject?.industry,
        //   projectTypes: docIDObject?.subcollections,
        // });
      } else {
        setError(
          `Please Remove ${
            combination.length - 6
          } of your selected combinations to be able to add a custom price`
        );
      }
    }
  };

  return (
    <div className="marketSearch request_form" id="marketSearchComponent">
      <div className="info-ctr">
        <Info className="market-info" />
        <p>
          Add any combination of up to 6 stacks, industries or categories". All
          the possible variations will also be computed. For example, if you add{" "}
          <span>"Python, JavaScript & Node.js"</span>, clients who search for
          Python, or Node.js, or any of the 7 possible combinations will find
          your pricing
        </p>
      </div>
      <div className="marketSearch_close">
        <Cancel
          className="marketSearch_close-icon"
          onClick={() => props.setIsSearchClick(false)}
        />
      </div>
      <div className="selectedStackDiv">
        {selectedStacks?.map((stackName) => {
          return (
            <div className="selectedStackItem" key={stackName + "CHOSEN"}>
              <div onClick={() => removeStack(stackName)} className="close">
                <h3>X</h3>
              </div>
              <p>{stackName}</p>
            </div>
          );
        })}
      </div>
      <input name="stackName" onChange={(e) => fetchAndSetData(e)} />
      <div
        className="stackDiv"
        style={{ marginBottom: data.length !== 0 ? "12.5px" : "0" }}
      >
        {data.length !== 0 ? (
          <h3 className="hrTitle">TECH STACKS</h3>
        ) : (
          <div></div>
        )}
        {data.length !== 0 ? <hr></hr> : <div></div>}
        {data?.map((stackItem) => {
          return (
            <div
              className="stackItem"
              key={stackItem?.data()?.["name"] + "STACK"}
              onClick={() => {
                addStack(
                  stackItem.data()?.["name"],
                  stackItem.data()?.["logoStorage"],
                  stackItem.ref.parent.id,
                  stackItem.ref.id
                );
              }}
            >
              <img src={stackItem.data()?.["logoStorage"]} alt="Tech Stack" />
              <h3>
                {!stackItem.data()?.["selected"]
                  ? stackItem.data()?.["name"]
                  : "SELECTED"}
              </h3>
            </div>
          );
        })}
      </div>
      <div
        className="stackDiv"
        style={{ marginBottom: data.length !== 0 ? "12.5px" : "0" }}
      >
        {catData.length !== 0 ? (
          <h3 className="hrTitle">CATEGORIES</h3>
        ) : (
          <div></div>
        )}
        {catData.length !== 0 ? <hr></hr> : <div></div>}
        {catData?.map((stackItem, i) => {
          return (
            <div
              className="stackItem"
              key={stackItem?.data()?.["name"] + "CATEGORY" + i}
              onClick={() => {
                addStack(
                  stackItem.data()?.["name"],
                  stackItem.data()?.["logoStorage"],
                  stackItem.ref.parent.id,
                  stackItem.ref.id
                );
              }}
            >
              <img
                src={stackItem.data()?.["logoStorage"]}
                alt="Tech Stack"
                style={{ opacity: ".85" }}
              />
              <h3>{stackItem.data()?.["name"]}</h3>
            </div>
          );
        })}
      </div>
      <div
        className="stackDiv"
        style={{ marginBottom: data.length !== 0 ? "12.5px" : "0" }}
      >
        {industryData.length !== 0 ? (
          <h3 className="hrTitle">INDUSTRIES</h3>
        ) : (
          <div></div>
        )}
        {industryData.length !== 0 ? <hr></hr> : <div></div>}
        {industryData?.map((stackItem) => {
          return (
            <div
              className="stackItem"
              key={stackItem?.data()?.["name"] + "INDUSTRY"}
              onClick={() => {
                addStack(
                  stackItem.data()?.["name"],
                  stackItem.data()?.["logoStorage"],
                  stackItem.ref.parent.id,
                  stackItem.ref.id
                );
              }}
            >
              <img
                src={stackItem.data()?.["logoStorage"]}
                alt="Tech Stack"
                style={{ opacity: ".85" }}
              />
              <h3>{stackItem.data()?.["name"]}</h3>
            </div>
          );
        })}
      </div>
      <div
        className="btnSearch"
        onClick={() => {
          onSearch();
        }}
      >
        <h3>Add Custom Pricing</h3>
      </div>
      {error !== "" ? (
        <div>
          <p style={{ color: "#EB3570", fontWeight: 600 }}>{error}</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SearchComponent;
