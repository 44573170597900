export default class Sprint {
  constructor(id, sprintName, sprintStartDate, sprintEndDate, projectId) {
    this.TaskId = id;
    this.TaskName = sprintName;
    this.StartDate = sprintStartDate;
    this.EndDate = sprintEndDate;
    this.subtasks = [];
    this.projectId = projectId;
  }

  /**
   * checks if the devs is for the specific sprint
   * if it's for that given sprint it will add the dev to the sprint
   * @param {object} dev
   */
  addDev(dev) {
    if (dev.sprintId === this.TaskId) {
      this.subtasks.push(dev);
    }
  }

  /**
   * return the list of devs
   */
  getDevs() {
    return this.subtasks;
  }
}
