import React from "react";
import { MdClose } from "react-icons/md";
import { updateDcoumentWithPath } from "../../../globals/services";
import styles from "../styles/editPopUp.module.css";

const ConfirmInterviewModal = ({
  onCloseConfirmInterviewModal,
  selectedResourceData,
  setShowConfirmInterviewModal,
  validateEmails,
}) => {
  const [selectedTimeIndex, setSelectedTimeIndex] = React.useState();
  const [mailIds, setMailIds] = React.useState("");
  const [invalidEmails, setInvalidEmails] = React.useState([]);
  const [error, setError] = React.useState("");

  const confirmInterview = async () => {
    if (selectedResourceData?.interview?.[selectedTimeIndex]) {
      setError("");
      const emailValidation = validateEmails([
        ...mailIds?.split(",").map((email) => email.trim()),
      ]);
      if (emailValidation.length == 0) {
        setInvalidEmails([]);
        setShowConfirmInterviewModal(false);
        await updateDcoumentWithPath(selectedResourceData.path, {
          interviewEmails: [
            ...new Set([
              ...(selectedResourceData?.interviewEmails ?? []),
              ...mailIds
                ?.split(",")
                ?.map((email) => email.trim())
                ?.filter((email) => email != ""),
            ]),
          ],
          interview: [selectedResourceData?.interview[selectedTimeIndex]],
          interviewConfirmed: true,
        });
        setSelectedTimeIndex([]);
        setMailIds("");
      } else {
        setInvalidEmails([...emailValidation]);
      }
    } else {
      setError("Please select a time slot to confirm!");
    }
  };

  return (
    <div className={styles.editPopup}>
      <div className={styles.editPopupContent}>
        <div className={styles.top}>
          <h4>Confirm interview</h4>
          <MdClose
            style={{ opacity: ".45", cursor: "pointer" }}
            onClick={() => {
              onCloseConfirmInterviewModal();
            }}
            size={20}
          />
        </div>
        <div className={styles.bottom} style={{ padding: "10px" }}>
          <p style={{ fontSize: "14px", fontWeight: "500" }}>
            Select a time slot you are available from the list of available
            times provided by the client. An interview call will be scheduled
            for that time and emailed to you, as well as any additional
            participants you wish to invite below. Times are in 24h format, in
            your local time zone.
          </p>
          {selectedResourceData?.interview.map((date, dateIndex) => {
            return (
              <div style={{ marginBottom: "5px" }}>
                <button
                  className={styles.darkBtn}
                  style={{
                    marginRight: "5px",
                    color: selectedTimeIndex == dateIndex ? "green" : "",
                  }}
                  onClick={() => {
                    setSelectedTimeIndex(dateIndex);
                  }}
                >
                  {date?.toDate()?.toString()}
                </button>
              </div>
            );
          })}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <input
              type="email"
              placeholder="email@website.com,email@gmail.com (input emails seperated by ',')"
              style={{ width: "90%", padding: "5px" }}
              value={mailIds}
              onChange={(event) => setMailIds(event.target.value)}
            />
            {invalidEmails?.length > 0 && (
              <p style={{ color: "red" }}>
                *Invalid emails found: {invalidEmails?.join(",")}
              </p>
            )}
            {error && <p style={{ color: "red" }}>*{error}</p>}
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ borderRadius: "5px" }}
              onClick={async () => {
                confirmInterview();
              }}
            >
              Confirm Interview
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmInterviewModal;
