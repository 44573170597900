export default class DevsForProject {
  constructor(id, devName, sprintId, dedication) {
    this.TaskId = id;
    this.TaskName = devName;
    this.StartDate = null;
    this.EndDate = null;
    this.sprintId = sprintId;
    this.dedication = (dedication * 100).toString() + "%";
  }
}
