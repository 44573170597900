import React from "react";

import ProgressBar from "react-customizable-progressbar";

import ContactTemplate from "./ContactTemplate";
/**
 * HeaderCert is the header of the subscribed vendor page
 * @param {*} props are coming from SubsPage.js
 */
const HeaderCert = (props) => {
  return (
    <div>
      <div className="resource-header cont">
        <div className="resource-header_right">
          <div className="header-intro">
            <h3>Your Certification Process</h3>
            <p className="lighter">
              Manage your quality, management and security certifications.
              Higher overall certification scores unlock access to clients and
              higher rates.
            </p>
          </div>
        </div>

        <div className="resource-header_left row">
          <div className="score">
            <ProgressBar
              radius={40}
              progress={props.scores.trustScore.toFixed(2)}
              strokeWidth={4}
              fillColor="#F7FAFC"
              strokeColor={props.trustColor}
              trackStrokeWidth={4}
              pointerRadius={8}
              pointerStrokeWidth={5}
              pointerStrokeColor={props.trustColor}
            >
              <div className="indicator">
                <h2>{props.scores.trustScore.toFixed(0)}</h2>
              </div>
            </ProgressBar>
            <p>Trust Score</p>
          </div>

          <div className="score">
            <ProgressBar
              radius={40}
              progress={props.scores.riskScore.toFixed(2)}
              strokeWidth={4}
              fillColor="#F7FAFC"
              strokeColor={props.riskColor}
              trackStrokeWidth={4}
              pointerRadius={8}
              pointerStrokeWidth={5}
              pointerStrokeColor={props.riskColor}
            >
              <div className="indicator">
                <h2>{props.scores.riskScore.toFixed(0)}</h2>
              </div>
            </ProgressBar>
            <p>Risk Score</p>
          </div>
        </div>
      </div>

      <div className="certification_header">
        <div className="stats">
          <div className="stat">
            <div className="stat_header">
              <div className="title">
                <h3>Contacts</h3>
                {/* <FontAwesomeIcon className="icon" icon={faCog} /> */}
              </div>
            </div>
            {props.contactErr !== "" ? (
              <div className="stat_content">
                <p style={{ color: "red", fontWeight: "500", opacity: '.65', fontStyle: 'italic', paddingTop: '10px'}}>
                  {props.contactErr}
                </p>
              </div>
            ) : (
              <div className="stat_content">
                <div className="stat_content_data row">
                  <ContactTemplate
                    title="Your Certification Point of Contact"
                    firstName={props.interCont.firstName}
                    lastName={props.interCont.lastName}
                    email={props.interCont.email}
                    jobTitle={props.interCont.jobTitle}
                    phoneNumber={props.interCont.phoneNumber}
                    className=" stat_content_rev left row"
                  />
                  <ContactTemplate
                    title="Your Spryte Certification Manager"
                    firstName={props.vendorCont.firstName}
                    lastName={props.vendorCont.lastName}
                    email={props.vendorCont.email}
                    jobTitle={props.vendorCont.jobTitle}
                    phoneNumber={props.vendorCont.phoneNumber}
                    className=" stat_content_rev right row"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="domain">
          <h3>Your Domains</h3>
          <p className="lighter">
            These are the domains of expertise your developers have. For each
            you can opt to fill in your certifications and security information.
            Adding this information gives you access to new clients and higher
            rates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderCert;
