import React from "react";

const AvailabilityIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.42521 0.953457V1.90691L1.78151 1.97753L0.137808 2.04839L0.0689039 11.0242L0 20H10H20L19.9311 11.0242L19.8622 2.04839L18.2185 1.97753L16.5748 1.90691V0.953457V0L15.4571 0.073233C14.3722 0.144088 14.337 0.172383 14.2578 1.03786L14.176 1.9295H10H5.82395L5.74216 1.03786C5.663 0.172383 5.62776 0.144088 4.54292 0.073233L3.42521 0V0.953457ZM2.37324 12.5103V17.8601H10H17.6268V12.5103V7.16044H10H2.37324V12.5103ZM11.2505 10.8998L9.48401 12.4967L8.47886 11.6093L7.4737 10.722L6.7084 11.4443L5.94283 12.1667L7.70882 13.7633L9.47481 15.3597L12.0353 13.045L14.5955 10.7301L13.8063 10.0163L13.017 9.30274L11.2505 10.8998Z"
        fill={fill}
      />
    </svg>
  );
};

export default AvailabilityIcon;
