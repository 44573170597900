import { HighlightOff, InsertDriveFile } from "@mui/icons-material";

const DocumentsViewer = (props) => {
  return (
    <div className="request docUpload">
      <div className="request_form column" id="data-request_form">
        <div className="docUpload_docs">
          {props.docs.map((doc, i) => {
            return (
              <div key={i} className="docUpload_docs-doc">
                <div className="docUpload_docs-doc__icons">
                  <InsertDriveFile className="docUpload_docs-doc__icons-file" />
                  <HighlightOff
                    onClick={() => props.delFile(i)}
                    className="docUpload_docs-doc__icons-del"
                  />
                </div>
                <p className="docUpload_docs-doc__name">
                  View{" "}
                  <a href={doc.docUrl} target="_blank" rel="noreferrer">
                    {doc.docName}
                  </a>
                </p>
              </div>
            );
          })}
        </div>
        <div className="request_form-action row">
          {props.dev.docs !== props.docs ? (
            <>
              {props.isSaving ? (
                <></>
              ) : (
                <button
                  className="spbtn spbtn-sub"
                  onClick={() => {
                    props.onSave();
                  }}
                >
                  Save
                </button>
              )}
            </>
          ) : (
            <></>
          )}
          <button
            className="spbtn spbtn-canc"
            onClick={() => {
              props.setIsViewDocs(false);
              props.setDeletedFiles([]);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default DocumentsViewer;
