import * as React from "react";
import { SampleBase } from "../sample-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import InputFiles from "./modalPages/InputFiles";
import ConfirmScrapedDevs from "./modalPages/ConfirmScrapedDevs";
// import { analytics } from "../Firebase/firebase";
import { CircularProgress } from "@mui/material";
/**
 * File Manager full functionalities sample
 */
export default class MultipleResumes extends SampleBase {
  constructor() {
    super(...arguments);
    this.state = {
      displayMessage: "Drag and drop files or choose files to import",
      hideDialog: false,
      filesList: [],
      resumeList: [],
      scrapedResumes: {},
      hasResumeBeenScraped: false,
      hasSkillsBeenConfirmed: false,
      resumeSkills: {},
      resumeText: "",
      currentIndex: 0,
      currentResume: null,
      isProcessing: false,
    };
    // List to hold all files that are being uploaded
    this.files = [];
    // Object to record skills to be added to the dev,
    // difference between the scraped skills from the resume and
    // this.skillsToBeDeleted
    this.devsToBeAdded = {};
    // Buttons for modal prior to selecting a resume to import and
    // clicking submit
    // https://ej2.syncfusion.com/react/documentation/dialog/getting-started/
    this.scrapeResumeButtons = [
      {
        buttonModel: {
          content: "Submit",
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: async () => {
          // If the user tries to click submit while the resumes are uploading
          // or when the submit shouldn't do anything, end the function
          if (this.state.isProcessing) {
            return false;
          }
          // Submit for page 1 of the modal
          if (
            !this.state.hasResumeBeenScraped &&
            !this.state.hasSkillsBeenConfirmed
          ) {
            this.resetInput();
            // Submit for page 2 of the modal
            // Buttons for after a resume has been scraped and correct skills
            // have been selected. Import the correct skills and close the modal.
          } else if (
            this.state.hasResumeBeenScraped &&
            !this.state.hasSkillsBeenConfirmed
          ) {
            this.setState({ isProcessing: true });
            // For each resume add the associated dev
            Object.values(this.state.devsToBeAdded).forEach(async (dev) => {
              // Initalize object to store corrected resume data for machine learning
              // purpose
              let devInfo = { ...dev.devInfo };

              this.props.firebase.updateDev(this.props.devId, devInfo);
              let correctedData = {
                categories: [],
                sectors: [],
                stacks: [],
              };

              // For each skill type, for each skill, add each skill unless the
              // skill has a key ignore with value true
              Object.entries(dev.skillsToBeAdded).forEach((entry) => {
                const [skillType, skills] = entry;
                Object.values(skills).forEach(async (skill) => {
                  const { ignore, experienceInYears, ...data } = skill;
                  if (!ignore) {
                    // adjust data to match db
                    if (experienceInYears) {
                      data.experience *= 12;
                    }
                    // Add the skill to the appropriate subcollection of the
                    // dev in the database or update it if the dev already had
                    // that skill
                    let repeatSkillId = this.checkForRepeatSkill(
                      skillType,
                      data
                    );
                    if (skillType === "stacks") {
                      if (repeatSkillId) {
                        await this.props.firebase.updateStack(
                          this.props.devId,
                          repeatSkillId,
                          data
                        );
                      } else {
                        await this.props.firebase.createStack(
                          this.props.devId,
                          data
                        );
                      }
                      // Add the data to correctedData for training data
                      correctedData.stacks.push(data.tech);
                    } else if (skillType === "sectors") {
                      if (repeatSkillId) {
                        await this.props.firebase.updatePrimaryDomain(
                          this.props.devId,
                          repeatSkillId,
                          data
                        );
                      } else {
                        await this.props.firebase.createPrimaryDomain(
                          this.props.devId,
                          data
                        );
                      }
                      // Add the data to correctedData for training data
                      correctedData.sectors.push(data.domain);
                    } else if (skillType === "categories") {
                      if (repeatSkillId) {
                        await this.props.firebase.updateProjExp(
                          this.props.devId,
                          repeatSkillId,
                          data
                        );
                      } else {
                        await this.props.firebase.createProjExp(
                          this.props.devId,
                          data
                        );
                      }
                      // Add the data to correctedData for training data
                      correctedData.categories.push(data.projType);
                    }
                  }
                });
              });
              // Once the dev and dev skills have been added, add the resume
              // to the machine learning training data
              // Note: skills is the key for the skills scraped by the API
              this.props.firebase.saveResumeData(
                dev.resume_text,
                correctedData,
                { ...dev.skills, ...dev.parsed_resume }
              );
            });
            this.resetInput();
          }
        },
      },
      {
        buttonModel: {
          content: "Cancel",
          cssClass: "e-flat",
        },
        click: () => {
          this.resetInput();
        },
      },
    ];

    // Reset the import resume to original state to start over
    this.resetInput = () => {
      document.getElementById("resume-input-S").value = "";
      this.setState({
        displayMessage: "Choose files to import",
        hideDialog: false,
        filesList: [],
        resumeList: [],
        scrapedResumes: {},
        hasResumeBeenScraped: false,
        hasSkillsBeenConfirmed: false,
        resumeSkills: {},
        resumeText: "",
        currentIndex: 0,
        currentResume: null,
      });
    };

    // Set state for dialog box for bulk upload to close
    this.dialogClose = () => {
      this.setState({ hideDialog: false });
    };
  }

  checkForRepeatSkill(skillType, skill) {
    if (skillType === "stacks") {
      for (let stack of this.props.stackExp) {
        if (stack.tech === skill.tech) {
          return stack.id;
        }
      }
    } else if (skillType === "sectors") {
      for (let domain of this.props.domainExp) {
        if (domain.domain === skill.domain) {
          return domain.id;
        }
      }
    } else if (skillType === "categories") {
      for (let proj of this.props.projExp) {
        if (proj.projType === skill.projType) {
          return proj.id;
        }
      }
    }
    return false;
  }

  // Create random string of length "length"
  randString(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  async scrapeResumes(resumeDict) {
    const configs = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resume_dict: resumeDict,
      }),
    };
    const resume_parserURL = await this.props.firebase.getResumeParserURL();
    try {
      const response = await fetch(resume_parserURL, configs);
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.log(error);
    }
  }

  handleErrorFile(file, ext) {
    // Check for errors in each file
    // If there are any problems with any of the files break
    if (file.size > 10000000) {
      this.setState({
        displayMessage: "File size too large, each file must be less than 10MB",
        isProcessing: false,
        filesList: [],
      });
      return true;
    }
    // Error handling for supported file types
    if (ext !== "pdf") {
      this.setState({
        displayMessage: "Please upload a PDF.",
        isProcessing: false,
        filesList: [],
      });
      return true;
    }
    // If it passes all tests return false
    return false;
  }

  // To be passed to the InputFiles component as a callback function to
  // change the state of filesList
  async handleFileInputChange(files) {
    this.setState({
      filesList: files,
      hideDialog: true,
      isProcessing: true,
      displayMessage:
        "Processing resumes, extracting information, Please wait...",
    });
    if (
      !this.state.hasResumeBeenScraped &&
      !this.state.hasSkillsBeenConfirmed
    ) {
      // If there are no files
      if (!files || files.length === 0) {
        this.setState({
          displayMessage: "Please choose a file to upload",
          isProcessing: false,
          filesList: [],
        });
        return false;
      }
      // Scrape each resume
      let resumeDict = {};
      let resumeData = {};

      for (const file of files) {
        // Regex to get the extension type of the file
        let re = /(?:\.([^.]+))?$/;
        const ext = re.exec(file.name)[1];
        if (this.handleErrorFile(file, ext)) {
          return;
        }
        // create a hash to save the resume under
        const hash = this.randString(10);
        // Create new file name {dev's Full Name} - {dev's document id} - resume
        let newFileName =
          this.props.dev?.resumeData?.resumeHash ?? `${hash}-resume.${ext}`;
        let storagePath = `vendors/${this.props.vendorId}/resume/${newFileName}`;
        const storage = this.props.firebase.getStorage();
        let response = await storage.ref(storagePath).put(file);
        // return a url to the resume
        let resumeLink = await response.ref.getDownloadURL();
        resumeDict[file.name] = resumeLink;
        resumeData[resumeLink] = {
          resumeHash: newFileName,
          resumeUrl: resumeLink,
        };
        //
      }

      const scrapedResumes = await this.scrapeResumes(resumeDict);

      // If an error occurs in the api and it returns null or an empty object
      if (!scrapedResumes || Object.keys(scrapedResumes).length === 0) {
        this.setState({
          displayMessage: "Unknown error has occurred",
          isProcessing: false,
          filesList: [],
        });
        return false;
      }
      // this.scrapedResumes is a copy of the original result of scraping
      // the resumes to be stored for machine learning training data
      this.scrapedResumes = { ...scrapedResumes };
      // Iterate through the scraped skills from the resume and set up the
      // skillsToBeIgnored and devData fields
      let resumeList = [];
      Object.keys(scrapedResumes).forEach((resume) => {
        resumeList.push(resume);
        // Each resume should have this format initially
        // resume-filename.pdf: {
        //   parsed_resume: fields { college_name, company_names, degree, designation, email, experience, mobile_number, name, no_of_pages, skills, total_experience }
        //   resume_text: text of the resume
        //   skills: fields {categories, sectors, stacks}
        //   url: url for the firestore where pdf is stored
        // }
        // Object to record skills to be added to the dev,
        // difference between the scraped skills from the resume and
        // this.skillsToBeDeleted
        resumeData[scrapedResumes[resume].url]["rawText"] =
          scrapedResumes[resume]["resume_text"];
        scrapedResumes[resume].devInfo = {
          email: scrapedResumes[resume]["parsed_resume"]["email"],
          resumeData: resumeData[scrapedResumes[resume].url],
        };
        scrapedResumes[resume].skillsToBeAdded = {
          stacks: {},
          sectors: {},
          categories: {},
        };
        Object.entries(scrapedResumes[resume].skills).forEach((entry) => {
          const [skillType, skills] = entry;
          Object.values(skills).forEach((skill) => {
            let data = {
              experience: 1,
              interest: "Interested",
              projects: 1,
              skill: 1,
              ignore: false,
              experienceInYears: true,
            };
            // Add the skill to the skillsToBeAdded depending on which
            // collection it needs to be added to
            if (skillType === "stacks") {
              data = {
                ...data,
                tech: skill.name,
                techRef: this.props.firebase.db.doc(skill.path),
              };
              scrapedResumes[resume].skillsToBeAdded.stacks[skill.name] = data;
            } else if (skillType === "sectors") {
              data = {
                ...data,
                domain: skill.name,
                domainRef: this.props.firebase.db.doc(skill.path),
              };
              scrapedResumes[resume].skillsToBeAdded.sectors[skill.name] = data;
            } else if (skillType === "categories") {
              data = {
                ...data,
                projType: skill.name,
                projectRef: this.props.firebase.db.doc(skill.path),
              };
              scrapedResumes[resume].skillsToBeAdded.categories[skill.name] =
                data;
            }
          });
        });
      });
      this.setState({
        devsToBeAdded: scrapedResumes,
        currentResume: resumeList[0],
        hasResumeBeenScraped: true,
        displayMessage: "",
        resumeList: resumeList,
        isProcessing: false,
      });

      // TODO: Track Import Resume Click
      // analytics.track("Resume Imported By Vendor", {
      //   numOfResumesAdded: Object.keys(scrapedResumes).length,
      // });
    }
  }

  // Callback function for incorrect and correct buttons to change the
  // ignore field of a skill to true or false
  confirmSkill(ignore, resumeName, skillType, skill) {
    let devsToBeAdded = { ...this.state.devsToBeAdded };
    devsToBeAdded[resumeName].skillsToBeAdded[skillType][skill].ignore = ignore;
    this.setState({ devsToBeAdded: devsToBeAdded });
  }
  // Callback function for editskill.js
  // Edit this.skillsToBeAdded so that the level, experience and number
  // of projects for that skill is correct
  editSkill(resumeName, skillType, skill, editedSkill) {
    let devsToBeAdded = { ...this.state.devsToBeAdded };
    devsToBeAdded[resumeName].skillsToBeAdded[skillType][skill] = editedSkill;
    this.setState({ devsToBeAdded: devsToBeAdded });
  }

  // Callback function for when the units for experience is changed
  setExperienceUnit(resumeName, skillType, skill, unit) {
    let devsToBeAdded = { ...this.state.devsToBeAdded };
    let editedSkill = {
      ...devsToBeAdded[resumeName].skillsToBeAdded[skillType][skill],
    };
    if (unit === "y") {
      editedSkill.experience = Math.floor(editedSkill.experience / 12);
      editedSkill.experienceInYears = true;
    } else if (unit === "m") {
      editedSkill.experience *= 12;
      editedSkill.experienceInYears = false;
    }
    devsToBeAdded[resumeName].skillsToBeAdded[skillType][skill] = editedSkill;
    this.setState({ devsToBeAdded: devsToBeAdded });
  }
  // OnCreate and onInput are used to automatically resize the textbox
  // https://ej2.syncfusion.com/react/documentation/textbox/multiline/
  onCreate() {
    this.textareaObj.addAttributes({ rows: 1 });
    this.textareaObj.respectiveElement.style.height = "auto";
    this.textareaObj.respectiveElement.style.height =
      this.textareaObj.respectiveElement.scrollHeight + "px";
  }

  onInput() {
    this.textareaObj.respectiveElement.style.height = "auto";
    this.textareaObj.respectiveElement.style.height =
      this.textareaObj.respectiveElement.scrollHeight + "px";
  }

  // Handle Click when user clicks on the import resume button
  handleClick() {
    this.setState({ hideDialog: true });
  }

  render() {
    this.confirmSkill = this.confirmSkill.bind(this);
    this.editSkill = this.editSkill.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.setExperienceUnit = this.setExperienceUnit.bind(this);
    // Conditional rendering for the modal dialog
    let currentPage;
    // If resume has yet to be scraped display the inputfile page
    if (!this.state.hasResumeBeenScraped) {
      currentPage = (
        <InputFiles
          handleFileInputChange={this.handleFileInputChange.bind(this)}
        />
      );
      // After scraping resumes, confirm that the name scraped from the
      // resume is correct, display ConfirmScrapedDevs
    } else if (this.state.hasResumeBeenScraped && !this.state.namesConfirmed) {
      currentPage = (
        <ConfirmScrapedDevs
          firebase={this.props.firebase}
          currentResume={this.state.currentResume}
          devsToBeAdded={this.state.devsToBeAdded}
          multiple={this.props.multiple}
          editSkill={this.editSkill}
          confirmSkill={this.confirmSkill}
          previousResume={this.previousResume}
          nextResume={this.nextResume}
          editDevInfo={this.editDevInfo}
          editedSkill={this.setExperienceUnit}
          setExperienceUnit={this.setExperienceUnit}
        />
      );
    }

    return (
      <div>
        {/* When the import resume button is clicked */}
        {/* If there is a file in state, open the dialog modal. If there is no file in state, activate the file upload */}
        <input
          type="file"
          id="resume-input-S"
          onChange={(e) => this.handleFileInputChange(e.target.files)}
          hidden="hidden"
          accept=".pdf"
        />
        <button
          className="e-control e-btn resume"
          id="resumebtn-input"
          style={{ background: "#1E96F5", color: "white", display: "none" }}
          onClick={
            this.state.hasResumeBeenScraped
              ? (this.handleClick = this.handleClick.bind(this))
              : () => {
                  document.getElementById("resume-input-S").click();
                }
          }
        >
          Import Resume
        </button>
        <DialogComponent
          className="resource_dialog"
          width="1000px"
          visible={this.state.hideDialog}
          close={this.dialogClose}
          showCloseIcon={true}
          buttons={this.scrapeResumeButtons}
          header="Import Resume (Please check all imported resumes)"
        >
          <div>
            {this.state.displayMessage}
            {this.state.isProcessing ? (
              <CircularProgress color="primary" size="small" />
            ) : (
              <>{currentPage}</>
            )}
          </div>
        </DialogComponent>
      </div>
    );
  }
}
