import styled from "styled-components";

export const SingleAssessBodyTab = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SingleAssessBodyImgWrapper = styled.div`
  display: flex;
  height: 315px;

  ._spVideoPlayer {
    padding-top: 0 !important;
    height: 100% !important;
    &-video {
      object-fit: cover;
      object-position: center;
    }
  }
  ._spAssBody--singleTab-imgWrapper--noVideo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-weight: 600;
    color: #6b6b6b;
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #dfdfdf;
    h3 {
      font-size: 54px !important;
    }
  }
`;

export const SingleAssessBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1.2rem;
  min-width: 500px;
  background-color: white;
  font-size: 14px;

  ._spAssBody--singleTab-content--difficulty {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    & > div {
      background-color: #f5f5f5;
      padding: 5px 10px;
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
    }
  }

  ._spAssBody--singleTab-content--question,
  ._spAssBody--singleTab-content--answer {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    white-space: pre-wrap;
    & > h5 {
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    & > p {
      font-size: 14px;
      color: #6b6b6b;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%; /* Takes the full width of the parent */
  /* Adjust padding-top for your desired aspect ratio; 56.25% is for 16:9 */
  padding-top: 56.25%;
  overflow: hidden;
  background-color: black;
  // max-height: 170px;

  & > video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
`;
