import React from "react";
import { withFirebase } from "./context";
import TagManager from "react-gtm-module";

// Saves the user session. Allows context for the react app to know if
// user is logged in or not
export const AuthUserContext = React.createContext(null);

const tagManagerArgs = {
  gtmId: "GTM-5PM6NVJ",
};

const withAuthUser = (Component) => {
  class WithAuth extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        vendorRef: null,
        vendor: null,
        domainExperiences: null,
        domainExperiencesObject: null,
        accountFound: false,
        userPartnerAccountDoc: null,
        isExisting: false,
        isSuperPartner: false,
        userId: "",
        isVendorUpdated: false,
        userRef: null,
        devRef: null,
        user: null,
        userAccounts: [],
        isDevSelfReg: false,
      };
    }

    updateVendor = () => {
      this.setState({
        isVendorUpdated: true,
      });
    };
    isSuperPartner = async () => {
      const userRole = await this.props.firebase.getPartnerRole();
      if (userRole === "SUPER-ADMIN" || userRole === "PARTNER-ADMIN") {
        return true;
      }
      return false;
    };

    componentDidMount() {
      TagManager.initialize(tagManagerArgs);
      this.authListener = this.props.firebase.auth.onAuthStateChanged(
        async (authUser) => {
          this.setState({
            isSuperPartner: false,
          });
          if (authUser) {
            this.setState({ userId: authUser.uid });
            const userRef = this.props.firebase.db
              .collection("users")
              .doc(authUser.uid);

            const _userDoc = userRef.get();
            const _accountData = userRef
              .collection("accounts")
              .doc("spryte-partner")
              .get();

            const _userAccounts = userRef.collection("accounts").get();
            let _vendor = this.props.firebase.getCurrentVendorDoc();
            const _isvendor = this.props.firebase.isAccountLinked();
            const _isExisting = this.props.firebase.isVendorExist();

            const [
              userDoc,
              accountData,
              userAccounts,
              vendor,
              isvendor,
              isExisting,
            ] = await Promise.all([
              _userDoc,
              _accountData,
              _userAccounts,
              _vendor,
              _isvendor,
              _isExisting,
            ]);
            const accountsList = userAccounts.docs.map((doc) => {
              return { ...doc.data(), ref: doc.ref, id: doc.id };
            });
            let userData = { ...userDoc.data() };
            userData["ref"] = userDoc.ref;

            let devRef = accountData.data()?.devRef;
            const vendorPublic = await this.props.firebase.getVendorPublicData(
              vendor?.id
            );

            if (vendorPublic) vendor["public"] = vendorPublic;
            const isSuper = await this.isSuperPartner();

            let accountFound = vendor?.thirdPartyTrust?.accountFound;
            this.setState({
              authUser: authUser,
              user: userData,
              vendorRef: isvendor,
              vendor: vendor,
              accountFound: accountFound,
              userPartnerAccountDoc: accountData.data(),
              isExisting: isExisting,
              userRef: userRef,
              isSuperPartner: isSuper,
              devRef: devRef,
              userAccounts: accountsList,
            });

            // console.log("auth user", authUser);
          } else {
            this.setState({
              user: null,
              authUser: null,
              vendorRef: null,
              vendor: null,
              userPartnerAccountDoc: null,
              userId: "",
              userRef: null,
              devRef: null,
            });
          }
        }
      );
    }

    // create function to set state of  key value pairs
    onUpddatState = (key, value) => {
      this.setState({
        [key]: value,
      });
    };

    componentWillUnmount() {
      this.authListener();
    }

    componentDidUpdate(prevState) {
      if (prevState.isVendorUpdated !== this.state.isVendorUpdated) {
        if (this.state.isVendorUpdated) {
          const getVendor = async () => {
            let vendor = await this.props.firebase.getCurrentVendorDoc();
            this.setState({
              vendor: vendor,
              isVendorUpdated: false,
            });
          };
          getVendor();
        }
      }
    }

    render() {
      return (
        <AuthUserContext.Provider
          value={{
            userId: this.state.userId,
            auth: this.state.authUser,
            userRef: this.state.userRef,
            vendorRef: this.state.vendorRef,
            vendor: this.state.vendor,
            accountFound: this.state.accountFound,
            userPartnerAccountDoc: this.state.userPartnerAccountDoc,
            isExisting: this.state.isExisting,
            isSuperPartner: this.state.isSuperPartner,
            isVendorUpdated: this.updateVendor,
            devRef: this.state.devRef,
            user: this.state.user,
            onUpddatState: this.onUpddatState,
            isDevSelfReg: this.state.isDevSelfReg,
          }}
        >
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuth);
};

export default withAuthUser;
