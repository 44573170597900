import React from "react";

const statusTemplate = (props) => {
  const renderStatus = () => {
    if (props.status === "Select All") {
      return <span>Select All</span>;
    }
    if (props.status === "Active") {
      return (
        <div className="statustemp e-activecolor">
          <span className="statustxt e-activecolor">Active</span>
        </div>
      );
    }
    if (props.status === "Inactive") {
      return (
        <div className="statustemp e-inactivecolor">
          <span className="statustxt e-inactivecolor">Inactive</span>
        </div>
      );
    }
  };

  return (
    <div id="status" className="statustemp">
      <span className="statustxt">{renderStatus()}</span>
    </div>
  );
};

export default statusTemplate;
