import { Button, InputBase, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { spryteCloudUrl } from "../../../globals/services";

const RequirementLocalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

const LocationButton = styled(Button)`
  background-color: ${({ isSelected }) =>
    isSelected ? "#4361E9 " : "#FFFFFF"} !important;
  color: ${({ isSelected }) => (isSelected ? "#FFFFFF" : "#4361E9")} !important;
`;

const RequirementLocalLocation = ({
  onSelection,
  localAddress,
  setLocalAddress,
  buttonColor = "#FFFFFF",
  placeHolder = "147 Avenue, NYC",
  fetchedLocationsMap,
  setFetchedLocationsMap,
}) => {
  /**
   * Triggers when the vendor click on the map button to map a given location
   */
  const onMap = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      address: localAddress?.toLowerCase(),
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const sendPotentialLocations = spryteCloudUrl("sendPotentialLocations");

    fetch(sendPotentialLocations, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        const fetchedLocation = JSON.parse(result);
        setFetchedLocationsMap(fetchedLocation?.[0]?.locationData ?? {});
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <RequirementLocalWrapper className="_sp-customReq-newRequirementForm--popUpBox_teamLocWrapper--local">
      <div
        className="sp_row _sp-customReq-newRequirementForm--popUpBox_teamLocWrapper--local_input"
        style={{
          gap: "1rem",
        }}
      >
        <TextField
          value={localAddress.toLowerCase()}
          onChange={(event) =>
            setLocalAddress(event.target.value?.toLowerCase())
          }
          placeholder={placeHolder}
          sx={{ flex: 1 }}
        />
        <Button
          variant="contained"
          onClick={() => onMap()}
          sx={{ width: "fit-content" }}
          disabled={localAddress?.length == 0}
        >
          Map Location
        </Button>
      </div>

      <div className="_sp-customReq-newRequirementForm--popUpBox_teamLocWrapper--local_locations sp_row">
        {Object.keys(fetchedLocationsMap)?.length > 0 &&
          Object.keys(fetchedLocationsMap).map((key) => (
            <LocationButton
              variant="contained"
              color="grey"
              isSelected={
                fetchedLocationsMap[key].toLowerCase() ==
                localAddress.toLowerCase()
              }
              onClick={() =>
                onSelection(
                  fetchedLocationsMap?.[key]?.toLowerCase() ==
                    localAddress.toLowerCase()
                    ? ""
                    : fetchedLocationsMap?.[key]?.toLowerCase()
                )
              }
            >
              {fetchedLocationsMap[key].toLowerCase()}
            </LocationButton>
          ))}
      </div>
    </RequirementLocalWrapper>
  );
};

export default RequirementLocalLocation;
