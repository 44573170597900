import React, { useMemo } from "react";
import {
  DevProfile,
  renderDevRates,
} from "../../../globals/SpDevCard/devProfile/DevProfile";
import { DevsClients } from "../../../globals/SpDevCard/";
import { DevsPartner } from "../../../globals/SpDevCard/devPartner/DevPartner";
// import SpResponsiveImg from "../SpResponsiveImg";
// import devEmptyProfile from "../../public/devEmptyProfile.png";
import {
  DevImageStatus,
  DevStatus,
} from "../../../globals/SpDevCard/devProfile/style";
import { DevDetails, DevPricing } from "../reqTeam/listDev/style";
import LocIcon from "../../../svgs/location/LocIcon";
import ResumeViewIcon from "../../../svgs/ResumeViewIcon";
import ShareLinkIcon from "../../../svgs/ShareLinkIcon";
import SpTitles from "../../../globals/SpTitles/SpTitles";
import SpStepper from "../../../globals/SpStepper/StepperWrapper/StepperWrapper";
import SpStepperElement from "../../../globals/SpStepper/StepperElement/StepperElement";
import { motion } from "framer-motion";

import TechTableData from "./TechTableBody";
import { useSpTimeout } from "../../../globals/hooks/SpHooks";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";

const DevProfileView = ({ devInfo, selectedUserRole }) => {
  const [isColapsed, setIsCollapsed] = React.useState({
    educ: false,
    exp: false,
    skills: false,
    proj: false,
    industry: false,
  });
  const [isCopied, setIsCopied] = React.useState(false);
  const [isViewAll, setIsViewAll] = React.useState(false);

  const devData = devInfo?.devData;
  const partnerData = devInfo?.partnerData;

  // set iscopied to false 1s after iscopied is true to reset the copy button
  useSpTimeout(
    () => {
      setIsCopied(false);
    },
    isCopied ? 1000 : null
  );

  const skillList = useMemo(() => {
    const skills = Object.values(devInfo?.skillsObject ?? {}).map((skill) => {
      return skill?.name;
    });
    // order skills by skill.stats.total.score asc
    skills.sort((a, b) => {
      return b?.stats?.total?.score - a?.stats?.total?.score;
    });
    // take the first 5 skills
    if (!isViewAll) return [skills.slice(0, 5), skills?.length - 5];
    return [skills, skills?.length - 5];

    // return
  }, [devInfo?.skillsObject, isViewAll]);

  const onCopytoClipboard = async () => {
    const el = `https://www.sprytelabs.com/resource/${devData?.devDocID}`;
    await navigator.clipboard.writeText(el);
    setIsCopied(true);
  };

  // take a timestamp and return the date in yyyy
  const getYear = (timestamp) => {
    const date = timestamp.toDate();
    return date.getFullYear();
  };

  const renderBullets = (list) => {
    return list?.map((item, index) => {
      return (
        <span style={{ display: "block" }} key={index}>
          {item}
        </span>
      );
    });
  };

  return (
    <div className=" _spReqTeam--OptionThird__details--right_devProfile">
      <div className="_sidePanel--devProfile ">
        {/* <div> */}
        <DevImageStatus className="_sidePanel--devProfile--img">
          <DevStatus
            statuscolor={devData?.status && "#13D00F"}
            statusright={"5px"}
            statustop={"0px"}
          />
          {/* {devData?.logoStorage ? (
            <SpResponsiveImg
              src={devData?.logoStorage}
              alt={devData?.first}
              width={"75px"}
              height={"75px"}
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
              }}
            />
          ) : (
            <SpResponsiveImg
              src={devEmptyProfile}
              alt={"empty profile"}
              width={"75px"}
              height={"75px"}
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
              }}
            />
          )} */}
          <SpResponsiveImg
            src={devData?.logoStorage}
            alt={devData?.first}
            borderRadius={"50%"}
          />
        </DevImageStatus>
        <DevDetails className="_sidePanel--devProfile--devDetails">
          <h4>{devData?.first + " " + devData?.last}</h4>
          <p>{devData?.position}</p>
          <div className="listDevLoc">
            <LocIcon />
            <span>{devData?.locTag}</span>
          </div>
          {renderDevRates("20px", "20px", devData?.rating ?? 0, "#3A0C9E")}
        </DevDetails>
        {/* {devInfo?.rates?.length > 0 && ( */}
        <DevPricing className="_sidePanel--devProfile--devPrice">
          <span className="amountText">
            {/* ${Math.min.apply(Math, devData?.rates).toFixed(2) ?? "--"} */}$
            {devData?.hourlyRate?.toFixed(2) ?? "0.00"}
          </span>

          <span>Per Hr</span>
        </DevPricing>
        {/* )} */}

        {/* </div> */}
      </div>
      {devData?.aboutMe && (
        <div className="_sidePanel--devAbout ">
          <p>{devData?.aboutMe}</p>
        </div>
      )}
      <div className="_sidePanel--devLinks">
        {devData?.resumeData?.resumeUrl && (
          <div className="sp_hover">
            <ResumeViewIcon />
            <a href={devData?.resumeData?.resumeUrl}>View Resume</a>
          </div>
        )}
        <div
          className="sp_hover _spDevLinkCopy"
          style={{
            position: "relative",
          }}
          onClick={onCopytoClipboard}
        >
          <ShareLinkIcon />
          <p>Share Link</p>

          <motion.span
            style={{
              position: "absolute",
              right: "0",
              backgroundColor: "#699BF7",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
            initial={{ opacity: 0, y: 40, x: "50vw" }}
            animate={
              isCopied
                ? { opacity: 1, y: 40, x: 0 }
                : { opacity: 0, y: 40, x: "50vw" }
            }
            exit={{ opacity: 0 }}
          >
            Copied
          </motion.span>
        </div>
      </div>
      {skillList?.[[0]]?.length > 0 && (
        <div className="_sidePanel--devSkills">
          <h3>Core skills</h3>
          <div className="_sidePanel--devSkills--list">
            {skillList[0]?.map((skill, index) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key={index}
              >
                {skill}
              </motion.span>
            ))}
            {skillList[1] > 0 && !isViewAll && (
              <span className="sp_hover" onClick={() => setIsViewAll(true)}>
                +{skillList[1]} More
              </span>
            )}
            {isViewAll && skillList[1] > 0 && (
              <span className="sp_hover" onClick={() => setIsViewAll(false)}>
                View Less
              </span>
            )}
          </div>
        </div>
      )}
      {/* Clients section */}
      {devInfo?.clients?.length > 0 && (
        <DevsClients
          clients={devInfo?.clients}
          showNumber={5}
          cls="_sidePanel--devClients"
          imgHeight="50px"
        />
      )}
      {selectedUserRole === "admin" && devData?.partnerData?.companyName && (
        <DevsPartner
          partnerData={partnerData}
          imgHeight="25px"
          imgBorderRadius="50%"
        />
      )}
      {/* Eduction section */}
      {devData?.education?.length > 0 && (
        <div>
          <SpTitles
            title={"Education"}
            isColapsable
            onCollapse={(isCol) =>
              setIsCollapsed((prev) => ({ ...prev, educ: isCol }))
            }
            iconSize={"30"}
          />
          {isColapsed?.educ && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
            >
              <SpStepper>
                {devData?.education?.map((edu, index) => (
                  <SpStepperElement
                    title={edu?.school}
                    info={edu?.degLevel}
                    desc={edu?.description}
                    left={getYear(edu?.gradDate)}
                  />
                ))}
              </SpStepper>
            </motion.div>
          )}
        </div>
      )}
      {/* Experience section */}

      {devData?.workExperience?.length > 0 && (
        <div>
          <SpTitles
            title={"Work Experience"}
            isColapsable
            onCollapse={(isCol) =>
              setIsCollapsed((prev) => ({ ...prev, exp: isCol }))
            }
            iconSize={"30"}
          />
          {isColapsed?.exp && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
            >
              <SpStepper>
                {devData?.workExperience?.map((exp, index) => (
                  <SpStepperElement
                    title={exp?.title}
                    info={exp?.company}
                    desc={renderBullets(exp?.bullets ?? [])}
                    left={getYear(exp?.start)}
                  />
                ))}
              </SpStepper>
            </motion.div>
          )}
        </div>
      )}

      {/* Stacks section */}
      {devData?.stats?.stacks?.data?.length > 0 && (
        <TechTableData
          title={"Tech Stacks"}
          setIsCollapsed={setIsCollapsed}
          keyName="stacks"
          iscolapsed={isColapsed?.stacks}
          techsList={devData?.stats?.stacks?.data}
          devInfo={devInfo}
        />
      )}

      {devData?.stats?.categories?.data?.length > 0 && (
        <TechTableData
          title={"Projects"}
          setIsCollapsed={setIsCollapsed}
          keyName="proj"
          iscolapsed={isColapsed?.proj}
          techsList={devData?.stats?.categories?.data}
          devInfo={devInfo}
        />
      )}

      {devData?.stats?.sectors?.data?.length > 0 && (
        <TechTableData
          title={"Industry Domains"}
          setIsCollapsed={setIsCollapsed}
          keyName="industry"
          iscolapsed={isColapsed?.industry}
          techsList={devData?.stats?.sectors?.data}
          devInfo={devInfo}
        />
      )}
    </div>
  );
};

export default DevProfileView;
