import React, { useState, useContext } from "react";
import { FirebaseContext } from "./Firebase";
import { Link } from "react-router-dom";
// import '../css/Login.css';

export default function Register(props) {
  const firebase = useContext(FirebaseContext);
  const [emptyAlert, setEmptyAlert] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogBack, setIsLogBack] = useState(false);
  const [newVendor, setNewVendor] = useState({
    website: "",
    companyName: "",
    address: "",
    techFocus: "",
    totalDevs: 0.0,
    timeToStart: 0.0,
    password: "",
    confirmPassword: "",
  });

  // add user to firebase database
  const addVendorAsync = async () => {
    // add vendor to vendors collection and reference associated users
    // from users collection in contactRef
    setIsLoading(true);
    setEmptyAlert("");
    const user = props.user;
    const financial = {
      avgYearlyIncome: 0,
      avgRateSprint: 0,
      avgRateHr: 0,
      netMarginP: 0,
      grossMarginP: 0,
      publicCompany: false,
    };
    const verification = {
      businessEntityName: "Unknown",
      source: "None",
      status: true,
      jurisdiction: "Unknown",
      companyId: "Unknown",
      dateOfFormation: new Date(),
      lastUpdated: new Date(),
    };
    const security = {
      L0: {
        approved: true,
        approvedBy: "System Registration",
        approvedOn: new Date(),
        level: 0,
        recertBy: null,
        submitted: true,
      },
      L1: {
        approved: false,
        approvedBy: null,
        approvedOn: null,
        level: 1,
        recertBy: null,
        submitted: false,
      },
      L2: {
        approved: false,
        approvedBy: null,
        approvedOn: null,
        level: 2,
        recertBy: null,
        submitted: false,
      },
      L3: {
        approved: false,
        approvedBy: null,
        approvedOn: null,
        level: 3,
        recertBy: null,
        submitted: false,
      },
      L4: {
        approved: false,
        approvedBy: null,
        approvedOn: null,
        level: 4,
        recertBy: null,
        submitted: false,
      },
      L5: {
        approved: false,
        approvedBy: null,
        approvedOn: null,
        level: 5,
        recertBy: null,
        submitted: false,
      },
    };
    const legal = {
      bankruptcy: false,
      taxLienFilings: 0,
      judgementFilings: 0,
      collectionsCount: 0,
      lastUpdated: new Date(),
    };
    const reviews = {
      verifiedClientReviews: 0,
      overallReviewRating: 0,
      source: "Unknown",
      lastUpdated: new Date(),
    };
    const logo = {
      url: "",
      name: "",
    };

    const vendorData = {
      companyName: newVendor.companyName.toString(),
      address: newVendor.address.toString(),
      techFocus: newVendor.techFocus.toString(),
      totalDevs: parseFloat(newVendor.totalDevs),
      website: newVendor.website.toString(),
      timeToStart: parseFloat(newVendor.timeToStart),
      contactRef: firebase.db.doc(`users/${user.uid}`),
      financial: financial,
      verification: verification,
      lastLogin: new Date(),
      legal: legal,
      reviews: reviews,
      security: security,
      logo: logo,
      profile: { bio: "Add your company bio!" },
      vendorProfileProgress: {
        financialProgress: 0,
        devsProgress: 0,
      },
    };

    const responseVendor = await firebase.createVendor(vendorData);
    const vendorRef = {
      vendorRef: firebase.db.doc(`vendors/${responseVendor.id}`),
      status: true,
      permissions: [],
    };
    await firebase.linkAccount(user.uid, vendorRef);
    setIsLoading(false);

    // await firebase.doSignOut();
    // Once vendor is created, link the created user to the created vendor
    // the user who creates the vendor is defaulted to be the point of
    // contact between Spryte and the vendor
    // firebase.linkAccount(auth.user.uid, {
    //   vendorRef: firebase.db.doc(`vendors/${responseVendor.id}`),
    // });
  };

  // setNewVendor field so that a new vendor can be created
  const handleChange = (prop) => (event) => {
    setNewVendor({ ...newVendor, [prop]: event.target.value });
  };

  //==================================== Added this alerte section to check and display the alert from the database=================================

  // setNewVendor field so that a new vendor can be created
  // const handleChange = (prop) => (event) => {
  //   setNewVendor({ ...newVendor, [prop]: event.target.value });
  //   if (prop === 'contactEmail') {
  //     setRegisterEmail(event.target.value);
  //   }
  // };

  const CheckAndUpdatePassword = (step) => {
    setEmptyAlert("");
    if (
      newVendor.password !== "" &&
      newVendor.confirmPassword !== "" &&
      newVendor.confirmPassword === newVendor.password
    ) {
      firebase
        .doPasswordUpdate(newVendor.password)
        .then(async () => {
          setEmptyAlert("");
          const userId = await firebase.auth.currentUser.uid;
          let userRef = await firebase.db.collection("users").doc(userId);
          let accountRef = await userRef
            .collection("accounts")
            .doc("spryte-partner");
          let updates = {
            passwordUpdated: true,
          };

          await accountRef.set(updates, { merge: true });
          setIsLoading(false);
          setStep(step + 1);
        })
        .catch((e) => {
          setEmptyAlert(e.message);
          if (e.code === "auth/requires-recent-login") setIsLogBack(true);
          setIsLoading(false);
        });
    } else {
      setEmptyAlert("Password should be the same and non empty value!");
      return;
    }
  };

  const onLoggin = async () => {
    setIsLoading(true);
    await firebase.doSignOut();
    setIsLoading(false);
  };

  const checkEmployeeInfo = (step) => {
    if (isNaN(newVendor.totalDevs) || isNaN(newVendor.timeToStart)) {
      setEmptyAlert(
        "Please check Employed Developer or Project Lead Time Field(should be numbers)"
      );
      return false;
    } else if (newVendor.totalDevs <= 0 || newVendor.timeToStart <= 0) {
      setEmptyAlert(
        "Please check Employed Developer or Project Lead Time Field(must be greater than 0)"
      );
      return false;
    } else {
      setIsLoading(true);
      setTimeout(async () => {
        await addVendorAsync();
        setIsLoading(false);
        setStep(step + 1);
      }, 1100);
    }
  };

  const checkVendorInfo = (step) => {
    if (
      newVendor.companyName === "" ||
      newVendor.website === "" ||
      newVendor.techFocus === "" ||
      newVendor.address === ""
    ) {
      setEmptyAlert("Please Fill All Fields");
      return false;
    } else if (
      !isNaN(newVendor.companyName) ||
      !isNaN(newVendor.website) ||
      !isNaN(newVendor.techFocus) ||
      !isNaN(newVendor.address)
    ) {
      setEmptyAlert("Please All value should not be a number");
      return false;
    }
    setEmptyAlert("");
    setStep(step + 1);
  };

  // render this function when the vendor
  // dont't have an existing account in the spryte dev platform
  const renderSteps = () => {
    switch (step) {
      case !props.isExisting ? 1 : -1:
        return (
          <div
            className="login-div"
            style={{ paddingBottom: emptyAlert !== "" ? "75px" : "25px" }}
          >
            <h1>
              Welcome!<br></br> First let's Create Your Password.
            </h1>
            <div className="input-div">
              <p>Password</p>
              <input
                placeholder="******"
                type="password"
                value={newVendor.password || ""}
                onChange={handleChange("password")}
              ></input>
              <p>Confirm Password</p>
              <input
                placeholder="******"
                type="password"
                value={newVendor.confirmPassword || ""}
                onChange={handleChange("confirmPassword")}
              ></input>
              <div className="button-div">
                <Link
                  to="/createVendor"
                  onClick={(e) => CheckAndUpdatePassword(step)}
                >
                  NEXT
                </Link>
                {isLogBack ? (
                  <Link to="/createVendor" onClick={(e) => onLoggin()}>
                    LOG BACK IN
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <p style={{ bottom: "6.5%" }} className="error">
              {emptyAlert}
            </p>
          </div>
        );
      case !props.isExisting ? 2 : 1:
        return (
          <div
            className="login-div"
            style={{ paddingBottom: emptyAlert !== "" ? "75px" : "25px" }}
          >
            <div className="input-div">
              <h1>Please verify your account details</h1>
              <p>Company Name</p>
              <input
                value={newVendor.companyName || ""}
                onChange={handleChange("companyName")}
              ></input>
              <p>Website</p>
              <input
                value={newVendor.website || ""}
                onChange={handleChange("website")}
              ></input>
              <p>Tech Focus</p>
              <input
                value={newVendor.techFocus || ""}
                onChange={handleChange("techFocus")}
              ></input>
              <p>Address</p>
              <input
                value={newVendor.address || ""}
                onChange={handleChange("address")}
              ></input>
              <div className="button-div">
                {props.isExisting ? (
                  <></>
                ) : (
                  <Link to="/createVendor" onClick={() => setStep(step - 1)}>
                    BACK
                  </Link>
                )}
                <Link to="/createVendor" onClick={() => checkVendorInfo(step)}>
                  NEXT
                </Link>
              </div>
            </div>
            <p className="error" style={{ bottom: "6.5%" }}>
              {emptyAlert}
            </p>
          </div>
        );
      case !props.isExisting ? 3 : 2:
        return (
          <div
            className="login-div"
            style={{ paddingBottom: emptyAlert !== "" ? "75px" : "25px" }}
          >
            <h1>Please specify:</h1>
            <div className="input-div">
              <p># of Employed Developers</p>
              <input
                value={newVendor.totalDevs || ""}
                onChange={handleChange("totalDevs")}
              ></input>
              <p>Project Lead Time</p>
              <input
                value={newVendor.timeToStart || ""}
                onChange={handleChange("timeToStart")}
              ></input>
              <div className="button-div">
                <Link to="/createVendor" onClick={() => setStep(step - 1)}>
                  BACK
                </Link>
                {isLoading ? (
                  <Link to="/createVendor">PLEASE WAIT</Link>
                ) : (
                  <Link
                    to="/createVendor"
                    onClick={(e) => checkEmployeeInfo(step)}
                  >
                    SUBMIT
                  </Link>
                )}
              </div>
            </div>
            <p className="error" style={{ bottom: "6.5%" }}>
              {emptyAlert}
            </p>
          </div>
        );
      case !props.isExisting ? 4 : 3:
        return (
          <div
            className="login-div"
            style={{
              textAlign: "center",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                marginLeft: 0,
                alignSelf: "center",
                color: "#51bc5d",
              }}
            >
              {emptyAlert
                ? "Account Not Created"
                : "Account Created Successfully"}
            </h2>
            <div className="input-div">
              <div className="button-div">
                <Link to="/createVendor" onClick={(e) => onLoggin()}>
                  {emptyAlert ? "Please reset Password again" : "Please Login"}
                </Link>
              </div>
            </div>
            <p className="error">{emptyAlert}</p>
          </div>
        );
      default:
    }
  };

  return (
    <div className="login-div">
      <div className="login">
        <div className="login-left">
          <div className="circle">
            <img
              src="https://shellsoftware.net/wp-content/uploads/2020/06/shell_hwid_spoofer_pro.png"
              alt="img"
            />
          </div>
          {renderSteps()}
        </div>
      </div>
    </div>
  );
}
