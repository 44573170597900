import styled from "styled-components";
import { RspTab } from "../stylesConstant";

export const HeadTabWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;
export const TabList = styled(RspTab)`
  display: flex;
  border: 1px solid #dfdfdf;
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // padding-right: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ArrowBox = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 0;
  &:first-child {
    left: 0px;
  }
  &:last-child {
    right: 0px;
  }
`;
