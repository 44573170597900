import React, { useEffect, useState } from "react";

import TechExperienceGrid from "../../../TechExperienceGrid/TechExperienceGrid";

import Requests from "./requests/Requests";
import NoStackIcon from "../../../svgs/NoStackIcon";
import TechSearch from "./TechSearch/TechSearch";
import {
  getDocs,
  getDocumentsByArrayMembershipInCollection,
} from "../../../globals/services";

const TechStacks = (props) => {
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (value) => {
    // setSearchValue(value);
    // setSearchValue(value);
    let dataDocs;
    let stacksCatsResult;
    if (props?.type === "stacks" || !props?.type) {
      dataDocs = await getDocumentsByArrayMembershipInCollection(
        "technologies",
        value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "rank",
        true,
        true,
        false,
        100
      );

      stacksCatsResult = await getDocs("techStacks150r");
    }
    if (props?.type === "domains") {
      const [sectDocs, subSecDocs] = await Promise.all([
        getDocumentsByArrayMembershipInCollection(
          "industry",
          value.toLowerCase(),
          ["nameIndex", "catIndex"],
          "name",
          true,
          true,
          false,
          50
        ),
        getDocumentsByArrayMembershipInCollection(
          "subSectors",
          value.toLowerCase(),
          ["nameIndex", "catIndex"],
          "name",
          true,
          true,
          false,
          50
        ),
      ]);
      dataDocs = [...sectDocs, ...subSecDocs];

      stacksCatsResult = await getDocs("sectors");
    }
    if (props?.type === "categories") {
      dataDocs = await getDocumentsByArrayMembershipInCollection(
        "subcollections",
        value.toLowerCase(),
        ["nameIndex", "catIndex"],
        "name",
        true,
        true,
        false,
        20
      );
      stacksCatsResult = await getDocs("categories");
    }

    const newStacksCategories = stacksCatsResult?.docs?.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
    }));
    const data = dataDocs?.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
      catId: doc?.ref?.parent?.parent?.id,
      catData: newStacksCategories.find(
        (cat) => cat?.id === doc?.ref?.parent?.parent?.id
      ),
    }));

    setSearchResults(data);
  };

  useEffect(() => {
    setSearchResults([]);
  }, [props?.type]);

  const onAdd = async (tech) => {
    let data = {
      experience: 1,
      interest: "Interested",
      projects: 1,
      skill: 1,
      addedOn: new Date(),
    };
    if (props?.type === "stacks" || !props?.type) {
      data.techRef = tech.ref;
      data.tech = tech.title;
      await props.firebase.createStack(props.devId, data);
    }

    if (props?.type === "domains") {
      data.domainRef = tech.ref;
      data.domain = tech.name;
      await props.firebase.createPrimaryDomain(props.devId, data);
    }
    if (props?.type === "categories") {
      data.projectRef = tech.ref;
      data.projType = tech.name;
      await props.firebase.createProjExp(props.devId, data);
    }
    // Updating lastUpdated for the dev
    await props.firebase.updateDev(props.devId, {
      lastUpdated: new Date(),
    });
  };
  return (
    <div
      className={
        props.devPage === props?.visibleCls ? "control-section" : "hide_page"
      }
    >
      {props.isRequest ? (
        <Requests
          isRequest={props.isRequest}
          formId="7ff5dc9e-f683-4b31-a621-ad39f0292538"
          setIsRequest={props.setIsRequest}
        />
      ) : (
        <></>
      )}
      <div className="resources_header">
        <div className="resources_header_left">
          <div className="title">
            <h3>{props?.title}</h3>
            {/* <FontAwesomeIcon icon={faCubes} className="icon" /> */}
          </div>
          <p>{props?.subTitle}</p>
        </div>
        <div className="resources_header_right">
          <TechSearch
            onSearch={handleSearch}
            searchResults={searchResults}
            onItemSelect={onAdd}
            data={props.stackExp}
            placeholder={props?.searchPlaceholder}
          />

          {/* <button onClick={(e) => props.clickedRequest()}>
            Request New Stack
          </button> */}
        </div>
      </div>
      {/* -----------End Stack Header -------------------------------------- */}

      {/* -----------Stacks Data start------------------------------------------------------------------------ */}

      {/* -----------Stacks Data End------------------------------------------------------------------------ */}

      {props.stackExp && props.stackExp.length > 0 ? (
        <TechExperienceGrid
          stacks={props?.stackExp}
          onCreateStackAssessment={props?.onCreateStackAssessment}
          devId={props.devId}
          devData={props.devData}
          stackAssessmentData={props?.stackAssessmentData}
          clickedStacksAssessment={props?.clickedStacksAssessment}
          stackAssessmentsLoading={props?.stackAssessmentsLoading}
          setTechStackData={props.setTechStackData}
          setIsOutSideBtnClicked={props.setIsOutSideBtnClicked}
          setChatBtnClickCount={props.setChatBtnClickCount}
        />
      ) : (
        <div className="no-tech-data">
          <div className="no-tech-data_icon">
            <NoStackIcon />
          </div>
          <div className="no-tech-data_text">
            <h2>{props?.emptyStackTitle}</h2>
            <p>{props?.emptyStackSubTitle}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechStacks;
