// DOCS: https://nivo.rocks/bump/
import { ResponsiveBar } from "@nivo/bar";

const CustomResponsiveBar = ({ data, indexByKey, otherKeys, maxValue }) => (
  <ResponsiveBar
    enableGridY={false}
    data={data}
    keys={otherKeys}
    indexBy={indexByKey}
    margin={{ top: 50, right: 50, bottom: 30, left: 25 }}
    maxValue={maxValue}
    padding={0.3}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "blues" }}
    height={200}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#459df5",
        rotation: -45,
        lineWidth: 20,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "n/a",
        },
        id: "dots",
      },
      {
        match: {
          id: "EVENTS",
        },
        id: "lines",
      },
    ]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    // axisBottom={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: 'date',
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={null}
    // axisLeft={{
    //     tickSize: 5,
    //     tickPadding: 5,
    //     tickRotation: 0,
    //     // legend: 'value',
    //     legendPosition: 'middle',
    //     legendOffset: -40
    // }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor="white"
    // legends={[
    //     {
    //         dataFrom: 'keys',
    //         anchor: 'bottom-right',
    //         direction: 'column',
    //         justify: false,
    //         translateX: 120,
    //         translateY: 0,
    //         itemsSpacing: 2,
    //         itemWidth: 100,
    //         itemHeight: 20,
    //         itemDirection: 'left-to-right',
    //         itemOpacity: 0.85,
    //         symbolSize: 20,
    //         effects: [
    //             {
    //                 on: 'hover',
    //                 style: {
    //                     itemOpacity: 1
    //                 }
    //             }
    //         ]
    //     }
    // ]}
    role="application"
    ariaLabel=""
    barAriaLabel={function (e) {
      return e.id + ": " + e.formattedValue + "..." + e.indexValue;
    }}
  />
);

export default CustomResponsiveBar;
