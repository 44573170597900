import React from "react";
import ReactDOM from "react-dom/client";

import Firebase, { FirebaseContext } from "./components/Firebase";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";
// import "bootstrap/dist/css/bootstrap.min.css";

// "ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0djXn9XcnVQTmRbWUI="
registerLicense(
  'Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS3xQd0ZiXH5acHBUQg==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEdgWHxfdXVRRmVaVQ==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhWn5dcXFUQmBbUUA=;MTE5MjExOEAzMjMwMmUzNDJlMzBLbFZiTFY3bVZ3dXZZRWM2aXVlU2pUOFB5c3dwMG1pSlQxV0grV0VTSVBzPQ==;MTE5MjExOUAzMjMwMmUzNDJlMzBUNVcxMHozUG52NFV2WU1qQkwwRDM2VWJZbENrdHZManVRbElkeTdmak5jPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhW3xfc3VQR2RdVEN/;MTE5MjEyMkAzMjMwMmUzNDJlMzBKdWtxTWppTDR6ZjBRUTBFajZXcjZGN1ZNM3NSenovb1JWUWVJZUh0c3NRPQ==;MTE5MjEyM0AzMjMwMmUzNDJlMzBlT2wwMjl4YzB4VDc4ZnQvYm5GcU5DZUNkbTJJdEtabHUyTkhVb0VRT3pVPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhWn5dcXFUQmJZUEA=;MTE5MjEyNUAzMjMwMmUzNDJlMzBTaWhMcDRQbWwwU3FXQTlCenB5cVlxMGE2bTh1MC9ISkJUZXdmQ1hvV3lvPQ==;MTE5MjEyNkAzMjMwMmUzNDJlMzBuUVpDdFArU2Vnc2F0UkY1dEwvVzFTOVRJcUgyMVI2OFpBNkdyMUx5aU9rPQ==;MTE5MjEyN0AzMjMwMmUzNDJlMzBKdWtxTWppTDR6ZjBRUTBFajZXcjZGN1ZNM3NSenovb1JWUWVJZUh0c3NRPQ=='
);
const rootElement = document.getElementById("root");
const rootContainer = ReactDOM.createRoot(rootElement);

rootContainer.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
