import React from "react";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

const Operations = (props) => {
  return (
    <div className="stat_content_data stat_content_rev row">
      <div className="stat_content_data-title">
        <h4>Operations</h4>
      </div>
      <div className="stat_content_data-content">
        <div className="column">
          <div className="row top">
            <label className="vendor-info">
              Hours In Your Company Work Day
            </label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) => props.updateVendorData("ops", e.data)}
              id="textboxEle"
              name="hoursInWorkDay"
              mode="Inline"
              type="Numeric"
              value={props.settings.ops.hoursInWorkDay}
              model={{ max: 12, ...props.numericModel, min: 4 }}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              Adjust your standard work day. This impacts how your developers
              are contracted. If your standard work day is more or less than 8h,
              adjust this here. A daily rate for your developers is the hourly
              rate multiplied by this number, which can be any amount up to 12.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="row top">
            <label className="vendor-info">Minimum Contract Team Size</label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) => props.updateVendorData("ops", e.data)}
              id="textboxEle"
              name="minContractTeamSize"
              mode="Inline"
              type="Numeric"
              value={props.settings.ops.minContractTeamSize}
              model={{ max: 8, ...props.numericModel, min: 1 }}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              Set the smallest team you will accept to take through the platform
              (1-8). The higher this number the less likely you are to be
              matched.{" "}
            </p>
          </div>
        </div>

        <div className="column">
          <div className="row top togglebox">
            {/* <label htmlFor="checked" className="vendor-info"></label> */}
            <label htmlFor="isDiscoverable" className="vendor-info">
              {" "}
              Make your resource discoverable to your entire Organisation{" "}
            </label>
            <SwitchComponent
              id="isDiscoverable"
              name="leadTimeOn"
              checked={props.settings?.ops?.discovery ?? true}
              change={(e) => props.onToggle(e, "discovery")}
            ></SwitchComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              The Spryte Platform is made to work for large and small partners
              alike. If you want your resources to be discoverable to other
              users within your own Organisation, leave this on. They can then
              be added on your internal projects.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="row top togglebox">
            {/* <label htmlFor="checked" className="vendor-info"></label> */}
            <label htmlFor="checked" className="vendor-info">
              {" "}
              Cross Vendor{" "}
            </label>
            <SwitchComponent
              id="checked"
              name="leadTimeOn"
              checked={props.settings.ops.crossVendor}
              change={(e) => props.onToggle(e, "crossVendor")}
            ></SwitchComponent>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              By default your resources will be matched with other resources
              from other Partners depending on their skills and the Client's
              particular requests. If you do not want your developers working on
              projects with other developers, you can set this to False. Beware
              that unless you are highly focused on a specific niche technical
              skill for which you are also the very best worldwide, this will
              dramatically reduce your chances of being matched.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="row top leadData">
            {/* <label htmlFor="checked" className="vendor-info"></label> */}

            <div className="row left togglebox">
              <label htmlFor="checkedLead" className="vendor-info">
                Automatic Resource Lead Time
              </label>
              <SwitchComponent
                id="checkedLead"
                name="leadTimeOn"
                checked={props.settings.ops?.leadTimeOn ?? true}
                change={(e) => props.onToggle(e, "leadTimeOn")}
              ></SwitchComponent>
            </div>
            {props.isLeadOn ? (
              <div className="row right leadData_time">
                <label className="vendor-info">Lead Time(days)</label>
                <InPlaceEditorComponent
                  className="inPlaceEditor"
                  actionBegin={(e) => props.updateVendorData("ops", e.data)}
                  id="textboxEle"
                  name="leadDays"
                  mode="Inline"
                  type="Numeric"
                  // value={15}
                  value={props.settings.ops.leadDays ?? 15}
                  model={{ max: 19, min: 1, format: "N0" }}
                ></InPlaceEditorComponent>
              </div>
            ) : (
              <div className="row right leadData_time">
                <label className="vendor-info" style={{ color: "#A2A8B4" }}>
                  Lead Time(days)
                </label>
                <p style={{ color: "#A2A8B4" }}>
                  {props.settings.ops.leadDays ?? 15}
                </p>
              </div>
            )}
          </div>
          <div className="bottom light">
            <p>
              {" "}
              If you don't want your resources to be immediatly booked (for
              tomorrow as an example), you can set a lead time. Setting a lead
              time of 15 days for example, will ensure that all your resources
              who have the lead time flag set will be undiscoverable and not
              booked until 15 days from today. This is automatically applied
              every day so that you don't have to manage availability precisely
              to the day on the platform.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="row top ">
            <label className="vendor-info">Company Roster Size</label>
            <InPlaceEditorComponent
              className="inPlaceEditor"
              actionBegin={(e) => props.updateVendor(e.data)}
              id="textboxEle"
              name="totalDevs"
              mode="Inline"
              type="Numeric"
              value={props.totalDevs}
              model={{ ...props.numericModel, min: 0 }}
            ></InPlaceEditorComponent>
          </div>
          <div className="bottom light">
            <p> How many technical resources are employed at your company</p>
          </div>
        </div>

        <div className="column">
          <div className="row top ">
            <label className="vendor-info">Uploaded Resources</label>
            <p>{props.totalDevsLive}</p>
          </div>
          <div className="bottom light">
            <p> This is how many resources you've uploaded to the platform</p>
          </div>
        </div>

        <div className="column">
          <div className="row top ">
            <label className="vendor-info">Bookable Resources</label>
            <p>{props.bookableDevs}</p>
          </div>
          <div className="bottom light">
            <p>
              {" "}
              This is the number of resources on the platform which are bookable
              by end clients
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operations;
