import React from "react";

const DevProfileIcon = ({ w = "11", h = "13", fill = "#4361E9" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91441 0H1.20493C0.540394 0 0 0.540328 0 1.20453V10.8421C0 11.5061 0.540327 12.0464 1.20493 12.0464H8.91441C9.57881 12.0464 10.1191 11.5061 10.1191 10.8421V1.20493C10.1195 0.54066 9.57921 0 8.91441 0ZM9.63753 10.8421C9.63753 11.2408 9.31317 11.565 8.91441 11.565H1.20493C0.806302 11.565 0.482146 11.2408 0.482146 10.8421V1.20493C0.482146 0.806302 0.806302 0.482146 1.20493 0.482146H8.91441C9.31284 0.482146 9.637 0.806302 9.637 1.20493L9.63753 10.8421Z"
        fill={fill}
      />
      <path
        d="M7.60148 5.26562H2.52083C2.39855 5.26562 2.2998 5.36477 2.2998 5.48705C2.2998 5.60926 2.39855 5.70847 2.52083 5.70847H7.60141C7.72383 5.70847 7.82257 5.60933 7.82257 5.48705C7.82264 5.36483 7.72383 5.26562 7.60148 5.26562Z"
        fill={fill}
      />
      <path
        d="M7.60148 6.27734H2.52083C2.39855 6.27734 2.2998 6.37669 2.2998 6.49897C2.2998 6.62125 2.39855 6.72012 2.52083 6.72012H7.60141C7.72383 6.72012 7.82257 6.62125 7.82257 6.49897C7.8223 6.37602 7.72383 6.27734 7.60148 6.27734Z"
        fill={fill}
      />
      <path
        d="M7.60148 7.28516H2.52083C2.39855 7.28516 2.2998 7.3845 2.2998 7.50665C2.2998 7.62893 2.39855 7.72794 2.52083 7.72794H7.60141C7.72383 7.72794 7.82257 7.62893 7.82257 7.50665C7.82264 7.3845 7.72383 7.28516 7.60148 7.28516Z"
        fill={fill}
      />
      <path
        d="M7.59971 8.29297H5.05918C4.9371 8.29297 4.83789 8.39191 4.83789 8.51419C4.83789 8.63647 4.9371 8.73582 5.05918 8.73582H7.59964C7.72205 8.73582 7.82046 8.63647 7.82046 8.51419C7.82046 8.39191 7.72205 8.29297 7.59971 8.29297Z"
        fill={fill}
      />
      <path
        d="M7.59927 10.2852H5.82077C5.69809 10.2852 5.59961 10.3842 5.59961 10.5064C5.59961 10.6286 5.69809 10.7276 5.82077 10.7276H7.59927C7.72169 10.7276 7.8201 10.6286 7.8201 10.5064C7.8201 10.3842 7.72162 10.2852 7.59927 10.2852Z"
        fill={fill}
      />
      <path
        d="M5.30532 4.2941C5.30532 3.87532 4.82317 3.31046 4.17233 3.14542C4.44422 2.96257 4.6316 2.60144 4.6316 2.27622C4.6316 1.81754 4.26017 1.44531 3.8011 1.44531C3.34256 1.44531 2.97059 1.81754 2.97059 2.27622C2.97059 2.60111 3.15797 2.96223 3.42926 3.14542C2.77935 3.31039 2.29688 3.87566 2.29688 4.2941C2.29741 4.78895 5.30532 4.78895 5.30532 4.2941ZM3.73414 3.38646H3.73035L3.59284 3.22854C3.66006 3.25234 3.72862 3.26691 3.80116 3.26691C3.87384 3.26691 3.94246 3.25274 4.00955 3.22887L3.87165 3.3864H3.86839L4.22326 4.24383L3.80116 4.66487L3.37953 4.24383L3.73414 3.38646Z"
        fill={fill}
      />
    </svg>
  );
};

export default DevProfileIcon;
