import React, { useState, useEffect } from "react";
import prof from "../../images/SPRYTE_LOGOS-08.jpg";

export default function CompanyLogo(props) {
  const [url, setUrl] = useState("");
  const [alt, setAlt] = useState("");
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpdate = (im) => {
    if (im) {
      const storage = props.firebase.getStorage();
      const uploadTask = storage
        .ref(`vendors/${props.vendor.id}/logo/${im.name}`)
        .put(im);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setError(error);
        },
        () => {
          storage
            .ref(`vendors/${props.vendor.id}/logo`)
            .child(im.name)
            .getDownloadURL()
            .then(async (url) => {
              // setUrl(url);
              setProgress(0);
              if (props.logo) {
                const updates = {};
                updates.logo = props.logo;
                updates.logo["url"] = url;
                updates.logo["name"] = im.name;
                try {
                  await props.firebase.updateVendor(props.vendor.id, updates);
                  this.props.updateVendor();
                } catch (err) {
                  console.log(err);
                }
              } else {
                const updates = {};
                updates.logo = {
                  url: url,
                  name: im.name,
                };
                try {
                  await props.firebase.updateVendor(props.vendor.id, updates);
                  this.props.updateVendor();
                  setLoading(false);
                } catch (err) {
                  console.log(err);
                }
              }
              setLoading(false);
            });
        }
      );
    } else {
      setError("Error please choose an image to upload");
    }
  };

  const handChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setError("");
        handleUpdate(file);
      } else {
        setError("Please select an image to upload");
      }
    }
  };
  useEffect(() => {
    let componentMounted = true;
    const getData = async () => {
      if (componentMounted) {
        setLoading(true);
        if (loading === true) {
          if (props.logo) {
            try {
              // const vendor = await props.firebase.getCurrentVendorDoc();
              const logo = props.logo;
              setUrl(logo.url);
              setAlt(logo.name);
            } catch (err) {
              setUrl("");
              setAlt("Avatar");
            }
          } else {
            try {
              const logos = await props.firebase.getLogos(props.vendor.id);
              setUrl(logos[0].fileUrl);
              setAlt(logos[0].fileName);
            } catch (err) {
              setUrl("");
              setAlt("Avatar");
            }
          }
        }
      }
    };
    getData();

    return () => {
      componentMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vendor.id, loading]);

  return (
    <div className="avatar">
      {url ? (
        <>
          <img className="avatar_image" src={url} alt={alt} />
          <div className="upload-btn-wrapper">
            <button className="btn">
              <p>+</p>
            </button>
            <input type="file" name="myfile" onChange={handChange} />
          </div>
        </>
      ) : (
        <div>
          <img className="avatar_image" src={prof} alt="Logo" />
          <div className="upload-btn-wrapper">
            <button className="btn">
              <p>+</p>
            </button>
            <input type="file" name="myfile" onChange={handChange} />
          </div>
        </div>
      )}

      <div>
        {progress > 0 ? <progress value={progress} max="100" /> : ""}
        <p
          style={{
            color: "red",
            fontWeight: "500",
            opacity: ".65",
            fontStyle: "italic",
          }}
        >
          {error}
        </p>
      </div>
    </div>
  );
}
