import React, { useState, useEffect, useContext } from "react";
import {
  GanttComponent,
  Inject,
  Edit,
  Selection,
  Toolbar,
  ColumnDirective,
  ColumnsDirective,
  Sort,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-gantt";
import DevsForProjects from "./classes/DevsForProjects";
import Project from "./classes/Project";
import ProjectsList from "./classes/ProjectsList";
import Sprint from "./classes/Sprint";
import SprintsList from "./classes/sprintsList";
import { GlobalVendorContex, AuthUserContext } from "./Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";

const Projects = (props) => {
  const taskFields = {
    id: "TaskId",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    duration: "Duration",
    dedication: "dedication",
    child: "subtasks",
  };

  // labels from the grids
  const labelSettings = {
    leftLabel: "TaskName",
    rightLabel: "dedication",
  };

  // gantt toolbar
  const toolbar = [
    "ExpandAll",
    "CollapseAll",
    "ZoomIn",
    "ZoomOut",
    "ZoomToFit",
    "ExcelExport",
    "CsvExport",
    "PdfExport",
  ];

  const vendorData = useContext(GlobalVendorContex);

  //to get all gantt component methods, events, and propreties
  const [ganttInstance, setGanttIstance] = useState({});

  // loading boolean
  const [isLoading, setIsLoading] = useState(false);

  // User data
  const allData = useContext(AuthUserContext);

  // project state
  const [projects, setProjects] = useState([]);

  // For Sorting in the gantt chart(sort depending on the end days Ascending)
  const sortingOptions = {
    columns: [{ field: "EndDate", direction: "Ascending" }],
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      // Get the list of devs
      const { devs } = await props.firebase.getDevsForVendor(
        vendorData?.vendor.id
      );

      // Classes
      let sprintList = new SprintsList();
      let projectsList = new ProjectsList();

      for (const dev of devs) {
        //For creating the devs Name
        let devName = dev.first + " " + dev.last;

        // get
        let devAvailsCol = await props.firebase.db
          .collection("devs")
          .doc(dev.id)
          .collection("availability")
          .get();
        for (const devAvail of devAvailsCol.docs) {
          let avail = devAvail.data();

          // if an availability has a sprintRef
          // that means the availability is from a project
          // we will instantiate the classes and create the list of sprints, projects
          if (avail.sprintRef) {
            let sprintColId = avail.sprintRef.id;
            let sprintPath = avail.sprintRef.path.split("/");
            let clientId = sprintPath[1];
            let sprintData = await props.firebase.getSprintData(
              clientId,
              sprintColId
            );

            let projectId = sprintData.projectRef.id;

            let projectData = await props.firebase.getProjectData(
              clientId,
              projectId
            );

            //team data and Id
            let teamId = sprintData.team.id;
            let teamsData = await props.firebase.getDevDedictionInTeam(teamId);
            let member = teamsData.members.filter(
              (member) => member.id.id === dev.id
            );

            // Ids
            sprintData.id = sprintColId;
            projectData.id = projectId;

            // devProjects class
            let devForProject = new DevsForProjects(
              dev.id,
              devName,
              sprintColId,
              member[0].dedication
            ); //Create the dev Class
            devForProject.EndDate = sprintData.end.toDate();
            devForProject.StartDate = sprintData.start.toDate();

            // instantiate the sprint class
            let sprint = new Sprint(
              sprintColId,
              sprintData.name,
              sprintData.start.toDate(),
              sprintData.end.toDate(),
              projectId
            );

            // Instantiate the project class
            let project = new Project(
              projectId,
              projectData.name,
              projectData.currentStartDate.toDate(),
              projectData.currentEndDate.toDate()
            );

            // add sprints to the sprint List
            sprintList.addSprint(sprint);
            // add devs to the sprint subtask
            sprintList.addDevToSprint(devForProject);

            // add projects to the projects list
            projectsList.addProject(project);
            projectsList.addSprintToProject(sprint);
          }
        }
      }
      setProjects(projectsList.projects);
      setIsLoading(false);
    };
    getData();
    // props.firebase.reportPageVisits("Projects Page", allData.isSuperPartner, {
    //   "Vendor Id": vendorData.vendor.id,
    //   "Vendor Name": vendorData.vendor.companyName,
    // });
  }, [vendorData?.vendor.id]);

  const toolbarClick = (args) => {
    if (args.item.id === "ResourceView_excelexport") {
      ganttInstance.excelExport();
    } else if (args.item.id === "ResourceView_csvexport") {
      ganttInstance.csvExport();
    } else if (args.item.id === "ResourceView_pdfexport") {
      ganttInstance.pdfExport();
    }
  };
  // You currently have no ongoing or planned projects with Spryte

  return (
    <div className="control-pane">
      <div className="resource-header">
        <h3>Projects</h3>
        <div className="desc-icon">
          <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
          <div className="description">
            Current Projects, Sprints and Allocated Resources{" "}
          </div>
        </div>
      </div>
      {isLoading ? (
        <CircularProgress color="primary" size="large" />
      ) : (
        <div>
          {projects.length >= 1 ? (
            <div className="control-section">
              <GanttComponent
                id="ResourceView"
                dataSource={projects}
                taskFields={taskFields}
                labelSettings={labelSettings}
                height="100%"
                sortSettings={sortingOptions}
                allowSorting={true}
                toolbar={toolbar}
                treeColumnIndex={1}
                ref={(gantt) => {
                  setGanttIstance(gantt);
                }}
                allowExcelExport={true}
                allowPdfExport={true}
                allowSelection={true}
                toolbarClick={toolbarClick.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="TaskId"
                    visible={false}
                    headerText="Id"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="TaskName"
                    headerText="Projects"
                    width="250"
                    clipMode="EllipsisWithTooltip"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="dedication"
                    headerText="dedication"
                  ></ColumnDirective>
                  <ColumnDirective field="StartDate"></ColumnDirective>
                  <ColumnDirective field="EndDate"></ColumnDirective>
                </ColumnsDirective>
                <Inject
                  services={[
                    Selection,
                    Sort,
                    Toolbar,
                    Edit,
                    ExcelExport,
                    PdfExport,
                    // CsvExport,
                  ]}
                />
              </GanttComponent>
            </div>
          ) : (
            <div className="resource-header">
              <p
                style={{
                  color: "red",
                  fontWeight: "500",
                  opacity: ".65",
                  fontStyle: "italic",
                  paddingTop: "25px",
                }}
              >
                You currently have no ongoing or planned projects with Spryte.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Projects;
