import styles from "./styles/searchComponent.module.css";
import React, { useState, useEffect } from "react";

import { MdClose } from "react-icons/md";
import {
  getDocumentsByArrayMembershipInCollection,
  getMatchesURL,
} from "../../globals/services";
// import { analytics } from "../../Firebase/firebase";
import parseFreeFormText from "../Markets/functions/searchFunctions";

// Crypto is used for hashing the skill IDs.
const crypto = require("crypto");

// This is required to parse residual text once the search button is pressed.
// import { parseFreeFormText } from "../functions/searchFunctions";

function SearchComponent({
  horizontal,
  homepage,
  startingStacks,
  vendorID,
  vendorSlug,
  interns = "",
  pageUsedOn,
  requirements,
  setLocalSkillsObject,
  localSkillsObject,
}) {
  const [data, setData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [selectedStacks, setSelectedStacks] = useState([]);
  const [selectedStackImages, setSelectedStackImages] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docIDObject, setDocIDObject] = useState({
    industry: {},
    subcollections: {},
    technologies: {},
  });
  const [placeholder, setPlaceholder] = useState(
    "Search by keywords or specific attributes"
  );
  const [searchText, setSearchText] = useState("");
  async function fetchAndSetData(e) {
    const data = await getDocumentsByArrayMembershipInCollection(
      "technologies",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "rank",
      true,
      true,
      false,
      5
    );
    const industryDataFetch = await getDocumentsByArrayMembershipInCollection(
      "industry",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "name",
      true,
      true,
      false,
      5
    );
    const categoriesData = await getDocumentsByArrayMembershipInCollection(
      "subcollections",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "name",
      true,
      true,
      false,
      5
    );
    setSearchText(e.target.value);
    setData(data);
    setIndustryData(industryDataFetch);
    setCatData(categoriesData);
  }

  function addStack(stackName, stackImage, stackCollectionKey, stackID) {
    var placeholderString = "";

    docIDObject[stackCollectionKey][stackName] = stackID;
    setDocIDObject({ ...docIDObject });
    const currentStacks = selectedStacks;
    const currentStackImages = selectedStackImages;
    if (!currentStacks?.includes(stackName)) {
      currentStacks.push(stackName);
      currentStackImages.push({ image: stackImage, name: stackName });
    }

    currentStacks.map(
      (stackName) =>
        (placeholderString +=
          currentStacks.length == 1 ? stackName : `${stackName}, `)
    );

    setSearchText("");
    setSelectedStacks([...currentStacks]);
    setSelectedStackImages([...currentStackImages]);
    setPlaceholder(placeholderString);
  }

  function addStackToLocal(stack, add) {
    if (pageUsedOn === void 0) return;
    var tempLocalSkillsObject = [...localSkillsObject] ?? [];
    if (add) {
      tempLocalSkillsObject.push(stack);
      setLocalSkillsObject(tempLocalSkillsObject);
    } else {
      setLocalSkillsObject(
        tempLocalSkillsObject.filter((s) => s["name"] != stack)
      );
    }
  }

  useEffect(() => {
    if (pageUsedOn == "customRequirement")
      requirements.current = {
        industryDomain: Object.values(docIDObject.industry).sort(),
        projectTypes: Object.values(docIDObject.subcollections).sort(),
        stacks: Object.values(docIDObject.technologies).sort(),
      };
  }, [docIDObject]);

  useEffect(() => {
    startingStacks?.map((stack) =>
      addStack(stack.name, stack.logoStorage, stack.collectionName, stack.id)
    );
  }, [startingStacks]);

  function removeStack(stackName) {
    delete docIDObject["technologies"][stackName];
    delete docIDObject["industry"][stackName];
    delete docIDObject["subcollections"][stackName];
    setDocIDObject({ ...docIDObject });
    const currentStacks = selectedStacks;
    const index = currentStacks.indexOf(stackName);
    currentStacks.splice(index, 1);
    setSelectedStacks([...currentStacks]);

    // To remove stack image chip from UI (TODO: re-write without the above splice?)
    const currentStackImages = selectedStackImages;
    setSelectedStackImages([
      ...currentStackImages.filter(
        (stackMap) => stackMap.name.toUpperCase() != stackName.toUpperCase()
      ),
    ]);
  }

  /**
   * This function creates a URL to push to, based upon the search criteria.
   *
   * @returns {String} Returns the URL to push to.
   */
  function createURL() {
    // Get the category, industry, and stack names.
    const categoryNames = {
      key: "category",
      value: [
        ...Object.keys(docIDObject.subcollections).map((val) =>
          val.replace(/ /g, "_")
        ),
      ].join(" "),
    };
    const industryNames = {
      key: "industry",
      value: [
        ...Object.keys(docIDObject.industry).map((val) =>
          val.replace(/ /g, "_")
        ),
      ].join(" "),
    };
    const stackNames = {
      key: "stack",
      value: [
        ...Object.keys(docIDObject.technologies).map((val) =>
          val.replace(/ /g, "_")
        ),
      ].join(" "),
    };

    // Create the url.
    let url = "/results/pricing";

    // For each skill, append the names
    for (let skillURLObject of [industryNames, categoryNames, stackNames]) {
      if (!skillURLObject.value) continue;
      url +=
        (url.endsWith("pricing") ? "?" : "&") +
        skillURLObject.key +
        "=" +
        skillURLObject.value;
    }

    //
    return url;
  }

  /**
   * This function hashes objects passed in, in line with the hashes produced by
   * the getMatches cloud function.
   */
  function computeQueryFamilyHash(queryData) {
    return crypto
      .createHash("sha256")
      .update(JSON.stringify(queryData))
      .digest("hex");
  }

  /**
   * This function creates a URL to push to, based upon the search criteria.
   *
   * @returns {String} Returns the URL to push to.
   */
  function createQueryFamilyHashURL() {
    // Create the url.
    let url = "/";

    // Update the url based upon whether we have a partner-specific query,
    // an intern query, or a regular query.
    if (vendorID) url += "partners/" + vendorSlug + "/pricing/";
    else if (interns) url += "interns/";
    else url += "search-results/";

    // Hash the data.
    const queryFamilyHash = computeQueryFamilyHash({
      industryDomain: Object.values(docIDObject.industry).sort(),
      projectTypes: Object.values(docIDObject.subcollections).sort(),
      stacks: Object.values(docIDObject.technologies).sort(),
    });

    // Return the URL.
    return url + queryFamilyHash;
  }

  /**
   * This function queries getMatches for a team corresponding to the search criteria requested.
   *
   * @param {[Boolean=false]} responseExpected - whether a response is expected.
   *
   * @returns {Promise<null>} Returns nothing. Pushes to the next page.
   */
  function search(responseExpected = false) {
    // Create the request headers.
    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");

    // Create a date representing the day to query.
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 18);
    while ([0, 6].includes(startDate.getDay()))
      startDate.setDate(startDate.getDate() + 1);

    // The payload. Most keys are set by default for the query.
    const payload = {
      isANewProject: true, // Is this a new project?
      industryDomain: [...Object.values(docIDObject.industry)], // Industries.
      projectTypes: [...Object.values(docIDObject.subcollections)], // Categories.
      stacks: [...Object.values(docIDObject.technologies)], // Stacks.
      minTeamSize: 1, // The smallest team size accepted.
      maxTeamSize: 8, // The largest team size accepted.
      queryTypes: ["global", "connect", "local"], // The types of query to run.
      clientLat: 37, // The client's latitude. This is used for connect team results.
      clientLon: 73, // The client's longitude. This is used for connect team results.
      locationScope: "country", // The location scope for the local results. Note that these words are Google Places API terms.
      locationName: "United States", // The value for the location scope. In this case, it will look for local teams in: Country - United States.
      responseExpected: responseExpected, // Whether we want results (as opposed to just caching).
      daysToCache: 30,
      vendorQuery: vendorID ? true : false,
      vendorID: vendorID ? vendorID : "",
      startDate: [
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate(),
      ],
    };

    // The request's options.
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: requestHeaders,
    };

    // Attempt to call getMatches.
    try {
      // Call the getMatches function.
      fetch(getMatchesURL + interns, requestOptions).catch((error) =>
        console.log(error)
      );

      // If an error occurred, do nothing.
    } catch (error) {}

    //Track Analytics
    // analytics.track("Search", {
    //   ...payload,
    // });

    // analytics.track(
    //   "Search",
    //   {
    //     category: "Spryte Website",
    //     label: "Search",
    //     value: "1",
    //   },
    //   {
    //     plugins: {
    //       // disable this specific track event in all plugins except googleAnalytics
    //       all: false,
    //       "google-analytics": true,
    //     },
    //   }
    // );

    // Return.
    return;
  }

  // Set loading state to false when new query is loaded.
  useEffect(() => setLoading(false), []);

  return (
    <>
      {!horizontal ? (
        <div className={styles.search}>
          <div className={styles.infoOverflow}>
            <div className={styles.info} onClick={() => setShowInfo(!showInfo)}>
              <h3>{showInfo ? "X" : "?"}</h3>
            </div>
          </div>
          <div
            className={`${styles.infoContent} ${showInfo ? styles.active : ""}`}
          >
            <p>
              Search here for tech stacks & domains offered through the Spryte
              Platform.
            </p>
          </div>
          <div className={styles.selectedStackDiv}>
            {selectedStacks?.map((stackName) => {
              return (
                <div
                  className={styles.selectedStackItem}
                  key={stackName + "CHOSEN"}
                >
                  <div
                    onClick={() => removeStack(stackName)}
                    className={styles.close}
                  >
                    <h3>X</h3>
                  </div>
                  <p>{stackName}</p>
                </div>
              );
            })}
          </div>
          <input name="stackName" onChange={fetchAndSetData} />
          <div
            className={styles.stackDiv}
            style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
          >
            {data.length != 0 ? (
              <h3 className={styles.hrTitle}>TECH STACKS</h3>
            ) : (
              <div></div>
            )}
            {data.length != 0 ? <hr></hr> : <div></div>}
            {data?.map((stackItem) => {
              return (
                <div
                  className={styles.stackItem}
                  key={stackItem?.data()?.["name"] + "STACK"}
                  onClick={() => {
                    search();
                    // analytics.track('Search', {
                    //   test : "test"
                    //   // payload : JSON.stringify(payload),
                    // })
                    addStack(
                      stackItem.data()?.["name"],
                      stackItem.data()?.["logoStorage"],
                      stackItem.ref.parent.id,
                      stackItem.ref.id
                    );
                  }}
                >
                  <img src={stackItem.data()?.["logoStorage"]} alt="" />
                  <h3>
                    {!stackItem.data()?.["selected"]
                      ? stackItem.data()?.["name"]
                      : "SELECTED"}
                  </h3>
                </div>
              );
            })}
          </div>
          <div
            className={styles.stackDiv}
            style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
          >
            {catData.length != 0 ? (
              <h3 className={styles.hrTitle}>CATEGORIES</h3>
            ) : (
              <div></div>
            )}
            {catData.length != 0 ? <hr></hr> : <div></div>}
            {catData?.map((stackItem) => {
              return (
                <div
                  className={styles.stackItem}
                  key={stackItem?.data()?.["name"] + "CATEGORY"}
                  onClick={() => {
                    search();
                    addStack(
                      stackItem.data()?.["name"],
                      stackItem.data()?.["logoStorage"],
                      stackItem.ref.parent.id,
                      stackItem.ref.id
                    );
                  }}
                >
                  <img
                    src={stackItem.data()?.["logoStorage"]}
                    alt=""
                    style={{ opacity: ".85" }}
                  />
                  <h3>{stackItem.data()?.["name"]}</h3>
                </div>
              );
            })}
          </div>
          <div
            className={styles.stackDiv}
            style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
          >
            {industryData.length != 0 ? (
              <h3 className={styles.hrTitle}>INDUSTRIES</h3>
            ) : (
              <div></div>
            )}
            {industryData.length != 0 ? <hr></hr> : <div></div>}
            {industryData?.map((stackItem) => {
              return (
                <div
                  className={styles.stackItem}
                  key={stackItem?.data()?.["name"] + "INDUSTRY"}
                  onClick={() => {
                    search();
                    addStack(
                      stackItem.data()?.["name"],
                      stackItem.data()?.["logoStorage"],
                      stackItem.ref.parent.id,
                      stackItem.ref.id
                    );
                  }}
                >
                  <img
                    src={stackItem.data()?.["logoStorage"]}
                    alt=""
                    style={{ opacity: ".85" }}
                  />
                  <h3>{stackItem.data()?.["name"]}</h3>
                </div>
              );
            })}
          </div>
          <div
            className={styles.btnSearch}
            onClick={async () => {
              setLoading(true);
              const parsedFreeFormText = await parseFreeFormText(searchText);
              Object.entries(parsedFreeFormText).forEach(
                ([skillKey, skillValuesObject]) =>
                  Object.entries(skillValuesObject).forEach(
                    ([skillName, skillID]) =>
                      (docIDObject[skillKey][skillName] = skillID)
                  )
              );
              setDocIDObject(docIDObject);
              search(true);
              window.open(createQueryFamilyHashURL());
            }}
          >
            {!loading ? (
              <h3>SEARCH</h3>
            ) : (
              <div className="spinner-box">
                <div className="pulse-container">
                  <div className="pulse-bubble pulse-bubble-1"></div>
                  <div className="pulse-bubble pulse-bubble-2"></div>
                  <div className="pulse-bubble pulse-bubble-3"></div>
                </div>
              </div>
            )}
          </div>
          <p>
            Spryte search allows you to get instant pricing and availability
            based on dozens of search criteria. Get the best team, anywhere in
            the world, in 3-clicks.
          </p>
        </div>
      ) : (
        <div
          className={`${styles.horizontalSearch} ${
            homepage ? ` ${styles.homepage} ` : ""
          }`}
          style={{ left: pageUsedOn == "customRequirement" ? "0" : null }}
        >
          {homepage ? (
            <div
              className={`${styles.betaChip} ${
                homepage ? styles.homepage : ""
              }`}
            >
              <p>SPRYTE SEARCH BETA</p>
            </div>
          ) : null}
          <div className={styles.inputCtr}>
            <div className={styles.inputFlex}>
              <input
                placeholder={
                  homepage
                    ? placeholder
                    : "Search by keywords or specific attributes"
                }
                onChange={fetchAndSetData}
              ></input>
              <div className={styles.pulseSearch}></div>
              {pageUsedOn != "customRequirement" ? (
                <div
                  className={styles.searchBtn}
                  onClick={async () => {
                    setLoading(true);
                    const parsedFreeFormText = await parseFreeFormText(
                      searchText
                    );
                    Object.entries(parsedFreeFormText).forEach(
                      ([skillKey, skillValuesObject]) =>
                        Object.entries(skillValuesObject).forEach(
                          ([skillName, skillID]) =>
                            (docIDObject[skillKey][skillName] = skillID)
                        )
                    );
                    setDocIDObject(docIDObject);
                    search(true);
                    window.open(createQueryFamilyHashURL());
                  }}
                >
                  {!loading ? (
                    <h3>SEARCH</h3>
                  ) : (
                    // <MdSearch style={{fill:'white'}} size={28}/>
                    <div className="spinner-box">
                      <div className="pulse-container small">
                        <div className="pulse-bubble pulse-bubble-1"></div>
                        <div className="pulse-bubble pulse-bubble-2"></div>
                        <div className="pulse-bubble pulse-bubble-3"></div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
              {data.length != 0 ? (
                <div
                  className={styles.searchResults}
                  style={{
                    width: pageUsedOn == "customRequirement" && "375px",
                  }}
                >
                  <div
                    className={styles.stackDiv}
                    style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
                  >
                    {data?.map((stackItem) => {
                      return (
                        <div
                          className={styles.horizontalStackItem}
                          key={stackItem?.data()?.["name"] + "STACK"}
                          onClick={() => {
                            if (
                              !selectedStacks.includes(
                                stackItem.data()?.["name"]
                              )
                            ) {
                              search();
                              addStackToLocal(
                                {
                                  ...stackItem.data(),
                                  collectionName: "technologies",
                                },
                                true
                              );
                              addStack(
                                stackItem.data()?.["name"],
                                stackItem.data()?.["logoStorage"],
                                stackItem.ref.parent.id,
                                stackItem.ref.id
                              );
                            } else {
                              addStackToLocal(
                                stackItem.data()?.["name"],
                                false
                              );
                              removeStack(stackItem?.data()?.["name"]);
                            }
                          }}
                        >
                          <img src={stackItem.data()?.["logoStorage"]} alt="" />
                          <div>
                            <h3>{stackItem.data()?.["name"]}</h3>
                            {selectedStacks.includes(
                              stackItem.data()?.["name"]
                            ) ? (
                              <div className={styles.selected}>
                                <p>SELECTED</p>
                                <div className={styles.check}>
                                  <h3>&#10003;</h3>
                                </div>
                              </div>
                            ) : (
                              <p>Tech Stack</p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={styles.stackDiv}
                    style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
                  >
                    {catData?.map((stackItem) => {
                      return (
                        <div
                          className={styles.horizontalStackItem}
                          key={stackItem?.data()?.["name"] + "CATEGORY"}
                          onClick={() => {
                            if (
                              !selectedStacks.includes(
                                stackItem.data()?.["name"]
                              )
                            ) {
                              search();
                              addStackToLocal(
                                {
                                  ...stackItem.data(),
                                  collectionName: "subcollections",
                                },
                                true
                              );
                              addStack(
                                stackItem.data()?.["name"],
                                stackItem.data()?.["logoStorage"],
                                stackItem.ref.parent.id,
                                stackItem.ref.id
                              );
                            } else {
                              addStackToLocal(
                                stackItem.data()?.["name"],
                                false
                              );
                              removeStack(stackItem?.data()?.["name"]);
                            }
                          }}
                        >
                          <img
                            id={styles.spryte}
                            src={stackItem.data()?.["logoStorage"]}
                            alt=""
                          />
                          <div>
                            <h3>{stackItem.data()?.["name"]}</h3>
                            <p
                              className={
                                !selectedStacks.includes(
                                  stackItem.data()?.["name"]
                                )
                                  ? ""
                                  : styles.selected
                              }
                            >
                              {!selectedStacks.includes(
                                stackItem.data()?.["name"]
                              )
                                ? "Category"
                                : "SELECTED"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={styles.stackDiv}
                    style={{ marginBottom: data.length != 0 ? "12.5px" : "0" }}
                  >
                    {industryData?.map((stackItem) => {
                      return (
                        <div
                          className={styles.horizontalStackItem}
                          key={stackItem?.data()?.["name"] + "INDUSTRY"}
                          onClick={() => {
                            if (
                              !selectedStacks.includes(
                                stackItem.data()?.["name"]
                              )
                            ) {
                              search();
                              addStackToLocal(
                                {
                                  ...stackItem.data(),
                                  collectionName: "industry",
                                },
                                true
                              );
                              addStack(
                                stackItem.data()?.["name"],
                                stackItem.data()?.["logoStorage"],
                                stackItem.ref.parent.id,
                                stackItem.ref.id
                              );
                            } else {
                              addStackToLocal(
                                stackItem.data()?.["name"],
                                false
                              );
                              removeStack(stackItem?.data()?.["name"]);
                            }
                          }}
                        >
                          <img
                            id={styles.spryte}
                            src={stackItem.data()?.["logoStorage"]}
                            alt=""
                          />
                          <div>
                            <h3>{stackItem.data()?.["name"]}</h3>
                            <p
                              className={
                                !selectedStacks.includes(
                                  stackItem.data()?.["name"]
                                )
                                  ? ""
                                  : styles.selected
                              }
                            >
                              {!selectedStacks.includes(
                                stackItem.data()?.["name"]
                              )
                                ? "Industry"
                                : "SELECTED"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {!homepage ? (
              <div
                className={styles.selectedStackDiv}
                style={{ width: pageUsedOn == "customRequirement" && "750px" }}
              >
                {/* {selectedStackImages.length ? <h3 style={{opacity: '.55', fontStyle: 'italic'}}>Search criteria for </h3> : null} */}
                {selectedStackImages?.map((stack, index) => {
                  return (
                    <div
                      onClick={() => {
                        removeStack(stack.name);
                        addStackToLocal(stack.name, false);
                      }}
                      key={`SRC ${stack.image}`}
                      className={styles.ssi}
                    >
                      <h3>{stack.name}</h3>
                      <div className={styles.closeBtn}>
                        <MdClose style={{ fill: "grey" }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SearchComponent;
