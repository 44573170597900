import React, { useMemo } from "react";
import styled from "styled-components";
import SpResponsiveImg from "../../../../globals/SpResponsiveImg";
// import SpResponsiveImg from "../../SpResponsiveImg";

const ReqOption = styled.div`
  // width: 400px;
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.08);
  font-family: "Manrope", sans-serif;
  .reqSections:not(:last-child) {
    border: 1px solid #ececec;
  }
  flex: 3;
`;

const ReqOptionEl = styled.div`
  padding: 1rem 2rem;
  .optionReqDes {
    color: #a0a0a0;
  }
  h4 {
    font-weight: 500;
    color: rgba(154, 162, 192, 1);
    margin-bottom: 0.5rem;
  }
`;

const ReqOptionHead = styled(ReqOptionEl)`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => (color ? color : "#ffedd1")};
  h3 {
    flex: 3;
  }
  & > div {
    display: flex;
    gap: 1rem;
    button {
      background-color: transparent;
      padding: 0;
      color: #4361e9;
      border: none;
      font-weight: 500;
      font-size: 1.4rem;

      margin: 0;
    }
    .deleteReq {
      color: #ff223d;
    }
  }
`;
const Stacks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  & > div {
    display: flex;
    gap: 0.5rem;
    p {
      color: #434146;
    }
  }
`;

const DevsPositions = styled(Stacks)`
  & > div {
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 0.5rem;
  }
`;

const SingleOption = ({
  color = "#ffedd1",
  positions = [],
  userType,
  optionData,
  onOptionEdit,
  onOptionDelete,
  selectedUserRole,
}) => {
  const stacks = useMemo(() => {
    return Object.values(optionData?.originalTeamCardData?.skillsObject ?? {});
  }, [optionData?.originalTeamCardData?.skillsObject]);

  return (
    <ReqOption>
      <ReqOptionHead color={color}>
        <h3>{optionData?.name}</h3>
        <div>
          {selectedUserRole === "admin" && (
            <button className="deleteReq" onClick={onOptionDelete}>
              Delete
            </button>
          )}
          {selectedUserRole !== "partner" && (
            <button onClick={onOptionEdit}>Edit</button>
          )}
        </div>
      </ReqOptionHead>

      <ReqOptionEl className="reqOptionDesc reqSections">
        <p className="optionReqDes">
          {optionData?.description !== ""
            ? optionData?.description
            : "No Description"}
        </p>
      </ReqOptionEl>
      {stacks?.length > 0 && (
        <ReqOptionEl className="reqOptionStacks reqSections">
          <h4>Stacks</h4>
          <Stacks>
            {stacks?.map((stack, i) => {
              return (
                <div key={`${stack?.name}_${i}`}>
                  <SpResponsiveImg
                    src={stack?.logoStorage}
                    alt={stack.name}
                    width="20px"
                    height="20px"
                  />
                  <p>{stack?.name}</p>
                </div>
              );
            })}
          </Stacks>
        </ReqOptionEl>
      )}
      {positions?.length > 0 && (
        <ReqOptionEl className="reqOptionPositions reqSections">
          <h4>Positions</h4>
          <DevsPositions>
            {positions?.map((position, i) => {
              return (
                <div>
                  <p>{position?.label}</p>
                </div>
              );
            })}
          </DevsPositions>
        </ReqOptionEl>
      )}
      {optionData?.originalTeamCardData?.countryList?.length > 0 && (
        <ReqOptionEl className="reqOptionTeamLoc reqSections">
          <h4>Desired resource locations</h4>

          <Stacks>
            {optionData?.originalTeamCardData?.countryList?.map(
              (country, i) => {
                return <p key={country}>{country}</p>;
              }
            )}
          </Stacks>
        </ReqOptionEl>
      )}
    </ReqOption>
  );
};

export default SingleOption;
