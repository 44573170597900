import React from "react";
// import '../../../../css/Contract.css';
import "react-toastify/dist/ReactToastify.css";
import ClipBoard from "./badges_copy/ClipBoard";

const Badges = (props) => {
  return (
    <div>
      <div className="badges">
        {props.badges.map((badge, index) => {
          return (
            <div key={index} className="badges_box">
              <div className="badges_box-img">
                <img src={badge.url} className="img" alt="Badge url" />
              </div>
              <ClipBoard
                url={badge.url}
                name={badge.name}
                companyName={props.vendorName}
                id={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Badges;
