import React from "react";

const OptionInfo = ({ title = "", desc = "" }) => {
  return (
    <div className="_optionsInfo">
      {title && <h4 className="_optionsInfo-title">{title}</h4>}
      {desc && <p className="_optionsInfo-desc">{desc}</p>}
    </div>
  );
};

export default OptionInfo;
