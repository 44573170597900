import styles from "./styles/successPopup.module.css";
import { MdDone, MdError } from "react-icons/md";
// import { useRouter } from "next/router";

function SuccessPopup({
  name,
  clientId,
  listId,
  message,
  successMessage = false,
}) {
  // const router = useRouter();

  return (
    <div className={styles.successPopup} id="success">
      <div className={styles.flex}>
        {!successMessage ? (
          message ? (
            <MdError size={23} style={{ fill: "#FF0000" }} />
          ) : (
            <MdDone size={23} style={{ fill: "#2ecc71" }} />
          )
        ) : (
          <MdDone size={23} style={{ fill: "#2ecc71" }} />
        )}
        {message ? (
          <p>{message}</p>
        ) : (
          <p>
            {name != ""
              ? `You have successfully added this card to an existing ${name} requirement.`
              : "You have successfully created a new requirement for this team."}
          </p>
        )}
      </div>
      {clientId && listId ? (
        <div
          className={styles.linkDiv}
          onClick={() => {
            // router.push({
            //   pathname: "/requirementTeam",
            //   query: { lId: listId, cId: clientId },
            // });
          }}
        >
          {/* <MdArrowForward size={15} style={{fill: '#2e86de'}}/> */}
          <p>
            <span>View the team here</span>
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default SuccessPopup;
