// import { Divider } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import UserLogs from "./userLogs";
import Metrics from "./metrics";

import MatchesTab from "./MatchesTab";
import RosterTab from "./RosterTab";
import DevProfileView from "./DevProfileView";
import ManageVendors from "./manageVendors";

export default function InfoTabs({
  helpTabData = [],
  posRecommendations,
  logsData = {},
  logAuthors = {},
  listData = {},
  maxValue,
  setMaxValue,
  endDate,
  showDevProfile = {},
  setShowDevProfile,
  selectedTuc = {},
  selectedPartner = {},
  selectedPartnerDevs = {},
  parentRef,
  allTucPositions,
  selectedTabIndex,
  setSelectedTabIndex,
  selectedUserRole = "",
  optionsHeight,
  tucsData,
  setShowUploadDevs,
  showUpdateDev,
  setShowUpdateDev,
  devDataToUpdate,
  setDevDataToUpdate,
  emailTemplates,
  setSelectedPartner,
  loggedInUserData,
}) {
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedPartnerName, setSelectedPartnerName] = useState("");
  const [resourceProps, setResourceProps] = useState({
    devData: {},
    rankings: {},
    skillsObject: {},
  });
  const ref = useRef(null);
  const scrollRef = createRef(null);
  const tabs = [
    selectedUserRole == "admin" ? "Access" : "Help",
    "Matches",
    "Metrics",
    selectedUserRole == "client" ? "Search" : "Roster",
    "Dev profile",
    "Logs",
  ];

  const tabsData = [
    () => {
      return selectedUserRole == "admin" ? (
        <ManageVendors
          tucItem={selectedTuc}
          isAdmin={selectedUserRole === "admin"}
          setSelectedPartner={setSelectedPartner}
          emailTemplates={emailTemplates}
          setSelectedTabIndex={setSelectedTabIndex}
          loggedInUserData={loggedInUserData}
        />
      ) : (
        helpTabData.map((item) => (
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <h3
              style={{
                marginBottom: "10px",
                fontWeight: "400",
                fontSize: "18px",
                color: "#4361E8",
              }}
            >
              {item.title}
            </h3>
            <p
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#A0A0A0",
              }}
            >
              {item.desc}
            </p>
            {item?.link && (
              <p
                onClick={() => {
                  window.open(
                    item?.link?.startsWith("http")
                      ? item.link
                      : "https://" + item.link,
                    "_blank"
                  );
                }}
              >
                {item.link}
              </p>
            )}
          </div>
        ))
      );
    },
    () => {
      return ["client", "admin"].includes(selectedUserRole) ? (
        <MatchesTab
          setSelectedTabIndex={setSelectedTabIndex}
          setShowDevProfile={setShowDevProfile}
          posRecommendations={posRecommendations}
          allTucPositions={allTucPositions}
        />
      ) : (
        <p>Only Clients and Admins can views this tab</p>
      );
    },
    () => {
      return (
        <div
          style={{
            marginTop: "25px",
            gap: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h4
              style={{
                marginBottom: "7.5px",
                opacity: ".75",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Client Activity
            </h4>
            <Metrics
              key={"LogActionsBarChart1"}
              logDataEntries={logsData}
              userTypes={["CLIENT"]}
              types={["VIEW", "ACTION", "CLICK"]}
              startDate={new Date(listData?.createdOn?.toDate() ?? new Date())}
              endDate={new Date(endDate ?? new Date())}
              maxValue={maxValue}
              setMaxValueFunction={setMaxValue}
            />
          </div>
          <div>
            <h4
              style={{
                marginBottom: "7.5px",
                opacity: ".75",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Spryte Activity
            </h4>
            <Metrics
              key={"LogActionsBarChart2"}
              logDataEntries={logsData}
              userTypes={["ADMIN"]}
              types={["VIEW", "ACTION", "CLICK", "EMAIL"]}
              startDate={new Date(listData?.createdOn?.toDate() ?? new Date())}
              endDate={new Date(endDate ?? new Date())}
              maxValue={maxValue}
              setMaxValueFunction={setMaxValue}
            />
          </div>
          <div>
            <h4
              style={{
                marginBottom: "7.5px",
                opacity: ".75",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Partner Activity
            </h4>
            <Metrics
              key={"LogActionsBarChart3"}
              logDataEntries={logsData}
              userTypes={["PARTNER"]}
              types={["VIEW", "ACTION", "CLICK"]}
              startDate={new Date(listData?.createdOn?.toDate() ?? new Date())}
              endDate={new Date(endDate ?? new Date())}
              maxValue={maxValue}
              setMaxValueFunction={setMaxValue}
            />
          </div>
        </div>
      );
    },
    () => {
      return ["partner", "admin"].includes(selectedUserRole) ? (
        <RosterTab
          selectedPartnerDevs={selectedPartnerDevs}
          allTucPositions={allTucPositions}
          tucsData={tucsData}
          selectedUserRole={selectedUserRole}
          setSelectedTabIndex={setSelectedTabIndex}
          setShowDevProfile={setShowDevProfile}
          selectedPartner={selectedPartner}
          listData={listData}
          setShowUploadDevs={setShowUploadDevs}
          showUpdateDev={showUpdateDev}
          setShowUpdateDev={setShowUpdateDev}
          devDataToUpdate={devDataToUpdate}
          setDevDataToUpdate={setDevDataToUpdate}
        />
      ) : (
        <p>Only Partners and Admins can views this tab</p>
      );
    },
    () => {
      return Object.keys(showDevProfile).length > 0 ? (
        <DevProfileView
          devInfo={resourceProps}
          selectedUserRole={selectedUserRole}
        />
      ) : (
        <></>
      );
    },
    () => {
      return (
        <UserLogs
          logsData={logsData.filter((item) => item.msg !== "")}
          logAuthors={logAuthors}
        />
      );
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      // Create a local developer object to enable editting.
      const localDeveloperData = showDevProfile?.devData;

      // Convert the stats into data.
      const localSkillsObject = {};

      // Create an array of relevant keys for the stats object.
      const skillKeys = ["stacks", "sectors", "categories"];

      // Iterate over the keys, and convert the references into ids.
      skillKeys?.forEach((skillKey) =>
        (localDeveloperData?.stats?.[skillKey]?.data ?? []).map(
          (statsObject) => {
            // Set the key-value combination in the localSkillsObject.
            // This shall subsequently be stored in props.
            localSkillsObject[statsObject?.ref?.id] = statsObject?.ref;
            // Set the ref key to be an ID.
            statsObject.ref = statsObject?.ref?.id;
          }
        )
      );

      // Pull the developer's rankings.
      const localRanking = await showDevProfile?.devRef
        ?.collection("rankings")
        ?.orderBy("computedOn", "desc")
        ?.limit(1)
        ?.get();

      // Create a local variable for ranking data.
      let rankingData = {};

      // If we found ranking data, process it.
      if (localRanking?.docs?.length === 1) {
        // Destructure the stats and computedOn.
        const { stats, computedOn, ...rankings } =
          localRanking?.docs?.[0]?.data();

        // Iterate over all data arrays.
        for (let key of ["stacks", "sectors", "categories"]) {
          rankings[key].forEach((skill) => {
            // Note that if a person deleted a skill, the localSkillsObject would not
            // have a corresponding entry from the stats!
            localSkillsObject[skill.ref.id] = skill.ref;

            // Convert DocumentReferences to strings to save memory.
            skill.ref = skill.ref.id;
          });
        }

        // Save the rankingData.
        rankingData = rankings;
      }

      // Retrieve data for each skill.
      await Promise.all(
        Object.entries(localSkillsObject).map(
          async ([skillID, skillDocRef]) => {
            // console.log(skillID, skillDocRef);
            if (!skillDocRef?.id || !skillID) return;
            // Pull the data.
            const skillDocumentSnapshot = await skillDocRef?.get();

            // Return if faulty data is found.
            if (!skillDocumentSnapshot?.exists) return;

            // Otherwise, destructure irrelevant data.
            const {
              catIndex,
              logo,
              nameIndex,
              popularQueries,
              rank,
              searchKeywords,
              show,
              ...skillData
            } = skillDocumentSnapshot.data();

            // Store relevant data locally, prior to setting state.
            localSkillsObject[skillID] = skillData;
          }
        )
      );

      // Set both developer data and the skills object.
      setResourceProps({
        devData:
          {
            ...localDeveloperData,
            hourlyRate:
              selectedUserRole == "partner"
                ? showDevProfile?.partnerHourlyRate
                : showDevProfile?.rates?.length > 0
                ? Math.min.apply(Math, showDevProfile?.rates)
                : 0,
          } ?? {},
        rankings: rankingData,
        skillsObject: localSkillsObject,
        clients: showDevProfile?.partnerData?.clients ?? [],
        partnerData: showDevProfile?.partnerData,
      });
      // setSelectedTabIndex(4);
    };
    if (Object.keys(showDevProfile).length > 0) {
      fetchData();
    }
  }, [showDevProfile, selectedUserRole]);

  useEffect(() => {
    if (scrollRef.current?.parentNode?.previousSibling?.clientHeight) {
      scrollRef.current.style.height = `${
        optionsHeight - scrollRef.current?.previousSibling?.clientHeight - 60
      }px`;
    }
  }, [optionsHeight]);

  useEffect(() => {
    if (
      selectedTabIndex == 3 &&
      selectedUserRole == "admin" &&
      selectedPartner
    ) {
      setSelectedPartnerName(
        (selectedPartner?.companyName
          ? selectedPartner?.companyName ?? ""
          : selectedPartner?.data()?.companyName ?? "") + " "
      );
    } else {
      setSelectedPartnerName("");
    }
  }, [selectedTabIndex, selectedUserRole, selectedPartner]);

  // useEffect(() => {
  //   if (selectedPartnerDevs) {
  //     setSelectedTabIndex(3);
  //   }
  // }, [selectedPartnerDevs]);

  // useEffect(() => {
  //   if (posRecommendations) {
  //     setSelectedTabIndex(1);
  //   }
  // }, [posRecommendations]);

  return (
    <div className=" _spReqTeam--OptionThird__details--right" ref={ref}>
      <div className=" _spReqTeam--OptionThird__details--right_header">
        {tabs.map((title, index) => (
          <p
            onClick={() => {
              if (selectedUserRole == "client" && index == 3) return;
              if (selectedUserRole == "partner" && index == 1) return;
              if (Object.keys(showDevProfile).length == 0 && index == 4) return;
              setSelectedTabIndex(index);
            }}
            style={{
              margin: "7.5px",
              width: "126px",
              height: "55px",
              background:
                (selectedUserRole == "client" && index == 3) ||
                (selectedUserRole == "partner" && index == 1) ||
                (Object.keys(showDevProfile).length == 0 && index == 4)
                  ? "lightgrey"
                  : selectedTabIndex == index
                  ? "#4361E9"
                  : "#FFFFFF",
              boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.08)",
              borderRadius: "5px",
              cursor: "pointer",

              fontWeight: "700",
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color:
                (selectedUserRole == "client" && index == 3) ||
                (selectedUserRole == "partner" && index == 1) ||
                (Object.keys(showDevProfile).length == 0 && index == 4)
                  ? "#000000"
                  : selectedTabIndex == index
                  ? "#FFFFFF"
                  : "#000000",
            }}
          >
            {title}
          </p>
        ))}
      </div>
      <div
        ref={scrollRef}
        style={{
          margin: "10px",
          padding: "20px",
          width: "425px",
          minHeight: "50vh",
          background: "#FFFFFF",
          boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.08)",
          borderRadius: "5px",
          overflowY: "auto",
          overflowX: "hidden",
          // minHeight: 40vh;
        }}
      >
        <h2>{selectedPartnerName + tabs[selectedTabIndex]}</h2>
        {/* <Divider
          sx={{
            width: "100%",
            height: "1px",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        /> */}
        {tabsData[selectedTabIndex]()}
      </div>
    </div>
  );
}
