import { useState, useEffect, useCallback } from "react";
import { getDocs } from "../globals/services";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const useStacks = (STACKFETCHLIMIT = 50, NEXTSTACKLIMIT = 20) => {
  const [state, setState] = useState({
    stacks: [],
    stacksCategories: [],
    lastVisible: null,
    loading: false,
    error: null,
  });

  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchStacks = useCallback(async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: null }));
    try {
      const [dataDocsResult, stacksCatsResult] = await Promise.all([
        getDocs("technologies", true, STACKFETCHLIMIT, "rank"),
        getDocs("techStacks150r"),
      ]);

      // console.log("dataDocsResult", dataDocsResult);
      // console.log("stacksCatsResult", stacksCatsResult);

      const newStacksCategories = stacksCatsResult?.docs?.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
        id: doc.id,
      }));

      let newData = [];
      let newLastVisible = null;

      if (dataDocsResult.docs.length > 0) {
        newLastVisible = dataDocsResult.docs[dataDocsResult.docs.length - 1];
        newData = dataDocsResult.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: newStacksCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));
      }

      setState((prevState) => ({
        ...prevState,
        stacks: newData,
        stacksCategories: newStacksCategories,
        lastVisible: newLastVisible,
        loading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [STACKFETCHLIMIT, refreshCounter]);

  const refreshData = () => {
    setRefreshCounter((c) => c + 1);
    console.log("Refreshing");
  };

  const onNext = useCallback(async () => {
    if (!state.lastVisible) return;

    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const dataDocs = await getDocs(
        "technologies",
        true,
        NEXTSTACKLIMIT,
        "rank",
        state.lastVisible
      );

      if (dataDocs.docs.length > 0) {
        const newData = dataDocs.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: state.stacksCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));

        setState((prevState) => ({
          ...prevState,
          stacks: [...prevState.stacks, ...newData].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          ),
          lastVisible: dataDocs.docs[dataDocs.docs.length - 1],
          loading: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          lastVisible: null,
          loading: false,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [state.lastVisible, NEXTSTACKLIMIT, state.stacksCategories]);

  useEffect(() => {
    fetchStacks();
  }, [fetchStacks]);

  return { ...state, onNext, refreshData };
};

const useDomains = (DOMAINFETCHLIMIT = 50, NEXTDOMAINLIMIT = 20) => {
  const [state, setState] = useState({
    domains: [],
    domainsCategories: [],
    lastVisible: null,
    loading: false,
    error: null,
  });
  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchStacks = useCallback(async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: null }));
    try {
      const [dataDocsResult, stacksCatsResult] = await Promise.all([
        getDocs("industry", true, DOMAINFETCHLIMIT, "name"),
        getDocs("sectors"),
      ]);
      // console.log("dataDocsResult", dataDocsResult);
      // console.log("stacksCatsResult", stacksCatsResult);

      const newStacksCategories = stacksCatsResult?.docs?.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
        id: doc.id,
      }));

      let newData = [];
      let newLastVisible = null;

      if (dataDocsResult.docs.length > 0) {
        newLastVisible = dataDocsResult.docs[dataDocsResult.docs.length - 1];
        newData = dataDocsResult.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: newStacksCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));
      }

      setState((prevState) => ({
        ...prevState,
        domains: newData,
        domainsCategories: newStacksCategories,
        lastVisible: newLastVisible,
        loading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [DOMAINFETCHLIMIT, refreshCounter]);

  const refreshData = () => {
    setRefreshCounter((c) => c + 1);
    console.log("Refreshing");
  };

  const onNext = useCallback(async () => {
    if (!state.lastVisible) return;

    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const dataDocs = await getDocs(
        "industry",
        true,
        NEXTDOMAINLIMIT,
        "name",
        state.lastVisible
      );

      if (dataDocs.docs.length > 0) {
        const newData = dataDocs.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: state.domainsCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));

        setState((prevState) => ({
          ...prevState,
          domains: [...prevState.domains, ...newData].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          ),
          lastVisible: dataDocs.docs[dataDocs.docs.length - 1],
          loading: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          lastVisible: null,
          loading: false,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [state.lastVisible, state.domainsCategories, NEXTDOMAINLIMIT]);

  useEffect(() => {
    fetchStacks();
  }, [fetchStacks]);

  return { ...state, onNext, refreshData };
};

const useCategories = (CATFETCHLIMIT = 50, NEXTCATLIMIT = 20) => {
  const [state, setState] = useState({
    categories: [],
    projectsCategories: [],
    lastVisible: null,
    loading: false,
    error: null,
  });

  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchStacks = useCallback(async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: null }));
    try {
      const [dataDocsResult, stacksCatsResult] = await Promise.all([
        getDocs("subcollections", true, CATFETCHLIMIT),
        getDocs("categories"),
      ]);

      const newStacksCategories = stacksCatsResult?.docs?.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
        id: doc.id,
      }));

      let newData = [];
      let newLastVisible = null;

      if (dataDocsResult.docs.length > 0) {
        newLastVisible = dataDocsResult.docs[dataDocsResult.docs.length - 1];
        newData = dataDocsResult.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: newStacksCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));
      }

      setState((prevState) => ({
        ...prevState,
        categories: newData,
        projectsCategories: newStacksCategories,
        lastVisible: newLastVisible,
        loading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [CATFETCHLIMIT, refreshCounter]);

  const refreshData = () => setRefreshCounter((c) => c + 1);

  const onNext = useCallback(async () => {
    // console.log("onNext");
    if (!state.lastVisible) return;
    // console.log("onNext");

    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const dataDocs = await getDocs(
        "subcollections",
        true,
        NEXTCATLIMIT,
        "name",
        state.lastVisible
      );

      if (dataDocs.docs.length > 0) {
        const newData = dataDocs.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
          id: doc.id,
          catId: doc?.ref?.parent?.parent?.id,
          catData: state.projectsCategories.find(
            (cat) => cat?.id === doc?.ref?.parent?.parent?.id
          ),
        }));
        // console.log("newData", newData);
        setState((prevState) => ({
          ...prevState,
          categories: [...prevState.categories, ...newData].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          ),
          lastVisible: dataDocs.docs[dataDocs.docs.length - 1],
          loading: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          lastVisible: null,
          loading: false,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
      }));
    }
  }, [state.lastVisible, state.projectsCategories, NEXTCATLIMIT]);

  useEffect(() => {
    fetchStacks();
  }, [fetchStacks]);

  return { ...state, onNext, refreshData };
};

const useTechAssessments = (techs, devID) => {
  const [stackAssessments, setStackAssessments] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState(null);
  const [setRefetch, setRefetchState] = useState({});
  // Memoize fetchVideoURL to avoid re-creation on every render
  const fetchVideoURL = useCallback(
    async (videoPath) => {
      const storage = getStorage();
      const videoRef = ref(
        storage,
        `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
      );
      try {
        return await getDownloadURL(videoRef);
      } catch (error) {
        console.error("Failed to fetch video URL", error);
        return null;
      }
    },
    [devID]
  );

  // Wrapped in useCallback to avoid re-creation unless dependencies change
  const fetchAssessments = useCallback(
    async (stack) => {
      if (!stack?.assessments) return;
      setLoading((prev) => ({ ...prev, [stack.id]: true }));
      try {
        let assessments = await Promise.all(
          stack?.assessments.map(async ({ source, units }) => {
            const sourceDoc = await source.get();
            const sourceData = {
              ...sourceDoc.data(),
              id: sourceDoc.id,
              ref: sourceDoc.ref,
            };

            const unitsData = await Promise.all(
              units.map(async (unit) => {
                const unitSnapshot = await unit.get();
                const { questions, ...unitData } = unitSnapshot.data();

                if (!questions) {
                  setRefetchState((prev) => ({ ...prev, [stack.id]: true }));
                  return {
                    id: unitSnapshot.id,
                    ...unitData,
                    questionsData: [],
                  };
                }
                const questionsData = await Promise.all(
                  questions.map(async (question) => {
                    if (!question) return null;
                    const questionSnapshot = await question.get();
                    const questionData = questionSnapshot.data();
                    const videoURL = questionData.videoPath
                      ? await fetchVideoURL(questionData.videoPath)
                      : null;
                    const submissionsSnapshot = await questionSnapshot.ref
                      .collection("submissions")
                      .get();
                    const submissionsData = submissionsSnapshot.docs.map(
                      (submission) => ({
                        id: submission.id,
                        ...submission.data(),
                        ref: submission.ref,
                      })
                    );
                    return {
                      id: questionSnapshot.id,
                      ...questionData,
                      videoURL,
                      submissionsData,
                    };
                  })
                );

                return { id: unitSnapshot.id, ...unitData, questionsData };
              })
            );

            return { sourceData, unitsData };
          })
        );
        setLoading((prev) => ({ ...prev, [stack.id]: false }));

        return assessments;
      } catch (error) {
        setLoading((prev) => ({ ...prev, [stack.id]: false }));
        console.error("Failed to fetch assessments", error);
        return null;
      } finally {
        // setLoading(false);
      }
    },
    [fetchVideoURL]
  );

  // refecths when a refetchstate is true on one stack
  useEffect(() => {
    const assessmentsByStack = { ...stackAssessments };
    Object.entries(setRefetch).forEach(([stackId, shouldRefetch]) => {
      if (shouldRefetch) {
        const stack = techs.find((t) => t.id === stackId);
        if (stack) {
          fetchAssessments(stack).then((assessments) => {
            setRefetchState((prev) => ({ ...prev, [stackId]: false }));
            if (assessments?.length > 0) {
              assessmentsByStack[stackId] = assessments;
              setStackAssessments(assessmentsByStack);
            }
          });
        }
      }
    });
  }, [setRefetch, techs, fetchAssessments]);

  useEffect(() => {
    const loadAssessments = async () => {
      const assessmentsByStack = {};

      try {
        for (const stack of techs) {
          if (!stack.assessments?.length) continue;

          const assessments = await fetchAssessments(stack);
          if (assessments?.length > 0) {
            assessmentsByStack[stack?.id] = assessments;
          }
        }

        setStackAssessments(assessmentsByStack);
      } catch (e) {
        setError(e);
      }
    };

    loadAssessments();
  }, [techs, devID, fetchAssessments]);

  return { stackAssessments, loading, error };
};

export { useStacks, useDomains, useCategories, useTechAssessments };
