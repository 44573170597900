const ResponsiveImage = ({ src, alt, className, objectFit, width, height }) => {
  const styles = {
    width: "100%",
    height: "100%",
    objectFit: objectFit || "cover",
    verticalAlign: "unset",
  };

  const containerStyles = {
    width: width ? `${width}` : "75px",
    height: height ? `${height}` : "75px",
    overflow: "hidden",
  };

  return (
    <div className={className} style={containerStyles}>
      <img src={src} alt={alt} style={styles} className={`${className}_img`} />
    </div>
  );
};

export default ResponsiveImage;
