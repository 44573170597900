import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import { SampleBase } from "../../sample-base";
import ConfirmDevInfo from "../components/ConfirmDevInfo";
import ConfirmDevSkills from "../components/ConfirmDevSkills";

export default class ConfirmScrapedNames extends SampleBase {
  render() {
    return (
      <div id="confirmScraped">
        <div>
          <h1>{this.props.currentResume}</h1>
          {/* Do not show the edit dev info if this is not the resource page */}
          {this.props.multiple ? (
            <ConfirmDevInfo
              firebase={this.props.firebase}
              resumeName={this.props.currentResume}
              devInfo={
                this.props.devsToBeAdded[this.props.currentResume].devInfo
              }
              editDevInfo={this.props.editDevInfo}
              allPositions={this.props?.allPositions}
            />
          ) : (
            <div></div>
          )}
        </div>
        <ConfirmDevSkills
          resumeName={this.props.currentResume}
          skillsToBeAdded={
            this.props.devsToBeAdded[this.props.currentResume].skillsToBeAdded
          }
          editSkill={this.props.editSkill}
          confirmSkill={this.props.confirmSkill}
          setExperienceUnit={this.props.setExperienceUnit}
        />

        {Object.keys(this.props.devsToBeAdded).length > 1 ? (
          <div id="confirmScraped_btns">
            <div
              onClick={() => this.props.previousResume()}
              className="spbtn spbtn-sub"
            >
              <ArrowBackIos className="icon_prev" />
              <span>REVIEW PREVIOUS RESUME</span>
            </div>

            <div
              onClick={() => this.props.nextResume()}
              className="spbtn spbtn-sub"
            >
              <span>REVIEW NEXT RESUME</span>
              <ArrowForwardIos />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
