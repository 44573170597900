import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import { FirebaseContext } from "./Firebase";

import logo from "./images/Logo.png";

const PasswordReset = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEmailClick = async (e) => {
    setLoading(true);
    setError("");

    await props.firebase
      .doPasswordReset(email) //get user email from the input and send code to the user email
      .then((e) => {
        setValidEmail(true);
      })
      .catch((err) => setError(err.message));
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <br></br>
      <br></br>
      <br></br>
      {loading && <LinearProgress />}

      {/* Toggle between user input and email success message if email is valid or not */}
      {!validEmail ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CssBaseline />
          <div
            // className={makeStyles.paper}
            style={{ textAlign: "center", width: "50%" }}
          >
            <img src={logo} height="40" width="40" alt="logo" />
            <br></br>
            <Typography component="h1" variant="h5">
              Password Reset
            </Typography>
            <br></br>
            <form
              // onSubmit={this.onSubmit}
              // className={makeStyles.form}
              noValidate
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                // value={this.state.email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br></br>
              <br></br>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                // className={makeStyles.submit}
                onClick={(e) => {
                  onEmailClick(e);
                  e.preventDefault();
                }}
              >
                Reset My Password
              </Button>
            </form>
          </div>
          <Box mt={8}></Box>
        </div>
      ) : (
        <div>
          <br></br>
          <br></br>
          <br></br>
          <Alert variant="filled" severity="success">
            Password Reset Email Sent Successfully!
          </Alert>
        </div>
      )}

      {/* Error message */}
      {error && (
        <>
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </>
      )}
    </Container>
  );
};

export default function FirebaseResources() {
  const firebase = useContext(FirebaseContext);
  return <PasswordReset firebase={firebase} />;
}
