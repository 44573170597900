import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import React from "react";

const MatchStats = ({ title = "Technical", y = 1, n = 1, mark = 1 }) => {
  return (
    <div
      className="sp_row matchStats"
      style={{
        gap: "1rem",
      }}
    >
      <div
        className="sp_row matchStats__iconTitle"
        style={{
          gap: ".5rem",
          alignItems: "center",
        }}
      >
        <CheckCircleRoundedIcon />
        <p className="sp_bold">{title}</p>
      </div>
      <div
        className="sp_row matchStats__stats"
        style={{
          gap: "10px",
        }}
      >
        <div className="sp_row matchStats__stats--y">
          <span>Y</span>
          <p className="matchStats__y sp_bold">{y}</p>
        </div>
        <div className="sp_row matchStats__stats--y">
          <span>N</span>
          <p className="sp_bold sp_green matchStats__n">{n}</p>
        </div>
        <div className="sp_row matchStats__stats--y">
          <span>?</span>
          <p className="sp_bold">{mark}</p>
        </div>
      </div>
    </div>
  );
};

export default MatchStats;
