import React from "react";
import styled from "styled-components";

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bg};
  border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  p {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
`;

const AddRosterMessage = ({ bg = "#F2DDA5", text, cls = "" }) => {
  return (
    <BoxWrapper className={`${cls} _spNotOpenPos`} bg={bg}>
      <p>
        {text ??
          "This position is open to you, add  resources using the Roster Tab."}
      </p>
    </BoxWrapper>
  );
};

export default AddRosterMessage;
