import styled from "styled-components";

export const DialogHead = styled.div``;
export const Title = styled.h3`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Desc = styled.p`
  font-size: 16px;
  color: #656667;
  line-height: 26px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;
