import styled from "styled-components";

export const DevProfileTop = styled.div`
  justify-content: space-between;
  align-items: ${({ center }) => center && "center"};
  background-color: ${({ bg }) => bg && bg};
  padding: ${({ padding }) => padding && padding};
  border-radius: ${({ bradius }) => bradius && bradius};
`;

export const DevProfileInfo = styled.div`
  gap: 0.5rem;
`;
export const DevImageStatus = styled.div`
  position: relative;
`;
export const DevStatus = styled.div`
  position: absolute;
  z-index: 2;
  background-color: ${({ statuscolor }) =>
    statuscolor ? statuscolor : "#ABABAB"};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: ${({ statusright }) => (statusright ? statusright : "-5px")};
  top: ${({ statustop }) => (statustop ? statustop : "-5px")};
  border: 2px solid white;
`;
export const DevDetails = styled.div`
  justify-content: space-between;
`;
export const DevName = styled.h4`
  cursor: pointer;
`;
export const DevLocation = styled.div`
  gap: 0.1rem;
`;
export const DevLoc = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
export const DevBadges = styled.div`
  gap: 5px;
`;

export const DevRate = styled.div`
  display: flex;
  gap: 0.2rem;
`;
