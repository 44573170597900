import React from "react";
import styled from "styled-components";

const CriteriaBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const SingleCriteria = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  span {
    font-size: 1.4rem;
    font-weight: 600;
  }
`;
const MatchCriteria = ({ points }) => {
  return (
    <CriteriaBox className="matchCriteria">
      {points?.map((point, i) => {
        return (
          <SingleCriteria
            className="matchCriteria--single"
            key={point[2] + "-" + i}
          >
            {point[0] && (
              <input
                style={{
                  height: "15px",
                  width: "15px",
                }}
                type="checkbox"
                checked={point[1]}
                readOnly
                className="matchCriteria--single-input"
              />
            )}
            <span className="matchCriteria--single-text">{point[2]}</span>
          </SingleCriteria>
        );
      })}
    </CriteriaBox>
  );
};

export default MatchCriteria;
