import styled from "styled-components";

export const TechsTableWrapper = styled.div`
  margin-top: 1rem;
`;
export const TechTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(4, 50px);
  gap: 1rem;
`;
export const TechTableHeader = styled(TechTableRow)`
  margin-bottom: 1rem;
  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    color: #656667;
  }
`;

export const TechTableBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const TechTableBodyRow = styled(TechTableRow)`
  p:not(:first-child) {
    font-weight: 700;
  }
`;
