import React from "react";
import CollapseIcon from "../../svgs/location/CollapseIcon";
import { CollapseIconWrapper, Title, TitleWrapper } from "./style";
// import CollapseIcon from "../../sp_svgs/location/CollapseIcon";

function SpTitles({
  title,
  isColapsable = false,
  children,
  bgColor,
  titleSize,
  titleColor,
  borderRadius,
  marginBottom,
  iconSize = 30,
  width,
  padding = "0",
  onCollapse,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    onCollapse && onCollapse(!isCollapsed);
  };

  return (
    <TitleWrapper
      bgColor={bgColor}
      borderRadius={borderRadius}
      marginBottom={marginBottom}
      width={width}
      padding={padding}
    >
      {children ? (
        <>{children}</>
      ) : (
        <Title titleSize={titleSize} titleColor={titleColor}>
          {title}
        </Title>
      )}

      {isColapsable && (
        <CollapseIconWrapper
          onClick={toggleCollapse}
          className="sp_hover"
          iconSize={iconSize}
          animate={{ rotate: isCollapsed ? 180 : 0 }}
        >
          <CollapseIcon />
        </CollapseIconWrapper>
      )}
    </TitleWrapper>
  );
}

export default SpTitles;
