import styled from "styled-components";
import { spGreyColor, spLightGreyColor } from "../../stylesConstant";

export const DevProfileTop = styled.div`
  justify-content: space-between;
  align-items: ${({ center }) => center && "center"};
  background-color: ${({ bg }) => bg && bg};
  padding: ${({ padding }) => padding && padding};
  border-radius: ${({ bradius }) => bradius && bradius};
`;

export const DevProfileInfo = styled.div`
  gap: 0.5rem;
  flex: 1;
`;
export const DevImageStatus = styled.div`
  position: relative;
`;
export const DevStatus = styled.div`
  position: absolute;
  z-index: 2;
  background-color: ${({ statuscolor }) =>
    statuscolor ? statuscolor : "#ABABAB"};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: ${({ statusright }) => (statusright ? statusright : "-5px")};
  top: ${({ statustop }) => (statustop ? statustop : "-5px")};
  border: 2px solid white;
`;
export const DevDetails = styled.div`
  justify-content: space-between;
`;
export const DevName = styled.h4`
  cursor: pointer;
`;
export const DevLocation = styled.div`
  gap: 0.2rem;
  align-items: center;
`;
export const DevLoc = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
export const DevBadges = styled.div`
  gap: 5px;
`;

export const DevRate = styled.div`
  display: flex;
  gap: 0.2rem;
`;
export const DevRightElement = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  background-color: ${spLightGreyColor};
  padding: 10px;
  max-height: 70px;
  align-items: center;
  justify-content: center;

  ._spMatchText {
    font-size: 10px;
    font-weight: 400;
    color: ${spGreyColor};

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: ${spGreyColor};
      display: inline-block;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  ._spMatchScore {
    font-size: 20px;
    font-weight: 600;
    color: ${spGreyColor};
  }
`;
