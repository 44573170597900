// components/CircularProgressBar.js
import styled from "styled-components";

const CircularContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size || 98}px;
  height: ${({ size }) => size || 98}px;
`;

const CircularBackground = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  circle {
    fill: none;
    stroke: ${({ bgColor }) => bgColor || "#3FDD98"};
    stroke-width: 6;
    stroke-opacity: 0.25;
  }
`;

const CircularForeground = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  circle {
    fill: none;
    stroke: ${({ progressColor }) => progressColor || "#3FDD98"};
    stroke-width: 6;
    stroke-dasharray: 283;
    stroke-dashoffset: ${({ progress }) => 283 - (283 * progress) / 100};
    transition: stroke-dashoffset 1s ease-out;
    transform: rotate(-90deg);
    transform-origin: center;
  }
`;

const ProgressText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ size }) => (size ? size / 4 : 24)}px;
  color: ${({ textColor }) => textColor || "#3FDD98"};
  font-weight: bold;
`;

const CircularProgressBar = ({
  progress,
  size = 98,
  progressColor,
  bgColor,
  textColor,
}) => {
  return (
    <CircularContainer size={size}>
      <CircularBackground
        width={size}
        height={size}
        viewBox="0 0 98 98"
        bgColor={bgColor}
      >
        <circle cx="49" cy="49" r="45" />
      </CircularBackground>
      <CircularForeground
        width={size}
        height={size}
        viewBox="0 0 98 98"
        progress={progress}
        progressColor={progressColor}
      >
        <circle cx="49" cy="49" r="45" />
      </CircularForeground>
      <ProgressText size={size} textColor={textColor}>
        {progress}%
      </ProgressText>
    </CircularContainer>
  );
};

export default CircularProgressBar;
