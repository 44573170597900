import React, { useEffect, useState } from "react";
import Select from "react-select";

import AddComponent from "./AddData/addComponent";
const AddTemplate = (props) => {
  //
  //
  ////////////// States////////////////////////////
  //
  // Stores the main photo Data
  const [mainPhoto, setMainPhoto] = useState([]);

  // Stores a list of maps containing the files and the images links
  const [slides, setSlides] = useState([]);

  // Stores list of strings containing stack titles
  const [stacks, setStacks] = useState([]);

  // Stores list of strings containing domain names
  const [domain, setDomains] = useState([]);

  // Stores list of strings containing project types
  const [projTypes, setProjTypes] = useState([]);

  // Holds the avatar url
  const [avaPhoto, setAvaPhoto] = useState([]);

  // Holds the clientLogo url
  const [clientLogo, setClientLogo] = useState([]);

  // client name
  const [name, setName] = useState("");

  // client company Name
  const [companyName, setCompanyName] = useState("");

  // Client Position
  const [position, setPosition] = useState("");

  // Client Domain
  const [website, setWebsite] = useState("");

  // Client Quote
  const [quote, setQuote] = useState("");

  // Case Title
  const [title, setTitle] = useState("");

  // Case Description
  const [description, setDescription] = useState("");

  // Challenge
  const [challenge, setChallenge] = useState("");

  // Solitions
  const [solution, setSolution] = useState("");

  // Case id
  const [caseId, setCaseId] = useState("");

  // Live Link
  const [link, setLink] = useState("");

  // Holds a list of external case studies Ids
  const [extId, setExId] = useState([]);

  // Status default is Draft
  const [status, setStatus] = useState("DRAFT");

  // Check for uploads
  const [isUploading, setIsUploading] = useState(false);

  // Spryte showcase toggle to show case study on spryte home page under featured case studies
  const [spryteShowcase, setSpryteShowcase] = useState(false);

  // Firebase storage
  const storage = props.firebase.getStorage();

  // Status list for none Super Admin
  const statusList = [
    {
      value: "DRAFT",
      label: "DRAFT",
    },
    {
      value: "READY-TO-SUBMIT",
      label: "READY-TO-SUBMIT",
    },
  ];

  // Status list for super admins
  const superList = [
    {
      value: "DRAFT",
      label: "DRAFT",
    },
    {
      value: "READY-TO-SUBMIT",
      label: "READY-TO-SUBMIT",
    },
    {
      value: "REVIEWED",
      label: "REVIEWED",
    },
    {
      value: "FEATURED",
      label: "FEATURED",
    },
  ];

  // //////////////////////Set Data for multiselectors//////////////

  /**
   *
   * @param {Object} e
   * @returns List of data values
   */
  const setMultiSelect = (e) => {
    if (e) {
      let datas = [];

      if (e.length > 0) {
        e.map((data) => {
          if (data?.value) {
            return datas.push(data.value);
          }
          return null;
        });
      }
      return datas;
    }
  };

  //
  // //////////////////////////Set Initial data on edit
  //
  //
  useEffect(() => {
    if (props.isEdit && props.selectedData) {
      setSlides(props.selectedData.mediaSlider);
      let main = props?.selectedData?.mainPhoto?.url
        ? [props?.selectedData?.mainPhoto]
        : [];

      let avatar = props?.selectedData?.avatarPhoto?.url
        ? [props?.selectedData?.avatarPhoto]
        : [];

      let clientLogo = props?.selectedData?.clientLogo?.url
        ? [props?.selectedData?.clientLogo]
        : [];
      setMainPhoto(main);
      setAvaPhoto(avatar);
      setClientLogo(clientLogo);
      setStacks(props.selectedData.stacks);
      setDomains(props.selectedData.domains);
      setProjTypes(props.selectedData.projects);
      setExId(setMultiSelect(props.selectedData.externalRef));
      setTitle(props.selectedData.title);
      setChallenge(props.selectedData.challenge);
      setName(props.selectedData.clientName);
      setCompanyName(props.selectedData.clientCompany ?? "");
      setWebsite(props.selectedData.clientDomain);
      setPosition(props.selectedData.clientPosition);
      setQuote(props.selectedData.clientQuote);
      setDescription(props.selectedData.description);
      setCaseId(props.selectedData.id);
      setLink(props.selectedData.liveLink);
      setSolution(props.selectedData.solution);
      setStatus(props.selectedStatus.value);
      setSpryteShowcase(props?.selectedData?.spryteShowcase ?? false);
    } else {
      setSlides([]);
      setMainPhoto([]);
      setAvaPhoto([]);
      setClientLogo([]);
      setStacks([]);
      setDomains([]);
      setProjTypes([]);
      setExId(setMultiSelect([]));
      setTitle("");
      setChallenge("");
      setName("");
      setCompanyName("");
      setWebsite("");
      setPosition("");
      setQuote("");
      setDescription("");
      setCaseId("");
      setLink("");
      setSolution("");
      setStatus("DRAFT");
    }
  }, [props.isEdit, props.isAdd]);

  /**
   *  handClicks will open upload file button depending on the Id
   * @param {Event} e
   * @param {String} id id of the button that triggers the events
   */
  const handleClicks = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
    e.preventDefault();
  };

  /**
   * SelectFIles will get all selected images and set the slides into
   * a list of objects holding files and urls
   * @param {Object} e
   */
  const selectFiles = (e) => {
    let theslides = [...slides];
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      theslides.push({ file: files[i], url: URL.createObjectURL(files[i]) });
    }
    setSlides(theslides);
    e.persist();
    e.preventDefault();
  };

  /**
   *  gets the selected file from the upload button
   * @param {Event} e
   */
  const selectFile = (e, name) => {
    const file = e.target.files[0];
    if (file?.type?.includes("image")) {
      if (name == "avatar") {
        setAvaPhoto([{ file: file, url: URL.createObjectURL(file) }]);
      } else if (name === "clientLogo") {
        setClientLogo([{ file: file, url: URL.createObjectURL(file) }]);
      } else {
        setMainPhoto([{ file: file, url: URL.createObjectURL(file) }]);
      }
    }
    e.persist();
    e.preventDefault();
  };

  /**
   * Deletes an image when we click on the x icon
   * @param {number} i index of the data to be deleted
   * @param {String} name name of the section where to data is in
   */
  const deleteFile = (i, name) => {
    if (name === "main") {
      const s = mainPhoto.filter((item, index) => index !== i);
      setMainPhoto(s);
    } else if (name === "slide") {
      const s = slides.filter((item, index) => index !== i);
      setSlides(s);
    } else if (name === "clientLogo") {
      const s = clientLogo.filter((item, index) => index !== i);
      setClientLogo(s);
    } else {
      const s = avaPhoto.filter((item, index) => index !== i);
      setAvaPhoto(s);
    }
  };

  /**
   * Take the list of data to be uploaded, the ini and Id is used to make sure that the file won't be saved with the same name
   * because the same name file will have different ref so others ref won't work
   * @param {Array} datas case data
   * @param {string} ini field name
   * @param {string} id case Id
   * @returns file ref and name
   */
  const uploadData = async (datas, ini, id) => {
    let slidesUrl = datas.map((slideMap) => {
      const slide = slideMap.file;
      if (slide) {
        return storage
          .ref(
            `vendors/${props.vendorId}/caseStudiesImages/${id}_${ini}_${slide.name}`
          )
          .put(slide)
          .then(async (snap) => {
            let data = {
              url: await snap.ref.getDownloadURL(),
              name: `${id}_${ini}_${slide.name}`,
            };
            return data;
          });
      } else {
        return slideMap;
      }
    });

    return Promise.all(slidesUrl);
  };

  /**
   *
   * @param {Array<object>} dataObj array of objects containing the data we want to convert
   * @param {Array<String>} dataList array of string containing a list of data name
   * @returns a list of references
   */
  const convertToRefs = (dataObj, dataList) => {
    if (dataList.length > 0) {
      const refs = dataList.map((data) => {
        return props.firebase.db.doc(dataObj[data]);
      });
      return refs;
    }
  };

  // console.log("vendorId", props.vendorId);

  // /**
  //  * creates a random string between letters an numbers
  //  * @returns the case Id
  //  */
  // const randId = () => {
  //   let length = Math.floor(Math.random() * 4) + 1;
  //   let result = "";
  //   let characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let charactersLength = characters.length;
  //   for (let i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   if (caseId === "") {
  //     return result;
  //   } else {
  //     return caseId;
  //   }
  // };
  /**
   * Gets all data from our inputs and checks whether it's an edit or a creation, if it's a creation in will'
   * create new case study, else it will update existing one
   */
  const onSubmit = async () => {
    setIsUploading(true);
    let extRefs = [];
    if (extId.length > 0) {
      extId.map((id) => {
        if (id) {
          let ref = props.firebase.db.doc(
            `/vendors/${props.vendorId}/caseStudies/${id}`
          );
          return extRefs.push(ref);
        }
      });
    }
    // console.log("stacks", stacks);
    const stacksRefs = stacks?.map((stack) => stack?.ref) ?? [];
    const domainRefs = domain?.map((dom) => dom?.ref) ?? [];
    const projRefs = projTypes?.map((proj) => proj?.ref) ?? [];
    let id = caseId !== "" ? caseId : Math.random().toString(36).slice(2);
    // console.log(id);
    let slidesData = await uploadData(slides, "s", id);
    let main_photo = await uploadData(mainPhoto, "m", id);
    let avatarPhoto = await uploadData(avaPhoto, "a", id);
    let clientLog = await uploadData(clientLogo, "c", id);
    let safeWord = title.replace(/[&\/\\#,+@!=^()$~%.'":*?<>{}]/g, "");

    let data = {
      avatarPhoto: avatarPhoto[0] ?? "",
      clientName: name,
      clientCompany: companyName,
      clientLogo: clientLog[0] ?? "",
      clientPosition: position,
      clientDomain: website,
      clientQuote: quote,
      title: safeWord,
      stacks: stacksRefs,
      challenge: challenge,
      solution: solution,
      description: description,
      domains: domainRefs,
      projects: projRefs,
      externalRef: extRefs,
      mediaSlider: slidesData,
      mainPhoto: main_photo[0] ?? "",
      internal: true,
      status: status,
      liveLink: link,
      id: id,
      spryteShowcase: spryteShowcase,
    };
    if (props.isAdd) {
      await props.firebase.createInternalCaseStudy(props.vendorId, data);
      // props.firebase.reportPageVisits("Case Study Added", props.isSuper);
      props.setIsAdd(false);
    } else {
      await props.firebase.updateCase(
        props.vendorId,
        props.selectedData.Id,
        data
      );
      // props.firebase.reportPageVisits("Case Study Edited", props.isSuper);
      props.setIsEdit(false);
    }

    setIsUploading(false);
    props.setShowMain(true);
  };

  /**
   * when it's a superUser he can se the status field every time
   * only superUser can select Reviewed and featured.
   * when it's a regular user and he select ready to submit the status field will disapear so user can edit it anymore
   * @returns status input field
   */
  const showStatus = () => {
    if (props.isSuper) {
      if (props.isEdit) {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
            <Select
              menuPlacement="top"
              options={superList}
              className="basic-multi-select"
              classNamePrefix="select Stack"
              name="colors"
              defaultValue={props.selectedStatus}
              onChange={(e) => setStatus(e.value)}
            />
          </div>
        );
      } else if (props.isAdd) {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
            <Select
              menuPlacement="top"
              options={superList}
              className="basic-multi-select"
              classNamePrefix="select Stack"
              name="colors"
              defaultValue={superList[0]}
              onChange={(e) => setStatus(e.value)}
            />
          </div>
        );
      } else {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
          </div>
        );
      }
    } else {
      if (props.isEdit && props.showStatus) {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
            <Select
              options={statusList}
              menuPlacement="top"
              className="basic-multi-select"
              classNamePrefix="select Stack"
              name="colors"
              defaultValue={props.selectedStatus}
              onChange={(e) => setStatus(e.value)}
            />
          </div>
        );
      }

      if (props.isAdd) {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
            <Select
              options={statusList}
              menuPlacement="top"
              className="basic-multi-select"
              classNamePrefix="select Stack"
              name="colors"
              defaultValue={statusList[0]}
              onChange={(e) => setStatus(e.value)}
            />
          </div>
        );
      }
      if (!props.isAdd && !props.isEdit) {
        return (
          <div className="column info_input stat_item">
            <label>Status</label>
          </div>
        );
      }
    }
  };

  return (
    <div className="brand_dialog">
      <AddComponent
        isEdit={props.isEdit}
        showStatus={showStatus}
        isAdd={props.isAdd}
        clientLogo={clientLogo}
        companyName={companyName}
        setCompanyName={setCompanyName}
        title={title}
        setTitle={setTitle}
        name={name}
        setName={setName}
        position={position}
        setPosition={setPosition}
        website={website}
        setWebsite={setWebsite}
        quote={quote}
        setQuote={setQuote}
        stackOption={props.stackOption}
        selectedData={props.selectedData}
        setStacks={setStacks}
        domainsOption={props.domainsOption}
        setDomains={setDomains}
        setMultiSelect={setMultiSelect}
        projOption={props.projOption}
        description={description}
        setDescription={setDescription}
        setProjTypes={setProjTypes}
        challenge={challenge}
        setChallenge={setChallenge}
        solution={solution}
        setSolution={setSolution}
        selectFiles={selectFiles}
        selectFile={selectFile}
        handleClicks={handleClicks}
        slides={slides}
        deleteFile={deleteFile}
        external={props.external}
        mainPhoto={mainPhoto}
        setExId={setExId}
        link={link}
        setLink={setLink}
        spryteShowcase={spryteShowcase}
        setSpryteShowcase={setSpryteShowcase}
        onSubmit={onSubmit}
        setIsAdd={props.setIsAdd}
        setIsEdit={props.setIsEdit}
        setShowMain={props.setShowMain}
        isUploading={isUploading}
        avatarPhoto={avaPhoto}
        setSelectedData={props.setSelectedData}
        isSuper={props.isSuper}
      />
    </div>
  );
};
export default AddTemplate;
