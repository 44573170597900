import React from "react";
import { ReqTableHead } from "../../../../globals/ReqTable/style";
import { MATCHTABLECOLUMNS } from "./constants";
import { HeadItem } from "../../../Requirements/RequirementHead";

const MatchTableHead = ({ requestSort }) => {
  return (
    <ReqTableHead gridColumn={MATCHTABLECOLUMNS}>
      <HeadItem
        label="Matched On"
        onClick={() => requestSort("matchedOn", true)}
      />
      <HeadItem label="Type" onClick={() => requestSort("matchType")} />
      <HeadItem label="Client" />
      <HeadItem label="Matching Criteria" />
      <HeadItem label="Match Score" onClick={() => requestSort("mScore")} />
    </ReqTableHead>
  );
};

export default MatchTableHead;
