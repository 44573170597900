import React, { useEffect, useRef } from "react";
import SingleContent from "../../../../globals/SpAssessement/SpAssessementBody/SingleContent";
import SpScrollableTab from "../../../../globals/SpScrollableTab";
import SingleTabHead from "../../../../globals/SpAssessement/SpAssessementHead/SingleHeadTab";
import prof from "../../../../images/profile2.png";
import { CircularProgress } from "@mui/material";

const DevAssessement = ({
  data,
  userRef,
  showScore = true,
  showRate = false,
  showDifficulty = true,
}) => {
  const [selectedHeadIndex, setSelectedHeadIndex] = React.useState(0);
  //   console.log(data?.[0]?.unitsData);
  const tabRef = useRef();

  const allUnitsData = data?.reduce((acc, item) => {
    // Sort questions by index
    item?.unitsData?.map((i) => {
      i.questionsData.sort((a, b) => a.index - b.index);
    });
    return acc.concat(item?.unitsData);
  }, []);

  const formatScore = (score) => {
    if (score % 1 === 0) {
      return score.toString(); // No decimal part
    } else {
      return score.toFixed(1); // One decimal place
    }
  };
  //   const handleChangeTab = () => {
  //     // Change selected tab index to 3
  //   };

  // console.log("allUnitsData?.[selectedHeadIndex]?.questionsData", allUnitsData);

  return (
    <div className="account-leadData_switches__assess">
      <SpScrollableTab
        data={allUnitsData}
        onItemChange={(args) => {
          if (args.index === selectedHeadIndex) return;
          setSelectedHeadIndex(args.index);
          tabRef?.current?.setSelectedIndex(0);
        }}
        renderItem={({ item, index, selectedIndex, setSelectedIndex }) => (
          <SingleTabHead
            key={item?.id}
            item={item}
            isActive={selectedIndex === index ? "1" : "0"}
            topicCount={index + 1}
            topicTotal={allUnitsData?.length}
            onClick={() => {
              setSelectedIndex(index);
              setSelectedHeadIndex(index);
              if (tabRef?.current?.setSelectedIndex)
                tabRef?.current?.setSelectedIndex(0);
            }}
            title={item?.name}
            showScore={false}
            showRate={true}
            score={item?.score || "?"}
            // rate={item}
          />
        )}
      />
      {allUnitsData?.[selectedHeadIndex]?.questionsData?.filter(
        (e) => e?.isQuestion
      )?.length > 0 ? (
        <SpScrollableTab
          style={{
            backgroundColor: "#f5f5f5",
            padding: "20px 0",
            border: "1px solid #dfdfdf",
            borderTop: "none",
          }}
          tabListStyle={{
            flex: 1,
            display: "flex",
            border: "none",
            gap: "20px",
          }}
          ref={tabRef}
          data={allUnitsData?.[selectedHeadIndex]?.questionsData
            ?.filter((e) => e?.isQuestion)
            ?.sort((a, b) => {
              if (a?.questionData?.difficulty < b?.questionData?.difficulty)
                return -1;
              if (a?.questionData?.difficulty > b?.questionData?.difficulty)
                return 1;
              return 0;
            })}
          renderItem={({ item, index, selectedIndex, setSelectedIndex }) => (
            <SingleContent
              key={item?.id}
              item={item}
              imgUrl={prof}
              question={item?.questionData?.question}
              answer={
                item?.submissionsData?.filter(
                  (el) => el?.author?.id === userRef?.id
                )?.[0]?.msg
              }
              isActive={selectedIndex === index ? "1" : "0"}
              autoPlay={false}
              // topicCount={item}
              // topicTotal={6}
              onClick={() => setSelectedIndex(index)}
              // rate={item}
              difficulty={item?.questionData?.difficulty}
              score={item?.score > 0 ? formatScore(item?.score * 5) : "?"}
              showScore={showScore}
              showDifficulty={showDifficulty}
            />
          )}
        />
      ) : (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default DevAssessement;
