import { PartnerWrapper, PartnerDiv } from "./style";
import SpResponsiveImg from "../../SpResponsiveImg";

export const DevsPartner = ({
  partnerData = {},
  title = "Partner",
  imgBorderColor = "#E1E1E1",
  imgHeight = "32px",
  imgBorderRadius = "5px",
  space = "5px",
  sx,
  imgListSx,
  cls,
  bg,
  pd,
  showBorderBtm = true,
}) => {
  return (
    <>
      {partnerData && (
        <PartnerWrapper
          style={sx}
          className={cls}
          bg={bg}
          pd={pd}
          showborderbtm={showBorderBtm}
        >
          <h3>{title}</h3>
          <PartnerDiv
            space={space}
            style={imgListSx}
            className={`${cls}_clients`}
          >
            {partnerData?.logo?.url && (
              <SpResponsiveImg
                src={partnerData?.logo?.url}
                alt={partnerData?.logo?.name}
                borderRadius={imgBorderRadius}
                width={"25px"}
                height={imgHeight}
              />
            )}
            <p>{partnerData?.companyName}</p>
          </PartnerDiv>
        </PartnerWrapper>
      )}
    </>
  );
};
