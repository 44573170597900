import styles from "./styles/requirementsSteps.module.css";
import editPopupStyles from "../SingleRequirement/styles/editPopUp.module.css";
import {
  MdWarning,
  MdClose,
  MdThumbUp,
  MdThumbDown,
  MdCheck,
  MdInfo,
  MdRefresh,
} from "react-icons/md";
import { FaCheckCircle, FaClock, FaBullhorn } from "react-icons/fa";
import { BsHouseDoorFill } from "react-icons/bs";
import { IoExit, IoGlasses } from "react-icons/io5";
import { ImEnter } from "react-icons/im";
import { SiAdblock } from "react-icons/si";

import { useState, useEffect, useMemo } from "react";
import ListSingleDev from "./reqTeam/listDev/ListSingleDev";
import SinglePosition from "./reqTeam/SinglePosition";
import AddRosterMessage from "./reqTeam/AddRosterMessage";
import NotOpenTo from "./reqTeam/NotOpenTo";
import {
  availableTimesForInterview,
  db,
  emailFormatValidationRegex,
  fetchRecommendationsOnReqPage,
  fireAuth,
  getCollectionRefFromPath,
  getDocumentFromPath,
  GetNowTimeStamp,
  GetRefFromPath,
  getTimestampFromMilliseconds,
  updateDcoumentWithPath,
  getDocumentsWhere,
} from "../../globals/services";
import ActiveDevsAndPartners from "./reqTeam/ActiveDevsAndPartners";
import ConfirmInterviewModal from "./reqTeam/ConfirmInterviewModal";
import { Router, useNavigate } from "react-router-dom";

function PosTeamComponent({
  setSelectedTuc,
  setPosRecommendations,
  showActions,
  index,
  tucItem,
  staffing = false,
  buildRatings,
  listTags,
  logAction,
  logAuthors,
  userType,
  paidStatus,
  devPositions,
  costFeeSum,
  priceFeeSum,
  fixedCostFeeSum,
  fixedPriceFeeSum,
  optionParms,
  setShowDevProfile,
  selectedUserRole,
  selectedPartner,
  setSelectedTabIndex,
  partnerAccountData,
  orgPosSpecs,
}) {
  const route = useNavigate();
  const [devItems, setDevItems] = useState([]);
  const [showNote, setShowNote] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [showTagOptions, setShowTagOptions] = useState(false);
  const [showPositionOptions, setShowPositionOptions] = useState(false);
  const [showVendorSearch, setShowVendorSearch] = useState(false);
  const [currentDevTags, setCurrentDevTags] = useState([]);
  const [noteForResource, setNoteForResource] = useState("");
  const [likeThisResource, setLikeThisResource] = useState(true);
  const [selectedResourceData, setSelectedResourceData] = useState({});
  const [optionalResourceComment, setOptionalResourceComment] = useState("");
  const [titleData, setTitleData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [devRankMap, setDevRankMap] = useState([]);
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedNoteIndex, setSelectedNoteIndex] = useState();
  const [deleteNoteConfirmationBox, setDeleteNoteConfirmationBox] =
    useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [showConfirmInterviewModal, setShowConfirmInterviewModal] =
    useState(false);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [mailIds, setMailIds] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState({});
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [existingTimes, setExistingTimes] = useState([]);
  const [timeSlotSelectionError, setTimeSlotSelectionError] = useState("");
  const [showMarginEditModal, setShowMarginEditModal] = useState(false);
  const [spryteMargin, setSpryteMargin] = useState("");
  const [newSpryteMargin, setNewSpryteMargin] = useState(0);
  const [partnerTakeHome, setPartnerTakeHome] = useState(0);
  const [clientPricing, setClientPricing] = useState(0);
  const [spryteMarginLabel, setSpryteMarginLabel] = useState("");
  const [isPercentual, setIsPercentual] = useState(true);
  const [marginValidationError, setMarginValidationError] = useState("");
  const [marginEdited, setMarginEdited] = useState(false);
  const [previousSpryteMargin, setPreviousSpryteMargin] = useState(0);
  const [isPreviousMarginPercentual, setIsPreviousMarginPercentual] =
    useState(true);

  const [activeResources, setActiveResources] = useState(0);
  const [activePartners, setActivePartners] = useState(0);

  useEffect(() => {
    var interviewTimeSlots = availableTimesForInterview();
    setAvailableDates(interviewTimeSlots["dates"]);
    setAvailableTimes(interviewTimeSlots["times"]);
  }, []);

  useEffect(() => {
    let subActiveDevsAndPartners;
    if (selectedUserRole === "partner") {
      if (tucItem) {
        subActiveDevsAndPartners = db
          .collection(`${tucItem?.path}/POS-${index + 1}`)
          .onSnapshot(async (snap) => {
            let filteredDevs = snap?.docs.filter(
              (dev) =>
                !["GENERIC", "WITHDRAWN", "RECOMMENDED", "ABANDONED"].includes(
                  dev?.data()?.status?.id
                )
            );
            setActiveResources(filteredDevs?.length ?? 0);
          });
        let tempActivePartners = {};
        if (tucItem?.sourceRT?.[`POS-${index + 1}`]) {
          tucItem?.sourceRT?.[`POS-${index + 1}`].map((item) => {
            if (
              tucItem.partners.find((partnerRef) => partnerRef?.id == item?.id)
            )
              tempActivePartners[item?.id] = item;
          });
          setActivePartners(Object.keys(tempActivePartners)?.length ?? 0);
        }
      }
    }
    return subActiveDevsAndPartners;
  }, [tucItem, selectedUserRole]);

  useEffect(() => {
    let subDevs;
    const getTitleData = async () => {
      var tempTitleData = (
        await tucItem?.positions?.[index]?.title.get()
      )?.data();
      setTitleData(tempTitleData);
    };
    if (tucItem) {
      if (selectedUserRole === "partner") {
        // console.log("selectedPartner", selectedPartner);
        if (selectedPartner) {
          subDevs = db
            .collection(`${tucItem?.path}/POS-${index + 1}`)
            .where("partnerRef", "==", selectedPartner?.ref)
            .onSnapshot(async (snap) => {
              let filteredDevs = snap?.docs.filter(
                (dev) =>
                  dev?.data()?.status?.id !== "GENERIC" &&
                  dev?.data()?.status?.id !== "WITHDRAWN"
              );
              if (!optionParms?.isInlineRecom)
                filteredDevs = filteredDevs.filter(
                  (dev) => dev?.data()?.status?.id !== "RECOMMENDED"
                );
              if (optionParms?.hideDismissedDev)
                filteredDevs = filteredDevs.filter(
                  (dev) => dev?.data()?.status?.id !== "ABANDONED"
                );
              await fetchDevData(filteredDevs);
            });
        }
      } else {
        subDevs = db
          .collection(`${tucItem?.path}/POS-${index + 1}`)
          .onSnapshot(async (snap) => {
            let filteredDevs = snap?.docs.filter(
              (dev) =>
                dev?.data()?.status?.id !== "GENERIC" &&
                dev?.data()?.status?.id !== "WITHDRAWN"
            );
            if (!optionParms?.isInlineRecom)
              filteredDevs = filteredDevs.filter(
                (dev) => dev?.data()?.status?.id !== "RECOMMENDED"
              );
            if (optionParms?.hideDismissedDev)
              filteredDevs = filteredDevs.filter(
                (dev) => dev?.data()?.status?.id !== "ABANDONED"
              );
            await fetchDevData(filteredDevs);
          });
      }
      if (tucItem?.partners?.[index]?.path)
        db.doc(`${tucItem?.partners?.[index]?.path}`).onSnapshot(
          (vendorSnapShot) => {
            setVendorName(vendorSnapShot.data()?.["companyName"] ?? "");
          }
        );
      getTitleData();
    }
    return subDevs;
  }, [tucItem, optionParms, selectedUserRole, selectedPartner]);

  // useEffect(()=>{
  //   var convertedSpryteMargin = Number.parseFloat(spryteMargin.toString());
  //   if(convertedSpryteMargin < 0 || (isPercentual ? convertedSpryteMargin >= 100 : false)) {
  //     setMarginValidationError(isPercentual ? 'Spryte margin value should be in range of 0 to 100.' : 'Spryte margin value should be in greater than 0.');
  //   } else {
  //     setMarginValidationError('');
  //   }

  //   if(marginEdited) {
  //     if(isPercentual) {

  //       // Note: How this is worked out.
  //       // (C - P) / C = M
  //       // (clientPricing - partnerTakeHome) / clientPricing = spryteMargin / 100
  //       // (- partnerTakeHome / clientPricing) = spryteMargin / 100 - 1
  //       // clientPricing = - partnerTakeHome / (spryteMargin / 100 - 1)
  //       const tempClientPricing = Math.ceil(-partnerTakeHome / (convertedSpryteMargin / 100 - 1));
  //       setClientPricing(tempClientPricing);
  //       setNewSpryteMargin(tempClientPricing - partnerTakeHome);

  //     } else {
  //       setClientPricing(partnerTakeHome+convertedSpryteMargin);
  //       setNewSpryteMargin(convertedSpryteMargin);
  //     }
  //   }

  // }, [spryteMargin, isPercentual, marginEdited]);

  async function fetchDevData(filteredDevs) {
    var tempDevItems = [];
    var tempDevRanks = [];
    await Promise.all(
      filteredDevs?.map(async (dev) => {
        var devData = dev?.data();
        devData["id"] = dev?.id;
        devData["ref"] = dev?.ref;
        if (devData?.["interview"])
          devData["interview"] = devData["interview"]?.filter(
            (time) => time?.toMillis() > GetNowTimeStamp()?.toMillis()
          );
        var devRefData =
          (await getDocumentFromPath(
            `${devData?.devRef?.path}/devPublic/profile`
          )) ?? {};
        devRefData["id"] = devData?.devRef?.id;
        devData["devData"] = devRefData;
        devData["partnerData"] =
          (
            await devData?.partnerRef
              ?.collection("partnerPublic")
              ?.doc("profile")
              ?.get()
          )?.data() ?? {};
        devData["partnerData"]["ref"] = devData?.partnerRef;
        if (selectedUserRole == "partner") {
          devData["rates"] = [];
          const rateRanges = await getDocumentsWhere(
            `${devData?.devRef?.path}/rateRanges`,
            false,
            null,
            true,
            "from",
            false,
            false,
            false,
            false
          );
          var hourlyRate = null;
          // Process all rate ranges.
          for (let rate of rateRanges) {
            // Continue if the date after today.
            if (rate.data().to.toDate() < new Date()) continue;

            if (hourlyRate == null)
              hourlyRate = rate?.data()?.["hourlyRates"]?.["L1"];
          }
          devData["partnerHourlyRate"] = hourlyRate;
        }
        if (devData.rank)
          tempDevRanks.push([devData.rank, dev?.id, devData.name]);
        tempDevItems.push(devData);
      })
    );
    tempDevItems.sort(
      (a, b) =>
        (a?.rank ?? tempDevItems.length * 2) -
        (b?.rank ?? tempDevItems.length * 2)
    );
    setDevItems(tempDevItems);
    tempDevRanks.sort((a, b) => a[0] - b[0]);
    setDevRankMap(tempDevRanks);
  }

  async function recomputeMargin() {
    var convertedSpryteMargin = Number.parseFloat(
      spryteMargin === "" || spryteMargin == null ? "0.0" : spryteMargin
    );
    // Number.parseFloat(spryteMargin.toString());
    if (
      convertedSpryteMargin < 0 ||
      (isPercentual ? convertedSpryteMargin >= 100 : false)
    ) {
      setMarginValidationError(
        isPercentual
          ? "Spryte margin value should be in range of 0 to 100."
          : "Spryte margin value should be in greater than 0."
      );
    } else {
      setMarginValidationError("");
    }

    if (marginEdited) {
      if (isPercentual) {
        // Note: How this is worked out.
        // (C - P) / C = M
        // (clientPricing - partnerTakeHome) / clientPricing = spryteMargin / 100
        // (- partnerTakeHome / clientPricing) = spryteMargin / 100 - 1
        // clientPricing = - partnerTakeHome / (spryteMargin / 100 - 1)
        const tempClientPricing =
          -partnerTakeHome / (convertedSpryteMargin / 100 - 1);
        setClientPricing(tempClientPricing);
        setNewSpryteMargin(tempClientPricing - partnerTakeHome);
      } else {
        setClientPricing(partnerTakeHome + convertedSpryteMargin);
        setNewSpryteMargin(convertedSpryteMargin);
      }
    }
  }

  async function handleAddTag(tag) {
    if (currentDevTags?.some((item) => item.label === tag?.label)) {
      setCurrentDevTags([...currentDevTags]);
    } else {
      setCurrentDevTags([...currentDevTags, tag]);
      await updateDcoumentWithPath(selectedResourceData.path, {
        tags: [...currentDevTags, tag],
      });
    }
  }

  async function handleRemoveTag(tag) {
    const arr = currentDevTags?.filter((item) => item.label !== tag?.label);
    setCurrentDevTags([...arr]);
    await updateDcoumentWithPath(selectedResourceData.path, { tags: [...arr] });
  }

  function resetEditSpryteMargin() {
    setSpryteMargin("");
    setSpryteMarginLabel("");
    setIsPercentual(true);
    setMarginValidationError("");
  }

  function validateEmails(emails = []) {
    var tempInvalidEmails = [];
    emails
      .filter((email) => email != "")
      .map((email) => {
        if (emailFormatValidationRegex.test(email)) return null;
        tempInvalidEmails.push(email);
      });
    return tempInvalidEmails;
  }

  const statusColorObject = {
    abandoned: {
      fill: "grey",
      icon: <IoExit style={{ fill: "white" }} />,
    },
    confirmed: {
      fill: "#2ecc71",
      icon: <FaCheckCircle size={13} style={{ fill: "white" }} />,
    },
    considered: {
      fill: "#459df5",
      icon: <IoGlasses style={{ fill: "white" }} />,
    },
    proposed: {
      fill: "#f368e0",
      icon: <ImEnter size={15} style={{ fill: "white" }} />,
    },
    "timed-out": {
      fill: "#70500a",
      icon: <FaClock style={{ fill: "white" }} />,
    },
    "to-be-confirmed": {
      fill: "grey",
      icon: <BsHouseDoorFill size={15} style={{ fill: "white" }} />,
    },
    withdrawn: {
      fill: "#ee5253",
      icon: <IoExit style={{ fill: "white" }} />,
    },
    recommended: {
      fill: "#8d54e9",
      icon: <FaBullhorn style={{ fill: "white" }} />,
    },
  };

  const onEditOption = async (devItem) => {
    // var tempRate = Math.min.apply(Math,devItem?.rates ?? 0);
    if (userType === "ADMIN") {
      var partnerPricing = (
        await getCollectionRefFromPath(`${devItem.devRef.path}/rates`)
          .where("date", ">=", tucItem?.originalTeamCardData?.startDate)
          .where("date", "<=", tucItem?.originalTeamCardData?.endDate)
          .limit(1)
          .get()
      )?.docs[0];
      const tempRate = partnerPricing?.data()?.hourlyRates?.L1 ?? null;

      setShowMarginEditModal(true);
      var data = {
        name: devItem?.name,
        notes: devItem?.notes ?? [],
        reviews: devItem?.reviews ?? [],
        status: devItem.status,
        path: `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
        rates: devItem.rates,
        rate: tempRate,
        marginEdit: devItem?.marginEdit ?? [],
      };
      // calculate partner take home, client pricing, spryte margin
      // setPartnerTakeHome((tempRate*(1-costFeeSum))-fixedCostFeeSum);
      // setClientPricing(staffing ? tempRate : ((tempRate*(1+priceFeeSum))+fixedPriceFeeSum));
      // setNewSpryteMargin(staffing ? ((tempRate*costFeeSum)+fixedCostFeeSum) : (((tempRate*(1+priceFeeSum))+fixedPriceFeeSum)-(tempRate*(1-costFeeSum))-fixedCostFeeSum)/((tempRate*(1+priceFeeSum))+fixedPriceFeeSum));
      setPartnerTakeHome(tempRate * 0.85);
      var tempDevRate = Math.min.apply(Math, devItem?.rates ?? 0);
      setClientPricing(tempDevRate);
      if ((devItem?.marginEdit?.length ?? 0) < 1) {
        setNewSpryteMargin(tempDevRate - tempRate * 0.85);
        setPreviousSpryteMargin(
          (((tempDevRate - tempRate * 0.85) / tempDevRate) * 100).toFixed(2)
        );
        setIsPreviousMarginPercentual(true);
      } else {
        const latestMarginEdit =
          devItem?.marginEdit[devItem?.marginEdit?.length - 1];
        if (latestMarginEdit.percentual) {
          setPreviousSpryteMargin(latestMarginEdit.margin * 100);
          setIsPreviousMarginPercentual(true);
          const tempClientPricing =
            -partnerTakeHome / (latestMarginEdit.margin - 1);
          setNewSpryteMargin(tempClientPricing - partnerTakeHome);
        } else {
          setPreviousSpryteMargin(latestMarginEdit.margin);
          setIsPreviousMarginPercentual(false);
          setNewSpryteMargin(latestMarginEdit.margin);
        }
      }
      // TODO: set spryte margin value to display something in the margin edit box
      setSelectedResourceData(data);
      logAction("Button Clicked", "CLICK");
    }
  };

  const onPickDev = async (devItem) => {
    setShowNote(true);
    setShowRateModal(true);
    var data = {
      name: devItem?.name,
      notes: devItem?.notes ?? [],
      reviews: devItem?.reviews ?? [],
      status: devItem.status,
      path: `${tucItem?.path}/POS-${index + 1}/${devItem.id}`,
    };
    setSelectedResourceData(data);
    setCurrentDevTags(devItem?.tags);
    logAction("Button Clicked", "CLICK");
  };

  const onDevUpRank = async (devItem, devIndex) => {
    if (devItem.rank) {
      if (devItem.rank > 1) {
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItems[devIndex - 1].id}`,
          { rank: Number.parseInt(devItem?.rank ?? "0") }
        );
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
          { rank: Number.parseInt(devItem?.rank ?? "0") - 1 }
        );
      }
    } else {
      if (devRankMap.length == 0) {
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
          { rank: 1 }
        );
      } else {
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${
            devRankMap[devRankMap.length - 1][1]
          }`,
          { rank: devRankMap.length + 1 }
        );
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
          { rank: devRankMap.length }
        );
      }
    }
  };

  const onDevDownRank = async (devItem, devIndex) => {
    if (devItem.rank) {
      if (devItem.rank < devItems.length) {
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
          { rank: Number.parseInt(devItem?.rank ?? "0") + 1 }
        );
        await updateDcoumentWithPath(
          `${tucItem.path}/POS-${index + 1}/${devItems[devIndex + 1].id}`,
          { rank: Number.parseInt(devItem?.rank ?? "0") }
        );
      }
    } else {
      await updateDcoumentWithPath(
        `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
        { rank: devRankMap.length + 1 }
      );
    }
  };

  const onViewDev = async (devItem) => {
    setShowDevProfile(devItem);
    if (
      (devItem?.status?.id == "TO-BE-CONFIRMED" ||
        devItem?.status?.id == "PROPOSED") &&
      selectedUserRole === "client"
    ) {
      await updateDcoumentWithPath(
        `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
        {
          status: GetRefFromPath(`${devItem.status.parent.path}/CONSIDERED`),
        }
      );
      // LOG: Logging user action.
      logAction(`Considered resource - ${devItem?.name}.`, "ACTION");
    }
    logAction("Button Clicked", "CLICK");
    setSelectedTabIndex(4);
    // window.open(`/resource/${devItem.devRef.id}`, "_blank");
  };

  const onAddNoteToDev = async (devItem) => {
    // setShowAddNoteModal(true);
    setShowRateModal(false);
    setShowNote(true);
    var data = {
      name: devItem?.name,
      notes: devItem?.notes ?? [],
      reviews: devItem?.reviews ?? [],
      status: devItem.status,
      path: `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
    };
    setSelectedResourceData(data);
    setCurrentDevTags(devItem?.tags ?? []);
    logAction("Button Clicked", "CLICK");
  };

  const onInterviewDev = async (devItem) => {
    setShowInterviewModal(true);
    logAction("Button Clicked", "CLICK");
    var data = {
      name: devItem?.name,
      notes: devItem?.notes ?? [],
      reviews: devItem?.reviews ?? [],
      status: devItem.status,
      interview: devItem?.interview ?? [],
      interviewEmails: devItem?.interviewEmails ?? [],
      path: `${tucItem?.path}/POS-${index + 1}/${devItem.id}`,
    };
    let tempExistingTimes = [];
    devItem?.interview?.map((timeSlot) => {
      tempExistingTimes.push(timeSlot.toMillis());
    });
    setExistingTimes([...tempExistingTimes]);
    setSelectedResourceData(data);
  };

  const onInterviewConfirm = async (devItem) => {
    setShowConfirmInterviewModal(true);
    logAction("Button Clicked", "CLICK");
    var data = {
      name: devItem?.name,
      notes: devItem?.notes ?? [],
      reviews: devItem?.reviews ?? [],
      status: devItem.status,
      interview: devItem?.interview ?? [],
      interviewEmails: devItem?.interviewEmails ?? [],
      path: `${tucItem?.path}/POS-${index + 1}/${devItem.id}`,
    };
    setSelectedResourceData(data);
  };

  const onCloseConfirmInterviewModal = () => {
    setShowConfirmInterviewModal(false);
    logAction("Button Clicked", "CLICK");
  };

  const onNoteClicked = async (note, devItem, noteIndex) => {
    if (note?.author?.path == `users/${fireAuth.currentUser.uid}`) {
      setShowEditNote(true);
      setSelectedNote(note?.note ?? "");
      setSelectedNoteIndex(noteIndex);
      var data = {
        name: devItem?.name,
        notes: devItem?.notes ?? [],
        reviews: devItem?.reviews ?? [],
        status: devItem.status,
        path: `${tucItem.path}/POS-${index + 1}/${devItem.id}`,
      };
      setSelectedResourceData(data);
    }
  };

  const onEditdev = () => {
    // open new tab with another url
    route(`/resources/`);
    // window.open(`https://partners.sprytelab.com/resources`, "_blank");
  };
  const onRemoveDev = async (devItem) => {
    const docRef = db.doc(
      "/client/bbnBIarkd1EHXlJvo2WU/lists/bdPmu0T7I4CW7xLRsUBH/resourceStatuses/WITHDRAWN"
    );

    devItem?.ref?.update({ status: docRef });
  };

  const isOpenToVendor = useMemo(() => {
    if (tucItem?.open) return true;
    if (tucItem?.partners?.length > 0) {
      return tucItem?.partners.some(
        (item) =>
          item?.id ===
          (selectedUserRole === "partner"
            ? partnerAccountData?.vendorRef?.id
            : selectedPartner?.ref?.id)
      );
    }
  }, [
    tucItem,
    selectedUserRole,
    partnerAccountData?.vendorRef?.id,
    selectedPartner?.ref?.id,
  ]);

  return (
    <>
      {showConfirmInterviewModal && (
        <ConfirmInterviewModal
          onCloseConfirmInterviewModal={onCloseConfirmInterviewModal}
          selectedResourceData={selectedResourceData}
          setShowConfirmInterviewModal={setShowConfirmInterviewModal}
          validateEmails={validateEmails}
        />
      )}
      {showInterviewModal ? (
        <div className={editPopupStyles.editPopup}>
          <div className={editPopupStyles.editPopupContent}>
            <div className={editPopupStyles.top}>
              <h4>Schedule an interview</h4>
              <MdClose
                style={{ opacity: ".45", cursor: "pointer" }}
                onClick={() => {
                  setShowInterviewModal(false);
                  logAction("Button Clicked", "CLICK");
                  setSelectedTimes([]);
                  setMailIds("");
                  setInvalidEmails([]);
                }}
                size={20}
              />
            </div>
            <div className={editPopupStyles.bottom} style={{ padding: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                Select time slots you are available to schedule an interview
                with this developer. (*Times are in 24h format, in your local
                time zone.)
              </p>
              {availableDates.map((date, dateIndex) => {
                const now = new Date();
                now.setDate(now.getDate() + dateIndex + 1);
                return (
                  <div style={{ marginTop: "20px" }}>
                    <h4 style={{ marginBottom: "0px" }}>
                      {date.toDateString()}
                    </h4>
                    {availableTimes[dateIndex].map((timeSlotsList) => {
                      return (
                        <button
                          className={editPopupStyles.darkBtn}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            color: existingTimes?.includes(
                              timeSlotsList.getTime()
                            )
                              ? "green"
                              : selectedTimes.includes(timeSlotsList)
                              ? "green"
                              : "",
                          }}
                          onClick={() => {
                            var tempSelectedTimes = [...selectedTimes];
                            if (
                              existingTimes.includes(timeSlotsList.getTime())
                            ) {
                              let tempExistingTimes = [
                                ...existingTimes.filter(
                                  (selectedTime) =>
                                    selectedTime != timeSlotsList.getTime()
                                ),
                              ];
                              setExistingTimes([...tempExistingTimes]);
                            } else if (
                              tempSelectedTimes.includes(timeSlotsList)
                            ) {
                              tempSelectedTimes = [
                                ...selectedTimes.filter(
                                  (selectedTime) =>
                                    selectedTime != timeSlotsList
                                ),
                              ];
                            } else {
                              tempSelectedTimes.push(timeSlotsList);
                            }
                            setSelectedTimes(tempSelectedTimes);
                          }}
                        >
                          {timeSlotsList.getHours() < 10 && "0"}
                          {timeSlotsList.getHours()} :{" "}
                          {timeSlotsList.getMinutes() < 10 && "0"}
                          {timeSlotsList.getMinutes()}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <input
                  type="email"
                  placeholder="email@website.com,email@gmail.com (input emails seperated by ',')"
                  style={{ width: "90%", padding: "5px" }}
                  value={mailIds}
                  onChange={(event) => setMailIds(event.target.value)}
                />
                {invalidEmails?.length > 0 && (
                  <p style={{ color: "red" }}>
                    *Invalid emails found: {invalidEmails?.join(",")}
                  </p>
                )}
                {timeSlotSelectionError && (
                  <p style={{ color: "red" }}>*{timeSlotSelectionError}</p>
                )}
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{ borderRadius: "5px" }}
                  onClick={async () => {
                    const updatedTimeSlots = [
                      ...selectedTimes,
                      ...existingTimes?.map((milliSecs) =>
                        getTimestampFromMilliseconds(milliSecs)
                      ),
                    ];
                    var emailValidation = validateEmails([
                      ...mailIds?.split(",").map((email) => email.trim()),
                    ]);
                    if (updatedTimeSlots?.length > 0) {
                      setTimeSlotSelectionError("");
                    } else {
                      setTimeSlotSelectionError(
                        "Please select atleast one time slot!"
                      );
                      return;
                    }
                    if (emailValidation?.length == 0) {
                      setInvalidEmails([]);
                      setShowInterviewModal(false);
                      await updateDcoumentWithPath(selectedResourceData.path, {
                        interviewEmails: [
                          ...new Set([
                            ...(selectedResourceData?.interviewEmails ?? []),
                            ...mailIds
                              ?.split(",")
                              ?.map((email) => email.trim())
                              ?.filter((email) => email != ""),
                          ]),
                        ],
                        interview: [...updatedTimeSlots],
                        interviewConfirmed: false,
                      });
                      setSelectedTimes([]);
                      setMailIds("");
                    } else {
                      setInvalidEmails([...emailValidation]);
                    }
                  }}
                >
                  Schedule an interview
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showMarginEditModal ? (
        <div className={editPopupStyles.editPopup}>
          <div className={editPopupStyles.editPopupContent}>
            <div className={editPopupStyles.top}>
              <h4>Edit Spryte's Margin</h4>
              <MdClose
                style={{ opacity: ".45", cursor: "pointer" }}
                onClick={() => {
                  setShowMarginEditModal(false);
                  resetEditSpryteMargin();
                  logAction("Button Clicked", "CLICK");
                }}
                size={20}
              />
            </div>
            <div className={editPopupStyles.bottom} style={{ padding: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                Edit spryte's margin and recalculate the rates.
              </p>
              <div style={{ marginTop: "30px", display: "flex" }}>
                <h4>Partner Pricing:</h4>
                <h5 style={{ marginLeft: "50px", marginTop: "2px" }}>
                  ${selectedResourceData?.rate?.toFixed(2) ?? "--"}/hr
                </h5>
                <h5 style={{ marginLeft: "20px", marginTop: "2px" }}>100%</h5>
              </div>
              <div style={{ marginTop: "5px", display: "flex" }}>
                <h4>Partner Take-home:</h4>
                <h5 style={{ marginLeft: "20px", marginTop: "2px" }}>
                  ${partnerTakeHome?.toFixed(2)}/hr
                </h5>
                <h5 style={{ marginLeft: "20px", marginTop: "2px" }}>85%</h5>
                {/* <h5 style={{marginLeft: "20px", marginTop: "2px"}}>{(partnerTakeHome/selectedResourceData?.rate)*100}%</h5> */}
              </div>
              {/* <div style={{display:'flex', alignItems: 'center', marginTop: '7.5px'}}>
                    <input onClick={()=>{setIsPercentual(!isPercentual);logAction('Button Clicked', 'CLICK');}} type="checkbox" checked={isPercentual}/>
                    <p style={{fontSize:'12px',fontStyle:'oblique', fontWeight:'500', color:'red', marginLeft:'3px'}}>Percentual.</p>
                    </div> */}
              <div style={{ marginTop: "2px", display: "flex" }}>
                <h4>Spryte Margin:</h4>
                <h5
                  style={{
                    marginLeft: "57px",
                    marginTop: "2px",
                    color: "darkblue",
                  }}
                >
                  ${newSpryteMargin?.toFixed(2)}/hr
                </h5>
                <input
                  style={{ width: "15%", marginLeft: "20px" }}
                  type="text"
                  placeholder={
                    previousSpryteMargin +
                    (isPreviousMarginPercentual ? "%" : "")
                  }
                  value={spryteMargin}
                  on
                  onChange={(e) => {
                    setMarginEdited(true);
                    setSpryteMargin(e.target.value);
                    // setSpryteMargin(Number.parseFloat((e.target.value == '' || e.target.value == null) ? '0.0' : e.target.value));}}
                  }}
                />
                <MdRefresh
                  onClick={() => {
                    recomputeMargin();
                  }}
                ></MdRefresh>
                <input
                  style={{ marginLeft: "20px", marginTop: "5px" }}
                  onClick={() => {
                    setIsPercentual(!isPercentual);
                    logAction("Button Clicked", "CLICK");
                  }}
                  type="checkbox"
                  checked={isPercentual}
                />
                <p
                  style={{
                    fontSize: "12px",
                    fontStyle: "oblique",
                    fontWeight: "500",
                    color: "red",
                    marginLeft: "3px",
                    marginTop: "4px",
                  }}
                >
                  Percentual.
                </p>
              </div>
              {/* <button style={{borderRadius:'5px', marginBottom: '10px', marginTop:'5px'}} id="dark-btn" onClick={()=>{recomputeRates();}}>compute</button> */}
              {/* <p style={{fontSize:'12px', fontWeight:'400', color:'red', marginBottom: '25px'}}>*Click on recompute once the margin value is edited.</p> */}
              {marginValidationError != "" ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "red",
                    marginBottom: "10px",
                  }}
                >
                  *{marginValidationError}
                </p>
              ) : (
                <></>
              )}
              <div style={{ marginTop: "2px", display: "flex" }}>
                <h4>Client Pricing:</h4>
                <h5
                  style={{
                    marginLeft: "62px",
                    marginTop: "2px",
                    color: "darkblue",
                  }}
                >
                  ${clientPricing.toFixed(2)}/hr
                </h5>
                <h5
                  style={{
                    marginLeft: "20px",
                    marginTop: "2px",
                    color: "darkblue",
                  }}
                >
                  {(
                    ((clientPricing - selectedResourceData?.rate ?? 0) /
                      (selectedResourceData?.rate ?? 1)) *
                      100 +
                    100
                  ).toFixed(2)}
                  %
                </h5>
              </div>
              <div style={{ marginTop: "5px", display: "flex" }}>
                <h4>Note:</h4>
                <input
                  style={{ width: "75%", marginLeft: "20px" }}
                  type="text"
                  placeholder="label"
                  value={spryteMarginLabel}
                  onChange={(event) => setSpryteMarginLabel(event.target.value)}
                />
              </div>
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    borderRadius: "5px",
                    justifyItems: "center",
                    padding: "10px",
                    marginLeft: "40%",
                    marginTop: "40px",
                  }}
                  id="dark-btn"
                  onClick={async () => {
                    // setShowMarginEditModal(false);
                    var tempMarginEdit = [
                      ...(selectedResourceData?.["marginEdit"] ?? []),
                    ];
                    tempMarginEdit.push({
                      margin: isPercentual
                        ? Number.parseFloat(spryteMargin.toString()) / 100
                        : Number.parseFloat(spryteMargin.toString()),
                      ts: GetNowTimeStamp(),
                      user: GetRefFromPath(`users/${fireAuth.currentUser.uid}`),
                      label: spryteMarginLabel ?? "",
                      percentual: isPercentual,
                    });
                    await updateDcoumentWithPath(selectedResourceData.path, {
                      marginEdit: tempMarginEdit,
                      syncRates: true,
                    });
                    resetEditSpryteMargin();
                    setShowMarginEditModal(false);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showEditNote ? (
        <div className={styles.confirmationCtr}>
          <div className={styles.confirmationCtrContent} id={styles.rate}>
            <div className={styles.top}>
              <div style={{ display: "flex" }}>
                <h2>Edit or delete your note.</h2>
              </div>
              <textarea
                style={{ marginTop: "25px" }}
                value={selectedNote}
                onChange={(e) => {
                  setSelectedNote(e.target.value);
                }}
                placeholder="Be as specific as you'd like..."
              />
              <div style={{ display: "flex", marginTop: "17.5px" }}>
                <button
                  style={{ backgroundColor: "#459df5" }}
                  onClick={async () => {
                    setShowEditNote(false);
                    var devDataToUpdate = {
                      notes: [...selectedResourceData?.notes],
                    };
                    devDataToUpdate.notes[selectedNoteIndex]["note"] =
                      selectedNote;
                    await updateDcoumentWithPath(
                      selectedResourceData.path,
                      devDataToUpdate
                    );
                    logAction("Button Clicked", "CLICK");
                    logAction(
                      `Updated note on the resource - ${selectedResourceData?.name}.`,
                      "ACTION"
                    );
                  }}
                >
                  Submit
                </button>
                <button
                  style={{ marginLeft: "7.5px" }}
                  onClick={() => {
                    setShowEditNote(false);
                    logAction("Button Clicked", "CLICK");
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ marginLeft: "7.5px" }}
                  onClick={() => {
                    setDeleteNoteConfirmationBox(true);
                    logAction("Button Clicked", "CLICK");
                  }}
                >
                  {" "}
                  Delete Note{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {deleteNoteConfirmationBox ? (
        <div className={styles.confirmationCtr}>
          <div className={styles.confirmationCtrContent}>
            <div className={styles.top}>
              <MdWarning className={styles.warning} />
              <h2>Delete your note</h2>
            </div>
            <div className={styles.bottom}>
              <h4>Are you sure you want to delete this note?</h4>
              <div className={styles.btnCtr}>
                <button
                  id={styles.yes}
                  onClick={async () => {
                    setDeleteNoteConfirmationBox(false);
                    setShowEditNote(false);
                    var devDataToUpdate = {
                      notes: [
                        ...selectedResourceData?.notes?.filter(
                          (note, noteIndex) => noteIndex != selectedNoteIndex
                        ),
                      ],
                    };
                    await updateDcoumentWithPath(
                      selectedResourceData.path,
                      devDataToUpdate
                    );
                    logAction("Button Clicked", "CLICK");
                    logAction(
                      `Deleted note on the resource - ${selectedResourceData?.name}.`,
                      "ACTION"
                    );
                  }}
                >
                  Delete Now
                </button>
                <button
                  id={styles.no}
                  onClick={() => {
                    setDeleteNoteConfirmationBox(false);
                    logAction("Button Clicked", "CLICK");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showNote ? (
        showRateModal ? (
          <div className={styles.confirmationCtr}>
            <div className={styles.confirmationCtrContent} id={styles.rate}>
              <div className={styles.top}>
                <div className={styles.activityCtr}>
                  <div
                    className={styles.active}
                    style={{ marginRight: "5px" }}
                  ></div>
                  <div className={styles.inactive}></div>
                </div>
                <h4>Confirm or reject this resource...</h4>
                <div
                  className={styles.activityCtr}
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6.5px",
                    }}
                  >
                    <MdThumbUp
                      className={`${styles.thumb} ${styles.up} ${
                        likeThisResource ? styles.chosen : ""
                      }`}
                      style={{ width: "40px" }}
                      onClick={() => {
                        setLikeThisResource(true);
                        logAction("Button Clicked", "CLICK");
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "3.5px",
                      }}
                    >
                      <MdInfo style={{ fill: "#459df5" }} />
                      <h5
                        style={{
                          marginLeft: "3.5px",
                          fontWeight: "600",
                          opacity: ".5",
                        }}
                      >
                        Confirm this resource, he/she works for my project.
                      </h5>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MdThumbDown
                      className={`${styles.thumb} ${styles.down} ${
                        !likeThisResource ? styles.chosen : ""
                      }`}
                      style={{ width: "40px" }}
                      onClick={() => {
                        setLikeThisResource(false);
                        logAction("Button Clicked", "CLICK");
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "3.5px",
                      }}
                    >
                      <MdInfo style={{ fill: "#459df5" }} />
                      <h5
                        style={{
                          marginLeft: "3.5px",
                          fontWeight: "600",
                          opacity: ".5",
                        }}
                      >
                        Reject this resource, he/she does not work for my
                        project.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.confirmationCtrContent} id={styles.rate}>
              <div className={styles.top}>
                <div className={styles.activityCtr}>
                  <div
                    className={styles.inactive}
                    style={{ marginRight: "5px" }}
                  ></div>
                  <div className={styles.active}></div>
                </div>
                <div style={{ display: "flex" }}>
                  <h4>Is there a particular reason why?</h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8.5px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "1.5px",
                        fontWeight: "600",
                        opacity: ".5",
                      }}
                    >
                      [
                    </h5>
                    {likeThisResource ? (
                      <MdThumbUp
                        style={{ fill: "#459df5", fontSize: "13.5px" }}
                      />
                    ) : (
                      <MdThumbDown
                        style={{ fill: "#459df5", fontSize: "13.5px" }}
                      />
                    )}
                    <h5
                      style={{
                        marginLeft: "1.5px",
                        fontWeight: "600",
                        opacity: ".5",
                      }}
                    >
                      ]
                    </h5>
                  </div>
                </div>
                <textarea
                  value={optionalResourceComment}
                  onChange={(e) => {
                    setOptionalResourceComment(e.target.value);
                  }}
                  placeholder="Your feedback (Optional, but very very useful)"
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    style={{ backgroundColor: "#459df5" }}
                    onClick={async () => {
                      setShowNote(false);
                      setShowRateModal(false);
                      var devDataToUpdate = {
                        reviews: [...selectedResourceData?.reviews],
                        status: likeThisResource
                          ? GetRefFromPath(
                              `${selectedResourceData.status.parent.path}/CONFIRMED`
                            )
                          : GetRefFromPath(
                              `${selectedResourceData.status.parent.path}/ABANDONED`
                            ),
                      };
                      devDataToUpdate.reviews.push({
                        author: GetRefFromPath(
                          `users/${fireAuth.currentUser.uid}`
                        ),
                        comment: optionalResourceComment,
                        addedOn: GetNowTimeStamp(),
                        previousStatus: selectedResourceData.status,
                        updatedStatus: devDataToUpdate["status"],
                      });
                      await updateDcoumentWithPath(
                        selectedResourceData.path,
                        devDataToUpdate
                      );
                      logAction("Button Clicked", "CLICK");
                      logAction(
                        `Reviewed resource - ${selectedResourceData?.name}.`,
                        "ACTION"
                      );
                      setOptionalResourceComment("");
                    }}
                  >
                    Submit
                  </button>
                  <button
                    onClick={async () => {
                      setShowNote(false);
                      setShowRateModal(false);
                      setShowTagOptions(false);
                      logAction("Button Clicked", "CLICK");
                    }}
                    style={{ marginLeft: "7.5px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.confirmationCtr}>
            {showNote ? (
              <div className={styles.confirmationCtrContent} id={styles.rate}>
                <div className={styles.top}>
                  <div
                    className={styles.activityCtr}
                    style={{ margin: "-10px 0" }}
                  >
                    <SiAdblock className={styles.checkE} />
                    <h4 style={{ margin: "-7.5px 6.5px" }}>
                      <span style={{ fontWeight: "600" }}>Don't forget!</span>
                      <br />
                      Once you're ready, you should{" "}
                      <span
                        className={styles.undl}
                        onClick={() => {
                          setShowRateModal(true);
                          logAction("Button Clicked", "CLICK");
                        }}
                      >
                        review/confirm
                      </span>{" "}
                      this resource.
                    </h4>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.confirmationCtrContent} id={styles.rate}>
              <div className={styles.top}>
                <div className={styles.activityCtr}>
                  <div
                    className={styles.inactive}
                    style={{ marginRight: "5px" }}
                  ></div>
                  <div className={styles.active}></div>
                </div>
                <div style={{ display: "flex" }}>
                  <h4>Leave a note or tag this resource for now.</h4>
                </div>
                <div className={styles.tagCtr}>
                  <h5>Resource Tags</h5>
                  <button
                    style={{
                      padding: "0",
                      margin: "0",
                      borderRadius: "1.5px",
                      fontSize: "11px",
                      padding: "1.25px 10px",
                      letterSpacing: "0",
                      opacity: ".85",
                    }}
                    onClick={() => {
                      setShowTagOptions(!showTagOptions);
                      logAction("Button Clicked", "CLICK");
                    }}
                  >
                    {showTagOptions ? "CLOSE" : "ADD"}
                  </button>
                  {/* <MdSettings size={15} style={{fill: '#2d343683', cursor: 'pointer'}} onClick={()=>{setShowTagOptions(!showTagOptions); logAction('Button Clicked', 'CLICK');}}/> */}
                  {showTagOptions ? (
                    <div className={styles.tagCtrOptions}>
                      {listTags?.map((tag, index) => {
                        return (
                          <>
                            <div
                              className={styles.option}
                              style={{
                                display: "flex",
                                paddingLeft: "30px",
                                marginTop: "0",
                              }}
                            >
                              <div
                                style={{
                                  height: "13px",
                                  width: "13px",
                                  borderRadius: "50%",
                                  backgroundColor: tag?.color,
                                  marginTop: "2px",
                                  marginRight: "8.5px",
                                }}
                              ></div>
                              <div
                                onClick={() => {
                                  handleAddTag(tag);
                                  logAction("Button Clicked", "CLICK");
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>{tag?.label}</p>
                                <p style={{ opacity: ".5" }}>{tag?.desc}</p>
                              </div>
                              {currentDevTags?.some(
                                (item) => item.label === tag?.label
                              ) ? (
                                <MdClose
                                  onClick={() => {
                                    handleRemoveTag(tag);
                                    logAction("Button Clicked", "CLICK");
                                  }}
                                  className={styles.close}
                                />
                              ) : null}
                              {currentDevTags?.some(
                                (item) => item.label === tag?.label
                              ) ? (
                                <MdCheck className={styles.check} />
                              ) : null}
                            </div>
                            <hr></hr>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className={styles.tagItems}>
                  {currentDevTags?.length == 0 ? (
                    <p
                      style={{
                        color: "#2d343683",
                        fontStyle: "italic",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      There are no active tags for this resource. Add some
                      above.
                    </p>
                  ) : (
                    currentDevTags?.map((tag, index) => {
                      return (
                        <h5
                          style={{
                            color: tag?.color,
                            border: `1px solid ${tag?.color}`,
                            padding: "1px 7.5px",
                            borderRadius: "2px",
                            marginBottom: "5px",
                            backgroundColor: `${tag?.color}17`,
                          }}
                        >
                          {tag?.label}
                        </h5>
                      );
                    })
                  )}
                </div>
                <textarea
                  style={{ marginTop: "25px" }}
                  onChange={(e) => {
                    setNoteForResource(e.target.value);
                  }}
                  placeholder="Be as specific as you'd like..."
                />
                <div style={{ display: "flex", marginTop: "17.5px" }}>
                  <button
                    style={{ backgroundColor: "#459df5" }}
                    onClick={async () => {
                      setShowRateModal(false);
                      setShowNote(false);
                      setOptionalResourceComment("e.target.value");
                      var devDataToUpdate = {
                        notes: [...selectedResourceData?.notes],
                      };
                      devDataToUpdate.notes.push({
                        author: GetRefFromPath(
                          `users/${fireAuth.currentUser.uid}`
                        ),
                        note: noteForResource,
                        addedOn: GetNowTimeStamp(),
                      });
                      await updateDcoumentWithPath(
                        selectedResourceData.path,
                        devDataToUpdate
                      );
                      logAction("Button Clicked", "CLICK");
                      //TODO: DevItem in commented out code below is undefined.
                      logAction(
                        `Commented on the resource - ${selectedResourceData?.name}.`,
                        "ACTION"
                      );
                      // logAction(`Commented on the resource - ${devItem?.name ?? ''}`, 'ACTION');
                      setNoteForResource("");
                    }}
                  >
                    Submit
                  </button>
                  <button
                    style={{ marginLeft: "7.5px" }}
                    onClick={() => {
                      setShowNote(false);
                      setShowRateModal(false);
                      setShowTagOptions(false);
                      logAction("Button Clicked", "CLICK");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : null}

      <SinglePosition
        index={index}
        position={titleData?.label?.toUpperCase() ?? "Position"}
        minRate={tucItem?.positions?.[index]?.maxRate?.toFixed(2) ?? "0"}
        onSelection={() => {
          if (selectedUserRole !== "partner") {
            fetchRecommendationsOnReqPage(
              tucItem,
              index,
              setPosRecommendations
            );
            setSelectedTabIndex(1);
            setSelectedTuc(tucItem);
          } else {
            setSelectedTabIndex(3);
          }
        }}
        // paidStatus={paidStatus}
        paidStatus={true}
        devPositions={devPositions}
        searchResults={searchResults}
        logAction={logAction}
        tucItem={tucItem}
        titleData={titleData}
        userType={userType}
        white={true}
        selectedUserRole={selectedUserRole}
        orgPosSpecs={orgPosSpecs}
      >
        {selectedUserRole === "partner" && (
          <div style={{ padding: "10px" }}>
            <ActiveDevsAndPartners
              activeResources={activeResources}
              partners={`${activePartners}/${
                tucItem?.sourceRT?.[`POS-${index + 1}`]?.length ?? 0
              }`}
            />
          </div>
        )}
        {devItems &&
          devItems?.length > 0 &&
          devItems?.map((dev, i) => {
            return (
              <div>
                <ListSingleDev
                  devData={dev}
                  onUpRank={() => onDevUpRank(dev, i)}
                  onDownRank={() => onDevDownRank(dev, i)}
                  logAuthors={logAuthors}
                  onViewDev={() => onViewDev(dev)}
                  onPick={() => onPickDev(dev)}
                  onAddNote={() => onAddNoteToDev(dev)}
                  onInterview={() => onInterviewDev(dev)}
                  onInterviewConfirm={() => {
                    onInterviewConfirm(dev);
                  }}
                  statusColorObject={statusColorObject}
                  optionParms={optionParms}
                  selectedUserRole={selectedUserRole}
                  onNoteClicked={onNoteClicked}
                  onEditdev={() => onEditdev(dev)}
                  onRemoveDev={() => onRemoveDev(dev)}
                  onEditOption={() => {
                    onEditOption(dev);
                  }}
                />
              </div>
            );
          })}
        {selectedUserRole === "partner" && (
          <div
            style={{
              padding: "10px",
            }}
          >
            {isOpenToVendor ? <AddRosterMessage /> : <NotOpenTo />}
          </div>
        )}
      </SinglePosition>
    </>
  );
}

export default PosTeamComponent;
