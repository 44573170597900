import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Selection,
  Inject,
  Filter,
  Page,
  Edit,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import {
  ExcelExport,
  PdfExport,
  Group,
  Toolbar,
  Search,
} from "@syncfusion/ej2-react-grids";
import ratingTemplate from "../../grid_templates/ratingTemplate";
import statusSwitchTemplate from "../../grid_templates/statusSwitchTemplate";
import { Animated } from "react-animated-css";
import CustomBulk from "./BulkUpload/CustomBulk";
import SpTabs from "./SpTabs";
import DraftResources from "./DraftResources";
import {
  colRef,
  createDocFromCollection,
  docRef,
} from "../../globals/services";
import GridDialog from "./GridDialog";
import { randString } from "../../globals/helpers";
import { ResourcesContext } from "../../Firebase/resources/ResourcesContext";
import { GlobalVendorContex } from "../../Firebase";
import SpLoadingBtn from "../../SpLoadingBtn";
import HourlyRateTemplate from "../../grid_templates/HourlyRateTemplate";
import { fetchGridDevsVendorRef, getDevs } from "../../utils/DevsUtils";
import UploadDev from "../SingleRequirement/reqTeam/UploadDevModal";
import { useNavigate } from "react-router-dom";
import SpUploader from "../../globals/SpUploader";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
// import InputContent from "./InputContent";

const ResourcesGrid = (props) => {
  const router = useNavigate();
  const {
    isViewDevsDetail,
    setIsViewDevsDetail,
    isUploading,
    setIsUploading,
    dataToDuplicate,
    setIsDuplicate,
  } = useContext(ResourcesContext);
  const { setDevName, setIsVerifiedResource, viewDev, viewingSection } =
    useContext(GlobalVendorContex);

  const [skip, setSkip] = useState(0);
  const [draftSkip, setDraftSkip] = useState(0);
  const [benchSkip, setBenchSkip] = useState(0);
  const [action, setAction] = useState("");
  const [activeDevsDocs, setActiveDevsDocs] = useState([]);
  const [devsDocs, setDevsDocs] = useState([]);
  const [draftDevs, setDraftDevs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [viewingTab, setViewingTab] = useState("Bench/Available Resources");
  const [visible, setVisible] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  // All available resource positions from spryte organisation.
  const [devPositions, setDevPositions] = useState([]);
  // Boolean to show uploading a resource modal.
  const [showUploadDevs, setShowUploadDevs] = useState(false);

  const gridRef = useRef(null);
  const draftGridRef = useRef(null);
  const benchGridRef = useRef(null);
  const [resetGridData, setResetGridData] = useState(true);
  const [selDevData, setSelDevData] = useState(null);

  // const ToolbarTemplate = () => {
  //   return (
  //     <div
  //       className="e-toolbar-item"
  //       aria-disabled="false"
  //       title="Move to Draft"
  //     >
  //       <button
  //         className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
  //         type="button"
  //         id="grid_draft_dev"
  //         dataRipple="true"
  //         tabindex="-1"
  //         dataTabindex="-1"
  //         ariaLabel="Move to Draft"
  //         onClick={() => {
  //           if (!selDevData) return;
  //           // console.log("selected dev data", selDevData);
  //           selDevData?.devRef?.update({
  //             draft: true,
  //           });
  //           setSelDevData(null);
  //         }}
  //       >
  //         <GroupRemoveIcon
  //           className="e-btn-icon  e-icons e-icon-left"
  //           style={{
  //             minHeight: "18px",
  //           }}
  //         />

  //         <span className="e-tbar-btn-text">Move to Draft</span>
  //       </button>
  //     </div>
  //     // <button
  //     //   className="e-tbar-btn e-tbar-btn"
  //     //   id="grid_draft_dev"
  //     //   onClick={() => console.log("Draft button clicked")}
  //     // >

  //     // </button>
  //   );
  // };

  const toolBarOptions = [
    {
      text: "Add",
      tooltipText: "Add",
      prefixIcon: "e-plus-small",
      id: "add_dev",
    },
    "ExcelExport",
    "PdfExport",
    "CsvExport",
    "Search",
    // {
    //   text: "Upload Resource",
    //   tooltipText: "Upload Resource",

    //   id: "upload_dev",
    // },
  ];

  const toolbarOnSelected = [
    {
      text: "Add",
      tooltipText: "Add",
      prefixIcon: "e-plus-small",
      id: "add_dev",
    },
    {
      text: "Edit",
      tooltipText: "Edit",
      prefixIcon: "e-edit",
      id: "edit_dev",
    },
    {
      text: "Move to Draft",
      tooltipText: "Move to Draft",
      prefixIcon: "e-circle-remove",
      // template: ToolbarTemplate,
      id: "move_draft_dev",
    },
    // "Delete",
    "ExcelExport",
    "PdfExport",
    "CsvExport",

    {
      text: "View Dev",
      tooltipText: "View Dev",
      prefixIcon: "e-view",
      id: "view_dev",
    },
    // {
    //   text: "Upload Resource",
    //   tooltipText: "Upload Resource",

    //   id: "upload_dev",
    // },

    // {
    //   text: "Duplicate",
    //   tooltipText: "Duplicate",
    //   prefixIcon: "e-dupli",
    //   id: "duplicate_dev",
    //   align: "Right",
    // },
  ];

  useEffect(() => {
    // Fetching all resource's positions for easy upload.
    let subDevPos = props.firebase.db
      .collection("/organisations/T6BAcTjwbXleCFpmWTmu/positions")
      .onSnapshot(async (positionsCollectionSnap) => {
        setDevPositions(positionsCollectionSnap.docs);
      });

    // Fetching all available devs for the selected vendor
    let activeDevsSub = props.firebase.db
      .collection("devs")
      .where("vendorRef", "==", props.vendorRef)
      .where("draft", "==", false)
      .where("status", "==", true)
      .orderBy("addedOn", "desc")
      .onSnapshot((snap) => {
        setActiveDevsDocs(snap.docs);
      });
    //Fetching all UN-available devs for the selected vendor
    let sub = props.firebase.db
      .collection("devs")
      .where("vendorRef", "==", props.vendorRef)
      .where("draft", "==", false)
      .where("status", "==", false)
      .orderBy("addedOn", "desc")
      .onSnapshot((snap) => {
        setDevsDocs(snap.docs);
      });
    let draftDevs = props.firebase.db
      .collection("devs")
      .where("vendorRef", "==", props.vendorRef)
      .where("draft", "==", true)
      .orderBy("addedOn", "desc")
      .onSnapshot((snap) => {
        setDraftDevs(snap.docs);
      });
    setSkip(0);
    setDraftSkip(0);
    setBenchSkip(0);
    return () => {
      try {
        subDevPos();
        activeDevsSub();
        sub();
        draftDevs();
      } catch (error) {}
    };
  }, [props.firebase.db, props.vendorRef]);

  const onFetchSuccess = (devs, docs, gridRef) => {
    if (!gridRef) return;
    // console.log("devs: ", devs);
    gridRef.current["dataSource"] = {
      result: devs,
      count: docs.length,
    };
    setResetGridData(false);
  };

  const onFetchError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    // if (!resetGridData) return;
    // UseEffect for fetching devs for Available resources tab
    if (viewingTab !== "Bench/Available Resources") return;
    if (!benchGridRef?.current?.dataSource) return;

    return fetchGridDevsVendorRef({
      vendorRef: props.vendorRef,
      draft: false,
      status: true,
      skip: benchSkip,
      docs: activeDevsDocs,
      limit: 10,
      onSuccess: (snap, docs) =>
        onFetchSuccess(snap, activeDevsDocs, benchGridRef),
      onError: onFetchError,
    });
  }, [activeDevsDocs, benchSkip, props.vendorRef?.id, viewingTab]);

  useEffect(() => {
    // if (!resetGridData) return;
    // UseEffect for fetching devs for All resources tab
    if (viewingTab !== "All Resources") return;
    if (!gridRef?.current?.dataSource) return;

    setIsViewDevsDetail(false);
    return fetchGridDevsVendorRef({
      vendorRef: props.vendorRef,
      status: false,
      draft: false,
      skip,
      docs: devsDocs,
      limit: 10,
      onSuccess: (snap) => onFetchSuccess(snap, devsDocs, gridRef),
      onError: onFetchError,
    });
  }, [devsDocs, skip, props.vendorRef?.id, viewingTab]);

  useEffect(() => {
    // if (!resetGridData) return;
    if (viewingTab !== "Draft Resources") return;
    if (!draftGridRef?.current?.dataSource) return;
    // console.log("refreshing draft resources ");
    // console.log("draftDevs", draftDevs);

    return fetchGridDevsVendorRef({
      vendorRef: props.vendorRef,
      draft: true,
      status: false,
      skip: draftSkip,
      docs: draftDevs,
      limit: 10,
      onSuccess: (snap, docs) => onFetchSuccess(snap, draftDevs, draftGridRef),
      onError: onFetchError,
    });
  }, [draftDevs, props.vendorRef?.id, draftSkip, viewingTab]);

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case "Add":
        setIsOpen(true);
        setAction("add");
        break;
      case "Edit":
        setIsOpen(true);
        setAction("edit");
        break;
      case "PDF Export":
        gridRef?.current?.pdfExport();
        break;
      case "Excel Export":
        gridRef?.current?.excelExport();
        break;
      case "CSV Export":
        gridRef?.current?.csvExport();
        break;
      case "View Dev":
        props.setShowDevData(true);
        viewingSection.current = "Resources";
        let name = viewDev?.current?.first + " " + viewDev?.current?.last;
        setDevName(name);
        router(`/resources/${selDevData?.id}`);
        // props.firebase.reportPageVisits(
        //   "Single Resource Page View",
        //   props.isSuper,
        //   {
        //     "Vendor Id": props?.vendor.id,
        //     "Vendor Name": props?.vendor.companyName,
        //     "Dev Id": viewDev?.id,
        //   }
        // );
        break;

      case "Duplicate":
        //set the the isDup boolean to true when the button is clicked
        // props.setIsDup(true);
        setIsDuplicate(true);
        viewingSection.current = "Resources";
        setIsOpen(true);
        setAction("duplicate");
        // gridRef?.current?.addRecord();
        break;

      // case "Upload Resource":
      //   setShowUploadDevs(true);
      //   break;

      default:
    }
  };

  const rowSelecting = () => {
    gridRef?.current?.clearSelection();
    benchGridRef?.current?.clearSelection();
    draftGridRef?.current?.clearSelection();
  };

  // ======================================================search feature on Resources page=================================================================
  // const searchDevData = async (searchVal) => {
  //   const lowerCaseSearchValue = searchVal.toLowerCase(); // converts all search values to lower case
  //   const initialCapSearchValue =
  //     lowerCaseSearchValue.charAt(0).toUpperCase() +
  //     lowerCaseSearchValue.slice(1); // converts first letter to upper case
  //   const upperCaseSearchValue = searchVal.toUpperCase(); // converts all search values to upper case

  //   // an array of converted search values which ignores cases irrespective of search inputs
  //   let convertedSearchValue = [
  //     initialCapSearchValue,
  //     lowerCaseSearchValue,
  //     upperCaseSearchValue,
  //   ];

  //   // queries the db and gets the docs/details of the search parameters e.g first name, last name, access code & developer ID
  //   const firstName = colRef("devs")
  //     .where("vendorRef", "==", props.vendorRef)
  //     .where("first", "in", convertedSearchValue)
  //     .get();
  //   const lastName = colRef("devs")
  //     .where("vendorRef", "==", props.vendorRef)
  //     .where("last", "in", convertedSearchValue)
  //     .get();
  //   const accessCode = colRef("devs")
  //     .where("vendorRef", "==", props.vendorRef)
  //     .where("accessCode", "==", searchVal)
  //     .get();
  //   const devID = docRef(`devs/${searchVal}`).get();

  //   // resolves all three promises once using Promise.all()
  //   const results = await Promise.all([firstName, lastName, accessCode, devID]);

  //   // spreads the details of the resolved promises into the devData array
  //   // 1st index rep the details of the first name; 2nd index rep the details of the last name, etc
  //   let devData = [...results[0].docs, ...results[1].docs, ...results[2].docs];

  //   // pushes the resolved developer ID into devData if it exists
  //   if (
  //     results[3].exists &&
  //     results[3]?.data()?.vendorRef?.path === props.vendorRef?.path
  //   ) {
  //     devData.push(results[3]);
  //   }

  //   // invokes allDevsData function which returns searched data docs;
  //   let devs = await props.firebase.allDevsData({ docs: devData }, true, true);

  //   gridRef.current["dataSource"] = {
  //     count: devData.length,
  //     result: devs,
  //   };
  // };

  // const searchDevData = async (searchVal) => {
  //   const lowerCaseSearchValue = searchVal.toLowerCase(); // converts all search values to lower case

  //   // Status of draft is true only for draft resources.
  //   const draftValue = viewingTab === "Draft Resources";

  //   // Query with devs of a particular vendor.
  //   const devColQuery = colRef("devs").where(
  //     "vendorRef",
  //     "==",
  //     props.vendorRef
  //   );

  //   // Query with devs of matching searchIndex
  //   const devNameColQuery = devColQuery
  //     .where("searchIndex", "array-contains", lowerCaseSearchValue)
  //     .where("draft", "==", draftValue);

  //   // Query with devs of matching accessCode.
  //   const accessCodeQuery = devColQuery
  //     .where("accessCode", "==", searchVal)
  //     .where("draft", "==", draftValue);

  //   // queries the db and gets the docs/details of the search parameters e.g first name, last name, access code & developer ID.
  //   let name;
  //   let accessCode;
  //   // Querying devs with status based on the selected tab. status true/false for Bench/ All devs and no status filtering for draft devs.
  //   if (viewingTab !== "Draft Resources") {
  //     name = devNameColQuery
  //       .where("status", "==", viewingTab === "Bench/Available Resources")
  //       .get();
  //     accessCode = accessCodeQuery
  //       .where("status", "==", viewingTab === "Bench/Available Resources")
  //       .get();
  //   } else {
  //     name = devNameColQuery.get();
  //     accessCode = accessCodeQuery.get();
  //   }

  //   const devID = docRef(`devs/${searchVal}`).get();

  //   // resolves all three promises once using Promise.all()
  //   const results = await Promise.all([name, accessCode, devID]);

  //   // spreads the details of the resolved promises into the devData array
  //   // 1st index rep the details of the name; 2nd index rep the details of the accessCode.
  //   let devData = [...results[0].docs, ...results[1].docs];

  //   // pushes the resolved developer ID into devData if it exists
  //   if (
  //     results[3]?.exists &&
  //     results[3]?.data()?.vendorRef?.path === props.vendorRef?.path
  //   ) {
  //     if (
  //       results[3]?.data()?.status === true &&
  //       viewingTab === "Bench/Available Resources"
  //     ) {
  //       devData.push(results[3]);
  //     } else if (
  //       results[3]?.data()?.status === false &&
  //       viewingTab === "All Resources"
  //     ) {
  //       devData.push(results[3]);
  //     } else if (results[3]?.data()?.draft === true) {
  //       devData.push(results[3]);
  //     }
  //   }

  //   // invokes allDevsData function which returns searched data docs;
  //   let devs = await getDevs(devData);
  //   // await props.firebase.allDevsData(
  //   //   { docs: devData.sort((a, b) => a.data()?.addedOn - b.data()?.addedOn) },
  //   //   true,
  //   //   true
  //   // );
  //   if (viewingTab === "Bench/Available Resources") {
  //     benchGridRef.current["dataSource"] = {
  //       count: devData.length,
  //       result: devs,
  //     };
  //   } else if (viewingTab === "All Resources") {
  //     gridRef.current["dataSource"] = {
  //       count: devData.length,
  //       result: devs,
  //     };
  //   } else {
  //     draftGridRef.current["dataSource"] = {
  //       count: devData.length,
  //       result: devs,
  //     };
  //   }
  // };

  const searchDevData1 = async (searchVal) => {
    const lowerCaseSearchValue = searchVal.toLowerCase(); // converts all search values to lower case

    // Status of draft is true only for draft resources.
    const draftValue = viewingTab === "Draft Resources";

    // Query with devs of a particular vendor.
    const devColQuery = colRef("devs").where(
      "vendorRef",
      "==",
      props.vendorRef
    );

    // Query with devs of matching searchIndex
    const devNameColQuery = devColQuery
      .where("searchIndex", "array-contains", lowerCaseSearchValue)
      .where("draft", "==", draftValue);

    // Query with devs of matching accessCode.
    const accessCodeQuery = devColQuery
      .where("accessCode", "==", searchVal)
      .where("draft", "==", draftValue);

    // queries the db and gets the docs/details of the search parameters e.g first name, last name, access code & developer ID.
    let name;
    let accessCode;
    // Querying devs with status based on the selected tab. status true/false for Bench/ All devs and no status filtering for draft devs.
    if (viewingTab !== "Draft Resources") {
      name = devNameColQuery
        .where("status", "==", viewingTab === "Bench/Available Resources")
        .get();
      accessCode = accessCodeQuery
        .where("status", "==", viewingTab === "Bench/Available Resources")
        .get();
    } else {
      name = devNameColQuery.get();
      accessCode = accessCodeQuery.get();
    }

    const devID = docRef(`devs/${searchVal}`).get();

    // resolves all three promises once using Promise.all()
    const results = await Promise.all([name, accessCode, devID]);

    // spreads the details of the resolved promises into the devData array
    // 1st index rep the details of the name; 2nd index rep the details of the accessCode.
    let devData = [...results[0].docs, ...results[1].docs];

    // pushes the resolved developer ID into devData if it exists
    if (
      results[3]?.exists &&
      results[3]?.data()?.vendorRef?.path === props.vendorRef?.path
    ) {
      if (
        results[3]?.data()?.status === true &&
        viewingTab === "Bench/Available Resources"
      ) {
        devData.push(results[3]);
      } else if (
        results[3]?.data()?.status === false &&
        viewingTab === "All Resources"
      ) {
        devData.push(results[3]);
      } else if (results[3]?.data()?.draft === true) {
        devData.push(results[3]);
      }
    }

    // invokes allDevsData function which returns searched data docs;
    let devs = await props.firebase.allDevsData(
      { docs: devData.sort((a, b) => a.data()?.addedOn - b.data()?.addedOn) },
      true,
      true
    );
    if (viewingTab === "Bench/Available Resources") {
      benchGridRef.current["dataSource"] = {
        count: devData.length,
        result: devs,
      };
    } else if (viewingTab === "All Resources") {
      gridRef.current["dataSource"] = {
        count: devData.length,
        result: devs,
      };
    } else {
      draftGridRef.current["dataSource"] = {
        count: devData.length,
        result: devs,
      };
    }
  };

  /**
   * Called when the grid finished rendering
   * @param {object} args arguments of the grid renderComplete function
   */
  //   const rendereComplete = (args) => {
  //     dataStateChange(args);
  //   };

  /**
   * Is trigger when the data source of the grid changes(row deletes or added or edited)
   * @param {*} args
   */
  const dataSourceChanged = async (args) => {};

  const resetSkip = () => {
    setSkip(0);
    setBenchSkip(0);
    setDraftSkip(0);
    setResetGridData(true);
  };

  /**
   * triggers when an action happens in the grid(paging ex)
   * @param {*} state
   */
  const dataStateChange = async (state) => {
    if (state?.skip) {
      if (viewingTab === "Bench/Available Resources") {
        setBenchSkip(state?.skip);
      } else if (viewingTab === "All Resources") {
        setSkip(state?.skip);
      } else {
        setDraftSkip(state?.skip);
      }
      setResetGridData(true);
    } else {
      resetSkip();
    }
    if (state?.search?.length > 0) {
      searchDevData1(state?.search[0]?.key);
    }
    if (
      state?.action?.requestType === "searching" &&
      state?.action?.searchString === ""
    ) {
      resetSkip();
    }
    if (
      state?.action?.requestType === "searching" &&
      state?.action?.searchString === ""
    ) {
      resetSkip();
    }
  };

  /**
   * triggers when an action happens in the grid(paging ex)
   * @param {*} state
   */
  const draftDataStateChange = async (state) => {
    if (state?.skip) {
      setDraftSkip(state?.skip);
    } else {
      setDraftSkip(0);
    }
    // if (state?.search?.length > 0) {
    //   searchDevData(state?.search[0]?.key);
    // }
  };

  const scrapeResumes = async (resumeDict) => {
    const configs = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resume_dict: resumeDict,
      }),
    };

    const resume_parserURL = await props.firebase.getResumeParserURL();
    try {
      const response = await fetch(resume_parserURL, configs);

      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const renderDevData = async (
    scrapedData,
    count,
    resumeData,
    fileName,
    file
  ) => {
    let fname = "John";
    let lname = "Smith";
    let name = scrapedData?.parsed_resume?.name;
    fname = name ? name.split(" ")[0] : "John";
    lname = name ? name.split(" ")[1] : "Smith";

    let devData = {
      first: fname ?? "No First",
      last: lname ?? "No Last",
      devID: String(count),
      locTag: props?.vendor?.locationData.administrative_area_level_1,
      status: false,
      minContractTeamSize: props.minContractTeamSize,
      rating: 1,
      email: scrapedData?.parsed_resume?.email,
      accessCode: randString(10),
      crossVendor: false,
      draft: true,
      isVerified: true,
      position: "Jr. Developer",
      positionRef: props.allPositions.filter(
        (el) => el.label === "Jr. Developer"
      )?.[0]?.ref,
      vendorRef: props.vendor.vendorRef,
      resumeData: resumeData,
      orgRef: props.vendor.orgRef,
      addedOn: new Date(),
    };

    let devDoc = await createDocFromCollection("devs", devData);
    let startDate = new Date();
    let date = new Date();
    //end Date is a year from the day the dev was added in the vendor resources
    let endDate = new Date(date.setFullYear(date.getFullYear() + 1));
    const rateData = {
      dailyHours: 8,
      description: "",
      from: new Date(startDate.setHours(0, 0, 0, 0)),
      to: new Date(endDate.setHours(23, 59, 59, 59)),
      hourlyRates: {
        L1: 10,
      },
    };

    const devId = devDoc.id;
    createDocFromCollection(`devs/${devId}/rateRanges`, rateData);
    let skillData = {
      experience: Math.floor(scrapedData?.parsed_resume?.total_experience * 12),
      interest: "Interested",
      projects: 1,
      skill: 1,
    };
    let stacks = Object.values(scrapedData?.skills?.stacks).map((stack) => {
      let data = { ...skillData };
      data["tech"] = stack.name;
      data["techRef"] = docRef(stack.path);
      createDocFromCollection(`devs/${devId}/stacks`, data);
      return data;
    });
    let categories = Object.values(scrapedData?.skills?.categories).map(
      (stack) => {
        let data = { ...skillData };
        data["projType"] = stack.name;
        data["projectRef"] = docRef(stack.path);
        createDocFromCollection(`devs/${devId}/categories`, data);
        return data;
      }
    );
    let projects = Object.values(scrapedData?.skills?.sectors).map((stack) => {
      let data = { ...skillData };
      data["domain"] = stack.name;
      data["domainRef"] = docRef(stack.path);
      createDocFromCollection(`devs/${devId}/sectors`, data);
      return data;
    });

    let education = scrapedData?.parsed_resume?.degree?.map((deg) => {
      let data = {};
      data["degName"] = deg;
      data["school"] = "";
      data["country"] = "";
      data["degLevel"] = "High School";
      data["gradDate"] = new Date();
      data["description"] = "";
      createDocFromCollection(`devs/${devId}/education`, data);
      return data;
    });

    let workExp = [...new Set(scrapedData?.parsed_resume?.company_names)]?.map(
      (comp, i) => {
        let data = {};
        data["id"] = i;
        data["title"] = "";
        data["addedOn"] = new Date();
        data["company"] = comp;
        data["bullets"] = [];
        data["location"] = "";
        data["start"] = new Date();
        data["end"] = new Date();
        createDocFromCollection(`devs/${devId}/workExp`, data);
        return data;
      }
    );
    const correctedData = {
      categories: [],
      sectors: [],
      stacks: [],
      dev: devData,
    };
    props.firebase.saveResumeData(
      scrapedData.resume_text,
      {
        ...scrapedData.skills,
        ...scrapedData.parsed_resume,
      },
      correctedData
    );

    let data = {
      scrapedData: scrapedData,
      localUrl: URL.createObjectURL(file),
      fileName: fileName,
      devData: devData,
      education: education ?? [],
      workExp: workExp ?? [],
      skills: {
        stacks: stacks ?? [],
        categories: categories ?? [],
        projects: projects ?? [],
      },
    };
    return data;
  };

  const handleFileInputChange = async (files) => {
    // Scrape each resume
    let count = draftDevs.length + 1;
    setIsUploading(true);

    for (const file of files) {
      // if (handleErrorFile(file)) return;
      const ext = "pdf";
      const hash = randString(10);

      // Create new file name {dev's Full Name} - {dev's document id} - resume
      let newFileName = `${hash}-resume.${ext}`;
      const storage = props.firebase.getStorage();
      let storagePath = `vendors/${props.vendor.id}/resume/${newFileName}`;
      let response = await storage.ref(storagePath).put(file);
      let resumeLink = await response.ref.getDownloadURL();
      let res = {};
      res[newFileName] = resumeLink;
      let resumeData = {
        resumeHash: newFileName,
        resumeUrl: resumeLink,
      };
      await scrapeResumes(res)
        .then((scraped) => {
          renderDevData(
            Object.values(scraped)[0],
            count,
            resumeData,
            file?.name,
            file
          );
        })
        .catch((e) => {
          console.log(e);
        });

      count++;
    }
    setIsUploading(false);
  };

  const onFilesSelected = async (e) => {
    if (!e.target.files.length) return;
    const files = e.target.files;

    handleFileInputChange(files);
  };

  const onRowSelected = (args) => {
    if (args?.data?.id) {
      viewDev.current = args.data;
      viewingSection.current = "Resources";
      setSelDevData(args.data);

      setIsVerifiedResource(args.data?.isVerified ?? true);
      setIsViewDevsDetail(true);
    }
  };
  const onRowDeselect = (args) => {
    if (args?.data?.id) {
      setIsVerifiedResource(true);
      setIsViewDevsDetail(false);
      setSelDevData(null);
    }
  };

  /**
   * Changing the visibility wether the add template is vible or not
   *
   */
  useEffect(() => {
    if (viewingTab === "Draft Resources")
      setTimeout(() => setVisible(false), 650);
    else setTimeout(() => setVisible(true), 650);
  }, [viewingTab]);

  const onClose = () => {
    setIsOpen(false);

    setIsDuplicate(false);
  };

  const gridDataRef = useMemo(() => {
    if (viewingTab === "Bench/Available Resources") return benchGridRef;
    return gridRef;
  }, [viewingTab]);

  // console.log("selected dev data", selDevData);

  // go to page 1 when tab changes in useEffect
  useEffect(() => {
    gridDataRef.current?.goToPage(1);
  }, [viewingTab, gridDataRef?.current]);

  // console.log("props", props);

  return (
    <div className="grid-bulk">
      <GridDialog
        isOpen={isOpen}
        setIsDuplicate={setIsDuplicate}
        headerTitle={
          action === "edit"
            ? "Edit Resource"
            : action === "add"
            ? "Add New Resource"
            : "Duplicate Resource"
        }
        loading={false}
        onClose={onClose}
        allPositions={props.allPositions}
        selDevData={viewDev?.current}
        vendorRef={props.vendorRef}
        orgRef={props.orgRef}
        vendor={props.vendor}
        hoursInWorkDay={props.hoursInWorkDay}
        crossVendor={props.crossVendor}
        minContractTeamSize={props.minContractTeamSize}
        action={action}
        setIsUpdated={setIsUpdated}
        dataToDuplicate={dataToDuplicate}
      />
      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <SpTabs
          sx={{ marginTop: "1rem" }}
          tabContent={[
            {
              title: "Bench/Available Resources",
              desc: "Your currently available resources",
              length: activeDevsDocs.length,
            },
            {
              title: "All Resources",
              desc: "Manage Your Resources and their personal information",
              length: devsDocs.length,
            },
            {
              title: "Draft Resources",
              desc: "Incomplete Resources being worked on",
              length: draftDevs.length,
            },
          ]}
          defaultTab={"Bench/Available Resources"}
          onTabClick={(args) => {
            setViewingTab(args.title);
            setIsViewDevsDetail(false);
            resetSkip();
          }}
        />
        <div
          style={{
            maxWidth: 200,
            margin: "1rem 0",
          }}
        >
          <SpUploader
            uploadText="Upload Resource"
            openFileUploader={false}
            onClick={() => setShowUploadDevs(true)}
          />
        </div>
      </div>
      <div className="bulk-info">
        {/* <CustomBulk
          firebase={props.firebase}
          vendorRef={props.vendorRef}
          vendorId={props.vendor.id}
          stacksObject={props.stacksObject}
          domainExperiencesObject={props.domainExperiencesObject}
          categoriesObject={props.categoriesObject}
          randString={randString}
          vendor={props.vendor}
          hoursInWorkDay={props.hoursInWorkDay}
          minContractTeamSize={props.minContractTeamSize}
          allPositions={props.allPositions}
          defaultPosition={props.defaultPosition}
          orgRef={props.vendor.orgRef}
          isSuper={props.isSuper}
        /> */}

        {/* <div>
          <input
            type="file"
            id="resume-input"
            onChange={(e) => onFilesSelected(e)}
            multiple
            hidden="hidden"
            accept=".pdf"
          />
          <SpLoadingBtn
            loading={isUploading}
            loadingText={"Uploading..."}
            btnClicked={() => {
              document.getElementById("resume-input").click();
            }}
            btnText="Import Resume"
          />
        </div> */}
        {/* <SpLoadingBtn
          btnText={"Upload Resource"}
          btnClicked={() => setShowUploadDevs(true)}
        /> */}
      </div>
      <UploadDev
        showUploadDevs={showUploadDevs}
        setShowUploadDevs={setShowUploadDevs}
        vendorRef={props.vendor.vendorRef}
        devPositions={devPositions}
      />

      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutRight"
        isVisible={viewingTab === "Draft Resources"}
        style={!visible ? null : { display: "none" }}
      >
        <div>
          <DraftResources
            gridRef={draftGridRef}
            resumeDwload={props.resumeDwload}
            draftDevs={draftDevs}
            setShowDevData={props.setShowDevData}
            draftDataStateChange={draftDataStateChange}
            editSettings={props.editSettings}
          />
        </div>
      </Animated>
      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutLeft"
        isVisible={viewingTab !== "Draft Resources"}
        style={visible ? null : { display: "none" }}
      >
        <div>
          <GridComponent
            id="devsGridId"
            className="data-grid"
            // dataSource={[]}
            ref={gridDataRef}
            toolbar={isViewDevsDetail ? toolbarOnSelected : toolBarOptions}
            allowSorting={false}
            width="100%"
            allowPaging={props.allowPaging}
            allowSelection={props.allowSelection}
            allowExcelExport={props.allowExcelExport}
            editSettings={props.editSettings}
            allowPdfExport={props.allowPdfExport}
            allowGrouping={props.allowGrouping}
            toolbarClick={toolbarClick}
            rowSelected={onRowSelected}
            rowDeselected={onRowDeselect}
            actionComplete={props.actionComplete}
            rowSelecting={rowSelecting}
            actionBegin={props.actionBegin}
            pageSettings={{ pageSize: 10 }}
            dataSourceChanged={dataSourceChanged}
            // dataStateChange={props.dataStateChange}
            dataStateChange={dataStateChange}
          >
            <ColumnsDirective>
              <ColumnDirective type="checkbox" width="40"></ColumnDirective>
              <ColumnDirective
                field="status"
                headerText=" AVAILABLE"
                width="105"
                textAlign="Center"
                disableHtmlEncode={false}
                template={statusSwitchTemplate}
              ></ColumnDirective>
              {/* <ColumnDirective
                field="devID"
                headerText="Internal ID"
                width="120"
                textAlign="Center"
                validationRules={props.validationRules}
                defaultValue={"0"}
              ></ColumnDirective> */}
              <ColumnDirective
                field="addedOnString"
                headerText="Added On"
                width="100"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="lastUpdatedString"
                headerText="Updated On"
                width="115"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="first"
                headerText="First Name"
                width="120"
                textAlign="Center"
                validationRules={props.validationRules}
              ></ColumnDirective>
              <ColumnDirective
                field="last"
                headerText="Last Name"
                width="120"
                textAlign="Center"
                validationRules={props.validationRules}
              ></ColumnDirective>
              <ColumnDirective
                field="position"
                headerText="Position"
                width="120"
                editType="dropdownedit"
                textAlign="Center"
                edit={props.positions}
              />
              <ColumnDirective
                field="type"
                headerText="Type"
                width="105"
                textAlign="Center"
                template={props.devTypeTemplate}
                // validationRules={props.validationRules}
              ></ColumnDirective>
              <ColumnDirective
                field="rating"
                headerText="Rating"
                width="87"
                textAlign="Center"
                template={ratingTemplate}
              ></ColumnDirective>
              <ColumnDirective
                field="hourlyRate"
                headerText="Hourly Rate"
                width="120"
                format="C2"
                textAlign="Center"
                // min={10}
                template={HourlyRateTemplate}
                // defaultValue={10}
                // allowEditing={false}
              ></ColumnDirective>
              <ColumnDirective
                field="resume"
                required={false}
                headerText="Resume"
                width="90"
                textAlign="Center"
                template={props.resumeDwload}
              ></ColumnDirective>
              <ColumnDirective
                field="docs"
                required={false}
                headerText="Documents"
                width="115"
                textAlign="Center"
                template={props.devDocs}
              ></ColumnDirective>
              <ColumnDirective
                field="progress"
                required={false}
                headerText="Profile Progress"
                width="140"
                textAlign="Center"
                template={props.progress}
              ></ColumnDirective>
              <ColumnDirective
                field="locTag"
                headerText="Location"
                width="120"
                textAlign="Center"
              ></ColumnDirective>

              <ColumnDirective
                field="id"
                required={false}
                headerText="Developer ID"
                width="220"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="accessCode"
                headerText="Access Code"
                width="140"
                textAlign="Center"
              ></ColumnDirective>
            </ColumnsDirective>

            <Inject
              services={[
                Search,
                Selection,
                Page,
                Toolbar,
                Edit,
                Filter,
                ExcelExport,
                PdfExport,
                Group,
              ]}
            />
          </GridComponent>
        </div>
      </Animated>
    </div>
  );
};

export default ResourcesGrid;
