import React from "react";

const AddIcon = ({ size = 32, fill = "#4361E9", line = "white" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="16" fill={fill} />
      <line
        x1="15.9619"
        y1="9.5"
        x2="15.9619"
        y2="22.5"
        stroke={line}
        strokeLinecap="round"
      />
      <line
        x1="22.5"
        y1="15.9609"
        x2="9.5"
        y2="15.9609"
        stroke={line}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIcon;
