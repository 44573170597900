import React from "react";
import { SampleBase } from "../../sample-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default class Verification extends SampleBase {
  constructor() {
    super(...arguments);
    this.statusDropDown = ["Active", "Inactive"];
    this.dropDownModel = {
      dataSource: this.statusDropDown,
    };
  }

  // Update vendor function to allow user to edit data
  // in syncfusion inplaceeditor component
  updateVendorData(data) {
    const updates = {};
    updates.verification = this.props.verification;
    // Date in a inplaceeditor returns a ISOstring. We want
    // a date object such that we have a timestamp value
    // in the firebase database
    if (data.name === "dateOfFormation") {
      updates.verification[data.name] = new Date(data.value);
    } else {
      updates.verification[data.name] = data.value;
    }
    updates.verification.lastUpdated = new Date();
    try {
      this.props.firebase.updateVendor(this.props.vendor.id, updates);
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    const status = this.props?.verification?.status ?? false;
    let isActive;
    if (status) {
      isActive = "Active";
    } else {
      isActive = "Inactive";
    }

    return (
      <div className="stat">
        <div className="circle"></div>
        <div className="stat_header">
          <div className="title">
            <h1>Verification</h1>
          </div>
          <div className="desc-icon">
            <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
            <div className="description">
              The process of becoming a Spryte Partner begins with a
              verification of your business. Once you have uploaded the
              necessary documents on your documents page, your account will
              undergo a manual verification process.
            </div>
          </div>
        </div>

        <div className="stat_content_data stat_content_avg row">
          <div className="stat_content_data-title">
            <h4>Business Details</h4>
          </div>

          <div className="stat_content_data-content">
            <div className="column">
              <div className="row top">
                <label htmlFor="businessEntityName">Business Entity Name</label>
                <p>{this.props?.verification?.businessEntityName}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The legal name of your business, as found on the incorporation
                  documents. This does not have to match the name of your
                  business on your website or marketing material
                </p>
              </div>
            </div>
            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="source">
                  Source
                </label>
                <p>{this.props?.verification?.source}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The source of the verification process. We have 3rd party
                  auditors or internal processes{" "}
                </p>
              </div>
            </div>

            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="status">
                  Status
                </label>
                <p>{isActive}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The current legal status of your business. In cases where an
                  audit finds your business inactive, your account will be
                  suspended until the legal verification is ascertained.
                </p>
              </div>
            </div>

            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="jurisdiction">
                  Jurisdiction of Formation
                </label>
                <p>{this.props?.verification?.jurisdiction}</p>
              </div>
              <div className="bottom light">
                <p> The legal jusrisdiction of your incorporation documents</p>
              </div>
            </div>
            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="companyId">
                  Company ID
                </label>
                <p>{this.props?.verification?.companyId}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The code granted by your incorporation jurisdiction,
                  identifying your company uniquely
                </p>
              </div>
            </div>

            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="dateOfFormation">
                  Date of Formation
                </label>
                <p>{this.props?.verification?.dateOfFormation}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The initial date of formation of the business, as presented on
                  your incorporation documents
                </p>
              </div>
            </div>

            <div className="column">
              <div className="row top">
                <label className="vendor-info" htmlFor="lastUpdated">
                  Last Updated
                </label>
                <p>{this.props?.verification?.lastUpdated}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The last time an audit was performed and this information
                  reviewed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
