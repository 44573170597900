import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  GetNowTimeStamp,
  GetRefFromPath,
  getTimestampFromMilliseconds,
} from "../globals/services";
import SpDialog, { SpDialogContent, SpDialogHead } from "../globals/SpDialog";
import { MdAddCircle, MdClear, MdVerified } from "react-icons/md";
import EndorsmentBadge2 from "../svgs/EndorsementBadge2";

export default function EligibilityTemplate(args) {
  const [eligibilityData, setEligibilityData] = useState({});
  const [showAddEligibility, setShowAddEligibility] = useState(false);
  const [newEligibility, setNewEligibility] = useState({
    label: "",
    location: "",
    eligibility: {
      eligibilityTypeLabel: "",
      startTime: null,
      endTime: null,
    },
  });
  const [newRemoteConnection, setNewRemoteConnection] = useState({
    downloadSpeed: 0,
    jitter: 0,
    ping: 0,
    uploadSpeed: 0,
    lastUpdated: GetNowTimeStamp(),
  });
  const [isNetwork, setIsNetwork] = useState(false);

  useEffect(() => {
    if (args?.args) setEligibilityData(args?.args?.availability ?? {});
  }, [args?.args]);

  const handleChange = (value, key, key1) => {
    let tempData = { ...(newEligibility ?? {}) };
    if (key1 === "") {
      tempData[key] = value;
    } else {
      tempData[key][key1] = value;
    }
    setNewEligibility(tempData);
  };
  const handleNetworkChange = (key, value) => {
    let temp = { ...newRemoteConnection };
    temp[key] = isNaN(Number.parseFloat(value)) ? 1 : Number.parseFloat(value);
    setNewRemoteConnection({ ...temp });
  };

  const resetEligibilityForm = () => {
    setNewEligibility({
      label: "",
      location: "",
      eligibility: {
        eligibilityTypeLabel: "",
        startTime: null,
        endTime: null,
      },
    });
    setShowAddEligibility(false);
  };

  const onClose = useCallback(() => {
    setShowAddEligibility(false);
  }, []);

  const renderStartToEnd = (start, end) => {
    if (!start && !end) return "Unavailable";
    // return date in format  12/10/23 to 12/11/25
    const startMonth = start.toDate().getMonth() + 1;
    const startDay = start.toDate().getDate();
    const startYear = start.toDate().getFullYear().toString().slice(-2);

    if (end === null) return `since ${startMonth}/${startDay}/${startYear}`;

    const endMonth = end.toDate().getMonth() + 1;
    const endDay = end.toDate().getDate();
    const endYear = end.toDate().getFullYear().toString().slice(-2);
    return `${startMonth}/${startDay}/${startYear} to ${endMonth}/${endDay}/${endYear}`;
  };

  const isEmptyAvailability = useMemo(() => {
    return (
      !eligibilityData?.onSiteLocations?.length &&
      !eligibilityData?.remoteConnectivity
    );
  }, [eligibilityData]);

  return (
    <div className="_sppdevAvail__eligib">
      <SpDialog open={showAddEligibility} locked onClose={onClose}>
        <div className="_spInviteUsers--content">
          <div>
            <h3>Add your details</h3>
          </div>
          {isNetwork ? (
            <div className="sp_col _spInviteUsers--content_form">
              <div>
                <label htmlFor="label">Download Speed</label>
                <input
                  type="number"
                  name="Download Speed"
                  id="downloadSpeed"
                  min={1}
                  value={newRemoteConnection?.downloadSpeed ?? 1}
                  onChange={(e) => {
                    handleNetworkChange("downloadSpeed", e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="visaType">Jitter</label>
                <input
                  type="number"
                  name="Jitter"
                  id="jitter"
                  min={1}
                  value={newRemoteConnection?.jitter ?? 1}
                  onChange={(e) => {
                    handleNetworkChange("jitter", e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="visaType">Ping</label>
                <input
                  type="number"
                  name="Ping"
                  id="ping"
                  min={1}
                  value={newRemoteConnection?.ping ?? 1}
                  onChange={(e) => {
                    handleNetworkChange("ping", e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="startTime">Upload Speed</label>
                <input
                  type="number"
                  name="Upload Speed"
                  id="uploadSpeed"
                  min={1}
                  value={newRemoteConnection?.uploadSpeed ?? 1}
                  onChange={(e) => {
                    handleNetworkChange("uploadSpeed", e.target.value);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="sp_col _spInviteUsers--content_form">
              <div>
                <label htmlFor="label">
                  Country or administrative area
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  value={newEligibility?.label ?? ""}
                  onChange={(e) => {
                    setNewEligibility({
                      ...newEligibility,
                      label: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="visaType">
                  Visa Type
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="text"
                  name="Visa Type"
                  id="visaType"
                  value={
                    newEligibility?.eligibility?.eligibilityTypeLabel ?? ""
                  }
                  onChange={(e) => {
                    handleChange(
                      e.target.value,
                      "eligibility",
                      "eligibilityTypeLabel"
                    );
                  }}
                />
              </div>
              <div>
                <label htmlFor="startTime">
                  start Time
                  <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                </label>
                <input
                  type="date"
                  name="Start Time"
                  id="start"
                  onChange={(e) => {
                    handleChange(
                      getTimestampFromMilliseconds(e.target.valueAsNumber),
                      "eligibility",
                      "startTime"
                    );
                  }}
                />
              </div>
              <div>
                <label htmlFor="endTime">endTime</label>
                <input
                  type="date"
                  name="endTime"
                  id="end"
                  onChange={(e) => {
                    handleChange(
                      getTimestampFromMilliseconds(e.target.valueAsNumber),
                      "eligibility",
                      "endTime"
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div className="sp_row _spInviteUsers--content_actions ">
            <button
              className="sp_btn _invitesend"
              style={{
                backgroundColor: isNetwork
                  ? null
                  : !newEligibility?.eligibility?.startTime ||
                    newEligibility?.label === "" ||
                    newEligibility?.eligibility?.eligibilityTypeLabel === ""
                  ? "grey"
                  : null,
              }}
              disabled={
                isNetwork
                  ? !isNetwork
                  : !newEligibility?.eligibility?.startTime ||
                    newEligibility?.label === "" ||
                    newEligibility?.eligibility?.eligibilityTypeLabel === ""
              }
              onClick={async () => {
                let updates = { availability: { ...eligibilityData } };
                if (isNetwork) {
                  updates.availability["remoteConnectivity"] =
                    newRemoteConnection;
                } else {
                  if (updates?.availability?.onSiteLocations) {
                    updates?.availability?.onSiteLocations.push(newEligibility);
                  } else {
                    updates.availability = {
                      onSiteLocations: [newEligibility],
                    };
                  }
                }
                setEligibilityData({ ...(updates.availability ?? {}) });
                // console.log("updates", updates);
                await GetRefFromPath(`devs/${args.args.id}`).set(updates, {
                  merge: true,
                });
                resetEligibilityForm();
              }}
            >
              {isNetwork ? "Save Remote Connectivity" : "Add Eligibility"}
            </button>
            <button
              className="sp_btn _inviteclose"
              onClick={() => {
                resetEligibilityForm();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </SpDialog>
      <div className="_sppdevAvail__eligib_head">
        <div className="_sppdevAvail__eligib_head__title">
          <h3>Eligibility</h3>
          {/* <EndorsmentBadge2 /> */}
          {args?.renderBadge && args.renderBadge(isEmptyAvailability)}
        </div>
        <p>
          This resource is legally allowed to work in the following
          jurisdictions
        </p>
      </div>
      <div className="_sppdevAvail__eligib_content">
        <div className="_sppdevAvail__eligib_content--element">
          <span>On-Site</span>
          <div className="_sppdevAvail__eligib_content--element-content">
            {eligibilityData?.onSiteLocations?.map((chipData, chipIndex) => {
              return (
                <div>
                  <MdVerified
                    size={16.5}
                    className="icon"
                    style={{
                      fill: "#25C7AA",
                    }}
                  />
                  <p>{chipData?.label ?? ""} :</p>
                  <p>{chipData?.eligibility?.eligibilityTypeLabel ?? ""} </p>
                  <p>
                    {renderStartToEnd(
                      chipData?.eligibility?.startTime,
                      chipData?.eligibility?.endTime
                    )}
                  </p>
                  <MdClear
                    size={16.5}
                    className="icon"
                    style={{
                      fill: "#FF223D",
                    }}
                    onClick={async () => {
                      let updates = { availability: { ...eligibilityData } };
                      updates.availability.onSiteLocations =
                        updates?.availability?.onSiteLocations.filter(
                          (v, i) => i !== chipIndex
                        );
                      setEligibilityData({ ...(updates.availability ?? {}) });
                      await GetRefFromPath(`devs/${args.args.id}`).set(
                        updates,
                        {
                          merge: true,
                        }
                      );
                      setShowAddEligibility(false);
                    }}
                  />
                </div>
              );
            })}
            <button
              onClick={() => {
                setIsNetwork(false);
                setShowAddEligibility(true);
              }}
            >
              <MdAddCircle
                size={20}
                className="icon"
                style={{
                  fill: "#4361E9",
                }}
              />
              ADD ELIGIBILITY
            </button>
          </div>
        </div>

        <div className="_sppdevAvail__eligib_content--element">
          <span>Remote</span>
          <div className="_sppdevAvail__eligib_content--element-content">
            {eligibilityData?.remoteConnectivity && (
              <div>
                <MdVerified
                  size={16.5}
                  className="icon"
                  style={{
                    fill: "#25C7AA",
                    marginRight: "5px",
                    opacity: "1",
                  }}
                />
                <p>
                  {eligibilityData?.remoteConnectivity?.downloadSpeed ?? ""}Mbps{" "}
                </p>
                <p>Verified</p>
              </div>
            )}
            <button
              onClick={() => {
                setNewRemoteConnection({
                  downloadSpeed:
                    eligibilityData?.remoteConnectivity?.downloadSpeed ?? 1,
                  jitter: eligibilityData?.remoteConnectivity?.jitter ?? 1,
                  ping: eligibilityData?.remoteConnectivity?.ping ?? 1,
                  uploadSpeed:
                    eligibilityData?.remoteConnectivity?.uploadSpeed ?? 1,
                });
                setIsNetwork(true);
                setShowAddEligibility(true);
              }}
            >
              <MdAddCircle
                size={20}
                className="icon"
                style={{
                  fill: "#4361E9",
                }}
              />
              UPDATE SPEEDTEST
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
