import { Delete } from "@mui/icons-material";
import React from "react";
//https://reactjsexample.com/react-component-confirm-dialog/
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function DeleteComponent(props) {
  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Are you want to delete ${props.fileName}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => props.delete(props.id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <div className="delete">
      <Delete onClick={submit} />
    </div>
  );
}
