import React from "react";
import SpLoadingBtn from "./SpLoadingBtn";
import Portal from "./globals/Portals";

const SpModal = (props) => {
  const [active, setActive] = React.useState(false);
  const { open, onClose, locked } = props;
  const backdrop = React.useRef(null);

  React.useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className="spModal">
          <div
            ref={backdrop}
            className={`backdrop ${active && open && "active"}`}
          >
            <div className="content modal-content">{props.children}</div>
          </div>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default SpModal;

export const SpModalHead = (props) => {
  const { title, desc, className } = props;
  return (
    <div className={`${className} SpModalHead`}>
      <h3>{title}</h3>
      <p>{desc}</p>
    </div>
  );
};

export const SpModalFooter = (props) => {
  const {
    onSubmit,
    onClose,
    submitLabel,
    closeLabel,
    loading,
    showSub = true,
    className,
    disabled = false,
  } = props;
  return (
    <div className={`${className} SpModalFooter`}>
      {showSub && (
        <SpLoadingBtn
          loading={loading}
          loadingText="saving..."
          btnClicked={onSubmit}
          btnText={submitLabel}
          disabled={disabled}
        />
      )}
      <button className="spclose" onClick={() => onClose()}>
        {closeLabel}
      </button>
    </div>
  );
};
