import { useState } from "react";
import FilePreview from "./FilePreview";

const DocumentUploader = (props) => {
  const storage = props.firebase.getStorage();
  const [files, setFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  /**
   * triggers when user click on the open button after selecting files
   * loop through all files the add them to the files sstate
   * @param {event} e event when clicking on the open when selecting files
   *
   */
  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (files) {
      let fileList = [];
      for (let file of files) {
        fileList.push(file);
      }
      setFiles(fileList);
      setIsFileSelected(true);
    } else {
      setIsFileSelected(false);
    }
    e.persist();
    document.getElementById("doc_uploadder").value = "";
  };
  /**
   * removes files with the given index
   * @param {number} i index of the file to be deleted
   */
  const delFile = (i) => {
    const rest = files.filter((item, index) => index !== i);
    setFiles(rest);
  };

  /**
   * triggers when users click on save when trying to upload new files
   * Loops through the Array of the selected file the upload the files to firebase
   * @returns Array of the object with document name and document url
   */
  const uploadFiles = async () => {
    if (files.length) {
      let filesToUpload = [];
      for (let file of files) {
        const ref = await storage
          .ref(`devs/${props.dev.id}/docs/${file.name}`)
          .put(file);
        const url = await ref.ref.getDownloadURL();
        filesToUpload.push({ docName: file.name, docUrl: url });
      }
      return filesToUpload;
    }
  };

  /**
   * Triggers when users click on save then call the uploadFiles function
   * then updates the selected user doc
   */
  const onSave = () => {
    setIsSaving(true);
    uploadFiles().then((filesToUpload) => {
      let updates = {};
      let devDocs = props.dev?.docs ?? [];
      updates["docs"] = [...filesToUpload, ...devDocs];
      props.firebase.updateDev(props.dev.id, updates);

      setIsFileSelected(false);
      setIsSaving(false);
    });
  };

  return (
    <div className="docUpload">
      {isFileSelected ? (
        <FilePreview
          files={files}
          isFileSelected={isFileSelected}
          setIsFileSelected={setIsFileSelected}
          delFile={delFile}
          onSave={onSave}
          isSaving={isSaving}
        />
      ) : (
        <></>
      )}
      <input
        type="file"
        id="doc_uploadder"
        onChange={(e) => handleFileSelect(e)}
        // accept=".png, .jpg, .jpeg"
        hidden
        multiple
      />
    </div>
  );
};
export default DocumentUploader;
