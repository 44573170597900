import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import { CircularProgress, Rating, Switch, Tooltip } from "@mui/material";

import SpTruncatedText from "../globals/SpTruncatedText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDevAssessements } from "../hooks/useDev";
import DevAssessement from "../pages/Resources/Profile/components/DevAssessement";
import { calculateScoreFromPercentile } from "../globals/helpers";

const SingleTechRow = ({
  item,
  itemData,
  handleSliderChange,
  updateSliderValue,
  handleIconClick,
  handleDelete,
  handleCancel,
  showOverlay,
  toggleAssessmentVisibility,
  selectedItemId,
  filteredAssessments,
  onCreateStackAssessment,
  devId,
  devData,
  stackAssessmentData,
  clickedStacksAssessment,
  stackAssessmentsLoading,
  setTechStackData,
  setIsOutSideBtnClicked,
  setChatBtnClickCount
}) => {
  const renderScore = useMemo(() => {
    if (!item?.assessmentScore) return null;

    const scoreValue = calculateScoreFromPercentile(
      item?.assessmentScore ?? 0,
      5
    );
    return Math.round(scoreValue * 100) / 100;
  }, [item?.assessmentScore]);

  const isAStackBeingAssessed = useMemo(() => {
    return clickedStacksAssessment?.[item?.id] || false;
  }, [clickedStacksAssessment, item?.id]);
  // console.log("item: ", item);

  // handler for the "Retake" button
  const handleRetake = (item) => {
    setTechStackData(item);
    setIsOutSideBtnClicked(true);
    setChatBtnClickCount((prevCount) => prevCount + 1);
  };

  return (
    <div key={item.id} className="_spTechstackGridSingleRow">
      <div className={`_spTechstackGrid-row`}>
        <div className="_spTechstackGrid-row--item" key={item.id}>
          <div className="_spTechstackGrid-item--col circle-column">
            <div className="_spTechstackGrid-item--circle circle">
              <img
                src={itemData?.logoStorage}
                alt={itemData?.name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://via.placeholder.com/150x150.png?text=No+Image";
                }}
                // onLoad={(e) => {
                //   e.target.onerror = null;
                //   e.target.src =
                //     "https://via.placeholder.com/150x150.png?text=No+Image";
                // }}
              />
            </div>
            {itemData?.rank && (
              <div className="_spTechstackGrid-item--text">
                <span>{` #${itemData?.rank}`}</span>
              </div>
            )}
          </div>

          <div className="_spTechstackGrid-item--desc-col desc-col">
            <div className="_spTechstackGrid-item--desc-col__infoText">
              <div className="_spTechstackGrid-item--title title">
                <span className="_spTechstackGrid-item--main-lang main-lang">
                  {itemData?.name}
                </span>{" "}
                |{" "}
                <span className="_spTechstackGrid-item--lang">
                  {itemData?.category || itemData?.subcategory}
                </span>
                <div className="_spTechstackGrid-item--interest sp_row">
                  <span className="interest--text">Interest</span>
                  <Switch
                    checked={item.interest === "Interested"}
                    onChange={(event) => {
                      updateSliderValue(item, {
                        interest: event.target.checked
                          ? "Interested"
                          : "Not Interested",
                      });
                      handleSliderChange(item, {
                        interest: event.target.checked
                          ? "Interested"
                          : "Not Interested",
                      });
                    }}
                    inputProps={{ "aria-label": "interest switch" }}
                  />
                  <span className="interest--text">
                    {item.interest === "Interested"
                      ? "Interested"
                      : "Not Interested"}
                  </span>
                </div>
              </div>
              <div className="_spTechstackGrid-item--desc">
                {/* <p>{item.description}</p> */}
                <SpTruncatedText
                  text={itemData?.description}
                  maxLines={2}
                  readMoreText="read more"
                  useLink={true}
                />
              </div>
            </div>

            <div className="_spTechstackGrid-item--details">
              <div className="_spTechstackGrid-item--details-skills">
                <label htmlFor="_spTechstackGrid-item--skills-label detailslabel levels">
                  skill:
                </label>
                <div className="_spTechstackGrid-item--details-skills_stars">
                  <Rating
                    name="simple-controlled"
                    value={item?.skill ?? 0}
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      updateSliderValue(item, { skill: newValue });
                    }}
                    size="large"
                    max={4}
                  />
                </div>
              </div>
              <div className="_spTechstackGrid-item--details-experience">
                <label htmlFor="_spTechstackGrid-item--details-labels _spTechstackGrid-item--skills-label levels">
                  experience:
                </label>
                <div className="_spTechstackGrid-item--details-data">
                  <Slider
                    min={0}
                    max={20}
                    step={1}
                    value={(item.experience / 12).toFixed(2)}
                    onChange={(event, newValue) =>
                      handleSliderChange(item, {
                        experience: newValue * 12,
                      })
                    }
                    onChangeCommitted={(event, newValue) =>
                      updateSliderValue(item, {
                        experience: newValue * 12,
                      })
                    }
                    valueLabelDisplay="on"
                  />
                </div>
              </div>
              <div className="_spTechstackGrid-item--details-projects">
                <label htmlFor="_spTechstackGrid-item--details-labels _spTechstackGrid-item--skills-label levels">
                  projects:
                </label>
                <div className="_spTechstackGrid-item--details-data">
                  <Slider
                    min={0}
                    max={20}
                    step={1}
                    value={item.projects}
                    onChange={(event, newValue) =>
                      handleSliderChange(item, { projects: newValue })
                    }
                    onChangeCommitted={(event, newValue) =>
                      updateSliderValue(item, { projects: newValue })
                    }
                    valueLabelDisplay="on"
                  />
                </div>
              </div>
            </div>
          </div>

          {item?.techData?.assessments?.length > 0 &&
          !item?.assessments?.length &&
          devData?.userRef ? (
            <div className="_spTechstackGrid-item--gridcards grid-cards">
              <div className="_spTechstackGrid-item--gridcard-card grid-card">
                <p>Not Assessed</p>
                <div
                  className="_spTechstackGrid-item--gridcard-assessments"
                  onClick={() => {
                    if (isAStackBeingAssessed) return;

                    item?.techData?.assessments?.length > 1
                      ? toggleAssessmentVisibility(item)
                      : onCreateStackAssessment(
                          item?.techData?.assessments?.[0]?.ref,
                          item?.ref
                        );
                  }}
                >
                  <p>Take an Assessment</p>

                  {item?.techData?.assessments?.length > 1 &&
                    !isAStackBeingAssessed && (
                      <div className="_spTechstackGrid-item--gridcard-assessments_icon">
                        {selectedItemId === item.id ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    )}

                  {isAStackBeingAssessed && (
                    <div className="_spTechstackGrid-item--gridcard-assessments_icon">
                      <CircularProgress
                        size={14}
                        style={{
                          color: "#fff",
                        }}
                      />
                    </div>
                  )}
                </div>
                {selectedItemId === item.id && !isAStackBeingAssessed && (
                  <div className="_spTechstackGrid-item--gridcard-assessments_single">
                    <div className="assessments">
                      {filteredAssessments[item.id]
                        ?.sort((a, b) => a.rank - b.rank)
                        ?.map((assessment) => (
                          <p
                            key={assessment.id}
                            onClick={() =>
                              onCreateStackAssessment(
                                assessment?.ref,
                                item?.ref
                              )
                            }
                          >
                            {assessment.name}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
                <div className="_spTechstackGrid-item--gridcard-info info">
                  <p>Getting assessed helps you get a project faster</p>

                  <div className="_spTechstackGrid-item--gridcard-info-icon opp-icon">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="_spTechstackGrid-item--gridcards-descIcon"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : item?.assessments?.length > 0 && devData?.userRef ? (
            <div className="_spTechstackGrid-item--gridcards grid-cards">
              <div className="_spTechstackGrid-item--gridcard-card grid-card">
                <div className="_spTechstackGrid-item--gridcards-assedTitle">
                  Skill Ranking
                </div>
                <div className="_spTechstackGrid-item--gridcards-assedScore">
                  {renderScore || "?"} / 5
                </div>
                <div className="_spTechstackGrid-item--gridcards-assedFooter">
                  <div
                    className="_spTechstackGrid-item--gridcards-assedFooter__retakeBtn"
                    onClick={() => handleRetake(item)}
                  >
                    Retake?
                  </div>
                  {/* i icon material ui */}
                  <Tooltip
                    title="This is your video assessment score. Make sure you've completed the entire assessment to get a valid score!"
                    placement="top"
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="_spTechstackGrid-item--gridcards-descIcon"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="_spTechstackGrid-row-deleteicon delete-icon">
          {selectedItemId === item.id && showOverlay && (
            <div className="_spTechstackGrid-row-overlay overlay">
              <p>Are you sure?</p>
              <div className="_spTechstackGrid-row--container btn-container">
                <button
                  className="_spTechstackGrid-row-btndelete btn-delete"
                  onClick={() => handleDelete(item)}
                >
                  Yes, Delete
                </button>
                <button
                  className="_spTechstackGrid-row-btncancel btn-cancel"
                  onClick={handleCancel}
                >
                  No, Cancel
                </button>
              </div>
            </div>
          )}
          <FontAwesomeIcon
            icon={faTimes}
            className="_spTechstackGrid-row-close-icon"
            onClick={() => handleIconClick(item.id)}
          />
        </div>
      </div>
      {stackAssessmentData?.[item?.id]?.length > 0 ? (
        <DevAssessement
          data={[stackAssessmentData[item?.id]?.[0]]}
          userRef={devData?.userRef}
        />
      ) : (clickedStacksAssessment?.[item?.id] ||
          stackAssessmentsLoading?.[item?.id]) &&
        !stackAssessmentData?.[item?.id]?.length ? (
        <div
          style={{
            height: "240px",
            width: "100%",
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            size={14}
            style={{
              color: "#000",
            }}
          />
          <p>Assessment is being loaded...</p>
        </div>
      ) : null}
    </div>
  );
};

export default SingleTechRow;
