import React from "react";
import { SampleBase } from "../../sample-base";
import EditSkill from "./EditSkill";

export default class ConfirmDevSkills extends SampleBase {
  render() {
    return (
      <div className="confirmDevSkills">
        {Object.entries(this.props.skillsToBeAdded).map((entries, i) => {
          const [skillType, skills] = entries;
          if (Object.values(skills).length === 0) {
            return;
          } else {
            let displaySkillType;
            if (skillType === "stacks") {
              displaySkillType = "Tech Stacks";
            } else if (skillType === "categories") {
              displaySkillType = "Project Type";
            } else if (skillType === "sectors") {
              displaySkillType = "Domains";
            }
            return (
              <table className="confirmDevSkills_table" key={i}>
                <thead className="confirmDevSkills_table_head">
                  <tr className="table_row">
                    <th className="table_row_head">
                      <h3>{displaySkillType}</h3>
                    </th>
                    <th className="table_row_head">
                      <h5>Skill Level</h5>
                    </th>
                    <th className="table_row_head">
                      <h5>Experience</h5>
                    </th>
                    <th className="table_row_head">
                      <h5>Projects</h5>
                    </th>
                    {/* <th className="table_row_head"><h5>Interest</h5></th> */}
                    <th className="table_row_head">
                      <h5>Import or Exclude</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(skills).map((skill, index) => {
                    let skillName;
                    if (skill.tech) {
                      skillName = skill.tech;
                    } else if (skill.domain) {
                      skillName = skill.domain;
                    } else if (skill.projType) {
                      skillName = skill.projType;
                    }

                    return (
                      <EditSkill
                        key={index}
                        resumeName={this.props.resumeName}
                        skillName={skillName}
                        skill={skill}
                        skillType={skillType}
                        editSkill={this.props.editSkill}
                        setExperienceUnit={this.props.setExperienceUnit}
                        confirmSkill={this.props.confirmSkill}
                      />
                    );
                  })}
                </tbody>
              </table>
            );
          }
        })}
      </div>
    );
  }
}
