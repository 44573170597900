import React from "react";
import { Routes, Route } from "react-router-dom";
import { FirebaseContext, GlobalVendorContex } from "../Firebase";
import Contract from "../Contract";
import Markets from "../Market";
import InternsTest from "../InternsTest";
import Branding from "../Branding";
import Dashboard from "../Dashboard";
import Avail from "../myapp/Avail";
import Requirements from "../Requirements";
import SingleListRequirement from "../SingleListRequirement";
import ColManagement from "../collectionManagement";
import AllResources from "../AllResources";
import PartnersManagement from "../PartnersManagement";
import IndexItems from "../pages/indexItems/IndexItems";
import DataPoint from "../pages/dataPoint/DataPoint";
import Certification from "../Certifications";
import Projects from "../ProjectsPage";
import ResourcesProvider from "../Firebase/resources/ResourcesContext";
import Resource from "../Resource";
import Account from "../Account";
import MainDev from "../ResourceSections/MainDev";

const LoggedInVendors = ({
  authUser,
  devPage,
  setDevPage,
  showDevData,
  setShowDevData,
  setIsDup,
  isRequest,
  setIsRequest,
  setIsVerifiedResource,
  isVerifiedResource,
  isAllDevs,
  setIsAllDev,
  isDup,
  setPublicChatData,
  setChatBtnClickCount,
  setIsOutSideBtnClicked,
  setTechStackData
}) => {
  return (
    <Routes>
      <Route
        path="/contract"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => <Contract firebase={firebase} vendor={authUser} />}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/markets"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => <Markets firebase={firebase} vendor={authUser} />}
          </FirebaseContext.Consumer>
        }
      />

      <Route
        path="/branding"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => <Branding firebase={firebase} vendor={authUser} />}
          </FirebaseContext.Consumer>
        }
      />

      <Route
        path="/dashboard"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => <Dashboard firebase={firebase} user={authUser} />}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/availability"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Avail firebase={firebase} isSuper={authUser.isSuperPartner} />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/requirements"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Requirements firebase={firebase} vendor={authUser} />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/requirements/:listId"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <SingleListRequirement firebase={firebase} vendor={authUser} />
            )}
          </FirebaseContext.Consumer>
        }
      />
      {authUser.isSuperPartner && (
        <Route
          path="/collection-management"
          element={
            <FirebaseContext.Consumer>
              {(firebase) => (
                <ColManagement firebase={firebase} vendor={authUser} />
              )}
            </FirebaseContext.Consumer>
          }
        />
      )}

      {authUser.isSuperPartner && (
        <Route
          path="/all-resources"
          element={
            <FirebaseContext.Consumer>
              {(firebase) => (
                <AllResources
                  firebase={firebase}
                  vendor={authUser}
                  devPage={devPage}
                  setDevPage={setDevPage}
                  showDevData={showDevData}
                  setShowDevData={setShowDevData}
                  setIsDup={setIsDup}
                  isRequest={isRequest}
                  setIsRequest={setIsRequest}
                  setIsVerifiedResource={setIsVerifiedResource}
                  isVerifiedResource={isVerifiedResource}
                  isAllDevs={isAllDevs}
                  setIsAllDev={setIsAllDev}
                />
              )}
            </FirebaseContext.Consumer>
          }
        />
      )}

      {authUser.isSuperPartner && (
        <Route
          path="/partners"
          element={
            <FirebaseContext.Consumer>
              {(firebase) => (
                <PartnersManagement firebase={firebase} vendor={authUser} />
              )}
            </FirebaseContext.Consumer>
          }
        />
      )}

      {authUser.isSuperPartner && (
        <Route
          path="/indexitems"
          element={
            <FirebaseContext.Consumer>
              {(firebase) => <IndexItems firebase={firebase} user={authUser} />}
            </FirebaseContext.Consumer>
          }
        />
      )}

      {authUser.isSuperPartner && (
        <Route
          path="/datapoints"
          element={
            <FirebaseContext.Consumer>
              {(firebase) => <DataPoint firebase={firebase} user={authUser} />}
            </FirebaseContext.Consumer>
          }
        />
      )}
      <Route
        path="/certification"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Certification
                firebase={firebase}
                vendor={authUser.vendor}
                domainExperiences={authUser.domainExperiences}
                domainExperiencesObject={authUser.domainExperiencesObject}
                accountFound={authUser.accountFound}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />

      <Route
        path="/projects"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Projects vendor={authUser.vendor} firebase={firebase} />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/resources"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <GlobalVendorContex.Consumer>
                {(userData) => (
                  <ResourcesProvider>
                    <Resource
                      showDevData={showDevData}
                      setShowDevData={setShowDevData}
                      firebase={firebase}
                      devPage={devPage}
                      setDevPage={setDevPage}
                      isDup={isDup}
                      setIsDup={setIsDup}
                      isRequest={isRequest}
                      setIsRequest={setIsRequest}
                      userData={userData}
                      allData={authUser}
                      updateVendor={authUser.isVendorUpdated}
                      setIsVerifiedResource={setIsVerifiedResource}
                      isVerifiedResource={isVerifiedResource}
                    />
                  </ResourcesProvider>
                )}
              </GlobalVendorContex.Consumer>
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/resources/:id"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <MainDev
                firebase={firebase}
                auth={authUser}
                showDevData={showDevData}
                setShowDevData={setShowDevData}
                devPage={devPage}
                setDevPage={setDevPage}
                isRequest={isRequest}
                setIsRequest={setIsRequest}
                setIsOutSideBtnClicked={setIsOutSideBtnClicked}
                setChatBtnClickCount={setChatBtnClickCount}
                setTechStackData={setTechStackData}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />
      <Route
        path="/"
        element={
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Account
                firebase={firebase}
                isSuper={authUser.isSuperPartner}
                updateVendor={authUser.isVendorUpdated}
                setPublicChatData={setPublicChatData}
                setChatBtnClickCount={setChatBtnClickCount}
              />
            )}
          </FirebaseContext.Consumer>
        }
      />
      {/* <Route element={ } /> */}
      {/* <Redirect from='/' to='/dashboard' /> */}
      {/* <Redirect from='/' to='/resource' /> */}
    </Routes>
  );
};

export default LoggedInVendors;
