import React from "react";
import { MdClose } from "react-icons/md";
import Portal from "../../Portals";
import { CloseIcon, DialogContent, DialogWrapper } from "./style";

/**
 * when using this component, if `useCustomBox` is true
 *  make sure to add the following `className` to the body
 * of your dialog to have the animation
 * work properly: `"modal-content"`
 *
 * @param param0
 * @returns
 */
export const SpDialog = ({
  open,
  onClose = null,
  locked = false,
  cls = "",
  children,
  closeIconColor = "#4361E9",
  useCustomBox = false,
  showCloseIcon = true,
  ...rest
}) => {
  const [active, setActive] = React.useState(false);
  const backdrop = React.useRef(null);

  React.useEffect(() => {
    if (!backdrop?.current) return;
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);
  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className="spfModal" {...rest}>
          <DialogWrapper
            ref={backdrop}
            className={` ${cls} ${active && open && "active"}`}
          >
            {!useCustomBox ? (
              <DialogContent className={`${cls}_content modal-content`}>
                {showCloseIcon && (
                  <CloseIcon
                    onClick={() => onClose()}
                    className={`${cls}_content--close`}
                  >
                    <MdClose size={25} fill={closeIconColor} />
                  </CloseIcon>
                )}
                {children}
              </DialogContent>
            ) : (
              children
            )}
          </DialogWrapper>
        </Portal>
      )}
    </React.Fragment>
  );
};
