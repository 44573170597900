import React from "react";

const ProjIcon = ({ w = "17", h = "16", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.20626 14.5111C0.465337 14.1699 0.377381 13.9636 0.33305 12.4661C0.288018 10.9337 0.529823 10.5592 1.73817 10.2912L2.3799 10.1487L2.27778 6.35726C2.19809 3.39897 2.24636 2.48075 2.49764 2.17883C2.7696 1.8522 3.19636 1.77261 5.23989 1.66756C7.42713 1.55506 7.66734 1.50511 7.7347 1.14726C7.90458 0.24632 8.39614 0.0538467 10.5858 0.0306045C13.2664 0.00215093 13.6161 0.244951 13.668 2.17068C13.7199 4.0964 13.3834 4.34649 10.7028 4.37494C8.51339 4.39818 8.01175 4.21625 7.79298 3.31943C7.70748 2.96894 7.48365 2.92134 5.76178 2.88866L3.82663 2.85172L3.88536 5.03198L3.94409 7.21205L5.93894 7.19088C7.89788 7.17008 7.93355 7.16101 7.92079 6.68728C7.8992 5.88575 8.69152 5.58145 10.86 5.55843C12.6608 5.53932 12.818 5.57142 13.2859 6.05316C13.6732 6.45181 13.7941 6.85269 13.8203 7.82523C13.8694 9.64807 13.5457 9.8755 10.8609 9.904C8.66163 9.92734 8.16095 9.74639 7.94192 8.84859C7.85642 8.4981 7.63259 8.4505 5.91071 8.41781L3.97557 8.38087L3.99915 9.25606C4.02043 10.0463 4.08574 10.1438 4.67177 10.2601C5.89929 10.5034 6.15539 10.8691 6.19768 12.4388C6.23313 13.7551 6.18565 13.9097 5.62898 14.2907C5.14124 14.6248 4.69277 14.7089 3.35164 14.7184C2.43321 14.7248 1.46773 14.6315 1.20626 14.5111ZM1.73816 12.4699L1.71423 11.5813L3.12236 11.5664L4.53049 11.5514L4.55442 12.44L4.57836 13.3286L3.17023 13.3436L1.7621 13.3585L1.73816 12.4699ZM9.36053 7.8459L9.3366 6.95729L10.7447 6.94234L12.1529 6.92739L12.1768 7.81601L12.2007 8.70462L10.7926 8.71957L9.38447 8.73451L9.36053 7.8459ZM9.32146 2.92692C9.24789 2.77521 9.21328 2.35327 9.24504 1.98948C9.30196 1.33151 9.30914 1.32768 10.6502 1.25458L11.9981 1.18102L12.0249 2.17824L12.0518 3.17546L10.7537 3.18924C9.85534 3.19878 9.41417 3.11793 9.32146 2.92692Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjIcon;
