import React, { useState, useEffect, useCallback, useMemo } from "react";
// import "./rating.css";
import ProgressBar from "react-customizable-progressbar";
import { Rating } from "@mui/material";
import { GetRefFromPath, addDocument, getCurrentUser } from "../services";
import SpDialog from "../SpDialog";
import RateStar from "../../svgs/RateStar";

function SpRatingComponent({ dataInput, showStart = true, onOpen, onClose }) {
  const [state, setState] = useState(false);

  const [Note, setNote] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [allow, setAllow] = useState(false);

  const [RatingType, setRatingType] = React.useState(null);
  const [mapRating, setmapRating] = React.useState({});

  const [verticals, setVerticals] = React.useState([]);

  const [allowComment, setallowComment] = React.useState(true);
  const [label, setLabel] = React.useState(null);
  const [user, setUser] = useState();

  const indicator = useMemo(() => {
    let indic = (
      Object.values(mapRating).reduce((partialSum, a) => partialSum + a, 0) /
      Object.values(mapRating)
        ?.filter((x) => x !== 0)
        ?.filter((x) => x !== null)?.length
    ).toFixed(2);
    //
    return isNaN(indic) ? 0 : indic;
  }, [mapRating]);

  useEffect(() => {
    const getUser = async () => {
      let _user = await getCurrentUser();
      setUser(_user);
    };
    getUser();
    return dataInput?.typeRef?.onSnapshot((doc) => {
      if (doc.exists) {
        let ratingData = doc.data();
        setRatingType(ratingData ?? {});
        setLabel(ratingData?.label ?? "");
        setVerticals(ratingData?.verticals ?? []);

        let ratingMap = {};

        ratingData?.verticals?.forEach((item) => {
          ratingMap[item.keyName] = 0;
        });
        setmapRating(ratingMap);
        setallowComment(ratingData?.allowComment);
        setNote("");
      }
    });
  }, []);

  const createDoc = async () => {
    if (indicator === 0) {
      seterrorMessage("Please choose a rating between 1 and 4!");
    } else {
      let s = mapRating;
      s["average"] = parseFloat(indicator);
      let data = {
        author: GetRefFromPath(user?.path),
        createdOn: new Date(),
        lastUpdated: new Date(),
        note: Note,
        type: dataInput?.typeRef,
        rating: s,
      };

      await addDocument(`${dataInput?.ref?.path}/rating/`, data);

      setmapRating({});
      setNote("");
      setAllow(false);
      setState(false);
      onClose && onClose();
    }
  };

  const onchangefun = (keyName, nv) => {
    let mape = { ...mapRating };
    mape[keyName] = nv;
    setmapRating(mape);
    if (indicator) {
      setAllow(true);
    }
  };

  const onModalClose = useCallback(() => {
    setmapRating({});
    setNote("");
    setAllow(false);
    setState(false);
    seterrorMessage("");
    onClose && onClose();
  }, []);

  return (
    <>
      {/* Button Rate To Open The Modal  */}
      {showStart && (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginLeft: "10px",
          }}
          onClick={() => {
            if (dataInput?.isAdmin) setState(true);
          }}
        >
          <h2>{dataInput?.rating}</h2>
          <RateStar size={"32px"} fill="black" stroke="black" />
        </div>
      )}

      {/* Modal Section  */}

      <SpDialog open={state || onOpen} locked onClose={onModalClose}>
        <div style={{ display: "block", padding: 10 }} className="d">
          <h1>{label}</h1>
          <br />
          <br />
          {/* Rating Stars  */}
          <div className="rating-container">
            <div className="rating-stars">
              <div className="rating-row">
                {verticals.map((ite, i) => {
                  let item = { ...ite };
                  let ma = RatingType?.max;

                  return (
                    <div className="col" key={`${item?.keyName}_${i}`}>
                      <h4>{item?.label}</h4>
                      <Rating
                        // defaultValue={mapRating?.[item?.label] ?? 0}
                        value={mapRating?.[item?.keyName] ?? 0}
                        // name="simple-controlled"
                        style={{ fontSize: "30px" }}
                        onChange={(event, nv) => {
                          onchangefun(item?.keyName, nv);
                        }}
                        size="large"
                        max={ma}
                        name="half-rating"
                        // defaultValue={2.5}
                        precision={0.1}
                        sx={{
                          "& .MuiRating-iconFilled": {
                            color: "#6349FF",
                          },

                          "& .MuiRating-iconHover": {
                            color: "#6349FF",
                          },

                          "& .MuiRating-iconEmpty": {
                            color: "#E0E0E0",
                          },

                          // make size of the stars bigger 45px
                          "& .MuiRating-icon": {
                            fontSize: "25px",
                          },
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="average">
              <div className="average-box">
                <ProgressBar
                  radius={60}
                  progress={indicator * (100 / 4)}
                  strokeWidth={2}
                  fillColor="#F7FAFC"
                  strokeColor="#0057b3"
                  trackStrokeWidth={2}
                  pointerRadius={4}
                  pointerStrokeWidth={2}
                  pointerStrokeColor="#0057b3"
                >
                  <div className="indicator">
                    <h1>{!indicator ? 0 : indicator}</h1>
                  </div>
                </ProgressBar>
              </div>{" "}
            </div>
          </div>
          {/* Note Input Field  */}

          <br />
          {allowComment && (
            <div>
              <br />
              <p style={{ color: "grey", marginBottom: "18px" }}>
                Do you have any thoughts you'd like to share?
              </p>

              <textarea
                // defaultValue={Note ?? " "}
                value={Note ?? " "}
                style={{ height: "150px", width: "100%", padding: 15 }}
                onInput={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          )}
          <br />

          {/* Submit Button To send The Rating */}

          <div component="fieldset" mb={5} borderColor="transparent">
            <p style={{ color: "#8FBC8F" }}>{errorMessage}</p>

            <button
              className="e-control e-btn"
              onClick={createDoc}
              style={{
                background: "#1E96F5",
                color: "white",
                marginLeft: "auto",
                width: 80,
                height: 30,
              }}
              {...(!allow && { disabled: true })}
            >
              Send
            </button>
          </div>
        </div>
      </SpDialog>
    </>
  );
}

export default SpRatingComponent;
