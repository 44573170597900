import React from "react";

const AboutMeIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0968 0.223054C11.8805 1.21367 10.7628 3.96101 11.6931 6.13137C12.11 7.10417 12.1041 7.49281 11.6592 8.31876C11.1625 9.24101 11.5101 9.59587 12.7113 9.39211C13.2327 9.30353 14.4466 9.13142 15.4086 9.00932C18.5762 8.60765 20 7.19035 20 4.43849C20 2.64613 19.3266 1.34987 17.9899 0.569132C16.999 -0.00970297 15.0129 -0.186333 14.0968 0.223054ZM15.3968 2.33357C15.1716 2.77727 15.6782 3.28588 15.9799 2.91879C16.2322 2.61181 16.0744 2.04442 15.7366 2.04442C15.6305 2.04442 15.4777 2.17449 15.3968 2.33357ZM5.12175 3.46783C4.22031 3.96553 3.32258 5.31818 3.14964 6.43914C3.07159 6.94563 3.04426 8.24002 3.08886 9.31576C3.15708 10.9613 3.26727 11.4263 3.78325 12.2496C4.69628 13.7057 4.52356 14.243 2.82147 15.2424C0.850236 16.3998 -0.204251 18.015 0.0329704 19.5135C0.105558 19.9718 0.484456 20 6.55382 20C12.6232 20 13.0021 19.9718 13.0747 19.5135C13.3119 18.015 12.2574 16.3998 10.2862 15.2424C8.58277 14.2422 8.41114 13.7062 9.32679 12.2456C9.90968 11.3159 9.9427 11.1169 9.9427 8.53529C9.9427 5.58712 9.75532 4.987 8.46974 3.81923C7.74605 3.16192 6.00679 2.97944 5.12175 3.46783ZM15.3948 3.98681C15.1489 4.76728 15.3922 6.69957 15.7366 6.69957C16.162 6.69957 16.2136 3.87828 15.7915 3.70697C15.641 3.64605 15.4626 3.77214 15.3948 3.98681Z"
        fill={fill}
      />
    </svg>
  );
};

export default AboutMeIcon;
