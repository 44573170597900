import React from "react";
import styled from "styled-components";

const BoxWrapper = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-around;
  background-color: ${(props) => props.bg};
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  p {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
`;

const PosSepcifications = ({
  bg = "rgba(242, 221, 165, 0.53)",
  cls = "",
  tucPosMap,
}) => {
  return (
    <BoxWrapper className={`${cls} _spNotOpenPos`} bg={bg}>
        {tucPosMap?.contractType && <p>{tucPosMap?.contractType}</p>}
        {tucPosMap?.commuteType && <p>{tucPosMap?.commuteType}</p>}
        {tucPosMap?.dedicationType && <p>{tucPosMap?.dedicationType}</p>}
    </BoxWrapper>
  );
};

export default PosSepcifications;