import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const NonLoggedNav = ({ toggleNavbar, setIsDev }) => {
  return (
    <Fragment>
      <Fragment>
        <Link
          to={"/"}
          onClick={() => {
            setIsDev(false);
          }}
          className={`${toggleNavbar ? "toggled_sign" : ""}`}
        >
          Partner Sign In
        </Link>
        <Link
          to={"/"}
          onClick={() => {
            setIsDev(true);
          }}
          className={`${toggleNavbar ? "toggled_sign" : ""}`}
        >
          Use an Access Code
        </Link>
        <Link
          to={"/requirements"}
          onClick={() => {
            setIsDev(false);
          }}
          className={`${toggleNavbar ? "toggled_sign" : ""}`}
        >
          Requirements
        </Link>
      </Fragment>
    </Fragment>
  );
};

export default NonLoggedNav;
