import React from "react";
import HeaderUnSub from "./UnSubs/HeaderUnSub";
import LogoCarou from "./UnSubs/LogosCarou";

/**
 * UnSubs is the Page of the Unsubscribers
 * @param {*} props are coming from Certifications.js
 */
const UnSubs = (props) => {
  return (
    <div className="unsubs">
      <HeaderUnSub email={props.email} />
      <div className="unsubs-caroussel">
        <h3 className="unsubs-caroussel_header">
          Current Enterprise Clients by Industry Domain
        </h3>
        <LogoCarou domains={props.domainsList} subs={props.subs} />
      </div>
    </div>
  );
};

export default UnSubs;
