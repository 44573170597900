import SpBtn from "../../SpBtn";
import SpChatIcon from "../../SpChatIcon";
import { ActionsWrapper } from "./style";

export const DevActions = ({
  actionsType = false,
  w,
  h,
  p,
  direction = "row",
  bg,
  cls,
  onChat,
  onPick,
  chatText = "Chat",
  pickText = "Pick & Start today",
  showPickBtn = true,
  showChatBtn = true,
  pickDisabled = false,
}) => {
  return (
    <ActionsWrapper
      direction={direction}
      w={w}
      h={h}
      p={p}
      bg={bg}
      className={cls}
    >
      {showChatBtn && (
        <SpBtn showBg={false} cls={`${cls}_chat`} onClick={onChat}>
          {!actionsType && <SpChatIcon />}
          <span>{chatText}</span>
        </SpBtn>
      )}
      {showPickBtn && (
        <SpBtn
          label={pickText}
          cls={`${cls}_pick`}
          onClick={onPick}
          disabled={pickDisabled}
        />
      )}
    </ActionsWrapper>
  );
};
