import React from "react";
import { MdVerified } from "react-icons/md";
import RenderagreedUser from "./RenderagreedUser";

const ReqAgreement = ({ vendorData, lastTerm, setViewTerm }) => {
  return (
    <div className="requirements-agreement">
      <div className="requirements-agreement-content">
        {vendorData?.searchedVendor?.id ? (
          <p>
            Requirements are governed by Spryte's{" "}
            <span
              onClick={() => {
                setViewTerm(true);
              }}
            >
              Partner Terms and Conditions v{lastTerm?.version}
            </span>
          </p>
        ) : (
          <p>
            Open Spryte Requirements are only available to Active Spryte
            Partners. Request to join{" "}
            <a
              href="https://share.hsforms.com/1vu0_YiKxR2aREymCafH3Hg36gxo"
              target={"_blank"}
              rel="noreferrer"
            >
              here
            </a>{" "}
            and a Spryte Account manager will reach out immediatly
          </p>
        )}
      </div>
      {vendorData?.searchedVendor?.id && (
        <div className="requirements-agreement-signature">
          {vendorData.searchedVendor?.terms?.ref?.id === lastTerm?.id ? (
            <p className="requirements-agreement-signBy">
              <MdVerified
                size={16.5}
                className="icon"
                style={{
                  fill: "#2ecc71",
                  marginRight: "5px",
                  opacity: "1",
                }}
              />
              Signed on{" "}
              {vendorData.searchedVendor?.terms?.agreedOn
                ?.toDate()
                .toDateString()}{" "}
              by{" "}
              <RenderagreedUser
                agreeBy={vendorData.searchedVendor?.terms?.agreedBy}
              />
            </p>
          ) : (
            <p className="requirements-agreement-noSign">
              Please Review and accept before moving forward with any open
              requirements
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ReqAgreement;
