import React, { useState } from "react";
import "../../../../css/danRqStyle.css";
import SpFunctionalTable from "../../../globals/SpFunctionalTable";
import { Backdrop, CircularProgress } from "@mui/material";
import MatchInfo from "../../SingleRequirement/requirement/MatchesTab/MatchInfo";
import MatchTopActions from "../../SingleRequirement/requirement/MatchesTab/MatchTopActions";
import ReqTable from "../../../globals/ReqTable/ReqTable";
import MatchTableHead from "./matchesComponents/MatchTableHead";
import SingleMatch from "./matchesComponents/SingleMatch";
import PageTop from "../../../globals/PageTop";
import MatchActions from "./matchesComponents/MatchActions";
import useSortableData from "../../../SortingData.js/Sortable";
import { useDynamicPaginator } from "../../../hooks/useDynamicPaginator";

const filters = [
  "All Matches",
  "Scores above 60%",
  "Requirement Matches",
  "Company Matches",
];
const filterOptions = [
  { value: "all", label: "All Matches" },
  { value: "score", label: "Scores above 60%" },
  { value: "req", label: "Requirement Matches" },
  { value: "company", label: "Company Matches" },
];

const Matches = (props) => {
  const [currentMatches, setCurrentMatches] = useState([]);
  // Variables to rerun the search for developers matching the company with.
  const [sigma, setSigma] = useState(30);
  const [jobLookBack, setJobLookBack] = useState(30);
  const [selectedOption, setSelectedOption] = useState(filterOptions[0]?.value);

  const { items, requestSort } = useSortableData(props?.matches, {
    key: "mScore",
    direction: "descending",
  });

  const { currentData, renderButtons } = useDynamicPaginator(items, 10);

  const handleMatchCriteriaChange = (e) => {
    !isNaN(e.target.value) &&
      !isNaN(parseInt(e.target.value)) &&
      setSigma(parseInt(e.target.value));
  };

  const handlePassCriteriaChange = (e) => {
    !isNaN(e.target.value) &&
      !isNaN(parseInt(e.target.value)) &&
      setJobLookBack(parseInt(e.target.value));
  };

  const onRecompute = () => {
    // If recomputeMatches is true, then the button is disabled.
    if (!props?.devId || props.reComputingMatches) return;

    // Set recomputeMatches to true to disable the button and update the document.
    props.firebase.updateDev(props.devId, {
      matching: { sigma: sigma, jobLookback: jobLookBack, computeMatch: true },
    });
    props.setReComputingMatches(true);
  };

  const handleFilterChange = (e) => {
    setSelectedOption(e.target.value);
    switch (e.target.value) {
      case filterOptions[0]?.value:
        setCurrentMatches(props?.matches);
        break;
      case filterOptions[1]?.value:
        setCurrentMatches(
          props?.matches?.filter((match) => match?.score > 0.6)
        );
        break;
      case filterOptions[2]?.value:
        setCurrentMatches(
          props.matches?.filter(
            (item) => item?.link?.split("/")?.[0] === "client"
          ) ?? []
        );
        break;
      case filterOptions[3]?.value:
        setCurrentMatches(
          props.matches?.filter(
            (item) => item?.link?.split("/")?.[0] === "companies"
          ) ?? []
        );
        break;
      default:
        setCurrentMatches(props?.matches);
        break;
    }
  };

  return props.matchesLoading ? (
    <Backdrop sx={{ marginTop: "25%" }} open={props.matchesLoading}>
      <CircularProgress />
    </Backdrop>
  ) : (
    <div className="spDevMatches">
      <PageTop
        title="Spryte AI Matches"
        subtitle="Best Matches recommended for you"
        cls="spDevMatches__top"
      />

      <MatchActions
        sigma={sigma}
        jobLookBack={jobLookBack}
        handleMatchCriteriaChange={handleMatchCriteriaChange}
        handlePassCriteriaChange={handlePassCriteriaChange}
        onRecompute={onRecompute}
        reComputingMatches={props.reComputingMatches}
        filterOptions={filterOptions}
        selectedOption={selectedOption}
        handleFilterChange={handleFilterChange}
      />

      <div className="_sp-company-data">
        {props?.matchesMetaData && (
          <MatchInfo matchesMetaData={props?.matchesMetaData} />
        )}
      </div>
      <ReqTable>
        <MatchTableHead requestSort={requestSort} />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {currentData?.length > 0 ? (
            <>
              {currentData?.map((match, i) => {
                return (
                  <SingleMatch
                    key={`${match?.matchedOn?.toDate()?.toDateString()}--${i}`}
                    tableData={match}
                  />
                );
              })}
            </>
          ) : (
            <p
              style={{
                color: "#EB3570",
                fontSize: "1.4rem",
                fontWeight: "600",
              }}
            >
              There are currently no records.
            </p>
          )}
        </div>
      </ReqTable>
      {currentData?.length > 0 && renderButtons()}
    </div>
  );
};

export default Matches;
