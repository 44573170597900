import { ArrowBack } from "@mui/icons-material";
import { useState } from "react";
import DomainsCaroussel from "../Developer/Carousels/Domains/DomainsCarousel";
import AddNew from "./components/AddNew";

export default function Subsectors(props) {
  const [selectedData, setSelectedData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultStack = props?.domains[0] ?? {};

  const domId = "collect_edit_subdomain";

  const categoryId =
    props.categoriesId[selectedData.category] ??
    props.categoriesId[defaultStack.category];

  /**
   * takes the id of the input and the event3.0+
   * +
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
  };
  const handleSelectedData = (selectedData) => {
    setSelectedData(selectedData);
  };

  return (
    <div
      className="colManagement_body-stacks collection"
      style={{ minHeight: "50vh" }}
    >
      <div style={{ marginBottom: "4rem" }}>
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => props.setViewSubs(false)}
        >
          <ArrowBack />
          Back
        </button>
      </div>
      {openDialog || isEdit ? (
        <AddNew
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          techsCategories={props.domainCategories}
          firebase={props.firebase}
          handleFileUpload={handleFileUpload}
          setIsUpdated={props.setIsUpdated}
          collection="subSectors"
          selectedSector={props.selectedSector}
          isEdit={isEdit}
          categoryId={categoryId}
          setIsEdit={setIsEdit}
          isSub={true}
          defaultData={selectedData.name ? selectedData : defaultStack}
          // isTech={true}
        />
      ) : (
        <></>
      )}
      <div className="collection_header">
        <h3> {props.selectedSector?.name} SUBSECTORS</h3>
        <p>All Sprytelabs Industries.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          {props.domains.length > 0 && (
            <DomainsCaroussel
              showHiddenCard={false}
              domains={props.domains}
              firebase={props.firebase}
              domainExperiencesObject={props.stacksObbject}
              numOfDomToShow={12}
              setSelectedDomain={handleSelectedData}
              useExist={false}
              domId={props.domId}
            />
          )}
          <div>
            <button
              className="spbtn spbtn-sub"
              onClick={() => setOpenDialog(true)}
            >
              Add New Sub-Sector
            </button>
          </div>
        </div>
        {props.domains.length > 0 && (
          <div className="collection_data-information" id={`${domId}`}>
            <div className="collection_data-information__header row">
              <h3>Edit {selectedData?.name ?? defaultStack.name} Category</h3>
              <div className="spbtn">
                <button
                  id="stacklogoEditBtn"
                  className="spbtn-sub spbtn"
                  onClick={(e) => setIsEdit(true)}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="collection_data-information__detail">
              <div className="collectDetail">
                <span className="collectDetail_title">name</span>
                <p>{selectedData?.name ?? defaultStack.name}</p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Description</span>
                <p>{selectedData?.description ?? defaultStack.description}</p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">searchKeywords</span>
                <p>
                  {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
                </p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Category</span>
                <span>{selectedData?.category ?? defaultStack.category}</span>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Id</span>
                <span>{selectedData?.id ?? defaultStack.id}</span>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Show</span>
                <p>
                  {selectedData?.show ?? defaultStack.show ? "true" : "false"}
                </p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">LogoStorage</span>
                <div>
                  <span>
                    {selectedData?.logoStorage ?? defaultStack.logoStorage}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
