import styles from "./styles/requirementsSteps.module.css";
import { MdPerson, MdStar, MdStarBorder } from "react-icons/md";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { BsHouseDoorFill } from "react-icons/bs";
import { IoExit, IoGlasses } from "react-icons/io5";
import { ImEnter } from "react-icons/im";
import { useState, useEffect } from "react";
// import { useRouter } from "next/router";
import {
  db,
  getDocumentFromPath,
  GetRefFromPath,
} from "../../globals/services";

function ConfirmedDevsComponent({ index, tucItem, logAuthors, userType }) {
  // const router = useRouter();
  const [devItems, setDevItems] = useState();
  const [titleData, setTitleData] = useState({});
  const [vendorName, setVendorName] = useState("");

  useEffect(() => {
    const getTitleData = async () => {
      var tempTitleData = (
        await tucItem?.positions?.[index]?.title.get()
      )?.data();
      setTitleData(tempTitleData);
    };
    if (tucItem) {
      db.collection(`${tucItem?.path}/POS-${index + 1}`)
        .where("status", "in", [
          GetRefFromPath(
            `${tucItem?.path?.split("tuc")?.[0]}resourceStatuses/CONFIRMED`
          ),
          GetRefFromPath(
            `${tucItem?.path?.split("tuc")?.[0]}resourceStatuses/BOOKED`
          ),
        ])
        .onSnapshot(async (snap) => {
          var tempdevItems = [];
          await Promise.all(
            snap?.docs?.map(async (dev) => {
              var devData = dev?.data();
              devData["id"] = dev?.id;
              var devRefData =
                (await getDocumentFromPath(
                  `${devData?.devRef?.path}/devPublic/profile`
                )) ?? {};
              devRefData["id"] = devData?.devRef?.id;
              devData["devData"] = devRefData;
              tempdevItems.push(devData);
            })
          );
          setDevItems(tempdevItems);
        });
      if (tucItem?.partners?.[index]?.path)
        db.doc(`${tucItem?.partners?.[index]?.path}`).onSnapshot(
          (vendorSnapShot) => {
            setVendorName(vendorSnapShot.data()?.["companyName"] ?? "");
          }
        );
      getTitleData();
    }
  }, [tucItem]);

  function buildRatings(rating) {
    const ratings = [];
    for (let i = 0; i < rating; i++) {
      ratings.push(<MdStar className={styles.star} />);
    }
    for (let i = 0; i < 4 - rating; i++) {
      ratings.push(<MdStarBorder className={styles.star} />);
    }
    return ratings;
  }

  const statusColorObject = {
    abandoned: {
      fill: "grey",
      icon: <IoExit style={{ fill: "white" }} />,
    },
    confirmed: {
      fill: "#2ecc71",
      icon: <FaCheckCircle size={13} style={{ fill: "white" }} />,
    },
    considered: {
      fill: "#459df5",
      icon: <IoGlasses style={{ fill: "white" }} />,
    },
    proposed: {
      fill: "#f368e0",
      icon: <ImEnter size={15} style={{ fill: "white" }} />,
    },
    "timed-out": {
      fill: "#70500a",
      icon: <FaClock style={{ fill: "white" }} />,
    },
    "to-be-confirmed": {
      fill: "grey",
      icon: <BsHouseDoorFill size={15} style={{ fill: "white" }} />,
    },
    withdrawn: {
      fill: "#ee5253",
      icon: <IoExit style={{ fill: "white" }} />,
    },
  };

  return (
    <>
      <div
        key={tucItem?.positions?.[index]?.notes ?? index}
        className={styles.emptyDev}
      >
        {devItems && devItems?.length > 0 ? (
          devItems?.map((devItem, devIndex) => {
            return (
              <div className={styles.dev}>
                {devItem?.devData?.logoStorage ? (
                  <div className={styles.devWrapper}>
                    <div
                      style={{
                        border: `2.5px solid ${
                          statusColorObject[
                            devItem?.status?.id.toLowerCase()
                          ]?.["fill"]
                        }`,
                      }}
                      className={`${styles.devImg} ${styles.img}`}
                    >
                      <img src={devItem?.devData?.logoStorage} />
                    </div>
                    <div
                      style={{
                        backgroundColor: `${
                          statusColorObject[
                            devItem?.status?.id.toLowerCase()
                          ]?.["fill"]
                        }`,
                      }}
                      className={styles.statusIcon}
                    >
                      {
                        statusColorObject[devItem?.status?.id.toLowerCase()]?.[
                          "icon"
                        ]
                      }
                      <p className={styles.statusText}>{devItem?.status?.id}</p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.devWrapper}>
                    <div
                      style={{
                        border: `2.5px solid ${
                          statusColorObject[
                            devItem?.status?.id.toLowerCase()
                          ]?.["fill"]
                        }`,
                      }}
                      className={styles.devImg}
                    >
                      <MdPerson className={styles.icon} />
                    </div>
                    <div
                      style={{
                        backgroundColor: `${
                          statusColorObject[
                            devItem?.status?.id.toLowerCase()
                          ]?.["fill"]
                        }`,
                      }}
                      className={styles.statusIcon}
                    >
                      {
                        statusColorObject[devItem?.status?.id.toLowerCase()]?.[
                          "icon"
                        ]
                      }
                      <p className={styles.statusText}>{devItem?.status?.id}</p>
                    </div>
                  </div>
                )}
                <div id={styles.sb}>
                  <h4>{devItem?.name}</h4>
                  <p>{devItem?.position}</p>
                  <div className={styles.starCtr}>
                    {buildRatings(devItem?.devData?.rating)}
                    <h5
                      style={{
                        marginLeft: "5px",
                        color: "#459df5",
                        fontSize: "12.5px",
                        marginBottom: "-5px",
                        opacity: ".55",
                      }}
                    >
                      [{devItem?.devData?.rating}/4]
                    </h5>
                  </div>
                  <div className={styles.priceCtr}>
                    <h4 style={{ opacity: ".5", marginTop: "7.5px" }}>
                      {titleData?.label ?? "Position"}
                    </h4>
                  </div>
                  <div className={styles.priceCtr}>
                    {devItem?.rates !== undefined &&
                    devItem?.rates?.length !== 0 ? (
                      <h4 style={{ opacity: ".5", marginTop: "7.5px" }}>
                        ${Math.min.apply(Math, devItem?.rates) ?? "--"}/hr
                      </h4>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "280px",
                      marginTop: "7.5px",
                    }}
                  >
                    {devItem?.tags?.map((tag, index) => {
                      return (
                        <h5
                          style={{
                            color: tag?.color,
                            border: `1px solid ${tag?.color}`,
                            padding: "1px 7.5px",
                            borderRadius: "2px",
                            marginBottom: "5px",
                            marginRight: "5px",
                            backgroundColor: `${tag?.color}17`,
                            fontSize: "11.5px",
                            fontWeight: "500",
                          }}
                        >
                          {tag?.label}
                        </h5>
                      );
                    })}
                  </div>
                  {devItem?.notes?.map((note, index) => {
                    return note?.note !== "" ? (
                      <p
                        style={{
                          fontWeight: "600",
                          opacity: ".5",
                          fontStyle: "italic",
                          fontSize: "13px",
                          margin: "6.5px 0",
                          width: "280px",
                        }}
                      >
                        " {note?.note} "{" "}
                        <span style={{ fontWeight: "800" }}>
                          {logAuthors[note?.author?.id]
                            ? "-" +
                              logAuthors[note?.author?.id]?.["first"] +
                              " " +
                              logAuthors[note?.author?.id]?.["last"][0] +
                              "."
                            : "Author"}
                        </span>
                      </p>
                    ) : null;
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ConfirmedDevsComponent;
