import React from "react";

function SpChatIcon({
  containerColor = "#4361E9",
  strokeWidth = "2",
  linesColor = "#4361E9",
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33398 16.9538C5.33398 15.4056 5.33398 14.6315 5.42901 13.9827C6.00531 10.0483 9.09522 6.95844 13.0296 6.38213C13.6783 6.28711 14.4524 6.28711 16.0007 6.28711V6.28711C17.5489 6.28711 18.323 6.28711 18.9717 6.38213C22.9061 6.95844 25.996 10.0483 26.5723 13.9827C26.6673 14.6315 26.6673 15.4056 26.6673 16.9538V23.7416C26.6673 25.5096 26.6673 26.3936 26.1472 26.9658C26.1045 27.0127 26.0596 27.0576 26.0126 27.1003C25.4405 27.6204 24.5565 27.6204 22.7885 27.6204H16.0007C14.4524 27.6204 13.6783 27.6204 13.0296 27.5254C9.09522 26.9491 6.00531 23.8592 5.42901 19.9248C5.33398 19.2761 5.33398 18.502 5.33398 16.9538V16.9538Z"
        stroke={containerColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12 15.6191L20 15.6191"
        stroke={linesColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20.9531H20"
        stroke={linesColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SpChatIcon;
