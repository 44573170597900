import React, { useEffect, useState, useMemo } from "react";
import ProjectsList from "./ProjectsList";
import Search from "../Search";
import { getDocumentsByArrayMembershipInCollection } from "../../../../globals/services";
import { Pagination } from "@mui/material";

export default function ProjectsCarousel(props) {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(12);
  const [numberOfData, setNumberOfData] = useState(0);

  const currentTableData = useMemo(() => {
    const theData =
      filteredProjects?.length < 1 ? props.projects : filteredProjects;
    setNumberOfData(theData.length);
    const firstPageIndex = (currentPage - 1) * pageLimit;
    const lastPageIndex = firstPageIndex + pageLimit;
    return theData.slice(firstPageIndex, lastPageIndex);
  }, [props.projects, filteredProjects, currentPage]);

  useEffect(() => {
    props.numOfDomToShow
      ? setPageLimit(props.numOfDomToShow)
      : setPageLimit(12);
  }, [props.projects]);

  const handleSearch = async (e) => {
    // setSearchValue(e.target.value);
    const dataDocs = await getDocumentsByArrayMembershipInCollection(
      "subcollections",
      e.target.value.toLowerCase(),
      ["nameIndex", "catIndex"],
      "name",
      true,
      true,
      false,
      20
    );

    const data = dataDocs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id,
    }));
    setFilteredProjects(data);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    props?.onCategoriesPaginate && props.onCategoriesPaginate(event, page);
  };

  return (
    <div className="Stacks_wrap _spTechSkills_wrap">
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder="Ex: Agriculture, category"
        allData={props.projects}
        setFilteredDatas={setFilteredProjects}
        currentPage={currentPage}
        pageLimit={pageLimit}
        onChange={handleSearch}
      />
      <div className="_spTechskillsSection stacks_area ">
        <ProjectsList
          projects={currentTableData}
          firebase={props.firebase}
          devId={props.devId}
          projExp={props.projExp}
          showHiddenCard={props.showHiddenCard}
          useExist={props.useExist}
          setSelectedData={props.setSelectedData}
        />
        <div className="stacks_pagination">
          <Pagination
            count={Math.ceil(props.projects?.length / pageLimit)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
