export default function devDocs(props) {
  return (
    <div>
      {props?.docs?.length ? (
        <button
          //   href={props.resume ?? props.resumeData.resumeUrl}
          style={{
            border: "none",
          }}
          target="blank"
          className="link"
        >
          {props.docs.length > 1
            ? `${props.docs.length} docs`
            : `${props.docs.length} doc`}
        </button>
      ) : (
        <p
          style={{
            color: "#F05A56",
            fontSize: "12px",
            // backgroundColor: "transparent",
            // border: "none",
          }}
        >
          No Doc
        </p>
      )}
    </div>
  );
}
