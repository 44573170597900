import { useEffect } from "react";
import styled from "styled-components";

const SpUploadBtn = styled.label`
  background-color: ${(props) => props.backgroundColor || "black"};
  color: ${(props) => props.color || "white !important"};
  font-size: ${(props) => props.fontSize || "14px"};
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const SpUploadInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

let counter = 0;

export default function SpUpload({
  backgroundColor,
  color,
  fontSize,
  onChange,
  id,
  label = "Select File",
  cls,
  resetFile = false,
  ...props
}) {
  const inputId = id || `sp-upload-${counter++}`;
  const handleFileSelect = (event) => {
    // do something with the selected file
    onChange && onChange(event);
  };

  //   should listen for resetFile prop change and reset the input value
  useEffect(() => {
    if (resetFile) {
      document.getElementById(inputId).value = null;
    }
  }, [resetFile]);

  return (
    <div className={`${cls} _spUploadBtn`}>
      <SpUploadBtn
        htmlFor={inputId}
        backgroundColor={backgroundColor}
        color={color}
        fontSize={fontSize}
        className={`${cls}--label _spUploadBtn--label`}
      >
        {label}
      </SpUploadBtn>
      <SpUploadInput id={inputId} onChange={handleFileSelect} {...props} />
    </div>
  );
}
