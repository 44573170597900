import React from "react";
import SpTitles from "../../../globals/SpTitles/SpTitles";
import SpTechsTable from "../../../globals/TechsTable/SpTechsTable";
import {
  TechTableBody,
  TechTableBodyRow,
} from "../../../globals/TechsTable/style";
import { motion } from "framer-motion";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";
// import SpResponsiveImg from "../SpResponsiveImg";

const TechTableData = ({
  title,
  techsList = [],
  iscolapsed = false,
  setIsCollapsed,
  keyName,
  devInfo,
}) => {
  return (
    <div>
      <SpTitles
        title={title}
        isColapsable
        onCollapse={(isCol) =>
          setIsCollapsed((prev) => ({ ...prev, [keyName]: isCol }))
        }
        iconSize={"30"}
      />
      {iscolapsed && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.5 }}
        >
          <SpTechsTable>
            <TechTableBody>
              {techsList?.map((stack) => {
                return (
                  <TechTableBodyRow key={stack?.ref}>
                    {keyName === "stacks" ? (
                      <div style={{ display: "flex", gap: "0.4rem" }}>
                        <SpResponsiveImg
                          src={devInfo?.skillsObject?.[stack?.ref]?.logoStorage}
                          alt={devInfo?.skillsObject?.[stack?.ref]?.name}
                          width={"20px"}
                          height={"20px"}
                          objFit={"contain"}
                        />
                        <p>{devInfo?.skillsObject?.[stack?.ref]?.name}</p>
                      </div>
                    ) : (
                      <p>{devInfo?.skillsObject?.[stack?.ref]?.name}</p>
                    )}
                    <p>{stack?.stats?.experience}</p>
                    <p>{stack?.stats?.projects}</p>
                    <p>{stack?.stats?.skill}</p>
                    <p>{stack?.stats?.score}</p>
                  </TechTableBodyRow>
                );
              })}
            </TechTableBody>
          </SpTechsTable>
        </motion.div>
      )}
    </div>
  );
};

export default TechTableData;
