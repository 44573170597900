import React from "react";

const CheckBox = ({ size = 32, fill = "white", path = "#CCC0AE", cls }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="16" fill={fill} />
      <path
        d="M10.75 16.75L14.25 20.25L21.25 12.75"
        stroke={path}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CheckBox;
