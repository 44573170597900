import React, { useRef, useEffect, useState, useContext } from "react";
import {
  Selection,
  Inject,
  Filter,
  Page,
  Edit,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  SortSettings,
} from "@syncfusion/ej2-react-grids";
import {
  ExcelExport,
  PdfExport,
  Group,
  Toolbar,
  Search,
  Sort,
} from "@syncfusion/ej2-react-grids";
import devProgessTemplate from "./grid_templates/devProgressTemp";
// import devTypeTemplate from "./grid_templates/typesTemplate";
import resumeDwload from "./grid_templates/resumeDwload";
import devDocs from "./grid_templates/devDocsTemplate.js";
import statusTemplate from "./grid_templates/statusTemplate";
import MigrateDevs from "./pages/MigrateDevs/MigrateDevs";
import { colRef, docRef } from "./globals/services";
import MigrateDevOverlay from "./pages/MigrateDevs/MigrateDevOverlay";
import { GlobalVendorContex } from "./Firebase";
import { useNavigate } from "react-router-dom";

export default function AllResources(props) {
  const router = useNavigate();
  const { setDevName, setIsVerifiedResource, viewDev, viewingSection } =
    useContext(GlobalVendorContex);
  const [devsCount, setDevsCount] = useState(null);
  const [sorting, setSorting] = useState();
  const [stateData, setStateData] = useState({ skip: 0, take: 20 });
  const [initialQuery, setInitialQuery] = useState(
    props.firebase.db.collection("devs").orderBy("addedOn", "desc")
  );
  const [selectedDevData, setSelectedDevData] = useState(null);
  const [migrateDevs, setMigrateDevs] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const gridRef = useRef({});
  // console.log(selectedDevData);
  const toolbarOptions = ["Search"];
  const searchOptions = {
    fields: ["name", "id"],
    ignoreCase: true,
    operator: "contains",
  };
  const firebaseDir = {
    ascending: "asc",
    descending: "desc",
  };
  // migrate developer state

  useEffect(() => {
    // let sub
    const sub = initialQuery.onSnapshot((snap) => setDevsCount(snap));
    return () => {
      try {
        sub();
      } catch (e) {}
    };
  }, [initialQuery]);

  const query = () => {
    let queryDoc = initialQuery;
    if (stateData.take) {
      queryDoc = queryDoc.limit(stateData?.take);
      if (stateData?.skip > 0 && devsCount?.docs[stateData?.skip - 1]) {
        queryDoc = queryDoc.startAfter(devsCount?.docs[stateData?.skip - 1]);
      }
    }
    return queryDoc;
  };

  useEffect(() => {
    let devsSubs;
    if (
      devsCount?.size &&
      gridRef?.current &&
      !props.isAllDevs &&
      searchValue === "" &&
      searchData.length <= 0
    ) {
      devsSubs = query().onSnapshot(async (snap) => {
        let devs = await props.firebase.allDevsData(snap, true, true);

        if (gridRef?.current)
          gridRef.current["dataSource"] = {
            count: devsCount.size,
            result: devs,
          };
      });
    }
    return () => {
      try {
        devsSubs();
      } catch (err) {}
    };
  }, [
    stateData?.skip,
    devsCount?.size,
    initialQuery,
    props.isAllDevs,
    searchValue,
    searchData,
  ]);
  /**
   * Called when the grid finished rendering
   * @param {object} args arguments of the grid renderComplete function
   */
  //   const rendereComplete = (args) => {
  //     this.dataStateChange(args);
  //   }

  /**
   * Is trigger when the data source of the grid changes(row deletes or added or edited)
   * @param {*} args
   */
  const dataSourceChanged = async (args) => {
    if (gridRef?.current) {
      if (args?.state?.skip) {
      } else {
      }
    }
  };

  const getDevData = async (searchVal) => {
    const lowerCaseSearchValue = searchVal.toLowerCase(); // converts all search values to lower case
    const initialCapSearchValue =
      lowerCaseSearchValue.charAt(0).toUpperCase() +
      lowerCaseSearchValue.slice(1); // converts first letter to upper case
    const upperCaseSearchValue = searchVal.toUpperCase(); // converts all search values to upper case

    // an array of converted search values which ignores cases irrespective of search inputs
    let convertedSearchValue = [
      initialCapSearchValue,
      lowerCaseSearchValue,
      upperCaseSearchValue,
    ];

    // queries the db and gets the docs/details of the search parameters e.g first name, last name & internal ID
    const firstName = colRef("devs")
      .where("first", "in", convertedSearchValue)
      .get();
    const lastName = colRef("devs")
      .where("last", "in", convertedSearchValue)
      .get();
    const devID = docRef(`devs/${searchVal}`).get();

    // resolves all three promises once using Promise.all()
    const results = await Promise.all([firstName, lastName, devID]);

    // spreads the details of the resolved promises into the devData array
    // 1st index rep the details of the first name; 2nd index rep the details of the last name
    let devData = [...results[0].docs, ...results[1].docs];

    // pushes the resolved internal ID into devData if it exists
    if (results[2].exists) {
      devData.push(results[2]);
    }

    // sets the searchData to the array of searched devData values
    // so that only that data would be displayed by allDevsData function
    setSearchData(devData);

    // invokes allDevsData function which returns searched data docs;
    let devs = await props.firebase.allDevsData({ docs: devData }, true, true);

    if (gridRef?.current)
      gridRef.current["dataSource"] = {
        count: devData.length,
        result: devs,
      };
  };

  const getSorting = (sorting) => {
    let init = props.firebase.db.collection("devs");
    switch (sorting.name) {
      case "name":
        init = init.orderBy("first", firebaseDir[sorting.direction]);
        setInitialQuery(init);
        break;
      case "position":
        init = init.orderBy("position", firebaseDir[sorting.direction]);
        setInitialQuery(init);
        break;

      case "rating":
        init = init.orderBy("rating", firebaseDir[sorting.direction]);
        setInitialQuery(init);
        break;

      case "addedOn":
        init = init.orderBy("addedOn", firebaseDir[sorting.direction]);
        setInitialQuery(init);
        break;

      case "locTag":
        init.orderBy("locTag", firebaseDir[sorting.direction]);
        setInitialQuery(init);
        break;

      default:
        setInitialQuery(
          props.firebase.db.collection("devs").orderBy("addedOn", "desc")
        );
    }
  };
  /**
   * triggers when an action happens in the grid(paging ex)
   * @param {*} state
   */
  const dataStateChange = async (state) => {
    if (gridRef?.current) {
      setStateData({
        skip: state.skip,
        take: state.take,
      });
      if (state?.search?.length > 0) {
        setSearchValue(state?.search[0]?.key);
        getDevData(state?.search[0]?.key);
      } else {
        setSearchValue("");
        setSearchData([]);
      }
      if (state?.sorted) {
        setSorting(state?.sorted[0]);
        getSorting(state?.sorted[0]);
      } else {
        setSorting(null);
        setInitialQuery(
          props.firebase.db.collection("devs").orderBy("addedOn", "desc")
        );
      }
    }
  };

  const rowSelecting = (args) => {
    if (args.data) {
      setSelectedDevData(args.data);
      viewDev.current = args.data;
      viewingSection.current = "Resources";
      // setIsVerifiedResource(args.data?.isVerified ?? true);
    }
  };
  const rowDeselecting = (args) => {
    setSelectedDevData(null);
  };

  const toolbarClick = (args) => {
    switch (args.item.text) {
      case "View Dev":
        router(`/resources/${selectedDevData.id}`);
        break;
      case "Migrate Dev":
        setMigrateDevs(true);
        break;
      default:
    }
  };

  // ==========================================================Code to make the rating and status states work=======================================================================
  // const onQueryCellInfo = (args) => {
  //   // Behavior of status column in dev roster section
  //   if (args.column.field === "status") {
  //     // If status is active give the text a green color and background
  //     if (args.cell.textContent === "Active") {
  //       args.cell.querySelector(".statustxt").classList.add("e-activecolor");
  //       args.cell.querySelector(".statustemp").classList.add("e-activecolor");
  //     }
  //     // If status is inactive give the text a red color and background
  //     if (args.cell.textContent === "Inactive") {
  //       args.cell.querySelector(".statustxt").classList.add("e-inactivecolor");
  //       args.cell.querySelector(".statustemp").classList.add("e-inactivecolor");
  //     }
  //   }
  //   // Behavior of ratings column in dev roster section
  //   if (args.column.field === "rating") {
  //     if (args.column.field === "rating") {
  //       for (let i = 0; i < args.data.rating; i++) {
  //         args.cell.querySelectorAll("span")[i].classList.add("checked");
  //       }
  //     }
  //   }
  // };

  return (
    <div className="all_resources">
      <div className="resource-header requirements_header spmrg_sm-btm">
        <div className="header-intro">
          <h3>All Spryte Resources</h3>
          <div className="desc-icon" style={{ marginTop: "6.5px" }}>
            {/* <FontAwesomeIcon icon={faInfoCircle} className="descIcon" /> */}
            <div className="description">
              Browse and search for Spryte Resources{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="grid-bulk">
        <GridComponent
          id="overviewgrid"
          className="data-grid"
          dataSource={[]}
          ref={gridRef}
          // queryCellInfo={onQueryCellInfo}
          toolbar={
            selectedDevData
              ? [
                  {
                    text: "View Dev",
                    tooltipText: "View Dev",
                    prefixIcon: "e-view",
                    id: "view_dev",
                  },
                  {
                    text: "Migrate Dev",
                    tooltipText: "Migrate Dev",
                    prefixIcon: "e-view",
                    id: "migrate_dev",
                  },
                ]
              : toolbarOptions
          }
          // toolbar={toolbarOptions}
          searchSettings={searchOptions}
          allowSorting={true}
          width="100%"
          // searchSettings={searchFields}
          toolbarClick={toolbarClick}
          //   queryCellInfo={this.props.queryCellInfo}
          allowPaging={true}
          //   allowSorting={true}
          //   allowPaging={true}

          allowSelection={true}
          //   allowExcelExport={this.props.allowExcelExport}
          //   editSettings={this.props.editSettings}
          //   allowPdfExport={this.props.allowPdfExport}
          //   allowGrouping={this.props.allowGrouping}
          //   toolbarClick={this.toolbarClick.bind(this)}
          //   rowSelected={this.props.rowSelected}
          rowDeselecting={rowDeselecting}
          //   actionComplete={this.props.actionComplete}
          rowSelecting={rowSelecting}
          //   actionBegin={this.props.actionBegin}
          pageSettings={{ pageSize: 20 }}
          dataSourceChanged={dataSourceChanged}
          dataStateChange={dataStateChange}
          // dataStateChange={dataStateChange}
        >
          <ColumnsDirective>
            <ColumnDirective type="checkbox" width="40"></ColumnDirective>

            <ColumnDirective
              field="company"
              headerText="Partner"
              width="120"
              textAlign="Center"
              allowSorting={false}
              //   validationRules={this.props.validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="name"
              headerText="First, Last"
              width="120"
              textAlign="Center"
              //   validationRules={this.props.validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="position"
              headerText="Position"
              width="130"
              editType="dropdownedit"
              textAlign="Center"
              //   edit={this.props.positions}
              //   validationRules={
              //     this.props.isDup
              //       ? this.props.norequired
              //       : this.props.validationRules
              //   }
            />
            <ColumnDirective
              field="status"
              headerText="Profile Status"
              width="100"
              textAlign="Center"
              template={statusTemplate}
              allowSorting={false}
              //   defaultValue={this.props.isDup ? "" : "Active"}
              required={false}
            ></ColumnDirective>
            {/* <ColumnDirective
                  field="rating"
                  headerText="Rating"
                  width="100"
                  textAlign="Center"
                  template={ratingTemplate}
                  // defaultValue={0}
                ></ColumnDirective> */}
            <ColumnDirective
              field="hourlyRate"
              headerText="Hourly Rate"
              width="120"
              format="C2"
              textAlign="Center"
              defaultValue={0}
              allowSorting={false}
            ></ColumnDirective>

            <ColumnDirective
              field="progress"
              required={false}
              headerText="Profile Progress"
              width="100"
              textAlign="Center"
              allowSorting={false}
            ></ColumnDirective>
            <ColumnDirective
              field="locTag"
              headerText="Location"
              width="120"
              textAlign="Center"
              //   validationRules={
              //     this.props.isDup
              //       ? this.props.norequired
              //       : this.props.validationRules
              //   }
            ></ColumnDirective>
            <ColumnDirective
              field="addedOn"
              headerText="Added On"
              width="150"
              textAlign="Center"
              editType="datepickeredit"
              // format="MMMM y"
              format="yMd"
            ></ColumnDirective>
            <ColumnDirective
              field="resume"
              required={false}
              headerText="CV"
              width="100"
              textAlign="Center"
              template={resumeDwload}
              allowSorting={false}
            ></ColumnDirective>
            <ColumnDirective
              field="docs"
              required={false}
              headerText="Docs"
              width="100"
              textAlign="Center"
              template={devDocs}
              allowSorting={false}
            ></ColumnDirective>
            <ColumnDirective
              field="id"
              headerText="Internal ID"
              width="220"
              textAlign="Center"
              allowSorting={false}
              //   validationRules={this.props.validationRules}
              defaultValue={"0"}
            ></ColumnDirective>
          </ColumnsDirective>

          <Inject
            services={[
              Search,
              Selection,
              Page,
              Toolbar,
              Edit,
              Filter,
              ExcelExport,
              PdfExport,
              Group,
              Sort,
            ]}
          />
        </GridComponent>
      </div>

      {migrateDevs && (
        <>
          <MigrateDevs
            firebase={props.firebase}
            selectedDevData={selectedDevData}
            setMigrateDevs={setMigrateDevs}
          />
          <MigrateDevOverlay />
        </>
      )}
    </div>
  );
}
