import { motion } from "framer-motion";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor};
  padding: ${(props) => props.padding || "0.5rem 1rem"};
  border-radius: ${(props) => props.borderRadius};
  margin-bottom: ${(props) => props.marginBottom};
  align-items: center;
  width: ${(props) => props.width || "100%"};
`;

export const Title = styled.h3`
  font-size: ${(props) => props.titleSize ?? "20px"};
  font-weight: 700;
  color: ${(props) => props.titleColor || "#000"};
  margin: 0;
`;
export const CollapseIconWrapper = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => `${props.iconSize}px ` ?? "20px"};
  height: ${(props) => `${props.iconSize}px ` ?? "20px"};
  svg {
    width: ${(props) => `${props.iconSize}px` ?? "20px"};
    height: ${(props) => `${props.iconSize}px` ?? "20px"};
  }
`;
