import React, { forwardRef, useEffect, useMemo, useState } from "react";
import {
  GetRefFromPath,
  getTimestampFromMilliseconds,
} from "../globals/services";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

import ActiveInactiveIcon from "../globals/ActiveInactiveIcon";
import EndorsmentBadge from "../svgs/endorsementBadge";
export default function AvailabilityTemplate(args) {
  const [availabilityData, setAvailabilityData] = useState({});

  const workTypes = ["", "On-site", "Hybrid", "Remote", "Available"];

  const workAvailability = ["fullTime", "partTime", "hourly"];

  useEffect(() => {
    if (args?.args) setAvailabilityData(args?.args?.availability ?? {});
  }, [args?.args]);

  // useEffect(() => {
  //   const ts = GetNowTimeStamp();
  //   console.log(ts,ts.toDate(), ts.toMillis())
  //   console.log("availabilityData: ", availabilityData, availabilityData?.fullTime?.nextAvailableStart);
  // }, [availabilityData]);

  const onUpdate = async (key, key1, value) => {
    let updates = { availability: { ...availabilityData } };
    if (!updates?.availability?.[key]) updates.availability[key] = {};
    updates.availability[key][key1] = value;
    // console.log("updates: ", updates);
    // console.log("updates: ", { ...(updates?.availability ?? {}) });
    setAvailabilityData({ ...(updates?.availability ?? {}) });
    await GetRefFromPath(`devs/${args.args.id}`).set(updates, { merge: true });
  };

  const CalendarInput = forwardRef(({ value, onClick }, ref) => (
    <button className="calendar-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const renderDate = (date) => {
    if (!date) return "Unavailable";
    // return date in format Starting March 12th
    const month = date.toDate().toLocaleString("default", { month: "long" });
    const day = date.toDate().getDate();
    const suffix = day % 10 == 1 ? "st" : day % 10 == 2 ? "nd" : "th";
    return `Starting ${month} ${day}${suffix}`;
  };

  const isEmptyAvailability = useMemo(() => {
    return !workAvailability.some((a) => {
      return (
        (availabilityData?.[a]?.onSite ||
          availabilityData?.[a]?.hybrid ||
          availabilityData?.[a]?.remote) &&
        (availabilityData?.[a]?.availableNow ||
          availabilityData?.[a]?.nextAvailableStart)
      );
    });
  }, [workAvailability, availabilityData]);

  // console.log("isEmptyAvailability: ", isEmptyAvailability);

  return (
    <div className="_sppdevAvail__availability">
      <div className="_sppdevAvail__availability_title">
        <h3>Availability</h3>
        {args?.renderBadge && args.renderBadge(isEmptyAvailability)}
        {/* <EndorsmentBadge /> */}
      </div>
      <table className="_sppdevAvail__availability_table">
        <thead>
          <tr>
            {workTypes.map((title) => (
              <th>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workAvailability.map((a) => (
            <tr>
              <td>{a.toUpperCase()}</td>
              <td>
                <ActiveInactiveIcon
                  key={`status ${args.args?.id} 1`}
                  value={availabilityData?.[a]?.onSite}
                  size="25px"
                  onToggle={(isActive) => {
                    onUpdate(a, "onSite", isActive);
                  }}
                />
              </td>
              <td>
                <ActiveInactiveIcon
                  key={`status ${args.args?.id} 2`}
                  value={availabilityData?.[a]?.hybrid}
                  size="25px"
                  onToggle={(isActive) => {
                    onUpdate(a, "hybrid", isActive);
                  }}
                />
              </td>
              <td>
                <ActiveInactiveIcon
                  key={`status ${args.args?.id} 3`}
                  value={availabilityData?.[a]?.remote}
                  size="25px"
                  onToggle={(isActive) => {
                    onUpdate(a, "remote", isActive);
                  }}
                />
              </td>
              <td>
                <SwitchComponent
                  key={`status ${args.args?.id}`}
                  id={`available`}
                  className="devAvailSwitch"
                  checked={availabilityData?.[a]?.availableNow}
                  change={async (e) => {
                    onUpdate(a, "availableNow", e.checked);
                  }}
                />
                <input
                  type="date"
                  name="Next Available Start"
                  id="nextAvailableStart"
                  style={{ width: "40px" }}
                  className={
                    availabilityData?.[a]?.availableNow
                      ? "custom-date-color"
                      : ""
                  }
                  onChange={(date) =>
                    onUpdate(
                      a,
                      "nextAvailableStart",
                      getTimestampFromMilliseconds(date.target.valueAsNumber)
                    )
                  }
                />
                <p>{renderDate(availabilityData?.[a]?.nextAvailableStart)}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
