import React, { useState, useEffect, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css"; //https://fkhadra.github.io/react-toastify/introduction/
import DeleteComponent from "../DeleteComponent";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  createDocFromCollection,
  addOrUpdateDocFromPath,
  docRef,
  colRef,
} from "../../globals/services";
import ConfirmComponent from "../ConfirmComponent";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import SpDialog from "../../globals/SpDialog";

/**
 *
 * @param {object} props coming from the branding.js file
 * @returns
 */
const ClientsLogos = (props) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [state, setState] = useState(false);

  const [website, setWebsite] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [file, setFile] = useState("");

  const vendorId = props.vendorId;
  let storage = props.firebase.getStorage();

  /**
   * Arrays of file and upload each of them to the firebase storage and in firestore
   * @param {Array} files array of files
   * @returns a list of object containing fileName, fileRef, uploadedDate
   */

  /**
   * when the input button is clicked it will open the file upload dialog so users can select multiple files
   *
   * @param {*} e event of the input event
   */

  /**
   * takes 3 strings and find the data to be deleted then remove the data from the firebase
   * @param {String} fileName the name of the file to be deleted
   * @param {String} i index of the file to be deleted
   * @param {String} docId id of the file to be deleted in firebase
   */

  const deleteDoc = async (id) => {
    const delDoc = await docRef(`vendors/${vendorId}/clients/${id}`);
    delDoc.delete();
  };

  /**
   * click the input button when the custom button is clicked
   */
  const handleImageChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  function checkCompanyName() {
    return logos.filter((logo) => logo.companyName === companyName);
  }

  const validate = () => {
    if (file === "") {
      alert("Please select a file");
      return false;
    }
    if (companyName === "") {
      alert("Please enter a company name");
      return false;
    }
    if (website === "") {
      alert("Please enter a website");
      return false;
    }
    return true;
  };
  const onSubmit = (e, id) => {
    if (!validate()) return;
    setState(false);
    e.preventDefault();
    let existingCompany = checkCompanyName();

    storage
      .ref(`vendors/${props.vendorId}/clients/${file.name}`)
      .put(file)
      .then(async (docRef) => {
        let fileUrl = await docRef.ref.getDownloadURL();
        let data = {
          website: website,
          companyName: companyName,
          fileRef: fileUrl,
          fileName: file.name,
          uploadedDate: new Date(),
        };

        if (existingCompany.length > 0) {
          await addOrUpdateDocFromPath(
            `vendors/${vendorId}/clients/${existingCompany[0].id}`,
            data
          );
        } else {
          await createDocFromCollection(`vendors/${vendorId}/clients`, data);
        }

        setUploading(false);
        return data;
      });
  };

  useEffect(() => {
    const collectionRef = colRef(`vendors/${vendorId}/clients`);
    let subscribe = collectionRef.onSnapshot((snapshot) => {
      let data = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setLogos(data);
      setLoading(false);
    });
    return subscribe;
  }, [vendorId]);

  const onClose = useCallback(() => {
    setState(false);
  }, []);

  return (
    <>
      <div className="contract_cont">
        <div className="ctr security">
          <div className="ctr_el_header resource-header">
            <div className="header-intro">
              <h3>Clients</h3>
              <AddCircleOutline
                className="icon"
                onClick={() => setState(true)}
              />

              {uploading ? (
                <CircularProgress color="primary" size="small" />
              ) : (
                <></>
              )}
            </div>

            <div className="content">
              <p className="lighter" style={{ marginTop: "-8.5px" }}>
                Upload your current and past client logos. They will be
                displayed on your Profile page and have a huge impact on sales
                conversion.
              </p>
            </div>
          </div>
        </div>

        <div className="contract_bottom">
          {logos.length !== 0 ? (
            <table className="stable">
              <thead className="stable_header">
                <tr>
                  <th>File</th>
                  <th>Company Name</th>
                  <th>Company Domain</th>
                  <th>Uploaded Date</th>
                  <th>Download</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(logos).map((logo, id) => (
                  <tr key={`${logos[logo].fileName}${id}`}>
                    <td>{logos[logo].fileName}</td>
                    <td>{logos[logo].companyName}</td>
                    <td>{logos[logo].website}</td>
                    <td>{logos[logo].uploadedDate.toDate().toDateString()}</td>
                    <td>
                      <a
                        className="download"
                        href={logos.fileRef}
                        target="blank"
                      >
                        Download
                      </a>
                    </td>
                    <td>
                      <DeleteComponent
                        onClick={() => deleteDoc(logos[logo].id)}
                        id={logos[logo].id}
                        delete={deleteDoc}
                        fileName={logos[logo].fileName}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>You do not have any uploaded clients logo.</p>
          )}
        </div>
      </div>

      <SpDialog open={state} onClose={onClose}>
        <div style={{ display: "block", padding: 10 }} className="d">
          <form className="brand_dialog-box__inputs column">
            <section className="column section client_info">
              <div className="client_upload column info_input domain " mb={5}>
                <label>Company Domain</label>
                <input
                  type="text"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={props.website}
                  placeholder="www.facebook.com"
                />
              </div>

              <div className="client_upload column  info_input name" mb={5}>
                <label>Company Name</label>
                <input
                  type="text"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={props.companyName}
                  placeholder="e.g. Facebook"
                />
              </div>

              <div className="client_upload column info_input name">
                <label htmlFor="file">Add Logo:</label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => handleImageChange(e)}
                />
              </div>
            </section>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                console.log("clicked");
                onSubmit(e);
              }}
            >
              Submit
            </Button>
            {/* <ConfirmComponent
                onSubmit={onSubmit}
                companyName={companyName}
                setState={setState}
              /> */}
          </form>
        </div>
      </SpDialog>
    </>
  );
};

export default ClientsLogos;
