import React from "react";

const StackIcon = ({ w = "17", h = "16", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.95893 1.70848C1.87658 2.64177 0.12154 3.51552 0.0587953 3.64966C-0.00394941 3.78399 -0.00440246 4.00364 0.0576627 4.13778C0.216676 4.48083 7.70957 7.79872 8.32524 7.79872C8.94408 7.79872 16.4168 4.48123 16.5693 4.13876C16.6293 4.00403 16.6279 3.78399 16.5663 3.64966C16.4005 3.28825 8.90603 -0.0132371 8.28107 3.99354e-05C7.98638 0.00609269 6.04129 0.774988 3.95893 1.70848ZM0.90347 6.96383C0.139661 7.29165 -0.130571 7.63647 0.0576627 8.04278C0.216676 8.38584 7.70957 11.7037 8.32524 11.7037C8.94408 11.7037 16.4168 8.38623 16.5693 8.04376C16.762 7.61069 16.4601 7.26998 15.5808 6.92829L14.7225 6.59461L11.9735 7.78232C8.41064 9.32187 8.21334 9.32226 4.68718 7.79872C3.19603 7.15439 1.89787 6.63288 1.80251 6.63972C1.70715 6.64655 1.30259 6.7924 0.90347 6.96383ZM0.90347 10.8688C0.139661 11.1967 -0.130571 11.5415 0.0576627 11.9478C0.216676 12.2908 7.70957 15.6087 8.32524 15.6087C8.94408 15.6087 16.4168 12.2912 16.5693 11.9488C16.762 11.5157 16.4601 11.175 15.5808 10.8333L14.7225 10.4996L11.9735 11.6873C8.41064 13.2269 8.21334 13.2273 4.68718 11.7037C3.19603 11.0594 1.89787 10.5379 1.80251 10.5447C1.70715 10.5516 1.30259 10.6974 0.90347 10.8688Z"
        fill={fill}
      />
    </svg>
  );
};

export default StackIcon;
