import React from "react";

export default function PageHeader({
  defaultComponent = true,
  header = "",
  desc = "",
  headerComponent = null,
}) {
  return (
    <div className="pageHeader control-pane ">
      {defaultComponent ? (
        <div className="pageHeader_default resource-header">
          <h3>{header}</h3>
          <p>{desc}</p>
        </div>
      ) : (
        { headerComponent }
      )}
    </div>
  );
}
