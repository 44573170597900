export async function assignUnitToUser(
  db,
  unit,
  userRef,
  isLastUnitOfTheCourse,
  fire
) {
  try {
    if (
      !db ||
      !userRef ||
      !unit?.name ||
      !unit?.reference ||
      isLastUnitOfTheCourse === undefined ||
      isLastUnitOfTheCourse === null ||
      typeof isLastUnitOfTheCourse !== "boolean" ||
      !fire
    ) {
      throw Error(
        `invalid input params db: ${db}, unit: ${unit}, userRef: ${userRef}, isLastUnitOfTheCourse: ${isLastUnitOfTheCourse}, firebase: ${fire}.`
      );
    }

    return await db.collection("careerPrep").add({
      name: unit.name,
      show: true,
      source: unit.reference,
      ts: fire?.Timestamp?.fromDate(new Date()),
      userRef: userRef,
      isLastUnitOfTheCourse,
    });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}
