import styled from "styled-components";
import { StepperElementWrapper, StepperRight } from "../StepperElement/style";

export const StepperWrapper = styled.div`
  margin-top: 1rem;
  ${StepperElementWrapper}:not(:last-child) {
    margin-bottom: 1rem;
    ${StepperRight}::after {
      content: "";
      position: absolute;
      top: 16px;
      left: -9%;
      width: 2px;
      height: 100%;
      background-color: black;
    }
  }
  ${StepperRight} {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10%;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: ${(props) => (props.isEnableEdit ? "red" : "black")};
    }

    &:not(:last-child) {
      &::before {
        background-color: ${(props) => (props.isEnableEdit ? "red" : "black")};
      }
    }

    button.delete-button {
      position: absolute;
      top: 0;
      left: -10%;
      border-radius: 50%;
      color: white;
      border: none;
      width: 17px;
      height: 17px;
      justify-content: center;
      align-items: center;
      padding: 0;
      display: ${(props) => (props.isEnableEdit ? "flex" : "none")};
      z-index: 1;
    }

    button.add-button {
      display: ${(props) => (props.isEnableEdit ? "flex" : "none")};
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      background-color: #6349ff;
      color: white;
      cursor: pointer;
      z-index: 1;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -10%;
    }
  }
`;
