import { LinearProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ScoreBox = styled.div`
  span {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .scoreProgress--digits-count {
    font-size: 2rem;
    font-weight: 600;
    margin-right: 0.5rem;
  }
`;

const ProgressBox = styled.div`
  // change the color of the progress and the background color of the progress bar
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.progressBarColor};
    border-radius: 10px;
  }
  // change the color of the progress bar
  .MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.progressColor};
    border-radius: 10px;
  }
`;

const getProgress = (num) => {
  // check if we can convert num to a number
  num = Number(num);
  // check if num is not a number
  if (isNaN(num)) return 0;
  // check if num is greater than 100
  if (num > 100) return 100;
  // check if num is less than 0
  if (num < 0) return 0;
  // return num divided by 100
  return num;
};

const ScoreProgress = ({
  score = 0,
  progressBarColor = "#3fdd98",
  progressColor = "#5C5C5C",
}) => {
  return (
    <ScoreWrapper className="scoreProgress">
      <ScoreBox className="scoreProgress--digits">
        <span className="scoreProgress--digits-count">{score}%</span>
        <span className="scoreProgress--digits-text">Match</span>
      </ScoreBox>
      <ProgressBox
        className="scoreProgress--progress"
        progressBarColor={progressBarColor}
        progressColor={progressColor}
      >
        <LinearProgress variant="determinate" value={getProgress(score)} />
      </ProgressBox>
    </ScoreWrapper>
  );
};

export default ScoreProgress;
