import React from "react";

/**
 * HeaderUnSub is the header of the Unsubscribed vendor page
 * @param {*} props are coming from UnSubs.js
 */
const HeaderUnSub = (props) => {
  return (
    <div className="resource-header cont">
      <div className="header-intro">
        <h3>Spryte Enterprise Account</h3>
        <p className="lighter">
          Upgrade your access to an enterprise account for direct access into
          our enterprise client network. Enterprise access allows you to share
          security, management, process and quality control credentials and
          certifications you may have.
        </p>
        <p className="lighter">
          For more information about access to an Enterprise Account, contact
          us: <b>{props.email}</b>
        </p>
      </div>
    </div>
  );
};

export default HeaderUnSub;
