import styled from "styled-components";

export const DialogWrapper = styled.div`
  * {
    font-family: "Manrope", sans-serif;
  }
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

export const DialogContent = styled.div`
  position: relative;
  padding: 87px 83px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 500px;

  min-width: 38rem;
  //   width: 40vw;
  max-height: 90vh;

  overflow: auto;
  width: 80vw;
  max-width: 70rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 15px;

  @media (max-width: 768px) {
    min-width: unset;
    padding: 57px 53px;
  }
  @media (max-width: 540px) {
    padding: 27px 23px;
    width: 90vw;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 85px;
  @media (max-width: 768px) {
    top: 50px;
    right: 65px;
  }
  @media (max-width: 540px) {
    top: 20px;
    right: 20px;
  }
`;
