import React, { useEffect } from "react";

export default function Entries(props) {
  const onChange = (e) => {
    e.persist();
    if (props.isYears) {
      props.setExpYears(e.target.value);
      props.setExp(e.target.value);
      props.setExpMonth("0");
    } else {
      props.setExpMonth(e.target.value);
      props.setExp(e.target.value);
      props.setExpYears("0");
    }
  };

  useEffect(() => {
    if (props.isYears) {
      if (parseInt(props.expMonth) > 0) {
        let exp = Math.floor(parseInt(props.expMonth) / 12);
        props.setExp(exp.toString());
      } else {
        props.setExp(props.expYears);
      }
    } else {
      if (parseInt(props.expYears) > 0) {
        let exp = parseInt(props.expYears) * 12;
        props.setExp(exp.toString());
      } else {
        props.setExp(props.expMonth);
      }
    }
  }, [props.isYears]);

  return (
    <div className="stack_entry">
      <div className="stack_entry_data">
        <label htmlFor="levels">Level(1-4)</label>
        <div className="data">
          <div className="range">
            <input
              type="range"
              min="1"
              max="4"
              className="stack_range"
              value={props.level}
              step="1"
              id="levels"
              list="levels"
              onChange={(e) => {
                props.setLevel(e.target.value);
              }}
            />
            <datalist id="levels">
              <option value="1" label="1">
                1
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </datalist>
          </div>
          <div className="output">
            <output name="x" htmlFor="a b" value="A">
              {props.level}
            </output>
          </div>
        </div>
      </div>

      <div className="stack_entry_data">
        <label htmlFor="exp">Experience</label>
        <div className="values">
          <input
            type="number"
            className="stack_input"
            min="0"
            id="exp"
            value={props.exp}
            onChange={(e) => onChange(e)}
          />

          <select
            name="time"
            id="time"
            className="stack_input"
            onChange={(e) => {
              e.persist();
              e.target.value === "years"
                ? props.setIsYears(true)
                : props.setIsYears(false);
            }}
          >
            <option value="years">years</option>
            <option value="month">months</option>
          </select>
        </div>
      </div>

      <div className="stack_entry_data">
        <label htmlFor="proj">Projects</label>
        <input
          type="number"
          className="stack_input"
          min="1"
          id="proj"
          value={props.proj}
          onChange={(e) => {
            e.persist();
            props.setProj(e.target.value);
          }}
        />
      </div>

      <div className="stack_entry_data">
        <label htmlFor="interests">Interest</label>

        <select
          name="interests"
          id="interests"
          className="stack_input"
          onChange={(e) => {
            e.persist();
            props.setInterest(e.target.value);
          }}
        >
          <option value="Interested">Interested</option>
          <option value="Not Interested">Not Interested</option>
          <option value="Slightly Interested">Slightly Interested</option>
        </select>
      </div>
    </div>
  );
}
