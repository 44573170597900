import React from "react";

export default function progessTemplate(props) {
  return (
    <div id="myProgress" className="pbar">
      <div id="myBar" className="bar">
        <div
          id={props.score <= 20 ? "wlabel" : "blabel"}
          className="barlabel"
        ></div>
      </div>
    </div>
  );
}
