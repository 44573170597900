import { useState, useEffect, useContext, useMemo } from "react";
import PopularSearch from "./pages/Markets/PopularSearch";
import YourPrices from "./pages/Markets/YourPrices";
import MyProduct from "./pages/Markets/MyProducts";
import SearchComponent from "./pages/Markets/Search";
import { GlobalVendorContex, AuthUserContext } from "./Firebase";
import RateCards from "./pages/Markets/RateCards";
import NewResources from "./pages/Markets/NewResources";
import { db, getDoc } from "./globals/services";

const Markets = (props) => {
  // Vendor Data
  const vendorData = useContext(GlobalVendorContex);
  const userData = useContext(AuthUserContext);

  //when a pricing is created
  const [isPricingCreated, setIsPricingCreated] = useState(false);

  //when search button is clicked
  const [isSearchClick, setIsSearchClick] = useState(false);

  //contain the current pageLink
  const [pageLink, setPageLink] = useState("");

  //contain the current generic id
  const [genericId, setGenericId] = useState("");

  const [isAddResources, setIsAddResource] = useState(false);

  //hold arrays of string with the combinations
  const [combination, setCombination] = useState([]);

  //Will hold the query data when we click on the search button && when we are editing a generic
  const [queryData, setQueryData] = useState({});

  //arrays of selected positions
  const [selectedResources, setSelectedResources] = useState([]);

  //hold arrays of all products
  const [prices, setPrices] = useState([]);

  //constain the spryte labs last term and condition
  const [lastTerm, setLastTerm] = useState(null);

  //hold an array of teams with respective country
  const [teams, setTeams] = useState([]);

  //When click on edit price button
  const [isPriceEdit, setIsPriceEdit] = useState(false);

  //when editing a pricing card it will hold an object containing all arrays
  const [selectedData, setSelectedData] = useState(null);

  //when apply button is clicked
  const [isApply, setIsApply] = useState(false);

  //store all of the selected pricing cards
  const [selectedRates, setSelectedRates] = useState([]);

  //list of pricing rate that will be displayed in the products grid page
  const [gridData, setGridData] = useState([]);

  //all rates
  const [rateCards, setRateCards] = useState([]);

  //When clicked on the Add Rate Button
  const [isAddRate, setIsAddRate] = useState(false);

  //array of boolean containing a check for already selected product
  const [checkedList, setCheckedList] = useState([]);

  //index of the current viewing popular search data
  const [viewingIndex, setViewingIndex] = useState(0);

  // length of the paginating data
  const [paginationLength, setPaginationLength] = useState(1);

  //
  const [isNextLoading, setIsnextLoading] = useState(false);

  // wether or not firebase has called the last queries

  /**
   * Takes the array of generics then
   * group them {
   * 'Generic Name':[
   * {generic1 doc data},
   * {generic2 doc data}
   * ],
   * 'Generic 2 Name':[...]
   * then reduce them to one data for each group
   * }
   *
   * @param {Array} generics array of all generics from firebase documents
   */
  const groupCards = (cardList) => {
    let pricesCards = []; //array to store the end list of generics
    let checks = [];

    // group generics by their names and types
    let groupedCards = cardList.reduce((r, a) => {
      r[a.id] = [...(r[a.id] || []), a];

      return r;
    }, {});

    // index will be saved as the element id
    //get all of the grouped generics values which will be a list of the same data with differents locations
    //   //and pricing matrix
    Object.values(groupedCards).forEach((group) => {
      let allLocations = [];
      let allPricing = [];
      let allIds = {};
      group.forEach((generic) => {
        allLocations.push(generic.locations[0]);
        allPricing.push(generic.pricingMatrix);
        allIds[generic.locations[0].country] = generic.Id;
      });

      //spread the group and remove locations and pricing matrix
      const { Id, pricingMatrix, locations, ...data } = group[0];
      //create the grid data to store all of the generics values with all locations and
      //pricing matrix
      let genericData = {
        // id: index,
        ids: allIds,
        locations: allLocations,
        pricingMatrix: allPricing,
        ...data,
      };
      pricesCards.push(genericData);
      checks.push(false);
    });

    setRateCards(pricesCards);
    setCheckedList(checks);
  };

  useEffect(() => {
    let selected = [];
    vendorData.vendorGenerics.forEach((generic) => {
      if (generic.isSelected) {
        selected.push(generic);
      }
    });
    setSelectedResources(selected);
  }, [vendorData.vendorGenerics]);

  /**
   * Listen to the prices streams
   * the rates cards stream
   * and the terms stream then return the last term
   */
  useEffect(() => {
    setPrices([]);
    setRateCards([]);
    let pricesStream;
    let lastTermRef;
    let rateCardStream;

    const getPrices = async () => {
      // =================================================prices Snapshot==============================================================
      pricesStream = await props.firebase.db
        .collection(`vendors/${vendorData.vendor.id}/generic`)
        .orderBy("createdOn", "desc")
        .onSnapshot((snap) => {
          let prices = [];
          snap.docs.forEach((priceDoc) => {
            let PriceData = priceDoc.data();
            PriceData["ID"] = priceDoc.id;
            PriceData["ref"] = priceDoc.ref;
            prices.push(PriceData);
          });
          setPrices(prices);
        });
      let spryteCollections = await props.firebase.db
        .collection("organisations")
        .where("name", "==", "Spryte Labs")
        .get();

      // =================================================Terms Snapshot==============================================================
      // use
      lastTermRef = spryteCollections.docs[0].ref
        .collection("terms")
        .where("platform", "==", "PARTNER")
        .onSnapshot((termsDocs) => {
          let terms = [];
          let lastTerm;
          termsDocs.forEach((termDoc) => {
            let term = termDoc.data();
            term["ref"] = termDoc.ref;
            terms.push(term);
          });

          if (terms.length > 1) {
            terms.sort((a, b) => {
              return a.version - b.version;
            });
          }
          lastTerm = terms[terms.length - 1];
          setLastTerm(lastTerm);
        });

      // ==========================================================RateCard Streams=====================================================
      rateCardStream = await props.firebase.db
        .collection(`vendors/${vendorData.vendor.id}/priceCards`)
        .orderBy("createdOn", "desc")
        .onSnapshot((snap) => {
          let prices = [];
          snap.docs.forEach((pricesDoc) => {
            let priceCard = pricesDoc.data();

            const {
              availability,
              status,
              type,
              location,
              pricingMatrix,
              ...rateCard
            } = priceCard;
            let cardData = {
              from: availability.from.toDate(),
              Id: pricesDoc.id,
              to: availability.to.toDate(),
              status: status ? "Active" : "Inactive",
              type: type === 0 ? "Generic Resource" : "Generic Service",
              locations: [location],
              pricingMatrix: pricingMatrix,
              ...rateCard,
            };
            prices.push(cardData);
          });
          groupCards(prices);
        });
    };
    getPrices();
    // props.firebase.reportPageVisits("Markets Page", userData.isSuperPartner);
    return () => {
      try {
        pricesStream();
        lastTermRef();
        rateCardStream();
      } catch (e) {
        return;
      }
    };
  }, [props.firebase, vendorData.vendor.id]);

  /**
   * This function queries getMatches for a team corresponding to the search criteria requested.
   *
   * @param {[Boolean=false]} responseExpected - whether a response is expected.
   *
   *
   * @returns {Promise<null>} Returns nothing. It only pings getMatches.
   */
  function search(
    projectTypes,
    stacks,
    industyDomains,
    queryType,
    locationName,
    responseExpected = false
  ) {
    // Create the request headers.
    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");

    // Create a date representing the day to query.
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 7);
    while ([0, 6].includes(startDate.getDay()))
      startDate.setDate(startDate.getDate() + 1);

    // The payload. Most keys are set by default for the query.
    const payload = {
      isANewProject: true, // Is this a new project?
      industryDomain: industyDomains, // Industries.
      projectTypes: projectTypes, // Categories.
      stacks: stacks, // Stacks.
      minTeamSize: 1, // The smallest team size accepted.
      maxTeamSize: 8, // The largest team size accepted.
      queryTypes: queryType, // The types of query to run.['global']
      clientLat: 37, // The client's latitude. This is used for connect team results.
      clientLon: 73, // The client's longitude. This is used for connect team results.
      locationScope: "country", // The location scope for the local results. Note that these words are Google Places API terms.
      locationName: locationName, // The value for the location scope. In this case, it will look for local teams in: Country - United States.
      responseExpected: responseExpected, // Whether we want results (as opposed to just caching).
      daysToCache: 30,
      vendorQuery: false,
      startDate: [
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate(),
      ],
    };

    let getMatchesURL = props.firebase.spryteCloudUrl("getMatches");
    // "https://us-central1-spryte-s.cloudfunctions.net/getMatches";

    // The request's options.
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: requestHeaders,
    };

    // Attempt to call getMatches.
    try {
      // Call the getMatches function.
      //    https://us-central1-spryte-app.cloudfunctions.net/getMatches
      // https://us-central1-spryte-s.cloudfunctions.net/getMatches
      // https://us-central1-spryte-p.cloudfunctions.net/getMatches
      fetch(getMatchesURL, requestOptions).catch((error) => console.log(error));
      // If an error occurred, do nothing.
    } catch (error) {}

    // Return.
    return;
  }

  /**
   * Takes to arrays and compare them in order to return the element that don't exist in the data we are comparing with
   *
   * @example
   * a = [{'id': 'abdoul', 'age': 20}, {'id': "Edgar", 'age': 20}, {'id': 'Dan', 'age':20}]
   * b = [{'id': 'Edgar', 'age': 20}, {'id': "Stephane", 'age': 20}, {'id': 'Dan', 'age':20}]
   * remove_existing(a, b)
   * returns => [{'id': "Stephane", 'age': 20}]
   *
   * @param {array} a => array of elements to compare with
   * @param {array} b => array of element to to be compared
   *
   * @returns array of element that don't already exist
   */
  const remove_existing = (a, b) => {
    let nonExisting = [];
    if (a.length) {
      for (let i = 0; i < a.length; i++) {
        let rest = b.filter((el) => el.id !== a[i].id);
        nonExisting.push(...rest);
      }
    } else {
      nonExisting.push(...b);
    }

    return nonExisting;
  };

  /**
   * When User Click on apply We will check to find if there is
   * no existing price that is already selected then remove the
   * existing ones and save the rest in the database
   */
  const onApplyCard = () => {
    if (selectedRates.length) {
      // remove existing data and return new one
      let ext = remove_existing(gridData, selectedRates);
      let status = false;

      let allData = [...gridData, ...ext];

      let allStatus = allData.filter((el) => el.status === "Active");
      status = allStatus.length > 0 ? true : false;
      let allStats = allData.map((data) => data.stats);
      let minPrice = Math.min.apply(
        Math,
        allStats.map((el) => el.minPrice)
      );
      let maxPrice = Math.max.apply(
        Math,
        allStats.map((el) => el.maxPrice)
      );

      let updatedData = {
        stats: {
          maxPrice: maxPrice,
          minPrice: minPrice,
        },
        status: status,
        lastUpdatedOn: new Date(),
        lastUpdatedBy: userData.userRef,
      };
      if (ext.length) {
        for (const selctRate of ext) {
          Object.values(selctRate.ids).forEach((id) => {
            let productData = {
              priceCardRef: props.firebase.db.doc(
                `vendors/${vendorData.vendor.id}/priceCards/${id}`
              ),
              createdOn: new Date(),
              createdBy: userData.userRef,
              lastUpdatedOn: new Date(),
              lastUpdatedBy: userData.userRef,
            };
            props.firebase.db
              .collection(
                `vendors/${vendorData.vendor.id}/generic/${genericId}/genProducts`
              )
              .add(productData);
            // props.firebase.reportPageVisits(
            //   "Generic Product Created",
            //   userData.isSuperPartner
            // );
          });
        }
        props.firebase.db
          .collection(`vendors/${vendorData.vendor.id}/generic/`)
          .doc(genericId)
          .set(updatedData, { merge: true });
      }
    }
    setIsApply(false);
    setSelectedRates([]);
  };

  /**
   * Takes array of projects, stacks, domain and loop
   * throught it to fine the id that match with a given
   * popular query data id
   *
   * @param {array} queryIds Array of object containing the raw
   * populare query (stacks, domains, and projectTypes)
   * with each query data containing the id
   *
   * @example case queryId is stacks => [{object of stack data}, {object of stack data}]
   *
   * @param {array} docs Array of existing data we already have with their respective ids
   * @returns array of strings conaining the ids
   */
  const getRelevantsName = (queryIds, docs) => {
    let relevantNames = [];
    if (queryIds.length) {
      for (const id of queryIds) {
        for (const doc of docs) {
          if (doc.id === id) {
            relevantNames.push(doc.name);
          }
        }
      }
    }

    return relevantNames;
  };

  /**
   * Listen to the popular query changes and wich
   * is an array of the recent query with got from firebase
   * and converts it in order for us to be able to display the combinations
   *
   * then add the new added array of queries to the arrays of array that we use for pagination
   */

  useEffect(() => {
    const fetchQueryData = async (queryData, collectionName) => {
      const data = await Promise.all(
        queryData?.map(async (id) => {
          const querySnapshot = await db
            .collectionGroup(collectionName)
            .where("id", "==", id)
            .get();
          return querySnapshot.docs[0].data()?.name;
        })
      );
      return data;
    };
    const fetchData = async () => {
      if (vendorData.popularQueries.length && !vendorData.isAllQueries) {
        let recentPopulare = [];
        for (const popularQuery of vendorData.popularQueries) {
          const [projects, domains, stacks] = await Promise.all([
            fetchQueryData(
              popularQuery.queryData?.projectTypes,
              "subcollections"
            ),
            fetchQueryData(popularQuery.queryData?.industryDomain, "industry"),
            fetchQueryData(popularQuery.queryData?.stacks, "technologies"),
          ]);
          let combo = [...domains, ...stacks, ...projects];
          let marketPopularQueries = {
            combination: combo,
            ...popularQuery,
          };
          recentPopulare.push(marketPopularQueries);
        }

        vendorData.setPopularQueries([]);
        let allPopulArray = [...vendorData.paginatinPopular, recentPopulare];
        vendorData.setPaginatingPopular(allPopulArray);
        setPaginationLength(allPopulArray.length);
      }
    };
    fetchData();
  }, [vendorData.popularQueries, prices, vendorData]);

  /**
   * Listens the prices, viewingIndex, vendorData.paginatinPopular states changes then checks
   * if the vendor have pricing matching a specifique
   */
  const curr = useMemo(() => {
    // console.log("prices", prices);
    // console.log("vendorData?.paginatinPopular", vendorData?.paginatinPopular);
    if (
      prices.length &&
      vendorData?.paginatinPopular?.length &&
      vendorData?.paginatinPopular?.[viewingIndex]
    ) {
      let newCurrent = [];
      vendorData?.paginatinPopular?.[viewingIndex]?.forEach((popular) => {
        let current = popular;

        prices.forEach((price) => {
          let vendorCombination = price.combination;
          let result =
            vendorCombination.length === popular.combination.length &&
            vendorCombination.every((com) => {
              return popular.combination.indexOf(com) !== -1;
            });
          if (result) {
            current = {
              ...popular,
              vendPrice: price,
              vendComb: price?.stats ?? { maxPrice: 0, minPrice: 0 },
            };
          }
        });
        newCurrent.push(current);
      });
      return newCurrent;
    }

    if (
      !prices.length &&
      vendorData?.paginatinPopular?.length &&
      vendorData?.paginatinPopular?.[viewingIndex]
    ) {
      return vendorData.paginatinPopular[viewingIndex];
    }
  }, [prices, viewingIndex, vendorData.paginatinPopular]);
  // console.log("curr", curr);
  /**
   * When User click on next button on the popupar queries,
   * we check if we have all firebase queries and if the page we are viewin is the last one
   * if both condition are true we will call firebase function to get new query and move to the called page
   */
  const onNextQueries = async () => {
    setIsnextLoading(true);
    // check to if we have queried the last query and we are on the last page
    if (!vendorData.isAllQueries && viewingIndex === paginationLength - 1) {
      try {
        let popular = await props.firebase.getPopularQueriesWithLimit(
          10,
          vendorData.lastQuery
        );
        // if we still have data on firebase
        if (popular[0].length) {
          vendorData.setPopularQueries(popular[0]);
          vendorData.setLastQuery(popular[1]);
          setViewingIndex(viewingIndex + 1);
        } else {
          vendorData.setIsAllQUeries(true);
        }
        setIsnextLoading(false);
      } catch (error) {}
    } else {
      //meaning that all of the queries are already in our front end

      if (viewingIndex >= paginationLength - 1) {
        setViewingIndex(0);
      } else {
        setViewingIndex(viewingIndex + 1);
      }
      setIsnextLoading(false);
    }
  };

  /**
   * triggers when User click on the previous button
   */
  const onPrevious = () => {
    if (viewingIndex > 0) {
      setViewingIndex(viewingIndex - 1);
    }
  };

  /**
   * Triggers when user click on the Add Pricing button on the popular query
   * and gets all data from that query then add it to firebase
   * and moves to my product page
   * @param {object} price => current query
   */
  const onAddPricing = (price) => {
    const { combination, queryData, queryFamilyHash } = price;
    let data = {
      createdBy: userData.userRef,
      createdOn: new Date(),
      lastUpdatedBy: userData.userRef,
      lastUpdatedOn: new Date(),
      combination: combination,
      combCalc: Math.pow(2, combination.length) - 1,
      status: false,
      queryRef: queryFamilyHash,
      queryData: queryData,
    };

    props.firebase.db
      .collection("vendors")
      .doc(vendorData.searchedVendor.id)
      .collection("generic")
      .add(data)
      .then((appQuery) => {
        // props.firebase.reportPageVisits(
        //   "Generic Created",
        //   userData.isSuperPartner
        // );
        setPageLink(`https://sprytelabs.com/search-results/${queryFamilyHash}`);
        setCombination(combination);
        setGenericId(appQuery.id);
        setIsPricingCreated(true);
        setIsSearchClick(false);
        search(
          [...queryData.projectTypes],
          [...queryData.stacks],
          [...queryData.industryDomain],
          ["global"],
          "global"
        );
      });
  };

  const onEdit = (genericId, comb, query, queryData) => {
    setGenericId(genericId);
    setCombination(comb);
    setIsPricingCreated(true);
    setPageLink(`https://sprytelabs.com/search-results/${query}`);
    setQueryData(queryData);
  };

  const onMarketDelete = async (docRef) => {
    let genPordRef = await docRef.collection("genProducts").get();
    if (genPordRef?.docs?.length) {
      for (const doc of genPordRef.docs) {
        doc.ref.delete();
      }
    }
    docRef.delete();
  };

  // console.log("vendorData", vendorData.popularQueries);

  /**
   * this function allows us to display the rate card anywhere we
   * want without calling the component everywhere
   * @returns RateCards Component
   */
  const renderRateCardComponent = () => {
    return (
      <RateCards
        lastTerm={lastTerm}
        getMatch={search}
        setIsAddResource={setIsAddResource}
        firebase={props.firebase}
        isAddRate={isAddRate}
        setIsAddRate={setIsAddRate}
        rateCards={rateCards}
        setTeams={setTeams}
        setIsPriceEdit={setIsPriceEdit}
        setSelectedData={setSelectedData}
        isApply={isApply}
        setIsApply={setIsApply}
        selectedRates={selectedRates}
        setSelectedRates={setSelectedRates}
        gridData={gridData}
        setGridData={setGridData}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
      />
    );
  };
  return (
    <div className="markets" style={{ paddingBottom: "50px" }}>
      {selectedResources.length ? (
        <>
          {isPricingCreated ? (
            <MyProduct
              pageLink={pageLink}
              genericId={genericId}
              setIsPricingCreated={setIsPricingCreated}
              isAddResources={isAddResources}
              setIsAddResource={setIsAddResource}
              combination={combination}
              selectedResources={selectedResources}
              firebase={props.firebase}
              vendorId={vendorData.searchedVendor.id}
              getMatch={search}
              queryData={queryData}
              isApply={isApply}
              setIsApply={setIsApply}
              renderRateCardComponent={renderRateCardComponent}
              onApplyCard={onApplyCard}
              gridData={gridData}
              setGridData={setGridData}
              rateCards={rateCards}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          ) : isAddRate || isPriceEdit ? (
            <NewResources
              selectedResources={selectedResources}
              firebase={props.firebase}
              genericId={genericId}
              vendorId={vendorData.searchedVendor.id}
              lastTerm={lastTerm}
              selectedData={selectedData}
              enableEdit={isPriceEdit}
              setEnableEdit={setIsPriceEdit}
              teams={teams}
              setSelectedData={setSelectedData}
              setIsAddRate={setIsAddRate}
              getMatch={search}
              queryData={queryData}
              userRef={userData.userRef}
              isSuperPartner={userData.isSuperPartner}
            />
          ) : (
            <>
              <div className="resource-header markets_header">
                {isSearchClick ? (
                  <div className="request docUpload">
                    <SearchComponent
                      firebase={props.firebase}
                      userRef={userData.userRef}
                      vendorId={vendorData.searchedVendor.id}
                      setIsPricingCreated={setIsPricingCreated}
                      setPageLink={setPageLink}
                      setGenericId={setGenericId}
                      setIsSearchClick={setIsSearchClick}
                      setCombination={setCombination}
                      search={search}
                      setQueryData={setQueryData}
                      isSuperPartner={userData.isSuperPartner}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="header-intro">
                  <h1>Markets of Interest</h1>
                  <div className="clientDescCtr">
                    <div className="warningCtr">
                      <div className="warning">
                        <h4>!</h4>
                      </div>
                      <div>
                        <p id="bold">
                          Please take great care to accurately describe your
                          Rate Cards & Markets of Interest- they are legally
                          binding.
                        </p>
                        <p>
                          Clients will find your teams & pricing based on the
                          markets you add. Adding a market means you confirm
                          that you have the capability to undertake the project.
                        </p>
                        <p className="cta">
                          If anything is unclear, we recommend you read through
                          the{" "}
                          <span>
                            <a
                              target="_blank"
                              href="https://sprytelabs.github.io/Spryte-Docs/#/partnerPlatform"
                              rel="noreferrer"
                            >
                              documentation
                            </a>
                          </span>{" "}
                          and, if needed,{" "}
                          <a
                            target="_blank"
                            href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo"
                            rel="noreferrer"
                          >
                            contact us
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="markets_content">
                {renderRateCardComponent()}
                <YourPrices
                  // setIsPricingCreated={setIsPricingCreated}
                  onEdit={onEdit}
                  isSearchClick={isSearchClick}
                  setIsSearchClick={setIsSearchClick}
                  pricesList={prices}
                  setGenericId={setGenericId}
                  setEdit={setIsPricingCreated}
                  setCombination={setCombination}
                  setPageLink={setPageLink}
                  setQueryData={setQueryData}
                  onDelete={onMarketDelete}
                />
                <PopularSearch
                  popularQueries={vendorData.popularQueries}
                  setPopularQueries={vendorData.setPopularQueries}
                  onNextQueries={onNextQueries}
                  onAddPricing={onAddPricing}
                  onEdit={onEdit}
                  currentPopular={curr}
                  paginationLength={paginationLength}
                  setPaginationLength={setPaginationLength}
                  viewingIndex={viewingIndex}
                  setViewingIndex={setViewingIndex}
                  paginatePopular={vendorData.paginatinPopular}
                  isNextLoading={isNextLoading}
                  isAll={vendorData.isAllQueries}
                  onPrevious={onPrevious}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div className="header-intro">
          <h1>Markets of Interest</h1>
          <div className="clientDescCtr">
            <div className="warningCtr">
              <div className="warning">
                <h4>!</h4>
              </div>
              <div>
                <p id="bold">
                  Please Select Your Organization Positions in the Account Page
                  to be able to add Pricing
                </p>
                <p>
                  Clients will find your teams & pricing based on the markets
                  you add. Adding a market means you confirm that you have the
                  capability to undertake the project.
                </p>
                <p className="cta">
                  If anything is unclear, we recommend you read through the{" "}
                  <span>
                    <a href="https://sprytelabs.github.io/Spryte-Docs/#/partnerPlatform">
                      documentation
                    </a>
                  </span>{" "}
                  and, if needed,{" "}
                  <a href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo">
                    contact us
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Markets;
