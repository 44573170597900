import React, { useEffect, useState } from "react";
import spryte from "./images/white-spryte.png";
import { auth, db, spApp, spryteOrgRef } from "./globals/services";
import { useNavigate } from "react-router-dom";

const RegisterUserFromDev = ({ authUser, firebase, devId, setIsDev }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [credErr, setCredErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [devData, setDevData] = useState(null);

  useEffect(() => {
    const getDevData = async () => {
      const devRef = db.collection("devs").doc(devId);
      const devDoc = await devRef.get();
      setDevData({ ...devDoc.data(), ref: devRef });

      if (devDoc.data()?.userRef) {
        // navigate("/");
        setCredErr(
          "User already exists, please logout and login with your email and password"
        );
      }
    };
    getDevData();
  }, [devId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getUser = async (email, password) => {
    let user;
    if (authUser?.auth?.isAnonymous) {
      user = authUser?.auth;
      const credential = spApp.auth.EmailAuthProvider.credential(
        email,
        password
      );

      await user.linkWithCredential(credential);
    } else {
      //   create user with email and password
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      user = userCredential.user;
    }
    console.log("user ", user); // user object
    return user;
  };

  const renderFirebaseErr = (err) => {
    setCredErr(err.message);
    if (err.code === "auth/email-already-in-use") {
      setCredErr("Email already in use");
    }
    if (err.code === "auth/weak-password") {
      setCredErr("Password should be at least 6 characters");
    }
    if (err.code === "auth/invalid-email") {
      setCredErr("Invalid email");
    }
    if (err.code === "auth/operation-not-allowed") {
      setCredErr("Operation not allowed");
    }
    if (err.code === "auth/argument-error") {
      setCredErr("Invalid email");
    }
    if (err.code === "auth/network-request-failed") {
      setCredErr("Network request failed");
    }
    if (err.code === "auth/too-many-requests") {
      setCredErr("Too many requests");
    }
    if (err.code === "auth/user-disabled") {
      setCredErr("User disabled");
    }
    if (err.code === "auth/user-not-found") {
      setCredErr("User not found");
    }
    if (err.code === "auth/wrong-password") {
      setCredErr("Wrong password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (devData?.userRef) {
      setIsDev(false);
      await auth.signOut();
      setIsLoading(false);
      navigate("/");
      return;
    }
    setIsLoading(true);
    const { email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setCredErr("Passwords do not match");
      setIsLoading(false);
      return;
    }
    const devsRef = db.collection("devs").doc(devId);
    const devDoc = await devsRef.get();
    const { first, last, orgRef, locTag, position, positionRef, vendorRef } =
      devDoc.data();

    const vendor = await vendorRef.get();

    const devReportingRef = db.collection("devsReporting").doc();

    const roleRef = db.doc(
      `/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/RESOURCE`
    );

    // role reference to be injected in the spryte-dev account as role after the user has been created
    const roleRef2 = db.doc(
      `/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/USER`
    );

    try {
      const user = await getUser(email, password);
      await firebase.createUser(
        {
          first: first, // Ensure you have these fields defined
          last: last,
          email: email,
          status: true,
          addedOn: new Date(),
          orgRef: orgRef || spryteOrgRef, // Ensure you have orgRef defined
        },
        user.uid
      );
      let devSpryteAccount = {
        role: roleRef,
        status: true,
        devRef: devsRef,
      };
      let reportingData = {
        addedOn: new Date(),
        city: locTag,
        companyName: vendor?.data()?.companyName || "",
        companyWebsite: vendor?.data()?.website || "",
        currentProjects: null,
        devId: devsRef,
        geoTag: null,
        firstName: first,
        lastName: last,
        freelancer: false,
        github: null,
        linkedInProfile: null,
        position: position,
        positionRef: positionRef,
        sprints: [],
        stakeholderOf: [],
      };

      let devSpryteAccount2 = {
        role: roleRef2,
        status: true,
        devsReportingId: devReportingRef,
      };

      const userRef = db.collection("users").doc(user.uid);

      await db.collection("devs").doc(devId).set(
        {
          userRef: userRef,
          isVerified: true,
        },
        { merge: true }
      );

      // Add Dev Reporting
      await db
        .collection("devsReporting")
        .doc(devReportingRef.id)
        .set(reportingData, { merge: true });
      await db
        .collection(`/users/${user.uid}/accounts`)
        .doc("spryte-partner")
        .set(devSpryteAccount, { merge: true });

      // Add dev spryte-dev account
      await db
        .collection(`/users/${user.uid}/accounts`)
        .doc("spryte-dev")
        .set(devSpryteAccount2, { merge: true });
      window.location = "/";
      navigate("/");
      window.location.reload();
      setIsLoading(false);
    } catch (err) {
      //   setCredErr(err.message);
      setIsLoading(false);
      //   console.log(err);
      renderFirebaseErr(err);
    }
  };

  return (
    <div className="login-div">
      <div className="register login">
        <div
          className="login-left"
          style={{
            padding: "20px",
          }}
        >
          <div
            className="spryte-ctr"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h2 className="spryte">spryte labs</h2>
            <div className="spryte-circle">
              <img src={spryte} alt="spryte logo" />
            </div>
          </div>

          <h1
            className="register-title"
            style={{
              fontSize: "1.8rem",
            }}
          >
            Create your user
          </h1>
          <form className="register-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label
                className="form-label"
                style={{
                  fontSize: "1.4rem",
                }}
              >
                Email Address:
              </label>
              <input
                type="email"
                name="email"
                className="form-input"
                value={formData.email}
                onChange={handleChange}
                required
                style={{
                  fontSize: "1.4rem",
                }}
                readOnly={devData?.userRef ? true : false}
              />
            </div>
            <div className="form-group">
              <label
                className="form-label"
                style={{
                  fontSize: "1.4rem",
                }}
              >
                Password:
              </label>
              <input
                type="password"
                name="password"
                className="form-input"
                value={formData.password}
                onChange={handleChange}
                required
                style={{
                  fontSize: "1.4rem",
                }}
                readOnly={devData?.userRef ? true : false}
              />
            </div>
            <div className="form-group">
              <label
                className="form-label"
                style={{
                  fontSize: "1.4rem",
                }}
              >
                Confirm Password:
              </label>
              <input
                type="password"
                name="confirmPassword"
                className="form-input"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                style={{
                  fontSize: "1.4rem",
                }}
                readOnly={devData?.userRef ? true : false}
              />
            </div>
            <button
              type="submit"
              className="form-button"
              style={{
                padding: "10px",
                // width: "100%",
                marginTop: "10px",
                backgroundColor: isLoading ? "#ccc" : "#6449ff",
                color: isLoading ? "#000" : "white",
                fontWeight: "bold",
                // border: "1px solid #000",
                borderRadius: "5px",
              }}
              disabled={isLoading}
            >
              {isLoading
                ? "Please wait..."
                : devData?.userRef
                ? "Logout"
                : "Create Account"}
            </button>
          </form>
          {credErr && (
            <p
              className="cred-err"
              style={{
                color: "red",
                fontSize: "1.4rem",
                marginTop: "10px",
                // textAlign: "center",
              }}
            >
              {credErr}
            </p>
          )}
          {/* <p className="register-footer">
            Don't have an account? Please register by{" "}
            <span>
              <a
                href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo"
                target="_blank"
                rel="noreferrer"
              >
                contacting us
              </a>
            </span>
            .
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default RegisterUserFromDev;
