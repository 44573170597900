import { HighlightOff } from "@mui/icons-material";
import Select from "react-select";

const NewDomain = (props) => {
  return (
    <div>
      <div className="sp-form_item">
        <label>Name</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setNewData({
              ...props.newData,
              name: e.target.value,
            })
          }
          value={props.newData.name}
        />
      </div>
      <div className="sp-form_item">
        <label>search keyword</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setNewData({
              ...props.newData,
              searchKeywords: e.target.value,
            })
          }
          value={props.newData.searchKeywords}
        />
      </div>
      <div className="row uploader spbtn">
        <div>
          <input
            type="file"
            id="stackLogo"
            onChange={(e) => props.selectLogo(e)}
            accept=".png, .jpg, .jpeg"
            hidden
          />
          <button
            id="stacklogoBtn"
            className="btn spbtn-sub"
            onClick={(e) => props.handleOpen(e, "stackLogo")}
          >
            Upload Category Logo
          </button>
        </div>
        <div className="uploader-preview">
          <img
            className="inputs-row_main-preview__img"
            src={props.logo.url ?? props.newData.logoStorage}
            alt={props.logo?.file?.name}
          />
          {props.logo.url ? (
            <HighlightOff
              onClick={() => {
                props.setLogo({});
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {props.openDialog && !props.isSub ? (
        <div className="sp-form_item">
          <label>Category</label>
          <Select
            options={props.techsCategories}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            // defaultValue={[]}
            onChange={(e) =>
              props.setNewData({
                ...props.newData,
                category: e,
              })
            }
          />
        </div>
      ) : (
        <></>
      )}
      {props.isEdit ? (
        <div className="sp-form_item">
          <label for="status">Show</label>
          {/* <Select
            options={props.show}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            defaultValue={{
              value: props.newData.show,
              label: `${props.newData.show}`,
            }}
            onChange={(e) =>
              props.setNewData({
                ...props.newData,
                show: e,
              })
            }
          /> */}
          <select
            name="status"
            id="status"
            onChange={(e) => {
              let status = e.target.value === "true" ? true : false;
              props.setNewData({
                ...props.newData,
                show: status,
              });
              e.persist();
            }}
          >
            <option value={true} selected={props.newData.show ? true : false}>
              True
            </option>
            <option value={false} selected={!props.newData.show ? true : false}>
              False
            </option>
          </select>
        </div>
      ) : (
        <>:</>
      )}

      <div className="sp-form_item">
        <label>Description</label>
        <textarea
          className="sp-form_item--txtarea sp-form_item--txtarea_btm"
          onChange={(e) =>
            props.setNewData({
              ...props.newData,
              description: e.target.value,
            })
          }
          value={props.newData.description}
        ></textarea>
      </div>
    </div>
  );
};
export default NewDomain;
