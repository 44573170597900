import React, { useState } from "react";
import { ReqTableRow } from "../../../../globals/ReqTable/style";
import { MATCHTABLECOLUMNS } from "./constants";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../../globals/SpIcons";

import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SpExcerptText from "../../../../globals/SpExerptText/SpExcerptText";
import SingleMatchDev from "../../../Requirements/AllRequirement/SingleMatchDev";
import ScoreProgress from "./ScoreProgress";
import MatchCriteria from "./MatchCriteria";
import SingleMatchPosition from "./SingleMatchPosition";

// motion component variants for Info and Matches
const infoMatchesVariants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

// motion component variants for Arrow icon
const arrowVariants = {
  open: { rotate: 180 },
  collapsed: { rotate: 0 },
};

const SingleMatch = ({
  list,
  vendor,
  isSuperPartner,
  lastTerm,
  tableData,
  ...props
}) => {
  const [showMore, setShowMore] = useState(false);
  const history = useNavigate();
  const [matchDevs, setMatchDevs] = useState([]);
  const [matchScore, setMatchScore] = useState(80);

  // create a function that will take up to 100 and return the opacity value
  const getOpacity = (num) => {
    if (num > 100) return 1;
    if (num < 0) return 0;
    return num / 100;
  };

  const renderClientName = (type) => {
    if (type === "C") {
      return (
        tableData?.sourceData?.profile?.name ??
        tableData?.sourceData?.crm?.data?.properties?.name?.value
      );
    }
    return "SuperPartner";
  };

  return (
    <div className="requirementRow" {...props}>
      <ReqTableRow
        gridColumn={MATCHTABLECOLUMNS}
        className="requirementRow--top"
        // onClick={() => setShowMore(!showMore)}
        style={{
          borderBottom: showMore ? "1px solid #DBDBDB" : "none",
          paddingBottom: showMore ? "1rem" : "0",
          marginBottom: showMore ? "1rem" : "0",
        }}
      >
        <div className="boldText matchedOn">
          <span>
            {tableData?.matchedOn?.toDate()?.toDateString()?.slice(4, 25) ??
              new Date().toDateString()?.slice(4, 25)}
          </span>
        </div>
        <div className="type boldText">
          <p
            style={{
              backgroundColor:
                tableData?.matchType === "R" ? "#6867FF" : "black",
              color: "white",
              padding: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
              height: "40px",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {tableData?.matchType}
          </p>
        </div>
        <div className="client boldText">
          <span
            style={{
              filter: tableData?.matchType === "R" ? "blur(6px)" : "none",
              userSelect: tableData?.matchType === "R" ? "none" : "auto",
            }}
          >
            {renderClientName(tableData?.matchType)}
          </span>
        </div>
        <div className="matchCriteria">
          <MatchCriteria points={tableData?.pointsData} />
        </div>
        <div className="matchScore">
          {/* <span>{tableData?.mScore}</span> */}
          <ScoreProgress score={tableData?.mScore?.toFixed(0)} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon
            cls="_spHover"
            stroke="black"
            onClick={tableData?.buttons?.[0]?.action}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <motion.div
            className="arrowDown"
            variants={arrowVariants}
            animate={showMore ? "open" : "collapsed"}
            onClick={() => setShowMore(!showMore)}
          >
            <KeyboardArrowDownIcon />
          </motion.div>
        </div>
      </ReqTableRow>
      <AnimatePresence>
        {showMore && (
          <motion.div
            className="requirementRow--info"
            variants={infoMatchesVariants}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <div
              className="requirementRow--info_match"
              style={{
                backgroundColor:
                  tableData?.mScore > 0
                    ? `rgba(63, 221, 152, ${getOpacity(tableData?.mScore)})`
                    : "#F6F6F6",
              }}
            >
              <span className="requirementRow--info_match__count">
                {tableData?.mScore > 0
                  ? `${tableData?.mScore?.toFixed(0)}%`
                  : "Not"}
              </span>
              <span className="requirementRow--info_match__text">
                {tableData?.mScore > 0 ? "Match" : "Matched"}
              </span>
            </div>
            <div className="requirementRow--info_desc">
              <SpExcerptText
                content={tableData?.descData?.desc}
                maxContentheight={74}
                lines={3}
                id={tableData?.ref?.id + "out"}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* {matchDevs?.length > 0 && ( */}
      {/* <AnimatePresence>
        {showMore && (
          <motion.div
            className="requirementRow--matches"
            variants={infoMatchesVariants}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <h5>You have Matching Profiles ({matchDevs?.length} resources)</h5>
            <div className="requirementRow--matches_cards">
              {matchDevs
                  ?.sort((a, b) => b?.reqMatchScore - a?.reqMatchScore)
                  ?.slice(0, 6)
                  .map((devMatchDoc) => {
                    return (
                      <SingleMatchDev
                        devData={devMatchDoc}
                        key={devMatchDoc?.id}
                      />
                    );
                  })}
              <SingleMatchPosition />
              <SingleMatchPosition />
              <SingleMatchPosition />
              <SingleMatchPosition />
              <SingleMatchPosition />
              <SingleMatchPosition />
              <SingleMatchPosition />
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
      {/* )} */}
    </div>
  );
};

export default SingleMatch;
