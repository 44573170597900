import React from "react";

const LinkedInIcon = ({ size = "20", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.663513 0.663513L0 1.32676V10V18.6732L0.663513 19.3365L1.32676 20H10H18.6732L19.3365 19.3365L20 18.6732V10V1.32676L19.3365 0.663513L18.6732 0H10H1.32676L0.663513 0.663513ZM3.96081 4.00784C3.08811 4.97216 3.75162 6.48649 5.04703 6.48649C5.72595 6.48649 6.48649 5.67622 6.48649 4.95297C6.48649 3.71486 4.79784 3.08297 3.96081 4.00784ZM11.7568 7.87973C11.4595 8.04216 11.0643 8.36594 10.8784 8.59865C10.5822 8.97027 10.5405 8.94946 10.5405 8.43C10.5405 7.90838 10.3957 7.83784 9.32432 7.83784H8.10811V12.027V16.2162H9.29892H10.49L10.5827 13.4892C10.6676 10.9976 10.7314 10.7173 11.3214 10.2397C11.9603 9.72297 12.7781 9.75216 13.3784 10.3135C13.527 10.4524 13.69 11.8376 13.7408 13.3914L13.833 16.2162H15.0246H16.2162V13.0814C16.2162 9.43513 15.8562 8.31811 14.5324 7.85676C13.4846 7.49135 12.4511 7.5 11.7568 7.87973ZM3.78378 12.027V16.2162H5H6.21622V12.027V7.83784H5H3.78378V12.027Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkedInIcon;
