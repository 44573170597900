class Project {
  constructor(id, projectName, projectStartDate, projectEndDate) {
    this.TaskId = id;
    this.TaskName = projectName;
    this.StartDate = projectStartDate;
    this.EndDate = projectEndDate;
    this.subtasks = [];
  }

  /**
   * Add new sprintData classe to the project
   * @param {object} sprintData
   */
  addSprintData(sprintData) {
    this.subtasks = sprintData;
  }

  /**
   * Checks if the sprint does not already exist
   * if the sprint does not exist it will add the sprint to the subtask
   * @param {object} sprint
   */
  addSprint(sprint) {
    if (sprint.projectId === this.TaskId) {
      if (this.subtasks.length < 1) {
        this.subtasks.push(sprint);
      } else if (
        this.subtasks.length >= 1 &&
        !this.subtasks.some((spt) => spt.TaskId === sprint.TaskId)
      ) {
        this.subtasks.push(sprint);
      }
    }
  }

  /**
   * return the list of sprintDatas
   */
  getSprintDatas() {
    return this.subtasks;
  }
}
export default Project;
