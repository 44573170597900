import React from "react";
// import { analytics } from "../../components/Firebase/firebase";
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items??[]];
    if (sortConfig !== null) {
      sortableItems?.sort((a, b) => {
        if (sortConfig.isDate) {
          if (a[sortConfig.key].toDate() < b[sortConfig.key].toDate()) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key].toDate() > b[sortConfig.key].toDate()) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else if (sortConfig.sub) {
          if (
            a[sortConfig.key][sortConfig.sub] <
            b[sortConfig.key][sortConfig.sub]
          ) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (
            a[sortConfig.key][sortConfig.sub] >
            b[sortConfig.key][sortConfig.sub]
          ) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else if (sortConfig.isArray) {
          if (a[sortConfig.key].toString() < b[sortConfig.key].toString()) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (a[sortConfig.key].toString() > b[sortConfig.key].toString()) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, isDate = false, sub = "", isArray = false) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    // TODO: Track Open Requirements Table Sort
    // analytics.track('Sort Action on Open Requirements Table', {
    //   'sort_type': key,
    //   'direction': direction,
    // });
    setSortConfig({ key, direction, isDate, sub, isArray });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
