import React, { useEffect, useState, useContext } from "react";
import {
  StacksCollection,
  DomainsCollection,
  ProjectsCollection,
} from "./pages/Collection_management";
import { GlobalVendorContex } from "./Firebase";
import { Animated } from "react-animated-css";
import Subsectors from "./pages/Collection_management/Subsectors";
import { getDocs } from "./globals/services";
import { useCategories, useDomains, useStacks } from "./hooks/useTechs";

const ColManagement = (props) => {
  // User data
  const allData = useContext(GlobalVendorContex);
  const [documentData, setDocumentData] = useState(null);

  const {
    stacks: allStacks,
    lastVisible,
    onNext,
    stacksCategories,
    refreshData,
  } = useStacks(50, 20);

  const {
    domains: allDomains,
    lastVisible: domainLastVisible,
    onNext: domainOnNext,
    domainsCategories,
    refreshData: refreshDomainData,
  } = useDomains(24, 12);

  const {
    categories: allCategories,
    lastVisible: categoryLastVisible,
    onNext: categoryOnNext,
    projectsCategories,
    refreshData: refreshCategoryData,
  } = useCategories(24, 12);

  const [stacks, setStacks] = useState({
    allStacks: [],
    loading: true,
    lastVisible: null,
  });
  const [techsCategories, setTechCategories] = useState([]);
  // const [catObjects, setCatObjects] = useState({});
  const [projCategories, setProjCategories] = useState([]);
  const [domainCategories, setDomainCategories] = useState([]);
  const [projObjects, setProjObjects] = useState({});
  const [domainsObject, setDomainObjects] = useState({});
  const [selectedSector, setSelectedSector] = useState("");
  const [viewSubs, setViewSubs] = useState(false);
  const [subsectors, setSubSectors] = useState([]);
  const [visible, setVisible] = useState(false);
  const [domId, setDomId] = useState("");

  const showData = [
    { value: true, text: true },
    { value: false, text: false },
  ];

  const onStacksPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * 50 >= allStacks.length;

    if (isLastPage && lastVisible) {
      // Fetch next 50 stacks
      onNext();
    }
  };

  const onDomainsPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * 12 >= allDomains.length;

    if (isLastPage && domainLastVisible) {
      // Fetch next 50 stacks
      domainOnNext();
    }
  };

  const onCategoriesPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * 12 >= allCategories.length;
    // console.log("isLastPage", isLastPage);
    // console.log("categoryLastVisible", categoryLastVisible);
    // console.log("page", page);

    if (isLastPage && categoryLastVisible) {
      // Fetch next 50 stacks
      categoryOnNext();
    }
  };

  useEffect(() => {
    if (stacksCategories?.length) {
      const techsCategories = stacksCategories.map((category) => {
        return { value: category.id, label: category.name };
      });
      // const categoriesId = {};
      // stacksCategories.forEach((category) => {
      //   categoriesId[category.id] = category.name;
      // });
      setTechCategories(techsCategories);
      // setCatObjects(categoriesId);
    }
  }, [stacksCategories]);

  useEffect(() => {
    if (domainsCategories?.length) {
      const projCategories = domainsCategories.map((category) => {
        return { value: category.id, label: category.name };
      });
      const categoriesId = {};
      domainsCategories.forEach((category) => {
        categoriesId[category.name] = category.id;
      });
      setDomainCategories(projCategories);
      setDomainObjects(categoriesId);
    }
  }, [domainsCategories]);

  useEffect(() => {
    if (projectsCategories?.length) {
      const projCategories = projectsCategories.map((category) => {
        return { value: category.id, label: category.name };
      });
      const categoriesId = {};
      projectsCategories.forEach((category) => {
        categoriesId[category.name] = category.id;
      });
      setProjCategories(projCategories);
      setProjObjects(categoriesId);
    }
  }, [projectsCategories]);

  useEffect(() => {
    let defaultSelected = selectedSector ?? allDomains[0];
    if (!viewSubs || !defaultSelected) {
      return;
    }

    setDomId("collect_edit_subdomain");
    let subsectors = props.firebase.db
      .collection(`${defaultSelected?.ref.path}/subSectors`)
      .onSnapshot((snap) => {
        let subs = [];
        snap.docs.forEach((doc) => {
          let data = {
            ...doc.data(),
            ref: doc.ref,
            id: doc.id,
          };
          subs.push(data);
        });
        setSubSectors(subs);
      });
    return () => {
      try {
        subsectors();
      } catch (e) {
        return;
      }
    };
  }, [viewSubs, selectedSector, allDomains]);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = props.firebase.db
          .collection("catalog")
          .doc(props.documentId);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          const docData = docSnapshot.data();
          setDocumentData(docData);
        } else {
          setDocumentData(null);
        }
      } catch (error) {
        setDocumentData(null);
      }
    };

    fetchDocumentData();
  }, [props.documentId]); // Fetch whenever documentId changes

  const updateStack = async (data, collectionId, stackId) => {
    let updates = {};
    if (data.name === "name") {
      updates["title"] = data.value;
    }

    updates[data.name] = data.value;
    if (data.name === "rank") {
      updates[data.name] = parseInt(data.value);
    }
    if (data.name === "show") {
      updates[data.name] = data.value === "true" ? true : false;
    }

    await props.firebase.updateTechStack(collectionId, stackId, updates);
    refreshData();
  };

  const updateDomain = async (data, collectionId, sectorId) => {
    let updates = {};

    updates[data.name] = data.value;
    if (data.name === "show") {
      updates[data.name] = data.value === "true" ? true : false;
    }

    await props.firebase.updateSectors(collectionId, sectorId, updates);
  };

  const updateProject = async (data, collectionId, sectorId) => {
    let updates = {};

    updates[data.name] = data.value;
    if (data.name === "show") {
      updates[data.name] = data.value === "true" ? true : false;
    }

    await props.firebase.updateCategory(collectionId, sectorId, updates);
  };

  /**
   * Changing the visibility wether the add template is vible or not
   *
   */
  useEffect(() => {
    if (!viewSubs) setTimeout(() => setVisible(false), 650);
    else setTimeout(() => setVisible(true), 650);
  }, [viewSubs]);

  return (
    <div className="colManagement">
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutRight"
        isVisible={viewSubs}
        style={visible ? null : { display: "none" }}
      >
        {viewSubs && (
          <Subsectors
            domains={subsectors}
            selectedSector={
              selectedSector !== "" ? selectedSector : allDomains[0]
            }
            categoriesId={domainsObject}
            firebase={props.firebase}
            domainCategories={domainCategories}
            updateDomain={updateDomain}
            showData={showData}
            setViewSubs={setViewSubs}
            subSectors={subsectors}
            domId={domId}
          />
        )}
      </Animated>

      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutLeft"
        isVisible={!viewSubs}
        style={!visible ? null : { display: "none" }}
      >
        <div className="resource-header">
          <div className="header-intro">
            <h3>MANAGE SPRYTE DATA COLLECTIONS</h3>
            <p>Add or edit Spryte Stacks, Categories & Industry Domains</p>
          </div>
        </div>
        <div className="colManagement_body">
          {allStacks.length ? (
            <StacksCollection
              stacks={allStacks}
              // categoriesId={catObjects}
              firebase={props.firebase}
              techsCategories={techsCategories}
              updateStack={updateStack}
              showData={showData}
              onStacksPaginate={onStacksPaginate}
              refreshData={refreshData}
            />
          ) : (
            <>loading stacks...</>
          )}
          {allDomains.length ? (
            <DomainsCollection
              setSelectedSector={setSelectedSector}
              domains={allDomains}
              categoriesId={domainsObject}
              firebase={props.firebase}
              domainCategories={domainCategories}
              updateDomain={updateDomain}
              showData={showData}
              setViewSubs={setViewSubs}
              onDomainsPaginate={onDomainsPaginate}
              refreshData={refreshDomainData}
            />
          ) : (
            <>Loading Domain...</>
          )}

          {allCategories.length ? (
            <ProjectsCollection
              projects={allCategories}
              categoriesId={projObjects}
              firebase={props.firebase}
              domainCategories={projCategories}
              updateStack={updateProject}
              showData={showData}
              onCategoriesPaginate={onCategoriesPaginate}
              refreshData={refreshCategoryData}
            />
          ) : (
            <>Loading projects...</>
          )}
        </div>
      </Animated>
    </div>
  );
};

export default ColManagement;
