import React from "react";
import GridCert from "./Subs/GridCert";

/**
 * SubsPage is the Page of the Subscribers and
 * @param {*} props are coming from Certifications.js
 */
const SubsPage = (props) => {
  return (
    <div>
      {props.headerCert}

      <GridCert
        certs={props.certs}
        editSettings={props.editSettings}
        onQueryCellInfo={props.onQueryCellInfo}
        progessTemplate={props.progessTemplate}
      />
    </div>
  );
};

export default SubsPage;
