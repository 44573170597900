import React from "react";

export default function SpLabelInfo({ title, description, addClass = "" }) {
  return (
    <div className={`SpLabelInfo ${addClass}`}>
      <label className="SpLabelInfo-label">{title}</label>
      <p className="SpLabelInfo-description">{description}</p>
    </div>
  );
}
