import * as React from "react";
import {
  GanttComponent,
  EditDialogFieldsDirective,
  DayMarkers,
  EditDialogFieldDirective,
  Inject,
  Edit,
  Selection,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
  AddDialogFieldsDirective,
  AddDialogFieldDirective,
} from "@syncfusion/ej2-react-gantt";
import { SampleBase } from "../../../sample-base";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

export default class ResourceAllocation extends SampleBase {
  constructor() {
    super(...arguments);
    this.taskFields = {
      id: "TaskID",
      name: "Description",
      startDate: "StartDate",
      endDate: "EndDate",
      duration: "Duration",
      progress: "Progress",
      notes: "info",
    };
    this.editSettings = {
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      allowTaskbarEditing: false,
      showDeleteConfirmDialog: true,
    };
    this.splitterSettings = {
      columnIndex: 2,
    };
    this.projectStartDate = new Date("03/25/2019");
    this.projectEndDate = new Date("07/28/2019");
    this.gridLines = "Both";
    this.toolbar = [
      "Add",
      "Edit",
      "Update",
      "Delete",
      "Cancel",
      "ExpandAll",
      "CollapseAll",
      "ZoomIn",
      "ZoomOut",
      "ZoomToFit",
    ];
    this.timelineSettings = {
      topTier: {
        unit: "Year",
        format: "MMM dd, y",
      },
      bottomTier: {
        unit: "Week",
      },
    };
    this.labelSettings = {
      leftLabel: "Description",
    };

    this.childTaskbarTemplate = this.taskbarTemplate.bind(this);
  }

  componentDidMount() {
    this.setState({
      isRequest: false,
    });
  }

  //get gantt grid data in the props
  // Change taskbar background color depending on the status value
  taskbarTemplate(props) {
    if (props.Status === "ONGOING") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#2d98da",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "CANCELED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#ff6b6b",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "COMPLETED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#1dd1a1",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'-4%', fontSize: "12px", borderRadius: "30px", textOoverflow: "ellipsis", height: "100%", overflow: "hidden", background: 'red', width:'100%'}}></span> */}
        </div>
      );
    } else if (props.Status === "PLANNED") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#fdcb6e",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else if (props.Status === "DRAFT") {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#636e72",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    } else {
      return (
        <div
          className="e-gantt-child-taskbar e-custom-moments"
          style={{
            height: "100%",
            borderRadius: "30px",
            background: "#82ccdd",
          }}
        >
          {/* <span className="e-task-label" style={{ position: "absolute", left:'50%', fontSize: "12px", textOoverflow: "ellipsis", height: "90%", overflow: "hidden" }}></span> */}
        </div>
      );
    }
  }
  async actionComplete(args) {
    // Changing the Dialog header Depending on the editing or adding availability
    if (args.requestType === "openAddDialog") {
      let header = args.element.firstChild.children[1];
      header.innerText = "Availability";
      let textBox = document.querySelector("#EditingDescription");
      textBox.value = "New Sprint " + args.data.TaskID;
    } else if (args.requestType === "openEditDialog") {
      let header = args.element.firstChild.children[1];
      header.innerText = "Edit Availability";
    }
    if (args.action === "add") {
      let newTask = args.newTaskData; //New data being added to the grid

      let { Description, ...task } = newTask; //destructuring the new data to manipulate Description
      // Error handling for improper date range format
      if (task.StartDate > task.EndDate) {
        toast.error(
          "Start Date cannot be after the End Date. Availability has not been added, please delete or refresh."
        );
        return false;
      }
      let value = "New Task " + task.TaskID;
      let newDescription = "";
      if (Description === value) {
        newDescription = "New Sprint " + task.TaskID; //changing the description to New sprint plus the task id
        //if the description is equal to new task plus id
      } else {
        newDescription = Description;
      }
      let add = {
        Description: newDescription,
        ...task,
      };
      await this.props.firebase.createTask(this.props.devId, add);

      // Updating lastUpdated Key for the dev
      await this.props.firebase.updateDev(this.props.devId, {});
    } else if (args.action === "DialogEditing") {
      let modifyDatas = args.modifiedRecords;
      modifyDatas.forEach(async (modiData) => {
        let availability = modiData.taskData;
        let { Id, ...updates } = availability;
        let task = {
          description: updates.Description,
          duration: updates.Duration,
          end: updates.EndDate,
          start: updates.StartDate,
          taskId: updates.TaskID,
          info: updates.info,
        };
        await this.props.firebase.updateTask(this.props.devId, Id, task);

        // Updating lastUpdated Key for the dev
        await this.props.firebase.updateDev(this.props.devId, {});
      });
    } else if (args.requestType === "delete") {
      if (args.data.length) {
        args.data.forEach(async (task) => {
          let deleteData = task.taskData;
          // Check that the avail has an Id and that it is in the database.
          // Incorrect data will show up on the grid but will not exist in the
          // database and so cannot be deleted.
          if (deleteData.Id) {
            const { Id } = deleteData;
            await this.props.firebase.deleteTask(this.props.devId, Id);

            // Updating lastUpdated Key for the dev
            await this.props.firebase.updateDev(this.props.devId, {});
          }
        });
      }
    }
  }

  render() {
    return (
      <div
        className={
          this.props.devPage === "avail" ? "control-section" : "hide_page"
        }
      >
        <ToastContainer />
        <div className="resources_header">
          <div className="resources_header_left">
            <div className="title">
              <h3>Your Availability</h3>
              <FontAwesomeIcon icon={faCalendarCheck} className="icon" />
            </div>
            <p>
              Define projects you are currently working on, or booked for in the
              future. You can only be booked for projects on the Spryte Platform
              for dates where you are available on this grid.
            </p>
            <div className="pallette">
              <div className="colorBox planned"></div>
              <p>DRAFT</p>
              <div className="colorBox draft"></div>
              <p>PLANNED</p>
              <div className="colorBox booked"></div>
              <p>BOOKED</p>
              <div className="colorBox ongoing"></div>
              <p>ONGOING</p>
              <div className="colorBox compl"></div>
              <p>COMPLETED</p>
              <div className="colorBox cancelled"></div>
              <p>CANCELLED</p>
            </div>
          </div>
        </div>
        {/* Availability defies camelcase convention as titlecase seems to 
          be how syncfusion wants it */}
        {this.props.holidays.length ? (
          <GanttComponent
            id="Editing"
            dataSource={this.props.availability}
            dateFormat={"MMM dd, y"}
            // width='450px'
            showColumnMenu={false}
            taskFields={this.taskFields}
            timelineSettings={this.timelineSettings}
            labelSettings={this.labelSettings}
            splitterSettings={this.splitterSettings}
            height={this.props.availability.length > 10 ? "auto" : "510px"}
            editSettings={this.editSettings}
            gridLines={this.gridLines}
            toolbar={this.toolbar}
            resourceFields={this.resourceFields}
            actionComplete={this.actionComplete.bind(this)}
            taskbarTemplate={this.childTaskbarTemplate}
            holidays={this.props.holidays}
            // minWidth='442px'
          >
            <ColumnsDirective>
              <ColumnDirective
                field="TaskID"
                visible={false}
                width="60"
              ></ColumnDirective>
              <ColumnDirective
                field="Description"
                headerText="Description"
                width="250"
                clipMode="EllipsisWithTooltip"
              ></ColumnDirective>
              <ColumnDirective
                field="StartDate"
                headerText="Start Date"
              ></ColumnDirective>
              <ColumnDirective
                field="EndDate"
                headerText="End Date"
              ></ColumnDirective>
              <ColumnDirective
                field="Duration"
                headerText="Duration"
              ></ColumnDirective>
              <ColumnDirective
                field="Status"
                headerText="Sprint Status"
              ></ColumnDirective>
            </ColumnsDirective>
            <EditDialogFieldsDirective>
              <EditDialogFieldDirective
                type="General"
                headerText="General"
                fields={["Description", "StartDate", "EndDate", "Duration"]}
              ></EditDialogFieldDirective>
              <EditDialogFieldDirective type="Notes"></EditDialogFieldDirective>
            </EditDialogFieldsDirective>
            <AddDialogFieldsDirective>
              <AddDialogFieldDirective
                type="General"
                headerText="General"
                fields={["Description", "StartDate", "EndDate", "Duration"]}
              ></AddDialogFieldDirective>
              <AddDialogFieldDirective type="Notes"></AddDialogFieldDirective>
            </AddDialogFieldsDirective>
            <Inject services={[Edit, Selection, Toolbar, DayMarkers]} />
          </GanttComponent>
        ) : (
          <GanttComponent
            id="Editing"
            dataSource={this.props.availability}
            dateFormat={"MMM dd, y"}
            treeColumnIndex={1}
            allowSelection={true}
            showColumnMenu={false}
            highlightWeekends={true}
            allowUnscheduledTasks={true}
            taskFields={this.taskFields}
            timelineSettings={this.timelineSettings}
            labelSettings={this.labelSettings}
            splitterSettings={this.splitterSettings}
            height="410px"
            editSettings={this.editSettings}
            gridLines={this.gridLines}
            toolbar={this.toolbar}
            resourceFields={this.resourceFields}
            actionComplete={this.actionComplete.bind(this)}
            taskbarTemplate={this.childTaskbarTemplate}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="TaskID"
                visible={false}
                width="60"
              ></ColumnDirective>
              <ColumnDirective
                field="Description"
                headerText="Description"
                width="250"
                clipMode="EllipsisWithTooltip"
              ></ColumnDirective>
              <ColumnDirective
                field="StartDate"
                headerText="Start Date"
              ></ColumnDirective>
              <ColumnDirective
                field="EndDate"
                headerText="End Date"
              ></ColumnDirective>
              <ColumnDirective
                field="Duration"
                headerText="Duration"
              ></ColumnDirective>
              <ColumnDirective
                field="Status"
                headerText="Sprint Status"
              ></ColumnDirective>
            </ColumnsDirective>
            <EditDialogFieldsDirective>
              <EditDialogFieldDirective
                type="General"
                headerText="General"
                fields={["Description", "StartDate", "EndDate", "Duration"]}
              ></EditDialogFieldDirective>
              <EditDialogFieldDirective type="Notes"></EditDialogFieldDirective>
            </EditDialogFieldsDirective>
            <AddDialogFieldsDirective>
              <AddDialogFieldDirective
                type="General"
                headerText="General"
                fields={["Description", "StartDate", "EndDate", "Duration"]}
              ></AddDialogFieldDirective>
              <AddDialogFieldDirective type="Notes"></AddDialogFieldDirective>
            </AddDialogFieldsDirective>
            <Inject services={[Edit, Selection, Toolbar, DayMarkers]} />
          </GanttComponent>
        )}
      </div>
    );
  }
}
