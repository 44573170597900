import { useState } from "react";
import DomainsCaroussel from "../Developer/Carousels/Domains/DomainsCarousel";
import AddNew from "./components/AddNew";

const DomainsCollection = (props) => {
  const [selectedData, setSelectedData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultStack = props?.domains[0] ?? {};

  const categoryId =
    props.categoriesId[selectedData.category] ??
    props.categoriesId[defaultStack.category];

  /**
   * takes the id of the input and the event
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
  };
  const handleSelectedData = (selectedData) => {
    setSelectedData(selectedData);
    props.setSelectedSector(selectedData);
  };

  return (
    <div className="colManagement_body-stacks collection">
      <AddNew
        open={openDialog || isEdit}
        onClose={() => {
          setOpenDialog(false);
          setIsEdit(false);
        }}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        techsCategories={props.domainCategories}
        firebase={props.firebase}
        handleFileUpload={handleFileUpload}
        setIsUpdated={props.setIsUpdated}
        collection="sectors"
        isEdit={isEdit}
        categoryId={categoryId}
        setIsEdit={setIsEdit}
        defaultData={selectedData.name ? selectedData : defaultStack}
        refreshData={props.refreshData}
        // isTech={true}
      />

      <div className="collection_header">
        <h3>INDUSTRIES</h3>
        <p>All Sprytelabs Industries.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          <DomainsCaroussel
            showHiddenCard={false}
            domains={props.domains}
            firebase={props.firebase}
            domainExperiencesObject={props.stacksObbject}
            numOfDomToShow={12}
            setSelectedDomain={handleSelectedData}
            useExist={false}
            onDomainsPaginate={props.onDomainsPaginate}
            refreshData={props.refreshData}
          />
          <div>
            <button
              className="spbtn spbtn-subno"
              onClick={() => setOpenDialog(true)}
            >
              Add New Category
            </button>
          </div>
        </div>
        <div className="collection_data-information" id="collect_edit_domain">
          <div className="collection_data-information__header row">
            <h3>Edit {selectedData?.name ?? defaultStack.name} Category</h3>
            <div className="spbtn">
              <button
                id="stacklogoEditBtn"
                className="spbtn-sub spbtn"
                onClick={(e) => setIsEdit(true)}
              >
                Edit
              </button>
              <button
                className="spbtn spbtn-subno"
                onClick={() => props.setViewSubs(true)}
              >
                Edit {selectedData?.name ?? defaultStack.name} Sub Sectors
              </button>
            </div>
          </div>

          <div className="collection_data-information__detail">
            {/* <div className="collectDetail">
              <span>CatIndex</span>
              <span>...</span>
            </div> */}

            <div className="collectDetail">
              <span className="collectDetail_title">name</span>
              <p>{selectedData?.name ?? defaultStack.name}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Description</span>
              <p>{selectedData?.description ?? defaultStack.description}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">searchKeywords</span>
              <p>
                {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Category</span>
              <span>
                {selectedData?.catData?.name ?? defaultStack?.catData?.name}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Id</span>
              <span>{selectedData?.id ?? defaultStack.id}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Path</span>
              <span>{selectedData?.ref?.path ?? defaultStack?.ref?.path}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Show</span>
              <p>
                {selectedData?.show ?? defaultStack.show ? "true" : "false"}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">LogoStorage</span>
              <div>
                <span>
                  {selectedData?.logoStorage ?? defaultStack.logoStorage}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DomainsCollection;
