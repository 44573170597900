import React from "react";

export default function FacadeProfileCard({
  authUser,
  firebase,
  vendor,
  toggleNavbar,
}) {
  const onAccountCheckOut = async () => {
    const userDoc = await vendor?.contactRef.get();
    const accounts = await vendor?.contactRef.collection("accounts").get();
    let foundDevAccount = accounts.docs.some(
      (account) => account.id === "spryte-dev"
    );

    if (foundDevAccount) return;

    const userData = { ...userDoc.data() };
    userData["ref"] = userDoc.ref;

    let devDocData = {
      avatarURL: null,
      city: null,
      companyName: vendor?.companyName,
      companyWebsite: vendor?.website,
      firstName: userData?.first,
      lastName: userData?.last,
      phone: userData?.phone,
      addedOn: new Date(),
    };

    let clientDocData = {
      companyName: vendor?.companyName,
      name: userData?.first + " " + userData?.last,
      phone: userData?.phone,
      createdOn: new Date(),
      orgRef: vendor?.orgRef, //by default adding spryte's orgRef
      public: { publicUrlEnabled: false },
      mainAccountHolder: userData?.ref,
    };

    let devsReportingDocRef = await firebase.db
      .collection("devsReporting")
      .add(devDocData);
    let clientDocRef = await firebase.db
      .collection("client")
      .add(clientDocData);

    let devsReportingDocData = {
      status: true,
      clientId: clientDocRef,
      devsReportingId: devsReportingDocRef,
      role: firebase.db.doc(
        "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/CLIENT"
      ),
    };
    await firebase.db
      .doc(`users/${userData?.ref.id}/accounts/spryte-dev`)
      .set(devsReportingDocData);
  };

  // console.log("vendor", vendor);

  return (
    <div className={toggleNavbar ? "toggleNavbar" : "spCard facadeProfile"}>
      <div className="row facadeProfile_info">
        <div className="spImgPrev">
          <img alt={vendor?.companyName} src={vendor?.logo?.url} />
        </div>
        <div className="facadeProfile_info-name">
          <p>{vendor?.companyName}</p>
        </div>
      </div>
      <div className="facadeProfile_check">
        <p>Check out your </p>
        <p onClick={onAccountCheckOut}>
          <a
            style={{ color: "#2296F5" }}
            // href='#'
            href={`https://www.sprytelabs.com/partners/${vendor?.public?.slug}?user=${authUser?.user?.email} `}
            target="_blank"
            rel="noreferrer"
          >
            Partner Profile Page
          </a>{" "}
        </p>
        <p>on sprytelabs.com</p>
      </div>
    </div>
  );
}
