import styles from "./styles/customReq.module.css";
import { MdClose, MdHelp } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import SearchComponent from "./searchComponent";
import {
  createCustomLists,
  getDocumentsByArrayMembershipInCollection,
  GetRefFromPath,
  updateDcoumentWithPath,
} from "../../globals/services";
import RequirementLocalLocation from "./reqTeam/requirementLocalLocation";

const CustomReq = ({
  setShowCustomReq,
  path,
  listData,
  userType,
  devPositions,
  isEdit = false,
  existingData = {},
}) => {
  const [currentLocationList, setCurrentLocationList] = useState([]);
  const [openToAllVendors, setOpenToAllVendors] = useState(false);
  const [optionTitle, setOptionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [minRate, setMinRate] = useState();
  const [maxRate, setMaxRate] = useState();
  const [showPositionSelection, setShowPositionSelection] = useState(false);
  const [selectedDevPositions, setSelectedDevPositions] = useState([]);
  const [positionVendorsIds, setPositionVendorsIds] = useState([]);
  const [showVendorSelection, setShowVendorSelection] = useState(false);
  const [localSkillsObject, setLocalSkillsObject] = useState([]);
  const [fetchedVendors, setFetchedVendors] = useState([]);
  const [selectedVendorIds, setSelectedVendorIds] = useState([]);
  const [error, setError] = useState("");

  const [localAddress, setLocalAddress] = useState("");
  const locationsArray = ["global", "asia", "america", "europe", "local"];
  const [fetchedLocationsMap, setFetchedLocationsMap] = useState({});

  useEffect(() => {
    if (isEdit) {
      setCurrentLocationList(
        existingData?.originalTeamCardData?.countryList ?? []
      );
      setOpenToAllVendors(existingData?.open ?? false);
      setOptionTitle(existingData?.name ?? "");
      setDescription(existingData?.description ?? "");
      setMinRate(listData?.requirements?.minHourlyPrice);
      setMaxRate(listData?.requirements?.maxHourlyPrice);
      const existingPositions =
        existingData?.positions?.map(
          (position) =>
            devPositions?.filter(
              (devPos) => devPos?.ref?.id == position?.title?.id
            )?.[0]
        ) ?? [];
      setSelectedDevPositions(existingPositions);
      setPositionVendorsIds(
        existingData?.partners?.map((partnerRef) => partnerRef.id) ?? []
      );
      setLocalSkillsObject(
        Object.values(existingData?.originalTeamCardData?.skillsObject ?? {})
      );
      // setFetchedVendors([]);
      setSelectedVendorIds(
        existingData?.partners?.map((partnerRef) => partnerRef.id) ?? []
      );
    }
  }, [existingData, isEdit]);

  //Pass this into the search component to get the selected stacks, categories and domains
  const requirements = useRef();

  function handleAddLocation(location) {
    const exceptionalLocations = ["global", "local"];
    var tempList = [];
    //Removing all other selected locations if global/local is selected
    if (exceptionalLocations.includes(location.toLowerCase())) {
      if (
        currentLocationList.includes("global") &&
        location.toLowerCase() == "global"
      ) {
        setCurrentLocationList([]);
      } else if (
        currentLocationList.includes("local") &&
        location.toLowerCase() == "local"
      ) {
        setCurrentLocationList([]);
      } else {
        setCurrentLocationList([location.toLowerCase()]);
      }
    } else if (!locationsArray.includes(location.toLowerCase())) {
      //selection and deselection of local addresses
      if (!currentLocationList.includes(location.toLowerCase())) {
        //When a local address is selected removing all default system locations.
        tempList = [...currentLocationList]?.filter(
          (item) => !locationsArray.includes(item)
        );
        //Pushing local because a local address is selected
        if(!tempList.includes("local")) tempList.push("local");
        tempList.push(location.toLowerCase());
      } else {
        tempList = [...currentLocationList]?.filter(
          (item) => item !== location.toLowerCase()
        );
      }
      setLocalAddress("");
      setCurrentLocationList([...tempList]);
    } else {
      //When a location is selected removing global/local
      tempList = [...currentLocationList]?.filter(
        (item) =>
          !exceptionalLocations.includes(item) && locationsArray.includes(item)
      );
      //selection and deselection of locations
      if (!currentLocationList.includes(location.toLowerCase())) {
        tempList.push(location.toLowerCase());
      } else {
        tempList = [...currentLocationList]?.filter(
          (item) => item !== location.toLowerCase()
        );
      }
      setCurrentLocationList([...tempList]);
    }
  }
  function createRequirement() {
    if (error != "") {
      return;
    }
    setShowCustomReq(false);
    var updatedSkillsObject = {};
    localSkillsObject.forEach((e) => {
      updatedSkillsObject[e.id] = e;
    });
    createCustomLists(
      {
        minRate: parseInt(minRate),
        maxRate: parseInt(maxRate),
        name: optionTitle,
        description: description,
        openToAllVendors: openToAllVendors,
        locations: currentLocationList,
        positions: selectedDevPositions,
        vendors: [...positionVendorsIds, ...selectedVendorIds],
        requirements: requirements.current,
        skillsObject: updatedSkillsObject,
      },
      path,
      listData,
      userType
    );
  }

  async function updateRequirement() {
    if (error != "") {
      return;
    }
    var updatedSkillsObject = {};
    localSkillsObject.forEach((e) => {
      updatedSkillsObject[e.id] = e;
    });
    var vendorsRefsArray =
      [...positionVendorsIds, ...selectedVendorIds]?.map((id) =>
        GetRefFromPath(`/vendors/${id}`)
      ) ?? [];
    var positionsArray = [];
    var membersRefs = [];
    for (const position of selectedDevPositions) {
      var positionData = {};
      positionData["title"] = position?.ref;
      positionData["status"] = GetRefFromPath(
        `${path}/posStatuses/UNSPECIFIED`
      );
      positionData["minRate"] = parseInt(minRate);
      positionData["maxRate"] = parseInt(maxRate);
      positionData["notes"] = "";
      membersRefs.push(position?.ref);
      positionsArray.push(positionData);
    }
    var data = { ...existingData };
    delete data["id"];
    delete data["path"];
    data["name"] = optionTitle;
    data["description"] = description;
    data["members"] = membersRefs;
    data["positions"] = positionsArray;
    data["open"] = openToAllVendors;
    data["originalTeamCardData"]["startDate"] = listData?.requirements?.start;
    data["originalTeamCardData"]["endDate"] = listData?.requirements?.end;
    data["originalTeamCardData"]["countryList"] = currentLocationList;
    data["originalTeamCardData"]["skillsObject"] = updatedSkillsObject;
    data["originalTeamCardData"]["hourlyPrice"] = parseInt(maxRate);
    data["partners"] = vendorsRefsArray;
    data["requirements"] = requirements.current;
    await updateDcoumentWithPath(existingData.path, data);
    setShowCustomReq(false);
  }

  async function fetchAndSetAllVendors(e) {
    const data = await getDocumentsByArrayMembershipInCollection(
      "vendors",
      e.target.value.toLowerCase(),
      ["nameIndex"],
      "companyName",
      false,
      true,
      false,
      5
    );
    setFetchedVendors(data);
  }

  useEffect(() => {
    if (minRate != "" && maxRate != "") {
      try {
        var parsedMin = parseInt(minRate);
        var parsedMax = parseInt(maxRate);
        if (isNaN(parsedMin)) {
          setError("Please enter valid minimum rate.");
        } else if (isNaN(parsedMax)) {
          setError("Please enter valid maximum rate.");
        } else if (parsedMin == "" && parsedMax == "") {
          setError("Rates are required.");
        } else if (parsedMin >= parsedMax) {
          setError("Maximum rate value should be higher than minimum rate.");
        } else {
          setError("");
        }
      } catch (error) {
        setError(error);
      }
    } else {
      setError("Rates are required.");
    }
  }, [minRate, maxRate]);

  return (
    <div className={styles.customReqBg}>
      <div className={styles.customReqModal}>
        <div className={styles.help}>
          {!isEdit && <MdHelp size={23.5} style={{ opacity: ".5" }} />}
          <div className={styles.helpContent}>
            <div className={styles.helpItem}>
              <h6>How & when to use custom requirements?</h6>
              <p>
                While you can search and pick teams directly to create
                requirements based on existing inventory, sometimes you need a
                team which is currently unavailable. If you can't find the right
                approximative team through a search to begin with, create a
                custom requirement here.
              </p>
            </div>
            <div className={styles.helpItem}>
              <h6>What happens next?</h6>
              <p>
                Once created, Spryte staff will review your requirement and
                assign it to the right mix of partner companies. You should
                expect resource profiles to stream in by the next business day.
              </p>
            </div>
            <div className={styles.helpItem}>
              <h6>Any questions?</h6>
              <p>
                Hit the chat icon on the bottom right of the page, and a Spryte
                specialist will help you out.
              </p>
            </div>
          </div>
        </div>
        <h3 id={styles.title}>Requirement Details</h3>
        <div className={styles.inputCtr} style={{ marginTop: "10px" }}>
          <div>
            <p>OPTION TITLE</p>
            <input
              style={{ width: "185px" }}
              className={styles.input}
              value={optionTitle}
              onChange={(e) => {
                setOptionTitle(e.target.value);
              }}
            ></input>
          </div>
          <div style={{ marginLeft: "15px" }}>
            <div className={styles.errorContainer}>
              <p>MIN & MAX RATES</p>
              <div className={styles.err}>
                {error !== "" ? error : "* REQUIRED"}
              </div>
            </div>
            <div className={styles.inputCtr}>
              <input
                className={styles.input}
                value={minRate}
                onChange={(e) => {
                  setMinRate(e.target.value);
                }}
              ></input>
              <input
                className={styles.input}
                value={maxRate}
                onChange={(e) => {
                  setMaxRate(e.target.value);
                }}
              ></input>
            </div>
          </div>
        </div>
        <div>
          <p>DESCRIPTION</p>
          <textarea
            style={{ width: "500px" }}
            className={styles.input}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Help our partners understand what you're looking for with a detailed description of your ideal team."
          ></textarea>
        </div>
        <h3 id={styles.title}>
          Specify Tech Stacks, Project Types & Industry Domains
        </h3>
        <MdClose
          onClick={() => {
            setShowCustomReq(false);
            setOptionTitle("");
            setDescription("");
            setMinRate();
            setMaxRate();
            setOpenToAllVendors(false);
            setCurrentLocationList([]);
          }}
          size={30}
          className={styles.close}
        />
        <SearchComponent
          horizontal={true}
          homepage={false}
          pageUsedOn={"customRequirement"}
          requirements={requirements}
          startingStacks={Object.values(localSkillsObject ?? {})}
          setLocalSkillsObject={setLocalSkillsObject}
          localSkillsObject={localSkillsObject}
        />
        {/* <p style={{color: '#e74c3c', margin: '-10.5px 0 20px 0'}}>{error}</p> */}
        <hr></hr>
        <h3 id={styles.title}>Team Locations & Vendor Options</h3>
        <p className="chipDivNote" style={{ margin: "-14.5px 0 15.5px 0" }}>
          You are able to determine what locations you prefer your resource(s)
          to be in.
        </p>
        <p>TEAM LOCATIONS</p>
        <div className={styles.teamLocation}>
        {locationsArray.map((location) => (
            <h6
              className={`${styles.location} ${
                currentLocationList.includes(location.toLowerCase())
                  ? styles.active
                  : ""
              }`}
              onClick={() => {
                handleAddLocation(location.toLowerCase());
              }}
            >
              {location.toUpperCase()}
            </h6>
          ))}
        </div>
        {currentLocationList.includes("local") && (
          <RequirementLocalLocation
            onSelection={(e) => {
              if (e != "") handleAddLocation(e?.toLowerCase());
              setLocalAddress(e);
            }}
            localAddress={localAddress}
            setLocalAddress={setLocalAddress}
            fetchedLocationsMap={fetchedLocationsMap}
            setFetchedLocationsMap={setFetchedLocationsMap}
          />
        )}
        {userType == "ADMIN" ? (
          <div className={styles.checkboxCtr}>
            <input
              checked={openToAllVendors}
              onChange={() => setOpenToAllVendors(!openToAllVendors)}
              type="checkbox"
            />
            <span>Open to all partners?</span>
            {!openToAllVendors ? (
              <span
                onClick={() => {
                  setShowVendorSelection(true);
                }}
                style={{
                  color: "#2e86de",
                  fontSize: "10.5px",
                  fontWeight: "600",
                  width: "fit-content",
                  cursor: "pointer",
                  letterSpacing: "0",
                  marginLeft: "6.5px",
                }}
              >
                OR OPEN TO SPECIFIC PARTNERS
              </span>
            ) : (
              <></>
            )}
            {showVendorSelection && (
              <div className={styles.vendorPopup}>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="Vendor Name"
                  onChange={fetchAndSetAllVendors}
                />
                <MdClose
                  style={{ fill: "#459df5", cursor: "pointer" }}
                  onClick={() => {
                    setShowVendorSelection(false);
                  }}
                  size={20}
                />
                {fetchedVendors.map((vendor) => {
                  return (
                    <p
                      onClick={() => {
                        var tempVendors = selectedVendorIds ?? [];
                        if (!tempVendors.includes(vendor.ref.id)) {
                          tempVendors.push(vendor.ref.id);
                          setSelectedVendorIds(tempVendors);
                        }
                      }}
                    >
                      {vendor?.data()?.companyName ??
                        vendor?.data()?.name + " - name"}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <hr></hr>
        <h3 style={{ margin: "14.5px 0 0 0" }} id={styles.title}>
          Team Positions
        </h3>
        <p className="chipDivNote" style={{ margin: "5px 0 -5.5px 0" }}>
          * Add as many separate positions as you need (up to 8).
        </p>
        {isEdit && (
          <p className="chipDivNote" style={{ margin: "5px 0 -5.5px 0" }}>
            * Once a position is added it cannot be removed.
          </p>
        )}
        <div className={styles.inputCtr} style={{ marginTop: "25px" }}>
          {showPositionSelection && (
            <div className={styles.positionsCtr}>
              {devPositions ? (
                devPositions?.map((position) => {
                  return (
                    <div
                      className={styles.position}
                      onClick={() => {
                        if (selectedDevPositions.length < 8) {
                          var tempSelectedPositions =
                            selectedDevPositions ?? [];
                          var tempPositionVendorsIds = positionVendorsIds ?? [];
                          //  if(!tempSelectedPositions.includes(position)) {
                          tempSelectedPositions.push(position ?? {});
                          tempPositionVendorsIds.push("tLjwPkExBug6XC42OsCO");
                          setPositionVendorsIds(tempPositionVendorsIds);
                          setSelectedDevPositions(tempSelectedPositions);
                        }
                        setShowPositionSelection(false);
                        //  }
                      }}
                    >
                      {position?.data()?.label ?? "name"}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          )}
          <button
            style={{
              opacity:
                (showPositionSelection || selectedDevPositions.length > 7) &&
                ".35",
            }}
            onClick={() => {
              if (selectedDevPositions.length < 8)
                setShowPositionSelection(!showPositionSelection);
            }}
          >
            {showPositionSelection ? "COLLAPSE" : "ADD POSITIONS"}
          </button>
          <div className={styles.positionCount}>
            {selectedDevPositions.length}
            <div className={styles.positionInfo}>
              {selectedDevPositions.map((position, index) => {
                return (
                  <div
                    className={styles.selectedPositionCtr}
                    onClick={() => {
                      if (!isEdit) {
                        var tempSelectedPositions = selectedDevPositions ?? [];
                        var tempPositionVendorsIds = positionVendorsIds ?? [];
                        setSelectedDevPositions(
                          tempSelectedPositions.filter((pos, i) => i !== index)
                        );
                        setPositionVendorsIds(
                          tempPositionVendorsIds.filter((pos, i) => i !== index)
                        );
                      }
                    }}
                  >
                    <h6 className={`${styles.location}`}>
                      {position?.data()?.label.toUpperCase()}
                    </h6>
                    {!isEdit && (
                      <MdClose
                        style={{
                          fill: "white",
                          backgroundColor: "rgb(215, 59, 62)",
                          borderRadius: "2.5px",
                          marginLeft: "6.5px",
                        }}
                        size={15}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <button
          style={{
            backgroundColor:
              !isEdit &&
              (!minRate ||
                !maxRate ||
                optionTitle == "" ||
                !selectedDevPositions.length ||
                error != "")
                ? "grey"
                : "#2e86de",
            textAlign: "right",
            marginTop: "20px",
          }}
          type="submit"
          disabled={
            !isEdit
              ? !minRate ||
                !maxRate ||
                optionTitle == "" ||
                !selectedDevPositions.length ||
                error != ""
              : false
          }
          onClick={() => {
            isEdit ? updateRequirement() : createRequirement();
          }}
        >
          {isEdit ? "UPDATE REQUIREMENT" : `SAVE & CREATE REQUIREMENT`}
        </button>
      </div>
    </div>
  );
};

export default CustomReq;
