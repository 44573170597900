import React, { useState, useEffect } from "react";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GenericService = (props) => {
  //stores the selected generic min Exp
  const [selMinExp, setSelMinExp] = useState(0);

  //stores the selected generic min proj
  const [selMinProj, setSelMinProj] = useState(0);

  //true if the edit button is clicked
  const [isEdit, setIsEdit] = useState(false);

  //stores the selected generic trust score
  const [selectScore, setSelectedScore] = useState(0);

  //stores the selected generic check box
  const [selChecked, setSelChecked] = useState(false);

  //generic to edit index
  const [editIndex, setEditIndex] = useState(0);

  const onEdit = (i) => {
    setEditIndex(i);
    setIsEdit(true);
  };

  /**
   * Triggers when a vendor click on save button in the generic servic and
   * updates the vendor generic data
   */
  const onSave = () => {
    let generics = [...props.generics];
    generics[editIndex] = {
      isSelected: selChecked,
      minExp: selMinExp,
      minProj: selMinProj,
      position: props.generics[editIndex].position,
      trustScore: parseFloat(selectScore.toFixed(2)),
      positionRef: props.generics[editIndex].positionRef,
    };
    props.updateVendorGeneric(generics);

    setIsEdit(false);
    setEditIndex(0);
  };

  useEffect(() => {
    if (isEdit) {
      let data = props.generics[editIndex];
      setSelMinExp(data.minExp);
      setSelMinProj(data.minProj);
      setSelChecked(data.isSelected);
      setSelectedScore(data.trustScore);
    }
  }, [isEdit, editIndex, props?.generics]);

  /**
   * Triggers when the vendor click on a - button to decrease either the min proj or min exp
   * @param {string} minName => name of the given data to be decreased
   */
  const onDecrease = (minName) => {
    if (selMinExp >= 1 && minName === "exp") {
      let exp = selMinExp - 1;
      setSelMinExp(exp);
    }
    if (selMinProj >= 1 && minName === "proj") {
      let proj = selMinProj - 1;
      setSelMinProj(proj);
    }
  };

  /**
   * Triggers when the vendor click on a + button to increase either the min proj or min exp
   * @param {string} minName => name of the given data to be increased
   */
  const onIncrease = (minName) => {
    if (selMinExp >= 0 && minName === "exp") {
      let exp = selMinExp + 1;
      setSelMinExp(exp);
    }
    if (selMinProj >= 0 && minName === "proj") {
      let proj = selMinProj + 1;
      setSelMinProj(proj);
    }
  };

  /**
   * Converts the rating into stars
   * @param {number} trustScore trustscore of a specific position
   * @returns
   */
  const ratingDetail = (trustScore) => {
    let ele = [];
    for (let i = 0; i < 4; i++) {
      if (i < trustScore) {
        ele.push(<span className="star checked" key={i}></span>);
      } else {
        ele.push(<span className="star" key={i}></span>);
      }
    }
    return <div className="rating">{ele}</div>;
  };

  return (
    <div className="stat_content_data stat_content_rev row genericService">
      <div className="stat_content_data-title">
        <h4>Generic Service Settings</h4>
      </div>
      <div className="stat_content_data-content">
        <div className="column">
          <div className="row top">
            <label className="vendor-info">Positions, Experience & Skill</label>
          </div>
          <div className="bottom light">
            <p className="small">
              {" "}
              Define your experience range and how they relate to rates. Skill
              Trust Scores are set by Spryte and dependent on our experience
              working with you.
            </p>
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th className="small">[-]</th>
                <th className="med">Position</th>
                <th className="med" style={{ width: "115px" }}>
                  Experience
                </th>
                <th className="lg">Projects</th>
                <th className="med" style={{ width: "165px" }}>
                  Skill Trust Score
                </th>
                <th className="small">Edit</th>
              </tr>
            </thead>
            <tbody>
              {props.generics.map((generic, i) => {
                return (
                  <tr
                    key={i}
                    className="accRow"
                    style={{ marginBottom: "5px" }}
                  >
                    <td
                      style={{
                        opacity:
                          generic.isSelected || editIndex === i ? "1" : ".3",
                      }}
                    >
                      {isEdit && editIndex === i ? (
                        <input
                          type="checkbox"
                          id={`checked_${i}`}
                          name="interest"
                          value="coding"
                          checked={selChecked}
                          // checked
                          onClick={(e) => {
                            setSelChecked(!selChecked);
                            e.persist();
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id={`checked_${i}`}
                          name="interest"
                          value="coding"
                          checked={generic.isSelected}
                          readOnly={true}
                        />
                      )}
                    </td>
                    <td
                      style={{
                        opacity:
                          generic.isSelected || editIndex === i ? "1" : ".3",
                      }}
                    >
                      <h4>{generic.position}</h4>
                    </td>
                    <td
                      style={{
                        opacity:
                          generic.isSelected || editIndex === i ? "1" : ".3",
                      }}
                    >
                      <div className="row">
                        {isEdit && editIndex === i ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            className="icon_minus"
                            onClick={() => {
                              onDecrease("exp");
                            }}
                          />
                        ) : (
                          <></>
                        )}

                        <span>
                          {" "}
                          {isEdit && editIndex === i
                            ? `${selMinExp}+ Years`
                            : `${generic.minExp}+ Years`}
                        </span>
                        {isEdit && editIndex === i ? (
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="icon_minus"
                            onClick={() => {
                              onIncrease("exp");
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    <td
                      className="row"
                      style={{
                        opacity:
                          generic.isSelected || editIndex === i ? "1" : ".3",
                      }}
                    >
                      <div className="row">
                        {isEdit && editIndex === i ? (
                          <FontAwesomeIcon
                            icon={faMinus}
                            className="icon_minus"
                            onClick={() => {
                              onDecrease("proj");
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        <span>
                          {" "}
                          {isEdit && editIndex === i
                            ? `${selMinProj}+`
                            : `${generic.minProj}+`}
                        </span>
                        {isEdit && editIndex === i ? (
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="icon_minus"
                            onClick={() => {
                              onIncrease("proj");
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <span style={{ marginLeft: "4px" }}>
                        completed client-facing projects
                      </span>
                    </td>
                    <td
                      style={{
                        opacity:
                          generic.isSelected || editIndex === i ? "1" : ".3",
                      }}
                    >
                      <div className="row">
                        {ratingDetail(generic.trustScore)}
                        {props.isSuper && isEdit && editIndex === i ? (
                          <input
                            type="number"
                            min={0}
                            max={4}
                            step={0.1}
                            value={selectScore}
                            onChange={(e) => {
                              setSelectedScore(parseFloat(e.target.value));
                              e.persist();
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              fontWeight: "700",
                              opacity: ".4",
                              marginLeft: "6.5px",
                            }}
                          >
                            ({generic.trustScore.toFixed(2)}/4.00)
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      {isEdit && editIndex === i ? (
                        <div style={{ display: "flex" }}>
                          <button
                            style={{
                              marginRight: "5px",
                              borderRadius: "3.5px",
                              color: "white",
                              backgroundColor: "#1E96F5",
                              border: "none",
                              padding: "0 7.5px",
                            }}
                            onClick={onSave}
                          >
                            Save
                          </button>
                          <button
                            onClick={() => {
                              setIsEdit(false);
                              setEditIndex(0);
                            }}
                            style={{
                              marginRight: "5px",
                              borderRadius: "3.5px",
                              color: "grey",
                              backgroundColor: "rgba(128, 128, 128, 0.15)",
                              border: "none",
                              padding: "0 7.5px",
                            }}
                          >
                            Cancel{" "}
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            onEdit(i);
                          }}
                          style={{
                            marginRight: "5px",
                            borderRadius: "3.5px",
                            color: "grey",
                            backgroundColor: "rgba(128, 128, 128, 0.15)",
                            border: "none",
                            padding: "0 7.5px",
                            fontSize: "12px",
                          }}
                        >
                          Edit{" "}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenericService;
