import SpryteTooltip from "../../SpryteTooltip";

const PopularSearch = (props) => {
  const renderCombinations = (comb) => {
    let el = [];
    for (let i = 0; i < comb.length; i++) {
      if (i < comb.length - 1) {
        el.push(`${comb[i]}, `);
      } else {
        el.push(`${comb[i]} `);
      }
    }
    return (
      <div className="row">
        <span style={{ fontWeight: 600 }}>{el.slice(0, 10)}</span>
        {comb.length > 10 ? (
          <SpryteTooltip desc={el.slice(8, el.length)} isMore={true} />
        ) : (
          <></>
        )}
      </div>
    );
  };

  // console.log("props?.currentPopular", props?.currentPopular);

  /**
   * takes the timestamp of the given query then gets the difference between the
   * dates
   * @param {timestamp} lastQueried => timestamp of the given query
   * @returns number of days
   */
  const getDays = (lastQueried) => {
    let today = new Date();
    let lastDay = lastQueried.toDate();
    let difTime = today.getTime() - lastDay.getTime();
    let difDays = difTime / (1000 * 3600 * 24);
    return difDays.toFixed(0);
  };

  const goToPage = (pageNum) => {
    props.setViewingIndex(pageNum);
  };

  // console.log("props?.currentPopular", props?.currentPopular);

  /**
   * Loop through the number of page and display the current viewing button and the last button
   * @param {number} pageLength => number of pages we have
   * @returns a array of buttons
   */
  const renderPageButtons = (pageLength) => {
    let buttons = [];
    // adding the first button to be always visible
    buttons[0] = (
      <button
        key={0}
        style={{
          margin: "0 10px",
          borderRadius: "3.5px",
          color: "grey",
          backgroundColor: "rgba(128, 128, 128, 0.15)",
          border: "none",
          padding: "1.5px 12.5px",
          fontSize: "12px",
          fontWeight: "600",
        }}
        onClick={() => goToPage(0)}
      >
        {1}
      </button>
    );

    for (let i = 0; i < pageLength; i++) {
      if (i > 0 && props.viewingIndex === i) {
        // if we have not get all of the queries in firebase we will keep showing and moving the end button
        if (i === pageLength - 1 && !props.isAll) {
          buttons.push(
            <button
              key={i}
              style={{
                margin: "0 10px",
                borderRadius: "3.5px",
                color: "grey",
                backgroundColor: "rgba(128, 128, 128, 0.15)",
                border: "none",
                padding: "1.5px 12.5px",
                fontSize: "12px",
                fontWeight: "600",
              }}
              onClick={() => goToPage(i)}
            >
              {i + 1}
            </button>
          );
        } else if (i !== pageLength - 1) {
          buttons.push(
            <button
              key={i}
              style={{
                margin: "0 10px",
                borderRadius: "3.5px",
                color: "grey",
                backgroundColor: "rgba(128, 128, 128, 0.15)",
                border: "none",
                padding: "1.5px 12.5px",
                fontSize: "12px",
                fontWeight: "600",
              }}
              onClick={() => goToPage(i)}
            >
              {i + 1}
            </button>
          );
        }
      }
    }
    buttons[buttons.length] =
      (pageLength > 1 && props.isAll) ||
      props.viewingIndex !== pageLength - 1 ? (
        <button
          key={pageLength - 1}
          style={{
            margin: "0 10px",
            borderRadius: "3.5px",
            color: "grey",
            backgroundColor: "rgba(128, 128, 128, 0.15)",
            border: "none",
            padding: "1.5px 12.5px",
            fontSize: "12px",
            fontWeight: "600",
          }}
          onClick={() => goToPage(pageLength - 1)}
        >
          {pageLength}
        </button>
      ) : (
        <div key={"0_empty"}></div>
      );
    return <div>{buttons}</div>;
  };
  return (
    <div className="markets_content-popularSearch">
      <div className="markets_content-popularSearch__head row">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Popular Markets</h3>
          {/* <p className="small">View And Edit Your Pricing Definitions</p> */}
          <SpryteTooltip desc="Browse popular searches and add your pricing." />
        </div>
      </div>
      <div className="markets_content-popularSearch__prices prices_table">
        <div className="row prices_table-header">
          <div className="keyword keyword_header">
            <h4>Keywords</h4>
          </div>
          <div className="searched searched_header">
            <h4>Number of Search</h4>
          </div>
          <div className="yRates yRates_header">
            <h4>YOUR MARKET PRICING</h4>
          </div>
          <div className="cRates cRates_header">
            <h4>COMPETITOR MARKET PRICING</h4>
          </div>
          <div className="rates rates_header">
            <h4>LIVE AVAILABILITY PRICING</h4>
          </div>
          <div className="edit edit_header"></div>
        </div>
        {props?.currentPopular?.map((price, i) => {
          return (
            <div key={i} className="row">
              <div className="keyword keyword_el">
                {renderCombinations(price.combination)}
              </div>
              <div className="searched searched_el">
                <p>
                  Last searched{" "}
                  {getDays(price.lastQueriedTS) === 0
                    ? "today"
                    : getDays(price.lastQueriedTS) === 1
                    ? "yesterday"
                    : ` ${getDays(price.lastQueriedTS)} days ago`}{" "}
                  {price.count.INTERACTIVE > 0
                    ? `, searched ${
                        price.count.INTERACTIVE > 1
                          ? `${price.count.INTERACTIVE} times`
                          : `${price.count.INTERACTIVE} time`
                      }`
                    : ""}
                </p>
              </div>
              <div className="yRates yRates_el" style={{ minWidth: "12rem" }}>
                <p>
                  {price?.vendComb
                    ? `$${price?.vendComb?.minPrice} - $${price?.vendComb?.maxPrice}/hr`
                    : "Not Available"}
                </p>
              </div>
              <div className="cRates cRates_el" style={{ minWidth: "12rem" }}>
                <p>
                  {price["genericStats"]["180-day"]["stats"]["price"]["min"] ===
                    0 &&
                  price["genericStats"]["180-day"]["stats"]["price"]["max"] > 0
                    ? `< $${
                        price["genericStats"]["180-day"]["stats"]["price"][
                          "max"
                        ] ?? 0
                      }/hr`
                    : price["genericStats"]["180-day"]["stats"]["price"][
                        "min"
                      ] === 0 &&
                      price["genericStats"]["180-day"]["stats"]["price"][
                        "max"
                      ] === 0
                    ? ``
                    : `$${
                        price["genericStats"]["180-day"]["stats"]["price"][
                          "min"
                        ] ?? 0
                      } - $${
                        price["genericStats"]["180-day"]["stats"]["price"][
                          "max"
                        ] ?? 0
                      }/hr`}
                </p>
              </div>
              <div className="rates rates_el">
                <p>
                  {price["stats"]["180-day"]["stats"]["price"]["min"] === 0 &&
                  price["stats"]["180-day"]["stats"]["price"]["max"] > 0
                    ? `< $${
                        price["stats"]["180-day"]["stats"]["price"]["max"] ?? 0
                      }/hr`
                    : price["stats"]["180-day"]["stats"]["price"]["min"] ===
                        0 &&
                      price["stats"]["180-day"]["stats"]["price"]["max"] === 0
                    ? ``
                    : `$${
                        price["stats"]["180-day"]["stats"]["price"]["min"] ?? 0
                      } - $${
                        price["stats"]["180-day"]["stats"]["price"]["max"] ?? 0
                      }/hr`}
                </p>
              </div>
              <div className="edit edit_el">
                {price.combination.length <= 6 ? (
                  <button
                    style={{
                      marginLeft: "13.5px",
                      borderRadius: "3.5px",
                      color: "grey",
                      backgroundColor: "rgba(128, 128, 128, 0.15)",
                      border: "none",
                      padding: "0 7.5px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      price?.vendComb
                        ? props.onEdit(
                            price.vendPrice.ID,
                            price.vendPrice.combination,
                            price.vendPrice.queryRef,
                            price.vendPrice.queryData
                          )
                        : props.onAddPricing(price);
                    }}
                  >
                    {price?.vendComb ? "Edit Pricing" : "Add Pricing"}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="row" style={{ alignItems: "center" }}>
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => {
            props.onPrevious();
          }}
        >
          Previous
        </button>
        {renderPageButtons(props.paginationLength)}
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => {
            if (!props.isNextLoading) props.onNextQueries();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default PopularSearch;
