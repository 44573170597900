import React from "react";
import FormInput from "../../../globals/FormInput";
import SpLabelInfo from "../../../SpLabelInfo";

const options = [
  { value: "UNIT", label: "Quantity" },
  { value: "USD", label: "Currency" },
  { value: "string", label: "String" },
  { value: "radio", label: "Single Selection" },
  { value: "checklist", label: "Multiple Choice" },
];

export default function AddDialog({
  onSubmit = null,
  loading = false,
  onClose = null,
  data = null,
  dispatch = null,
  renderProto,
  error,
  fieldsError,
}) {
  const verifyDataType = (data) => {
    if (data.type === "radio" || data.type === "checklist") {
      return (
        data.hintArray.filter((el) => el !== "").length > 0 &&
        data.protoArray.filter((el) => el !== "").length > 0
      );
    } else {
      return data.hintText !== "" && data.proto !== "";
    }
  };
  return (
    <div className="request">
      <div
        className="request_form column ctr"
        id="data-request_form"
        style={{
          // marginTop: "20vh",
          width: "50vw",
          padding: "25px 65px",
          maxHeight: "80vh",
          top: "40vh",
        }}
      >
        <div className="request_form-content  column">
          <div>
            <div>
              <FormInput
                label="Label"
                desc="Data Point labels must be unique and in lower camelCase (no spaces)"
                value={data?.label}
                onChange={(e) =>
                  dispatch({
                    type: "ADD",
                    key: "label",
                    value: e.target.value,
                  })
                }
              />
              {data?.id && (
                <div style={{ marginTop: "1rem" }}>
                  <p style={{ fontSize: "1.2rem" }}>
                    Key: {data?.id}{" "}
                    <span style={{ color: "#eb3570" }}>{error}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col_3_input">
            <div className="formInput">
              <SpLabelInfo
                title="Type"
                description="Select the question type"
              />

              <select
                name="status"
                id="expboxinput"
                value={data?.type}
                onChange={(e) => {
                  dispatch({
                    type: "ADD",
                    key: "type",
                    value: e.target.value,
                  });
                  e.persist();
                }}
              >
                {options.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <FormInput
              label="Precision"
              type="number"
              min={0}
              step={1}
              readOnly={
                data?.type === "radio" ||
                data?.type === "checklist" ||
                data?.type === "string"
                  ? true
                  : false
              }
              value={data?.precision}
              onChange={(e) =>
                dispatch({
                  type: "ADD",
                  key: "precision",
                  value: parseInt(e.target.value),
                })
              }
              desc="How many significant units to display"
            />

            <FormInput
              label="Prefix"
              desc="Add a prefix to the units such as $"
              value={data?.prefix}
              readOnly={
                data?.type === "radio" ||
                data?.type === "checklist" ||
                data?.type === "string"
                  ? true
                  : false
              }
              onChange={(e) =>
                dispatch({
                  type: "ADD",
                  key: "prefix",
                  value: e.target.value,
                })
              }
            />
          </div>

          {data?.type === "radio" || data?.type === "checklist" ? (
            renderProto(data, dispatch)
          ) : (
            <div className="col_2_proto">
              <FormInput
                label="Hint Text"
                desc="Help users understand with a helpful hint example"
                value={data?.hintText}
                onChange={(e) =>
                  dispatch({
                    type: "ADD",
                    key: "hintText",
                    value: e.target.value,
                  })
                }
              />
              <FormInput
                label="Proto"
                desc="Help users understand with a helpful hint example"
                value={data?.proto}
                type="number"
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  dispatch({
                    type: "ADD",
                    key: "proto",
                    value: value,
                  });
                }}
              />
            </div>
          )}

          <FormInput
            label="Description"
            desc="Help users understand the question with description text"
            value={data?.desc}
            isInput={false}
            onChange={(e) =>
              dispatch({ type: "ADD", key: "desc", value: e.target.value })
            }
          />
        </div>
        {fieldsError && (
          <div style={{ marginTop: "1rem" }}>
            <p style={{ fontSize: "1.2rem", color: "#eb3570" }}>
              {fieldsError}
            </p>
          </div>
        )}
        <div className="request_form-action row">
          {data.label && data.desc && verifyDataType(data) && (
            <button
              id="add"
              onClick={() => {
                onSubmit();
              }}
              disabled={loading}
            >
              Save
            </button>
          )}
          <button
            id="cancel"
            style={{ padding: "5px 10px", fontWeight: "600" }}
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
