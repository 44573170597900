import React, { useState, useContext } from "react";
import { FirebaseContext } from "./Firebase";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";
import spryte from "./images/white-spryte.png";
import LoginInputs from "./LoginInputs";
import RegisterInputs from "./RegisterInputs";
import DevInputs from "./DevInputs";
import preload from "./images/preload.png";
// import { analytics } from "./Firebase/firebase";

export default function SignIn({ newUser, setNewUser, isDev, firebase }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [credErr, setCredErr] = useState("");
  const firebase_context = useContext(FirebaseContext);
  const history = useNavigate();
  const [register, setRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPass, setRegisterPass] = useState("");

  // ====================Check login information and throw error==========================================
  const logincheck = (email, password) => {
    setCredErr("");
    if (email === "") {
      setCredErr("Email should not be Empty, Please enter an email");
    } else if (password === "") {
      setCredErr("Password should not be Empty, Please enter a password");
    }
  };

  return (
    <div className="login-div">
      <div className="login">
        <div className="login-left">
          <div
            className="spryte-ctr"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h2 className="spryte">spryte labs</h2>
            <div className="spryte-circle">
              <img src={spryte} alt="spryte logo" />
            </div>
          </div>
          <p className="register">
            Don't have an account? Please register by{" "}
            <span>
              <a
                href="https://share.hsforms.com/1lbEEFTZZQJOAzm_WwGCbIg36gxo"
                target="_blank"
                rel="noreferrer"
              >
                contacting us
              </a>
            </span>
            .
          </p>
          <div className={!isDev ? "circle" : "circle dev_log"}>
            <img
              src="https://shellsoftware.net/wp-content/uploads/2020/06/shell_hwid_spoofer_pro.png"
              alt="img"
            />
          </div>
          {!isDev ? (
            <LoginInputs
              setRegister={setRegister}
              setEmail={setEmail}
              setPassword={setPassword}
              firebase_context={firebase_context}
              setCredErr={setCredErr}
              email={email}
              password={password}
              logincheck={logincheck}
              history={history}
              credErr={credErr}
              newUser={newUser}
              registerEmail={registerEmail}
              registerPass={registerPass}
              setNewUser={setNewUser}
              firebase={firebase}
            />
          ) : (
            <DevInputs setIsLoading={setIsLoading} firebase={firebase} />
          )}
        </div>
      </div>
    </div>
  );
}
