import React, { useEffect, useState } from "react";
import { Cancel, Info } from "@mui/icons-material";
import {
  GetRefFromPath,
  getCollectionRefFromPath,
} from "../../../globals/services";

const AssociatedCompany = (props) => {
  const [showLinkCompany, setShowLinkCompany] = useState(false);
  const [compSearch, setCompSearch] = useState("");
  const [fetchedCompanies, setFetchedCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [linkedCompany, setLinkedCompany] = useState();

  const fetchCompanies = async () => {
    const querySnap = await getCollectionRefFromPath(`companies`)
      .where("nameIndex", "array-contains", compSearch?.toLowerCase())
      .get();
    setFetchedCompanies(querySnap.docs);
  };

  useEffect(() => {
    const fetchLinkedCompanyData = async () => {
      const compData = (await props?.vendorData?.companyRef?.get())?.data();
      setLinkedCompany({ ...compData, ref: props?.vendorData?.companyRef });
    };
    if (props?.vendorData?.companyRef) fetchLinkedCompanyData();
  }, [props?.vendorData?.companyRef]);

  const linkCompanyButton = () => {
    return (
      <div className="marketSearch request_form" id="marketSearchComponent">
        <div className="info-ctr">
          <Info className="market-info" />
          <h5>Associate a company to {props.vendorData.companyName}</h5>
        </div>
        <div className="marketSearch_close">
          <Cancel
            className="marketSearch_close-icon"
            onClick={() => setShowLinkCompany(false)}
          />
        </div>
        <div className="selectedStackDiv"></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            name="email"
            value={compSearch}
            onChange={(e) => {
              setCompSearch(e.target.value);
            }}
          />
          <button
            className="e-control e-btn "
            style={{
              background: "white",
              color: "black",
              marginLeft: 10,
              height: 30,
            }}
            onClick={() => {
              fetchCompanies();
            }}
          >
            Search
          </button>
        </div>
        <div class="selectedStackDiv">
          {fetchedCompanies?.map((item, i) => {
            return (
              <div
                class="selectedStackItem"
                style={{
                  backgroundColor:
                    item?.data()?.profileSlug === selectedCompany?.profileSlug
                      ? "green"
                      : null,
                }}
                key={`${item?.data()?.profileSlug ?? ""}_${i}`}
                onClick={() => {
                  setSelectedCompany({
                    ...item?.data(),
                    ref: item?.ref,
                  });
                  setCompSearch(item?.data()?.profileSlug);
                }}
              >
                <a style={{ color: "white" }}>
                  {item?.data()?.profileSlug ?? ""}
                </a>
              </div>
            );
          })}
        </div>
        <div
          className="btnSearch"
          onClick={async () => {
            await GetRefFromPath(`vendors/${props.vendorData.id}`).update({
              companyRef: selectedCompany?.ref,
            });
            setShowLinkCompany(false);
          }}
        >
          <h3>Associate</h3>
        </div>
      </div>
    );
  };

  return (
    <div className="stat_content_data stat_content_rev row marketing">
      <div className="stat_content_data-title">
        <h4>Associated Company</h4>
      </div>
      {props.vendorPublic?.featured ? (
        <div className="stat_content_data-content">
          <div className="column">
            <div className="row">
              <h4>{linkedCompany?.crm?.data?.properties?.name?.value}</h4>
              {props?.isSuper && (
                <button
                  className="e-control e-btn "
                  style={{
                    background: "white",
                    color: "black",
                    marginLeft: "20px",
                  }}
                  onClick={() => setShowLinkCompany(true)}
                >
                  Associate a Company
                </button>
              )}
              {showLinkCompany && linkCompanyButton()}
            </div>
          </div>
        </div>
      ) : (
        <div className="stat_content_data-content">
          {/* <div className="column">
            <div className="row top">
              <div className="row">
                <label
                  className="vendor-info"
                  style={{ marginRight: "12px", color: "grey" }}
                >
                  Slug:
                </label>
                <p style={{ color: "grey" }}>
                  {props.vendorPublic?.slug ?? props.vendorData.companyName}
                </p>
              </div>
              <div className="row">
                <label
                  className="vendor-info"
                  style={{ marginRight: "12px", color: "grey" }}
                >
                  Index:
                </label>
                <p style={{ color: "grey" }}>true</p>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default AssociatedCompany;
