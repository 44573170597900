import React from "react";

const ArrowUpFilled = ({ w = "14", h = "15", fill = "black" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.173329 6.18989C0.264334 6.25316 1.21592 6.30561 2.28796 6.30649L4.23675 6.30811L4.23343 10.23C4.22939 15.0101 4.1802 14.9174 6.71738 14.9195C9.25407 14.9216 9.20523 15.0133 9.20927 10.2412L9.21258 6.32618L11.2624 6.27777C12.9821 6.23735 13.3257 6.18099 13.3956 5.92845C13.4802 5.62306 7.64066 0.211213 7.04097 0.0392179C6.87009 -0.00982432 6.5902 -0.0100562 6.41899 0.0387026C5.95687 0.170387 0.007932 5.54354 0.00769005 5.82937C0.00757564 5.96454 0.0820759 6.1268 0.173329 6.18989Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowUpFilled;
