import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/remote-config";
import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import googleAnalytics from "@analytics/google-analytics";
import hubSpotPlugin from "@analytics/hubspot";

// set up configs to access firebase database

// const config = {
//   apiKey: "AIzaSyCseWymNjBL4M44SlPROUrQMJIoTjEd3f4",
//   authDomain: "com.sprytelabs.chatone",
//   databaseURL: "https://chattest1-b7121.firebaseio.com",
//   projectId: "chattest1-b7121",
//   storageBucket: 'chattest1-b7121.appspot.com',
//   //messagingSenderId: ,
// };

// export const config = {
//   apiKey: "AIzaSyCq_shSKB7NFP_6UB1wtxjuZcQhg_Xt8TI",
//   authDomain: "spryte-app.firebaseapp.com",
//   databaseURL: "https://spryte-app.firebaseio.com/",
//   projectId: "spryte-app",
//   storageBucket: "spryte-app.appspot.com",
//   messagingSenderId: "721672789701",
//   appId: "1:721672789701:web:c968d9b06bff00541d5aac",
//   measurementId: "G-ZEB4DFNHD7",
// };

// export const config = {
//   apiKey: "AIzaSyDxYAVQW2rp18N9IvGtavc1t65LYG5CA28",
//   authDomain: "spryte-s.firebaseapp.com",
//   databaseURL: "https://spryte-s.firebaseio.com",
//   projectId: "spryte-s",
//   storageBucket: "spryte-s.appspot.com",
//   messagingSenderId: "609379530518",
//   appId: "1:609379530518:web:6761e959cf4b40b7710237",
//   measurementId: "G-BY2J9KFE8F",
// };

export const config = {
  apiKey: "AIzaSyAbja9XMqe-Lp29-IqnG_PSs6gHYMwupaE",
  authDomain: "spryte-p.firebaseapp.com",
  databaseURL: "https://spryte-p.firebaseio.com",
  projectId: "spryte-p",
  storageBucket: "spryte-p.appspot.com",
  messagingSenderId: "311298464829",
  appId: "1:311298464829:web:16bb428d82ab4f0d829e54",
  measurementId: "G-G1BPP8WQKN",
};

////////////////// ANALYTICS /////////////////////
var token = { mixPanel: "", google: "", hubSpot: "" };
if (config.projectId === "spryte-p") {
  // Use the Production Tokens only for prod deployments
  token.mixPanel = "5da26ced2ab6991c11f352725d0d1ae8";
  token.google = "GTM-5PM6NVJ";
  token.hubSpot = "4340732";
} else {
  // Use the testTokens so as to not track developer usage
  token.mixPanel = "01101b3890c19b5bdb12a9bdb95bca7d";
  token.google = "test";
  token.hubSpot = "test";
}
console.log("token google: ", token.google);
// This code is used to initialize the analytics plugins.
// The IS_BROWSER variable is used to check if the code is running in the browser or not.
//
// It helps with this issue:
// https://github.com/DavidWells/analytics/issues/220#issuecomment-933000715
//
// This was formerly characterized with the logged statements:
// "mixpanel not available in node.js yet. Todo implement https://github.com/mixpanel/mixpanel-node"
// "hubspot not available in node.js yet. Todo implement https://www.npmjs.com/package/hubspot"
const IS_BROWSER = typeof window !== "undefined";
const plugins = [
  mixpanelPlugin({
    token: token.mixPanel,
  }),
  //unresolved issue with this instantiation, doesn't let the app load.
  // googleAnalytics({
  //   trackingId: token.google,
  // }),
  hubSpotPlugin({
    portalId: token.hubSpot,
  }),
];
console.log("plugins", plugins);

export const analytics = Analytics({
  app: "Spryte Partner Platform",
  plugins: [...(IS_BROWSER ? plugins : [])],
});

///////////////////////////////////////////////////

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth(); // auth is a firebase solution for authenticating
    this.db = app.firestore(); // firestore allows access to the database specified in configs
    this.storage = app.storage();
    this.fire = app.firestore;
    this.remoteConfig = app.remoteConfig();
    // The URL to call the getMatches cloud function.
    // this.getMatchesURL = ;
  }
  // Function to save information from scraping a resume to be used as
  // training data for a NLP machine

  saveResumeData = (resumeText, scrapedData, correctedData) => {
    this.db
      .collection("ML")
      .doc("resumeParsing")
      .collection("trainingData")
      .add({
        text: resumeText,
        scrapedData: scrapedData,
        correctedData: correctedData,
      });
  };

  /**
   * This function is call in a page view or a button click to diplay information in mixpanel
   * @param {string} info => Information to display on Mixpanel
   * @param {boolean} isSuperUser => true if it's a super user
   * @param {object} data => objects containing information to display in the properties
   *
   * @example firebase.reportPageVisits('Account Page View', false, {'dev Id': xxxxx})
   * @returns none
   */
  // reportPageVisits(info, isSuperUser = false, data = {}) {
  //   if (isSuperUser) return;
  //   analytics.track(info, data);
  // }

  /**
   * URL of the spryte labs cloud function
   * @param {string} ref the cloud function reference
   * @returns Spryte cloud function url
   */
  spryteCloudUrl = (ref) => {
    return `https://us-central1-${config.projectId}.cloudfunctions.net/${ref}`;
  };

  /**
   * Takes an extension to redirect the user to the sprytelabs link
   * @param {string} ext
   * @returns the partner platform link
   */
  getSpryteLinks = (ext) => {
    if (config.projectId === "spryte-app") {
      return `https://spryte-partner.web.app/${ext}`;
    }
    if (config.projectId === "spryte-s") {
      return `https://spryte-s.web.app/${ext}`;
    }
    if (config.projectId === "spryte-p") {
      return `https://partners.sprytelab.com/${ext}`;
    }
  };

  /**
   * This function registers new user in firebase and creates a doc in 'users' and 'clients' collections
   * and link them in 'accounts' sub collection of 'users' collection.
   * returns uid of newly created user
   *
   * @example
   * await registerNewUser(data);
   *
   * @param {Map} data - Object containing email, password, first and last of the new user.
   *
   * @returns {String} Returns UID of newly created user.
   */
  registerNewUser = async (data, uid) => {
    var registeredUser = {};
    var devDocData = {
      avatarURL: null,
      city: null,
      companyName: data["companyName"],
      companyWebsite: data["website"],
      firstName: data["first"],
      lastName: data["last"],
      phone: data["phone"],
    };

    var clientDocData = {
      companyName: data["companyName"],
      name: data["first"] + data["last"],
      phone: data["phone"],
      createdOn: this.fire.Timestamp.now(),
      orgRef: this.db.doc("organisations/T6BAcTjwbXleCFpmWTmu"), //by default adding spryte's orgRef
      public: { publicUrlEnabled: false },
    };
    try {
      if (registeredUser["error"] != null) return registeredUser;
      var devsReportingDocRef = await this.db
        .collection("devsReporting")
        .add(devDocData);

      clientDocData["mainAccountHolder"] = this.db.doc(`users/${uid}`);

      var clientDocRef = await this.db.collection("client").add(clientDocData);

      var devsReportingDocData = {
        status: true,
        clientId: clientDocRef,
        devsReportingId: devsReportingDocRef,
        role: this.db.doc(
          "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/CLIENT"
        ),
      };
      await this.db
        .doc(`users/${uid}/accounts/spryte-dev`)
        .set(devsReportingDocData);

      return {
        uid: uid,
      };
    } catch (e) {
      return { error: e };
    }
  };

  // Formula's related to remote config
  getResumeParserURL = async () => {
    await this.remoteConfig.fetchAndActivate();
    return this.remoteConfig.getString("resume_parser_url");
  };

  getDashboard = async () => {
    await this.remoteConfig
      .fetchAndActivate()
      .then(() => {})
      .catch((err) => {});
    return this.remoteConfig.getString("partner_dashboard_report");
  };

  /**
   * Takes a latitude and longitude of a location then compute its geopoint
   * @param {number} latitude
   * @param {number} longitude
   * @returns geopoint
   */
  createGeoPoint = (latitude, longitude) => {
    let geoPoint = new app.firestore.GeoPoint(latitude, longitude);
    return geoPoint;
  };

  /**
   * Takes a user reference and a boolean to return wether a devs is verified or not
   * @param {object} userRef user reference
   * @param {boolean} isReVerified the boolean of the isVerified field in the resource document
   * @returns isverified
   */
  isRegisteredAndVerifiedResource = async (userRef, isReVerified) => {
    if (userRef && !isReVerified) return false;
    return true;
  };

  // Functions Related To Searching Arrays

  /**
   * This function returns an array of DocumentSnapshots where a value (fieldValue)
   * is found in any field in the fields array (fields). It searches either a
   * collection (collection && !group) or a collection group (collection && group).
   * The ordering of the fields is done in accordance with the following list of
   * precendence: index of the field in the fields array, then the orderBy key
   * (fieldOrder && orderBy). Alternatively, one can have the results returned purely
   * based upon the orderBy key (!fieldOrder && orderBy).
   * The order may be set to descending (orderByDesc).
   *
   *
   * @example
   * // Returns all documents inside the collection "techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies"
   * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
   * // in an ascending manner, with rank being the secondary factor. The primary determinant is
   * // whether the "a" was found in the "nameIndex" or the "catIndex" field; "nameIndex" matches
   * // will come first.
   * await getDocumentsByArrayMembershipInCollection("/techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies", "a", ["nameIndex", "catIndex"], "rank"));
   *
   * // Returns all documents inside the collection group "technologies"
   * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
   * // in an descending manner, with rank being the only factor determining the order of the results.
   * await getDocumentsByArrayMembershipInCollection("technologies", "a", ["nameIndex", "catIndex"], "rank", true, false, true));
   *
   * @param {String} collection - the collection or collection group to search.
   * @param {String} fieldValue - the value being searched for in the arrays.
   * @param {Array<String>} fields - the fields, containing arrays as values, to search.
   * @param {String} orderBy - the key to order the returned DocumentSnapshots by.
   * @param {Boolean} [group=false] - whether the collection key is in reference to a collection or a collection group.
   * @param {Boolean} [fieldOrder=true] - whether the returned array should be primarily sorted by the order of the fields array.
   * @param {Boolean} [orderByDesc=false] - whether any ordering done by the orderBy key should be desceding.
   * Note: This applies to sorting irrespective of fieldOrder.
   * @param {Number} [perFieldDocCap=null] - whether to limit the documents retrieved.
   * Note: This is a per-field cap. Use null for no limit. By default, duplicates with
   * higher indices are removed first.
   *
   * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
   */
  getDocumentsByArrayMembershipInCollection = async (
    collection,
    fieldValue,
    fields,
    orderBy,
    group = false,
    fieldOrder = true,
    orderByDesc = false,
    perFieldDocCap = null
  ) => {
    // Create a reference to either the collection or collectionGroup.
    const collectionReference = group
      ? this.db.collectionGroup(collection)
      : this.db.collection(collection);

    // An object to termporarily store data.
    const documentSnapshotObject = {};

    // Create a set to ensure that only unique documents are returned.
    const visitedPathSet = new Set();

    // Iterate over each field.
    await Promise.all(
      fields.map(async (field, index) => {
        // Attempt to get the data based upon the field.
        try {
          // Add the where clause.
          let queryReference = collectionReference.where(
            field,
            "array-contains",
            fieldValue
          );

          // Add the orderBy, if it exists.
          if (orderBy)
            queryReference = queryReference.orderBy(
              orderBy,
              orderByDesc ? "desc" : "asc"
            );

          // Add the limit, if it exists.
          if (perFieldDocCap !== null)
            queryReference = queryReference.limit(perFieldDocCap);

          // Get the results.
          const querySnapshot = await queryReference.get();

          // Add the data to the documentSnapshotObject, retaining only unvisited documents.
          documentSnapshotObject[index] =
            querySnapshot?.docs?.filter(
              (doc) => !visitedPathSet.has(doc.path)
            ) ?? [];

          // Add the documents' paths to the set.
          visitedPathSet.add(...querySnapshot?.docs.map((doc) => doc.path));

          // Catch all errors.
        } catch (error) {
          // Do nothing if an error occurred.
          console.log(error);
        }

        // Return the field.
        return field;
      })
    );

    // The final array of DocumentSnapshots to return.
    const documentSnapshotArray = [];

    // Add the data to the documentSnapshotArray in field order.
    for (let i = 0; i < fields.length; i++) {
      if (documentSnapshotObject[i] !== void 0)
        documentSnapshotArray.push(...documentSnapshotObject[i]);
    }

    // If the field order is irrelevant, sort based upon the key.
    if (!fieldOrder && orderBy !== void 0)
      // eslint-disable-next-line array-callback-return
      documentSnapshotArray.sort((documentSnapshot1, documentSnapshot2) => {
        if (
          documentSnapshot1?.data()?.[orderBy] <
          documentSnapshot2?.data()?.[orderBy]
        )
          return orderByDesc ? 1 : -1;
        if (
          documentSnapshot1?.data()?.[orderBy] >
          documentSnapshot2?.data()?.[orderBy]
        )
          return orderByDesc ? -1 : 1;
        if (
          documentSnapshot1?.data()?.[orderBy] ===
          documentSnapshot2?.data()?.[orderBy]
        )
          return 0;
      });

    // Return the array of DocumentSnapshots.
    return documentSnapshotArray;
  };

  /**
   * This function runs a query in firebase. It is as generic as possible,
   * allowing for variegated use.
   *
   * @example
   * // Gets 1 document from the partnerPublic collectionGroup where the companyName is equal to "Cicero".
   * // Does not order results in any particular way.
   * await getDocuments("partnerPublic", true, "companyName", "==", "Cicero", false, null, null, true, 1, true);
   *
   * @param {String} collection - The basic collection or CollectionGroup to search. See group, below, for more.
   * @param {Boolean} where - whether to add a where clause.
   * @param {String} whereKey - the field to search for the whereFieldValue. Disregarded if !where.
   * @param {String} whereOperand - the operand to use in the where clause. Disregarded if !where.
   * @param {any} whereFieldValue - the value of the where query to match. Disregarded if !where.
   * @param {Boolean} orderBy - whether to add an orderBy clause.
   * @param {String} orderByKey - the key to order results by. Disregarded if !orderBy.
   * @param {Boolean} [orderByDesc=false] - whether to order results in a descending manner. Disregarded if !orderBy.
   * @param {Boolean} [limit=false] - whether to add a limit clause.
   * @param {Number} [limitNumber=1] - the number of documents returned. Disregarded if !limit.
   * @param {Boolean} [group=false] - whether the "collection" string refers to a collection or to a CollectionGroup.
   *
   * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
   */
  getDocuments = async (
    collection,
    where,
    whereKey,
    whereOperand,
    whereFieldValue,
    orderBy,
    orderByKey,
    orderByDesc = false,
    limit = false,
    limitNumber = 1,
    group = false
  ) => {
    // Attempt to get the requested documents.
    try {
      // Create the queryReference.
      let queryReference = group
        ? this.db.collectionGroup(collection)
        : this.db.collection(collection);

      // Add the where clause, if required.
      if (where)
        queryReference = queryReference.where(
          whereKey,
          whereOperand,
          whereFieldValue
        );

      // Add the orderBy clause, if required.
      if (orderBy)
        queryReference = queryReference.orderBy(
          orderByKey,
          orderByDesc ? "desc" : "asc"
        );

      // Add the limit clause, if required.
      if (limit) queryReference = queryReference.limit(limitNumber);

      // Grab the data.
      const querySnapshot = await queryReference.get();

      // Return the documents.
      return querySnapshot.docs;

      // Should something go wrong, return an empty array.
    } catch (error) {
      return [];
    }
  };

  /**
   * Returns the reference of a given document by path
   * @example getDocumentRef('/vendors/0cdoLdEWQTMzyKzIUXtm')
   * @param {string} path path of the document we want the reference
   * @returns reference
   */
  getDocumentRef = (path) => {
    return this.db.doc(path);
  };

  /**
   * Reads the document referred to by the path.
   * @example getSingleDocumentFromPath('devs/sjrhhdbt23SE/')
   * @param {string} path path of the document in firebase
   * @returns the document response
   */
  getSingleDocumentFromPath = async (path) => {
    let resp = await this.db.doc(path).get();
    return resp;
  };

  getDocsFromArray = async (colGroupName, whereKey, whereValues) => {
    try {
      let queryDoc = await this.db
        .collectionGroup(colGroupName)
        .where(whereKey, "in", whereValues)
        .get();
      let resData = [];
      let names = [];
      for (const doc of queryDoc.docs) {
        let data = doc.data();
        resData.push(data);
        names.push(data.name);
      }
      return [resData, names];
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * This function returns an array of DocumentSnapshots where a value (fieldValue)
   * is found in any field in the fields array (fields). It searches either a
   * collection (collection && !group) or a collection group (collection && group).
   * The ordering of the fields is done in accordance with the following list of
   * precendence: index of the field in the fields array, then the orderBy key
   * (fieldOrder && orderBy). Alternatively, one can have the results returned purely
   * based upon the orderBy key (!fieldOrder && orderBy).
   * The order may be set to descending (orderByDesc).
   *
   *
   * @example
   * // Returns all documents inside the collection "techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies"
   * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
   * // in an ascending manner, with rank being the secondary factor. The primary determinant is
   * // whether the "a" was found in the "nameIndex" or the "catIndex" field; "nameIndex" matches
   * // will come first.
   * await getDocumentsByArrayMembershipInCollection("/techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies", "a", ["nameIndex", "catIndex"], "rank"));
   *
   * // Returns all documents inside the collection group "technologies"
   * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
   * // in an descending manner, with rank being the only factor determining the order of the results.
   * await getDocumentsByArrayMembershipInCollection("technologies", "a", ["nameIndex", "catIndex"], "rank", true, false, true));
   *
   * @param {String} collection - the collection or collection group to search.
   * @param {String} fieldValue - the value being searched for in the arrays.
   * @param {Array<String>} fields - the fields, containing arrays as values, to search.
   * @param {String} orderBy - the key to order the returned DocumentSnapshots by.
   * @param {Boolean} [group=false] - whether the collection key is in reference to a collection or a collection group.
   * @param {Boolean} [fieldOrder=true] - whether the returned array should be primarily sorted by the order of the fields array.
   * @param {Boolean} [orderByDesc=false] - whether any ordering done by the orderBy key should be desceding.
   * Note: This applies to sorting irrespective of fieldOrder.
   * @param {Number} [perFieldDocCap=null] - whether to limit the documents retrieved.
   * Note: This is a per-field cap. Use null for no limit. By default, duplicates with
   * higher indices are removed first.
   *
   * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
   */
  getDocumentsByArrayMembershipInCollection = async (
    collection,
    fieldValue,
    fields,
    orderBy,
    group = false,
    fieldOrder = true,
    orderByDesc = false,
    perFieldDocCap = null
  ) => {
    // Create a reference to either the collection or collectionGroup.
    const collectionReference = group
      ? this.db.collectionGroup(collection)
      : this.db.collection(collection);

    // An object to termporarily store data.
    const documentSnapshotObject = {};

    // Create a set to ensure that only unique documents are returned.
    const visitedPathSet = new Set();

    // Iterate over each field.
    await Promise.all(
      fields.map(async (field, index) => {
        // Attempt to get the data based upon the field.
        try {
          // Add the where clause.
          let queryReference = collectionReference.where(
            field,
            "array-contains",
            fieldValue
          );

          // Add the orderBy, if it exists.
          if (orderBy)
            queryReference = queryReference.orderBy(
              orderBy,
              orderByDesc ? "desc" : "asc"
            );

          // Add the limit, if it exists.
          if (perFieldDocCap !== null)
            queryReference = queryReference.limit(perFieldDocCap);

          // Get the results.
          const querySnapshot = await queryReference.get();

          // Add the data to the documentSnapshotObject, retaining only unvisited documents.
          documentSnapshotObject[index] =
            querySnapshot?.docs?.filter(
              (doc) => !visitedPathSet.has(doc.path)
            ) ?? [];

          // Add the documents' paths to the set.
          visitedPathSet.add(...querySnapshot?.docs.map((doc) => doc.path));

          // Catch all errors.
        } catch (error) {
          // Do nothing if an error occurred.
          console.log(error);
        }

        // Return the field.
        return field;
      })
    );

    // The final array of DocumentSnapshots to return.
    const documentSnapshotArray = [];

    // Add the data to the documentSnapshotArray in field order.
    for (let i = 0; i < fields.length; i++) {
      if (documentSnapshotObject[i] !== void 0)
        documentSnapshotArray.push(...documentSnapshotObject[i]);
    }

    // If the field order is irrelevant, sort based upon the key.
    if (!fieldOrder && orderBy !== void 0)
      documentSnapshotArray.sort((documentSnapshot1, documentSnapshot2) => {
        if (
          documentSnapshot1?.data()?.[orderBy] <
          documentSnapshot2?.data()?.[orderBy]
        )
          return orderByDesc ? 1 : -1;
        if (
          documentSnapshot1?.data()?.[orderBy] >
          documentSnapshot2?.data()?.[orderBy]
        )
          return orderByDesc ? -1 : 1;
        if (
          documentSnapshot1?.data()?.[orderBy] ===
          documentSnapshot2?.data()?.[orderBy]
        )
          return 0;
      });

    // Return the array of DocumentSnapshots.
    return documentSnapshotArray;
  };

  /**
   * Will add a collection path then create a new document
   * @param {string} path => collection path
   * @param {Object} data => data to be added in the document
   */
  createDocumentFromPath = async (path, data) => {
    let resp = await this.db.collection(path).add(data);
    return resp;
  };

  /**
   *  Will edit a document based on the path
   * @param {string} path => path of the document to be edited
   * @param {*} update => object of edited data
   * @returns
   */
  updateDocFromPath = async (path, update) => {
    let resp = await this.db.doc(path).set(update, { merge: true });
    return resp;
  };
  // adds a user to the firebase authentication
  // does not add to users collection
  // uses firebaseorderByDescs auth solution
  // email should have include "@" and password should be 6 characters long
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  // sign in a user
  // uses firebases auth solution
  // email should have include "@" and password should be 6 characters long
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  // sign out a user
  // uses firebases auth solution
  doSignOut = () => this.auth.signOut();

  /**
   *
   * @returns firebase storage
   */
  getStorage = () => {
    return this.storage;
  };

  /**
   *
   * @returns firebase remote config
   */
  remoteConf = () => {
    return this.remoteConfig;
  };

  // Sends the user an email to the email they input in passResetForm with
  // instructions on how to reset their password
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // Updates password in the users documentation
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // Return true if a user is logged in currently, false otherwise
  get isLoggedIn() {
    return this.auth.currentUser ? true : false;
  }

  //Returns document of the user currently logged in
  getCurrentUserDoc = async () => {
    // Use try because if the user logs out faster than this function is
    // called, this.auth.currentUser is null
    try {
      const userId = this.auth.currentUser.uid;
      let userRef = this.db.collection("users").doc(userId);
      let userDoc = await userRef.get();
      return { ...userDoc.data(), id: userDoc.id };
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  // Returns true if spryte-partner document exists else false
  isAccountLinked = async () => {
    let accountsRef = await this.getSprytePartnerRef();
    let query = accountsRef.get().then(async (docSnapshot) => {
      if (docSnapshot.exists) {
        if (docSnapshot.data().vendorRef) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
    return query;
  };

  /**
   * This function check if the user already have a spryte-dev account so we can avoid user to change a password
   * if the user does have a spryte dev account he will just need to updatethe vendor details
   * @returns boolean
   */
  isVendorExist = async () => {
    const userId = this.auth.currentUser.uid;
    const userRef = this.db.collection("users").doc(userId);
    const vendorAccounts = userRef
      .collection("accounts")
      .doc("spryte-dev")
      .get()
      .then(async (docSnap) => {
        if (docSnap.exists) {
          let data = docSnap.data();
          if (data.status) {
            return true;
          }
          return false;
        }
        return false;
      });

    return vendorAccounts;
  };

  //get partner ref in the account on user subcollection
  getSprytePartnerRef = async () => {
    const userId = this.auth.currentUser.uid;
    const userRef = this.db.collection("users").doc(userId);
    const sprytePartnerRef = userRef
      .collection("accounts")
      .doc("spryte-partner");
    return sprytePartnerRef;
  };

  /**
   *
   * @param {String} vendorName
   * @returns selected vendor by the name
   */
  getVendorByName = async (vendorName) => {
    const vendorRef = this.db
      .collection("vendors")
      .where("companyName", "==", vendorName)
      .get()
      .then(async (docSnapshot) => {
        let vendorDocs = docSnapshot.docs;
        if (vendorDocs.length > 0) {
          const vendorData = vendorDocs[0].data();
          return { ...vendorData, id: vendorDocs[0].id };
        }

        return null;
      });

    return vendorRef;
  };

  // Return the partner role
  getPartnerRole = async () => {
    const sprytePartnerRef = await this.getSprytePartnerRef();
    const query = sprytePartnerRef.get().then(async (docSnapshot) => {
      if (docSnapshot.exists) {
        const userAccountData = docSnapshot.data();
        return userAccountData?.role?.id ?? false;
      }
    });
    return query;
  };

  // Returns the vendor id for the vendor the current user is associated with
  getCurrentVendorId = async () => {
    try {
      const sprytePartnerRef = await this.getSprytePartnerRef();
      const query = await sprytePartnerRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          let vendorRef = docSnapshot.data().vendorRef;
          return vendorRef.id;
        }
      });
      return query;
    } catch (err) {
      return false;
    }
  };

  // Returns document of the vendor of the user that is currently logged in
  // Returns undefined is the logged in user is does not have a spryte-partner
  // document in the accounts collection. User is not linked to the
  // spryte-partner application
  getCurrentVendorDoc = async () => {
    // Use try because if the user logs out faster than this function is
    // called, this.auth.currentUser is null
    try {
      let accountsRef = await this.getSprytePartnerRef();
      let query = accountsRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          if (
            docSnapshot.data().vendorRef &&
            docSnapshot.data().status === true
          ) {
            let accountsDoc = await accountsRef.get().then((doc) => doc.data());

            let vendor = await accountsDoc.vendorRef.get();

            let vendorData = vendor.data();

            vendorData.vendorRef = vendor.ref;
            // Convert date variables to readable format, handle for null value
            if (vendorData?.verification?.dateOfFormation) {
              let date = vendorData.verification.dateOfFormation.toDate();
              vendorData.verification.dateOfFormation =
                date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear();
            } else {
              vendorData["verification"]["dateOfFormation"] = " ";
            }
            if (vendorData?.verification?.lastUpdated) {
              let date = vendorData.verification.lastUpdated.toDate();
              vendorData.verification.lastUpdated =
                date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear();
            } else {
              vendorData.verification.lastUpdated = " ";
            }
            if (vendorData.legal.lastUpdated) {
              let date = vendorData.legal.lastUpdated.toDate();
              vendorData.legal.lastUpdated =
                date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear();
            } else {
              vendorData.legal.lastUpdated = " ";
            }
            if (vendorData.reviews.lastUpdated) {
              let date = vendorData.reviews.lastUpdated.toDate();
              vendorData.reviews.lastUpdated =
                date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear();
            } else {
              vendorData.reviews.lastUpdated = " ";
            }
            // Convert booleans to values syncfusion can read
            if (vendorData.financial.publicCompany) {
              vendorData.financial.publicCompany = "YES";
            } else {
              vendorData.financial.publicCompany = "NO";
            }
            if (vendorData.verification.status) {
              vendorData.verification.status = "Active";
            } else {
              vendorData.verification.status = "Inactive";
            }
            if (vendorData.legal.bankruptcy) {
              vendorData.legal.bankruptcy = "YES";
            } else {
              vendorData.legal.bankruptcy = "NO";
            }
            if (vendorData?.terms) {
              let userDoc = await vendorData?.terms.agreedBy.get();
              let agreedBy = {};
              agreedBy["first"] = userDoc.data()?.first ?? " ";
              agreedBy["last"] = userDoc.data()?.last ?? " ";
              agreedBy["ref"] = vendorData?.terms?.agreedBy;
              vendorData.terms["agreedBy"] = agreedBy;
            }
            return { ...vendorData, id: vendor.id };
          }
          return false;
        } else {
          return false;
        }
      });
      return query;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  // Returns an array of all devs within devs collection
  getDevs = () =>
    this.db
      .collection("devs")
      .get()
      .then((querySnapshot) => {
        const devs = [];
        querySnapshot.forEach((dev) =>
          devs.push({ ...dev.data(), id: dev.id })
        );
        return devs;
      });

  authenticateDev = async (docId, passcode) => {
    let query = await this.db
      .collection("devs")
      .doc(docId)
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          const dev = docSnapshot.data();
          if (dev.accessCode === passcode) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    return query;
  };

  allDevsData = async (snapshot, wantVendor = false, wantRates = false) => {
    let devs = [];
    for (const dev of snapshot.docs) {
      // let devsGet = await dev.ref.collection("rates").get();

      // The frontend uses status as a string. Convert the bool into
      // a string before sending to frontend
      let status = "";
      if (dev.data().status === true) {
        status = "Active";
      } else {
        status = "Inactive";
      }

      try {
        // Omit the GeoTag key and status. Separate the GeoTag data
        // in to two separate keys and convert status from bool to
        // string because that is how the front end wants it

        let devInfo = dev.data();
        if (wantVendor) {
          const vendorDoc = await devInfo.vendorRef.get();
          const vendorData = vendorDoc.data();
          if (vendorData) {
            vendorData["ref"] = vendorDoc.ref;
            devInfo.company = vendorData.companyName;
            devInfo.vendorData = vendorData;
          }
        }
        devInfo.hourlyRate = 0;
        if (wantRates) {
          //the fulldate should be 2021/01/31
          let currentDate = new Date();
          let day = currentDate.getDate();
          let theDay = day > 9 ? day.toString() : "0" + day.toString();
          let month = currentDate.getMonth() + 1;
          let theMonth = month > 9 ? month.toString() : "0" + month.toString();
          let year = currentDate.getFullYear();
          let fulldate = year.toString() + theMonth + theDay;
          let rateDoc = await this.db
            .collection("devs")
            .doc(dev.id)
            .collection("rates")
            .doc(fulldate)
            .get();
          let rateData = rateDoc.data();
          if (rateData) {
            for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
              if (rateData.hourlyRates[secLev]) {
                devInfo.hourlyRate = rateData.hourlyRates
                  ? rateData.hourlyRates[secLev]
                  : 0;
              }
            }
          }
        }

        let type = "RESOURCE";

        if (devInfo?.userRef && !devInfo?.isVerified) {
          type = "PLEASE VERIFY";
        } else if (devInfo?.userRef && devInfo?.isVerified && devInfo?.intern) {
          type = "INTERN";
        }
        devInfo.type = type;
        devInfo.id = dev.id;
        devInfo.addedOn = devInfo?.addedOn?.toDate() ?? new Date();
        // devInfo.hourlyRate = 0;
        devInfo.devRef = dev.ref;
        devInfo.status = status;
        devInfo.progress = 0;
        devInfo.name = devInfo?.first + " " + devInfo?.last;
        let profileProgress = devInfo?.profileProgress;
        // delete devInfo.profileProgress;
        devInfo.progress =
          Math.floor(
            ((profileProgress?.stacksProgress || 0) * 0.125 +
              (profileProgress?.categoriesProgress || 0) * 0.125 +
              (profileProgress?.sectorsProgress || 0) * 0.125 +
              (profileProgress?.ratesProgress || 0) * 0.5 +
              0.125) *
              100
          ) ?? 0;

        if (devInfo.geoTag) {
          devInfo.latitude = devInfo.geoTag.latitude;
          devInfo.longitude = devInfo.geoTag.longitude;
          delete devInfo.geoTag;
        } else {
          // devInfo.latitude = dev.data().locationData ? dev.data().locationData.geoTag.latitude : 40.7127753
          // devInfo.longitude = dev.data().locationData ? dev.data().locationData.geoTag.longitude : -74.0059728
          devInfo.latitude = dev.data().locationData.geoTag.latitude;
          devInfo.longitude = dev.data().locationData.geoTag.longitude;
          // devInfo.progress = (profileProgress * 100).toFixed(2);
        }
        devs.push(devInfo);
      } catch (error) {}
    }
    return devs;
  };

  /**
   * Uses the vendor ref to find matching devs with the same vendor
   * ref then get these devs with a limit(10 as default) and uses startAfter if not
   * null to get devs after the startAfter doc
   *
   * @param {reference} vendorRef reference of the vendor
   * @param {snap} startAfter document to ignore and start with the next one
   * @param {number} limit
   * @returns Array of devs doc
   */
  getDevsWithLimit = async (vendorRef, startAfter = null, limit = 10) => {
    let defRef = this.db
      .collection("devs")
      .where("vendorRef", "==", vendorRef)
      .where("draft", "==", false);
    if (startAfter) defRef = defRef.startAfter(startAfter);
    let query = defRef
      .limit(limit)
      .get()
      .then((snaps) => {
        let devs = [];
        snaps.forEach(async (dev) => {
          // The frontend uses status as a string. Convert the bool into
          // a string before sending to frontend
          let status = "";
          if (dev.data().status === true) {
            status = "Active";
          } else {
            status = "Inactive";
          }

          try {
            // Omit the GeoTag key and status. Separate the GeoTag data
            // in to two separate keys and convert status from bool to
            // string because that is how the front end wants it
            let devInfo = dev.data();
            delete devInfo.hourlyRate;
            let type = "RESOURCE";

            if (devInfo?.userRef && !devInfo?.isVerified) {
              type = "PLEASE VERIFY";
            } else if (
              devInfo?.userRef &&
              devInfo?.isVerified &&
              devInfo?.intern
            ) {
              type = "INTERN";
            }
            devInfo.type = type;
            devInfo.id = dev.id;
            devInfo.devRef = dev.ref;
            devInfo.hourlyRate = 0;
            devInfo.status = status;
            devInfo.progress = 0;

            if (devInfo.geoTag) {
              devInfo.latitude = devInfo.geoTag.latitude;
              devInfo.longitude = devInfo.geoTag.longitude;
              delete devInfo.geoTag;
            } else {
              // devInfo.latitude = dev.data().locationData ? dev.data().locationData.geoTag.latitude : 40.7127753
              // devInfo.longitude = dev.data().locationData ? dev.data().locationData.geoTag.longitude : -74.0059728
              devInfo.latitude = dev.data().locationData.geoTag.latitude;
              devInfo.longitude = dev.data().locationData.geoTag.longitude;
              // devInfo.progress = (profileProgress * 100).toFixed(2);
            }
            if (!devInfo?.draft) devs.push(devInfo);
          } catch (error) {}
        });
        return devs;
      });
    return query;
  };

  // Returns a list of devs associated with the vendor that is currently
  // signed in. Returns 'No devs for this vendor' if there are no devs
  // associated with the vendor.

  getDevsForVendor = (vendorId) => {
    const devs = [];
    let bookableDevs = 0;
    const vendorRef = this.db.collection("vendors").doc(vendorId);
    let devsRef = this.db.collection("devs");
    let query = devsRef
      .where("vendorRef", "==", vendorRef)
      .where("draft", "==", false)
      .get()
      .then((snapshot) => {
        snapshot.forEach((dev) => {
          // The frontend uses status as a string. Convert the bool into
          // a string before sending to frontend
          let status = "";
          if (dev.data().status === true) {
            status = "Active";
            bookableDevs++;
          } else {
            status = "Inactive";
          }
          try {
            // Omit the GeoTag key and status. Separate the GeoTag data
            // in to two separate keys and convert status from bool to
            // string because that is how the front end wants it
            let devInfo = { ...dev.data() };
            devInfo.id = dev.id;
            devInfo.status = status;

            // Convert geoTeg to a format syncfusion and javascript can
            // work with
            if (devInfo.geoTag) {
              devInfo.latitude = devInfo.geoTag.latitude;
              devInfo.longitude = devInfo.geoTag.longitude;
              delete devInfo.geoTag;
            } else {
              devInfo.latitude = dev.data().locationData
                ? dev.data().locationData.geoTag.latitude
                : 40.7127753;
              devInfo.longitude = dev.data().locationData
                ? dev.data().locationData.geoTag.longitude
                : -74.0059728;
            }
            devs.push(devInfo);
          } catch (error) {
            console.log(error);
          }
        });
        return { devs: devs, bookable: bookableDevs };
      });
    return query;
  };

  /**
   * Takes the vendor Id and look for that vendor Id then return the needed data
   * @example firebase.getVendorPublicData('1r4JxU5y6xsfiNij58FL')
   * @param {string} vendorId Id of the vendor we want to get the public data
   * @returns the data of the profile data in the partnerPublic collection in firebase
   */
  getVendorPublicData = async (vendorId) => {
    const pubData = this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("partnerPublic")
      .doc("profile")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.data();
      });
    return pubData;
  };
  getVendorById = async (vendorId) => {
    const vendRef = this.db.collection("vendors").doc(vendorId);
    let query = vendRef.get().then(async (docSnapshot) => {
      if (docSnapshot.exists) {
        let vendorData = docSnapshot.data();
        vendorData["vendorRef"] = docSnapshot.ref;
        // Convert date variables to readable format, handle for null value

        if (vendorData?.verification?.dateOfFormation) {
          let date = vendorData.verification.dateOfFormation.toDate();
          vendorData.verification.dateOfFormation =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear();
        } else {
          vendorData.verification.dateOfFormation = " ";
        }
        if (vendorData?.verification?.lastUpdated) {
          let date = vendorData.verification.lastUpdated.toDate();
          vendorData.verification.lastUpdated =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear();
        } else {
          vendorData.verification.lastUpdated = " ";
        }
        if (vendorData?.legal?.lastUpdated) {
          let date = vendorData.legal.lastUpdated.toDate();
          vendorData.legal.lastUpdated =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear();
        } else {
          vendorData.legal.lastUpdated = " ";
        }
        if (vendorData?.reviews?.lastUpdated) {
          let date = vendorData.reviews.lastUpdated.toDate();
          vendorData.reviews.lastUpdated =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear();
        } else {
          vendorData.reviews.lastUpdated = " ";
        }
        // Convert booleans to values syncfusion can read
        if (vendorData?.financial?.publicCompany) {
          vendorData.financial.publicCompany = "YES";
        } else {
          vendorData.financial.publicCompany = "NO";
        }
        if (vendorData?.verification?.status) {
          vendorData.verification.status = "Active";
        } else {
          vendorData.verification.status = "Inactive";
        }
        if (vendorData?.legal?.bankruptcy) {
          vendorData.legal.bankruptcy = "YES";
        } else {
          vendorData.legal.bankruptcy = "NO";
        }
        if (vendorData?.terms) {
          let userDoc = await vendorData?.terms.agreedBy.get();
          let agreedBy = {};
          agreedBy["first"] = userDoc.data()?.first ?? " ";
          agreedBy["last"] = userDoc.data()?.last ?? " ";
          agreedBy["ref"] = vendorData?.terms?.agreedBy;
          vendorData.terms["agreedBy"] = agreedBy;
        }
        return { ...vendorData, id: vendorId };
      } else {
        return false;
      }
    });
    return query;
  };

  /**
   * Retrieve certifcations in the Certification subcollection of a vendor
   * @param {*} vendorId Vendor's document ID
   */
  getCertifications = (vendor) => {
    // Retrieve certifcation information from firebase
    let certs = this.db
      .collection("vendors")
      .doc(vendor.id)
      .collection("certifications")
      .get()
      .then((querySnapshot) => {
        const certifications = [];
        querySnapshot.forEach((cert) => {
          certifications.push({ ...cert.data() });
        });
        // Add to the certs data
        for (let cert of certifications) {
          // Filter the requirements for the certification we are looking for
          let requirementList = Object.values(
            vendor?.thirdPartyTrust?.tptData["company"][
              "progress_by_requirement"
            ] ?? {}
          );

          let requirement =
            requirementList.filter((req) => {
              return cert.tag === req["name"];
            }) ?? [];
          // Populate the cert object with the necessary information
          //if we find a match we will populate the data from  tptData
          //else we willsend default values
          if (requirement[0]) {
            cert.requirements = requirement[0]["number_of_requirements"];
            cert.status = requirement[0]["is_completed"];
            cert.score = requirement[0]["total_progress_score"];
            cert.manualReviewState = requirement[0]["manual_review_state"];
          } else {
            cert.requirements = 0;
            cert.status = false;
            cert.score = 0;
            cert.manualReviewState = 0;
          }
        }
        return certifications;
      });
    return certs;
  };

  // Returns a list of all vendors in the database
  getVendors = () =>
    this.db
      .collection("vendors")
      .get()
      .then((querySnapshot) => {
        const vendors = [];
        querySnapshot.forEach((vend) =>
          vendors.push({ ...vend.data(), id: vend.id })
        );
        return vendors;
      });

  // Returns a list of all vendors in the database
  getVendorsIdName = () =>
    this.db
      .collection("vendors")
      .orderBy("addedOn", "desc")
      .get()
      .then(async (querySnapshot) => {
        const vendors = [];
        for (const vend of querySnapshot.docs) {
          let vendorData = vend.data();

          const {
            companyName,
            lastLogin,
            vendorProfileProgress,
            settings,
            marketCount,
            caseStudyCount,
            priceCardCount,
            ...data
          } = vendorData;
          let managerDoc = await vendorData?.manager?.get();
          let manager = managerDoc?.data();
          let managerName = manager
            ? manager?.first + " " + manager?.last
            : null;
          let caseStudies = caseStudyCount
            ? caseStudyCount["DRAFT"] +
              caseStudyCount["FEATURED"] +
              caseStudyCount["READY-TO-SUBMIT"] +
              caseStudyCount["REVIEWED"]
            : 0;

          let marketCountNo =
            Object.values(marketCount ?? {}).reduce((a, b) => a + b, 0) ?? 0;
          let priceCardCountNo =
            Object.values(priceCardCount ?? {}).reduce((a, b) => a + b, 0) ?? 0;

          let vendorProgress = 0.0;
          if (vendorProfileProgress) {
            vendorProgress =
              (vendorProfileProgress.devsProgress || 0) * 0.75 +
              (vendorProfileProgress.financialProgress || 0) * 0.25;
          }
          let theTrustScore = 0.0;
          if (settings?.generalService) {
            theTrustScore =
              settings?.generalService.reduce(function (accumulator, item) {
                return accumulator + item.trustScore;
              }, 0) / settings?.generalService.length;
          }
          let logout = lastLogin?.toDate() ?? null;
          if (logout) {
            let today = new Date();
            let difTime = today.getTime() - logout.getTime();
            let days = (difTime / (1000 * 3600 * 24)).toFixed(0);
            if (days >= 0) {
              logout = parseInt(days);
            } else {
              logout = 999;
            }
          }

          vendors.push({
            name: companyName ?? "no name",
            id: vend.id,
            ...data,
            lastLogin: lastLogin?.toDate().toDateString() ?? "No Login Time",
            totalDevs: data.totalDevs ?? 0,
            addedOn: data.addedOn?.toDate() ?? new Date(),
            totalDevsLive: data.totalDevsLive ?? 0,
            bookableCount: data.bookableCount ?? 0,
            caseStudies: caseStudies,
            marketCountNo: marketCountNo ?? 0,
            marketCount: marketCount,
            priceCardCountNo: priceCardCountNo ?? 0,
            priceCardCount: priceCardCount,
            lastLogOut: logout ?? 999,
            caseStudyCount: caseStudyCount,
            profileCompletion: vendorProgress,
            trustScore: theTrustScore.toFixed(2),
            managerName: managerName,
          });
        }
        return vendors;
      });

  // Returns a list of stacks from all the technologies subcollections in the
  // techStacks150r collection
  getTech = () =>
    this.db
      .collection("techStacks150r")
      .get()
      .then((techSnapshot) => {
        const stacks = [];
        const stackObject = {};
        techSnapshot.forEach((category) => {
          let category_name = category.data().category_tag;

          this.db
            .collection("techStacks150r")
            .doc(category.id)
            .collection("technologies")
            .get()
            .then((stacksSnapShot) => {
              stacksSnapShot.forEach((stack) => {
                let stackData = { ...stack.data() };
                if (stackData.show) {
                  stacks.push({
                    ...stackData,
                    id: stack.id,
                    category: category_name,
                    ref: stack.ref,
                  });
                  stackObject[
                    stackData.title
                  ] = `/techStacks150r/${category.id}/technologies/${stack.id}`;
                }
              });
            });
        });
        return [stacks, stackObject];
      });

  getTechCats = () =>
    this.db
      .collection("techStacks150r")
      .get()
      .then((techSnapshot) => {
        let techs = [];
        techSnapshot.forEach((category) => {
          techs.push({
            ...category.data(),
            id: category.id,
            ref: category.ref,
          });
        });
        return techs;
      });

  //get the spryte email for contact
  getEmail = () =>
    this.db
      .collection("organisations")
      .doc("T6BAcTjwbXleCFpmWTmu")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.data().enterprisePartnerContact;
      });

  // Returns a list of entrepriseClients from the organization
  getSubsClients = () =>
    this.db
      .collection("organisations")
      .doc("T6BAcTjwbXleCFpmWTmu")
      .collection("enterpriseClients")
      .get()
      .then((querySnapshot) => {
        const clients = [];
        querySnapshot.forEach((client) => {
          let data = client.data();
          let domainsList = data.domains;
          let domains = [];
          domainsList.forEach((domain) => {
            domain.get().then((da) => {
              let domainData = da.data();
              domains.push(domainData.name);
            });
          });
          clients.push({
            ...client.data(),
            id: client.id,
            domains: domains,
          });
        });

        return clients;
      });

  getTechs = (catId, catTag) =>
    this.db
      .collection("techStacks150r")
      .doc(catId)
      .collection("technologies")
      .get()
      .then((stacksSnapShot) => {
        let techs = [];
        stacksSnapShot.forEach((stack) => {
          techs.push({
            ...stack.data(),
            id: stack.id,
            category_name: catTag,
          });
        });
        return techs;
      });

  getVendId = async (vendId) => {
    const vendRef = this.db.collection("vendors").doc(vendId);
    const vendDoc = await vendRef.get();
    const vendData = vendDoc.data();
    return vendData;
  };
  getDevById = async (devId) => {
    const devRef = this.db.collection("devs").doc(devId);
    const devDoc = await devRef.get();
    const devData = devDoc.data();
    let dev;
    // change geoTag from firebase object to keys
    if (devData.geoTag) {
      dev = {
        id: devId,
        // Separate the GeoTag data in to two separate keys
        latitude: devData.geoTag.latitude,
        longitude: devData.geoTag.longitude,
        ...devData,
      };
      delete dev.geoTag;
    } else {
      dev = {
        id: devId,
        // Separate the GeoTag data in to two separate keys
        latitude: devData.locationData.geoTag.latitude,
        longitude: devData.locationData.geoTag.longitude,
        ...devData,
      };
    }
    // Convert status from bool to string because that is how the front end wants it
    let status = "";
    if (devData.status === true) {
      status = "Active";
    } else {
      status = "Inactive";
    }
    dev.status = status;
    return dev;
  };

  // Returns a list of stack objects associated with a dev
  // Searches through the devs collection for a certain dev ID and retrieves
  // the information in the stacks subcollection for that dev
  getStacks = (id) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("stacks")
      .get()
      .then((querySnapshot) => {
        const stacks = [];
        querySnapshot.forEach((stack) =>
          stacks.push({ ...stack.data(), id: stack.id })
        );
        return stacks;
      });

  /**
   * Takes a dev Id and returns an arrays of the resources educations
   * @param {String} id Id of the specific dev we want to retrive the education
   * @returns Array of education objects with id each id011
   */
  getEducation = (id) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("education")
      .get()
      .then((querySnapshot) => {
        const educations = [];
        querySnapshot.forEach((education) =>
          educations.push({ ...education.data(), id: education.id })
        );
        return educations;
      });

  // Returns a list of domain experience objects associated with a dev
  // Searches through the devs collection for a certain dev ID and retrieves
  // the information in the sectors subcollection for that dev
  getDomains = (id) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("sectors")
      .get()
      .then((querySnapshot) => {
        const domains = [];
        querySnapshot.forEach((domain) => {
          domains.push({ ...domain.data(), id: domain.id });
        });
        return domains;
      });

  // Returns a list of project experience objects associated with a dev
  // Searches through the devs collection for a certain dev ID and retrieves
  // the information in the projectExperience subcollection for that dev
  getProjExp = (devId) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("categories")
      .get()
      .then((querySnapshot) => {
        const projExps = [];
        querySnapshot.forEach((projExp) =>
          projExps.push({ ...projExp.data(), id: projExp.id })
        );
        return projExps;
      });

  // Returns a list of project objects associated with a dev
  // Searches through the devs collection for a certain dev ID and retrieves
  // the information in the projects subcollection for that dev
  getVac = (id) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("vacation")
      .get()
      .then((querySnapshot) => {
        const vacations = [];
        querySnapshot.forEach((vacation) => {
          const { from, to, ...data } = vacation.data();
          vacations.push({
            description: data.description,
            from: from.toDate(),
            to: to.toDate(),
            Id: vacation.id,
          });
        });
        return vacations;
      });

  getDevRates = (id, maxSecLev) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("rateRanges")
      .orderBy("to", "desc")
      .get()
      .then(async (querySnapshot) => {
        const rates = [];
        // Vendors can edit rates for security levels equal to and below their
        // security level and one above
        // i.e. vendors with maxSecLev = L1 can edit rates for L1 and L2
        querySnapshot.forEach((rate) => {
          const { from, to, hourlyRates, ...data } = rate.data();
          // Syncfusion wants the data flat so remove the map returned by
          // firestore and give it to the front end flat
          Object.entries(hourlyRates).forEach((entry) => {
            const [key, value] = entry;
            if (key <= maxSecLev) {
              data[key] = value;
            }
          });
          rates.push({
            from: from.toDate(),
            to: to.toDate(),
            id: rate.id,
            ...data,
          });
        });

        return rates;
      });

  getProfiles = (id) =>
    this.db
      .collection("devs")
      .doc(id)
      .collection("profiles")
      .get()
      .then((querySnapshot) => {
        const profiles = [];
        querySnapshot.forEach((profile) => {
          const { uploadedDate, ...data } = profile.data();
          profiles.push({
            fileName: data.fileName,
            fileUrl: data.fileUrl,
            uploadedDate: uploadedDate.toDate(),
            Id: profile.id,
          });
        });
        return profiles;
      });

  getLogos = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("logos")
      .get()
      .then((querySnapshot) => {
        const logos = [];
        querySnapshot.forEach((logo) => {
          const { uploadedDate, ...data } = logo.data();
          logos.push({
            fileName: data.fileName,
            fileUrl: data.fileUrl,
            uploadedDate: uploadedDate.toDate(),
            Id: logo.id,
          });
        });
        return logos;
      });

  addOrEditTechs = async (colName, docId, data, docToUpdId = null) => {
    let ref = this.db;

    if (colName === "techStacks150r")
      ref = ref.collection(`${colName}/${docId}/technologies`);

    if (colName === "sectors")
      ref = ref.collection(`${colName}/${docId}/industry`);

    if (colName === "categories")
      ref = ref.collection(`${colName}/${docId}/subcollections`);

    if (!docToUpdId) {
      ref.add(data).then((docSnap) => {
        docSnap.set({ id: docSnap.id }, { merge: true });
      });
    } else {
      ref.doc(docToUpdId).set(data, { merge: true });
    }
  };

  // Returns a list of project objects associated with a dev
  // Searches through the devs collection for a certain dev ID and retrieves
  // the information in the projects subcollection for that dev
  getHolidays = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("holidays")
      .get()
      .then((querySnapshot) => {
        const holidays = [];
        querySnapshot.forEach((holiday) => {
          const { from, to, ...data } = holiday.data();
          holidays.push({
            // id : data.id,
            description: data.description,
            from: from.toDate(),
            to: to.toDate(),
            Id: holiday.id,
          });
        });
        return holidays;
      });

  // Returns a list of all the files within the contracts collection of a
  // user.
  getContracts = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("contracts")
      .get()
      .then((querySnapshot) => {
        const contracts = [];
        querySnapshot.forEach((contract) => {
          const { uploadedDate, ...data } = contract.data();
          contracts.push({
            fileName: data.fileName,
            fileRef: data.fileRef,
            uploadedDate: uploadedDate.toDate(),
            Id: contract.id,
          });
        });
        return contracts;
      });

  getDocuments = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("documents")
      .get()
      .then((querySnapshot) => {
        const certifs = [];
        querySnapshot.forEach((certif) => {
          const { uploadedDate, ...data } = certif.data();
          certifs.push({
            fileName: data.fileName,
            fileRef: data.fileRef,
            uploadedDate: uploadedDate.toDate(),
            Id: certif.id,
          });
        });
        return certifs;
      });

  getExternalCaseStudies = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("caseStudies")
      .get()
      .then((querySnapshot) => {
        const casesStudies = [];
        querySnapshot.forEach((cs) => {
          if (cs.data()?.internal === false) {
            const { uploadedDate, ...data } = cs.data();

            casesStudies.push({
              fileName: data.fileName,
              fileRef: data.fileRef,
              uploadedDate: uploadedDate.toDate(),
              Id: cs.id,
            });
          }
        });
        return casesStudies;
      });

  getClientLogos = (id) =>
    this.db
      .collection("vendors")
      .doc(id)
      .collection("clients")
      .get()
      .then((querySnapshot) => {
        const clientLogos = [];
        querySnapshot.forEach((cs) => {
          if (cs.data()?.isLogo) {
            const { uploadedDate, ...data } = cs.data();

            clientLogos.push({
              fileName: data.fileName,
              fileRef: data.fileRef,
              uploadedDate: uploadedDate.toDate(),
              Id: cs.id,
            });
          }
        });
        return clientLogos;
      });
  // Returns a list of project objects associated with dev
  // Searches through the devs collection for a dev ID and returns projects
  // in the availability subcollection
  getProjects = async (id) => {
    const querySnapshot = await this.db
      .collection("devs")
      .doc(id)
      .collection("availability")
      .get(); //.then(querySnapshot => {
    const projs = [];
    querySnapshot.forEach((proj) => {
      const { start, end, ...data } = proj.data();
      try {
        let status = "";
        if (data.status) {
          try {
            status = data.status.id;
          } catch (err) {
            console.log(err);
          }
        } else {
          status = "DRAFT";
        }
        // If the project has a holiday status then ignore and skip it
        // do not add it
        if (status !== "HOLIDAY") {
          const startDate = start.toDate();
          const endDate = end.toDate();
          let task = {
            TaskID: data.taskId,
            Id: proj.id,
            Description: data.description,
            Status: status,
            StartDate: startDate,
            EndDate: endDate,
            Duration: data.duration || 1,
            Progress: data.progress,
            info: data.info,
            resources: [{ id: id }],
          };
          projs.push(task);
        }
      } catch (error) {
        return;
      }
    });
    return projs;
  };

  /**
   * Returns a sprint Data{Map} of a given vendor
   * @param {String} clientId
   * @param {String} sprintId
   */
  getSprintData = async (clientId, sprintId) => {
    const querySnapshot = await this.db
      .collection("client")
      .doc(clientId)
      .collection("sprints")
      .doc(sprintId)
      .get();

    let data = querySnapshot.data();
    return data;
  };

  /**
   * Return a Single project{Map} of a given vendor
   * @param {String} clientId
   * @param {String} projectId
   */
  getProjectData = async (clientId, projectId) => {
    const querySnapshot = await this.db
      .collection("client")
      .doc(clientId)
      .collection("projects")
      .doc(projectId)
      .get();

    let data = querySnapshot.data();
    return data;
  };
  /**
   * Getting all the data from the team by id
   * @param {string} id
   * @returns Object of the data
   */
  getDevDedictionInTeam = async (id) => {
    const querySnapshot = await this.db.collection("teams").doc(id).get();
    let data = querySnapshot.data();
    return data;
  };

  //
  getAllProjects = async (id, devData) => {
    const querySnapshot = await this.db
      .collection("devs")
      .doc(id)
      .collection("availability")
      .get(); //.then(querySnapshot => {
    const projs = [];

    querySnapshot.forEach(async (proj) => {
      const data = proj.data();
      if (data.sprintRef) {
        let sprintColId = data.sprintRef.id;
        let sprintPath = data.sprintRef.path.split("/");
        let clientId = sprintPath[1];
        let sprintData = await this.getSprintData(clientId, sprintColId);
        let projectId = sprintData.projectRef.id;

        let projectData = await this.getProjectData(clientId, projectId);

        sprintData.id = sprintColId;
        projectData.id = projectId;
        data.sprintData = sprintData;
        data.projectData = projectData;
      }

      projs.push(data);
    });

    return projs;
  };

  //   getProjects = (id) => this.db.collection("devs").doc(id).collection("availability").get().then(querySnapshot => {
  //     const stacks = [];
  //     let x;
  //     querySnapshot.forEach(stack =>
  //         stacks.push({...stack.data(), id:stack.id})
  //       );
  //     const end = stacks[0]['end'].toDate().toISOString().slice(0, 10).split('-');
  //     const start = stacks[0]['start'].toDate().toISOString().slice(0, 10).split('-');
  //     stacks[0]['end'] = end[1] +'/'+ end[2] +'/'+ end[0]
  //     stacks[0]['start'] = start[1] +'/'+ start[2] +'/'+ start[0]
  //     querySnapshot.forEach(stack => {
  //         const {start, end, ...data} = stack.data()
  //         stacks.push({...data, start: start.toDate(), end: end.toDate(), devId:id, id:stack.id})
  //       });
  //     return stacks
  //   });

  // For a list of devs, returns a list of project objects that devs in
  // the list are currently on
  getDevsAvail = (devs) => {
    const tasks = [];
    devs.forEach(async (dev) => {
      const projects = await this.getProjects(dev.id); //.then(projects => tasks.push(projects))
      tasks.push(...projects);
    });
    return tasks;
  };

  // Returns a list of domain experiences a dev can have from the
  // industry collection
  getDomainExp = () =>
    this.db
      .collection("sectors")
      .get()
      .then((sectorsSnapshot) => {
        const industries = [];
        const industriesObject = {};
        sectorsSnapshot.forEach((sector) => {
          let category = sector.data().name;
          this.db
            .collection("sectors")
            .doc(sector.id)
            .collection("industry")
            .get()
            .then((industrySnapShot) => {
              industrySnapShot.forEach((industry) => {
                let industryData = { ...industry.data() };
                if (industryData.show) {
                  industries.push({
                    ...industryData,
                    id: industry.id,
                    category: category,
                  });
                  industriesObject[
                    industryData.name
                  ] = `/sectors/${sector.id}/industry/${industry.id}`;
                }
              });
            });
        });
        return [industries, industriesObject];
      });

  // Get categories in categories collection
  getCategories = () =>
    this.db
      .collection("categories")
      .get()
      .then((querySnapshot) => {
        const categories = [];
        querySnapshot.forEach((category) =>
          categories.push({ ...category.data(), id: category.id })
        );
        return categories;
      });

  getPopularQueriesWithLimit = async (limit, lastQuery = null) => {
    let ref = this.db
      .collection("queries")
      .where("queryData.queryType", "==", "global")
      .orderBy("count.INTERACTIVE", "desc");
    if (lastQuery) {
      ref = ref.startAfter(lastQuery);
    }

    let data = await ref
      .limit(limit)
      .get()
      .then((snap) => {
        let popularQueries = [];
        let lastQuery = snap.docs[snap.docs.length - 1];
        snap.docs.forEach((querySnap) => {
          let query = querySnap.data();
          if (
            query.queryData.industryDomain.length ||
            query.queryData.projectTypes.length ||
            query.queryData.stacks.length
          ) {
            popularQueries.push(query);
            // lastQuery = query;
          }
        });
        return [popularQueries, lastQuery];
      });
    return data;
  };

  // Get subcategories from the subcollections subcollection of the
  // categories collection
  getSubCategories = () =>
    this.db
      .collection("categories")
      .get()
      .then((catSnapshot) => {
        const subCategories = [];
        const subCategoriesObject = {};
        catSnapshot.forEach((category) => {
          let category_name = category.data().name;

          this.db
            .collection("categories")
            .doc(category.id)
            .collection("subcollections")
            .get()
            .then((subCatSnapShot) => {
              subCatSnapShot.forEach((subCategory) => {
                let subCategoryData = { ...subCategory.data() };
                if (subCategoryData.show) {
                  subCategories.push({
                    ...subCategoryData,
                    id: subCategory.id,
                    category: category_name,
                  });
                  subCategoriesObject[
                    subCategoryData.name
                  ] = `/categories/${category.id}/subcollections/${subCategory.id}`;
                }
              });
            });
        });
        return [subCategories, subCategoriesObject];
      });

  //get all of the vendor projects, sprints and the team on each projects

  // Test getStacks function, retrieves data from devs then shows the
  // stacks data for that dev in the console
  testStacks = () =>
    this.getDevs().then((devs) => {
      this.getStacks(devs[0].id).then((r) => console.log(r));
    });
  // Adds a stack object in the stacks subcollection of a dev
  createStack = (devId, newStack) =>
    this.db.collection("devs").doc(devId).collection("stacks").add(newStack);

  /**
   * Takes a dev id and creates a new education for the specific dev
   * @example
   * firebase.createEducation
   * ('f3yFuHGpYalkl2rNHHpw', {
   *  'school': 'someSchool',
   * 'country': 'some country',
   * 'description': 'description',
   * 'year':'year',
   * 'degreeName':'name of the degree',
   * 'degreeLevel':'BS'
   * })
   * @param {String} devId Id of the dev to add education
   * @param {object} newEduc object containing the education data(School, country, description, year, degree name, degre level)
   * @returns
   */
  createEducation = (devId, newEduc) =>
    this.db.collection("devs").doc(devId).collection("education").add(newEduc);

  // Add a task / project to a dev in the availability subcollection
  createTask = (devId, newTask) => {
    let statusRef = this.db.collection("sprintStatuses").doc("DRAFT");
    // const vendorRef =
    // let status = ''

    let addTask = {
      start: newTask.StartDate,
      end: newTask.EndDate,
      duration: newTask.Duration,
      description: newTask.Description,
      status: statusRef,
      progress: 0,
      info: newTask.info,
      taskId: newTask.TaskID,
    };
    this.db
      .collection("devs")
      .doc(devId)
      .collection("availability")
      .add(addTask);
  };

  createProfile = (devId, newProfile) => {
    let date = new Date();
    this.db.collection("devs").doc(devId).collection("profiles").add({
      fileUrl: newProfile.fileUrl,
      uploadedDate: date,
      fileName: newProfile.fileName,
    });
  };

  createLogo = (vendId, newLogo) => {
    let date = new Date();

    this.db.collection("vendors").doc(vendId).collection("logos").add({
      fileUrl: newLogo.fileUrl,
      uploadedDate: date,
      fileName: newLogo.fileName,
    });
  };

  // Add a domain experience to a dev in the sectors subcollection
  createPrimaryDomain = (devId, domain) =>
    this.db.collection("devs").doc(devId).collection("sectors").add(domain);

  // Create project experience to project experience subcollection of a dev
  createProjExp = (devId, projExp) =>
    this.db.collection("devs").doc(devId).collection("categories").add(projExp);

  // Adds a Company Holiday object in the stacks subcollection of a dev
  createContract = (vendId, newContract) => {
    let date = new Date();
    this.db.collection("vendors").doc(vendId).collection("contracts").add({
      fileRef: newContract.fileRef,
      uploadedDate: date,
      fileName: newContract.fileName,
    });
  };

  createDocument = (vendId, newContract) => {
    let date = new Date();
    this.db.collection("vendors").doc(vendId).collection("documents").add({
      fileRef: newContract.fileRef,
      uploadedDate: date,
      fileName: newContract.fileName,
    });
  };

  createExternalCaseStudy = (vendId, newContract) => {
    let date = new Date();
    this.db.collection("vendors").doc(vendId).collection("caseStudies").add({
      fileRef: newContract.fileRef,
      uploadedDate: date,
      fileName: newContract.fileName,
      internal: false,
    });
  };

  createClientLogo = (vendId, logo) => {
    let date = new Date();
    this.db.collection("vendors").doc(vendId).collection("clients").add({
      fileRef: logo.fileRef,
      uploadedDate: date,
      fileName: logo.fileName,
      isLogo: true,
    });
  };
  createInternalCaseStudy = (vendorId, caseStudy) => {
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("caseStudies")
      .add(caseStudy);
  };

  // Adds a Company Holiday object in the stacks subcollection of a dev
  createHoliday = (vendId, newHoliday) =>
    this.db
      .collection("vendors")
      .doc(vendId)
      .collection("holidays")
      .add(newHoliday);

  // Adds a vacation object in the stacks subcollection of a dev
  createVac = (devId, newVac) =>
    this.db.collection("devs").doc(devId).collection("vacation").add(newVac);

  // Adds a rate document in the rates subcollection of a dev
  createDevRate = async (devId, newRate, ratesForSecLevUpTo) => {
    // Initalize hourlyRates field
    newRate.hourlyRates = {};
    // Syncfusion likes things flat but in the db, the hourly rates for each
    // security level is stored in a map under hourlyRate
    // Just in case, also compare the security levels for the rates to the
    // allowable security level. Ensure database does not recieve rates for
    // security levels the dev is not authorized for
    for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
      if (secLev <= ratesForSecLevUpTo) {
        if (newRate[secLev]) {
          newRate.hourlyRates[secLev] = newRate[secLev];
        } else {
          newRate.hourlyRates[secLev] = 0;
        }
      }
      delete newRate[secLev];
    }
    this.db.collection("devs").doc(devId).collection("rateRanges").add(newRate);
  };

  // Create a new user in the "users" collection, from a JavaScript object
  // of the same strucure as the "users" collection. Must include reference
  // to a new Authentication account (the returned "uid")
  // Returns a Promise
  createUser = (newUser, uid) =>
    this.db.collection("users").doc(uid).set(newUser);

  // Link account so that users can access the spryte vendor app
  // add spryte-vendor document to accounts collections with vendorRef
  linkAccount = (uid, vendorRef) =>
    this.db
      .collection("users")
      .doc(uid)
      .collection("accounts")
      .doc("spryte-partner")
      .set(vendorRef, { merge: true });

  // get accounts the user is linked to.
  // get the client ref, devsReporting ref, or vendor ref is they exist
  getAccounts = (uid) =>
    this.db
      .collection("users")
      .doc(uid)
      .collection("accounts")
      .get()
      .then((querySnapshot) => {
        return querySnapshot;
      });

  getVendorRef = (uid) =>
    this.db
      .collection("users")
      .doc(uid)
      .collection("accounts")
      .doc("spryte-partner")
      .get()
      .then((querySnapshot) => {
        return querySnapshot;
      });

  // Create a new dev in the "devs" collection, from a JavaScript object
  // of the same strucure as the "devs" collection.
  // Returns a Promise
  createDev = (newDev) => {
    // Add a addedOn timestamp
    newDev.addedOn = new Date();
    // Frontend has status as "Active" and "Inactive". In the database, it
    // is a boolean. Convert status before sending the data to the database
    let status = "";
    if (newDev.status === "Active") {
      status = true;
    } else {
      status = false;
    }
    // newDev.latitude = parseFloat(newDev.latitude)
    // newDev.longitude = parseFloat(newDev.longitude)
    // newDev.geoTag = new firestore.GeoPoint(newDev.latitude, newDev.longitude)
    // delete newDev.latitude
    // delete newDev.longitude

    newDev.status = status;

    //store sprint Rate and hourly Rate in a variable for later use
    let hourlyRate = newDev.hourlyRate;
    let dailyHours = newDev.hoursInWorkDay;

    //delete hoursInWorkDay and hourlyRate to not use it when creating the dev doc
    delete newDev.hoursInWorkDay;
    delete newDev.hourlyRate;
    newDev.profileProgress = {
      stacksProgress: 0,
      categoriesProgress: 0,
      sectorsProgress: 0,
    };
    let devsRef = this.db
      .collection("devs")
      .add(newDev)
      .then(async (re) => {
        //after a dev is created we will retrieve the dev id to be able to create the dev rateRange collection
        let devId = re.id;
        let startDate = new Date();
        let date = new Date();
        //
        //end Date is a year from the day the dev was added in the vendor resources
        let endDate = new Date(date.setFullYear(date.getFullYear() + 1));

        let data = {
          dailyHours: dailyHours,
          description: "",
          from: new Date(startDate.setHours(0, 0, 0, 0)),
          to: new Date(endDate.setHours(23, 59, 59, 59)),
          hourlyRates: {
            L1: hourlyRate,
          },
        };
        await this.db
          .collection("devs")
          .doc(devId)
          .collection("rateRanges")
          .add(data);
        return devId;
      });

    return devsRef;
  };

  // Similar to createDev but retrieves and returns
  // document ID for the newly created dev
  // Unlike createDev, the input newDev will be mostly formatted to how
  // firebase wants to retrieve it, the only conversion we have to do is
  // the GeoTag
  createDevBulkUpload = (newDev) => {
    newDev.addedOn = new Date();

    //store sprint Rate and hourly Rate in a variable for later use
    let dailyHours = newDev.hoursInWorkDay;
    let hourlyRate = newDev.hourlyRate;

    //delete sprintRate and hourlyRate to not use it when creating the dev doc
    delete newDev.hoursInWorkDay;
    delete newDev.hourlyRate;

    // Create the dev in the devs collection then retrieve and return the newly
    // created devs document id
    let devRef = this.db
      .collection("devs")
      .add(newDev)
      .then(async (docRef) => {
        //after a dev is created we will retrieve the dev id to be able to create the dev rateRange collection
        let devId = docRef.id;
        let startDate = new Date();
        let date = new Date();
        //
        //end Date is a year from the day the dev was added in the vendor resources
        let endDate = new Date(date.setFullYear(date.getFullYear() + 1));

        let data = {
          dailyHours: dailyHours,
          description: "",
          from: new Date(startDate.setHours(0, 0, 0, 0)),
          to: new Date(endDate.setHours(23, 59, 59, 59)),
          hourlyRates: {
            L1: hourlyRate,
          },
        };
        await this.db
          .collection("devs")
          .doc(devId)
          .collection("rateRanges")
          .add(data);
        return docRef.id;
      });
    return devRef;
  };

  // Create a new vendor in the "vendors" collection, from a JavaScript object
  // of the same strucure as the "vendors" collection.
  // Add the addedOn field
  // Returns a Promise
  createVendor = (newVendor) =>
    this.db.collection("vendors").add({ ...newVendor, addedOn: new Date() });

  // Update a stack document, with document id: id, from a dev in devs
  // collection with document id: devId. Updates is an object containing
  // the keys to be updated and their new values.
  updateStack = (devId, id, updates) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("stacks")
      .doc(id)
      .set(updates, { merge: true });

  /**
   * takes the stack category id, the stack to update id and uses the updates objects to update a
   *  given stack
   * @param {string} collectionId Stack category id
   * @param {string} stackId stack to update id
   * @param {object} updates updates containing the specific update
   */
  updateTechStack = (collectionId, stackId, updates) => {
    this.db
      .collection("techStacks150r")
      .doc(collectionId)
      .collection("technologies")
      .doc(stackId)
      .set(updates, { merge: true });
  };

  /**
   * takes the stack category id, the stack to update id and uses the updates objects to update a
   *  given stack
   * @param {string} collectionId Stack category id
   * @param {string} sectorId stack to update id
   * @param {object} updates updates containing the specific update
   */
  updateSectors = (collectionId, sectorId, updates) => {
    this.db
      .collection("sectors")
      .doc(collectionId)
      .collection("industry")
      .doc(sectorId)
      .set(updates, { merge: true });
  };

  /**
   * takes the stack category id, the stack to update id and uses the updates objects to update a
   *  given stack
   * @param {string} collectionId Stack category id
   * @param {string} catId stack to update id
   * @param {object} updates updates containing the specific update
   */
  updateCategory = (collectionId, catId, updates) => {
    this.db
      .collection("categories")
      .doc(collectionId)
      .collection("subcollections")
      .doc(catId)
      .set(updates, { merge: true });
  };
  updateEduc = (devId, id, updates) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("education")
      .doc(id)
      .set(updates, { merge: true });
  // Update a task document, with document id: id, from a dev in devs
  // collection with document id: devId. Updates is an object containing
  // the keys to be updated and their new values.
  updateTask = (devId, id, updates) => {
    this.db
      .collection("devs")
      .doc(devId)
      .collection("availability")
      .doc(id)
      .set(updates, { merge: true });
  };

  // Update a sectors document, with document id: id, from a dev in devs
  // collection with document id: devId. Updates is an object containing
  // the keys to be updated and their new values.
  updatePrimaryDomain = (devId, id, updates) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("sectors")
      .doc(id)
      .set(updates, { merge: true });

  // Update a categories document, with document id: id, from a
  // dev in devs collection with document id: devId. Updates is an object
  // containing the keys to be updated and their new values.
  updateProjExp = (devId, id, updates) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("categories")
      .doc(id)
      .set(updates, { merge: true });

  // Update a vacation document, with document id that matches id,
  // of a dev in the devs collection, with document id that matches devID,
  // updates is an object containing the keys to be updated and their new
  // values
  updateVac = (devId, id, updates) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("vacation")
      .doc(id)
      .set(updates, { merge: true });

  // Update a holiday document, with document id that matches id,
  // of a dev in the devs collection, with document id that matches devID,
  // updates is an object containing the keys to be updated and their new
  // values
  updateHoliday = (vendorId, id, updates) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("caseStudies")
      .doc(id)
      .set(updates, { merge: true });

  // Update a Case Study document, with document id that matches id,
  // of a dev in the devs collection, with document id that matches devID,
  // updates is an object containing the keys to be updated and their new
  // values
  updateCase = (vendorId, id, updates) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("caseStudies")
      .doc(id)
      .set(updates, { merge: true });

  // Update a rates document, with document id that matches id,
  // of a dev in the devs collection, with document id that matches devID,
  // updates is an object containing the keys to be updated and their new
  // values
  updateDevRate = async (devId, docId, updates, ratesForSecLevUpTo) => {
    // Initalize hourlyRates field
    updates.hourlyRates = {};
    // Syncfusion likes things flat but in the db, the hourly rates for each
    // security level is stored in a map under hourlyRate
    // Just in case, also compare the security levels for the rates to the
    // allowable security level. Ensure database does not recieve rates for
    // security levels the dev is not authorized for
    for (let secLev of ["L1", "L2", "L3", "L4", "L5"]) {
      if (secLev <= ratesForSecLevUpTo) {
        if (updates[secLev]) {
          updates.hourlyRates[secLev] = updates[secLev];
        } else {
          updates.hourlyRates[secLev] = 0;
        }
      }
      delete updates[secLev];
    }
    this.db
      .collection("devs")
      .doc(devId)
      .collection("rateRanges")
      .doc(docId)
      .set(updates, { merge: true });
  };

  // Update the "devs" collection document with a given id. The updates
  // are passed as a new object of the keys to be updated, and their new values.
  // Returns a Promise
  updateDev = (id, updates) => {
    // Convert status and geotag coordinates to a form that is consistent
    // with the database
    // Chcek for status and geotag values, if updates does not include
    // status or geoTag data which would be the case if you are adding to
    // the dev document and not updating any values
    if (updates.status) {
      if (typeof updates.status !== "boolean") {
        let status = "";
        if (updates.status === "Active") {
          status = true;
        } else {
          status = false;
        }
        updates.status = status;
      }
    }
    if (updates.latitude && updates.longitude) {
      // updates.geoTag = new app.firestore.GeoPoint(
      //   updates.latitude,
      //   updates.longitude
      // );
      delete updates.latitude;
      delete updates.longitude;
    }

    //sprintRate is displaying in the front End
    //So when you update something in the grid the sprintRate will be always saving as undefined
    //we Are deleting it to not get i saved in the database
    delete updates.sprintRate;

    // Updating lastUpdated for the dev
    updates.lastUpdated = this.fire.Timestamp.now();
    return this.db.collection("devs").doc(id).set(updates, { merge: true });
  };

  /**
   * Update data in the devPublic collection
   * @example updatDevPublic('y4cK0t50ScYEto4tgc0M', {featured: false});
   * @param {string} id resource id that will be updated
   * @param {object} updates object with the data to be updated
   */
  updateDevPublic = (id, updates) => {
    this.db
      .collection(`/devs/${id}/devPublic`)
      .doc("profile")
      .set(updates, { merge: true });
  };

  // placeholder to update a vendor in Firebase by the vendor's document id
  updateVendor = (id, updates) => {
    this.db.collection("vendors").doc(id).update(updates);
  };

  /**
   * Takes the vendor Id and the object of the updated data then updates the data to firebase
   * @example const updates = {'feature' : true, ...}
   * @example firebase.updateVendorPubData('1r4JxU5y6xsfiNij58FL', updates)
   * @param {string} id Vendor Id
   * @param {string} updates Updated data
   */
  updateVendorPubData = (id, updates) => {
    this.db
      .collection("vendors")
      .doc(id)
      .collection("partnerPublic")
      .doc("profile")
      .set(updates, { merge: true });
  };
  // Update a user, with a document ID matching id.
  // updates is an object with the keys to be updated and their new values
  updateUser = (id, updates) =>
    this.db.collection("users").doc(id).set(updates, { merge: true });

  //update a user spryte-partner account
  updateUserPartnerAccount = (userId, updates) =>
    this.db
      .collection("users")
      .doc(userId)
      .collection("accounts")
      .doc("spryte-partner")
      .set(updates, { merge: true });

  // Delete a dev from the devs collection with document ID, id
  deleteDev = async (id) => {
    // For firebase, documents are not completely deleted unless all
    // subcollections for that document are also deleted.
    // There exists a cloud function listener that will recursively delete
    // the subcollections when a dev is deleted
    this.db.collection("devs").doc(id).delete();
  };

  // Delete a stack from the stacks subcollection with document ID: id from
  // a dev in the devs collection with document ID: devID
  deleteStack = (devId, id) =>
    this.db.collection("devs").doc(devId).collection("stacks").doc(id).delete();

  /**
   * Takes the resource id and education id then deletes the given eduction from the id
   * @param {String} devId Resource id
   * @param {String} educId education id
   */
  deleteEducation = (devId, educId) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("education")
      .doc(educId)
      .delete();

  // Delete a domain experience from the sectors subcollection with
  // document ID: id from a dev in the devs collection with document ID: devID
  deletePrimaryDomain = (devId, id) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("sectors")
      .doc(id)
      .delete();

  // Delete a project experience from the projectExperience subcollection
  // with document ID: id, from a dev in the devs collection with document ID: devId
  deleteProjExp = (devId, id) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("categories")
      .doc(id)
      .delete();

  // Delete a project experience from the projectExperience subcollection
  // with document ID: id, from a dev in the devs collection with document ID: devId
  deleteProfile = (devId, id) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("profiles")
      .doc(id)
      .delete();

  // Delete a project experience from the projectExperience subcollection
  // with document ID: id, from a dev in the devs collection with document ID: devId
  deleteLogo = (vendId, id) =>
    this.db
      .collection("vendors")
      .doc(vendId)
      .collection("logos")
      .doc(id)
      .delete();

  // Delete a Task from the stacks subcollection with document ID: id from
  // a dev in the devs collection with document ID: devID
  deleteTask = (devId, id) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("availability")
      .doc(id)
      .delete();

  // Delete a vacation from the stacks subcollection with document ID: id from
  // a dev in the devs collection with document ID: devID
  deleteVac = (devId, id) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("vacation")
      .doc(id)
      .delete();

  // Delete a vacation from the stacks subcollection with document ID: id from
  // a dev in the devs collection with document ID: devID
  deleteHoliday = (vendorId, id) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("holidays")
      .doc(id)
      .delete();

  // Delete a rate from the rates subcollection with document ID: docId from
  // a dev in the devs collection with document ID: devID
  deleteDevRate = (devId, docId) =>
    this.db
      .collection("devs")
      .doc(devId)
      .collection("rateRanges")
      .doc(docId)
      .delete();

  deleteContract = (clientId, id) =>
    this.db
      .collection("vendors")
      .doc(clientId)
      .collection("contracts")
      .doc(id)
      .delete();

  deleteDocument = (vendorId, id) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("documents")
      .doc(id)
      .delete();

  deleteCaseStudy = (vendorId, id) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("caseStudies")
      .doc(id)
      .delete();

  deleteClientLogo = (vendorId, id) =>
    this.db
      .collection("vendors")
      .doc(vendorId)
      .collection("clients")
      .doc(id)
      .delete();

  // Return a list of the document ID of all the users in the users collection
  getUsersID = () =>
    this.db
      .collection("users")
      .get()
      .then((querySnapshot) => {
        const ref = [];
        let x;
        querySnapshot.forEach((users) =>
          ref.push({ ...users.data(), id: users.id })
        );
        for (x of ref) {
          return x["id"];
        }
      });

  // Return a list of the document ID of the vendors in the vendors collection
  getVendorsID = () =>
    this.db
      .collection("vendors")
      .get()
      .then((querySnapshot) => {
        const vendors = [];
        let x;
        querySnapshot.forEach((vend) =>
          vendors.push({ ...vend.data(), id: vend.id })
        );
        for (x of vendors) {
          return x["id"];
        }
      });

  // Returns a list of all document ID's of the devs in the devs collection
  getAllDevDocID = () =>
    this.db
      .collection("devs")
      .get()
      .then((querySnapshot) => {
        const ref = [];
        const all = [];
        let x;
        querySnapshot.forEach((dev) => ref.push({ ...dev.data(), id: dev.id }));
        for (x of ref) {
          all.push(x["id"]);
        }
        return all;
      });

  //
  // if getUsersAccountRef and getDevVendRef are equal to each other -> that Dev belongs to the associated Vendor
  getUsersAccountRef = () =>
    this.db
      .collection("users")
      .get()
      .then((querySnapshot) => {
        const ref = [];
        let x;
        querySnapshot.forEach((users) =>
          ref.push({ ...users.data(), id: users.id })
        );
        for (x of ref) {
          return x["accountRef"]["path"];
        }
      });

  // ${this.getDevVendRef()}`
  showProjectTimes = () =>
    this.db
      .collection("devs/JgRj1tnhKrnBMnEoe1VQ/availability")
      .get()
      .then((querySnapshot) => {
        const avail = [];
        querySnapshot.forEach((pro) =>
          avail.push({ ...pro.data(), id: pro.id })
        );
        return avail;
      });
}

// getLongLat = () => this.db.

// function getLocation(location) {
//   geoUrl = "https://maps.googleapis.com/maps/api/geocode/json?address="+`${location}`+"key=AIzaSyA7yIyjjyGpSiDs-5QlMvFm5eDtBL7Thkc"
//   coordinates = geoUrl.response[results][2]['location']
//   return coordinates
// }
// 42.0886089,-87.7708629

// addProject = () => this.db.collection('devs/' + 'JgRj1tnhKrnBMnEoe1VQ' + '/availability').add({
//   TaskID: id.value,
//   Dev: dev.value,
//   StartDate: date.value,
//   EndDate: date.value,
//   ProjectType: project.value
// })

// showDevProjectTimes = () => this.db.collection('devs').get().then(querySnapshot => {
//   const all = [];
//   let x;
//   const specTeam = [];
//   querySnapshot.forEach(users =>
//     all.push({...users.data(), id:users.id})
//   );
//   for (x of all) {
//     if (x['vendorRef']['path'] === this.getUsersAccountRef()) {
//       specTeam.push(x)
//     } else {
//       {}
//     }
//   }
//   })

// showDevTeamProjectTimes  = () => this.db.collection('dev' + )

// addProjectTime = () => {this.db.collection('devs' + 'JgRj1tnhKrnBMnEoe1VQ' + '/availability').update({
//   id: snap.val(),
//   startDate: snap.val(),
//   endDate: snap.val(),
//   task: snap.val(),
//   })
// }
//  delProjectTime = (proEnd, proStart) => {this.db.collection('devs').get(id).collection('availability')
//  .delete({
//    end: proEnd,
//    start: proStart
//  })
// }

export default Firebase;
