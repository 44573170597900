import React, { useState, useContext } from "react";
import { FirebaseContext } from "./Firebase";
import { useNavigate } from "react-router-dom";

function DevInputs({ setIsLoading }) {
  const [docId, setDocId] = useState("");
  const [passcode, setPasscode] = useState("");
  const firebase = useContext(FirebaseContext);
  const history = useNavigate();
  const [credErr, setCredErr] = useState("");

  // ====================Check login information and throw error==========================================
  const loginCheck = async (docId, passcode) => {
    if (docId === "") {
      setCredErr("Passcode should not be empty");
      return false;
    } else if (passcode === "") {
      setCredErr("Passcode should not be empty");
      return false;
    } else {
      let response = await firebase.authenticateDev(docId, passcode);
      if (response) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className="login-div">
      <p className="error">{credErr}</p>
      <h1>Use an Access Code</h1>
      <div className="input-div dev_log">
        <p>DEVELOPER ID</p>
        <input onChange={(e) => setDocId(e.target.value)}></input>
        <p>ACCESS CODE</p>
        <input
          type="password"
          onChange={(e) => setPasscode(e.target.value)}
        ></input>
        <div className="button-div">
          <a
            href="/#"
            onClick={async (e) => {
              e.preventDefault();
              setIsLoading(true);
              setTimeout(() => {
                firebase.auth
                  .signInAnonymously()
                  .then(async () => {
                    const auth = await loginCheck(docId, passcode);
                    if (auth) {
                      localStorage.setItem("dev", docId);
                      let updates = {};
                      updates["lastLogin"] = new Date();
                      await firebase.updateDev(docId, updates);

                      history("/");
                    } else {
                      firebase.doSignOut();
                      setCredErr("Developer ID or Passcode is incorrect");
                    }
                  })
                  .catch((err) => {
                    setCredErr(err.message);
                    setIsLoading(false);
                  });
              }, 1100);
            }}
          >
            LOGIN
          </a>
        </div>
      </div>
    </div>
  );
}

export default DevInputs;
