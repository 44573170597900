import React from "react";
import { CounterWrapper, DevCardWrapper, DevUID } from "./style";

export function SpDevCards({
  borderrad = 0,
  wrappercls = "",
  wrapwidth = "419px",
  children,
  devId,
  sx,
  count = null,
}) {
  return (
    <DevCardWrapper
      className={`sp_col ${wrappercls}`}
      borderrad={borderrad}
      wrapwidth={wrapwidth}
      style={sx}
    >
      {count !== null && (
        <CounterWrapper>
          <span>#{count}</span>
        </CounterWrapper>
      )}
      {children}
      {devId && <DevUID className={`${wrappercls}_devId`}>UID# {devId}</DevUID>}
    </DevCardWrapper>
  );
}
