import styles from "./styles/confirmationPopup.module.css";
import { MdWarning } from "react-icons/md";
import { logUserAction } from "../../globals/services";

function ConfirmationPopup({ info, enableLogging = true }) {
  return (
    <div className={styles.confirmationCtr}>
      <div className={styles.confirmationCtrContent}>
        <div className={styles.top}>
          <MdWarning className={styles.warning} />
          <h2>{info.action + " " + (info?.title ?? "Action")}</h2>
        </div>
        <div className={styles.bottom}>
          <h4>
            Are you sure you want to {info?.action ?? "action on"} this{" "}
            {info?.title ?? ""}?
          </h4>
          <div className={styles.btnCtr}>
            <button
              id={styles.yes}
              onClick={async () => {
                await info.confirmButton();
                if (enableLogging) {
                  logUserAction(
                    `${info.listPath}/log`,
                    "Button Clicked",
                    info.userType,
                    "CLICK"
                  );
                  logUserAction(
                    `${info.listPath}/log`,
                    `${info.title} had been ${info.action + "d"}.`,
                    info.userType,
                    "ACTION"
                  );
                }
              }}
            >
              {info?.action ?? "Confirm Action"} Now
            </button>
            <button
              id={styles.no}
              onClick={() => {
                info.cancelButton();
                if (enableLogging) {
                  logUserAction(
                    `${info.listPath}/log`,
                    "Button Clicked",
                    info.userType,
                    "CLICK"
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
