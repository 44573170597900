import React, { useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
// import SpOption from "../globalComponents/SpOptions/SpOption";
// import { SpOptionItem } from "../globalComponents/SpOptions/style";
import styles from "../styles/requirementsSteps.module.css";
// import { updateDcoumentWithPath } from "../../firebase/store";
import { MdArrowBack } from "react-icons/md";
import { updateDcoumentWithPath } from "../../../globals/services";
import SpOption from "../../../globals/SpOptions/SpOption";
import { SpOptionItem } from "../../../globals/SpOptions/style";
import PosSepcifications from "./PosSpecifications";
const PositionWrap = styled.div`
  // width: 420px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.08);
`;

const PositionHeadWrap = styled.div`
  background-color: ${({ color }) => (color ? color : "#E7E1FF")};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  gap: 5px;
  padding: 10px;
  flex-direction: column;
  ._spReqPosition--positionDesc {
    font-size: 14px;
    word-break: break-word;
  }
`;
const PositionHead = styled.div`
  display: flex;
  align-items: center;
`;

const PositionInfo = styled.div`
  flex: 3;
  ._spReqPosition--positionName {
    color: #b07417;
  }
  ._spReqPosition--positionType {
    font-weight: 600;
    font-size: 1.2rem;
  }
  // ._spReqPosition--positionDesc {
  //   font-size: 14px;
  //   // color: #b07417;
  // }
`;
const PositionAction = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  // min-width: 100px;

  & > div:first-child {
    display: flex;
    align-items: center;
    background-color: white;
    // width: 120px;
    padding: 1rem;
    justify-content: center;
    border-radius: 5px;
    height: 26px;
    p {
      font-weight: 600;
    }
    svg {
      width: 20px;
      height: 20px;
      flex: 3;
    }
  }
`;

const OptionRes = styled.div`
  display: grid;
  // gap: 1rem;

  // padding: 10px 24px;
  & > div:not(:last-child) {
    // padding-bottom: 2rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 80%;
      height: 2px;
      background-color: #e7e1ff;
      left: 10%;
    }
  }
`;

const initial = {
  showEditMaxRate: false,
  showEditDescription: false,
  showPositionOptions: false,
  showPosSpecs: false,
};

const SinglePosition = ({
  children,
  index = 0,
  position,
  minRate,
  onSelection,
  paidStatus,
  devPositions,
  searchResults,
  logAction,
  tucItem,
  titleData,
  userType,
  white,
  selectedUserRole,
  orgPosSpecs,
}) => {
  const [dialogOpen, setDialogOpen] = useState(initial);
  const [optionDescription, setOptionDescription] = useState(
    tucItem?.positions?.[index]?.["notes"] ?? ""
  );
  const [showOptions, setShowOptions] = useState(false);
  const [optionMaxRate, setOptionMaxRate] = useState(
    tucItem?.positions?.[index]?.["maxRate"] ?? ""
  );


  //stores the index of the selected pos spec option by user	  //stores the index of the selected pos spec option by user
  const [posSpecIndex, setPosSpecIndex] = useState(0);

  return (
    <div className={styles.posOptions}>
      {dialogOpen?.showPositionOptions ? (
        <div className={`${styles.posSubOptions} ${styles.full}`}>
          <div className={styles.subOptionTop}>
            <MdArrowBack
              style={{ fill: "#459df5", cursor: "pointer" }}
              size={20}
              onClick={() => {
                setDialogOpen(initial);
              }}
            />
            <h4>Available Positions</h4>
          </div>
          {devPositions?.map((devPosition, devIndex) => {
            return (
              <>
                <div
                  onClick={async () => {
                    var temp = [...tucItem?.positions];
                    temp[index]["title"] = devPosition?.ref;
                    setDialogOpen(initial);
                    // setShowPosOptions(false);
                    await updateDcoumentWithPath(tucItem?.path, {
                      positions: temp,
                    });
                    logAction(
                      `Changed ${tucItem?.name}'s POS ${index + 1 + 1} from ${
                        titleData?.label ?? "Position"
                      } to ${devPosition?.data()?.label ?? "Position"}`,
                      "ACTION"
                    );
                    logAction("Button Clicked", "CLICK");
                  }}
                  className={styles.option}
                  style={{ display: "flex", marginTop: "0" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>{devPosition?.data()?.label ?? "Position"}</p>
                  </div>
                </div>
                <hr></hr>
              </>
            );
          })}
        </div>
      ) : null}
      {dialogOpen?.showEditMaxRate ? (
        <div className={`${styles.posSubOptions} ${styles.full}`}>
          <div className={styles.subOptionTop}>
            <MdArrowBack
              style={{ fill: "#459df5", cursor: "pointer" }}
              size={20}
              onClick={() => {
                setOptionMaxRate(
                  tucItem?.positions?.[index]?.["maxRate"] ?? ""
                );
                setDialogOpen(initial);
              }}
            />
            <h4>BACK</h4>
          </div>
          <input
            type="text"
            placeholder="Enter maximum rate for this position"
            value={optionMaxRate}
            onChange={(event) => setOptionMaxRate(event.target.value)}
          />
          <button
            onClick={async () => {
              var temp = [...tucItem?.positions];
              temp[index]["maxRate"] = Number.parseInt(optionMaxRate);
              setDialogOpen(initial);
              await updateDcoumentWithPath(tucItem?.path, { positions: temp });
            }}
          >
            UPDATE
          </button>
        </div>
      ) : null}
      {dialogOpen?.showEditDescription ? (
        <div className={`${styles.posSubOptions} ${styles.full}`}>
          <div className={styles.subOptionTop}>
            <MdArrowBack
              style={{ fill: "#459df5", cursor: "pointer" }}
              size={20}
              onClick={() => {
                setOptionDescription(
                  tucItem?.positions?.[index]?.["notes"] ?? ""
                );
                setDialogOpen(initial);
              }}
            />
            <h4>BACK</h4>
          </div>
          <textarea
            type="text"
            placeholder="Edit your description here"
            value={optionDescription}
            onChange={(event) => setOptionDescription(event.target.value)}
          />
          <button
            onClick={async () => {
              var temp = [...tucItem?.positions];
              temp[index].notes = optionDescription;
              setDialogOpen(initial);
              await updateDcoumentWithPath(tucItem?.path, { positions: temp });
            }}
          >
            SAVE
          </button>
        </div>
      ) : null}
      {dialogOpen?.showPosSpecs ? (
        <div className={`${styles.posSubOptions} ${styles.full}`}>
          <div className={styles.subOptionTop}>
            <MdArrowBack
              style={{ fill: "#459df5", cursor: "pointer" }}
              size={20}
              onClick={() => {
                setDialogOpen(initial);
              }}
            />
            <h4>{orgPosSpecs[posSpecIndex]?.data()?.label}</h4>
          </div>
          {orgPosSpecs[posSpecIndex]
            ?.data()
            ?.["options"]?.filter((a) => a?.show)
            ?.sort((a, b) => a.rank - b.rank)
            ?.map((posSpec) => {
              return (
                <>
                  <div
                    onClick={async () => {
                      var temp = [...tucItem?.positions];
                      temp[index][orgPosSpecs[posSpecIndex]?.data()?.keyName] =
                        posSpec?.["label"];
                      setDialogOpen(initial);
                      await updateDcoumentWithPath(tucItem?.path, {
                        positions: temp,
                      });
                      logAction("Button Clicked", "CLICK");
                    }}
                    className={styles.option}
                    style={{ display: "flex", marginTop: "0" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>{posSpec?.["label"]}</p>
                    </div>
                  </div>
                  <hr></hr>
                </>
              );
            })}
        </div>
      ) : null}
      <PositionWrap className={`_spReqPosition`}>
        <PositionHeadWrap>
          <PositionHead className={`_spReqPosition--head`}>
            <PositionInfo
              className={`_spReqPosition--head__info sp_hover`}
              onClick={() => {
                onSelection();
              }}
            >
              <p className={`_spReqPosition--positionName`}>
                Position {`0${index + 1}`}
              </p>
              <p className={`_spReqPosition--positionType`}>{position}</p>
            </PositionInfo>
            <PositionAction className={`_spReqPosition--head--action`}>
              <div
                className={`_spReqPosition--head--action__price sp_hover`}
                onClick={() => {
                  onSelection();
                }}
              >
                <MdKeyboardArrowLeft size={20} />

                <p>${minRate}/Hr</p>
              </div>
              {paidStatus && selectedUserRole !== "partner" && (
                <div className={`_spReqPosition--head--action__more `}>
                  {/* <VerticalOption /> */}
                  <SpOption
                    left="100%"
                    open={showOptions}
                    onOptionClick={(isOpen) => setShowOptions(isOpen)}
                  >
                    <SpOptionItem
                      onClick={() => {
                        setShowOptions(false);
                        setDialogOpen({ ...initial, showEditMaxRate: true });
                      }}
                    >
                      Edit Max Rate
                    </SpOptionItem>
                    <SpOptionItem
                      onClick={() => {
                        setShowOptions(false);
                        setDialogOpen({
                          ...initial,
                          showEditDescription: true,
                        });
                      }}
                    >
                      Edit Description
                    </SpOptionItem>
                    {userType === "ADMIN" && (
                      <SpOptionItem
                        onClick={() => {
                          setShowOptions(false);
                          setDialogOpen({
                            ...initial,
                            showPositionOptions: true,
                          });
                        }}
                      >
                        Change Position
                      </SpOptionItem>
                    )}
                    {orgPosSpecs?.map((orgPosSpecsDocSnap, specIndex) => (
                      <SpOptionItem
                        onClick={() => {
                          setShowOptions(false);
                          setDialogOpen({
                            ...initial,
                            showPosSpecs: true,
                          });
                          setPosSpecIndex(specIndex);
                        }}
                      >
                        Edit {orgPosSpecsDocSnap?.data()?.label}
                      </SpOptionItem>
                    ))}
                  </SpOption>
                </div>
              )}
            </PositionAction>
          </PositionHead>
          {optionDescription && (
            <p className={`_spReqPosition--positionDesc`}>
              {optionDescription}
            </p>
          )}
        </PositionHeadWrap>
        {(tucItem?.positions?.[index]?.contractType ||
          tucItem?.positions?.[index]?.commuteType ||
          tucItem?.positions?.[index]?.dedicationType) && (
          <PosSepcifications tucPosMap={tucItem?.positions?.[index]} />
        )}
        <OptionRes className={`_spReqPosition--positions`}>
          {children}
        </OptionRes>
      </PositionWrap>
    </div>
  );
};

export default SinglePosition;
