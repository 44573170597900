import React, { useContext, useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Sort,
  Edit,
  ExcelExport,
  PdfExport,
  Toolbar,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  Page,
  Search,
} from "@syncfusion/ej2-react-grids";
import { useNavigate } from "react-router-dom";
import { GlobalVendorContex } from "./Firebase";
import PartnerInfo from "./pages/PartnerManagement/PartnerInfo";
import FirebaseContext from "./Firebase/context";
import { AuthUserContext } from "./Firebase/session";
import AddPartner from "./pages/AddPartner/AddPartner";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

const PartnersManagement = () => {
  // const authData = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  const history = useNavigate();
  const vendorsData = useContext(GlobalVendorContex);
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [grid, setGrid] = useState(null);
  const [allVendors, setAllVendors] = useState([]);
  const [showMigrateDialog, setShowMigrateDialog] = useState(false);
  const [fetchActiveVendors, setFetchActiveVendors] = useState(true);

  const toolBarOptions = [
    "Search",
    {
      text: "View Vendor",
      tooltipText: "View Vendor",
      prefixIcon: "e-view",
      id: "view_vendor",
    },
  ];
  const onRowSelect = (args) => {
    if (args?.data) {
      setIsVendorSelected(true);
      setSelectedVendor(args.data);
    }
  };
  const onRowDeselect = (args) => {
    setIsVendorSelected(false);
    setSelectedVendor(null);
  };

  const toolbarClicked = (args) => {
    switch (args.item.text) {
      case "PDF Export":
        grid.pdfExport();
        break;
      case "Excel Export":
        grid.excelExport();
        break;
      case "View Vendor":
        vendorsData.setSearchedVendId(selectedVendor.id);
        vendorsData.setIsResetted(false);
        history("/");
        break;
      default:
    }
  };

  const footerSum = (props) => {
    return <span>Total: {props.Sum}</span>;
  };
  const footerAvg = (props) => {
    return <span>Average: {props.Average}</span>;
  };
  const custom = () => {
    return <span>{allVendors.length} Vendors </span>;
  };

  useEffect(() => {
    let subVendor;
    const getVendors = async () => {
      if (authUser.isSuperPartner && authUser.vendor) {
        let vendorsQuery = firebase.db.collection("vendors");
        if (fetchActiveVendors)
          vendorsQuery = vendorsQuery.where(
            "activePartner",
            "==",
            fetchActiveVendors
          );
        subVendor = vendorsQuery
          .orderBy("addedOn", "desc")
          .onSnapshot(async (querySnapshot) => {
            const vendors = [];
            for (const vend of querySnapshot.docs) {
              let vendorData = vend.data();
              const {
                companyName,
                lastLogin,
                vendorProfileProgress,
                settings,
                marketCount,
                caseStudyCount,
                priceCardCount,
                ...data
              } = vendorData;
              // let managerDoc = await vendorData?.manager?.get();
              // let manager = managerDoc?.data();
              // let managerName = manager
              //   ? manager?.first + " " + manager?.last
              //   : null;
              let caseStudies = caseStudyCount
                ? caseStudyCount["DRAFT"] +
                  caseStudyCount["FEATURED"] +
                  caseStudyCount["READY-TO-SUBMIT"] +
                  caseStudyCount["REVIEWED"]
                : 0;

              let marketCountNo =
                Object.values(marketCount ?? {}).reduce((a, b) => a + b, 0) ??
                0;
              let priceCardCountNo =
                Object.values(priceCardCount ?? {}).reduce(
                  (a, b) => a + b,
                  0
                ) ?? 0;
              let vendorProgress = 0.0;
              if (vendorProfileProgress) {
                vendorProgress =
                  (vendorProfileProgress.devsProgress || 0) * 0.75 +
                  (vendorProfileProgress.financialProgress || 0) * 0.25;
              }
              let theTrustScore = 0.0;
              if (settings?.generalService) {
                theTrustScore =
                  settings?.generalService.reduce(function (accumulator, item) {
                    return accumulator + item.trustScore;
                  }, 0) / settings?.generalService.length;
              }
              let logout = lastLogin?.toDate() ?? null;
              if (logout) {
                let today = new Date();
                let difTime = today.getTime() - logout.getTime();
                let days = (difTime / (1000 * 3600 * 24)).toFixed(0);
                if (days >= 0) {
                  logout = parseInt(days);
                } else {
                  logout = 999;
                }
              }
              // TO BE
              // const profileRating = await vend?.ref
              //   ?.collection("rating")
              //   ?.orderBy("lastUpdated", "desc")
              //   ?.limit(1)
              //   ?.get();
              // ------
              // let profileRatingSum = 0;
              // profileRating?.docs?.map((ratingDoc) => {
              //   profileRatingSum += ratingDoc?.data()?.rating?.average ?? 0;
              // });
              // let profileRatingAvg = (
              //   (profileRatingSum ?? 0) / (profileRating?.docs?.length ?? 1)
              // )?.toFixed(2);
              vendors.push({
                name: companyName ?? "no name",
                id: vend.id,
                vendorRef: vend?.ref,
                ...data,
                lastLogin:
                  lastLogin?.toDate().toDateString() ?? "No Login Time",
                totalDevs: data.totalDevs ?? 0,
                addedOn: data.addedOn?.toDate() ?? new Date(),
                totalDevsLive: data.totalDevsLive ?? 0,
                bookableCount: data.bookableCount ?? 0,
                caseStudies: caseStudies,
                marketCountNo: marketCountNo ?? 0,
                marketCount: marketCount,
                priceCardCountNo: priceCardCountNo ?? 0,
                priceCardCount: priceCardCount,
                lastLogOut: logout ?? 999,
                caseStudyCount: caseStudyCount,
                profileCompletion: vendorProgress,
                trustScore: theTrustScore.toFixed(2),
                ratingFetched: data?.rating ?? 0,
                // TO BE
                // ratingFetched:
                //   profileRating?.docs[0]?.data()?.rating?.average ?? 0,
                // ----
                // isNaN(profileRatingAvg)
                //   ? "N/A"
                //   : profileRatingAvg,
                // managerName: managerName,
              });
            }
            setAllVendors(vendors);
          });
      } else {
        setAllVendors([]);
      }
    };
    getVendors();

    return () => {
      try {
        subVendor();
      } catch (error) {}
    };
  }, [authUser, firebase.db, fetchActiveVendors]);

  return (
    <div className="partnManagement" style={{ paddingBottom: "50px" }}>
      <div className="resource-header" style={{ marginBottom: "20px", display:"flex" }}>
        <div className="header-intro">
          <h3>Spryte Partners</h3>
          <p className="lighter">
            Registered Spryte partner account information
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginLeft: "auto",
            justifyContent: "end",
            alignItems:"center"
          }}
        >
          <p>All Vendors</p>
          <SwitchComponent
            id="isActivePartnerAllPartneresPage"
            checked={fetchActiveVendors}
            change={(e) => setFetchActiveVendors(e.checked)}
          ></SwitchComponent>
          <p>Active Vendors</p>
        </div>
        {/* <button
          className="e-control e-btn "
          id="targetButton1"
          style={{
            background: "#1E96F5",
            color: "white",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "end",
          }}
          onClick={() => {
            setShowMigrateDialog(true);
          }}
        >
          Add New Partner +
        </button> */}
        <AddPartner
          openState={showMigrateDialog}
          setOpenState={setShowMigrateDialog}
        />
      </div>

      <PartnerInfo selectedVendor={selectedVendor} />
      <div className="partnManagement_body-grid control-pane">
        <GridComponent
          ref={(grid) => setGrid(grid)}
          dataSource={allVendors}
          allowSelection={true}
          allowSorting={true}
          height="auto"
          toolbar={
            isVendorSelected
              ? toolBarOptions
              : ["Search", "ExcelExport", "PdfExport"]
          }
          rowSelected={onRowSelect}
          rowDeselected={onRowDeselect}
          allowPdfExport={true}
          allowExcelExport={true}
          toolbarClick={toolbarClicked}
          allowPaging={true}
          pageSettings={{
            pageSize: 20,
            pageCount: 10,
            enableQueryString: true,
            pageSizes: true,
          }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="name"
              headerText="Company"
              textAlign="Center"
              width="120"
            />
            {/* <ColumnDirective
              field="managerName"
              headerText="Manager"
              textAlign="Center"
              width="150"
            /> */}
            <ColumnDirective
              field="totalDevs"
              headerText="Roster size"
              // editType="numericedit"
              format="n0"
              width="100"
              defaultValue={0}
              textAlign="Center"
            />
            <ColumnDirective
              field="totalDevsLive"
              defaultValue={0}
              headerText="Resources"
              // editType="numericedit"
              format="n0"
              width="95"
              textAlign="Center"
            />
            <ColumnDirective
              field="bookableCount"
              // defaultValue={0}
              headerText="Bookable"
              // editType="numericedit"
              format="n0"
              width="100"
              textAlign="Center"
            />
            <ColumnDirective
              field="ratingFetched"
              headerText="Rating"
              // defaultValue={0}
              // editType="numericedit"
              format="n0"
              width="120"
              textAlign="Center"
            />
            <ColumnDirective
              field="trustScore"
              headerText="Trust Score"
              defaultValue={0}
              // editType="numericedit"
              format="n0"
              width="120"
              textAlign="Center"
            />
            <ColumnDirective
              field="profileCompletion"
              headerText="Profile Completion"
              defaultValue={0}
              editType="numericedit"
              format="p2"
              required={false}
              width="160"
              textAlign="Center"
              // template={devProgessTemplate}
            />
            <ColumnDirective
              field="priceCardCountNo"
              headerText="Rate Cards"
              // editType="numericedit"
              format="n0"
              width="100"
              defaultValue={0}
              textAlign="Center"
            />
            <ColumnDirective
              field="marketCountNo"
              headerText="Markets"
              // editType="numericedit"
              format="n0"
              defaultValue={0}
              width="80"
              textAlign="Center"
            />
            <ColumnDirective
              field="caseStudies"
              defaultValue={0}
              headerText="Case Studies"
              // editType="numericedit"
              format="n0"
              width="100"
              textAlign="Center"
            />
            <ColumnDirective
              field="clientLogoCount"
              defaultValue={0}
              headerText="Client Logos"
              // editType="numericedit"
              format="n0"
              width="100"
              textAlign="Center"
            />

            <ColumnDirective
              field="lastLogOut"
              defaultValue={0}
              headerText="Last Login"
              // editType="numericedit"
              width="100"
              format="n0"
              textAlign="Center"
              // format="yMd"
            />
            <ColumnDirective
              field="addedOn"
              headerText="Added On"
              editType="datepickeredit"
              width="100"
              textAlign="Center"
              format="yMd"
            />
          </ColumnsDirective>

          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="name"
                  type="Custom"
                  // customAggregate={customAggregateFn}
                  footerTemplate={custom}
                ></AggregateColumnDirective>
                <AggregateColumnDirective
                  field="totalDevs"
                  type="Sum"
                  format="N0"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="totalDevsLive"
                  type="Sum"
                  format="N0"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="bookableCount"
                  type="Sum"
                  format="N0"
                  // textAlign="Left"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="trustScore"
                  type="Average"
                  format="N2"
                  // textAlign="Left"
                  footerTemplate={footerAvg}
                >
                  {" "}
                </AggregateColumnDirective>

                <AggregateColumnDirective
                  field="profileCompletion"
                  type="Average"
                  format="P2"
                  textAlign="Left"
                  footerTemplate={footerAvg}
                >
                  {" "}
                </AggregateColumnDirective>

                <AggregateColumnDirective
                  field="priceCardCountNo"
                  type="Sum"
                  format="N0"
                  textAlign="Left"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="marketCountNo"
                  type="Sum"
                  format="N0"
                  textAlign="Left"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="caseStudies"
                  type="Sum"
                  format="N0"
                  textAlign="Left"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
                <AggregateColumnDirective
                  field="clientLogoCount"
                  type="Sum"
                  format="N0"
                  textAlign="Left"
                  footerTemplate={footerSum}
                >
                  {" "}
                </AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject
            services={[
              Selection,
              Aggregate,
              ExcelExport,
              PdfExport,
              Toolbar,
              Edit,
              Sort,
              Page,
              Search,
            ]}
          />
        </GridComponent>
      </div>
    </div>
  );
};
export default PartnersManagement;
