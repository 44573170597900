import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import Communication from "./settings/Communication";
import Operations from "./settings/Operations";
import Security from "./settings/Security";
import Marketing from "./settings/Marketing";
import Profile from "./settings/Profile";
import GenericService from "./settings/GenericService";
import { GlobalVendorContex } from "../../Firebase/GlobalVendorContext";
import AssociatedCompany from "./settings/associatedCompany";

const Settings = (props) => {
  const [securityOverheads, setSecurityOverheads] = useState([]);
  const [isLeadOn, setIsLeadOn] = useState(false);
  const vendorData = useContext(GlobalVendorContex);

  const yesNoDropDown = ["YES", "NO"];
  const dropDownModel = {
    dataSource: yesNoDropDown,
  };

  const updateVendorGeneric = (data) => {
    let updates = {};
    updates.settings = { ...props.settings };
    updates.settings["generalService"] = data;
    updates.settings["gsLastUpdated"] = new Date();
    let trustScore = 0;
    props.setVendorGenerics(data);
    data.forEach((generic) => {
      trustScore += generic.trustScore;
    });
    props.setVendorTrust((trustScore / data.length).toFixed(2));
    props.firebase.updateVendor(props.vendor.id, updates);
  };

  useEffect(() => {
    // Sort the overheads from L1 - L5
    let securityOverheads = Object.entries(
      props.settings.securitySettings.securityOverHeads
    );
    securityOverheads = securityOverheads.sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    });

    setSecurityOverheads(securityOverheads);
    setIsLeadOn(props.settings.ops?.leadTimeOn ?? true);
  }, [
    props.settings?.ops?.leadTimeOn,
    props.settings?.securitySettings?.securityOverHeads,
  ]);

  const updateVendorData = (subCollection, data, overhead = false) => {
    let updates = {};
    updates.settings = { ...props.settings };
    if (overhead) {
      updates.settings[subCollection]["securityOverHeads"][data.name] =
        parseFloat(data.value);
    } else {
      if (data.value === "YES") {
        updates.settings[subCollection][data.name] = true;
      } else if (data.value === "NO") {
        updates.settings[subCollection][data.name] = false;
      } else if (typeof data.value === "object") {
        updates.settings[subCollection][data.name] = data.value;
      } else {
        updates.settings[subCollection][data.name] = parseFloat(data.value);
      }
    }
    try {
      props.firebase.updateVendor(props.vendor.id, updates);
      props.updateVendor(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onToggle = (e, fieldName) => {
    let updates = {};
    updates.settings = { ...props.settings };

    if (fieldName === "crossVendor") {
      updates.settings["ops"]["crossVendor"] = e.checked;
    }
    if (fieldName === "leadTimeOn") {
      updates.settings["ops"]["leadTimeOn"] = e.checked;
      setIsLeadOn(!isLeadOn);
    }
    if (fieldName === "discovery") {
      updates.settings["ops"]["discovery"] = e.checked;
      // setIsLeadOn(!isLeadOn)
    }

    try {
      props.firebase.updateVendor(props.vendor.id, updates);
      props.updateVendor(true);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * get the updated data form the user input and updates it in the database
   * @param {object} data => map of the data containing the name of the field
   *
   */
  const upVendor = (data) => {
    let updates = {};
    updates["totalDevs"] = parseInt(data.value);
    props.firebase.updateVendor(props.vendor.id, updates);
  };

  return (
    <div className="stat">
      <div className="stat_header settings">
        <div className="title">
          <h1>Settings</h1>
          <FontAwesomeIcon className="icon" icon={faCog} />
        </div>
        <div className="current-user">
          <p>{vendorData?.vendor?.companyName ?? ""}</p>
          <FontAwesomeIcon icon={faUser} className="userIcon" />
        </div>
      </div>
      <div className="stat_content">
        <Communication
          updateVendorData={updateVendorData}
          settings={props.settings}
          dropDownModel={dropDownModel}
          percentModel={props.percentModel}
        />
        <Operations
          updateVendorData={updateVendorData}
          settings={props.settings}
          dropDownModel={dropDownModel}
          percentModel={props.percentModel}
          numericModel={props.numericModel}
          onToggle={onToggle}
          // onLeadToggle={onLeadToggle}
          updateVendor={upVendor}
          isLeadOn={isLeadOn}
          totalDevs={props?.vendor.totalDevs}
          totalDevsLive={props?.totalDevsCount ?? 0}
          bookableDevs={props?.bookableCount ?? 0}
        />
        <Security
          securityOverHeads={securityOverheads}
          updateVendorData={updateVendorData}
          settings={props.settings}
          dropDownModel={dropDownModel}
          percentModel={props.percentModel}
        />

        <Marketing vendor={props?.vendor} firebase={props.firebase} />
        <GenericService
          orgPositions={props.orgPositions}
          generics={props.vendorGenerics}
          updateVendorGeneric={updateVendorGeneric}
          isSuper={props.isSuper}
        />
        <Profile
          vendorPublic={props.vendorPublic}
          vendorData={props.vendor}
          firebase={props.firebase}
          updateVendor={props.updateVendorData}
        />
        <AssociatedCompany
          vendorPublic={props.vendorPublic}
          vendorData={props.vendor}
          isSuper={props.isSuper}
        />
      </div>
    </div>
  );
};

export default Settings;
