import React from "react";

const NoteIcon = ({ size = 11, fill = "#1B16FF" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7139_15349)">
        <path
          d="M10.5976 0H0.402445C0.180189 0 0 0.180189 0 0.402445V10.5976C0 10.8198 0.180189 11 0.402445 11H7.01044V11C7.11264 11 7.21849 10.9591 7.29534 10.8821L10.8821 7.29532C10.9554 7.22206 11 7.11898 11 7.01076V0.402445C11 0.180189 10.8198 0 10.5976 0ZM7.41323 9.62599V7.41321H9.62599C8.98567 8.05353 8.05353 8.98567 7.41323 9.62599ZM10.1951 6.60831H7.01078C6.78853 6.60831 6.60834 6.7885 6.60834 7.01076V10.1951H0.804869V0.804869H10.1951L10.1951 6.60831Z"
          fill={fill}
        />
        <path
          d="M2.53526 3.3752H8.46385C8.68611 3.3752 8.86629 3.19501 8.86629 2.97276C8.86629 2.7505 8.68611 2.57031 8.46385 2.57031H2.53526C2.313 2.57031 2.13281 2.7505 2.13281 2.97276C2.13281 3.19501 2.313 3.3752 2.53526 3.3752Z"
          fill={fill}
        />
        <path
          d="M2.53526 5.51192H8.46385C8.68611 5.51192 8.86629 5.33173 8.86629 5.10948C8.86629 4.88722 8.68611 4.70703 8.46385 4.70703H2.53526C2.313 4.70703 2.13281 4.88722 2.13281 5.10948C2.13281 5.33175 2.313 5.51192 2.53526 5.51192Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7139_15349">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoteIcon;
