import React, { useEffect, useState } from "react";
import { useScreenSize } from "../../hooks/useScreenSize";
import {
  Actions,
  ContentExcerpt,
  ExcerptWrapper,
  FullContent,
  ShowMore,
  SideBtn,
} from "./Style";

function SpExcerptText({
  content,
  lines,
  wrappercls,
  textcls,
  btncls,
  wrapperWidth,
  wsx,
  tsx,
  btnsx,
  tsize = "16px",
  tcolor,
  fw = "400",
  maxContentheight = 60,
  onShowMore,
  showShowMore = true,
  id,
  showSideBtn,
  onSideBtnClick,
  sideBtnText,
}) {
  const [isFullWidth, setIsFullwidth] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const { winWidth } = useScreenSize();
  const countLines = () => {
    var el = document.getElementById(`${id ?? "_spExcerptContent"}`);
    var pHeight = el.scrollHeight;

    if (pHeight >= maxContentheight) {
      setShowBtn(true);
    }
  };

  const onBtnClick = () => {
    setIsFullwidth((prev) => !prev);
    onShowMore && onShowMore();
  };

  useEffect(() => {
    countLines();
  }, [winWidth, maxContentheight, id]);
  return (
    <ExcerptWrapper
      className={`_spExcerptContent ${wrappercls}`}
      wrapperWidth={wrapperWidth}
      style={wsx}
    >
      {isFullWidth ? (
        <FullContent
          className={`_spExcerptContent-content ${textcls}`}
          tsx={tsx}
          tsize={tsize}
          tcolor={tcolor}
          fw={fw}
          id={`${id ?? "_spExcerptContent"}`}
        >
          {content}
        </FullContent>
      ) : (
        <ContentExcerpt
          className={`_spExcerptContent-content ${textcls}`}
          lineClamp={lines}
          tsx={tsx}
          tsize={tsize}
          tcolor={tcolor}
          fw={fw}
          id={`${id ?? "_spExcerptContent"}`}
        >
          {content}
        </ContentExcerpt>
      )}
      {showBtn && showShowMore && !showSideBtn && (
        <ShowMore className={btncls} style={btnsx} onClick={onBtnClick}>
          {isFullWidth ? "Show Less" : "Show More"}
        </ShowMore>
      )}

      {showSideBtn && (
        <Actions className={`_spExcerptContent-actions`}>
          {showBtn && showSideBtn && (
            <ShowMore className={btncls} style={btnsx} onClick={onBtnClick}>
              {isFullWidth ? "Show Less" : "Show More"}
            </ShowMore>
          )}

          <SideBtn
            className={"_spExcerptContent-sideBtn"}
            onClick={onSideBtnClick}
          >
            {sideBtnText ?? "View Details"}
          </SideBtn>
        </Actions>
      )}
    </ExcerptWrapper>
  );
}

export default SpExcerptText;
