import SpryteTooltip from "../../SpryteTooltip.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const YourPrices = (props) => {
  const submit = (priceRef) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: `This action will remove your pricing from all search result combinations`,
      buttons: [
        {
          label: "Yes",
          onClick: () => props.onDelete(priceRef),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const renderCombinations = (comb) => {
    let el = [];
    for (let i = 0; i < comb.length; i++) {
      if (i < comb.length - 1) {
        el.push(`${comb[i]}, `);
      } else {
        el.push(`${comb[i]} `);
      }
    }
    return <span style={{ fontWeight: 600 }}>{el}</span>;
  };

  return (
    <div className="markets_content-yourPrice ">
      <div className="markets_content-yourPrice__head row">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Your Markets</h3>
          <SpryteTooltip desc="Markets define the searches on which your pricing will be displayed to Spryte clients." />
          {/* <p className="small">View & edit your pricing definitions</p> */}
        </div>
        <div>
          <button
            className="spbtn spbtn-blackbkg"
            onClick={() => {
              props.setIsSearchClick(true);
            }}
          >
            Search & Add a Market
          </button>
        </div>
      </div>
      <div className="markets_content-yourPrice__prices prices_table">
        <div className="row prices_table-header">
          <div className="keywords keywords_header">
            <h4>Keywords</h4>
          </div>
          <div className="combinations combinations_header">
            <h4>Combinations</h4>
          </div>
          <div className="variants variants_header">
            <h4>Variants</h4>
          </div>
          <div className="status status_header">
            <h4>Status</h4>
          </div>
          <div className="rates rates_header">
            <h4>Rates</h4>
          </div>
          <div className="rates rates_header">
            <h4>ACTIONS</h4>
          </div>
          <div className="edit edit_header"></div>
        </div>
        {props.pricesList.map((price, i) => {
          return (
            <div key={i} className="row">
              <div className="keywords keywords_el">
                {renderCombinations(price.combination)}
              </div>
              <div className="combinations combinations_el">
                <p>{price.combCalc ?? price.combination.length}</p>
              </div>
              <div className="variants variants_el">
                <p>{price?.variantsCount ?? 0}</p>
              </div>
              <div
                className="status status_el"
                style={{
                  backgroundColor: !price.status ? "#e70e021c" : "#20975b2c",
                }}
              >
                <p style={{ color: !price.status ? "#E70E02" : "#20975C" }}>
                  {price.status ? "Active" : "Inactive"}
                </p>
              </div>
              <div className="rates rates_el">
                <p>
                  {price.stats
                    ? `$${price?.stats?.minPrice} - $${price?.stats?.maxPrice}/hr`
                    : "$0 - $0/hr"}
                </p>
              </div>
              <div className="edit edit_el">
                <button
                  onClick={() => {
                    props.onEdit(
                      price.ID,
                      price.combination,
                      price.queryRef,
                      price.queryData
                    );
                  }}
                  style={{
                    marginRight: "5px",
                    borderRadius: "3.5px",
                    color: "grey",
                    backgroundColor: "rgba(128, 128, 128, 0.15)",
                    border: "none",
                    padding: ".5px   7.5px",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Edit{" "}
                </button>
                <button
                  onClick={() => {
                    submit(price.ref);
                  }}
                  style={{
                    marginLeft: "5px",
                    borderRadius: "3.5px",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.7)",
                    border: "none",
                    padding: ".5px   7.5px",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Delete{" "}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default YourPrices;
