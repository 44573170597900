import { Star } from "@mui/icons-material";
import React from "react";

export default function ratingTemplate(props) {
  const renderStars = () => {
    let ele = [];
    for (let i = 0; i < 4; i++) {
      if (i < props.rating) {
        // push stars with yellow color
        ele.push(
          <Star
            className="warning"
            sx={{
              color: "#ffa600",
              fontSize: "1.7rem",
            }}
          />
        );
      } else {
        ele.push(
          <Star
            className="unselected"
            sx={{
              color: "lightgrey",
              fontSize: "1.7rem",
            }}
          />
        );
      }
    }
    return ele;
  };
  return <div className="rating">{renderStars()}</div>;
}
