import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import { MenuItem, Select, Switch } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import SpTruncatedText from "../globals/SpTruncatedText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SingleTechRow from "./SingleTechRow";

const fetchAssessmentsForTech = async (assessments) => {
  const assessmentPromises = assessments.map(async (assessment) => {
    if (assessment.ref) {
      try {
        const doc = await assessment.ref.get();
        if (doc.exists) {
          return {
            rank: assessment.rank,
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          };
        } else {
          console.error("Assessment data not found");
          return null;
        }
      } catch (error) {
        console.error("Error fetching assessment data", error);
        return null;
      }
    } else {
      console.error("No ref provided for assessment");
      return null;
    }
  });

  // Filter out any null results before returning
  return (await Promise.all(assessmentPromises)).filter(
    (assessment) => assessment !== null
  );
};

function TechExperienceGrid({
  stacks,
  onCreateStackAssessment,
  devId,
  devData,
  stackAssessmentData,
  clickedStacksAssessment,
  stackAssessmentsLoading,
  setTechStackData,
  setIsOutSideBtnClicked,
  setChatBtnClickCount
}) {
  // console.log("stacks: ", stacks);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [filteredStacks, setFilteredStacks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(5);
  const [numberOfStacks, setNumberOfStacks] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [isAssessmentVisible, setIsAssessmentVisible] = useState({});
  const [assessments, setAssessments] = useState({});

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const loadAssessments = async (item) => {
    const techId = item.id; // Assuming techData includes a unique ID for the tech

    // Check if we already have the assessments to avoid refetching
    if (!assessments[techId]) {
      // console.log("Fetching assessments for tech", techId);
      const fetchedAssessments = await fetchAssessmentsForTech(
        item?.techData?.assessments
      );
      setAssessments((prevAssessments) => ({
        ...prevAssessments,
        [techId]: fetchedAssessments,
      }));
    }
  };

  useEffect(() => {
    for (const stack of stacks) {
      // console.log("stack: ", stack);
      if (stack.techData?.assessments) {
        loadAssessments(stack);
      }
    }
  }, [stacks]);

  const toggleAssessmentVisibility = (item) => {
    // Load assessments when a user attempts to view them

    setIsAssessmentVisible((prevState) => ({
      ...prevState,
      [item.id]: !prevState[item.id],
    }));

    if (selectedItemId === item.id) {
      setSelectedItemId(null);
    } else {
      setSelectedItemId(item.id);
    }
  };

  // Handle items per page change
  const onPageCountChange = (event) => {
    setPageLimit(event.target.value);
    setCurrentPage(1); // Reset to first page
  };

  const pageSizes = [5, 10, 20, 50, 100, 150, 200, 250, 300, 350, 400];

  useEffect(() => {
    const theStacks = stacks.sort((a, b) => (a?.addedOn > b?.addedOn ? 1 : -1));
    setNumberOfStacks(theStacks.length);
    const firstPageIndex = (currentPage - 1) * pageLimit;
    const lastPageIndex = firstPageIndex + pageLimit;
    setCurrentTableData(theStacks.slice(firstPageIndex, lastPageIndex));
  }, [searchValue, filteredStacks, currentPage, pageLimit, stacks]);

  const handleIconClick = (itemId) => {
    setSelectedItemId(itemId);
    setShowOverlay(true);
  };

  const handleDelete = async (item) => {
    await item?.ref?.delete();
  };

  const handleSliderChange = (item, update) => {
    const items = [...currentTableData];

    const index = items.findIndex((x) => x.ref.id === item.ref.id);
    items[index] = { ...items[index], ...update };
    setCurrentTableData(items);
  };

  const updateSliderValue = async (item, update) => {
    await item.ref.set(update, { merge: true });
  };

  const handleCancel = () => {
    setSelectedItemId(null);
    setShowOverlay(false);
  };

  // const filteredAssessments = useMemo(() => {
  //   const newAssessment = { ...assessments };

  //   let filteredAssessments;
  //   for (const stack of stacks) {
  //     filteredAssessments = assessments[stack?.id]?.filter(
  //       (assessment) =>
  //         !stack?.assessments?.some(
  //           (selectedAssessment) =>
  //             selectedAssessment?.source?.id === assessment?.ref?.id
  //         )
  //     );

  //     newAssessment[stack?.id] = filteredAssessments;
  //   }

  //   return newAssessment;
  //   // remove assessments that exist in the array of assessments for the selected item, it is array of objects with source which is ref for assessment
  // }, [assessments, stacks]);

  return (
    <div className="_spTechstackGrid">
      {currentTableData?.map((item) => {
        const itemData =
          item?.techData || item?.projectData || item?.domainData;

        return (
          <SingleTechRow
            item={item}
            itemData={itemData}
            handleSliderChange={handleSliderChange}
            updateSliderValue={updateSliderValue}
            handleIconClick={handleIconClick}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
            showOverlay={showOverlay}
            toggleAssessmentVisibility={toggleAssessmentVisibility}
            isAssessmentVisible={isAssessmentVisible}
            selectedItemId={selectedItemId}
            filteredAssessments={assessments}
            onCreateStackAssessment={onCreateStackAssessment}
            devId={devId}
            devData={devData}
            stackAssessmentData={stackAssessmentData}
            clickedStacksAssessment={clickedStacksAssessment}
            stackAssessmentsLoading={stackAssessmentsLoading}
            setTechStackData={setTechStackData}
            setIsOutSideBtnClicked={setIsOutSideBtnClicked}
            setChatBtnClickCount={setChatBtnClickCount}
          />
        );
      })}
      <div className="stacks_pagination">
        <Select
          // size="small"
          value={pageLimit}
          onChange={onPageCountChange}
          sx={{
            backgroundColor: "white",
            borderRadius: "0px",
            border: "1px solid black",
            fontSize: "14px",
          }}
        >
          {pageSizes.map((count) => (
            <MenuItem key={count} value={count}>
              {count}
            </MenuItem>
          ))}
        </Select>
        <Pagination
          count={Math.ceil(numberOfStacks / pageLimit)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          size="small"
        />
      </div>
    </div>
  );
}

export default TechExperienceGrid;
