import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/remote-config";

// import hubSpotPlugin from "@analytics/hubspot";
import { config } from "../Firebase/firebase";
import { generateTimestamp } from "./helpers";

if (app.apps.length === 0) {
  app.initializeApp(config);
}

export const db = app.firestore();
export const auth = app.auth();
export const fire = app.firestore;
export const funct = app.functions();
export const spApp = app;

export const fireAuth = app.auth();

export const projectID = config.projectId;

/**
 * Takes an extension to redirect the user to the sprytelabs link
 * @param {string} ext
 * @returns the partner platform link
 */
export const getSpryteLinks = (ext = "") => {
  if (config.projectId === "spryte-app") {
    return `https://spryte-partner.web.app/${ext}`;
  }
  if (config.projectId === "spryte-s") {
    return `https://spryte-s.web.app/${ext}`;
  }
  if (config.projectId === "spryte-p") {
    return `https://partners.sprytelab.com/${ext}`;
  }
};

export const spryteOrgRef = () => {
  return docRef("organisations/T6BAcTjwbXleCFpmWTmu");
};

export const caseStudyRatingType = () => {
  return docRef(
    "organisations/T6BAcTjwbXleCFpmWTmu/ratingTypes/HCyJ8QyBABNCq54kAKhx"
  );
};

export const devRatingType = () => {
  return docRef(
    "organisations/T6BAcTjwbXleCFpmWTmu/ratingTypes/po4GKaFj8dniwG2JouNB"
  );
};

export const partnerRatingType = () => {
  return docRef(
    "organisations/T6BAcTjwbXleCFpmWTmu/ratingTypes/C97b1JJccq2hemzpl56a"
  );
};

export const spryteCloudUrl = (ref) => {
  return `https://us-central1-${config.projectId}.cloudfunctions.net/${ref}`;
};

export const currentTime = () => {
  return fire.Timestamp.now();
};

//Returns firebase timestamp for current time.
export function GetNowTimeStamp() {
  return fire.Timestamp.now();
}

/**
 * This functions signs up new user with email and password as params
 * @param {string} email
 * @param {string} password
 * @returns user
 */
export const signUpWithEmailPassword = async (email, password) => {
  let userRef = await auth.createUserWithEmailAndPassword(email, password);
  return userRef;
};

export const getInvitedUserId = async (email, password) => {
  let addAuth = funct.httpsCallable("addAuth");
  let uid = await addAuth({ email, password });
  return uid;
};

/**
 * This function checks if email exists
 * @param {string} email
 * @returns email ref
 */
export const fetchSignMethods = async (email) => {
  let emailRef = await auth.fetchSignInMethodsForEmail(email);
  return emailRef;
};

/**
 * This function will return a document reference by the document path
 *
 * @param {string} _path document path
 * @example docRef("/devs/smdhue")
 * @returns document ref
 */
export const docRef = (_path) => {
  return db.doc(_path);
};

/**
 * this function checks wether it is a collection group or a collection and
 * return its reference for manipulation
 * @example colleRef('/devs/02O7gsQWZF62aRWNQKoP/stacks/')
 * @param {string} _collection
 * @param {boolean} isGroup
 * @returns collection reference
 */
export const colRef = (_collection, isGroup = false) => {
  let colRef = isGroup
    ? db.collectionGroup(_collection)
    : db.collection(_collection);
  return colRef;
};

/**
 * This function will add a document in the specified collection
 * @example createDocFromCollection('vendors', {'companyName':'test'...})
 * @param {string} _collection collection path
 * @param {object} _data object containing document data
 * @returns document ref
 */
export const createDocFromCollection = async (_collection, _data) => {
  let docRef = await db.collection(_collection).add(_data);
  return docRef;
};

/**
 * This function will create a document with a given id if the
 * document does not exist or update the document if the given
 * document id exist
 * @example
 * addOrUpdateDocFromPath('devs/testDevId/', {'first':'john', 'last':'doe',...})
 * @param {string} _path document path
 * @param {object} _data document data
 * @returns document reference
 */
export const addOrUpdateDocFromPath = async (_path, _data, merge = true) => {
  let ref = await db.doc(_path).set(_data, { merge: true });
  return ref;
};

/**
 * This functions takes a document path then delete the documents
 * @example deleteDocument('/devs/devIdToDelete')
 * @param {string} _path path of the document
 */
export const deleteDocument = async (_path) => {
  await docRef(_path).delete();
};

/**
 * This function listen to stream on documents changes in
 * collection or group collection
 * @example collectionStreamer('devs', getData)
 * @param {string} _collection
 * @param {function} _callBack
 * @param {boolean} isGroup
 * @returns
 */
export const collectionStreamer = (_collection, _callBack, isGroup = false) => {
  return colRef(_collection, isGroup).onSnapshot(_callBack);
};

/**
 * this function takes a path of a given document and return
 * that document
 * @example getDoc('devs/devId/')
 * @param {string} _path
 * @returns docment
 */
export const getDoc = async (_path) => {
  let doc = await docRef(_path).get();

  return doc;
};

/**
 * This function takes a collection path then return collection data with array of docs
 * @param {string} _collection
 * @param {boolean} isGroup
 * @returns
 */
export const getDocs = async (
  _collection,
  isGroup = false,
  limit,
  orderBy,
  startAfter = null
) => {
  let ref = colRef(_collection, isGroup);
  if (limit) ref = ref.limit(limit);
  if (orderBy) ref = ref.orderBy(orderBy);
  if (startAfter) ref = ref.startAfter(startAfter);
  const docsRef = await ref.get();
  return docsRef;
};

export const getDocsFromArray = async (colGroupName, whereKey, whereValues) => {
  try {
    let queryDoc = await db
      .collectionGroup(colGroupName)
      .where(whereKey, "in", whereValues)
      .get();
    let resData = [];
    let names = [];
    for (const doc of queryDoc.docs) {
      let data = doc.data();
      resData.push(data);
      names.push(data.name);
    }
    return [resData, names];
  } catch (e) {
    console.log(e);
  }
};

// functions from facade is here
/**
 * This function returns an array of DocumentSnapshots where a value (fieldValue)
 * is found in any field in the fields array (fields). It searches either a
 * collection (collection && !group) or a collection group (collection && group).
 * The ordering of the fields is done in accordance with the following list of
 * precendence: index of the field in the fields array, then the orderBy key
 * (fieldOrder && orderBy). Alternatively, one can have the results returned purely
 * based upon the orderBy key (!fieldOrder && orderBy).
 * The order may be set to descending (orderByDesc).
 *
 *
 * @example
 * // Returns all documents inside the collection "techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies"
 * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
 * // in an ascending manner, with rank being the secondary factor. The primary determinant is
 * // whether the "a" was found in the "nameIndex" or the "catIndex" field; "nameIndex" matches
 * // will come first.
 * await getDocumentsByArrayMembershipInCollection("/techStacks150r/eWf0RWD1qnEh5u7AVRUJ/technologies", "a", ["nameIndex", "catIndex"], "rank"));
 *
 * // Returns all documents inside the collection group "technologies"
 * // where either the nameIndex or catIndex keys contain the string "a". The results are ordered
 * // in an descending manner, with rank being the only factor determining the order of the results.
 * await getDocumentsByArrayMembershipInCollection("technologies", "a", ["nameIndex", "catIndex"], "rank", true, false, true));
 *
 * @param {String} collection - the collection or collection group to search.
 * @param {String} fieldValue - the value being searched for in the arrays.
 * @param {Array<String>} fields - the fields, containing arrays as values, to search.
 * @param {String} orderBy - the key to order the returned DocumentSnapshots by.
 * @param {Boolean} [group=false] - whether the collection key is in reference to a collection or a collection group.
 * @param {Boolean} [fieldOrder=true] - whether the returned array should be primarily sorted by the order of the fields array.
 * @param {Boolean} [orderByDesc=false] - whether any ordering done by the orderBy key should be desceding.
 * Note: This applies to sorting irrespective of fieldOrder.
 * @param {Number} [perFieldDocCap=null] - whether to limit the documents retrieved.
 * Note: This is a per-field cap. Use null for no limit. By default, duplicates with
 * higher indices are removed first.
 *
 * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
 */
export async function getDocumentsByArrayMembershipInCollection(
  collection,
  fieldValue,
  fields,
  orderBy,
  group = false,
  fieldOrder = true,
  orderByDesc = false,
  perFieldDocCap = null
) {
  // Create a reference to either the collection or collectionGroup.
  const collectionReference = group
    ? db.collectionGroup(collection)
    : db.collection(collection);

  // An object to termporarily store data.
  const documentSnapshotObject = {};

  // Create a set to ensure that only unique documents are returned.
  const visitedPathSet = new Set();

  // Iterate over each field.
  await Promise.all(
    fields.map(async (field, index) => {
      // Attempt to get the data based upon the field.
      try {
        // Add the where clause.
        let queryReference = collectionReference.where(
          field,
          "array-contains",
          fieldValue
        );

        // Add the orderBy, if it exists.
        if (orderBy)
          queryReference = queryReference.orderBy(
            orderBy,
            orderByDesc ? "desc" : "asc"
          );

        // Add the limit, if it exists.
        if (perFieldDocCap !== null)
          queryReference = queryReference.limit(perFieldDocCap);

        // Get the results.
        const querySnapshot = await queryReference.get();

        // Add the data to the documentSnapshotObject, retaining only unvisited documents.
        documentSnapshotObject[index] =
          querySnapshot?.docs?.filter((doc) => !visitedPathSet.has(doc.path)) ??
          [];

        // Add the documents' paths to the set.
        visitedPathSet.add(...querySnapshot?.docs.map((doc) => doc.path));

        // Catch all errors.
      } catch (error) {
        // Do nothing if an error occurred.
        console.log(error);
      }

      // Return the field.
      return field;
    })
  );

  // The final array of DocumentSnapshots to return.
  const documentSnapshotArray = [];

  // Add the data to the documentSnapshotArray in field order.
  for (let i = 0; i < fields.length; i++) {
    if (documentSnapshotObject[i] !== void 0)
      documentSnapshotArray.push(...documentSnapshotObject[i]);
  }

  // If the field order is irrelevant, sort based upon the key.
  if (!fieldOrder && orderBy !== void 0)
    documentSnapshotArray.sort((documentSnapshot1, documentSnapshot2) => {
      if (
        documentSnapshot1?.data()?.[orderBy] <
        documentSnapshot2?.data()?.[orderBy]
      )
        return orderByDesc ? 1 : -1;
      if (
        documentSnapshot1?.data()?.[orderBy] >
        documentSnapshot2?.data()?.[orderBy]
      )
        return orderByDesc ? -1 : 1;
      if (
        documentSnapshot1?.data()?.[orderBy] ===
        documentSnapshot2?.data()?.[orderBy]
      )
        return 0;
    });

  // Return the array of DocumentSnapshots.
  return documentSnapshotArray;
}

// The URL to cloud function that sends a requirement via mail to the mentioned mail ids by user
export const shareRequirementViaMailURL = `https://us-central1-${projectID}.cloudfunctions.net/sendHorologicalListsEmailsOnRequest`;

export async function transferClientRequirement(
  oldClientId,
  newClientId,
  listData,
  newClientName
) {
  try {
    // getting existing list Document data
    var tempListData = { ...listData, isTransfer: true };
    tempListData?.["clientId"] && delete tempListData["clientId"];
    tempListData?.["companyName"] && delete tempListData["companyName"];
    // tempListData?.["id"] && delete tempListData["id"];
    tempListData?.["logs"] && delete tempListData["logs"];
    tempListData?.["tuc"] && delete tempListData["tuc"];
    //Creating list document with existing list data and doc id
    await db
      .doc(`client/${newClientId}/lists/${listData.id}`)
      .set({ ...tempListData });

    const listStatuses = await db
      .collection(`client/${oldClientId}/lists/${listData.id}/listStatuses`)
      .get();
    const posStatuses = await db
      .collection(`client/${oldClientId}/lists/${listData.id}/posStatuses`)
      .get();
    const resourceStatuses = await db
      .collection(`client/${oldClientId}/lists/${listData.id}/resourceStatuses`)
      .get();
    const tucStatuses = await db
      .collection(`client/${oldClientId}/lists/${listData.id}/tucStatuses`)
      .get();

    await Promise.all(
      // Mapping through all tucs to create tucs docs
      listData.tuc.map(async (tucMap) => {
        //Getting existing TUC document data
        var tempTucMapData = { ...tucMap, isTransfer: true };
        tempTucMapData?.["id"] && delete tempTucMapData["id"];
        tempTucMapData?.["posData"] && delete tempTucMapData["posData"];
        tempTucMapData.positions.map(
          (tempPosMap) => delete tempPosMap["titleData"]
        );
        //Creating TUC doument with existing tuc data and doc id
        await db
          .doc(`client/${newClientId}/lists/${listData.id}/tuc/${tucMap.id}`)
          .set({ ...tempTucMapData });

        //Mapping through all position collections inside each TUC
        await Promise.all(
          Object.entries(tucMap.posData).map(async (posColArray) => {
            var posColDocId = posColArray[0]; //Position collection name
            var docData = posColArray[1]; // contains a map with key as that positon's dev's docID and value as the doc data
            Object.keys(docData).map(async (posDevDocId) => {
              //Getting existing position dev's document data
              var devData = { ...docData[posDevDocId], isTransfer: true };
              devData?.["id"] && delete devData["id"];
              devData?.["devData"] && delete devData["devData"];
              devData?.["positionDetails"] && delete devData["positionDetails"];
              //Creating dev document with existing data and doc id
              await db
                .doc(
                  `client/${newClientId}/lists/${listData.id}/tuc/${tucMap.id}/${posColDocId}/${posDevDocId}`
                )
                .set({ ...devData });
            });
          })
        );
      }),

      //Mapping through logs collection to copy all the logs
      listData?.logs?.map(async (logMap) => {
        //Getting existing log's document data
        var tempLogDocData = { ...logMap, isTransfer: true };
        tempLogDocData?.["id"] && delete tempLogDocData["id"];
        //Creating a log document with existing data and doc id
        await db
          .doc(`client/${newClientId}/lists/${listData.id}/log/${logMap.id}`)
          .set({ ...tempLogDocData });
      }),

      //Mapping through other sub-collections to copy the data over to new client
      listStatuses.docs.map(async (listStatusDocSnap) => {
        await db
          .doc(
            `client/${newClientId}/lists/${listData.id}/listStatuses/${listStatusDocSnap.id}`
          )
          .set(listStatusDocSnap.data());
      }),
      posStatuses.docs.map(async (listStatusDocSnap) => {
        await db
          .doc(
            `client/${newClientId}/lists/${listData.id}/posStatuses/${listStatusDocSnap.id}`
          )
          .set(listStatusDocSnap.data());
      }),
      resourceStatuses.docs.map(async (listStatusDocSnap) => {
        await db
          .doc(
            `client/${newClientId}/lists/${listData.id}/resourceStatuses/${listStatusDocSnap.id}`
          )
          .set(listStatusDocSnap.data());
      }),
      tucStatuses.docs.map(async (listStatusDocSnap) => {
        await db
          .doc(
            `client/${newClientId}/lists/${listData.id}/tucStatuses/${listStatusDocSnap.id}`
          )
          .set(listStatusDocSnap.data());
      })
    );

    //Deleting the requirement for the old client
    await Promise.all(
      listStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      posStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      resourceStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      tucStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      listData?.logs?.map(async (logMap) => {
        //Deleting a log document
        await db
          .doc(`client/${oldClientId}/lists/${listData.id}/log/${logMap.id}`)
          .delete();
      }),
      listData.tuc.map(async (tucMap) => {
        //Mapping through all position collections inside each TUC
        await Promise.all(
          Object.entries(tucMap.posData).map(async (posColArray) => {
            var posColDocId = posColArray[0]; //Position collection name
            var docData = posColArray[1]; // contains a map with key as that positon's dev's docID and value as the doc data
            Object.keys(docData).map(async (posDevDocId) => {
              //Deleting dev document
              await db
                .doc(
                  `client/${oldClientId}/lists/${listData.id}/tuc/${tucMap.id}/${posColDocId}/${posDevDocId}`
                )
                .delete();
            });
          })
        );
        //Deleting TUC doument
        await db
          .doc(`client/${oldClientId}/lists/${listData.id}/tuc/${tucMap.id}`)
          .delete();
      })
    );
    await db.doc(`client/${oldClientId}/lists/${listData.id}`).delete();
    logUserAction(
      `client/${newClientId}/lists/${listData.id}/log`,
      `Requirement transferred to ${newClientName} Account`,
      "ADMIN",
      "ACTION"
    );
    return {
      success: true,
      newReqRef: GetRefFromPath(`client/${newClientId}/lists/${listData.id}`),
    };
  } catch (e) {
    return { error: e };
  }
}

export async function deleteClientRequirement(clientId, listData) {
  try {
    const listStatuses = await db
      .collection(`client/${clientId}/lists/${listData.id}/listStatuses`)
      .get();
    const posStatuses = await db
      .collection(`client/${clientId}/lists/${listData.id}/posStatuses`)
      .get();
    const resourceStatuses = await db
      .collection(`client/${clientId}/lists/${listData.id}/resourceStatuses`)
      .get();
    const tucStatuses = await db
      .collection(`client/${clientId}/lists/${listData.id}/tucStatuses`)
      .get();

    //Deleting the requirement for the old client
    await Promise.all(
      listStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      posStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      resourceStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      tucStatuses.docs.map(async (docSnap) => {
        await docSnap.ref.delete();
      }),
      listData?.logs?.map(async (logMap) => {
        //Deleting a log document
        await db
          .doc(`client/${clientId}/lists/${listData.id}/log/${logMap.id}`)
          .delete();
      }),
      listData.tuc.map(async (tucMap) => {
        //Mapping through all position collections inside each TUC
        await Promise.all(
          Object.entries(tucMap.posData).map(async (posColArray) => {
            var posColDocId = posColArray[0]; //Position collection name
            var docData = posColArray[1]; // contains a map with key as that positon's dev's docID and value as the doc data
            Object.keys(docData).map(async (posDevDocId) => {
              //Deleting dev document
              await db
                .doc(
                  `client/${clientId}/lists/${listData.id}/tuc/${tucMap.id}/${posColDocId}/${posDevDocId}`
                )
                .delete();
            });
          })
        );
        //Deleting TUC doument
        await db
          .doc(`client/${clientId}/lists/${listData.id}/tuc/${tucMap.id}`)
          .delete();
      })
    );
    await db.doc(`client/${clientId}/lists/${listData.id}`).delete();
    return {
      success: true,
    };
  } catch (e) {
    return { error: e };
  }
}

/**
 * This function returns an array of Maps which represents the client list document data along with
 * tuc sub collection documents data maps in an array.
 *
 * @example
 * await fetchClientLists(clientId);
 *
 * @param {String} clientId - Client Id of the logged in user.
 *
 * @returns {List<Map>} Returns an array of Maps.
 */
export async function fetchClientLists(
  listId = null,
  allClientLists = false,
  clientNameObject = null,
  onlyTucCount = false
) {
  var returnObject = [];
  var clientListDoc = await db
    .collectionGroup("lists")
    .where("id", "==", listId)
    .get();
  let clientLists = clientListDoc.docs?.[0];
  const clientId = clientLists.ref.parent.parent.id;

  try {
    if (listId) {
      var tempData = clientLists.data();
      tempData["ref"] = clientLists.ref;
      tempData["id"] = clientLists.id;
      tempData["clientId"] = clientId;
      tempData["companyName"] =
        (await db.doc(`client/${clientId}`).get())?.data()?.["companyName"] ??
        "companyName";
      var tucDocs = await clientLists.ref.collection("tuc").get();
      if (!onlyTucCount) {
        tempData["tuc"] = [];
        tempData["logs"] = [];
        var logDocs = await clientLists.ref.collection("log").get();
        await Promise.all(
          tucDocs.docs.map(async (tucDocSnap) => {
            var temp = tucDocSnap.data();
            temp["id"] = tucDocSnap.id;
            tempData["tuc"].push(temp);
            // if(!temp['ref'])
            //    {
            //     temp['devData'] = {};
            temp["posData"] = {};
            await Promise.all(
              temp["members"]?.map(async (devRef, index) => {
                // var devData = await devRef?.collection("devPublic")?.doc("profile")?.get();
                // temp["devData"][devRef.path] = devData?.data();
                var positionsData = await tucDocSnap.ref
                  .collection(`POS-${index + 1}`)
                  .get();
                var tempPosData = {};
                await Promise.all(
                  positionsData.docs.map(async (posDocSnap) => {
                    tempPosData[posDocSnap.id] = posDocSnap.data();
                    tempPosData[posDocSnap.id]["id"] = posDocSnap.id;
                    tempPosData[posDocSnap.id]["devData"] = {};
                    if (posDocSnap.data()["devRef"]?.parent?.id == "devs") {
                      tempPosData[posDocSnap.id]["devData"] = (
                        await posDocSnap
                          .data()
                          ["devRef"]?.collection("devPublic")
                          ?.doc("profile")
                          ?.get()
                      ).data();
                    } else {
                      tempPosData[posDocSnap.id]["positionDetails"] = (
                        await posDocSnap.data()["devRef"]?.get()
                      )?.data();
                    }
                  })
                );
                temp["posData"][`POS-${index + 1}`] = tempPosData;
              }),
              temp["positions"]?.map(async (position, index) => {
                var positionData = await position["title"]?.get();
                temp["positions"][index]["titleData"] =
                  positionData?.data() ?? {};
              })
            );
            // }
          }),
          logDocs.docs.map(async (logDocSnap) => {
            var tempLog = logDocSnap.data();
            tempLog["id"] = logDocSnap.id;
            tempData["logs"].push(tempLog);
          })
        );
        tempData["tuc"]?.sort((a, b) => a?.addedOn - b?.addedOn);
        tempData["logs"]?.sort((a, b) => a?.addedOn - b?.addedOn);
      } else {
        tempData["tucCount"] = tucDocs?.docs?.length ?? 0;
      }
      returnObject.push(tempData);
    } else {
      await Promise.all(
        (!allClientLists ? clientLists.docs : clientLists).map(
          async (listDocSnap) => {
            var tempData = listDocSnap.data();
            tempData["id"] = listDocSnap.id;
            tempData["clientId"] = !allClientLists
              ? clientId
              : listDocSnap?.ref?.parent?.parent?.id;
            tempData["companyName"] = !allClientLists
              ? ""
              : clientNameObject[listDocSnap?.ref?.parent?.parent?.id];
            var tucDocs = await listDocSnap.ref.collection("tuc").get();
            if (!onlyTucCount) {
              tempData["tuc"] = [];
              tempData["logs"] = [];
              var logDocs = await listDocSnap.ref.collection("log").get();
              await Promise.all(
                tucDocs.docs.map(async (tucDocSnap) => {
                  var temp = tucDocSnap.data();
                  temp["id"] = tucDocSnap.id;
                  tempData["tuc"].push(temp);
                  temp["members"] = temp["members"] || [];
                  //  if(!temp['ref'])
                  //  {
                  // temp['devData'] = {};
                  temp["posData"] = {};
                  await Promise.all(
                    temp["members"]?.map(async (devRef, index) => {
                      // var devData = await devRef?.collection("devPublic")?.doc("profile")?.get();
                      // temp["devData"][devRef.path] = devData?.data();
                      var positionsData = await tucDocSnap.ref
                        .collection(`POS-${index + 1}`)
                        .get();
                      var tempPosData = {};
                      await Promise.all(
                        positionsData.docs.map(async (posDocSnap) => {
                          tempPosData[posDocSnap.id] = posDocSnap.data();
                          tempPosData[posDocSnap.id]["devData"] = {};
                          if (
                            posDocSnap.data()["devRef"]?.parent?.id == "devs"
                          ) {
                            tempPosData[posDocSnap.id]["devData"] = (
                              await posDocSnap
                                .data()
                                ["devRef"]?.collection("devPublic")
                                ?.doc("profile")
                                ?.get()
                            ).data();
                          } else {
                            tempPosData[posDocSnap.id]["positionDetails"] = (
                              await posDocSnap.data()["devRef"]?.get()
                            ).data();
                          }
                        })
                      );
                      temp["posData"][`POS-${index + 1}`] = tempPosData;
                    }),
                    temp["positions"]?.map(async (position, index) => {
                      var positionData = await position["title"]?.get();
                      temp["positions"][index]["titleData"] =
                        positionData?.data() ?? {};
                    })
                  );
                }),
                logDocs.docs.map(async (logDocSnap) => {
                  var tempLog = logDocSnap.data();
                  tempLog["id"] = logDocSnap.id;
                  tempData["logs"].push(tempLog);
                })
              );
              tempData["tuc"]?.sort((a, b) => a?.addedOn - b?.addedOn);
              tempData["logs"]?.sort((a, b) => a?.addedOn - b?.addedOn);
            } else {
              tempData["tucCount"] = tucDocs?.docs?.length ?? 0;
            }
            returnObject.push(tempData);
          }
        )
      );
    }
    return returnObject;
  } catch (error) {
    console.log("error: ", error);
    return null;
  }
}

// Example, please generalize and rename?
export async function getDocument(_collection, _document) {
  var docSnap = { data: {} };
  try {
    docSnap = await db.collection(_collection).doc(_document).get();
  } catch (e) {
    console.log(e);
  }
  return docSnap?.data() ?? {};
}

/**
 * This function runs a query in firebase. It is as generic as possible,
 * allowing for variegated use.
 *
 * @example
 * // Gets 1 document from the partnerPublic collectionGroup where the companyName is equal to "Cicero".
 * // Does not order results in any particular way.
 * await getDocuments("partnerPublic", true, "companyName", "==", "Cicero", false, null, null, true, 1, true);
 *
 * @param {String} collection - The basic collection or CollectionGroup to search. See group, below, for more.
 * @param {Boolean} where - Whether to add a where clause.
 * @param {Array<Array<String>>} whereConditions - The where clauses. Of the format: [[whereField, whereOperand, whereValue], ...]. Disregarded if !where.
 * @param {Boolean} orderBy - Whether to add an orderBy clause.
 * @param {String} orderByKey - The key to order results by. Disregarded if !orderBy.
 * @param {Boolean} [orderByDesc=false] - Whether to order results in a descending manner. Disregarded if !orderBy.
 * @param {Boolean} [limit=false] - Whether to add a limit clause.
 * @param {Number} [limitNumber=1] - The number of documents returned. Disregarded if !limit.
 * @param {Boolean} [group=false] - Whether the "collection" string refers to a collection or to a CollectionGroup.
 *
 * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
 */
export async function getDocumentsWhere(
  collection,
  where,
  whereConditions,
  orderBy,
  orderByKey,
  orderByDesc = false,
  limit = false,
  limitNumber = 1,
  group = false
) {
  // Attempt to get the requested documents.
  try {
    // Create the queryReference.
    let queryReference = group
      ? db.collectionGroup(collection)
      : db.collection(collection);

    // Add the where clause, if required.
    if (where)
      for (let whereCondition of whereConditions)
        queryReference = queryReference.where(
          whereCondition[0],
          whereCondition[1],
          whereCondition[2]
        );

    // Add the orderBy clause, if required.
    if (orderBy)
      queryReference = queryReference.orderBy(
        orderByKey,
        orderByDesc ? "desc" : "asc"
      );

    // Add the limit clause, if required.
    if (limit) queryReference = queryReference.limit(limitNumber);

    // Grab the data.
    const querySnapshot = await queryReference.get();

    // Return the documents.
    return querySnapshot.docs;

    // Should something go wrong, return an empty array.
  } catch (error) {
    return [];
  }
}

export async function updateDcoumentWithPath(path, data) {
  try {
    await db.doc(path).update(data);
    return { success: true };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
}

/**
 * Retrieve firestore document data from document path.
 *
 * @example
 * await getDocumentFromPath('categories/7sZ9Mx2uuiMHFBj5PQHK/subcollections/XImlbXq9xtKQSTI1qkHd');
 *
 * @param {String} _path Path to the document in the database.
 * @returns {Object} Firestore document's data
 */
export async function getDocumentFromPath(_path) {
  const docSnap = await db.doc(_path).get();
  return docSnap.data();
}

export async function logUserAction(
  path,
  message,
  userType = "",
  actionType = ""
) {
  // LOG: Logging user action.
  try {
    var logRef = await db.collection(path).add({
      addedOn: currentTime(),
      author: db.doc(`users/${fireAuth.currentUser.uid}`),
      msg: message,
      userType: userType,
      type: actionType,
    });
    return logRef;
  } catch (error) {
    return null;
  }
}

//Returns DocumentReference for the path string passed.
export function GetRefFromPath(_path) {
  try {
    return db.doc(_path);
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function addDocument(_path, _data) {
  try {
    var docRef = await db.collection(_path).add(_data);
    return docRef;
  } catch (error) {
    console.log(error);
  }
}

//Returns a collection reference for the given collection path
export function getCollectionRefFromPath(collectionPath) {
  return db.collection(collectionPath);
}

//Returns a collection reference for the given collection path
export function getCollectionGroupRefFromPath(collectionPath) {
  return db.collectionGroup(collectionPath);
}

// Regex for email format validation
export const emailFormatValidationRegex =
  /^\w+([\.-]?\w+)+([\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/;

/**
 * This function creates a custom requirement for selected list for the logged in client.
 *
 * @param {Map} tucData - All the custom inputs given by user for new tuc.
 * @param {String} path - list's path.
 * @param {Map} listData - Lists' data map.
 * @param {String} userType - role type of the logged in user(ADMIN/CLIENT).
 *
 */
export async function createCustomLists(
  tucData,
  path,
  listData = {},
  userType = ""
) {
  try {
    var vendorsRefsArray =
      tucData["vendors"]?.map((id) => db.doc(`/vendors/${id}`)) ?? [];
    var positionsArray = [];
    var membersRefs = [];
    for (const position of tucData["positions"]) {
      var positionData = {};
      positionData["title"] = position.ref;
      positionData["status"] = db.doc(`${path}/posStatuses/UNSPECIFIED`);
      positionData["minRate"] = tucData.minRate;
      positionData["maxRate"] = tucData.maxRate;
      positionData["notes"] = "";
      membersRefs.push(position.ref);
      positionsArray.push(positionData);
    }

    var tucRef = await db.collection(`${path}/tuc`).add({
      name: tucData["name"],
      open: tucData["openToAllVendors"],
      description: tucData["description"],
      addedOn: currentTime(),
      members: membersRefs ?? [],
      partners: vendorsRefsArray ?? [],
      ref: null,
      originalTeamCardData: {
        startDate: listData?.requirements?.start,
        endDate: listData?.requirements?.end,
        countryList: tucData["locations"],
        skillsObject: tucData["skillsObject"],
        hourlyPrice: tucData?.maxRate ?? 0,
      },
      requirements: tucData.requirements,
      positions: positionsArray,
    });
  } catch (error) {
    console.log("error: ", error);
  }

  // LOG: Logging user action.
  try {
    var message = `Added Team ${tucData["name"]} to the list.`;
    await logUserAction(`${path}/log`, message, userType, "ACTION");
  } catch (error) {
    return null;
  }
  return;
}

// The URL to call the getMatches cloud function.
export const getMatchesURL = `https://us-central1-${projectID}.cloudfunctions.net/getMatches`;

export async function deleteRequirementTuc(tucItem) {
  try {
    //Mapping through all position collections inside each TUC
    await Promise.all(
      Object.entries(tucItem.posData).map(async (posColArray) => {
        var posColDocId = posColArray[0]; //Position collection name
        var docData = posColArray[1]; // contains a map with key as that positon's dev's docID and value as the doc data
        Object.keys(docData).map(async (posDevDocId) => {
          //Deleting dev document
          await db
            .doc(`${tucItem.path}/${posColDocId}/${posDevDocId}`)
            .delete();
        });
      })
    );
    //Deleting TUC doument
    await db.doc(tucItem.path).delete();
    // logUserAction(
    //   `${tucItem?.path?.split('tuc')?.[0]}log`,
    //   `A team option had been deleted`,
    //   "ADMIN",
    //   "ACTION"
    // );
    return {
      success: true,
    };
  } catch (e) {
    return { error: e };
  }
}

export function availableTimesForInterview(
  numOfDays = 5,
  fromTime = 6,
  toTime = 12,
  isHalfHourInterval = true
) {
  var dates = [];
  var count = 1;
  var times = {};

  while (dates.length < numOfDays) {
    const now = new Date();
    now.setDate(now.getDate() + count);
    count = count + 1;
    if (!["Sun", "Sat"].includes(now.toDateString().split(" ")[0])) {
      dates.push(now);
    }
  }
  dates.forEach((date, index) => {
    var t = [];
    var timeCount = 0;
    while (timeCount <= (toTime - fromTime) / (isHalfHourInterval ? 0.5 : 1)) {
      const tempDate = new Date(date);
      tempDate.setHours(fromTime + timeCount * (isHalfHourInterval ? 0.5 : 1));
      tempDate.setMinutes(
        60 * ((fromTime + timeCount * (isHalfHourInterval ? 0.5 : 1)) % 1)
      );
      tempDate.setSeconds(0);
      tempDate.setMilliseconds(0);
      t.push(tempDate);
      timeCount++;
    }
    times[index] = t;
  });
  return { dates: dates, times: times };
}

// returns a firestore timestamp for the given value in milliseconds
export function getTimestampFromMilliseconds(value = 0) {
  return fire.Timestamp.fromMillis(value);
}

// Uploads file to firestorage and returns the firestore link of that file.
export async function uploadFileToStorage(_path, _file) {
  let fileLink;
  try {
    let response = await spApp
      .storage()
      .ref(`${_path}/${_file.name}_${generateTimestamp()}`)
      .put(_file);
    fileLink = await response.ref.getDownloadURL();
  } catch (e) {
    console.log(e);
  }
  return fileLink ?? "";
}

// deletes the file from the storage using url
export async function deleteFileFromStorage(_url) {
  try {
    let response = await spApp.storage().refFromURL(_url).delete();
    return response;
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetches recommended devs for a se;ected position on requirement page.
 *
 * @param {Map} tucItem - Needs to pass on selected TUC.
 * @param {Number} index - Index of the selected Position
 * @param setPosRecommendations - Funciton of an setState variable to set the value.
 *
 */
export async function fetchRecommendationsOnReqPage(
  tucItem,
  index,
  setPosRecommendations
) {
  db.collection(`${tucItem?.path}/POS-${index + 1}`)
    .where(
      "status",
      "==",
      GetRefFromPath(
        `${tucItem?.path?.split("tuc")?.[0]}resourceStatuses/RECOMMENDED`
      )
    )
    .onSnapshot(async (snap) => {
      var tempdevs = [];
      await Promise.all(
        snap?.docs?.map(async (dev) => {
          var tempData = dev.data();
          tempData["ref"] = dev.ref;
          tempData["devData"] =
            (
              await dev
                .data()
                ?.devRef?.collection("devPublic")
                ?.doc("profile")
                .get()
            )?.data() ?? {};
          tempData["devData"]["id"] = dev.data()?.devRef?.id;
          var tempPartnerData =
            (
              await dev
                ?.data()
                ?.partnerRef?.collection("partnerPublic")
                ?.doc("profile")
                ?.get()
            )?.data() ?? {};
          tempData["partnerData"] = {
            ...tempPartnerData,
            ref: dev.data().partnerRef,
          };
          var tempStacksData = [];
          if (tempData["devData"]?.stats?.stacks?.data) {
            await Promise.all(
              tempData?.["devData"]?.stats?.stacks?.data?.map(
                async (stackMap) => {
                  var tempData = (await stackMap.ref.get()).data();
                  if (tempData?.show)
                    tempStacksData.push({
                      name: tempData?.name,
                      logoStorage: tempData?.logoStorage,
                      stats: stackMap.stats,
                    });
                }
              )
            );
          }
          const rateRanges = await getDocumentsWhere(
            `devs/${tempData["devData"]["id"]}/rateRanges`,
            false,
            null,
            true,
            "from",
            false,
            false,
            false,
            false
          );
          var hourlyRate = null;
          // Process all rate ranges.
          for (let rate of rateRanges) {
            // Continue if the date after today.
            if (rate.data().to.toDate() < new Date()) continue;

            if (hourlyRate == null)
              hourlyRate = rate?.data()?.["hourlyRates"]?.["L1"];
          }
          tempData["devData"]["clients"] = tempPartnerData["clients"];
          tempData["devData"]["hourlyRate"] = hourlyRate;
          tempData["devData"]["stacks"] = tempStacksData;
          tempdevs.push(tempData);
        })
      );
      tempdevs.sort((a, b) => a.matchRank - b.matchRank);
      setPosRecommendations(tempdevs);
    });
}

export const helpTabSiteComponentPath = {
  admin: "/siteComponents/NQE0PfPYC0w5lvoqmiPS/inst/B8R3soBTxFRvYwcP5sLJ",
  partner: "/siteComponents/NQE0PfPYC0w5lvoqmiPS/inst/OsYoncKl15BlcnjBGDhl",
  client: "/siteComponents/NQE0PfPYC0w5lvoqmiPS/inst/rWlAbqBHfL52vYdtQJL1",
};

/**
 * Fetches devs for a selected partner on requirement page.
 *
 * @param {firebase.firestore.DocumentSnapshot} selectedPartner - Slected Partner docSnap.
 * @param {String} selectedUserRole - Selected user role of the logged in user
 * @param {Map} partnerAccountData - spryte-partner account data of the logged in user.
 * @param setSelectedPartnerDevs - setState variable for setting the fetched devs array.
 *
 */
export async function fetchPartnerDevsOnReqPage(
  selectedPartner,
  selectedUserRole,
  partnerAccountData,
  setSelectedPartnerDevs
) {
  db.collectionGroup("devPublic")
    .where(
      "vendorID",
      "==",
      selectedUserRole === "admin"
        ? selectedPartner?.ref?.id
        : partnerAccountData?.vendorRef?.id
    )
    .orderBy("status", "desc")
    .onSnapshot(async (snap) => {
      var tempdevs = [];
      await Promise.all(
        snap?.docs?.map(async (dev) => {
          var tempData = dev?.data() ?? {};
          tempData["ref"] = dev.ref.parent.parent;
          var tempPartnerData =
            (
              await db
                .doc(`vendors/${dev?.data()?.vendorID}/partnerPublic/profile`)
                ?.get()
            )?.data() ?? {};
          var tempStacksData = [];
          if (tempData?.stats?.stacks?.data) {
            await Promise.all(
              tempData?.stats?.stacks?.data?.map(async (stackMap) => {
                var tempData = (await stackMap.ref.get()).data();
                if (tempData?.show)
                  tempStacksData.push({
                    name: tempData?.name,
                    logoStorage: tempData?.logoStorage,
                    stats: stackMap.stats,
                  });
              })
            );
          }
          const rateRanges = await getDocumentsWhere(
            `devs/${tempData["devDocID"]}/rateRanges`,
            false,
            null,
            true,
            "from",
            false,
            false,
            false,
            false
          );
          var hourlyRate = null;
          // Process all rate ranges.
          for (let rate of rateRanges) {
            // Continue if the date after today.
            if (rate.data().to.toDate() < new Date()) continue;

            if (hourlyRate == null)
              hourlyRate = rate?.data()?.["hourlyRates"]?.["L1"];
          }
          const tempDevDocData = (await dev.ref.parent.parent.get()).data();
          tempData["partnerData"] = tempPartnerData;
          tempData["partnerData"]["ref"] = db.doc(
            `vendors/${dev?.data()?.vendorID}/partnerPublic/profile`
          );
          tempData["partnerHourlyRate"] = hourlyRate;
          tempData["clients"] = tempPartnerData["clients"];
          tempData["hourlyRate"] = hourlyRate;
          tempData["stacks"] = tempStacksData;
          tempData["resumeData"] = tempDevDocData?.["resumeData"] ?? {};
          tempData["resume"] = tempDevDocData?.["resume"] ?? {
            external: null,
            externalRef: null,
            internal: null,
            internalRef: null,
          };
          tempdevs.push(tempData);
        })
      );
      setSelectedPartnerDevs(tempdevs);
    });
}

// Gets the logged in user's user doc data
export async function getCurrentUser() {
  var uid = fireAuth.currentUser?.uid;
  if (uid != null) {
    const docSnap = await db.doc(`users/${uid}`).get();
    var data = docSnap.data();
    data["path"] = docSnap.ref.path;
    data["uid"] = uid;
    return data;
  } else {
    return { error: "User not Logged In" };
  }
}

// Return a DocumentReference from a path.
export function createDocumentReferenceFromPath(path) {
  return db.doc(path);
}
