import React, { useEffect, useState } from "react";
import SpModal, { SpModalFooter, SpModalHead } from "../../SpModal";
import {
  addOrUpdateDocFromPath,
  createDocFromCollection,
} from "../../globals/services";
import { DEVSCOLLECTIONS } from "../../globals/helpers";
import { Grid, InputLabel, TextField } from "@mui/material";
import { DEVDATA } from "../../utils/DevsUtils";
// import { makeStyles } from "@mui/styles";

// status data
const myStatus = [
  { label: "Inactive", value: "Inactive" },
  { label: "Active", value: "Active" },
];

// removes the border bottom on mui text components
// const useStyles = makeStyles({
//   underline: {
//     "&&&:before": {
//       borderBottom: "none",
//     },
//     "&&:after": {
//       borderBottom: "none",
//     },
//   },
// });

function GridDialog({
  isOpen,
  onClose,
  headerTitle,
  desc,
  loading,
  allPositions,
  selDevData,
  vendorRef,
  vendor,
  hoursInWorkDay,
  minContractTeamSize,
  action,
  setIsDuplicate = null,
  setIsUpdated = null,
  dataToDuplicate,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(10);
  const [locTag, setLocTag] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [devID, setDevID] = useState("");
  const [error, setError] = useState("");
  // const [devData, setDevData] = useState({ ...DEVDATA });
  // const classes = useStyles();

  // converted firstName and lastName values to initial caps
  const lowerCaseFirstName = firstName?.toLowerCase(); // converts all search values to lower case
  const convertedFirstName =
    lowerCaseFirstName?.charAt(0)?.toUpperCase() + lowerCaseFirstName?.slice(1); // converts first letter to upper case

  const lowerCaseLastName = lastName?.toLowerCase(); // converts all search values to lower case
  const convertedLastName =
    lowerCaseLastName?.charAt(0)?.toUpperCase() + lowerCaseLastName?.slice(1); // converts first letter to upper case

  useEffect(() => {
    // pre-fill form details with selected dev data
    if (action === "edit" && selDevData?.hasOwnProperty.length === 1) {
      setFirstName(selDevData.first);
      setLastName(selDevData.last);
      setPosition(selDevData.position);
      setStatus(selDevData.status ? "Active" : "Inactive");
      setRating(selDevData.rating);
      setHourlyRate(selDevData.hourlyRate);
      setLocTag(selDevData.locTag);
      setAccessCode(selDevData.accessCode);
      setDevID(selDevData.devID);
    } else if (action === "duplicate") {
      setFirstName("");
      setLastName("");
      setPosition(selDevData?.position);
      setStatus(selDevData?.status ? "Active" : "Inactive");
      setRating(0);
      setHourlyRate(selDevData?.hourlyRate);
      setLocTag(selDevData?.locTag);
      setAccessCode("");
      setDevID("");
    } else {
      setFirstName("");
      setLastName("");
      setPosition("Jr. Developer");
      setStatus("Inactive");
      setRating(0);
      setHourlyRate(10);
      setLocTag("");
      setAccessCode("");
      setDevID("");
    }
  }, [action, selDevData, isOpen]);

  // OPTIMIZE USING DEV DATA
  // useEffect(() => {
  //   if (action === "duplicate") {
  //     const { position, positionRef, status, rating, hourlyRate, locTag } =
  //       selDevData;
  //     setDevData({
  //       ...DEVDATA,
  //       position,
  //       status,
  //       rating,
  //       hourlyRate,
  //       locTag,
  //       positionRef,
  //     });
  //   }

  //   if (action === "edit") setDevData({ ...selDevData });

  //   if (action === "add") setDevData({ ...DEVDATA });
  // }, [action, selDevData, isOpen]);

  // clears form
  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setPosition("");
    setStatus("");
    setRating(0);
    setHourlyRate(0);
    setLocTag("");
    setAccessCode("");
    setDevID("");
  };

  // Create random string of length "length"
  let randomString = (length) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // const canSave = () => {
  //   const { first, last, position, locTag, devID } = devData;
  //   return first && last && position && locTag && devID;
  // };

  // submit create dev data
  const createNewDev = async () => {
    if (firstName === "" || lastName === "" || locTag === "" || devID === "") {
      return;
    }
    // closes the modal on submit if required text fields are not empty
    if (firstName !== "" || lastName !== "" || locTag === "" || devID !== "") {
      onClose();
    }
    const devPosition = position === "" ? "Jr. Developer" : position;
    let selPosition = allPositions.filter((el) => el.label === devPosition);

    if (action === "add" || action === "duplicate") {
      // dev data
      let devData = {
        first: convertedFirstName,
        last: convertedLastName,
        position: devPosition,
        status: status === "Active" ? true : false,
        // rating: parseFloat(rating),
        locTag: locTag,
        accessCode: accessCode,
        devID: devID,
      };

      devData.positionRef = selPosition[0]?.ref;
      devData.vendorRef = vendorRef;
      devData.isVerified = true;
      devData.orgRef = vendor.orgRef;
      devData.crossVendor = false;

      if (devData?.status === true) {
        devData["triggerSearchCompute"] = new Date();
      }
      devData.minContractTeamSize = minContractTeamSize;
      if (!devData.accessCode) {
        devData.accessCode = randomString(10);
      }
      devData.addedOn = new Date();
      devData.discovery = true;
      devData.draft = false;
      devData.isVerified = true;
      let devDoc = await createDocFromCollection("devs", devData);
      let startDate = new Date();
      let date = new Date();
      //end Date is a year from the day the dev was added in the vendor resources
      let endDate = new Date(date.setFullYear(date.getFullYear() + 1));
      // hourly rate data
      const rateData = {
        dailyHours: hoursInWorkDay,
        description: "",
        from: new Date(startDate.setHours(0, 0, 0, 0)),
        to: new Date(endDate.setHours(23, 59, 59, 59)),
        hourlyRates: {
          L1: parseFloat(hourlyRate),
        },
      };

      const devId = devDoc.id;
      // creates a new dev if the action is add
      await createDocFromCollection(`devs/${devId}/rateRanges`, rateData);

      if (action === "duplicate") {
        Object.entries(dataToDuplicate).map(([key, values]) => {
          return Promise.all(
            values.map((data) => {
              const { id, ...rawData } = data;
              createDocFromCollection(
                `devs/${devId}/${DEVSCOLLECTIONS?.[key]}`,
                rawData
              );
              return rawData;
            })
          );
        });
      }
      setIsUpdated && setIsUpdated(true);
    } else {
      // edit selected dev data
      let editDevData = {
        first: convertedFirstName,
        last: convertedLastName,
        position: devPosition,
        status: status === "Active" ? true : false,
        positionRef: selPosition[0]?.ref,
        // rating: parseFloat(rating),
        locTag: locTag,
        accessCode: accessCode,
        devID: devID,
      };

      // triggerSearchCompute when status change from false to true
      if (!selDevData.status && status === "Active") {
        editDevData["triggerSearchCompute"] = new Date();
      }

      const devId = selDevData.id;
      // updates the  selected dev data if the action is edit
      await addOrUpdateDocFromPath(`devs/${devId}`, editDevData);
      setIsUpdated && setIsUpdated(true);
    }

    setIsDuplicate && setIsDuplicate(false);

    // clears form
    clearForm();
  };

  return (
    <SpModal open={isOpen} locked={false} onClose={onClose}>
      <SpModalHead title={headerTitle} desc={desc} />
      <Grid container style={{ marginLeft: "20px" }}>
        <Grid item xs={6} direction="column">
          <InputLabel
            required
            style={{ fontSize: 12 }}
            id="demo-multiple-position-label"
          >
            First Name
          </InputLabel>
          <TextField
            required
            style={{ width: "85%", marginBottom: "20px" }}
            id="outlined-required"
            value={firstName}
            InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
            InputProps={{
              style: { fontSize: 12 }, // font size of input text
              // classes,
            }}
            // text field validation
            error={firstName === ""}
            helperText={
              firstName === "" ? "First name field cannot be empty!" : ""
            }
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputLabel
            required
            style={{ fontSize: 12 }}
            id="demo-simple-select-standard-label"
          >
            Select Position
          </InputLabel>
          <select
            style={{ width: "85%", marginBottom: "20px" }}
            id="demo-simple-select-standard"
            className="dropdown"
            defaultValue={"Jr. Developer"}
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            {/* <option value={position}>{position}</option> */}
            {allPositions.map((position) => (
              <option key={position.ref.id} value={position.label}>
                {position.label}
              </option>
            ))}
          </select>
          {action === "edit" ? (
            <>
              <InputLabel
                // required
                style={{ fontSize: 12 }}
                id="demo-multiple-position-label"
              >
                Rating
              </InputLabel>
              <TextField
                type="number"
                className="rating"
                // required
                style={{ width: "85%", marginBottom: "20px" }}
                id="outlined-required"
                InputLabelProps={{ style: { fontSize: 14 } }}
                InputProps={{
                  inputProps: { min: parseFloat(0), max: 4 },
                  style: { fontSize: 12 },
                  // classes,
                }}
                disabled={true}
                value={rating}
                error={rating === ""}
                helperText={
                  rating === "" ? "Rating field cannot be empty!" : ""
                }
                onChange={(e) => {
                  let val = parseFloat(e.target.value, 10);
                  if (isNaN(val)) {
                    setRating("");
                  } else {
                    // if val is a number
                    val = val >= 0 ? val : 0;
                    val = val > 4 ? 4 : val;
                    setRating(val);
                  }
                }}
              />
            </>
          ) : (
            <>
              <InputLabel
                required
                style={{ fontSize: 12 }}
                id="demo-multiple-position-label"
              >
                Hourly Rate
              </InputLabel>
              <span className="_spResource_dialog_error">{error}</span>
              <TextField
                type="number"
                className="rating"
                required
                style={{ width: "85%", marginBottom: "20px" }}
                id="outlined-required"
                InputLabelProps={{ style: { fontSize: 14 } }}
                InputProps={{
                  inputProps: {
                    min: parseInt(10),
                    max: Number.POSITIVE_INFINITY,
                  },
                  style: { fontSize: 12 },
                  // classes,
                }}
                value={hourlyRate}
                error={hourlyRate === ""}
                helperText={
                  hourlyRate === "" ? "Hourly rate field cannot be empty!" : ""
                }
                onChange={(e) => {
                  let val = parseFloat(e.target.value, 10);
                  if (isNaN(val)) {
                    setHourlyRate("");
                  } else {
                    // if val is a number
                    val = val >= 0 ? val : 0;
                    // val = val < 10 ? 0 : val;
                    if (val < 10 && val !== "") {
                      // if (val < 10) {
                      setError("Value cannot be less than 10");
                    } else {
                      setError("");
                    }
                    setHourlyRate(val);
                  }
                }}
              />
            </>
          )}
          <InputLabel
            required
            style={{ fontSize: 12 }}
            id="demo-multiple-position-label"
          >
            Location
          </InputLabel>
          <TextField
            required
            style={{ width: "85%", marginBottom: "20px" }}
            id="outlined-required"
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{
              style: { fontSize: 12 },
              // classes,
            }}
            value={locTag}
            error={locTag === ""}
            helperText={locTag === "" ? "Location field cannot be empty!" : ""}
            onChange={(e) => setLocTag(e.target.value)}
          />
        </Grid>
        <Grid xs={6} direction="column">
          <InputLabel
            required
            style={{ fontSize: 12 }}
            id="demo-multiple-position-label"
          >
            Last Name
          </InputLabel>
          <TextField
            required
            style={{ width: "85%", marginBottom: "20px" }}
            id="outlined-required"
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{
              style: { fontSize: 12 },
              // classes,
            }}
            value={lastName}
            error={lastName === ""}
            helperText={
              lastName === "" ? "Last name field cannot be empty!" : ""
            }
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputLabel
            required
            style={{ fontSize: 12 }}
            id="demo-simple-select-standard-label"
          >
            Status
          </InputLabel>
          <select
            style={{ width: "85%", marginBottom: "20px" }}
            id="demo-simple-select-standard"
            className="dropdown"
            defaultValue={selDevData?.status ? "Active" : "Inactive"}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            {myStatus.map((stat) => (
              <option key={stat.label} value={stat.value}>
                {stat.label}
              </option>
            ))}
          </select>
          <InputLabel
            // required
            style={{ fontSize: 12 }}
            id="demo-multiple-position-label"
          >
            Access Code
          </InputLabel>
          <TextField
            // required
            style={{ width: "85%", marginBottom: "20px" }}
            id="outlined-required"
            placeholder="Leave Empty To Generate Or Enter Your Access Code"
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{
              style: { fontSize: 12 },
              // classes,
            }}
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
          />
        </Grid>
        <Grid
          xs={6}
          direction="row"
          className="internalID-edit"
          // className={action === "edit" ? "internalID-edit" : "internalID"}
        >
          <div
            className="internalID-div-edit"
            // className={
            //   action === "edit" ? "internalID-div-edit" : "internalID-div"
            // }
          >
            <InputLabel
              required
              style={{ fontSize: 12 }}
              id="demo-multiple-position-label"
            >
              Internal ID
            </InputLabel>
            <TextField
              required
              style={{
                width: "85%",
                marginBottom: "20px",
                textAlign: "center",
              }}
              id="outlined-required"
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{
                style: { fontSize: 12 },
                // classes,
              }}
              value={devID}
              error={devID === ""}
              helperText={
                devID === "" ? "Internal ID field cannot be empty!" : ""
              }
              onChange={(e) => {
                let val = e.target.value;
                setDevID(val);
              }}
            />
          </div>
        </Grid>
      </Grid>

      <SpModalFooter
        onSubmit={() => {
          error === "" && createNewDev();
        }}
        disabled={
          error !== "" ||
          loading ||
          !devID ||
          !lastName ||
          !locTag ||
          !status ||
          ((action === "add" || action === "duplicate") && !hourlyRate) ||
          !firstName ||
          !position
        }
        submitLabel={"Save"}
        closeLabel="cancel"
        onClose={() => {
          onClose();
          // clearForm();
        }}
        loading={loading}
      />
    </SpModal>
  );
}

export default GridDialog;
