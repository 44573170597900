import React from "react";

const WorkIcon = ({ w = "17", h = "16", fill = "#FA5E2F" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.85552 0.0962541C7.93041 0.404378 7.07674 1.06753 6.66782 1.79609C6.03352 2.9264 6.07971 3.08056 7.05148 3.08056C7.73918 3.08056 7.89336 3.00776 7.89336 2.68251C7.89336 1.98957 8.95029 1.32875 10.0582 1.32875C11.1661 1.32875 12.2231 1.98957 12.2231 2.68251C12.2231 3.00776 12.3772 3.08056 13.0649 3.08056C13.5809 3.08056 13.9047 2.98635 13.9015 2.83725C13.889 2.25682 12.9377 0.962037 12.2204 0.54861C11.4418 0.100147 9.59108 -0.148804 8.85552 0.0962541ZM1.89025 4.12931C1.47676 5.0093 0.463617 7.84684 0.305343 8.56858C-0.0164977 10.036 1.41687 10.5039 7.35215 10.8689C10.1677 11.0419 14.2633 10.8734 17.137 10.4664C18.3118 10.3 18.9745 10.0923 19.3817 9.76273C20.0802 9.19748 20.0136 8.68187 18.86 5.71722L18.0992 3.76181L10.1051 3.71043L2.11106 3.65924L1.89025 4.12931ZM0.447501 11.5895C0.306064 11.9947 0.123495 12.9436 0.0417124 13.6983C-0.124259 15.2319 0.186757 15.7325 1.3055 15.7325C1.63215 15.7325 2.21979 15.9242 2.61114 16.1584L3.32314 16.5843L4.03513 16.1584C4.71681 15.7506 4.97203 15.7325 10.0582 15.7325C15.1444 15.7325 15.3996 15.7506 16.0813 16.1584L16.7933 16.5843L17.5053 16.1584C17.8966 15.9242 18.4843 15.7325 18.8109 15.7325C19.6504 15.7325 20.1608 15.2365 20.1608 14.4206C20.1608 13.5879 19.7161 11.4234 19.482 11.1171C19.3618 10.9596 18.5968 10.9738 16.6721 11.1692C15.2173 11.3168 13.418 11.4406 12.6733 11.4439C11.7716 11.4482 11.22 11.5476 11.0204 11.7423C10.8556 11.9029 10.4226 12.0342 10.0582 12.0342C9.69379 12.0342 9.26083 11.9029 9.09606 11.7423C8.9094 11.5603 8.37757 11.4497 7.68361 11.4487C6.56872 11.4472 2.91615 11.1593 1.47268 10.9592C0.751541 10.8594 0.688761 10.8981 0.447501 11.5895Z"
        fill={fill}
      />
    </svg>
  );
};

export default WorkIcon;
