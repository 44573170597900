// import { Tooltip } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../hooks/SpHooks";
import ResponsiveImage from "../../ResponsiveImg";
// import SpResponsiveImg from "../../../SpResponsiveImg";
import { renderOpacity } from "../../stylesConstant";
import { SingleStack, StacksList, StacksWrapper, StackTitle } from "./style";

export const DevStacks = ({
  stacks = [],
  showNumber = 0,
  cls = "",
  title = "Expertise",
  bg,
  sx,
  experience,
  stackImgWidth = "16px",
  stackImgheight = "16px",
  stackborderRadius = "0px",
  stackPadding = "0px",
  showStackBg = true,
  experText = "experience",

  stackSx,
  stackListSx,
  showLessThanYear = true,
}) => {
  // console.log(experience);
  const stackListRef = useRef();
  const windowDimenion = useScreenSize();
  useEffect(() => {
    if (!stackListRef?.current?.clientHeight || stacks.length < 1) return;

    const el = stackListRef?.current;
    let childCol = el?.children;
    let hiddenEl = Array.prototype.slice.call(el?.children);
    hiddenEl?.forEach((ele, i) => {
      let elementToHide = childCol[i];
      elementToHide.style.display = "";
      if (
        ele?.clientHeight + ele?.offsetTop >
        el?.clientHeight + el?.offsetTop
      ) {
        let elementToHide = childCol[i];
        elementToHide.style.display = "none";
      }
    });
  }, [
    stackListRef?.current?.clientWidth,
    stackListRef?.current?.clientHeight,
    stackListRef?.current?.parentElement?.clientHeight,
    windowDimenion?.winWidth,
    stacks?.length,
  ]);

  return (
    <>
      {stacks?.length > 0 && (
        <StacksWrapper className={cls} style={sx} bg={bg}>
          <StackTitle className={`${cls}_title`}>
            <h3>{title}</h3>
            {experience >= 0 && (
              <span>
                (
                {experience > 1
                  ? `${Math.round(experience)} years`
                  : `${Math.round(experience)} year`}{" "}
                {experText})
              </span>
            )}
          </StackTitle>
          <StacksList
            style={stackListSx}
            className={`${cls}_stacksList`}
            ref={stackListRef}
          >
            {stacks
              ?.sort((a, b) => b?.stats?.experience - a?.stats?.experience)
              ?.map((stack, i) => {
                // if (i < stacksToDisplay) {
                // console.log("stack", stack);
                if (!showLessThanYear && stack?.stats?.experience < 11)
                  return null;

                if (showNumber > 0 && i <= showNumber - 1) {
                  return (
                    <Tooltip
                      // title={stack?.name}
                      arrow
                      placement="top"
                      key={`${stack?.name}_${i}`}
                      title={
                        <p style={{ color: "white", fontSize: "10px" }}>
                          {stack?.name}
                        </p>
                      }
                    >
                      <SingleStack
                        style={stackSx}
                        stackborderradius={stackborderRadius}
                        stackpadding={stackPadding}
                        className={`${cls}_stacksList-single`}
                        showstackbg={showStackBg}
                      >
                        <div className="stackLeft">
                          <ResponsiveImage
                            src={stack?.logoStorage}
                            alt={stack?.name}
                            width={stackImgWidth}
                            height={stackImgheight}
                            className={`${cls}_stacksList-single-img stackLeft--logoWrapper`}
                          />
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {stack?.name}
                          </p>
                        </div>
                        <div
                          className="stackRight"
                          style={{
                            backgroundColor: `rgba(0, 0, 0, ${renderOpacity(
                              stack?.stats?.experience / 12,
                              10
                            )})`,
                          }}
                        >
                          <span>{`${(
                            stack?.stats?.experience / 12
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}y`}</span>
                        </div>
                      </SingleStack>
                    </Tooltip>
                  );
                } else if (showNumber === 0) {
                  return (
                    <SingleStack
                      key={`${stack?.name}_${i}`}
                      style={stackSx}
                      stackborderradius={stackborderRadius}
                      stackpadding={stackPadding}
                      className={`${cls}_stacksList-single`}
                      showstackbg={showStackBg}
                    >
                      <div className="stackLeft">
                        {/* <SpResponsiveImg
                          src={stack?.logoStorage}
                          alt={stack?.name}
                          width={stackImgWidth}
                          height={stackImgheight}
                          wrappercls={`${cls}_stacksList-single-img`}
                        /> */}
                        <p>{stack?.name}</p>
                      </div>
                      <div className="stackRight">
                        <span>{1}y</span>
                      </div>
                    </SingleStack>
                  );
                }

                // }
              })}
            {stacks?.length - showNumber > 0 && (
              <SingleStack
                style={stackSx}
                stackborderradius={stackborderRadius}
                stackpadding={stackPadding}
                className={`${cls}_stacksList-single`}
                showstackbg={showStackBg}
              >
                <div className="stackLeft">
                  <span
                    style={{
                      color: "#868DA9",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    +{stacks?.length - showNumber} more
                  </span>
                </div>
              </SingleStack>
            )}
            {/* <div className="_criteriaExpertise">
              <span
                style={{
                  // color: "#868DA9",
                  backgroundColor: "black",
                  color: "white",
                  padding: "5px 5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                top 1%
              </span>
            </div> */}
          </StacksList>
        </StacksWrapper>
      )}
    </>
  );
};
