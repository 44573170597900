import React from "react";
import {
  addDocument,
  GetNowTimeStamp,
  GetRefFromPath,
  getCollectionRefFromPath,
} from "../../../../globals/services";

import SpMatchCard, {
  DevActions,
  DevProfile,
  DevRates,
  DevsClients,
  DevStacks,
} from "../../../../globals/SpMatchCard";
import { Check, Upload } from "@mui/icons-material";
// import {
//   addDocument,
//   GetNowTimeStamp,
//   GetRefFromPath,
// } from "../../../firebase/store";
// import SpDevCards, {
//   DevActions,
//   DevProfile,
//   DevRates,
//   DevsClients,
//   DevStacks,
// } from "../../globalComponents/SpDevCard";

const PartnerDev = ({
  member,
  allTucPositions,
  tucsData,
  selectedUserRole,
  setSelectedTabIndex,
  setShowDevProfile,
  isOpenToVendor,
  setShowUpdateDev,
  setDevDataToUpdate,
}) => {
  const [isPicked, setIsPicked] = React.useState(false);
  const [devPos, setDevPos] = React.useState(false);
  const devData = member;
  devData["experience"] = devData?.stats?.stacks?.stats?.max?.experience;
  //should be dev hourly rate
  devData["hourlyRate"] = devData?.hourlyRate ?? 0;
  devData["sprintRate"] =
    devData?.hourlyRate > 0 ? (devData?.hourlyRate * 80).toFixed(2) : null;
  devData["monthlyBurn"] =
    devData?.hourlyRate > 0 ? (devData?.hourlyRate * 23 * 8).toFixed(2) : null;
  devData["devRef"] = GetRefFromPath(`devs/${devData?.devDocID}`);
  devData["partnerRef"] = GetRefFromPath(`vendors/${devData?.vendorID}`);

  async function addDevToTuc(tucIndex, positionIndex) {
    await addDocument(
      `${allTucPositions[tucIndex].tucPath}/POS-${positionIndex}`,
      {
        addedOn: GetNowTimeStamp(),
        devRef: devData["devRef"],
        name: devData["first"] + " " + devData["last"],
        notes: [],
        partnerRef: devData["partnerRef"],
        reviews: [],
        status: GetRefFromPath(
          `${
            allTucPositions[tucIndex].tucPath?.split("/tuc")[0]
          }/resourceStatuses/PROPOSED`
        ),
        tags: [],
      }
    );
    if (
      !tucsData[tucIndex].partners.find((ref) => devData?.vendorID == ref.id)
    ) {
      await GetRefFromPath(tucsData[tucIndex]?.path).update({
        partners: [...tucsData[tucIndex]?.partners, devData["partnerRef"]],
      });
    }
    setIsPicked(false);
  }

  async function onDevSelect() {
    setShowDevProfile({
      devData: devData,
      partnerData: devData["partnerData"],
      partnerHourlyRate: devData["partnerHourlyRate"],
    });
    setSelectedTabIndex(4);
  }

  async function fetchDevPositions() {
    let temp = {};
    await Promise.all(
      allTucPositions?.map(async (option, tucIndex) => {
        temp[tucIndex] = {};
        await Promise.all(
          option?.positions?.map(async (position, positionIndex) => {
            if (selectedUserRole === "partner" && !tucsData[tucIndex]?.open) {
              if (
                tucsData[tucIndex]?.sourceRT[`POS-${positionIndex + 1}`]?.find(
                  (ref) => devData?.vendorID == ref.id
                )
              ) {
                var tempDoc = await getCollectionRefFromPath(
                  option.tucPath + `/POS-${positionIndex + 1}`
                )
                  .where("devRef", "==", devData["devRef"])
                  .get();
                temp[tucIndex][`POS-${positionIndex + 1}`] =
                  tempDoc?.docs?.length == 0;
              } else {
                temp[tucIndex][`POS-${positionIndex + 1}`] = false;
              }
            } else if (
              selectedUserRole === "admin" ||
              tucsData[tucIndex]?.open
            ) {
              var tempDoc = await getCollectionRefFromPath(
                option.tucPath + `/POS-${positionIndex + 1}`
              )
                .where("devRef", "==", devData["devRef"])
                .get();
              temp[tucIndex][`POS-${positionIndex + 1}`] =
                tempDoc?.docs?.length == 0;
            }
          })
        );
      })
    );
    setDevPos(temp);
    // setIsPicked(true);
  }

  // convert time to hours ago
  const timeAgo = (time) => {
    const timeNow = new Date().getTime();
    const timeDiff = timeNow - time;
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
    // if hours is less than 24 return hours ago
    if (hoursAgo < 24) {
      return `${hoursAgo} hours ago`;
    }
    // if hours is more than 24 return days ago
    const daysAgo = Math.floor(hoursAgo / 24);
    return `${daysAgo} days ago`;
  };

  const profileProgress = (devData) => {
    let progress = 0;
    if (devData.profileProgress) {
      progress =
        (devData.profileProgress.stacksProgress || 0) * 0.125 +
        (devData.profileProgress.categoriesProgress || 0) * 0.125 +
        (devData.profileProgress.sectorsProgress || 0) * 0.125 +
        (devData.profileProgress.ratesProgress || 0) * 0.5 +
        0.125;
    }

    return progress;
  };

  // return (
  //   <SpDevCards
  //     devId={member?.ref?.id}
  //     sx={{ minWidth: "250px", position: "relative" }}
  //     wrappercls="_spSingleOptionMatch"
  //   >
  //     <div
  //       className={` ${isPicked ? "_spSingleOptionMatch--overlay" : ""}`}
  //     ></div>
  //     {/* <div className="_spSingleOptionMatch--rank">
  //       <div>
  //         <h3>Ranking</h3>
  //         <p>{timeAgo(member?.addedOn?.toDate())}</p>
  //       </div>
  //       <div>
  //         <p className="rankNumber">#{(member?.matchRank ?? 0) + 1}</p>
  //         <p>#AR14758</p>
  //       </div>
  //     </div> */}
  //     <DevProfile
  //       devData={devData}
  //       profileUrl={devData?.logoStorage}
  //       profileAlt={devData?.first}
  //       onSelect={() => onDevSelect()}
  //     />
  //     <DevStacks
  //       experience={devData?.experience / 12}
  //       stacks={devData?.stacks}
  //     />
  //     <DevsClients clients={devData?.clients} showNumber={4} />
  //     {devData?.hourlyRate && devData?.sprintRate && devData?.monthlyBurn ? (
  //       <DevRates
  //         hourlyRate={devData?.hourlyRate ?? " -"}
  //         sprint={
  //           devData?.sprintRate?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
  //         }
  //         month={
  //           devData?.monthlyBurn?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
  //         }
  //       />
  //     ) : null}
  //     {isPicked && (
  //       <div className="_spSingleOptionMatch--optionPicker">
  //         {allTucPositions.map((option, tucIndex) => {
  //           return Object.values(devPos?.[tucIndex] ?? {})?.includes(true) ? (
  //             <div
  //               key={tucIndex}
  //               className="_spSingleOptionMatch--optionPicker-optPosLists"
  //             >
  //               <h3>{option.name?.slice(0, 12)}</h3>
  //               {option.positions.map((position, positionIndex) => {
  //                 if (selectedUserRole == "admin" || tucsData[tucIndex].open) {
  //                   return (
  //                     <div
  //                       key={positionIndex}
  //                       className="_spSingleOptionMatch--optionPicker-optPosLists--position"
  //                       onClick={async () => {
  //                         devPos?.[tucIndex]?.[`POS-${positionIndex + 1}`] &&
  //                           (await addDevToTuc(tucIndex, position.posIndex));
  //                       }}
  //                     >
  //                       <p
  //                         style={{
  //                           backgroundColor: devPos?.[tucIndex]?.[
  //                             `POS-${positionIndex + 1}`
  //                           ]
  //                             ? null
  //                             : "#FFC7D5",
  //                         }}
  //                       >
  //                         {position?.label}
  //                       </p>
  //                     </div>
  //                   );
  //                 } else {
  //                   return tucsData[tucIndex]?.sourceRT[
  //                     `POS-${positionIndex + 1}`
  //                   ]?.find((ref) => devData?.vendorID == ref.id) ? (
  //                     <div
  //                       key={positionIndex}
  //                       className="_spSingleOptionMatch--optionPicker-optPosLists--position"
  //                       onClick={async () => {
  //                         devPos?.[tucIndex]?.[`POS-${positionIndex + 1}`] &&
  //                           (await addDevToTuc(tucIndex, position.posIndex));
  //                       }}
  //                     >
  //                       <p
  //                         style={{
  //                           backgroundColor: devPos?.[tucIndex]?.[
  //                             `POS-${positionIndex + 1}`
  //                           ]
  //                             ? null
  //                             : "#FFC7D5",
  //                         }}
  //                       >
  //                         {position.label}
  //                       </p>
  //                     </div>
  //                   ) : (
  //                     <></>
  //                   );
  //                 }
  //               })}
  //             </div>
  //           ) : (
  //             <></>
  //           );
  //         })}
  //       </div>
  //     )}
  //     <DevActions
  //       actionsType={true}
  //       cls={isPicked ? "_spSingleOptionMatch--actions" : ""}
  //       showChatBtn={false}
  //       showPickBtn={true}
  //       pickText={
  //         devData.status ? (!isPicked ? "Propose" : "Cancel") : "Inactive"
  //       }
  //       onPick={async () => {
  //         if (isPicked) {
  //           setIsPicked(false);
  //           return;
  //         } else {
  //           fetchDevPositions();
  //           setIsPicked(true);
  //         }
  //       }}
  //       pickDisabled={
  //         isOpenToVendor
  //           ? !devData.status ||
  //             !devData?.hourlyRate ||
  //             !devData?.sprintRate ||
  //             !devData?.monthlyBurn
  //           : !isOpenToVendor
  //       }
  //     />
  //   </SpDevCards>
  // );

  return (
    <SpMatchCard devId={devData?.id} wrappercls="spMatchCard">
      <DevProfile
        devData={devData}
        profileUrl={devData?.logoStorage}
        profileAlt={devData?.first}
        onSelect={() => onDevSelect()}
        showRates={false}
        // score={profileProgress(devData)}
        progressText="Profile Progress"
        progressValue={(profileProgress(devData) * 100).toFixed(0)}
        cls="spMatchCard--profile"
      />
      {/* <DevMatchCriteria
        technicalMeta={devData?.matchesDocSnap?.data()?.technicalMeta}
      /> */}

      {selectedUserRole !== "admin" ? (
        devData?.resume?.external ? (
          <div>
            <a
              href={
                devData?.resume?.externalRef ?? devData?.resumeData?.resumeUrl
              }
              target="_blank"
              rel="noreferrer"
            >
              View Resume
            </a>
          </div>
        ) : (
          <div>
            <p>Profile Under Review</p>
          </div>
        )
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px",
              backgroundColor: "#E1E1FF",
            }}
          >
            <a
              href={
                devData?.resume?.external
                  ? devData?.resume?.externalRef
                  : devData?.resumeData?.resumeUrl
              }
              target="_blank"
              rel="noreferrer"
            >
              View Resume
            </a>
          </div>
          {!devData?.resume?.external && (
            <div style={{ display: "flex", gap: "5px" }}>
              <div
                style={{
                  display: "flex",
                  // gap: "5px",
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#E1E1FF",
                }}
              >
                <Check style={{ marginTop: "2px" }} />
                <p
                  onClick={async () => {
                    await devData.ref?.update({ "resume.external": true });
                  }}
                >
                  Approve Resume
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // gap: "5px",
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#E1E1FF",
                }}
              >
                <Upload style={{ marginTop: "2px" }} />
                <p
                  onClick={async () => {
                    setDevDataToUpdate(devData);
                    setShowUpdateDev(true);
                  }}
                >
                  Upload New Resume
                </p>
              </div>
            </div>
          )}
        </>
      )}

      <DevStacks
        experience={devData?.experience / 12}
        stacks={devData?.stacks}
        title="Matching Expertise"
        experText="experience"
        showNumber={5}
        cls="spMatchCard--stacks"
        // showLessThanYear={false}
      />
      {/* {selectedUserRole !== "partner" && devData?.clients && (
        <DevsClients
          title="Prior Clients"
          imgHeight="75px"
          clients={devData?.clients}
          showNumber={1}
        />
      )} */}
      {devData?.hourlyRate && devData?.sprintRate && devData?.monthlyBurn ? (
        <DevRates
          titleRight=""
          pricePerc={null}
          hourlyRate={devData?.hourlyRate ?? " -"}
          sprint={
            devData?.sprintRate?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
          }
          month={
            devData?.monthlyBurn?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
          }
        />
      ) : null}
      {isPicked && (
        <div className="_spSingleOptionMatch--optionPicker">
          {allTucPositions.map((option, tucIndex) => {
            return (
              <div
                key={tucIndex}
                className="_spSingleOptionMatch--optionPicker-optPosLists"
              >
                <h3>{option.name}</h3>
                {option.positions.map((position, positionIndex) => {
                  return (
                    <div
                      key={positionIndex}
                      className="_spSingleOptionMatch--optionPicker-optPosLists--position"
                      onClick={async () => {
                        await addDevToTuc(tucIndex, position.posIndex);
                      }}
                    >
                      <p>{position.label}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      <DevActions
        actionsType={true}
        // cls={isPicked ? "_spSingleOptionMatch--actions" : ""}
        onChat={async () => {
          window.open(
            `/resource/${devData?.ref?.id}`,
            "_blank",
            "noopener,noreferrer"
          );
        }}
        chatText="View Profile"
        pickText={
          !devData?.hourlyRate || !devData?.sprintRate || !devData?.monthlyBurn
            ? "Rates N/A"
            : devData.status
            ? "Pick"
            : "Activate"
        }
        onPick={async () => {
          devData.status
            ? setIsPicked(!isPicked)
            : await devData?.ref?.update({ status: true });
        }}
        pickDisabled={
          // !devData.status ||
          !devData?.hourlyRate || !devData?.sprintRate || !devData?.monthlyBurn
        }
      />
    </SpMatchCard>
  );
};

export default PartnerDev;
