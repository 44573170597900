import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Text = styled.p`
  margin-bottom: 5px;
`;

const ReadMoreLink = styled.span`
  cursor: pointer;
  display: inline; /* Inline with the text */
`;

const SpTruncatedText = ({
  text,
  maxLines = 4,
  readMoreText = "Read More",
  lineHeight = 1.2,
  cls = "",
  onShowMore = () => {},
  showShowMore = true,
  showShowLess = true,
  showEllipsis = true,
  useLink = false,
  href = "/",
  linkProps = {},
}) => {
  const [displayedText, setDisplayedText] = useState(text);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (!text) return;
    if (textRef.current) {
      const computeTruncatedText = () => {
        // Clone the text node
        const clone = textRef.current.cloneNode(true);
        clone.style.position = "absolute";
        clone.style.visibility = "hidden";
        clone.style.width = textRef.current.offsetWidth + "px";
        document.body.appendChild(clone);

        // Set clone text to measure its height
        clone.innerText = text;

        // Compute the maximum height of the text based on the specified number of lines
        const maxHeight =
          lineHeight *
          (maxLines + 1) *
          parseInt(getComputedStyle(clone).fontSize, 10);

        // Determine if the text needs to be truncated
        let isTextTruncated = clone.clientHeight > maxHeight;
        let truncatedText = text;

        if (isTextTruncated) {
          const words = text.split(" ");
          // Adjust the clone text to fit the max height
          while (clone.clientHeight > maxHeight && words.length > 0) {
            words.pop(); // Remove words to make space for the ellipsis and Read More link
            truncatedText = words.join(" ") + (showEllipsis ? "..." : "");
            clone.innerText = truncatedText;
          }
        }

        // Clean up: remove the clone
        document.body.removeChild(clone);

        // Return both the truncated text and the truncation status
        return { truncatedText, isTextTruncated };
      };

      const { truncatedText, isTextTruncated } = computeTruncatedText();
      setDisplayedText(truncatedText);
      setIsTruncated(isTextTruncated);
    }
  }, [text, lineHeight, maxLines, showEllipsis, readMoreText]);

  const toggleTruncated = () => {
    onShowMore(!isTruncated);
    if (showShowLess) {
      setDisplayedText(text);
      setIsTruncated(!isTruncated);
    }
  };

  return (
    <Container className={`_spTruncatedText ${cls}`}>
      <Text ref={textRef} className={`_spTruncatedText--text ${cls}--text`}>
        {isTruncated ? displayedText : text}
        {!useLink ? (
          <a
            className={`_spTruncatedText--more ${cls}--more`}
            href={href}
            {...linkProps}
          >
            {readMoreText}
          </a>
        ) : (
          isTruncated && (
            <ReadMoreLink
              className={`_spTruncatedText--more ${cls}--more`}
              onClick={toggleTruncated}
            >
              {readMoreText}
            </ReadMoreLink>
          )
        )}
      </Text>
    </Container>
  );
};

export default SpTruncatedText;
