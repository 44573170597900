import React from "react";
import {
  Selection,
  Inject,
  Sort,
  Filter,
  Page,
  Edit,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import {
  ExcelExport,
  PdfExport,
  Group,
  Toolbar,
  Search,
} from "@syncfusion/ej2-react-grids";

/**
 * GridCert is the grid of the subscribed vendor page
 * @param {*} props are coming from SubsPage.js
 */
const GridCert = (props) => {
  return (
    <div className="certification contain">
      <GridComponent
        id="overviewgrid"
        className="data-grid"
        dataSource={props.certs}
        // ref={grid => this.gridInstance = grid}
        allowSorting={true}
        allowPaging={true}
        allowSelection={true}
        editSettings={props.editSettings}
        height="600"
        pageSettings={{ pageSize: 15 }}
        queryCellInfo={props.onQueryCellInfo}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="tag"
            headerText="Domain"
            width="120"
            textAlign="Center"
          ></ColumnDirective>

          <ColumnDirective
            field="totalDevs"
            headerText="Resources"
            width="120"
            textAlign="Center"
          ></ColumnDirective>

          <ColumnDirective
            field="totalExperience"
            headerText="Total Experience (Months)"
            width="120"
            textAlign="Center"
          ></ColumnDirective>

          <ColumnDirective
            field="totalProjects"
            headerText="Total Projects"
            width="130"
            textAlign="Center"
          />
          <ColumnDirective
            field="requirements"
            headerText="Number of Requirements"
            width="120"
            textAlign="Center"
          ></ColumnDirective>
          <ColumnDirective
            field="status"
            headerText="Status & Progress Score"
            width="120"
            textAlign="Center"
            allowFiltering={false}
            allowSorting={false}
            template={props.progessTemplate}
            format="C2"
          ></ColumnDirective>
          {/* <ColumnDirective field='score' headerText='Total Progress Score' width='120' textAlign='Center'></ColumnDirective> */}
          <ColumnDirective
            field="manualReviewState"
            headerText="Manual Review State"
            width="120"
            textAlign="Center"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject
          services={[
            Search,
            Selection,
            Page,
            Toolbar,
            Edit,
            Sort,
            Filter,
            ExcelExport,
            PdfExport,
            Group,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default GridCert;
