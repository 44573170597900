import React, { useState, useReducer, useEffect } from "react";
import PageHeader from "../../globals/PageHeader";
import ItemsList from "./components/ItemsList";

import AddDialog from "./components/AddDialog";

const initialState = {
  label: "",
  desc: "",
  id: "",
  formDesc: "",
  formSub: "",
  formTitle: "",
  dataPoints: [],
  letterGrade: false,
  formula: "",
};

const reducer = (state, action) => {
  let indexItems = { ...state };
  switch (action.type) {
    case "EDIT":
      indexItems[action.key] = action.value;
      return indexItems;
    case "RESET":
      indexItems = { ...action.data };
      return indexItems;
    default:
      return indexItems;
  }
};

function IndexItems({ firebase }) {
  const [indexItems, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fieldError, setFieldsError] = useState("");
  const [isRowSelected, setisRowSelected] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchedResult, setSearchedResult] = useState([]);
  const [data, setData] = useState([]);
  const indexItemPath = "/organisations/T6BAcTjwbXleCFpmWTmu/indexItems/";

  useEffect(() => {
    let sub = firebase.db
      .collection("/organisations/T6BAcTjwbXleCFpmWTmu/indexItems/")
      .onSnapshot((snap) => {
        // setData(snap?.docs);
        let data = snap.docs.map((item) => {
          let data = {
            ...item?.data(),
            ...item?.data()?.stats,
          };
          data["id"] = item?.id;
          data["ref"] = item?.ref;
          let dataPointsKeys = data.dataPoints?.length;
          data["formDesc"] = item?.data()?.form?.desc ?? "";
          data["formSub"] = item?.data()?.form?.subTitle ?? "";
          data["formTitle"] = item?.data()?.form?.title ?? "";
          data["dataPointsKeys"] = dataPointsKeys;
          data["max"] = data?.max ?? 0;
          data["min"] = data?.min ?? 0;
          data["wFactor"] = data?.wFactor ?? 0;
          data["count"] = data?.count ?? 0;
          data["isLetter"] = data?.letterGrade ? "Yes" : "No";
          return data;
        });
        setData(data);
      });
    return sub;
  }, []);

  const onClose = () => {
    setError("");
    setFieldsError("");
    setLoading(false);
    setIsAdd(false);
    setSearchedResult([]);
    setIsEdit(false);
    dispatch({ type: "RESET", data: initialState });
  };
  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };
  useEffect(() => {
    const checkLabel = async () => {
      setError("");
      if (!indexItems.label) return;
      let newId = camelize(indexItems.label);

      dispatch({ type: "EDIT", key: "id", value: newId });
      let docRef = await firebase.db.doc(`${indexItemPath}${newId}`).get();

      if (docRef.exists && docRef.id !== indexItems.id) {
        setError("exists already. Key must be unique");
      }
    };
    checkLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexItems.id, indexItems.label]);

  const onSubmit = () => {
    setLoading(true);
    const {
      formTitle,
      formSub,
      formDesc,
      id,
      ref,
      isLetter,
      ...indexItem
    } = indexItems;
    indexItem["form"] = {
      title: formTitle,
      desc: formDesc,
      subTitle: formSub,
    };

    if (isEdit) {
      // Edit
      ref.update(indexItem);
      onClose();
      return;
    }

    firebase.updateDocFromPath(`${indexItemPath}${id}`, indexItem);
    onClose();
  };

  const fetchSearchData = async (e) => {
    const dataDocs = await firebase.getDocumentsByArrayMembershipInCollection(
      "/organisations/T6BAcTjwbXleCFpmWTmu/dataPoints/",
      e.target.value,
      ["nameIndex"],
      "label",
      false,
      false,
      false,
      30
    );
    setSearchedResult(dataDocs);
  };

  const onDataPointSelect = (dataDoc) => {
    let dataPoints = [...indexItems?.dataPoints];
    let dataPoint = dataDoc.ref;

    let exist = dataPoints.filter((el) => el.id === dataPoint.id);
    if (exist.length > 0) return;

    dataPoints.push(dataPoint);
    dispatch({ type: "EDIT", key: "dataPoints", value: dataPoints });
  };

  const onRemoveDataPoint = (index) => {
    let dataPoints = [...indexItems?.dataPoints];
    dataPoints.splice(index, 1);
    dispatch({ type: "EDIT", key: "dataPoints", value: dataPoints });
  };

  const checkIfFilled = (dataIndex, error) => {
    if (error) return false;

    const { ref, ...indexItem } = dataIndex;
    let truthy = true;
    truthy = Object.values(indexItem).every((el) => {
      if (typeof el === "string" && el !== "") return true;

      if (Array.isArray(el) && el.length > 0) return true;

      if (typeof el === "boolean") return true;

      return false;
    });

    return truthy;
  };

  return (
    <div>
      {(isAdd || isEdit) && (
        <AddDialog
          onClose={onClose}
          data={indexItems}
          dispatch={dispatch}
          loading={loading}
          onSubmit={onSubmit}
          error={error}
          fieldsError={fieldError}
          fetchData={fetchSearchData}
          isTruthy={checkIfFilled}
          searchedResult={searchedResult}
          onDataPointSelect={onDataPointSelect}
          onRemoveDataPoint={onRemoveDataPoint}
        />
      )}
      <PageHeader header="Index Items" desc="Manage Spryte Index Items" />
      <div className="brand_body-btn" style={{ alignItems: "center" }}>
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => {
            dispatch({ type: "RESET", data: initialState });
            setIsAdd(true);
          }}
          style={{ margin: "10px 0" }}
        >
          Add
        </button>

        {isRowSelected ? (
          <button
            className="spbtn spbtn-blackbkg"
            onClick={() => {
              setIsEdit(true);
            }}
            style={{ margin: "10px 0" }}
          >
            Edit
          </button>
        ) : (
          <></>
        )}
      </div>
      <ItemsList
        // docsQuery={data}
        indexes={data}
        setisRowSelected={setisRowSelected}
        dispatch={dispatch}
        initialState={initialState}
      />
    </div>
  );
}

export default IndexItems;
