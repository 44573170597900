export default class SprintsList {
  constructor() {
    this.sprints = [];
  }

  /**
   * Return the size of the sprints list
   */
  size() {
    return this.sprints.length;
  }

  /**
   * add new sprint to the sprint list
   * @param {class} sprint
   */
  addSprint(sprint) {
    if (
      this.sprints.length >= 1 &&
      this.sprints.some((spt) => spt.TaskId === sprint.TaskId)
    ) {
      return;
    }

    this.sprints.push(sprint);
  }

  /**
   * Loops through the devs list in the sprints then push the new dev to the sprint
   * @param {object} dev
   */
  addDevToSprint(dev) {
    let sprintList = this.sprints;
    for (const sprint of sprintList) {
      sprint.addDev(dev);
    }
  }
  /**
   * return the list of sprints
   */
  getsprints() {
    return this.sprints;
  }
}
