import React from "react";
import { ReqTableBody, ReqTableHead, RequirementTable } from "./style";

const ReqTable = ({
  headContent,
  gridColumn = " repeat(3, 1fr)",

  children,
  ...props
}) => {
  return (
    <RequirementTable gridColumn={gridColumn} {...props}>
      {children}
    </RequirementTable>
  );
};

export default ReqTable;
