import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { OptionElWrapper, OptionIconWrapper, OptionWrapper } from "./style";

const SpOption = ({
  iconSize = 30,
  top = "0",
  left = "unset",
  bottom = "unset",
  right = "unset",
  height = "auto",
  width = "auto",
  bg = "white",
  borderRadius = "15px",
  boxShadow = "0px 0px 10px 0px rgba(0,0,0,0.2)",
  children,
  open,
  onOptionClick,
  cls = "sp-option",
  iconColor = "black",
  openIconColor = "#4361e9",
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [position, setPosition] = useState("bottom");
  const optionElRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const setInitialPosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - rect.bottom;
        const spaceAbove = rect.top;

        if (spaceBelow < 250 && spaceAbove > spaceBelow) {
          setPosition("top");
        } else {
          setPosition("bottom");
        }
      }
    };

    setInitialPosition();
    window.addEventListener("resize", setInitialPosition);

    return () => {
      window.removeEventListener("resize", setInitialPosition);
    };
  }, []);

  useEffect(() => {
    if (!open) setShowOptions(false);
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showOptions &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        optionElRef.current &&
        !optionElRef.current.contains(event.target)
      ) {
        setShowOptions(false);
        onOptionClick && onOptionClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions, onOptionClick]);

  const onOptionClickHandler = () => {
    const newShowOptions = !showOptions;
    onOptionClick && onOptionClick(newShowOptions);
    setShowOptions(newShowOptions);
  };

  return (
    <OptionWrapper ref={wrapperRef} className={`_spOptions ${cls}`}>
      <OptionIconWrapper
        onClick={onOptionClickHandler}
        className={`_spOptions_icon ${cls}_icon`}
      >
        <HiDotsVertical
          size={iconSize}
          color={open ? openIconColor : iconColor}
        />
      </OptionIconWrapper>
      <AnimatePresence>
        {showOptions && (
          <OptionElWrapper
            top={position === "top" ? "auto" : top}
            bottom={position === "top" ? "-10px" : bottom}
            left={left}
            bg={bg}
            width={width}
            height={height}
            right={right}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
            className={`_spOptions_body ${cls}_body`}
            animate={{ opacity: 1, y: position === "top" ? -10 : 10 }}
            initial={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            exit={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            ref={optionElRef}
          >
            {children}
          </OptionElWrapper>
        )}
      </AnimatePresence>
    </OptionWrapper>
  );
};

export default SpOption;
