import React from "react";

export default function devTypeTemplate(props) {
  return (
    <div id="devType" className="devType">
      <div
        id="devType_div"
        className="devType_div"
        style={{
          color:
            props.type === "PLEASE VERIFY"
              ? "#EB3570"
              : props.type === "RESOURCE"
              ? "#00CC00"
              : "#1E96F5",
        }}
      >
        <p style={{ fontSize: "1.2rem", fontWeight: 500, marginBottom: "0px" }}>
          {props.type}
        </p>
      </div>
    </div>
  );
}
