import React from "react";

const ShareLinkIcon = ({}) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4947 13.5071C16.4367 12.4496 15.002 11.8555 13.5061 11.8555C12.0101 11.8555 10.5754 12.4496 9.51739 13.5071L5.52743 17.4958C4.4694 18.5538 3.875 19.9888 3.875 21.4851C3.875 22.9814 4.4694 24.4164 5.52743 25.4744C6.58546 26.5325 8.02046 27.1269 9.51674 27.1269C11.013 27.1269 12.448 26.5325 13.5061 25.4744L15.5004 23.4801"
        stroke="#699BF7"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5059 17.4947C14.5639 18.5523 15.9986 19.1464 17.4945 19.1464C18.9905 19.1464 20.4252 18.5523 21.4832 17.4947L25.4732 13.5061C26.5312 12.448 27.1256 11.013 27.1256 9.51674C27.1256 8.02046 26.5312 6.58546 25.4732 5.52743C24.4151 4.4694 22.9801 3.875 21.4838 3.875C19.9876 3.875 18.5526 4.4694 17.4945 5.52743L15.5002 7.52176"
        stroke="#699BF7"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ShareLinkIcon;
