import React, { useEffect, useRef, useState } from "react";
import NewStack from "./newStack";
import NewDomain from "./newDomain";
import NewProj from "./newProj";
import { useStacks } from "../../../hooks/useTechs";
import { CircularProgress } from "@mui/material";
import SpDialog from "../../../globals/SpDialog";

const AddNew = (props) => {
  // Firebase storage
  const storage = props.firebase.getStorage();
  // const [isUpdating, setIsUpdating] = useState(false);
  const catRef = useRef(props.categoryId);

  const [techData, setTechData] = useState({
    techCategory: {},
    name: "",
    searchKeywords: "",
    rank: 0,
    subcategory: "",
    show: false,
    description: "",
    logoStorage: "",
    assessments: [],
  });
  const [newData, setNewData] = useState({
    category: {},
    name: "",
    searchKeywords: "",
    show: false,
    description: "",
  });
  const [logo, setLogo] = useState({});
  const [loading, setLoading] = useState(false);

  const resetStates = () => {
    setTechData({
      techCategory: {},
      name: "",
      searchKeywords: "",
      rank: 0,
      subcategory: "",
      show: false,
      description: "",
      logoStorage: "",
      assessments: [],
    });
    setNewData({
      category: {},
      name: "",
      searchKeywords: "",
      show: false,
      description: "",
    });
    setLogo({});
    setLoading(false);

    props.setOpenDialog(false);
    props.setIsEdit(false);
  };
  const show = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];

  if (props.openDialog || props.isEdit) {
    // close the modal when we click  outside the data-request_form box
    window.addEventListener("mouseup", (e) => {
      let subBox = document.getElementById("col_data-request_form");
      try {
        if (!subBox.contains(e.target)) {
          props.setOpenDialog(false);
          props.setIsEdit(false);
        }
      } catch (e) {
        return;
      }
    });
  }

  useEffect(() => {
    if (props.isTech && props.isEdit) {
      setTechData({ ...props.defaultData });
    } else if (!props.isTech && props.isEdit) {
      setNewData({ ...props.defaultData });
    } else {
      // setTechData({ ...techData });
      // setNewData({ ...newData });
    }
  }, [props.isEdit]);

  const selectLogo = (e) => {
    const file = e.target.files[0];
    if (file?.type?.includes("image")) {
      setLogo({ file: file, url: URL.createObjectURL(file) });
    }
  };

  const uploadLogo = async (file, name) => {
    if (file) {
      let fileExt = file.type.replace("image/", "");
      let url;
      switch (name) {
        case "techStacks150r":
          const ref = await storage
            .ref(`techStacks/${techData.name}.${fileExt}`)
            .put(file);
          url = await ref.ref.getDownloadURL();
          return url;

        case "sectors":
          const domRef = await storage
            .ref(`sectors/${newData.name}.${fileExt}`)
            .put(file);
          url = await domRef.ref.getDownloadURL();
          return [url, `${newData.name}.${fileExt}`];

        case "subSectors":
          const subRef = await storage
            .ref(
              `sectors/${props.selectedSector.name}/${newData.name}.${fileExt}`
            )
            .put(file);
          url = await subRef.ref.getDownloadURL();
          return [url, `${newData.name}.${fileExt}`];
        case "categories":
          const projRef = await storage
            .ref(`projectCategories/${newData.name}.png`)
            .put(file);
          url = await projRef.ref.getDownloadURL();
          return [url, `${newData.name}`];

        default:
          break;
      }
    }
  };

  /**
   * trigger when user click on add button to add new data
   * it will check the collection name  and push the data to firebase
   * @param {string} name collection name
   */
  const addNewData = async (name) => {
    const { techCategory, ...stackData } = techData; // if it's a techStack
    const { category, ...others } = newData;
    let techId = techCategory?.value ?? category?.value;

    let data = name === "techStacks150r" ? stackData : others;
    if (data.name) {
      setLoading(true);
      uploadLogo(logo.file, props.collection).then(async (url) => {
        //if the collection is not stacks we will take the returned url for the logo storage
        data["logoStorage"] = name !== "techStacks150r" ? url[0] : url;
        if (name === "techStacks150r") data["title"] = data.name; //if it's in the subsector we will add title

        if (name === "sectors" || name === "subSectors") data["image"] = url[1];
        if (name === "categories") data["iconImage"] = url[1];

        if (name === "subSectors") {
          await props.firebase.createDocumentFromPath(
            `${props?.selectedSector?.ref?.path}/subSectors`,
            data
          );
          setLoading(false);
          props.setOpenDialog(false);
          return;
        }
        await props.firebase.addOrEditTechs(props.collection, techId, data);
        setLoading(false);
        props.setOpenDialog(false);
      });
    }
  };

  /**
   * triggers when vendor click on save data after clicking on the edit button
   * @param {string} name collection name of the editing data
   */
  const editData = async (name) => {
    let theData = techData.name ? techData : newData;
    const {
      catIndex,
      techCategory,
      category,
      popularQueries,
      nameIndex,
      stats,
      catData,
      catId,
      owner,
      ref,
      ...data
    } = theData;
    console.log(techData, "techData");
    console.log(theData, "theData");

    if (!logo.file) {
      setLoading(true);
      if (props.collection === "subSectors") {
        // props.firebase.updateDocFromPath(
        //   `${props.selectedSector?.ref?.path}/subSectors/${data.id}`,
        //   data
        // );
        await ref.update(data);

        setLoading(false);
        props.setIsEdit(false);
        return;
      }

      await ref.update(data);
      setLoading(false);
      props.setIsEdit(false);
      props.setOpenDialog(false);
    } else {
      setLoading(true);
      uploadLogo(logo.file, props.collection).then(async (url) => {
        data["logoStorage"] = name !== "techStacks150r" ? url[0] : url;
        if (name === "techStacks150r") data["title"] = data.name;
        if (name === "sectors" || name === "subSectors") data["image"] = url[1];
        if (name === "categories") data["iconImage"] = url[1];
        if (props.collection === "subSectors") {
          // props.firebase.updateDocFromPath(
          //   `${props.selectedSector?.ref?.path}/subSectors/${data.id}`,
          //   data
          // );
          await ref.update(data);
          setLoading(false);
          props.setIsEdit(false);
          return;
        }
        // props.firebase
        //   .addOrEditTechs(props.collection, catRef.current, data, data.id)
        //   .then((_) => {
        //     setLoading(false);
        //     props.setIsEdit(false);
        //     props.setOpenDialog(false);
        //   });

        await ref.update(data);
        setLoading(false);
        props.setIsEdit(false);
        props.setOpenDialog(false);
      });
    }
  };

  /**
   * trigger when vendor click on edit button
   * @param {string} name collection name
   */
  const onSave = async (name) => {
    if (!props.isEdit) {
      if (logo.file) await addNewData(name);
      props.refreshData();
    } else {
      await editData(name);
      props.refreshData();
    }
  };
  /**
   * Switch between components dependants on the collection name
   * @returns the component to use for editing or adding new data
   */
  const getNew = () => {
    switch (props.collection) {
      case "techStacks150r":
        return (
          <NewStack
            techsCategories={props.techsCategories}
            setTechData={setTechData}
            techData={techData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            // toggleAssessment={toggleAssessment}
            show={show}
            defaultData={props.defaultData}
            searchAssessments={props.searchAssessments} // Pass searchAssessments prop
            searchData={props.searchData} // Pass searchData prop
            setSearchData={props.setSearchData} // Pass setSearchData prop
            showDropdown={props.showDropdown} // Pass showDropdown prop
            setShowDropdown={props.setShowDropdown}
            onToggleAssessment={props.onToggleAssessment}
          />
        );

      case "categories":
        return (
          <NewProj
            techsCategories={props.techsCategories}
            setNewData={setNewData}
            newData={newData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            show={show}
          />
        );

      default:
        return (
          <NewDomain
            techsCategories={props.techsCategories}
            setNewData={setNewData}
            newData={newData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            show={show}
            isSub={props?.isSub ?? false}
          />
        );
    }
  };

  return (
    <SpDialog
      open={props?.open}
      locked={true}
      // className="request addNew"
      // id="col_data-edit"
      onClose={props?.onClose}
      useCustomBox={true}
    >
      <div
        className="collectionModal column modal-content"
        id="col_data-request_form"
      >
        {getNew()}
        <div className="request_form-action row">
          {loading ? (
            <CircularProgress color="primary" size="small" />
          ) : (
            <button
              className="spbtn spbtn-sub"
              onClick={() => {
                onSave(props.collection);
              }}
            >
              Save
            </button>
          )}
          <button className="spbtn spbtn-canc" onClick={resetStates}>
            Cancel
          </button>
        </div>
      </div>
    </SpDialog>
  );
};

export default AddNew;
