import React from "react";
import { SampleBase } from "../../sample-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default class Legal extends SampleBase {
  constructor() {
    super(...arguments);
    this.publicCompanyDropDown = ["YES", "NO"];
    this.dropDownModel = {
      dataSource: this.publicCompanyDropDown,
    };
  }

  // Update vendor function to allow user to edit data
  // in syncfusion inplaceeditor component
  updateVendorData(data) {
    const updates = {};
    updates.legal = this.props.legal;
    updates.legal[data.name] = data.value;
    updates.legal.lastUpdated = new Date();
    try {
      this.props.firebase.updateVendor(this.props.vendor.id, updates);
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <div className="stat">
        <div className="circle"></div>
        <div className="stat_header">
          <div className="title">
            <h1>Payment & Legal Filings</h1>
            <FontAwesomeIcon icon={faBalanceScale} className="icon" />
          </div>
          <div className="desc-icon">
          <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
          <div className="description">
            Audit information related to your business standing and prior legal
            and/or collection events
          </div>
        </div>
        </div>

        <div className="stat_content">
          <div className="stat_content_data stat_content_rev row">
            <div className="stat_content_data-title">
              <h4>Legal Filings</h4>
            </div>

            <div className="stat_content_data-content">
              <div className="column">
                <div className="row top">
                  <label>Bankruptcy</label>
                  <p>{this.props.legal.bankruptcy}</p>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    Any prior Bankruptcy filings? You are due to inform Spryte
                    of any prior bankruptcy if this data is incorrect
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label>Tax Lien Filings</label>
                  <p>{this.props.legal.taxLienFilings}</p>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    Any prior liens against your business from tax collection
                    agencies? You are due to inform Spryte if this data is
                    incorrect
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label>Judgement Filings</label>
                  <p>{this.props.legal.judgementFilings}</p>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    Any prior legal filings against your business? You are due
                    to inform Spryte if this data is incorrect
                  </p>
                </div>
              </div>

              <div className="column">
                <div className="row top">
                  <label>Collections Count</label>
                  <p>{this.props.legal.collectionsCount}</p>
                </div>
                <div className="bottom light">
                  <p>
                    {" "}
                    Collection counts against your business. You are due to
                    inform Spryte if this data is incorrect
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="row top">
                  <label>Last Updated</label>
                  <p>{this.props?.legal?.lastUpdated}</p>
                </div>
                <div className="bottom light">
                  <p> The last time this information was updated</p>
                </div>
              </div>

              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
