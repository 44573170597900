import React from "react";

const CollapseIcon = ({
  size = 42,
  fill = "white",
  stroke = "#4361E9",
  line = "#4361E9",
  onClick = null,
  cls,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={cls}
    >
      <circle
        cx="21"
        cy="21"
        r="20.5"
        transform="rotate(180 21 21)"
        fill="white"
        stroke="#4361E9"
      />
      <line
        x1="21"
        y1="24.5858"
        x2="28.5858"
        y2="17"
        stroke="#4361E9"
        stroke-width="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11.7279"
        y2="-1"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 21 26)"
        stroke="#4361E9"
        stroke-width="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CollapseIcon;
