import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: ${({ isselected }) => (isselected ? "white" : "black")};
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0;
  margin-left: 1rem;
  border: 2px solid rgba(128, 128, 128, 0.35);
  color: ${({ isselected }) => (isselected ? "black" : "white")};

  &:last-child {
    margin-right: 1rem;
  }
`;

const PagesList = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const useDynamicPaginator = (allData, pageLimit = 10) => {
  const [viewingIndex, setViewingIndex] = useState(0);

  const pageCount = useMemo(
    () => Math.ceil(allData.length / pageLimit),
    [allData, pageLimit]
  );

  const currentData = useMemo(() => {
    const firstPageIndex = viewingIndex * pageLimit;
    const lastPageIndex = firstPageIndex + pageLimit;
    return allData.slice(firstPageIndex, lastPageIndex);
  }, [allData, pageLimit, viewingIndex]);

  const onNext = useCallback(() => {
    setViewingIndex((prev) => (prev < pageCount - 1 ? prev + 1 : 0));
  }, [pageCount]);

  const onPrevious = useCallback(() => {
    setViewingIndex((prev) => (prev > 0 ? prev - 1 : pageCount - 1));
  }, [pageCount]);

  const goToPage = useCallback((pageNum) => {
    setViewingIndex(pageNum);
  }, []);

  const renderButtons = useCallback(() => {
    let buttons = [];

    // Always add the first page button
    buttons.push(
      <Button
        key={0}
        onClick={() => goToPage(0)}
        isselected={viewingIndex === 0}
      >
        {1}
      </Button>
    );

    // Add the selected page button if it's not the first or last page
    if (viewingIndex !== 0 && viewingIndex !== pageCount - 1) {
      buttons.push(
        <Button
          key={viewingIndex}
          onClick={() => goToPage(viewingIndex)}
          isselected={true}
        >
          {viewingIndex + 1}
        </Button>
      );
    }

    // Always add the last page button if there's more than one page
    if (pageCount > 1) {
      buttons.push(
        <Button
          key={pageCount - 1}
          onClick={() => goToPage(pageCount - 1)}
          isselected={viewingIndex === pageCount - 1}
        >
          {pageCount}
        </Button>
      );
    }

    return (
      <PagesList>
        <Button onClick={onPrevious} disabled={viewingIndex === 0}>
          Prev
        </Button>
        {buttons}
        <Button onClick={onNext} disabled={viewingIndex === pageCount - 1}>
          Next
        </Button>
      </PagesList>
    );
  }, [viewingIndex, pageCount, onPrevious, onNext, goToPage]);

  return {
    onNext,
    onPrevious,
    viewingIndex,
    setViewingIndex,
    renderButtons,
    currentData,
  };
};
