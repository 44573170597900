import React from "react";
import FormInput from "../../../globals/FormInput";
import SpLabelInfo from "../../../SpLabelInfo";

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export default function AddDialog({
  onSubmit = null,
  loading = false,
  onClose = null,
  data = null,
  dispatch = null,
  error,
  fieldsError,
  fetchData = null,
  searchedResult = [],
  onDataPointSelect,
  onRemoveDataPoint,
  isTruthy,
}) {
  return (
    <div className="request">
      <div
        className="request_form column ctr"
        id="data-request_form"
        style={{
          width: "50vw",
          padding: "25px 65px",
          maxHeight: "80vh",
          top: "50vh",
        }}
      >
        <div className="request_form-content  column">
          <div>
            <div>
              <FormInput
                label="Label"
                desc="Data Point labels must be unique and in lower camelCase (no spaces)"
                value={data?.label}
                onChange={(e) =>
                  dispatch({
                    type: "EDIT",
                    key: "label",
                    value: e.target.value,
                  })
                }
              />
              {data?.id && (
                <div style={{ marginTop: "1rem" }}>
                  <p style={{ fontSize: "1.2rem" }}>
                    Key: {data?.id}{" "}
                    <span style={{ color: "#eb3570" }}>{error}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <FormInput
            label="Description"
            desc="Help users understand the question with description text"
            value={data?.desc}
            isInput={false}
            onChange={(e) =>
              dispatch({ type: "EDIT", key: "desc", value: e.target.value })
            }
          />
          <FormInput
            label="Form Title"
            desc="Data Point labels must be unique and in lower camelCase (no spaces)"
            value={data?.formTitle}
            onChange={(e) =>
              dispatch({
                type: "EDIT",
                key: "formTitle",
                value: e.target.value,
              })
            }
          />
          <FormInput
            label="Form Sub-Title"
            desc="Data Point labels must be unique and in lower camelCase (no spaces)"
            value={data?.formSub}
            onChange={(e) =>
              dispatch({
                type: "EDIT",
                key: "formSub",
                value: e.target.value,
              })
            }
          />

          <FormInput
            label="Form Description"
            desc="This is the form description"
            value={data?.formDesc}
            isInput={false}
            onChange={(e) =>
              dispatch({ type: "EDIT", key: "formDesc", value: e.target.value })
            }
          />
          <div className="col_3_input">
            <div className="formInput">
              <SpLabelInfo
                title="Letter Grade"
                description="Select the question type"
              />

              <select
                name="status"
                id="expboxinput"
                value={data?.letterGrade ? "Yes" : "No"}
                onChange={(e) => {
                  dispatch({
                    type: "EDIT",
                    key: "letterGrade",
                    value: e.target.value === "Yes" ? true : false,
                  });
                  e.persist();
                }}
              >
                {options.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="formInput dataSearch">
              <SpLabelInfo
                title="Data Points"
                description="Select the data points belonging to this index items"
              />
              {searchedResult.length > 0 && (
                <div className="dataSearch_searchedValues">
                  {searchedResult.map((doc, i) => {
                    return (
                      <p
                        className="dataSearch_searchedValues-value"
                        onClick={() => onDataPointSelect(doc)}
                      >
                        {doc?.data()?.label}
                      </p>
                    );
                  })}
                </div>
              )}

              <div>
                <input type="search" onChange={fetchData} id="expboxinput" />
              </div>
            </div>
            {data.dataPoints.length > 0 && (
              <div
                className="dataSearch_selectedValues row"
                style={{ paddingTop: "1rem" }}
              >
                {data.dataPoints.map((dataPoint, index) => {
                  return (
                    <div
                      className="selectedStackItem"
                      key={`${dataPoint?.id}_${index}`}
                    >
                      <div
                        className="close"
                        onClick={() => onRemoveDataPoint(index)}
                      >
                        <h3>X</h3>
                      </div>
                      <p style={{ margin: "0" }}>{dataPoint?.id}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <FormInput
          label="Formula"
          desc="Data Point labels must be unique and in lower camelCase (no spaces)"
          value={data?.formula}
          onChange={(e) =>
            dispatch({
              type: "EDIT",
              key: "formula",
              value: e.target.value,
            })
          }
        />
        {fieldsError && (
          <div style={{ marginTop: "1rem" }}>
            <p style={{ fontSize: "1.2rem", color: "#eb3570" }}>
              {fieldsError}
            </p>
          </div>
        )}
        <div className="request_form-action row">
          {isTruthy(data, error) && (
            <button
              id="add"
              onClick={() => {
                onSubmit();
              }}
              disabled={loading}
            >
              Save
            </button>
          )}
          <button
            id="cancel"
            style={{ padding: "5px 10px", fontWeight: "600" }}
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
