import React from "react";

const LocIcon = ({ size = 18, fill = "#808080" }) => {
  return (
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39767 14.8008L9.67978 15.3304H9.67978L9.39767 14.8008ZM8.60232 14.8008L8.32022 15.3304H8.32022L8.60232 14.8008ZM13.65 8.25C13.65 9.8674 12.8495 11.2007 11.851 12.2359C10.8522 13.2714 9.70001 13.96 9.11557 14.2713L9.67978 15.3304C10.3193 14.9897 11.5933 14.2316 12.7147 13.069C13.8363 11.9062 14.85 10.2901 14.85 8.25H13.65ZM9 3.6C11.5681 3.6 13.65 5.68188 13.65 8.25H14.85C14.85 5.01913 12.2309 2.4 9 2.4V3.6ZM4.35 8.25C4.35 5.68188 6.43188 3.6 9 3.6V2.4C5.76913 2.4 3.15 5.01913 3.15 8.25H4.35ZM8.88443 14.2713C8.29998 13.96 7.14782 13.2714 6.14902 12.2359C5.15046 11.2007 4.35 9.8674 4.35 8.25H3.15C3.15 10.2901 4.16372 11.9062 5.28533 13.069C6.40671 14.2316 7.6807 14.9897 8.32022 15.3304L8.88443 14.2713ZM9.11557 14.2713C9.04051 14.3113 8.95949 14.3113 8.88443 14.2713L8.32022 15.3304C8.74796 15.5583 9.25203 15.5583 9.67978 15.3304L9.11557 14.2713ZM10.65 8.25C10.65 9.16127 9.91127 9.9 9 9.9V11.1C10.574 11.1 11.85 9.82401 11.85 8.25H10.65ZM9 6.6C9.91127 6.6 10.65 7.33873 10.65 8.25H11.85C11.85 6.67599 10.574 5.4 9 5.4V6.6ZM7.35 8.25C7.35 7.33873 8.08873 6.6 9 6.6V5.4C7.42599 5.4 6.15 6.67599 6.15 8.25H7.35ZM9 9.9C8.08873 9.9 7.35 9.16127 7.35 8.25H6.15C6.15 9.82401 7.42599 11.1 9 11.1V9.9Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocIcon;
