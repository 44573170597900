import styled from "styled-components";
import { spPrimaryColor } from "../../stylesConstant";

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction};
  // flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 327px) {
    flex-direction: column;
    align-items: center;
    button {
      flex: 100%;
      width: 100% !important;
    }
  }
  button {
    flex: 50%;
    min-width: 98px;
    border-radius: 0px !important;
  }
  button:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #484848 !important;
    span {
      color: #484848;
      font-weight: 700;
      font-size: 16px;
    }
  }
  button:last-child {
    background-color: ${spPrimaryColor} !important;
    border: 1px solid ${spPrimaryColor} !important;
    color: white !important;
    &:disabled {
      background-color: ${spPrimaryColor} !important;
    }
  }
`;
