import React from "react";

const UploadIcon = ({ w = "34", h = "34", fill = "#545454" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5156 20.6641H18.2656V12.4141H22.3906L16.8906 5.53906L11.3906 12.4141H15.5156V20.6641Z"
        fill={fill}
      />
      <path
        d="M27.8906 24.7891H5.89062V15.1641H3.14062V24.7891C3.14062 26.3057 4.374 27.5391 5.89062 27.5391H27.8906C29.4072 27.5391 30.6406 26.3057 30.6406 24.7891V15.1641H27.8906V24.7891Z"
        fill={fill}
      />
    </svg>
  );
};

export default UploadIcon;
