import React from "react";
// import Carousel from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";

// need a caroussel

/**
 * LogoCarou is the Caroussel Displaying subscribed companies
 * @param {*} props are coming from UnSubs.js
 */
const LogosCarou = (props) => {
  /**
   * returnedEl is a function that return a list of the images to be displayed in the caroussel
   * @param {[]} subscribers [] list of the clients logo images that have the same filtered domains
   */
  const returnedEl = (subscribers) => {
    let el = [];
    if (subscribers.length > 0) {
      // eslint-disable-next-line array-callback-return
      subscribers.map((sub, index) => {
        el.push(
          <img key={index} alt="" className="logoClient" src={sub.logoUrl} />
        );
      });
    }
    return el;
  };

  /**
   * Caroussel hold the visual of the caroussel of each domain
   * We will map through the list of domains from the provider
   * then filter from the subscriber to organise based on the domains
   * Ie: it will look through the list of subscriber objects and return all the companie that have finance
   * then we will loop through the list of the returned domain to get the logoUrl and add them to the caroussel
   */
  let caroussel = props.domains.map((domain, index) => {
    const subscribers = props.subs.filter((data) => {
      let domainLower = domain.toLowerCase();
      let val = "";
      data.domains.forEach((dom) => {
        if (dom === domain) {
          val = dom;
        }
      });
      return val.toLowerCase().includes(domainLower);
    });
    let theList = returnedEl(subscribers);
    return (
      <div
        key={index}
        className={
          subscribers.length > 0 ? "unsubs-caroussel_data--content" : "hidCa"
        }
      >
        <h5 className="unsubs-caroussel_data--content__title">
          {subscribers.length > 0 ? domain : ""}
        </h5>

        {/* {theList.length < 1 ? (
          <div style={{ height: "90px" }}></div>
        ) : (
          <Carousel
            key={index}
            slidesPerPage={subscribers.length < 2 ? 1 : 2}
            autoPlay={20000}
            arrows
            infinite
            // centered
            animationSpeed={3000}
          >
            {theList}
          </Carousel>
        )} */}
      </div>
    );
  });

  return <div className="unsubs-caroussel_data">{caroussel}</div>;
};

export default LogosCarou;
