import React, { useEffect, useState } from "react";
import Badges from "./Badges";

import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";

const Marketing = (props) => {
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [settings, setSettings] = useState({});
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    let componentMounted = true;
    const getData = async () => {
      if (componentMounted) {
        let imgList = [
          ".jpg",
          ".JPG",
          ".png",
          ".PNG",
          ".jpeg",
          ".JPEG",
          ".gif",
          "GIF",
        ];
        let storage = props.firebase.getStorage();
        // Create a reference under which you want to list
        let listRef = storage.ref(`vendors/${props.vendor.id}/badges/`);
        let badgesList = [];

        // Find all the prefixes and items.
        let res = await listRef.listAll();
        for (const item of res.items) {
          // All the items under listRef.

          let url = await item.getDownloadURL();

          for (const ext of imgList) {
            let isExt = item.name.includes(ext);
            if (isExt) {
              let badgeData = {
                name: item.name.replace(ext, ""),
                url: url,
              };
              badgesList.push(badgeData);
            }
          }
        }

        // states
        setBadges(badgesList);

        setVendorId(props.vendor.id);
        setSettings(props.vendor.settings);
        setVendorName(props.vendor.companyName);
      }
    };
    getData();

    return () => {
      componentMounted = false;
    };
  }, [
    props.firebase,
    props?.vendor?.companyName,
    props?.vendor?.id,
    props?.vendor?.settings,
  ]);

  const updateVendorData = (name, value) => {
    const updates = {};
    updates.settings = { ...settings };
    updates.settings["marketing"]["linkbackSettings"][name] = value;

    try {
      props.firebase.updateVendor(vendorId, updates);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="stat_content_data stat_content_rev row marketing">
      <div className="stat_content_data-title">
        <h4>Marketing</h4>
      </div>

      <div className="stat_content_data-content">
        {vendorId && badges.length > 0 ? (
          <div className="column">
            <div className="row top">
              <label className="vendor-info">Badges</label>
            </div>
            <div className="bottom">
              <p>
                {" "}
                You can embed these badges on your own website for link-referral
                tracking and back-linking SEO benefits. Please copy the code
                below and uses AS-IS (with no modifications) on your wbesite in
                order to conform to our Partner Terms and Conditions.
              </p>
            </div>
            <div>
              <Badges
                badges={badges}
                firebase={props.firebase}
                vendorId={vendorId}
                vendorName={vendorName}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="column">
          <div className="row">
            <label className="vendor-info">LinkBack Marketing Settings</label>
          </div>
          <div className="row top">
            <div>
              <label className="vendor-info" style={{ marginRight: "12px" }}>
                UTM_SOURCE:
              </label>
              <InPlaceEditorComponent
                className="inPlaceEditor"
                actionBegin={(e) => updateVendorData(e.data.name, e.data.value)}
                id="textboxEle"
                name="utmSource"
                mode="Inline"
                type="Text"
                value={
                  settings?.marketing?.linkbackSettings?.utmSource ??
                  " SpryteLabs"
                }
              ></InPlaceEditorComponent>
            </div>
            <div>
              <label className="vendor-info" style={{ marginRight: "12px" }}>
                UTM_MEDIUM:
              </label>
              <InPlaceEditorComponent
                className="inPlaceEditor"
                actionBegin={(e) => updateVendorData(e.data.name, e.data.value)}
                id="textboxEle"
                name="utmMedium"
                mode="Inline"
                type="Text"
                value={
                  settings?.marketing?.linkbackSettings?.utmMedium ?? "referral"
                }
              ></InPlaceEditorComponent>
            </div>
            <div>
              <label className="vendor-info" style={{ marginRight: "12px" }}>
                UTM_CAMPAIGN:
              </label>
              <InPlaceEditorComponent
                className="inPlaceEditor"
                actionBegin={(e) => updateVendorData(e.data.name, e.data.value)}
                id="textboxEle"
                name="utmCampaign"
                mode="Inline"
                type="Text"
                value={
                  settings?.marketing?.linkbackSettings?.utmCampaign ??
                  "profile"
                }
              ></InPlaceEditorComponent>
            </div>
            <div>
              <label className="vendor-info" style={{ marginRight: "12px" }}>
                UTM_CONTENT:
              </label>
              <InPlaceEditorComponent
                className="inPlaceEditor"
                actionBegin={(e) => updateVendorData(e.data.name, e.data.value)}
                id="textboxEle"
                name="utmContent"
                mode="Inline"
                type="Text"
                value={
                  settings?.marketing?.linkbackSettings?.utmContent ?? null
                }
              ></InPlaceEditorComponent>
            </div>
          </div>
          <div className="bottom light">
            <p>
              We will embed your badges in the same manner on your Spryte Labs
              Public Company Profile page, which will allow your marketing team
              to track incoming users into your site from Spryte Labs. You can
              set your own UTM parameters below to match your marketing needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
