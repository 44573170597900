import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Testimonia from "./reviews/testimonials";

export default function Reviews(props) {
  // Update vendor function to allow user to edit data
  // in syncfusion inplaceeditor component

  return (
    <div className="stat reviews">
      <div className="circle"></div>

      <div className="stat_header">
        <div className="title">
          <h1>Client Reviews</h1>
        </div>
        <div className="desc-icon">
          <FontAwesomeIcon icon={faInfoCircle} className="descIcon" />
          <div className="description">
            Positive client reviews is one of the major contributors to your
            Spryte score, which in turns affects how your teams are ranked
            against others
          </div>
        </div>
      </div>

      <div className="stat_content">
        <div className="stat_content_data stat_content_rev row">
          <div className="stat_content_data-title">
            <h4>Reviews</h4>
          </div>

          <div className="stat_content_data-content">
            <div className="column">
              <div className="row top">
                <label className="vendor-info">Verified Client Reviews</label>
                <p>
                  {props?.reviews?.verifiedClientReviews
                    ? props?.reviews?.verifiedClientReviews
                    : 0}
                </p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  We accept third party reviews from existing sites, discuss
                  this with your account manager
                </p>
              </div>
            </div>

            <div className="column">
              <div className="row top">
                <label className="vendor-info">Overall Review Rating</label>
                <p>{props?.reviews?.overallReviewRating}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  This is the review component score which is used in
                  determining your overall score
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ====================================================== */}
        <Testimonia
          reviews={props?.reviews}
          vendor={props.vendor}
          firebase={props.firebase}
          isVendorUpdated={props.updateVendor}
          isInitUpdated={props.updateInitialData}
        />

        {/* ================================================= */}
        <div className="stat_content_data stat_content_rev row">
          <div className="stat_content_data-title">
            <h4>Other</h4>
          </div>

          <div className="stat_content_data-content">
            <div className="column">
              <div className="row top">
                <label className="vendor-info">Source</label>
                <p>{props?.reviews.source}</p>
              </div>
              <div className="bottom light">
                <p>
                  {" "}
                  The sources of your current review collection. We allow
                  internal reviews as well as third party reviews
                </p>
              </div>
            </div>
            <div className="column">
              <div className="row top">
                <label className="vendor-info">Last Updated</label>
                <p>{props?.reviews?.lastUpdated}</p>
              </div>
              <div className="bottom light">
                <p> The last time this information was updated</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
