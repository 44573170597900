import React from "react";

import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { SampleBase } from "./sample-base.js";
import RatingButton from "./Rating/RatingButton";
import {
  Finance,
  Verification,
  Legal,
  Reviews,
  Settings,
  AccManagement,
} from "./pages/Account";
import { CompanyLogo, BioEdit } from "./pages/Account";
import ProgressBar from "react-customizable-progressbar";
import { GlobalVendorContex } from "./Firebase";
// import { analytics } from "./Firebase/firebase";
import { MdEdit } from "react-icons/md";

export default class Account extends SampleBase {
  static contextType = GlobalVendorContex;
  constructor(props) {
    super(...arguments);

    this.state = {
      user: {},
      vendor: {},
      vendorPublic: {},
      profile: "",
      financial: {},
      verification: {},
      legal: {},
      reviews: {},
      logo: {},
      isBioTruncated: true,
      bioButton: "",
      bioTruncated: "",
      isLoading: true,
      loading: true,
      vendorProfileProgress: 0,
      managerDetails: {},
      managersList: [],
      bookableCount: 0,
      totalDevsCount: 0,
    };
    this.numericModel = {
      min: 0,
      format: "N0",
    };
    this.dollarModel = {
      format: "C2",
      min: 0,
      step: 0.01,
    };
    this.percentModel = {
      format: "P2",
      min: 0,
      max: 1,
      step: 0.01,
    };
  }

  //
  //
  //
  ///////// ///////////////////////////////////// Get Vendor Data /////////////////////////////////////////////////////////////////////////
  //
  getData = async () => {
    const vend = this.vendor;
    const vendor = await vend?.searchedVendor;
    // const vendor = await this.props.firebase.getCurrentVendorDoc()
    const managerDoc = await this.props.firebase.db
      .collection("users")
      .doc(vendor?.manager?.id)
      .get();
    const managerDetails = managerDoc.data();

    this.setState({ isLoading: true });
    const user = await this.props.firebase.getCurrentUserDoc();
    const vendorPublic = await this.props.firebase.getVendorPublicData(
      vendor.id
    );

    // Creating the admin role ref
    const superAdmin = this.props.firebase.db
      .collection(
        "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/"
      )
      .doc("SUPER-ADMIN");

    // Creating the partner-admin role ref
    const partnerAdmin = this.props.firebase.db
      .collection(
        "userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/"
      )
      .doc("PARTNER-ADMIN");
    if (this.props.isSuper) {
      // Streaming managers data
      this.managers = this.props.firebase.db
        .collectionGroup("accounts")
        .where("role", "in", [superAdmin, partnerAdmin])
        .onSnapshot(async (snap) => {
          let managersList = [];
          for (const doc of snap.docs) {
            let userDoc = await doc.ref.parent.parent.get();
            let userData = userDoc?.data() ?? {};
            userData["id"] = userDoc.id;
            userData["userRef"] = userDoc.ref;
            userData["name"] = userData?.first + " " + userData?.last;
            managersList.push(userData);
          }
          this.setState({ managersList });
        });
    }
    if (vendor.vendorRef) {
      this.setState({
        usersForAccount: [],
      });

      this.devsLive = this.props.firebase.db
        .collection("devs")
        .where("vendorRef", "==", vendor.vendorRef)
        .onSnapshot((snap) => {
          this.setState({ totalDevsCount: snap?.docs?.length ?? 0 });
        });

      this.bookable = this.props.firebase.db
        .collection("devs")
        .where("vendorRef", "==", vendor.vendorRef)
        .where("status", "==", true)
        .onSnapshot((snap) => {
          this.setState({ bookableCount: snap?.docs?.length ?? 0 });
        });
    }

    const profile = vendor.profile;
    // Set each section of the Accounts page to a variable to be passed
    // as props to their respective section. If the vendor does not have
    // the necessary information give a default value
    let financial = vendor.financial;
    if (!financial) {
      financial = {
        avgYearlyIncome: 0,
        avgRateSprint: 0,
        avgRateHr: 0,
        netMarginP: 0,
        grossMarginP: 0,
        publicCompany: false,
      };
    }
    let verification = vendor.verification;

    if (!verification) {
      verification = {
        businessEntityName: "Unknown",
        source: "None",
        status: true,
        jurisdiction: "Unknown",
        companyId: "Unknown",
        dateOfFormation: new Date(),
        lastUpdated: new Date(),
      };
    }
    let legal = vendor.legal;
    if (!legal) {
      legal = {
        bankruptcy: false,
        taxLienFilings: 0,
        judgementFilings: 0,
        collectionsCount: 0,
        lastUpdated: new Date(),
      };
    }
    let reviews = vendor.reviews;
    if (!reviews) {
      reviews = {
        verifiedClientReviews: 0,
        overallReviewRating: 0,
        source: "Unknown",
        lastUpdated: new Date(),
      };
    }
    let profileProgress = vendor.vendorProfileProgress;
    if (!profileProgress) {
      let updates = {};

      let vendorProfileProgress = {
        financialProgress: 0,
        devsProgress: 0,
      };
      updates["vendorProfileProgress"] = vendorProfileProgress;
      await this.props.firebase.updateVendor(vendor.id, updates);
    }
    let settings = { ...vendor.settings };
    // If settings is an empty object
    if (Object.keys(settings).length === 0 && settings.constructor === Object) {
      settings = {
        communication: {
          emailNotifications: true,
          monthlyNewsletter: true,
        },
        ops: {
          hoursInWorkDay: 8,
        },
        securitySettings: {
          securityLevelRateMode: true,
          securityOverHeads: {
            L1: null,
            L2: null,
            L3: null,
            L4: null,
            L5: null,
          },
        },
      };
    }
    const logo = vendor.logo;
    let vendorProfileProgress = 0;
    if (vendor.vendorProfileProgress) {
      vendorProfileProgress =
        (vendor?.vendorProfileProgress?.devsProgress || 0) * 0.75 +
        (vendor?.vendorProfileProgress?.financialProgress || 0) * 0.25;
    }
    // const profileRating = await vendor?.vendorRef
    //   ?.collection("rating")
    //   ?.orderBy("lastUpdated", "desc")
    //   ?.limit(3)
    //   ?.get();
    // let profileRatingAvg = 0;
    // profileRating?.docs?.map((ratingDoc) => {
    //   profileRatingAvg += ratingDoc?.data()?.rating?.average;
    // });
    this.setState({
      user: user,
      vendor: vendor,
      profile: profile,
      financial: financial,
      vendorPublic: vendorPublic,
      verification: verification,
      legal: legal,
      reviews: reviews,
      settings: settings,
      logo: logo,
      bioTruncated: "bio-truncated",
      bioButton: "Show more...",
      vendorProfileProgress: vendorProfileProgress,
      vendorProfileRating: vendor?.rating ?? 0,
      // (
      //   profileRatingAvg ?? 0 / (profileRating?.docs?.length ?? 1)
      // ).toFixed(2),
      isLoading: false,
      managerDetails: managerDetails,
    });
  };

  //
  //
  ///////////////////////////////////////////////////// Component Did Mount /////////////////////////////////////////////////////////////////////////
  componentDidMount() {
    this.getData();
    //Track Analytics
    // this.props.firebase.reportPageVisits("Account Page", this.props.isSuper, {
    //   "Vendor Id": this.vendor.vendor.id,
    //   "Vendor Name": this.vendor.vendor.companyName,
    // });
  }

  //
  //
  /////////////////////////////////////////////////////// Component Did Update /////////////////////////////////////////////////////////////////////////
  componentDidUpdate() {
    if (this.state.vendor?.id !== this.vendor.searchedVendor?.id) {
      if (this.vendor.searchedVendor.id) {
        this.getData();
      }
    }
  }

  componentWillUnmount() {
    try {
      this.users();
      this.bookable();
      this.devsLive();
      if (this.props.isSuper) this.managers();
    } catch (e) {}
  }

  //
  //
  ///////////////////////////////////////////////////// Update User Data /////////////////////////////////////////////////////////////////////////
  // Update the user data in the database
  updateUserData(data) {
    const updates = {};
    updates[data.name] = data.value;
    try {
      this.props.firebase.updateUser(this.state.user.id, updates);
    } catch (err) {}
  }

  //
  //
  ///////////////////////////////////////////////////////// Update Vendor Data /////////////////////////////////////////////////////////////////////////
  // Update the vendor data in the database
  async updateVendorData(data) {
    const updates = {};
    updates[data.name] = data.value;
    try {
      await this.props.firebase.updateVendor(this.state.vendor.id, updates);
      this.props.updateVendor();
    } catch (err) {
      console.log(err);
    }
  }

  //
  //
  // ///////////////////////////////////// Expand Bio /////////////////////////////////////////////////////////////////////////
  expandBio() {
    if (this.state.isBioTruncated) {
      this.setState({
        bioTruncated: "bio-expanded",
        bioButton: "Show less...",
        isBioTruncated: false,
      });
    } else {
      this.setState({
        bioTruncated: "bio-truncated",
        bioButton: "Show more...",
        isBioTruncated: true,
      });
    }
  }

  //
  //
  // ///////////////////////////////////// Edit Bio Function /////////////////////////////////////////////////////////////////////////
  editAbout() {
    document.querySelector("#targetButton1").click();
  }

  render() {
    // to be able to access the venodr data context on every render and on every level

    this.vendor = this.context;

    // console.log(this.vendor);

    return (
      <>
        {this.state.vendor?.id && (
          <div className="account">
            <div className="account-topnav">
              <h4>Settings</h4>
              <h4 className="arrow">{">"}</h4>
              <h4 className="edit">Edit Account Details</h4>
              <h4
                className={
                  this.props.isSuper ? "admin-account-name" : "account-name"
                }
              >
                {this.state.user.first + " " + this.state.user.last}
              </h4>
              {this.props.isSuper && (
                <RatingButton vendorData={this.state.vendor} />
              )}
            </div>
            <div className="account-div">
              <div className="account-div_info">
                <div className="account-intro">
                  <div className="account-intro_top">
                    <div style={{ display: "flex" }}>
                      <div className="account-photo">
                        <CompanyLogo
                          firebase={this.props.firebase}
                          vendor={this.state.vendor}
                          logo={this.state.logo}
                          updateVendor={this.props.updateVendor}
                        />
                      </div>
                      <div className="name">
                        <h1>{this.state.vendor.companyName}</h1>

                        <div className="personal-info">
                          <div className="personal-info-content">
                            <h3>Website</h3>
                            <InPlaceEditorComponent
                              actionBegin={(e) => this.updateVendorData(e.data)}
                              ref={(text) => {
                                this.textObj = text;
                              }}
                              id="textboxEle"
                              name="website"
                              mode="Inline"
                              type="Text"
                              value={this.state.vendor.website}
                              model={this.textModel}
                              popupSettings={this.popupSettings}
                            ></InPlaceEditorComponent>
                          </div>

                          <div className="personal-info-content">
                            <h3>Address</h3>
                            <InPlaceEditorComponent
                              actionBegin={(e) => this.updateVendorData(e.data)}
                              ref={(text) => {
                                this.textObj = text;
                              }}
                              id="textboxEle"
                              name="address"
                              mode="Inline"
                              type="Text"
                              value={this.state.vendor.address}
                              model={this.textModel}
                              popupSettings={this.popupSettings}
                            ></InPlaceEditorComponent>
                          </div>

                          <div className="personal-info-content">
                            <h3>Phone</h3>
                            <InPlaceEditorComponent
                              actionBegin={(e) => this.updateUserData(e.data)}
                              ref={(text) => {
                                this.textObj = text;
                              }}
                              id="textboxEle"
                              name="phone"
                              mode="Inline"
                              type="Text"
                              value={this.state.user.phone}
                              model={this.textModel}
                              popupSettings={this.popupSettings}
                            ></InPlaceEditorComponent>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="completed row">
                      <div style={{ marginRight: "25px" }}>
                        <p>Spryte Rating</p>
                        <ProgressBar
                          radius={35}
                          progress={
                            (this.state.vendorProfileRating * 100) / 4 || 0
                          }
                          strokeWidth={2}
                          fillColor="#F7FAFC"
                          strokeColor="#0057b3"
                          trackStrokeWidth={2}
                          pointerRadius={4}
                          pointerStrokeWidth={2}
                          pointerStrokeColor="#0057b3"
                        >
                          <div className="indicator">
                            <h2>{this.state.vendorProfileRating ?? 0}</h2>
                          </div>
                        </ProgressBar>
                      </div>
                      <div style={{ marginRight: "15px" }}>
                        <p>Trust Score</p>
                        <ProgressBar
                          radius={35}
                          progress={(this.vendor.vendorTrust * 100) / 4 || 0}
                          strokeWidth={2}
                          fillColor="#F7FAFC"
                          strokeColor="#0057b3"
                          trackStrokeWidth={2}
                          pointerRadius={4}
                          pointerStrokeWidth={2}
                          pointerStrokeColor="#0057b3"
                        >
                          <div className="indicator">
                            <h2>{this.vendor.vendorTrust ?? 0}</h2>
                          </div>
                        </ProgressBar>
                      </div>
                      <div style={{ marginRight: "15px" }}>
                        <p>Profile Completion</p>

                        <ProgressBar
                          radius={35}
                          progress={
                            Math.floor(
                              this.state?.vendorProfileProgress * 100 ?? 0.0
                            ) ?? 0.0
                          }
                          strokeWidth={2}
                          fillColor="#F7FAFC"
                          strokeColor="#0057b3"
                          trackStrokeWidth={2}
                          pointerRadius={4}
                          pointerStrokeWidth={2}
                          pointerStrokeColor="#0057b3"
                        >
                          <div className="indicator">
                            <h2>
                              {Math.floor(
                                this.state.vendorProfileProgress * 100
                              ) ?? 0}
                              %
                            </h2>
                          </div>
                        </ProgressBar>
                      </div>
                    </div>
                  </div>

                  <div className="account-intro_bottom">
                    <div className="account-about">
                      <div className="account-about_header">
                        <h2>About</h2>
                        <MdEdit onClick={(e) => this.editAbout()} />
                      </div>

                      <p>{this.state?.profile?.bio}</p>
                      <BioEdit
                        firebase={this.props.firebase}
                        vendor={this.state.vendor}
                        profile={this.state.profile}
                        getData={this.getData}
                        updateVendor={this.props.updateVendor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isLoading ? (
              "Loading..."
            ) : (
              <div className="stats">
                <AccManagement
                  vendor={this.vendor.searchedVendor}
                  firebase={this.props.firebase}
                  updateVendor={this.props.updateVendor}
                  isSuper={this.props.isSuper}
                  managerDetails={this.state.managerDetails}
                  managersList={this.state.managersList}
                  user={this.state.user}
                  loading={this.state.loading}
                  setPublicChatData={this.props.setPublicChatData}
                  setChatBtnClickCount={this.props.setChatBtnClickCount}
                />
                <Settings
                  settings={this.state.settings}
                  vendor={this.state.vendor}
                  firebase={this.props.firebase}
                  bookableCount={this.state.bookableCount}
                  totalDevsCount={this.state.totalDevsCount}
                  numericModel={this.numericModel}
                  percentModel={this.percentModel}
                  updateVendor={this.vendor.setIsVendorUpdated}
                  vendorPublic={this.state.vendorPublic}
                  updateVendorData={this.props.updateVendor}
                  orgPositions={this.vendor.orgPositions}
                  vendorGenerics={this.vendor.vendorGenerics}
                  setVendorGenerics={this.vendor.setVendorGenerics}
                  vendorTrust={this.vendor.vendorTrust}
                  setVendorTrust={this.vendor.setVendorTrust}
                  isSuper={this.props.isSuper}
                />
                <Finance
                  financial={this.state.financial}
                  vendor={this.state.vendor}
                  firebase={this.props.firebase}
                  dollarModel={this.dollarModel}
                  percentModel={this.percentModel}
                  updateVendor={this.vendor.setIsVendorUpdated}
                />
                <Reviews
                  reviews={this?.vendor?.searchedVendor?.reviews}
                  vendor={this?.vendor?.searchedVendor}
                  firebase={this.props.firebase}
                  updateVendor={this.vendor.setIsVendorUpdated}
                  updateInitialData={this.props.updateVendor}
                />
                <Verification
                  verification={this.state.verification}
                  vendor={this.state.vendor}
                  firebase={this.props.firebase}
                  numericModel={this.numericModel}
                  updateVendor={this.vendor.setIsVendorUpdated}
                />
                <Legal
                  legal={this.state.legal}
                  vendor={this.state.vendor}
                  firebase={this.props.firebase}
                  updateVendor={this.vendor.setIsVendorUpdated}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
