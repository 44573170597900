import React from "react";

const DevPickIcon = ({ w = "13", h = "12", fill = "#4361E9" }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14286 1H1V11H11V7.66667"
        stroke={fill}
        stroke-width="0.75"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 5.69231L6.35294 8L12 2"
        stroke={fill}
        stroke-width="0.75"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DevPickIcon;
