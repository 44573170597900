import React, { useState, useEffect, useRef } from "react";
import {
  addDocument,
  emailFormatValidationRegex,
  getCollectionRefFromPath,
  getDocumentsByArrayMembershipInCollection,
  createDocumentReferenceFromPath,
} from "../../../globals/services";
import SpResponsiveImg from "../../../globals/SpResponsiveImg";

export default function NotifyUsers({
  onClose,
  emailTemplates,
  partnersToNotify = [],
  loggedInUserData,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [showTemplateChange, setShowTemplateChange] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateText, setTemplateText] = useState("");
  const [isTemplateModified, setIsTemplateModified] = useState(false);
  const spanRef = useRef();
  const [showHtml, setShowHtml] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [allVendorManagers, setAllVendorManagers] = useState([]);
  const [search, setSearch] = useState("");
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [showUserResults, setShowUserResults] = useState(false);
  const [validRequest, setValidRequest] = useState(false);

  useEffect(() => {
    var emailValidation = validateEmails([...selectedUsers]);
    if (emailValidation.length == 0) {
      setInvalidEmails([]);
    } else {
      setInvalidEmails([...emailValidation]);
    }
  }, [selectedUsers]);

  const fetchUsers = async (text = "") => {
    var usersFoundByEmail = [];
    var usersFoundByName = [];
    var contactsFound = [];
    var tempUsers = await getCollectionRefFromPath("users")
      .where("email", "==", text)
      .get();
    var tempUsers1 = await getDocumentsByArrayMembershipInCollection(
      "users",
      text,
      ["searchIndex"],
      "first",
      false,
      true,
      false,
      20
    );
    await Promise.all(
      tempUsers?.docs?.map(async (user) => {
        var tempUserPartnerAccount = await user.ref
          .collection("accounts")
          ?.doc("spryte-partner")
          ?.get();
        var tempUserPartnerData;
        if (tempUserPartnerAccount.exists) {
          tempUserPartnerData = await tempUserPartnerAccount
            .data()
            ?.vendorRef?.get();
        }
        usersFoundByEmail.push({
          ...user?.data(),
          partnerData: tempUserPartnerData?.data(),
        });
      }),
      tempUsers1?.map(async (user) => {
        var tempUserPartnerAccount = await user.ref
          .collection("accounts")
          .doc("spryte-partner")
          .get();
        var tempUserPartnerData;
        if (tempUserPartnerAccount.exists) {
          tempUserPartnerData = await tempUserPartnerAccount
            .data()
            ?.vendorRef?.get();
        }
        usersFoundByName.push({
          ...user?.data(),
          partnerData: tempUserPartnerData?.data(),
        });
      })
    );
    var tempUsers2 = await getDocumentsByArrayMembershipInCollection(
      "contacts",
      text,
      ["emailIndex", "nameIndex"],
      "crm.data.properties.firstname",
      false,
      true,
      false,
      20
    );
    contactsFound = tempUsers2 ?? [];

    var tempUsers3 = await getDocumentsByArrayMembershipInCollection(
      "vendors",
      text,
      ["nameIndex"],
      "companyName",
      false,
      true,
      false,
      20
    );
    var vendorsManagersFound = [];
    tempUsers3?.map((vendorDoc) => {
      if (
        (vendorDoc?.data()?.settings?.communication?.members?.length ?? 0) > 0
      ) {
        vendorDoc?.data()?.settings?.communication?.members?.map((email)=>{
          vendorsManagersFound.push(
            {email: email, companyName: vendorDoc?.data()?.companyName}
          );
        })
      }
    });
    setAllUsers([...usersFoundByEmail, ...usersFoundByName]);
    setAllContacts(contactsFound ?? []);
    setAllVendorManagers(vendorsManagersFound);
  };

  useEffect(() => {
    if (spanRef.current && templateText) {
      spanRef.current.innerHTML = templateText;
    }
  }, [spanRef.current, templateText, showHtml]);

  useEffect(() => {
    const fetchContactData = async () => {
      var tempSelectedUsers = [...selectedUsers];
      await Promise.all(
        partnersToNotify?.map(async (partner) => {
          var contactData = await partner?.contactRef?.get();
          if (contactData?.exists) {
            tempSelectedUsers.push(contactData?.data()?.email);
          }
        })
      );
      setSelectedUsers(tempSelectedUsers);
    };
    if (partnersToNotify) fetchContactData();
  }, [partnersToNotify]);

  useEffect(() => {
    if (selectedTemplate >= 0)
      setIsTemplateModified(
        templateText != emailTemplates[selectedTemplate]?.data()?.html ||
          emailSubject != emailTemplates[selectedTemplate]?.data()?.subject ||
          templateName != emailTemplates[selectedTemplate]?.data()?.name
      );
  }, [emailSubject, templateName, templateText, selectedTemplate]);

  useEffect(() => {
    setValidRequest(
      selectedUsers.length > 0 &&
        emailSubject != "" &&
        templateText != "" &&
        invalidEmails.length == 0
    );
  }, [selectedUsers, emailSubject, templateText, invalidEmails]);

  function validateEmails(emails = []) {
    var tempInvalidEmails = [];
    emails
      .filter((email) => email != "")
      .map((email) => {
        if (emailFormatValidationRegex.test(email)) return;

        tempInvalidEmails.push(email);

        return null;
      });
    return tempInvalidEmails;
  }

  function hideUserSearchResults() {
    setShowUserResults(false);
  }

  const onUserSearch = (e) => {
    setSearch(e.target.value);
    if (!e.target.value) {
      setAllUsers([]);
      return;
    }

    fetchUsers(e.target.value.toString().toLowerCase());
  };

  const onAddUser = (record) => {
    var tempSelectedUsers = [...selectedUsers];
    if (!tempSelectedUsers.includes(record?.email)) {
      tempSelectedUsers.push(record?.email);
    } else {
      tempSelectedUsers = tempSelectedUsers.filter(
        (user) => user != record?.email
      );
    }
    setSelectedUsers(tempSelectedUsers);
    setShowUserResults(false);
  };

  const onAddContact = (contactCrmData) => {
    var tempSelectedUsers = [...selectedUsers];
    if (!tempSelectedUsers.includes(contactCrmData?.email)) {
      tempSelectedUsers.push(contactCrmData?.email);
    } else {
      tempSelectedUsers = tempSelectedUsers.filter(
        (user) => user != contactCrmData?.email
      );
    }
    setSelectedUsers(tempSelectedUsers);
    setShowUserResults(false);
  };

  const onAddManager = (managerEmail) => {
    var tempSelectedUsers = [...selectedUsers];
    if (!tempSelectedUsers.includes(managerEmail)) {
      tempSelectedUsers.push(managerEmail);
    } else {
      tempSelectedUsers = tempSelectedUsers.filter(
        (user) => user != managerEmail
      );
    }
    setSelectedUsers(tempSelectedUsers);
    setShowUserResults(false);
  };

  return (
    <div className="modal-content _req-userNotify">
      {!showTemplateChange ? (
        <div className="_req-userNotify--main">
          <div className="_req-userNotify-search">
            <label htmlFor="searchInput">Search & Find Users</label>
            <input
              type="text"
              id="searchInput"
              name="search"
              onFocusCapture={() => {
                setShowUserResults(true);
              }}
              placeholder="Search by user name or email"
              onChange={onUserSearch}
              value={search}
              // onBlur={hideUserSearchResults}
            />
            {search && (allUsers?.length > 0 || allContacts?.length > 0 || allVendorManagers?.length > 0) && (
              <div className="_req-userNotify-search-results">
                {allUsers?.map((record) => {
                  return (
                    <div className="_req-userNotify-search-results-single">
                      <div className="_req-userNotify-search-results-single-left">
                        <SpResponsiveImg
                          alt={record?.first + record?.last}
                          src={record?.avatar}
                          wrappercls="_req-userNotify-search-results-single-left-img"
                          imgCls={
                            "_req-userNotify-search-results-single-left-img-img"
                          }
                          sx={{
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p>{record?.first + record?.last}</p>
                        </div>
                        <div>
                          <p>{record?.email ?? "userEmail"}</p>
                        </div>
                        <div>
                          <p>{record?.partnerData?.companyName}</p>
                        </div>
                      </div>
                      <div className="_req-userNotify-search-results-single-right">
                        <button onClick={() => onAddUser(record)}>
                          {selectedUsers.includes(record?.email)
                            ? "Remove"
                            : "Add User"}
                        </button>
                      </div>
                    </div>
                  );
                })}
                {allContacts?.map((contact) => {
                  var contactCrmData =
                    contact?.data()?.crm?.data?.properties ?? {};
                  return (
                    <div className="_req-userNotify-search-results-single">
                      <div className="_req-userNotify-search-results-single-left">
                        <SpResponsiveImg
                          src={contact?.data()?.profile?.logoUrl}
                          wrappercls="_req-userNotify-search-results-single-left-img"
                          imgCls={
                            "_req-userNotify-search-results-single-left-img-img"
                          }
                          sx={{
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p>
                            {(contactCrmData?.firstname ?? " ") +
                              " " +
                              (contactCrmData?.lastname ?? " ")}
                          </p>
                        </div>

                        <div>
                          <p>{contactCrmData?.email ?? " "}</p>
                        </div>
                        <div>
                          <p>{contactCrmData?.partnerData?.company ?? " "}</p>
                        </div>
                      </div>
                      <div className="_req-userNotify-search-results-single-right">
                        <button onClick={() => onAddContact(contactCrmData)}>
                          {selectedUsers.includes(contactCrmData?.email)
                            ? "Remove"
                            : "Add Contact"}
                        </button>
                      </div>
                    </div>
                  );
                })}
                {allVendorManagers?.map((managerData) => {
                  return (
                    <div className="_req-userNotify-search-results-single">
                      <div className="_req-userNotify-search-results-single-left">
                        <SpResponsiveImg
                          src={""}
                          wrappercls="_req-userNotify-search-results-single-left-img"
                          imgCls={
                            "_req-userNotify-search-results-single-left-img-img"
                          }
                          sx={{
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p>{managerData?.["email"] ?? " "}</p>
                        </div>
                        <div>
                          <p>{managerData?.["companyName"] ?? " "}</p>
                        </div>
                      </div>
                      <div className="_req-userNotify-search-results-single-right">
                        <button onClick={() => onAddManager(managerData?.["email"])}>
                          {selectedUsers.includes(managerData?.["email"])
                            ? "Remove"
                            : "Add Member"}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="_req-userNotify-recipients">
            <div className="_req-userNotify-recipients_top">
              <label>BCC Recipients</label>
              {(selectedUsers?.length ?? 0) <= 0 && (
                <span className="_req-userNotify-required">*required</span>
              )}
            </div>

            <textarea
              placeholder="Email1@vendor.com,email2@vendor2.com,email3@vendor3.com"
              value={selectedUsers?.join(",")}
              onChange={(e) => {
                setSelectedUsers(
                  e.target.value != "" ? e.target.value.split(",") : []
                );
              }}
            />
            {(invalidEmails?.length ?? 0) > 0 && (
              <p className="recipent-err">
                Invalid emails found: {invalidEmails.join(",")}
              </p>
            )}
          </div>
          <div className="_req-userNotify-subject">
            <div className="_req-userNotify-subject_top">
              <label htmlFor="subject">Subject Line</label>
              {emailSubject == "" && (
                <span className="_req-userNotify-required">*required</span>
              )}
            </div>
            <input
              onFocusCapture={() => {
                hideUserSearchResults();
              }}
              placeholder="Email Subject Line"
              onChange={(e) => {
                setEmailSubject(e.target.value);
              }}
              value={emailSubject}
            />
          </div>
          <div className="_req-userNotify-messageBox">
            <div className="_req-userNotify-messageBox_top">
              <label htmlFor="emailBody">Compose email</label>
              {templateText == "" && (
                <span className="_req-userNotify-required">*required</span>
              )}
            </div>

            <div className="_req-userNotify-messageBox_switchers">
              <button
                onClick={() => {
                  setShowHtml(false);
                  hideUserSearchResults();
                }}
                className={showHtml ? "" : "active"}
              >
                Edit Email
              </button>
              <button
                onClick={() => {
                  setShowHtml(true);
                  hideUserSearchResults();
                }}
                className={showHtml ? "active" : ""}
              >
                HTML Preview
              </button>
            </div>
            {showHtml ? (
              <div ref={spanRef} className="template--viewer"></div>
            ) : (
              <textarea
                onFocusCapture={() => {
                  hideUserSearchResults();
                }}
                onChange={(e) => setTemplateText(e.target.value)}
                value={templateText}
                placeholder={`Compose your email here`}
              />
            )}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {selectedTemplate >= 0 && (
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "28px",
                    display: "flex",
                    alignItems: "center",
                    color: "#000C18",
                  }}
                >
                  Template Used: {templateName}
                </h2>
              )}
              <h2
                style={{
                  cursor: "pointer",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "28px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "right",
                  textDecorationLine: "underline",
                  color: "#2E86DE",
                }}
                onClick={() => {
                  setShowTemplateChange(true);
                  hideUserSearchResults();
                  setEmailSubject("");
                  setTemplateText("");
                  setTemplateName("");
                  setSelectedTemplate();
                }}
              >
                Change Template
              </h2>
            </div>
          </div>

          <div className="_req-userNotify-actions">
            <button
              style={{
                background: "#ED4396",
              }}
              className="_req-userNotify-actions-cancel"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="_req-userNotify-actions-send"
              style={{
                background: validRequest
                  ? "#4361E9"
                  : "rgba(67, 97, 233, 0.75)",
              }}
              disabled={!validRequest}
              onClick={async () => {
                if (validRequest && loggedInUserData?.user?.emailFwd !== void 0 && loggedInUserData?.user?.emailFwd?.email !== void 0) {
                  await addDocument("mailx", {
                    bcc: selectedUsers,
                    from: `${loggedInUserData?.user?.first} ${loggedInUserData?.user?.last} ${loggedInUserData?.user?.emailFwd?.email}`,
                    replyTo: `${loggedInUserData?.user?.first} ${loggedInUserData?.user?.last} ${loggedInUserData?.user?.emailFwd?.email}`,
                    message: {
                      html: templateText.replace(/\n/g, "<br>"),
                      subject: emailSubject,
                    },
                    fwdThrough: createDocumentReferenceFromPath(`users/${loggedInUserData?.user?.uid}`)
                  });
                  onClose();
                } else if (validRequest) {
                  await addDocument("mail", {
                    bcc: selectedUsers,
                    from: `${loggedInUserData?.user?.first} ${loggedInUserData?.user?.last} ${loggedInUserData?.user?.email}`,
                    replyTo: `${loggedInUserData?.user?.first} ${loggedInUserData?.user?.last} ${loggedInUserData?.user?.email}`,
                    message: {
                      html: templateText.replace(/\n/g, "<br>"),
                      subject: emailSubject,
                    },
                  });
                  onClose();
                }
              }}
            >
              Send mail
            </button>
          </div>
        </div>
      ) : (
        <div className="_req-userNotify--emailTemp">
          <div className="_req-userNotify--emailTemp_templates">
            <label>Templates</label>
            {emailTemplates && (
              <div className="_req-userNotify--emailTemp_templates__list">
                {emailTemplates?.map((template, index) => {
                  return (
                    <p
                      className="_req-userNotify--emailTemp_templates__list-template"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedTemplate == index ? "lightblue" : null,
                      }}
                      onClick={() => {
                        setTemplateName(template?.data()?.name);
                        setEmailSubject(template?.data()?.subject);

                        setTemplateText(template?.data()?.html);
                        setSelectedTemplate(index);
                      }}
                    >
                      {template?.data()?.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div className="_req-userNotify--emailTemp_tempName">
            <label>Edit Template Name</label>
            <input
              style={{
                width: "650px",
                height: "40px",
                background: "#FFFFFF",
                border: "1px solid #DDDDE1",
                padding: "5px",
                borderRadius: "6px",
              }}
              placeholder="Default email title"
              onChange={(e) => {
                setTemplateName(e.target.value);
              }}
              value={templateName}
            />
          </div>
          <div className="_req-userNotify--emailTemp_tempSubj">
            <label>Edit Subject Line</label>
            <input
              placeholder="Default email title"
              onChange={(e) => {
                setEmailSubject(e.target.value);
              }}
              value={emailSubject}
            />
          </div>
          <div className="_req-userNotify--emailTemp_tempEditor">
            <label>Edit Template</label>
            <textarea
              value={templateText}
              onChange={(e) => {
                setTemplateText(e.target.value);
              }}
            />
          </div>
          <div className="_req-userNotify--emailTemp_actions">
            <button
              style={{
                background: isTemplateModified
                  ? "#ED4396"
                  : "rgba(237, 67, 150, 0.75)",
              }}
              disabled={!isTemplateModified}
              onClick={async () => {
                if (isTemplateModified)
                  await emailTemplates[selectedTemplate]?.ref?.update({
                    editable: true,
                    name: templateName,
                    subject: emailSubject,
                    html: templateText,
                  });
              }}
            >
              Save Changes
            </button>
            <button
              style={{
                background: isTemplateModified
                  ? "#4361E9"
                  : "rgba(67, 97, 233, 0.75)",
              }}
              disabled={!isTemplateModified}
              onClick={async () => {
                if (isTemplateModified)
                  await emailTemplates[selectedTemplate]?.ref?.parent?.add({
                    editable: true,
                    name: templateName,
                    subject: emailSubject,
                    html: templateText,
                  });
              }}
            >
              Save as new
            </button>
          </div>

          <div className="_req-userNotify--emailTemp_realActions">
            <button
              style={{
                background: "#ED4396",
              }}
              onClick={() => {
                setShowTemplateChange(false);
              }}
            >
              Exit
            </button>
            <button
              style={{
                background: "#4361E9",
              }}
              onClick={() => {
                if (isTemplateModified)
                  emailTemplates[selectedTemplate]?.ref?.update({
                    editable: true,
                    name: templateName ?? "",
                    subject: emailSubject ?? "",
                    html: templateText ?? "",
                  });
                setShowTemplateChange(false);
              }}
            >
              Pick & Use
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
