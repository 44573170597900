import { useEffect, useRef, useState } from "react";

export const useSpTimeout = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

export const useScreenSize = () => {
  const [windowDimenion, detectHW] = useState({
    winWidth: 1030,
    winHeight: 1120,
  });

  useEffect(() => {
    detectHW({ winWidth: window.innerWidth, winHeight: window.innerHeight });
  }, []);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return windowDimenion;
};
